import React from 'react';

class IndustryComponent extends React.Component{
    render(){
        return(
            <div className="container align-items-center">
                <h3 className="pt-4 text-center font-bold">INDUSTRIES</h3>

                <div className="pt-4" style={{textAlign:"justify"}}>
                    <h6 className="font-bold pb-2">Education & Training</h6>
                    <p>
                        All the students can join our platform to obtain world class training from our GTS Certified skilled teachers/professors.
                        Teacher/professors can enrol to attract students to provide them world class coaching.
                    </p>
                    <p>
                        GTS Certified teachers are paid the most!!! 
                    </p>
                </div>
                <hr />
                <div style={{textAlign:"justify"}}>
                    <h6 className="font-bold pb-2">IT & Software</h6>
                    <p>
                        IT & Software companies can get best professionals in our platform. 
                        We are constantly providing Software Development Training to thousands of IT professionals in the area of UI Development, Mobile App, Microservice, Cloud Computing, DevOps etc..
                    </p>
                    <p>
                        IT professional are in our platform to improve/upgrade their skills incrementally.
                    </p>
                </div>
                <hr />
                <div style={{textAlign:"justify"}}>
                    <h6 className="font-bold pb-2">Call Centers</h6>
                    <p>
                        Our highly qualified trainers are providing  best training to the self-motivated individuals who want to build their profession in Call Centres.
                    </p>
                    <p>
                        If you are looking for World Class Trainers then register in our platform.
                    </p>
                    <p>
                        If you are interested to join us to train people to make them best Call Centre Executives, then please join us. 
                    </p>
                </div>
                <hr />
                <div style={{textAlign:"justify"}}>
                    <h6 className="font-bold pb-2">Hotel & Hospitality</h6>
                    <p>
                        GTS Platform is the best place for the following professionals: 
                        Event Planner, Executive Chef, Hotel General Manager, Housekeeper, Porter, Waiter/Waitress.
                    </p>
                </div>
                <hr />
                <div style={{textAlign:"justify"}}>
                    <h6 className="font-bold pb-2">Retail</h6>
                    <p>
                        We have well trained resources who has worked in Retail Stores.
                    </p>
                </div>
                <hr />

                <div style={{textAlign:"justify"}}>
                    <h6 className="font-bold pb-2">Daily Wages</h6>
                    <p>
                        If you are you a Driver, Plumber or Electrician, 
                        Labour Worker then you can expect to get trained in our platform and seek a job. 
                    </p>
                </div>
                <hr />

                <div style={{textAlign:"justify"}}>
                    <h6 className="font-bold pb-2">Health Care</h6>
                    <p>
                        Our platform is the best place for the Hospitals, Clinics, 
                        Laboratories to find best Doctors, Nurses & Health Workers.
                    </p>
                </div>
                <hr />

                <div style={{textAlign:"justify"}}>
                    <h6 className="font-bold pb-2">Politics</h6>
                    <p>
                        This platform will help the politicians to get party workers during the election campaigns.
                    </p>
                    <p>
                        People who want to act as party workers, can register with us by specifying their available time.
                    </p>
                </div>
                <hr />

                <div style={{textAlign:"justify"}}>
                    <h6 className="font-bold pb-2">Real Estate</h6>
                    <p>
                        This platform will help the property dealers to help the Sellers & Buyers of the properties.
                    </p>
                    <p>
                        Sellers can publish their properties to find the most potentials list of customers.
                    </p>
                    <p>
                        Buyers can find the most suitable properties to buy.
                    </p>
                </div>
                <br/>
                <br/>
            </div>
        )
    }
}
export default IndustryComponent;