import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import ls from 'local-storage';
import axios from 'axios';
import {Link } from 'react-router-dom';
import { Popover,  OverlayTrigger, FormControl, InputGroup,Dropdown, NavDropdown} from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SwitchRole from '../common/SwitchRole';

// reactstrap components
import {
  Alert,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,

  Row,
  Col,
  Container
} from 'reactstrap';

import { FormLabel, Card, CardBody,CardText } from 'react-bootstrap';

var userLoginDetails=ls.get('userLoginDetails');
var token = ls.get('token');
var userDetail = '';
var userPersonalDetails=ls.get('userPersonalDetails');


class TrainerMenu extends Component {

  constructor(props) {
		if(token==null || token=="" || token=="undefined"){
		  window.location.href = '/gts/global-home-page';
		}
    super(props)

    this.state = {
      role_name:'',
      errors: {},
      isUserDetailExisting:false,
      first_name:'',
      last_name:'',
      userDetail:{}
    }
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  componentDidMount() {


    if(userPersonalDetails!== null){
    if( userPersonalDetails.gts_user_id == userLoginDetails.user_id ){
      this.setState({first_name : userPersonalDetails.gts_user_first_name})
      this.setState({last_name : userPersonalDetails.gts_user_last_name})
      }
    }
  }

  setUserId = (userID)=>{
    ls.set('gts_user_id', userID);
  }

render()
{
  const {
   user_name,
   user_Detail
  }=this.state;
    var roles=[];
    roles=userLoginDetails.other_roles.split(',');

    return(
      <div>
       <div className="container-fluid">
         <InputGroup>
           <NavDropdown title={<span style={{color:'black',fontSize:"15px", paddingRight:"5px"}}><b>Profile</b></span>} >
              <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/trainer-profile"><span style={{fontSize:"15px"}}><b>Update Personal Details</b></span></NavDropdown.Item>
              <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/your-profile-viewer"><span style={{fontSize:"15px"}}><b>Your Profile Viewer</b></span></NavDropdown.Item>
              <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/manage-roles"><span style={{fontSize:"15px"}}><b>Update Roles</b></span></NavDropdown.Item>
              <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/change-password"><span style={{fontSize:"15px"}}><b>Change Password</b></span></NavDropdown.Item>
           </NavDropdown>

          <NavDropdown title={<span style={{color:'black',fontSize:"15px"}}><b>Trainings</b></span>} >
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/add-training"><span style={{fontSize:"15px"}}><b>Add Training</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/add-training-offer"><span style={{fontSize:"15px"}}><b>Add Training Offer</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/list-of-training"><span style={{fontSize:"15px"}}><b>List Trainings</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/training-applications"><span style={{fontSize:"15px"}}><b>Training Applications</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/training-in-progress"><span style={{fontSize:"15px"}}><b>Trainings in Progress</b></span></NavDropdown.Item>
          </NavDropdown>

           <NavDropdown title={<span style={{color:'black',fontSize:"15px"}}><b>Trainees</b></span>} >
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/trainee-recommendation"><span style={{fontSize:"15px"}}><b>Trainee Recommendation</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/trainee-search"><span style={{fontSize:"15px"}}><b>Trainee Search</b></span></NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title={<span style={{color:'black',fontSize:"15px"}}><b>Wish List</b></span>} >
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/training-wishes"><span style={{fontSize:"15px"}}><b>Training Wishes</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/job-wishes"><span style={{fontSize:"15px"}}><b>Job Wishes</b></span></NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title={<span style={{color:'black',fontSize:"15px"}}><b>Notifications</b></span>} >
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/notifications"><span style={{fontSize:"15px"}}><b>Notifications</b></span></NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title={<span style={{color:'black',fontSize:"15px"}}><b>Messages</b></span>} >
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/messages"><span style={{fontSize:"15px"}}><b>Messages</b></span></NavDropdown.Item>
          </NavDropdown>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <span style={{fontWeight: 'bold'}}>Current Role:</span>&nbsp;&nbsp; <span style={{fontWeight: 'bold', color: 'red'}}>{userLoginDetails.primary_role}</span>&nbsp;&nbsp;
          <SwitchRole/>
       </InputGroup>
       <div style={{borderBottomColor: 'black',borderBottomWidth: 1.5}} />
     </div>
   </div>
  )
}}
export default TrainerMenu;