import React from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import TrainingForServiceConsumersComponent from '../../components/trainings/TrainingForServiceConsumersComponent';

// reactstrap components
import {
  Button,

  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert,
  ButtonGroup
} from 'reactstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
//import backgroundImage from '../assets/img/icons/common/4.svg';
import {Card, CardBody,CardText, FormControl, FormLabel,FormCheck } from 'react-bootstrap';
import ls from 'local-storage';

var token = ls.get("token");

class TrainingForServiceConsumersPage extends React.Component {


    constructor(props) {
						if(token==null || token=="" || token=="undefined"){
						  window.location.href = '/gts/global-home-page';
				}
        super(props);
        this.state = {

          roleSuccess:''
        };
        }

              render() {
                return (
                <div>

                        <Header />
                        <TrainingForServiceConsumersComponent/>
                        <Footer/>
                </div>
               )
        }
}


export default TrainingForServiceConsumersPage;



