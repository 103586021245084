import React, { Component } from "react";
import "./SearchServiceConsumerComponent.css";
import 'bootstrap/dist/css/bootstrap.css'
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import axios from "axios";
import ls from "local-storage";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import DropdownButton from 'react-bootstrap/DropdownButton'
import { endpoints_properties } from '../../properties/EndPointsProperties.js';
import { api_properties } from '../../properties/APIProperties.js';

import {
  Container,
  Grid,
  Slider,
  Paper,
  FormLabel,
  Select,
  Input,
  Checkbox,
  ListItemText,
  MenuItem,
  FormControl,
  Chip,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { Button, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { Dropdown,InputGroup } from "react-bootstrap";

var token = ls.get("token");

var userLoginDetails = ls.get("userLoginDetails");

class SearchServiceConsumerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceConsumer: [],
      primarySkillName: [],
      secondarySkillName: [],
      modalOpen: false,
      dimmer: undefined,
      value: 20,
      rangeVal: [0, 10],
      yearVal: [0, 10],
      skills: [
        {
          gts_skill_id: 0,
          gts_skill_name: null,
          gts_skill_description: null,
          gts_skill_status: false,
        },
      ],
      skillName: [],
      selectedSkills: [],
      selectedAdSkills: [],
      chipData: [],
      jobTitles: [
        {
          gts_job_title_id: 0,
          gts_job_title_name: "",
          gts_job_title_description: null,
          gts_job_title_status: false
        },
      ],
      jobTitleName: [],
      selectedJobTitles: [],
      industries: [
        {
          gts_industry_id: 0,
          gts_industry_name: "",
          gts_industry_description: null,
          gts_industry_status: false
        },
      ],
      industryName: [],
      selectedIndustries: [],
      locations: [
        {
          gts_city_id: 0,
          gts_city_name: "",
          gts_city_description: null,
          gts_city_status: false,
          gts_country_id: 0,
          gts_state_id: 0,
          gts_country_name: "",
          gts_state_name: ""
        }
      ],
      cityName: [],
      selectedCities: [],
      companies: [
        {
          gts_company_id: 0,
          gts_company_name: "",
          gts_company_description: "",
          gts_company_status: false,
          gts_company_contact_email: "",
          gts_country_code: 0,
          gts_company_contact_mobile_number: 0,
          gts_company_contact_alternate_mobile_number: 0
        }
      ],
      companyName: [],
      selectedCompanies: [],
      degrees: [
        {
        gts_degree_id: 0,
        gts_degree_name: "",
        gts_degree_description: "",
        gts_degree_status: false
        }
      ],
      degreeName: [],
      selectedEducationLevel: [],
      accountstatus: [],
      search: "",
      successMessage: "",
      errorMessage: "",
      loading: false,
      currentPage: 0,
    };

    this.cancel = "";
    // this.updateSkillsOption = this.updateSkillsOption.bind(this);
  }

  componentDidMount() {
    if (this.cancel) {
      this.cancel.cancel();
    }
    this.cancel = axios.CancelToken.source();

    this.skillsAutoCompleteChangeHandler();
  }

  //search
  handleOnInputChange = (event) => {
    let search = event.target.value.substr(0, 20);
    this.setState({ search: search, loading: true, message: "" });
  };

  //pagination
  handleClick(e, index) {
    e.preventDefault();
    this.setState({
      currentPage: index,
    });
  }

  onInputChangeHandler = (e)=>{
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  skillsAutoCompleteChangeHandler = (input) => {
    axios
      .get(endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_SKILLS, {
        headers: { Auth_Token: `Bearer ${token}` },
      })
      .then((response) => {
        // console.log(response.data);
        this.state.skills = response.data;

        if (this.state.skillName.length != 0) this.state.skillName.length = [];
        this.state.skills.forEach((skills) => {
          this.state.skillName.push(skills.gts_skill_name);
          this.state.skillName.filter((value) => value.includes(input));
        });
      })
      .catch((error) => {
        if (axios.isCancel(error) || error) {
          this.setState({
            loading: false,
            errorMessage: "Failed to fetch the data",
          });
          console.log(error);
        }
      });
  };

  saveSkillAutoCompleteInput = (event, value) => {
    this.setState({
      errorMessage: "",
      successMessage: ""
    })
    this.state.selectedSkills = value;
    console.log(this.state.selectedSkills);
  };

  saveAdSkillAutoCompleteInput = (e, value)=>{
    this.state.selectedAdSkills = value;
  }

  industryAutoCompleteChangeHandler = (input) =>{
    axios
      .get(endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_INDUSTRIES, { headers: { Auth_Token: `Bearer ${token}` },
      })
      .then((response)=>{
        this.state.industries = response.data;

        if (this.state.industryName.length != 0) this.state.industryName.length = [];
        this.state.industries.forEach((industry) => {
          this.state.industryName.push(industry.gts_industry_name);
          this.state.industryName.filter((value) => value.includes(input));
        })
      })
      .catch((error)=>{
        //console.log(error.response.data.message);
        console.log(error);
      })
  }

  saveIndustryAutoCompleteInput =(event, value) =>{
    this.state.selectedIndustries = value;
    console.log(this.state.selectedIndustries);
  }

  locationAutoCompleteChangeHandler = (input) =>{
    axios
      .get(endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_CITIES,{ headers: { Auth_Token: `Bearer ${token}` },
      })
      .then((response)=>{
        this.state.locations = response.data;

        if (this.state.cityName.length != 0) this.state.cityName.length = [];
          this.state.locations.forEach((location) => {
            this.state.cityName.push(location.gts_city_name);
            this.state.cityName.filter((value) => value.includes(input));
          })
      })
      .catch((error)=>{
        // console.log(error.response.data.message);
        console.log(error);

      })
  }

  saveLocationAutoCompleteInput = (event, value) =>{
    this.state.selectedCities = value;
    console.log(this.state.selectedCities);
  }

  companyAutoCompleteChangeHandler = (input) =>{
    axios
      .get(endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_COMPANIES, { headers: { Auth_Token: `Bearer ${token}` },
      })
      .then((response)=>{
        this.state.companies = response.data;

        if (this.state.companyName.length != 0) this.state.companyName.length = [];
          this.state.companies.forEach((company) => {
            this.state.companyName.push(company.gts_company_name);
            this.state.companyName.filter((value) => value.includes(input));
          })
      })
      .catch((error)=>{
        //console.log(error.response.data.message);
        console.log(error);

      })
  }

  saveCompanyAutoCompleteInput =(event, value)=>{
    this.state.selectedCompanies = value;
    console.log(this.state.selectedCompanies);
  }

  jobTitleAutoCompleteChangeHandler = (input) =>{
    axios
      .get(endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_SERVICE_TITLES, { headers: { Auth_Token: `Bearer ${token}` },
      })
      .then((response)=>{
        this.state.jobTitles = response.data;

        if (this.state.jobTitleName.length != 0)
        this.state.jobTitleName.length = [];
          this.state.jobTitles.forEach((jobTitle) => {
             this.state.jobTitleName.push(jobTitle.gts_job_title_name);
          })
      })
      .catch((error)=>{
        //console.log(error.response.data.message);
        console.log(error);

      })
  }

  saveJobTitleAutoCompleteInput = (event, value)=>{
    this.state.selectedJobTitles = value;
    console.log(this.state.selectedJobTitles);
  }

  degreeAutoCompleteChangeHandler = (input)=>{
    axios
    .get(endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_DEGREES, { headers: { Auth_Token: `Bearer ${token}` },
    })
    .then((response)=>{
      this.state.degrees = response.data;
      if (this.state.degreeName.length != 0) this.state.degreeName.length = [];
          this.state.degrees.forEach((degree) => {
            this.state.degreeName.push(degree.gts_degree_name);
            this.state.degreeName.filter((value) => value.includes(input));
          })
    })
    .catch((error)=>{
      //console.log(error.response.data.message);
      console.log(error);
    })
  }

  saveDegreeAutoCompleteInput = (event, value) =>{
    this.state.selectedEducationLevel = value;
    console.log(this.state.selectedEducationLevel);
  }

  ratings = (e)=>{
    this.state.ratings = e.target.value;
  }

  serviceConsumerFromAdvanceSearch = ()=>{
    const selectedAdSkillIds = [];
    this.state.selectedAdSkills.forEach((selectedSkill) => {
      this.state.skills.forEach((skill) => {
        if (selectedSkill === skill.gts_skill_name) {
          selectedAdSkillIds.push(skill.gts_skill_id);
        }
      })
    })
    // console.log("Selected skill id: " + selectedAdSkillIds.join(",").toString());


    const selectedIndustryIds = [];
    this.state.selectedIndustries.forEach((selectedIndustry)=>{
      this.state.industries.forEach((industry)=>{
        if (selectedIndustry === industry.gts_industry_name){
          selectedIndustryIds.push(industry.gts_industry_id);
        }
      })
    })
    // console.log("Selected industry id: " + selectedIndustryIds.join(",").toString());

    const selectedLocationIds = [];
    this.state.selectedCities.forEach((selectedLocation)=>{
      this.state.locations.forEach((location)=>{
        if (selectedLocation === location.gts_city_name){
          selectedLocationIds.push(location.gts_city_id);
        }
      })
    })
    // console.log("Selected location id: " + selectedLocationIds.join(",").toString());

    const selectedCompanyIds = [];
    this.state.selectedCompanies.forEach((selectedCompany)=>{
      this.state.companies.forEach((company)=>{
        if (selectedCompany === company.gts_company_name){
          selectedCompanyIds.push(company.gts_company_id);
        }
      })
    })
    // console.log("Selected company id: " + selectedCompanyIds.join(",").toString());

    const selectedJobTitleIds = [];
    this.state.selectedJobTitles.forEach((selectedJobTitle)=>{
      this.state.jobTitles.forEach((jobTitle)=>{
        if (selectedJobTitle === jobTitle.gts_job_title_name){
          selectedJobTitleIds.push(jobTitle.gts_job_title_id);
        }
      })
    })
    console.log("Selected currency id: " + selectedJobTitleIds.join(",").toString());

    const selectedDegreeIds = [];
    this.state.selectedEducationLevel.forEach((selectedDegree)=>{
      this.state.degrees.forEach((degree)=>{
        if (selectedDegree === degree.gts_degree_name){
          selectedDegreeIds.push(degree.gts_degree_id);
        }
      })
    })
    // console.log("Selected Degree id: " + selectedDegreeIds.join(",").toString());

    this.state.errorMessage = "";
    var url =endpoints_properties.ENDPOINT_PROD+api_properties.API_SEARCH_SC;
    var advPayload = {
      gts_employer_searched_by_id: userLoginDetails.user_id,
      gts_employer_search_required_skill_ids: selectedAdSkillIds.join(",").toString(),
      gts_employer_search_industry_ids: selectedIndustryIds.join(",").toString(),
      gts_employer_search_city_ids: selectedLocationIds.join(",").toString(),
      gts_employer_search_company_ids: selectedCompanyIds.join(",").toString(),
      gts_employer_search_field_of_recruitment_ids: selectedJobTitleIds.join(",").toString(),
      gts_employer_search_education_ids: selectedDegreeIds.join(",").toString(),
      gts_employer_search_ratings: this.state.ratings,
    };
    console.log(advPayload);

    axios
    .post(url, advPayload, {
      headers: { Auth_Token: `Bearer ${token}` },
    })
    .then((response)=>{
      this.setState({
        serviceConsumer: response.data,
        // successMessage: "Search Results",

      })
      console.log(response.data);
    })
    .catch((error)=>{
      this.setState({
        errorMessage: error.response.data.message,
        serviceConsumer:[]
      })
      console.log(error.response.data.message);
    })
  }

  clearAllFields = () =>{
      this.setState({
        selectedAdSkills: [],
        selectedIndustries: [],
        selectedCities: [],
        selectedCompanies: [],
        selectedJobTitles: [],
        selectedEducationLevel: [],
        selectedInstitute: [],
        selectedLanguages: [],
      })
  }

  setUserId = (userID)=>{
    var url =endpoints_properties.ENDPOINT_PROD+api_properties.API_PROFILE_VIEWER;
      var payload = {
        "gts_user_profile_owner_id": userID,
        "gts_user_profile_viewer_id": userLoginDetails.user_id
        }
      axios.post(url, payload, {
        headers: { Auth_Token: `Bearer ${token}` },
      })
      .then((response)=>{
        alert(response.data.message)
        console.log(response.data.message)
      })


      ls.set('gts_user_id', userID);
  }

  autoCompleteChangeHandler = (input) => {
    axios
      .get(endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_SKILLS, {
        headers: { Auth_Token: `Bearer ${token}` },
      })
      .then((response) => {
        // console.log(response.data);
        this.state.skills = response.data;

        if (this.state.skillName.length != 0) this.state.skillName.length = [];
        this.state.skills.forEach((skills) => {
          this.state.skillName.push(skills.gts_skill_name);
          this.state.skillName.filter((value) => value.includes(input));
        });
      })
  };

  saveAutoCompleteInput = (event, value) => {
    this.setState({
      errorMessage: "",
      successMessage: ""
    })
    this.state.selectedSkills = value;
    console.log(this.state.selectedSkills);
  };

  fetchServiceConsumer = () => {
    const selectedSkillIds = [];
    this.state.selectedSkills.forEach((selectedSkill) => {
      this.state.skills.forEach((skill) => {
        if (selectedSkill === skill.gts_skill_name) {
          selectedSkillIds.push(skill.gts_skill_id);
        }
      });
    });
    console.log("Selected skill id: " + selectedSkillIds.join(",").toString());
    let url =endpoints_properties.ENDPOINT_PROD+api_properties.API_SEARCH_SC;
    var payload = {
      gts_employer_searched_by_id: userLoginDetails.user_id,
      gts_employer_search_required_skill_ids: selectedSkillIds.join(",").toString(),
    };
    console.log(payload);
    axios
      .post(url, payload, {
        headers: { Auth_Token: `Bearer ${token}` },
      })
      .then((response) => {
        console.log(response.data);

        this.setState({
          serviceConsumer: response.data,
          // successMessage: "Search Results",
          loading: false,
        });
      })
      .catch((error) => {
        if (axios.isCancel(error) || error) {
          this.setState({
            loading: false,
            errorMessage: "Service Consumers are not available",
          });
          console.log(error);
        }
      });
  };

   StarRating = (count, value, inactiveColor, size, activeColor)=> {
      const stars = Array.from({length: count}, ()=> '★')
      return(
        <>
          {stars.map((s,index) =>{
            let style = inactiveColor;
            if(index< value){
              style = activeColor;
            }
            return(
              <span
              className= {"star"}
              key={index}
              style={{color: style, width: size, height: size, fontSize: size}}
              >{s}
              </span>)
          })}
        </>
      )
  }

  ActivateOrDeactivateUser =(userID,value)=>{
    var payload = {
        "gts_user_id": userID
    }

    if(value == 'true'){
      var activateURL = endpoints_properties.ENDPOINT_PROD+api_properties.API_ACTIVATE_USER;
      axios
      .put(activateURL, payload, {
        headers: { Auth_Token: `Bearer ${token}` },
      })
      .then((response)=>{
        this.setState({
          successMessage: "User account activated successfully",
        })
      })
      .catch((err) =>{
        this.setState({
          errorMessage: "Not able to activate user account"
        })
      })
    }
    else{
      var inactivateURL = endpoints_properties.ENDPOINT_PROD+api_properties.API_INACTIVATE_USER;
      axios
      .put(inactivateURL, payload, {
        headers: { Auth_Token: `Bearer ${token}` },
      })
      .then((response)=>{
        this.setState({
          successMessage: "User account inactivated successfully",
        })
      })
      .catch((err) =>{
        this.setState({
          errorMessage: "Not able to inactivate user account"
        })
      })
    }
  }

  clearSuccessMessage =()=>{
    this.setState({
      successMessage:'',
      errorMessage : ''
    })
  }



  render() {
    return (
     <div>
       <br/>
       <InputGroup>
         <h5 class="row offset-2" style={{fontSize:"18px"}}><b>Enter Skills in the Search Bar</b></h5>
       </InputGroup>
       <InputGroup>
         <Autocomplete
           className="offset-2"
           multiple
           options={this.state.skillName}
           style={{width:"70%"}}
           filterSelectedOptions
           onChange={this.saveAutoCompleteInput}
           renderInput={(params) => {
             this.autoCompleteChangeHandler(params);
             return (
               <TextField
                {...params}
                 variant="outlined"
                 placeholder="Skills"
                />
             );
            }}
         />
       </InputGroup>
       <div style={{fontSize:"15px", color:"red"}}>
         <b><center>{this.state.errorMessage}</center></b>
       </div>
       <div className="text-center">
         <Button color="primary" onClick={this.fetchServiceConsumer}>
            Search
         </Button>
         <Button
              color="link"
              data-toggle="modal"
              data-target="#advancedSearch"
              style={{paddingLeft: "10px"}}
            >
              Advanced Search
            </Button>
       </div>
       {/* ------------------------------------------------------------------------------------------------------- */}
       <div id="advancedSearch" class="modal fade" role="dialog">
             <div class="modal-dialog modal-lg">
              <div class="modal-content" style={{ border: "solid 2px black" }}>
                <div class="modal-header">
                  <h4 class="modal-title">Advanced Search</h4>
                  <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
                <div class="modal-body" style={{ border: "solid 2px black" }}>
                  <div class="row">
                    <div class="col">
                      <label for="skills">Skills</label>
                      <Autocomplete
                        class="autocomplete"
                        multiple
                        id="skills"
                        options={this.state.skillName}
                        style={{outlineColor: "black" }}
                        filterSelectedOptions
                        onChange={this.saveAdSkillAutoCompleteInput}
                        onBlur={this.clearSuccessMessage}
                        renderInput={(params) => {
                          this.skillsAutoCompleteChangeHandler(params);
                          return (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Skills"
                            />
                          );
                        }}
                      />
                    </div>
                    <div class="col">
                      <label for="industry">Industry</label>
                      <Autocomplete
                        class="autocomplete"
                        multiple
                        id="industry"
                        options={this.state.industryName}
                        onBlur={this.clearSuccessMessage}
                        style={{outlineColor: "black" }}
                        filterSelectedOptions
                        onChange={this.saveIndustryAutoCompleteInput}
                        renderInput={(params) => {
                          this.industryAutoCompleteChangeHandler(params);
                          return (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Industry"
                            />
                          );
                        }}
                      />
                    </div>
                    <div class="col">
                      <label for="company">Company</label>
                      <Autocomplete
                        class="autocomplete"
                        multiple
                        id="company"
                        options={this.state.companyName}
                        onBlur={this.clearSuccessMessage}
                        style={{outlineColor: "black" }}
                        filterSelectedOptions
                        onChange={this.saveCompanyAutoCompleteInput}
                        renderInput={(params) => {
                          this.companyAutoCompleteChangeHandler(params);
                          return (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Company"
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                  <div class="col">
                      <label for="currency">Field of Recruitment</label>
                      <Autocomplete
                        class="autocomplete"
                        multiple
                        id="jobTitles"
                        options={this.state.jobTitleName}
                        onBlur={this.clearSuccessMessage}
                        style={{outlineColor: "black" }}
                        filterSelectedOptions
                        onChange={this.saveJobTitleAutoCompleteInput}
                        renderInput={(params) => {
                          this.jobTitleAutoCompleteChangeHandler(params);
                            return (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Service"
                              />
                          );
                        }}
                      />
                    </div>
                  <div class="col">
                      <label for="location">Location</label>
                      <Autocomplete
                        class="autocomplete"
                        multiple
                        id="location"
                        options={this.state.cityName}
                        onBlur={this.clearSuccessMessage}
                        style={{outlineColor: "black" }}
                        filterSelectedOptions
                        onChange={this.saveLocationAutoCompleteInput}
                        renderInput={(params) => {
                          this.locationAutoCompleteChangeHandler(params);
                          return (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Location"
                            />
                          );
                        }}
                      />
                    </div>
                    <div class="col">
                        <label for="degree">Education Level</label>
                        <Autocomplete
                          multiple
                          id="degree"
                          options={this.state.degreeName}
                          onBlur={this.clearSuccessMessage}
                          style={{outlineColor: "black" }}
                          filterSelectedOptions
                          onChange={this.saveDegreeAutoCompleteInput}
                          renderInput={(params) => {
                            this.degreeAutoCompleteChangeHandler(params);
                            return (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Education Level"
                              />
                            );
                          }}
                        />
                    </div>
                  </div>
                  <hr />

                  <div class="row">
                    <div class="col">
                    <label for="ratings">Ratings</label><br/>
                      <select class="select" onBlur={this.clearSuccessMessage} onChange={this.ratings} style={{ border: "1px solid #D3D3D3", height:"35px", width:"230px"}}>
                        <option selected value="">Ratings</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                    </div>
                    </div>
                  <div class="text-center">
                      <Button color="primary" onBlur={this.clearSuccessMessage} class="close" data-dismiss="modal" onClick={this.serviceConsumerFromAdvanceSearch}>
                        Search
                      </Button>

                  </div>
                </div>
              </div>
             </div>
            </div>
       {/* ---------------------------------------------------------------------------------------------------------- */}
       <div class="successMsg">
         {this.state.successMessage}
       </div>
       {this.state.serviceConsumer.map((item)=>(
           <Grid container spacing={2}>
            <Grid item xs={10}>
              <div class="mt-4">
              <div class="border border-dark rounded-lg offset-1">
             <div class="row" >
               <div class="col-4 pr-0 ml-2">
               <h4><AccountCircleIcon fontSize="large"/>
                <strong>{item.gts_employer_name !== null ? item.gts_employer_name : 'Unknown'}</strong></h4>
                 {item.gts_employer_company.length == 0 ? <h6 class="pl-4" style={{fontSize:"15px"}}>&nbsp;&nbsp;Job Title: <span class="span">Unemployed</span></h6> :
                   item.gts_employer_company.map((company)=>{
                   return <h6 style={{fontSize:"15px"}}>Job Title: <span class="span">{company.is_currently_working == true ? company.gts_user_job_title_name : 'Unemployed' }</span></h6>
                 })}

                 {item.gts_employer_company.length == 0 ? <h6 style={{fontSize:"15px"}} class="pl-4">&nbsp;&nbsp;Current Company: Unknown</h6> :
                   item.gts_employer_company.map((company)=>{
                   return <h6 style={{fontSize:"15px"}} class="pl-4">Current Company: <span class="span">{company.is_currently_working == true ? company.gts_user_company_name : "Unknown"}</span></h6>
                 })}

                 {item.gts_employer_required_skills.map((skill)=>{
                                this.state.primarySkillName.push(skill.gts_required_skill_name)
                            })}
                            <h6 style={{fontSize:"15px"}} class="pl-4">&nbsp;&nbsp;Required Skills: {this.state.primarySkillName.toString()}</h6>
               </div>

               <div class={ "col-3 pl-5 p-2"}>
               <h6 style={{fontSize:"15px"}}>Contact: <span class="span"> {item.gts_primary_contact_number}</span></h6>
                 <h6 style={{fontSize:"15px"}}>Email: <span class="span">{item.gts_user_email}</span></h6>
                 <h6 style={{fontSize:"15px"}}>Ratings:{item.sc_ratings.length== 0 ? this.StarRating(5, 0, 'grey', 20, 'yellow') : this.StarRating(5, item.sc_ratings[0].gts_sc_avg_ratings, 'grey', 20, 'yellow')}</h6>
               </div>

               <div class="col-3 p-2">
                 {item.gts_employer_city.length === 0 ? <h6>Location: <span class="span">Unknown</span></h6> :
                  <h6>Location: <span class="span">{item.gts_employer_city[0].gts_city_name}</span></h6>
                 }

                {item.gts_service_consumer_year_of_exp === null ? <h6>Years of Exp: <span class="span">0</span></h6> :
                  <h6>Years of Exp: <span class="span">{item.gts_service_consumer_year_of_exp.gts_years_of_experience}</span></h6>
                }
               </div>
               <div class="col-1 p-2">
                  <Dropdown>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic" className="btn btn-primary" style={{backgroundColor:"white",color:"white", width:"100px", fontSize:"10px"}}>
                    <span style={{fontSize:"10px"}}>View Actions</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href={"/gts/service-consumer-profile"} onBlur={this.clearSuccessMessage} onClick={()=>this.setUserId(item.gts_user_id)} >View Profile</Dropdown.Item>
                      <Dropdown.Item href={"/gts/list-of-services"} onBlur={this.clearSuccessMessage} onClick={()=>this.setUserId(item.gts_user_id)} >View Job Posted</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  {userLoginDetails.primary_role == "ADMIN"?
               <div class="col-1 p-0">
                 <Dropdown>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic" className="btn btn-primary" style={{backgroundColor:"white",color:"white", width:"100px", fontSize:"10px"}}>
                    <span style={{fontSize:"10px"}}>{item.gts_user_status == true ? 'Activate' : 'Deactivate'}</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {item.gts_user_status == false ?
                       <Dropdown.Item onBlur={this.clearSuccessMessage} onClick={()=>this.ActivateOrDeactivateUser(item.gts_user_id,'true')} onChange={item.gts_user_status == true}>Activate</Dropdown.Item>
                       : <Dropdown.Item onBlur={this.clearSuccessMessage} onClick={()=>this.ActivateOrDeactivateUser(item.gts_user_id,'false')} onChange={item.gts_user_status == false}>Deactivate</Dropdown.Item>
                      }
                   </Dropdown.Menu>
                  </Dropdown>
               </div>:''}

               {userLoginDetails.primary_role == "ADMIN" ?
                 <button
                 onBlur={this.clearSuccessMessage}
                 className="btn btn-primary"
                 style={{backgroundColor:"white",color:"white", width:"100px", fontSize:"10px"}}>
                 Login As
                 </button> : ''}

               </div>
             </div>
             </div>

             <div style={{display: this.state.primarySkillName!==null ? 'none' : 'block'}}>
             { this.state.primarySkillName =  []}
             </div>
             </div>
             </Grid>
           </Grid>

       ))}
       <br/>
     </div>
    );
  }
}

export default SearchServiceConsumerComponent;
