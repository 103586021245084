import React, { Component } from "react";
import { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import axios from "axios";
import Card from "react-bootstrap/Card";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import { Multiselect} from "multiselect-react-dropdown";
import TextField from "@material-ui/core/TextField";
// import { Dropdown } from 'semantic-ui-react'
import ls from "local-storage";
import Moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTrash, faEdit} from '@fortawesome/free-solid-svg-icons'
import { endpoints_properties } from '../../properties/EndPointsProperties.js';
import { api_properties } from '../../properties/APIProperties.js';
// import DatePicker from 'react-datepicker';
import {
  Accordion,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Button,
  Form,
  InputGroup,
  FormControl
} from "react-bootstrap";

import {Input,DropdownMenu} from 'reactstrap';


var env=ls.get('ENV');
var token=ls.get('token');
var userLoginDetails = ls.get("userLoginDetails");


var getUserEducationsURL = "";
var activeDegreesURL="";
var activeCountriesURL="";
var getCitiesByCountry="";
var getInstitutesByCity="";
var getCitiesByCountryURL="";
var addOrEditUserEducations="";
var deleteEducationByEducationID="";


if(env=="LOCAL"){
	getUserEducationsURL = endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_USER_EDUCATIONS;
	activeDegreesURL=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_ACTIVE_DEGREES;
	activeCountriesURL=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_ACTIVE_COUNTRIES;
	getCitiesByCountry=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_CITIES_BY_COUNTRY;
	getInstitutesByCity=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_INSTITUTES_BY_CITY;
	getCitiesByCountryURL=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_CITIES_BY_COUNTRY;
	addOrEditUserEducations=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_USER_EDUCATIONS;
	deleteEducationByEducationID=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_DELETE_USER_EDUCATIONS;
}
else{
	getUserEducationsURL = endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_USER_EDUCATIONS;
	activeDegreesURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_DEGREES;
	activeCountriesURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_COUNTRIES;
	getCitiesByCountry=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_CITIES_BY_COUNTRY;
	getInstitutesByCity=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_INSTITUTES_BY_CITY;
	getCitiesByCountryURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_CITIES_BY_COUNTRY;
	addOrEditUserEducations=endpoints_properties.ENDPOINT_PROD+api_properties.API_USER_EDUCATIONS;
	deleteEducationByEducationID=endpoints_properties.ENDPOINT_PROD+api_properties.API_DELETE_USER_EDUCATIONS;
}


class EducationComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalEducation:0,
      month:[],
      year:[],
      disableCity:true,
      deselected:true,
      disableEducation: true,
      gts_user_education: "",
      gts_user_id: userLoginDetails.user_id,
      gts_user_degree_name: "",
      gts_user_degree_start_date: "",
      gts_user_degree_end_date: "",
      gts_institute_country: "",
      gts_institute_city: "",
      gts_user_educations: [],
      country: [],
      countries:[],
      city: [],
      cities:[],
      degree:[],
      degrees:[],
      institute: [],
      instituties:[],
      gts_user_educations_id: [],
      gts_user_educations_name: [],
      gts_institute_country_id: '',
      gts_country_id:null,
      gts_institute_city_id: 0,
      message: "",
      hasError:false,
   initialState:[{
     gts_user_degree_name:'',
     gts_user_degree_id :'',
     gts_user_degree_country_name :'',
     gts_user_degree_country_id :'',
     gts_user_degree_city_name:'',
     gts_user_degree_city_id: '',
     gts_user_degree_institute_name:'',
     gts_user_degree_institute_id :'',
     gts_user_other_institute:'',
     gts_user_degree_start_date:'',
     gts_user_degree_end_date:'',
     gts_user_degree_marks_percentage:0,
     gts_user_degree_grade:''
    }],
    startDate: new Date(),
    monthSelected:''
    };
    this.handleChange = this.handleChange.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
  }

    changeHandler = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };

    handleChange(date) {
      this.setState({
      startDate:date
      })
      }

    validateDegree = (e) => {
      let fields = this.state.fields;
      let degreeError = this.state.degreeError;
      let formIsValid = true;
      if (this.state.gts_user_current_degree_id === null || this.state.gts_user_current_degree_id === undefined) {
        formIsValid = false;
        degreeError = "Please select degree";
        this.setState({
          degreeError: degreeError
        });
      }

      else{
      degreeError = "";
        this.setState({
          degreeError: degreeError
        });
      }
    };

    validate = (e) => {
      let fields = this.state.fields;
      let degreeError = this.state.degreeError;
      let error = this.state.error
      let formIsValid = true;
      if (this.state.gts_user_current_degree_id === null || this.state.gts_user_current_degree_id === undefined) {
        formIsValid = false;
        degreeError = "Please select degree";
        this.setState({
          degreeError: degreeError,
          validated: false
        });
      }

      else{
      degreeError = "";
        this.setState({
          degreeError: degreeError,
          validated: true
        });
      }
      if(this.state.currentStartYear !='' || this.state.currentEndYear!=''){
      if(this.state.currentStartYear >= this.state.currentEndYear){
        this.setState({error: 'Start date cannot be greater than end date',
        validated: false})
        }
        else{
          this.setState({error : '',
          validated: true})
        }
      }
    };

    reset= (e) =>{
      let initialState=[]
      this.setState({initialState:initialState})
    }

    validateUpdateDegree = (e) => {
      let fields = this.state.fields;
      let degreeUpdateError = this.state.degreeUpdateError;
      let formIsValid = true;
      if (this.state.selectedDegree === null || this.state.selectedDegree === undefined) {
        formIsValid = false;
        degreeUpdateError = "Please select degree";
        this.setState({
          degreeUpdateError: degreeUpdateError
        });
      }

      else{
        degreeUpdateError = "";
        this.setState({
          degreeUpdateError: degreeUpdateError
        });
      }
    };

    clearDegreeError = () => {
      let degreeError = this.state.degreeError;
       if (this.state.selectedDegree) {
        degreeError = "";
        this.setState({
          degreeError: degreeError,
          validated : true
        });
      }
    }

    clearUpdateDegreeError = () => {
      let degreeError = this.state.degreeError;
       if (this.state.selectedDegree) {
        degreeError = "";
        this.setState({
          degreeError: degreeError
        });
      }
    }

    clearOtherInstitute = () => {
      if(this.state.selectedInstitute!== null){
        console.log(this.state.selectedInstitute)
        this.state.gts_user_other_institute = " "
      }
    }

    clearMessage = () => {
      let message = this.state.message;
      let error = this.state.error;
      let updateMessage = this.state.updateMessage;
      let updateError = this.state.updateError;
      let deleteMessage = this.state.deleteMessage;
      let deleteError = this.state.deleteError;
      let errors = this.state.errors;


      if(this.state.gts_user_current_degree_id !== 0 || this.state.gts_user_current_degree_id!== null){
        this.setState({validated: true})
      }
      if(this.state.currentStartYear !='' || this.state.currentEndYear!=''){
        if(this.state.currentStartYear >= this.state.currentEndYear){
          this.setState({error: 'Start date cannot be greater than end date',
          validated: false})
          }
          else{
           this.setState({error : '',
           validated: true})
           this.state.updateErrors= '';
         }
        }

        if(this.state.startYear !='' || this.state.endYear!=''){
          if(this.state.startYear >= this.state.endYear){
            this.setState({updateError: 'Start date cannot be greater than end date',
            validatedUpdate: false})
            }
            else{
              this.setState({updateError : '',
              validatedUpdate: true})
              this.state.updateErrors= '';
            }
          }
          else{
            this.setState({ validatedUpdate: true})
            this.state.updateErrors= '';
          }

      if(this.state.message){
        message = " ";
        this.setState({ message: message });
        this.setState({validated: true})
      }
      if(this.state.error){
        error = " ";
        this.setState({ error: error });
        this.setState({validated: true})
      }
      if(this.state.updateMessage){
        updateMessage = " ";
        this.setState({ updateMessage: updateMessage });
        this.setState({validated: true})
      }
      if(this.state.updateError){
        updateError = " ";
        this.setState({ updateError: updateError });
      }
      if(this.state.deleteMessage){
        deleteMessage = " ";
        this.setState({ deleteMessage: deleteMessage });
        this.setState({validated: true})
      }
      if(this.state.deleteError){
        deleteError = " ";
        this.setState({ deleteError: deleteError });
        this.setState({validated: true})
      }
      if(this.state.errors){
        errors = " ";
        this.setState({ errors: errors });
        this.setState({validated: true})
      }

    };

    enableEducation = () =>{
        this.setState({disableEducation:false})
    }

    componentDidMount() {

	  var get_url=getUserEducationsURL+'/'+userLoginDetails.user_id;
      axios.get(get_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
        .then(res => {
           this.setState({totalEducation : res.data.length})
          this.setState({gts_user_educations : res.data});
        })
        var month=[];
        for(var i=1;i<=12;i++){
          let zero = i < 10 ? '0' : '';
          this.state.month.push(zero + i);
        }

        var years=[];
        var minOffset = 0, maxOffset = 70; // Change to whatever you want
        var thisYear = (new Date()).getFullYear();

        for (var i = minOffset; i <= maxOffset; i++) {
          var year = thisYear - i;
          years.push(year)
        }
        this.setState({year:years})
        this.autoCompleteChangeHandler();
   }


    autoCompleteChangeHandler = (input) =>{
        let degreeError= this.state.degreeError;
        axios.get(activeDegreesURL,{ headers: {"Auth_Token" : `Bearer ${token}`} })
        .then((res) =>{
          this.setState({ degrees : res.data});
          this.state.degrees.forEach((degrees) => {
            this.state.degree.push(degrees.gts_degree_name);
          });
        })

      axios.get(activeCountriesURL,{ headers: {"Auth_Token" : `Bearer ${token}`} })
        .then((res) =>{
          this.setState({ countries : res.data});
          console.log(res.data)
          this.state.countries.forEach((countries) => {
            this.state.country.push(countries.gts_country_name);
          });
        })
    }

    saveAutoCompleteDegree=(event, value) => {
        this.state.selectedDegree = value;

        this.state.degrees.forEach((degree) => {
          if (this.state.selectedDegree === degree.gts_degree_name) {
            this.state.gts_user_degree_id = degree.gts_degree_id;
          }
        });

        const degreeClose = document.getElementsByClassName(
          "MuiAutocomplete-clearIndicator"
        )[0];

        // Add a Click Event Listener to the button
        degreeClose.addEventListener("click", () => {
          this.setState({gts_user_degree_name : ''})
        });
    }

    saveAutoCompleteCountry=(event, value) => {
      console.log(event)
      this.state.selectedCountry = value;
      if(value == ''  || value== null || this.state.gts_institute_country_id !== value.gts_country_id){
        this.setState ({gts_institute_country_id : ''})
        this.setState ({city: []})
      }
    }

    saveAutoCompleteCity=(event, value) => {
      this.state.selectedCity = value;
      this.setState({selectedCity : value})
      if(value == ''  || value== null || this.state.gts_institute_city_id !== value.gts_city_id){
        this.setState ({gts_institute_city_id : ''})
        this.setState ({institute: []})
      }
    }

    saveAutoCompleteInstitute=(event, value) => {
      this.state.selectedInstitute = value;
      if(value == ''  || value== null){
        this.setState ({gts_user_degree_institute_id : ''})
      }
       else{
        this.setState({gts_other_institute:''})
         this.setState({gts_user_other_institute:''})
        this.state.instituties.forEach((institute) => {
          if (this.state.selectedInstitute === institute.gts_institute_name) {
            this.state.gts_user_degree_institute_id = institute.gts_institute_id;
          }
         })
       }
    }

    onSelectCountryHandler = (e) => {
      if(this.state.selectedCountry){
        this.state.countries.forEach((country) => {
          if (this.state.selectedCountry === country.gts_country_name) {
            this.state.gts_institute_country_id = country.gts_country_id;
          }
        });
      }
        var getCities =getCitiesByCountry+'/'+this.state.gts_institute_country_id;
        axios.get(getCities,{ headers: {"Auth_Token" : `Bearer ${token}`} })
      .then((res) =>{
        console.log(res.data)
        this.setState({ cities: res.data});

        if(this.state.selectedCountry == ''){
         this.setState({city : []})
        }
      else{
        this.state.cities.forEach((cities) => {
          this.state.city.push(cities.gts_city_name);
        })
      }
        console.log(this.state.city)
        })
    }

    onSelectCityHandler = (e) => {
      console.log(this.state.selectedCity)
      if(this.state.selectedCity!==null){
        this.state.cities.forEach((city) => {
          if (this.state.selectedCity === city.gts_city_name) {
            this.state.gts_institute_city_id = city.gts_city_id;
          }
        });
          if(this.state.gts_institute_city_id!=undefined ||this.state.gts_institute_city_id!=0){
      var getInstitutes =getInstitutesByCity+'/'+this.state.gts_institute_city_id;
      axios.get(getInstitutes,{ headers: {"Auth_Token" : `Bearer ${token}`} })
     .then((res) =>{
       this.setState({ instituties: res.data});
         this.state.instituties.forEach((instituties) => {
         this.state.institute.push(instituties.gts_institute_name);
        });
      });
     }}
    }

    selectStartMonth = (month) => {
      if(month.target.value){
      var e = document.getElementById("startMonth");
      this.state.startMonth = month.target.value;
      }
      else{
        this.state.startMonth = Moment(this.state.gts_user_degree_start_date).format("MM")
      }
    }

    selectStartYear = (year) => {
      if(year.target.value){
      var e = document.getElementById("startYear");
      this.state.startYear = year.target.value;
      }
      else{
        this.state.startMonth = Moment(this.state.gts_user_degree_start_date).format("YYYY")
      }
    }

    selectEndMonth = (month) => {
      if(month.target.value){
      var e = document.getElementById("endMonth");
      this.state.endMonth = month.target.value;
      }
      else{
        this.state.startMonth = Moment(this.state.gts_user_degree_end_date).format("MM")
      }
    }

    selectEndYear = (year) => {
      if(year.target.value){
      var e = document.getElementById("endYear");
      this.state.endYear = year.target.value;
     }
      else{
        this.state.startMonth = Moment(this.state.gts_user_degree_end_date).format("YYYY")
      }
    }


// --------------------------------------------------------------for current----------------------------------------------------

    saveAutoCompleteCurrentDegree=(event, value) => {
      this.state.selectedCurrentDegree = value;

      this.state.degrees.forEach((degree) => {
        if (this.state.selectedCurrentDegree === degree.gts_degree_name) {
          this.state.gts_user_current_degree_id = degree.gts_degree_id;
        }
      });

      const degreeClose = document.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )[0];

      // Add a Click Event Listener to the button
      degreeClose.addEventListener("click", () => {
        this.setState({gts_current_user_degree : ''})
      });
  }

  saveAutoCompleteCurrentCountry=(event, value) => {
    this.state.selectedCurrentCountry = value;
    if(value == ''  || value== null || this.state.gts_institute_current_country_id !== value){
      this.setState ({gts_institute_current_country_id : ''})
      this.setState ({city: []})
    }
  }

  saveAutoCompleteCurrentCity=(event, value) => {
    this.state.selectedCurrentCity = value;
    this.setState({selectedCurrentCity : value})
    if(value == ''  || value== null || this.state.gts_institute_current_city_id !== value){
      this.setState ({gts_institute_current_city_id : ''})
      this.setState ({institute: []})
    }
  }

  saveAutoCompleteCurrentInstitute=(event, value) => {
    this.state.selectedCurrentInstitute = value;
    if(value == ''  || value== null){
      this.setState ({gts_user_degree_current_institute_id : ''})
    }
     else{
      this.state.instituties.forEach((institute) => {
        if (this.state.selectedCurrentInstitute === institute.gts_institute_name) {
          this.state.gts_user_degree_current_institute_id = institute.gts_institute_id;
        }
       })
     }
  }

  onSelectCurrentCountryHandler = (e) => {
    if(this.state.selectedCurrentCountry){
      this.state.countries.forEach((country) => {
        if (this.state.selectedCurrentCountry === country.gts_country_name) {
          this.state.gts_institute_current_country_id = country.gts_country_id;
        }
      });
    }
      var getCities =getCitiesByCountryURL+'/'+this.state.gts_institute_current_country_id;
      axios.get(getCities,{ headers: {"Auth_Token" : `Bearer ${token}`} })
    .then((res) =>{
      console.log(res.data)
      this.setState({ cities: res.data});

      if(this.state.selectedCurrentCountry == ''){
       this.setState({city : []})
      }
    else{
      this.state.cities.forEach((cities) => {
        this.state.city.push(cities.gts_city_name);
      })
    }
      })
  }

  onSelectCurrentCityHandler = (e) => {
    if(this.state.selectedCurrentCity!==null){
      this.state.cities.forEach((city) => {
        if (this.state.selectedCurrentCity === city.gts_city_name) {
          this.state.gts_institute_current_city_id = city.gts_city_id;
        }
      });

    var getInstitutes =getInstitutesByCity+'/'+this.state.gts_institute_current_city_id;
    axios.get(getInstitutes,{ headers: {"Auth_Token" : `Bearer ${token}`} })
   .then((res) =>{
     this.setState({ instituties: res.data});
       this.state.instituties.forEach((instituties) => {
       this.state.institute.push(instituties.gts_institute_name);
      });
    });
   }
  }

  selectCurrentStartMonth = (e) => {
    // var e = document.getElementById("currentStartMonth");
    this.state.currentStartMonth = e.target.value;
  }

  selectCurrentStartYear = (e) => {
    // var e = document.getElementById("currentStartYear");
    this.state.currentStartYear = e.target.value;
  }

  selectCurrentEndMonth = (e) => {
    // var e = document.getElementById("currentEndMonth");
    this.state.currentEndMonth = e.target.value;
  }

  selectCurrentEndYear = (e) => {
    // var e = document.getElementById("currentEndYear");
    this.state.currentEndYear = e.target.value;
  }

  validateUpdate = (start_date,end_date) =>{
    var Difference_In_Time = new Date(end_date) - new Date(start_date);
            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

           if(Difference_In_Days < 365){
            this.state.validatedUpdate = false;
            setTimeout(
              () =>
                this.setState({
                  updateError: "User degree start date should not be greater than end date."
                }),
              3
            );
            }
            else{
              this.setState({
              validatedUpdate: true})
            }

  }

  // -----------------------------------------------/////---------------------------------------------------

  componentDidCatch(errorMsg,errorInfo){
      let errors = this.state.error;
      errors = "Try after sometime or Please contact the administrator";
      this.setState({errors : errors})
  }

  saveEducation = (event) => {
        event.preventDefault();
        let responses = this.state.responses;
        let message = this.state.message;
        let error = this.state.error;

        if(this.state.currentStartYear || this.state.currentStartMonth){
          var  start_date=(this.state.currentStartYear+"-"+this.state.currentStartMonth+"-01").toLocaleString();
        }
        else{
          var start_date = ''
        }

        if(this.state.currentEndYear || this.state.currentEndMonth){
          var  end_date=(this.state.currentEndYear+"-"+this.state.currentEndMonth+"-01").toLocaleString()
        }
        else{
          var  end_date= ''
        }

        if(this.state.gts_current_user_degree_grade != null){
         var grade = this.state.gts_current_user_degree_grade.toString()
        }
        else{
          var grade =  this.state.gts_current_user_degree_grade = ""
        }

        this.validateUpdate(start_date,end_date);
        var url = addOrEditUserEducations;
          var postPayload = {

            gts_user_id: userLoginDetails.user_id,
            gts_user_degree_id: this.state.gts_user_current_degree_id,
            gts_user_degree_institute_id: this.state.gts_user_degree_current_institute_id,
            gts_user_other_institute: this.state.gts_user_other_institute,
            gts_user_degree_city_id: this.state.gts_institute_current_city_id,
            gts_user_degree_country_id: this.state.gts_institute_current_country_id,
            gts_user_degree_start_date:start_date,
            gts_user_degree_end_date: end_date,
            gts_user_degree_marks_percentage: this.state.gts_current_user_degree_marks_percentage,
            gts_user_degree_grade: grade

          };

		var get_url=getUserEducationsURL+'/'+userLoginDetails.user_id;

          if(this.state.validated == true){
          axios.post(addOrEditUserEducations, postPayload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
            .then((response) => {
              this.setState({ message: response.data.message });
              this.setState({gts_user_education_id : response.data.gts_user_education_id});
              axios.get(get_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
              .then(res => {
              if(res.data.length!==0){
                this.setState({totalEducation : res.data.length})
                this.setState({gts_user_educations : res.data});
            }
            else{
              this.setState({gts_user_educations : [] });
            }
              })
            })
            .catch((err) => {
              if(err.response.data.status_code == 400 || err.response.data.status_code == 404 ){
                this.setState({ error: err.response.data.message });
              }
              else{
              error = "Not able to add education"
              this.setState({ error: error });
              }
            });
          }
  }

  updateEducation(education) {
	      var get_url=getUserEducationsURL+'/'+userLoginDetails.user_id;
          let updateMessage = this.state.updateMessage;
          let updateError = this.state.updateError;

          if(this.state.startMonth=='' || this.state.startMonth == undefined){
            this.state.startMonth = Moment(education.gts_user_degree_start_date).format('MM')
          }

          if(this.state.endMonth=='' || this.state.endMonth == undefined){
            this.state.endMonth =  Moment(education.gts_user_degree_end_date).format('MM')
          }

          if(this.state.startYear=='' || this.state.startYear == undefined){
            this.state.startYear = Moment(education.gts_user_degree_start_date).format('YYYY')
          }

          if(this.state.endYear=='' || this.state.endYear == undefined){
            this.state.endYear =  Moment(education.gts_user_degree_end_date).format('YYYY')
          }

          if(this.state.startYear || this.state.startMonth){
          var  start_date=(this.state.startYear+"-"+this.state.startMonth+"-01").toLocaleString();
          }
          else{
            var start_date = education.gts_user_degree_start_date;
          }


          if(this.state.endYear || this.state.endMonth){
            var end_date=(this.state.endYear+"-"+this.state.endMonth+"-01").toLocaleString()
            }
            else{
              var end_date = education.gts_user_degree_end_date;
            }

            this.validateUpdate(start_date,end_date);

            if(this.state.gts_current_user_degree_grade != null){
              var grade = this.state.gts_current_user_degree_grade.toString()
             }
             else{
               var grade =  this.state.gts_current_user_degree_grade = ""
             }

          var url = addOrEditUserEducations;
          var putPayload = {
            gts_user_education_id: education.gts_user_education_id,
            gts_user_degree_id: education.gts_user_degree_id,
            gts_user_degree_institute_id: this.state.gts_user_degree_institute_id,
            gts_user_other_institute: this.state.gts_user_other_institute,
            gts_user_degree_city_id: this.state.gts_institute_city_id,
            gts_user_degree_country_id: this.state.gts_institute_country_id,
            gts_user_degree_start_date:start_date,
            gts_user_degree_end_date: end_date,
            gts_user_degree_marks_percentage: this.state.gts_user_degree_marks_percentage,
            gts_user_degree_grade: grade
          };
          if(this.state.validatedUpdate== true){

          axios
            .put(addOrEditUserEducations, putPayload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
            .then((response) => {
              this.setState({ updateMessage: response.data.message });
              axios.get(get_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
              .then(res => {
              if(res.data.length!==0){
                this.setState({totalEducation : res.data.length})
              this.setState({gts_user_educations : res.data});
            }
            else{
              this.setState({gts_user_educations : [] });
            }
              })
            })
            .catch((err) => {
              console.log(err.response.data.message)
              if(err.response.data.status_code == 400 || err.response.data.status_code == 404 ){
                this.setState({ updateError: err.response.data.message });
              }
              else{
                updateError = "Not able to update education"
                this.setState({ updateError: updateError });
              }
            });
          }
  }

  deleteEducation(educationId){
	      var get_url=getUserEducationsURL+'/'+userLoginDetails.user_id;
          let deleteMessage = this.state.deleteMessage;
          let deleteError = this.state.deleteError;
          if(window.confirm('Are you sure?')){
           axios.delete(deleteEducationByEducationID+"/"+educationId,{ headers: {"Auth_Token" : `Bearer ${token}`} })
           .then(response=>{
            axios.get(get_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
            .then(res => {
            if(res.data.length!==0){
              this.setState({totalEducation : res.data.length})
            this.setState({gts_user_educations : res.data});
          }
          else{
            this.setState({gts_user_educations : [] });
          }
            })
            var gts_user_educations=[]
            axios.get(get_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
            .then(res => {
            if(res.data.length!==0){
            this.setState({gts_user_educations : res.data});
          }
          else{
            this.setState({gts_user_educations : [] });
             }
            })
            this.setState({gts_user_educations : []});
             deleteMessage = response.data.message
             this.setState({ deleteMessage : deleteMessage });
           })
           .catch((err) => {
				console.log(err.response);
				deleteError = "Not able delete education";
				this.setState({ deleteError: deleteError })
           })
          }
  }

  render() {
    const successMessageStyles = {
      //backgroundColor: "#f0f",
      fontWeight: "bold",
      fontSize: 18,
      color: "#008000",
      //padding: paddings
    };

    const useStyles = {
      '@global': {
          '.MuiAutocomplete-option[data-focus="true"]': {
              background: 'blue'
          }
      }
  };

  const sizes = ["X-Small", "Small", "Medium", "Large", "X-Large", "2X-Large"];

    const {
      gts_user_education,
      gts_user_id,
      gts_user_degree_name,
      gts_user_degree_start_date,
      gts_user_degree_end_date,
      gts_institute_country,
      gts_institute_city,
      gts_user_educations,
      country,
      city,
      gts_country_id,
      gts_user_educations_id,
      gts_user_educations_name,
      gts_institute_country_id,
      gts_institute_city_id,
      message,
      isUserProjectExisting,
      countryId,
      cityId,
      gts_user_other_institute,
      gts_current_user_other_institute,
      disableEducation
    } = this.state;



    return (
      <Container className="container align-items-center">
     <div className="container align-items-center">
        <div className="mt-3">
          <div className="border border-dark rounded">
              <Row>
                <Col>
				           <h5 style={{color : "black"}} class="mb-2 p-2"><b>Education Background</b></h5>
                </Col>
                {this.state.totalEducation < 5 ?
                <Col>
                   <div className = "col-12" align="right">
                     < button type="button" style={{width:"160px", height:"35px", align:"center",display: "inline"}} class="btn btn-primary float-right" data-toggle="modal" data-target="#addEducationDetails"  onClick={this.addButtonListener} onBlur={this.clearMessage} size="small"><center><h6 style={{ color: 'white' }}>Add Education</h6></center></button>
                  </div>
                </Col>
              : ''}
                  </Row>
              <div class="form.row">
                <span style={{color:"red"}} ><center>{this.state.errors}</center></span>
                <span style={{color:"green"}} ><center>{this.state.deleteMessage}</center></span>
                <span style={{color:"red"}}><center>{this.state.deleteError}</center></span>
              </div>
			        <div style={{borderBottomColor: 'black',borderBottomWidth: 1.5}} />

 {/*------------------------------------------------------------MODEL DIALOG TO ADD EDUCATION-------------------------------------  */}
    <div id={"addEducationDetails"} class="modal fade" role="dialog">
      <div class="modal-dialog modal-lg">
         <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title"> Add Education</h5>
            </div>
            <div class="modal-body">
              <div className="container" >
                <div class="row-sm m-0  text-left">
                  <InputGroup className="mb-3" size="sm">
                    <h6 style={{ color : "black",fontSize:"15px"}}>Name of Degree:<span style={{fontSize:"15px", color: "red"}}><b>*</b></span></h6>
									     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Autocomplete
                      options={this.state.degree}
                      style={{ width: 410,height:"30px", outlineColor: "black", display: "inline-block"}}
                      renderInput={(params) => <TextField {...params} id="gts_current_user_degree" variant="outlined" style={{ color: "black" }} name="gts_current_user_degree" size="small"/>}
                      onChange={this.saveAutoCompleteCurrentDegree}
                      onFocus={this.clearMessage}
                      onBlur={this.validateDegree}
                      noOptionsText='No options'
                    />
                  </InputGroup>
                  <div className="row" align="center">
                    <span style={{color:"red"}}>{this.state.degreeError}</span>
                    <span >{this.state.degreeError?<br/> : ''}</span>
                  </div>
                  <InputGroup className="mb-3" size="sm">
                    <h6  style={{ color : "black",fontSize:"15px"}}>Country:</h6>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Autocomplete
                      options={this.state.country}
                      style={{ width: 158, outlineColor: "black", display: "inline-block"}}
                      renderInput={(params) => <TextField {...params} id="gts_current_institute_country" variant="outlined" style={{ color: "black" }} name="gts_current_institute_country" onSelect={this.onSelectCurrentCountryHandler} size="small"/>}
                      onChange={this.saveAutoCompleteCurrentCountry}
                      noOptionsText='No options'
                      onBlur={this.clearMessage}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <h6 style={{ color : "black",fontSize:"15px"}}>City:</h6>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Autocomplete
                      options={this.state.city}
                      style={{ width: 158, outlineColor: "black", display: "inline-block"}}
                      renderInput={(params) => <TextField {...params} variant="outlined" style={{ color: "black" }} id="gts_current_institute_city" name="gts_current_institute_city"  onSelect={this.onSelectCurrentCityHandler} size="small"/>}
                      disabled={!this.state.selectedCurrentCountry}
                      onChange={this.saveAutoCompleteCurrentCity}
                      onBlur={this.clearMessage}
                      noOptionsText='No options'
                    />
                 </InputGroup>
                 <InputGroup className="mb-3" size="sm">
                    <h6 style={{ color : "black",fontSize:"15px"}}>Name of University/Insititute:</h6>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Autocomplete
                      options={this.state.institute}
                      style={{ width: 410, outlineColor: "black", display: "inline-block"}}
                      renderInput={(params) => <TextField {...params} variant="outlined" style={{ color: "black" }} id="gts_current_degree_institute" name="gts_current_degree_institute" size="small"/>}
                      disabled={!this.state.gts_institute_current_city_id || this.state.gts_user_other_institute}
                      onChange={this.saveAutoCompleteCurrentInstitute}
                      onBlur={this.clearMessage}
                      noOptionsText='No options'
                    />
                 </InputGroup>
                 <InputGroup className="mb-3" size="sm">
                   <h6 style={{ color : "black",fontSize:"15px"}}>Other University/Insititute:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>
                    <input  type="text" name="gts_current_user_other_institute" id="gts_current_user_other_institute" disabled={this.state.gts_user_degree_current_institute_id} style={{width:410,color :(!this.state.gts_user_degree_current_institute_id)? "black" : "gray",border:"1px solid #D3D3D3",paddingLeft:"10px",paddingRight:"10px",borderRadius:"4px",height:"35px",paddingTop:"7px",paddingBottom:"7px"}}  placeholder="Enter institute name (if not in the above list)" name="gts_user_other_institute"  onChange={this.changeHandler} onBlur={this.clearMessage} />
                 </InputGroup>
                 <InputGroup className="mb-3" size="sm">
									 <h6 style={{ color : "black",fontSize:"15px"}}>Start Date(MM/YY):&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>
                   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                     <select style={{border:"1px solid #D3D3D3", height:"35px", width:"75px"}} id="currentStartMonth" onChange={this.selectCurrentStartMonth}>
                        <option selected  hidden disabled value="Month">Month</option>
                           {this.state.month.map(monthSelected => {
                              return (<option value={monthSelected}> {monthSelected} </option>)
                           })}
                     </select>
                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                     <select style={{border:"1px solid #D3D3D3", height:"35px", width:"75px"}} id="currentStartYear" onChange={this.selectCurrentStartYear}>
                        <option selected  hidden disabled value="Year">Year</option>
                          {this.state.year.map(yearSelected => {
                            return (<option value={yearSelected}> {yearSelected} </option>)
                          })}
                      </select>
                  </InputGroup>
                  <InputGroup className="mb-3" size="sm">
                  <h6 style={{ color : "black",fontSize:"15px"}}>End Date(MM/YY):&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>
                   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <select style={{border:"1px solid #D3D3D3", height:"35px", width:"75px"}} id="currentEndMonth" onChange={this.selectCurrentEndMonth}>
                    <option selected  hidden disabled value="Month">Month</option>
                      {this.state.month.map(monthSelected => {
                        return (<option value={monthSelected}> {monthSelected} </option>)
                      })}
                  </select>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <select style={{border:"1px solid #D3D3D3", height:"35px", width:"75px"}} id="currentEndYear" onChange={this.selectCurrentEndYear}>
                    <option selected hidden disabled value="Year">Year</option>
                      {this.state.year.map(yearSelected => {
                        return (<option value={yearSelected}> {yearSelected} </option>)
                       })}
                  </select>
                </InputGroup>
                <InputGroup className="mb-3" size="sm">
									<h6 style={{ color : "black",fontSize:"15px"}}>Percentage [1-100] /<br/> CGPA [1-10]:</h6>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									<input type="test" name="gts_current_user_degree_marks_percentage" id="gts_current_user_degree_marks_percentage" style={{border:"1px solid #D3D3D3",paddingLeft:"10px",paddingRight:"10px",borderRadius:"4px",height:"35px",paddingTop:"7px",paddingBottom:"7px"}} onChange={this.changeHandler} onBlur={this.clearMessage} size="14" ></input>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                	<h6 style={{ color : "black",fontSize:"15px"}}><label>Grades:</label></h6>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									<input type="test" name="gts_current_user_degree_grade" id="gts_current_user_degree_grade"  style={{border:"1px solid #D3D3D3",paddingLeft:"10px",paddingRight:"10px",borderRadius:"4px",height:"35px",paddingTop:"7px",paddingBottom:"7px"}} onChange={this.changeHandler} onFocus={this.clearMessage}  size="14" required></input>
								</InputGroup>
                                       </div>
                                       </div>
                                  </div>
                                  <div class="modal-footer">
                                     <div class="text-inline">
                                          <Button id ="test" type="button"  onClick={this.saveEducation} onBlur={this.validate} onFocus={this.clearMessage}>Save</Button>
                                         <Button type="button" class="close" data-dismiss="modal" >Cancel</Button>
                                     </div>
                                  </div>
                                  <div class="row">
                                  <span style={{color:"green"}}><center>{this.state.message}</center></span>
                                   <span style={{color:"red"}}><center>{this.state.error}</center></span>
                                  </div>
                             </div>
                         </div>
                     </div>

   {/* ------------------------------------------------------ComponentDidMount------------------------------------------------- */}

    <Row>
      <Col>
        <Container>
          <Col>
            <Container className="align-items-center">
              <div className="mt-3">
                <Form onSubmit={this.submitHandler}>
                  <Row>
                    {
						//alert("this.state.gts_user_educations.length: "+this.state.gts_user_educations.length);
                     this.state.gts_user_educations.map((education,index) => {
                      return(<>
                      { this.state.gts_user_educations.length > 0 &&
                      <Container className="border border-dark rounded">
                      <Row>
                        <Col>
                          <div class="row-sm m-0  text-left"><br/>
                            <InputGroup className="mb-3" size="sm">
                              <h6 style={{color : "black"}}><label> Name of Degree :</label></h6>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                               <input  type="text" name="gts_user_degree" id="gts_user_degree" disabled={this.state.gts_user_educations  && this.state.disableEducation ? 'disableEducation' : ''} style={{color:this.state.educations ? '' : 'gray',width:"498px" ,border:"1px solid #D3D3D3",paddingLeft:"10px",paddingRight:"10px",borderRadius:"4px",height:"35px",paddingTop:"7px",paddingBottom:"7px"}} defaultValue={education.gts_user_degree_name} value={education.gts_user_degree_name}></input>
                             </InputGroup>
                             <InputGroup className="mb-3" size="sm">
                               <h6  style={{color : "black"}}><label>Country:</label></h6>
                               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                               <input  type="text" name="gts_institute_country" id="gts_institute_country" disabled={this.state.gts_user_educations  && this.state.disableEducation ? 'disableEducation' : ''} style={{width:"158px",color:this.state.educations ? '' : 'gray',border:"1px solid #D3D3D3",paddingLeft:"10px",paddingRight:"10px",borderRadius:"4px",height:"35px",paddingTop:"7px",paddingBottom:"7px"}} defaultValue={education.gts_user_degree_country_name}></input>
                               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                               <h6 style={{color : "black"}}><label>City:
                               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <input  type="text" name="gts_institute_city" id="gts_institute_city" disabled={this.state.gts_user_educations} style={{width:"158px",color:this.state.educations ? '' : 'gray',border:"1px solid #D3D3D3",paddingLeft:"10px",paddingRight:"10px",borderRadius:"4px",height:"35px",paddingTop:"7px",paddingBottom:"7px"}} defaultValue={education.gts_user_degree_city_name}></input></label></h6>
                              </InputGroup>
                              <InputGroup className="mb-3" size="sm">
                                <h6 style={{color : "black"}}><label>Name of University/Insititute:</label></h6>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <input  type="text" name="gts_degree_institute" id="gts_degree_institute" disabled={this.state.gts_user_educations} style={{width:"498px",color:this.state.educations ? '' : 'gray',border:"1px solid #D3D3D3",paddingLeft:"10px",paddingRight:"10px",borderRadius:"4px",height:"35px",paddingTop:"7px",paddingBottom:"7px"}} defaultValue={education.gts_user_degree_institute_name}></input>
                              </InputGroup>
                              <InputGroup className="mb-3" size="sm">
										            <h6  style={{color : "black"}}><label>Other University/Insititute:</label></h6>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									              <input  type="text" name="gts_user_other_institute" id="gts_user_other_institute" disabled={this.state.selectedInstitute || this.state.gts_user_educations && this.state.disableEducation} style={{width:"498px", color :this.state.selectedInstitute || this.state.gts_user_educations && this.state.disableEducation? 'gray' :"black" ,border:"1px solid #D3D3D3",paddingLeft:"10px",paddingRight:"10px",borderRadius:"4px",height:"35px",paddingTop:"7px",paddingBottom:"7px",display : this.state.selectedInstitute ? 'none' : 'block'}}  name="gts_user_other_institute" defaultValue={education.gts_user_other_institute} onChange={this.changeHandler} onFocus={this.clearMessage} onClick={this.clearMessage}></input>
                              </InputGroup>
                              <InputGroup className="mb-3" size="sm">
										            <h6 style={{color : "black"}}><label>Start Date(MM/YY):</label></h6>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									              <input type="text"  name="gts_user_degree_start_date"  defaultValue={education.gts_user_degree_start_date ? Moment(education.gts_user_degree_start_date).format("MM") : ''} id="gts_user_degree_start_date" style={{color:this.state.gts_user_educations && this.state.disableEducation? 'gray' : 'black',width:"75px", border:"1px solid #D3D3D3",paddingLeft:"10px",paddingRight:"10px",borderRadius:"4px",height:"35px",paddingTop:"7px",paddingBottom:"7px"}}  disabled={this.state.gts_user_educations && this.state.disableEducation ? 'disableEducation' : ''} ></input>
									              &nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="text"  name="gts_user_degree_start_date"  defaultValue={education.gts_user_degree_start_date ? Moment(education.gts_user_degree_start_date).format("YYYY"): ''} id="gts_user_degree_start_date" style={{color:this.state.gts_user_educations && this.state.disableEducation? 'gray' : 'black',width:"75px", border:"1px solid #D3D3D3",paddingLeft:"10px",paddingRight:"10px",borderRadius:"4px",height:"35px",paddingTop:"7px",paddingBottom:"7px"}}  disabled={this.state.gts_user_educations && this.state.disableEducation ? 'disableEducation' : ''}></input>
                                </InputGroup>
                              <InputGroup className="mb-3" size="sm">
										            <h6  style={{color : "black"}}><label>End Date(MM/YY):</label></h6>
									              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="text"  name="gts_user_degree_end_date"  defaultValue={education.gts_user_degree_end_date ? Moment(education.gts_user_degree_end_date).format("MM") : ''} id="gts_user_degree_start_date" style={{color:this.state.gts_user_educations && this.state.disableEducation? 'gray' : 'black',width:"75px", border:"1px solid #D3D3D3",paddingLeft:"10px",paddingRight:"10px",borderRadius:"4px",height:"35px",paddingTop:"7px",paddingBottom:"7px"}}  disabled={this.state.gts_user_educations && this.state.disableEducation ? 'disableEducation' : ''} ></input>
									              &nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="text"  name="gts_user_degree_end_date"  defaultValue={education.gts_user_degree_end_date ? Moment(education.gts_user_degree_end_date).format("YYYY") : ''} id="gts_user_degree_start_date" style={{color:this.state.gts_user_educations && this.state.disableEducation? 'gray' : 'black',width:"75px", border:"1px solid #D3D3D3",paddingLeft:"10px",paddingRight:"10px",borderRadius:"4px",height:"35px",paddingTop:"7px",paddingBottom:"7px"}}  disabled={this.state.gts_user_educations && this.state.disableEducation ? 'disableEducation' : ''}></input>
                                </InputGroup>
                              <InputGroup className="mb-3" size="sm">
										           <h6  style={{color : "black"}}><label>Percentage / CGPA:</label></h6>
                               &nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									             <input type="text" name="gts_user_degree_marks_percentage" defaultValue={education.gts_user_degree_marks_percentage == 0 ? ' ' :education.gts_user_degree_marks_percentage} id="gts_user_degree_marks_percentage" onFocus={this.clearMessage} style={{color:this.state.gts_user_educations && this.state.disableEducation? 'gray' : 'black' ,border:"1px solid #D3D3D3",paddingLeft:"10px",paddingRight:"10px",borderRadius:"4px",height:"35px",paddingTop:"7px",paddingBottom:"7px"}} onChange={this.changeHandler} disabled={this.state.gts_user_educations && this.state.disableEducation ? 'disableEducation' : ''} size="14" onClick={this.clearMessage}></input>
                               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										          <h6 class="form-group" style={{color : "black"}}><label>Grades:</label></h6>
                              &nbsp;&nbsp;&nbsp;&nbsp;
									            <input type="test" name="gts_user_degree_grade" id="gts_user_degree_grade" defaultValue={education.gts_user_degree_grade} onFocus={this.clearMessage} class="form.control" style={{color:this.state.gts_user_educations && this.state.disableEducation? 'gray' : 'black' ,border:"1px solid #D3D3D3",paddingLeft:"10px",paddingRight:"10px",borderRadius:"4px",height:"35px",paddingTop:"7px",paddingBottom:"7px"}} onChange={this.changeHandler} disabled={this.state.gts_user_educations && this.state.disableEducation ? 'disableEducation' : ''} size="14" required></input>
									            </InputGroup>
                              <div className="row">
                                <div className="col-13 pr-0 pl-7" align="right"> <button type="button" class="btn float" href="#" style={{display: "inline"}} data-toggle="modal" data-target={"#editEducationDetails"+education.gts_user_education_id} onBlur={this.clearmessage} ><FontAwesomeIcon icon={faEdit} /></button>
                                    <button type="button" class="btn float" href="#" onClick={()=>this.deleteEducation(education.gts_user_education_id)} style={{display: "inline"}} onFocus={this.clearmessage}><FontAwesomeIcon icon={faTrash} /></button>
                                </div>
                             </div>
                            </div>
                          </Col>
                         </Row>
                        </Container>
                     }
                       <div class="row">
                       <h6 class="form-group" style={{color : "black"}}><label></label></h6>
                       </div>

   {/*--------------------------------------------------MODEL DIALOG TO EDIT EDUCATION-------------------------------------------------------------------------  */}
                                    <div id={"editEducationDetails"+education.gts_user_education_id} class="modal fade" role="dialog">
                                   <div class="modal-dialog modal-lg">
                                    <div class="modal-content">
                                 <div class="modal-header">
                                     <h5 class="modal-title"> Update Education</h5>
                                 </div>
                                 <div class="modal-body">
                                     <div className="container" >
                                     <div class="row-sm m-0  text-left">
                  <InputGroup className="mb-3" size="sm">
                    <h6 style={{ color : "black",fontSize:"15px"}}>Name of Degree:<span style={{fontSize:"15px", color: "red"}}><b>*</b></span></h6>
									     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                       <Autocomplete
                        renderTags={() => null}
                        options={this.state.degree}
                        style={{ width: 400, outlineColor: "black", display: "inline-block"}}
                        renderInput={(params) => <TextField {...params} id="gts_user_degree" variant="outlined" style={{ color: "black" }} name="gts_user_degree"  size="small" required/>}
                        onChange={this.saveAutoCompleteDegree}
                        onClick={this.clearMessage}
                        defaultValue={ education.gts_user_degree_name}
                        onBlur={this.validateUpdateDegree}
                        onFocus={this.clearUpdateDegreeError}
                        noOptionsText='No options'
                        disabled
                      />
                  </InputGroup>
                  <InputGroup className="mb-3" size="sm">
                    <h6  style={{ color : "black",fontSize:"15px"}}>Country:</h6>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Autocomplete
                      options={this.state.country}
                      style={{ width: 158, outlineColor: "black", display: "inline-block"}}
                      renderInput={(params) => <TextField {...params} id="gts_institute_country" variant="outlined"  style={{ color: "black" }} name="gts_institute_country" onSelect={this.onSelectCountryHandler} size="small"/>}
                      onChange={this.saveAutoCompleteCountry}
                      defaultValue={education.gts_user_degree_country_name}
                      noOptionsText='No options'
                      onBlur={this.clearMessage}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <h6 style={{ color : "black",fontSize:"15px"}}>City:</h6>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Autocomplete
                      options={this.state.city}
                      style={{ width: 158, outlineColor: "black", display: "inline-block"}}
                      renderInput={(params) => <TextField {...params} variant="outlined" style={{ color: "black" }} id="gts_institute_city" name="gts_institute_city"  onSelect={this.onSelectCityHandler} size="small"/>}
                      disabled={!this.state.selectedCountry}
                      onChange={this.saveAutoCompleteCity}
                      onBlur={this.clearMessage}
                      defaultValue={education.gts_user_degree_city_name}
                      noOptionsText='No options'
                    />
                 </InputGroup>
                 <InputGroup className="mb-3" size="sm">
                    <h6 style={{ color : "black",fontSize:"15px"}}>Name of University/Insititute:</h6>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Autocomplete
                      options={this.state.institute}
                      style={{ width: 400, outlineColor: "black", display: "inline-block"}}
                      disabled={!this.state.selectedCity}
                      renderInput={(params) => <TextField {...params} variant="outlined" style={{ color: "black" }} id="gts_degree_institute" name="gts_degree_institute" size="small"/>}
                      onChange={this.saveAutoCompleteInstitute}
                      onSelect={this.clearOtherInstitute}
                      onBlur={this.clearMessage}
                      defaultValue={education.gts_user_degree_institute_name}
                      noOptionsText='No options'
                    />
                 </InputGroup>
                 <InputGroup className="mb-3" size="sm">
                   <h6 style={{ color : "black",fontSize:"15px"}}>Other University/Insititute:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>
                   <input  type="text" name="gts_user_other_institute" id="gts_user_other_institute" disabled={this.state.selectedInstitute} style={{width:"400px", border:"1px solid #D3D3D3",paddingLeft:"10px",paddingRight:"10px",borderRadius:"4px",height:"35px",paddingTop:"7px",paddingBottom:"7px",display : this.state.selectedInstitute ? 'none' : 'block'}}  placeholder="Enter institute name (if not in the above list)" name="gts_user_other_institute" defaultValue={education.gts_user_other_institute} onChange={this.changeHandler} onBlur={this.clearMessage} onBlur={this.clearMessage}></input>
                 </InputGroup>
                 <InputGroup className="mb-3" size="sm">
									 <h6 style={{ color : "black",fontSize:"15px"}}>Start Date(MM/YY):&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>
                   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                   <select style={{border:"1px solid #D3D3D3", height:"35px", width:"75px"}} id="startMonth" onChange={this.selectStartMonth} onBlur={this.clearMessage}>
                    <option selected hidden disabled value="Month">{education.gts_user_degree_start_date ? Moment(education.gts_user_degree_start_date).format("MM") : 'Month'}</option>
                      {this.state.month.map(monthSelected => {
                         return (<option value={monthSelected} > {monthSelected} </option> )
                      })}
                    </select>
                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                     <select style={{border:"1px solid #D3D3D3", height:"35px", width:"75px"}} id="startYear" onChange={this.selectStartYear} onBlur={this.clearMessage}>
                        <option selected hidden disabled value="Year">{education.gts_user_degree_start_date? Moment(education.gts_user_degree_start_date).format("YYYY") : 'Year'}</option>
                          {this.state.year.map(yearSelected => {return (<option value={yearSelected} > {yearSelected} </option>)})}
                        </select>
                  </InputGroup>
                  <InputGroup className="mb-3" size="sm">
                  <h6 style={{ color : "black",fontSize:"15px"}}>End Date(MM/YY):&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>
                   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                   <select style={{border:"1px solid #D3D3D3", height:"35px", width:"75px"}} id="endMonth" onChange={this.selectEndMonth} onBlur={this.clearMessage}>
                     <option selected hidden disabled value="Month">{education.gts_user_degree_end_date ? Moment(education.gts_user_degree_end_date).format("MM"): 'Month'}</option>
                      {this.state.month.map(monthSelected => {
                         return (<option value={monthSelected}> {monthSelected} </option>)})
                         }
                    </select>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <select style={{border:"1px solid #D3D3D3", height:"35px", width:"75px"}} id="endYear" onChange={this.selectEndYear} onBlur={this.clearMessage}>
                    <option selected hidden disabled value="Year">{education.gts_user_degree_end_date ? Moment(education.gts_user_degree_end_date).format("YYYY") : 'Year'}</option>
                      {this.state.year.map(yearSelected => {
                        return (
                          <option value={yearSelected}> {yearSelected} </option>
                        )
                      })}
                   </select>
                </InputGroup>
                <InputGroup className="mb-3" size="sm">
									<h6 style={{ color : "black",fontSize:"15px"}}>Percentage [1-100] /<br/> CGPA [1-10]:</h6>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <input type="text" name="gts_user_degree_marks_percentage" defaultValue={education.gts_user_degree_marks_percentage} id="gts_user_degree_marks_percentage" onBlur={this.clearMessage} style={{border:"1px solid #D3D3D3",paddingLeft:"10px",paddingRight:"10px",borderRadius:"4px",height:"35px",paddingTop:"7px",paddingBottom:"7px"}} onChange={this.changeHandler} size="14" onBlur={this.clearMessage} onFocus={'Percentage in [1-100] or CPGA in [1-10]'} size="14" ></input>
								  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                	<h6 style={{ color : "black",fontSize:"15px"}}><label>Grades:&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                  <input type="test" name="gts_user_degree_grade" id="gts_user_degree_grade" defaultValue={education.gts_user_degree_grade} onBlur={this.clearMessage}  style={{border:"1px solid #D3D3D3",paddingLeft:"10px",paddingRight:"10px",borderRadius:"4px",height:"35px",paddingTop:"7px",paddingBottom:"7px"}} onChange={this.changeHandler} size="14" required></input>
								</InputGroup>
                </div>
               </div>
              </div>
            <div class="modal-footer">
                <div class="text-inline">
                   <Button id ="test" type="button" onClick={()=>this.updateEducation(education)} onFocus={this.clearMessage}>Save</Button>
                   <Button type="button" class="close" data-dismiss="modal" onClick={this.cancelHandler}>Cancel</Button>
                </div>
             </div>
              <div class= "row">
                <span style={{color:"green"}}><center>{this.state.updateMessage}</center></span>
                <span style={{color:"red"}}><center>{this.state.updateError}</center></span>
                <span style ={{color:"red"}}><center>{this.state.updateErrors}</center></span>
               </div>
            </div>
         </div>
       </div>
      </>)
     })}
    </Row>
                   </Form>
                 </div>
               </Container>
               </Col>
               </Container>
               </Col>
               </Row>
            </div>
         </div>
        </div>
      </Container>
    );
  }
}

export default EducationComponent;

