import React, { Component } from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import ls from 'local-storage';
import axios from 'axios';
import Moment from 'moment';
import {FormControl, InputGroup,Dropdown,Accordion ,Card,NavDropdown} from 'react-bootstrap';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { endpoints_properties } from '../../properties/EndPointsProperties.js';
import { api_properties } from '../../properties/APIProperties.js';

var userLoginDetails=ls.get('userLoginDetails');
if(userLoginDetails !=null){
var gts_user_id = userLoginDetails.user_id;
}
var token = ls.get('token');
var userDetail = '';
var userPersonalDetails=ls.get('userPersonalDetails');

class SCServiceActivityComponent extends Component {

  constructor(props) {
    super(props)

    this.state = {
      listOfServiceActivity:[],
    }
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  componentDidMount() {
    var appliedJobIDs=[];
    var jobURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_SERVICE_POST_ACTIVITY+"/"+gts_user_id;
    axios.get(jobURL,{ headers: {"Auth_Token" : `Bearer ${token}`} })
    .then(response =>{
        console.log(response.data)
        this.setState({listOfServiceActivity : response.data})
    })
    .catch(error => {
       this.setState({status:400})
   })
  }

  render(){
    return(
      <div className="mt-2">
        <div className="container align-jobAppls-center">
         <h5 class="p-1">JOB POST ACTIVITY</h5>
         <div className="border border-dark  rounded-lg">
           {this.state.listOfServiceActivity.map((job, index)=>(
           <InputGroup>
              <div style={{ display: 'block', width: 1150, padding: 15 }}>
                <Accordion defaultActiveKey="1">
                 <Card>
                   <Card.Header>
                      <div class="row" >
                       <div class="col-2">
                          <h6 style={{fontSize:"14px", color:'black'}}><b>Service ID: </b><span style={{color:'red'}}> {job.jobID}</span></h6>
                        </div>
                        <div class="col-9">
                            <h6 style={{fontSize:"14px", color:'black'}}><b>Service Name: </b><span style={{color:'red'}}>{job.jobTitleName.toUpperCase()}</span></h6>
                        </div>
                        <div class="col">
                          <Accordion.Toggle eventKey= "0" aria-controls="additional-actions3-content" aria-label="Expand" >
                            <i text-align="right" id="btnGetCount" value="Count Rows"  aria-hidden="true"> <ExpandMoreIcon /></i>
                          </Accordion.Toggle>
                        </div>
                     </div>
                   </Card.Header>
                   <Accordion.Collapse eventKey="0" >
                      <Card.Body>
                        <div class="row">
                            <div class="col-9">
                              <h6 style={{fontSize:"14px", color:'black'}}><b>Max. number of service positions:&nbsp;</b><span class="pl-1" style={{color:'red'}}>{job.jobMaxNumberOfSeats}</span></h6>
                              <h6 style={{fontSize:"14px", color:'black'}}><b>Number of candidates applied:&nbsp;&nbsp;&nbsp;</b><span class="pl-3" style={{color:'red'}}>{job.noOfCandidatesApplied}</span></h6>
                              <h6 style={{fontSize:"14px", color:'black'}}><b>Number of candidates rejected:&nbsp;&nbsp;</b><span class="pl-3"  style={{color:'red'}}>{job.noOfCandidatesRejected}</span></h6>
                              <h6 style={{fontSize:"14px", color:'black'}}><b>Number of candidates screening:&nbsp;</b><span class="pl-2" style={{color:'red'}}>{job.noOfCandidatesInScreening}</span></h6>
                              <h6 style={{fontSize:"14px", color:'black'}}><b>Number of candidates offered:&nbsp;&nbsp;&nbsp;</b><span class=" pl-3"  style={{color:'red'}}>{job.noOfCandidatesOffered}</span></h6>
                           </div>
                           <div class="col" style={{align:"right"}}>
                           <button
                              className="btn btn-primary"
                              disabled
                              // onClick={()=>this.applyForJob("SAVED",item.gts_job_id)}
                              style={{backgroundColor:"white",color:"white", align:"right", borderRadius:"15px", fontSize:"12px"}}>
                              <b>View Candidate Enrollment</b>
                              </button>
                           </div>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
            </InputGroup>))}
          </div>
       </div>
     </div>
    )
  }
}
export default SCServiceActivityComponent;