import React from 'react';
import ls from 'local-storage';

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert,
  ButtonGroup
} from 'reactstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {Card, CardBody,CardText, FormControl, FormLabel,FormCheck } from 'react-bootstrap';
import { endpoints_properties } from '../../properties/EndPointsProperties.js';
import { api_properties } from '../../properties/APIProperties.js';

var userLoginDetails=ls.get('userLoginDetails');
var token=ls.get('token');
class UserRolesComponent extends React.Component {

  componentDidMount() {
    axios.get(endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_USER_ROLES_USER_ID+'/'+userLoginDetails.user_id,{ headers: {"Auth_Token" : `Bearer ${token}`} })
    .then(response => {
      console.log(response.data)
      for(var i=0;i<response.data.length;i++){
        if(response.data[i].gts_user_role_is_primary===true){
          if(response.data[i].gts_role_name == "SERVICE_PROVIDER"){
            this.setState({isServiceProvider : true,
            defaultRole : "ServiceProvider",
            user_primary_role_id : response.data[i].gts_user_role_id})
          }

          else if(response.data[i].gts_role_name == "SERVICE_CONSUMER"){
            this.setState({isServiceConsumer : true,
              defaultRole : "ServiceConsumer",
              user_primary_role_id : response.data[i].gts_user_role_id})
          }

          else if(response.data[i].gts_role_name == "TRAINER"){
            this.setState({isTrainer : true,
            defaultRole: "Trainer",
            user_primary_role_id : response.data[i].gts_user_role_id})
          }

          else if(response.data[i].gts_role_name == "TRAINER"){
            this.setState({isRecruiter : true,
            defaultRole: "Recruiter",
            user_primary_role_id : response.data[i].gts_user_role_id})
          }

          else if(response.data[i].gts_role_name == "TRAINEE"){
            this.setState({isTrainee : true,
              defaultRole: "Trainee",
              user_primary_role_id : response.data[i].gts_user_role_id})
          }
        }
        else{
          if(response.data[i].gts_role_name == "SERVICE_PROVIDER"){
            this.setState({isSPOtherRole : true,
            otherRoleSP : "ServiceProvider"})
          }

          else if(response.data[i].gts_role_name == "SERVICE_CONSUMER"){
            this.setState({isSCOtherRole : true,
              otherRoleSC : "ServiceConsumer"})
          }

          else if(response.data[i].gts_role_name == "TRAINER"){
            this.setState({isTrainerOtherRole : true,
              otherRoleTrainer: "Trainer"})
          }

          else if(response.data[i].gts_role_name == "RECRUITER"){

            this.setState({isRecruiterOtherRole : true,
              otherRoleRecruiter: "Trainer"})
          }

          else if(response.data[i].gts_role_name == "TRAINEE"){
            this.setState({isTraineeOtherRole : true,
              otherRoleTrainee: "Trainee"})
          }
        }
      }
    })
  }

  constructor(props) {
    super(props);
    this.state = {
      user:0,
      isServiceProvider: false,
      isServiceConsumer: false,
      isTraineeOtherRole: false,
      isTrainerOtherRole: false,
      isSPOtherRole: false,
      isSCOtherRole: false,
      isRecruiterOtherRole: false,
      isTrainer: false,
      isTrainee: false,
      isRecruiter: false,
      isAdmin: false,
      defaultRole:'none',
      otherRoleTrainee: '',
      otherRoleTrainer: '',
      otherRoleSC: '',
      otherRoleSP: '',
      otherRoleRecruiter: '',
      gts_user_id:userLoginDetails.user_id,
      email: '',
      password: '',
      role:'',
      confirmPassword: '',
      registrationError: '',
      registrationSuccess: '',
      buttonDisabled: false,
      message: '',
      isError: '',
      errors: {},
      responses: {},
      visible: false,
      roleError:'',
      roleSuccess:'',
      user_primary_role_id:'',
      role_id:'',
      changed: false
   };
    this.onRadioChange = this.onRadioChange.bind(this);
  }

  clearMessage = () =>{
    let roleSuccess=this.state.roleSuccess;
    let roleError=this.state.roleError;

    roleSuccess = "";
    roleError = "";

    if(this.state.roleSuccess){
      this.setState({roleSuccess : roleSuccess})
    }

    else if(this.state.roleError){
      this.setState({roleError : roleError})
    }
  }

  changePrimaryRole = (gts_role_id,new_primary_role) => {
    var put_payload={
      gts_user_role_id: this.state.user_primary_role_id,
      gts_user_id: this.state.gts_user_id,
      gts_role_id: gts_role_id,
      gts_user_role_is_primary:1
    }

    if(this.state.user_primary_role_id==0){
      var post_payload={
        gts_user_id: this.state.gts_user_id,
        userRole: [{
          gts_role_id: gts_role_id,
          gts_user_role_is_primary:1
        }]
      }
      axios.post(endpoints_properties.ENDPOINT_PROD+api_properties.API_ADD_ROLES,post_payload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
      .then(response =>{
        this.setState({roleSuccess: "User primary role added successfully",
        changed: true})
        this.state.changed = true;
        console.log(response.data.message)

      })
      .catch(error => {
        this.setState({roleError: "Not able to add user other role"})
        console.log(error.response.data.message)
      })

    }
    else{
      axios.put(endpoints_properties.ENDPOINT_PROD+api_properties.API_ADD_ROLES,put_payload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
      .then(response =>{
        this.state.changed = true;
        if(response.data.status_code ===  "200"){
        this.setState({
          roleSuccess: "User primary role updated successfully",
          status_code: "201",
          changed: true
        })
        window.setTimeout(function() {
          window.location.href = '/gts/login';
        },300);

        }
        else{
          this.setState({roleSuccess: "Not able to update user primary role"})
          window.setTimeout(function() {
            window.location.href = '/gts/login';
          },300);
        }
        console.log(response.data.message)

      })
      .catch(error => {
        this.setState({roleError: "Not able to update user primary role"})
        console.log(error.response.data.message)

      });
    }
  }

  onRadioChange = (e) => {
    if (e.target.value == "ServiceProvider") {
      this.setState({
        defaultRole: e.target.value,
        isServiceProvider: true,
        isServiceConsumer: false,
        isTrainer: false,
        isTrainee: false,
        isRecruiter: false,
      });

      if(this.state.isSPOtherRole==true){
        this.deleteOtherRole(2);
      }
      else{
        this.changePrimaryRole(2,"SERVICE_PROVIDER");
      }

    }

    if (e.target.value == "ServiceConsumer") {
      this.setState({
        defaultRole: e.target.value,
        isServiceConsumer: true,
        isServiceProvider: false,
        isTrainer: false,
        isTrainee: false,
        isRecruiter: false,
      });

      if(this.state.isSCOtherRole === true){
        this.deleteOtherRole(3);
      }
      else{
          this.changePrimaryRole(3,'SERVICE_CONSUMER');
      }
    }

    if (e.target.value == "Trainer") {
      console.log(e.target.checked)
      this.setState({
        defaultRole: e.target.value,
        isTrainer: true,
        isServiceConsumer: false,
        isServiceProvider: false,
        isTrainee: false,
        isRecruiter: false,
      });

      if(this.state.isTrainerOtherRole === true){
        this.deleteOtherRole(4);
      }
      else{
          this.changePrimaryRole(4,'TRAINER');
        }
    }

    if (e.target.value == "Recruiter") {
      this.setState({
        defaultRole: e.target.value,
        isRecruiter: true,
        isTrainee: false,
        isServiceConsumer: false,
        isServiceProvider: false,
        isTrainer: false
      });

      if(this.state.isRecruiterOtherRole === true){
        this.deleteOtherRole(5);
      }
      else{
        this.changePrimaryRole(5,'RECRUITER');
      }
    }

    if (e.target.value == "Trainee") {
      this.setState({
        defaultRole: e.target.value,
        isTrainee: true,
        isServiceConsumer: false,
        isServiceProvider: false,
        isTrainer: false,
        isRecruiter: false,
      });

      if(this.state.isTraineeOtherRole === true){
        this.deleteOtherRole(6);
      }
      else{
          this.changePrimaryRole(6,'TRAINEE');
      }
    }
  }

  addOtherRole = (other_role_id) =>{
    var post_payload={
      gts_user_id: this.state.gts_user_id,
      userRole: [{
        gts_role_id: other_role_id,
        gts_user_role_is_primary:0
      }]
    }
    axios.post(endpoints_properties.ENDPOINT_PROD+api_properties.API_ADD_ROLES,post_payload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
    .then(response =>{
      this.setState({roleSuccess: "User other role added successfully"})
      console.log(response.data.message)
      if(other_role_id == 2){
        this.setState({isSPOtherRole : true,
        otherRoleSP : "ServiceProvider"})
      }

      else if(other_role_id == 3){
        this.setState({isSCOtherRole : true,
          otherRoleSC : "ServiceConsumer"})
      }

      else if(other_role_id == 4){
        this.setState({isTrainerOtherRole : true,
          otherRoleTrainer: "Trainer"})
      }

      else if(other_role_id == 5){

        this.setState({isRecruiterOtherRole : true,
          otherRoleRecruiter: "Trainer"})
      }

      else if(other_role_id == 6){
        this.setState({isTraineeOtherRole : true,
          otherRoleTrainee: "Trainee"})
      }
    })
    .catch(error => {
      this.setState({roleError: "Not able to add user other role"})
      console.log(error.response.data.message)
    })
  }

  deleteOtherRole = (other_role_id) =>{
    axios.delete(endpoints_properties.ENDPOINT_PROD+'/api/v1/user/role/user_id/'+this.state.gts_user_id+'/role_id/'+other_role_id,{ headers: {"Auth_Token" : `Bearer ${token}`} })
    .then(response =>{
      this.setState({roleSuccess: "User other role deleted successfully"})

      if(other_role_id == 2){
        this.changePrimaryRole(2,"SERVICE_PROVIDER");
       this.state.isSPOtherRole = false
       this.setState({
        isServiceProvider: false,
        isSPOtherRole : false
      })
      }

      if(other_role_id == 3){
        this.changePrimaryRole(3,'SERVICE_CONSUMER');
        this.state.isSCOtherRole = false
        this.setState({
          isServiceConsumer: false,
          isSCOtherRole : false
        })
       }

       if(other_role_id == 4){
        this.changePrimaryRole(4,'TRAINER');
        this.state.isTrainerOtherRole = false
        this.setState({
          isTrainer: false,
          isTrainerOtherRole : false
        })
       }

       if(other_role_id == 5){
        this.changePrimaryRole(5,'RECRUITER');
        this.state.isRecruiterOtherRole = false
        this.setState({
          isRecruiter: false,
          isRecruiterOtherRole : false
        })
       }

       if(other_role_id == 6){
        this.changePrimaryRole(6,'TRAINEE');
        this.state.isTraineeOtherRole = false
        this.setState({
          isTrainee: false,
          isTraineeOtherRole : false
        })
       }

    })
    .catch(error => {
      this.setState({roleError: "Not able to delete user other role"})
      console.log(error.response.data.message)
    })
  }

  toggleChangeServiceProvider = (e) => {
    this.setState(prevState => ({ isServiceProvider: !prevState.isServiceProvider}));
    if(e.target.checked === true){
      this.setState({isSPOtherRole : true})
      this.addOtherRole(2);
    }

    else if(e.target.checked === false){
      this.deleteOtherRole(2);
    }
  }

  toggleChangeServiceConsumer = (e) => {
   this.setState(prevState => ({isServiceConsumer: !prevState.isServiceConsumer}));
   if(e.target.checked === true){
    this.setState({isSCOtherRole : true})
     this.addOtherRole(3);
    }

    else if(e.target.checked === false){
     this.deleteOtherRole(3);
    }
  }

  toggleChangeRecruiter = (e) => {
    this.setState(prevState => ({isRecruiter: !prevState.isRecruiter}));
    if(e.target.checked === true){
    this.setState({isRecruiterOtherRole : true})
      this.addOtherRole(5);
    }
    else if(e.target.checked === false){
      this.deleteOtherRole(5);
    }
  }

  toggleChangeTrainer = (e) => {
    this.setState(prevState => ({isTrainer: !prevState.isTrainer}));
    if(e.target.checked === true){
    this.setState({isTrainerOtherRole : true})
      this.addOtherRole(4);
    }
    else if(e.target.checked === false){
      this.deleteOtherRole(4);
    }
  }

  toggleChangeTrainee = (e) => {
    this.setState(prevState => ({isTrainee: !prevState.isTrainee}));
    if(e.target.checked === true){
    this.setState({isTraineeOtherRole : true,
    })
      this.addOtherRole(6);
    }
    else if(e.target.checked === false){
      this.deleteOtherRole(6);
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    console.log(this.state);
  }

  inputOnChangeHandler = e => {
    this.setState({ [e.target.name]: e.target.value });
  };


 render() {
    const errorMessageStyles = {
      color: "#ff4d4d"
    }

    const bgImage = {
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat'
    }

    const successMessageStyles = {
      color: "#008000",
       marginLeft: '-25%'
    }

    return (
    <>
        <main ref="main">
          <div class="d-flex justify-content-around">
            <div className="mt-3" >
              <Container>
                <Row className="justify-content-center mt-7">
                  <Col lg="">
                    <Card border="secondary" style={{ width: '26rem' }}>
                    <Card.Body>
                      <form>

                        <div class="row" align="center">
                          <h5><label style={{fontSize:"22px"}}><b><center>Role Settings</center></b></label></h5>
                        </div>

                        <div class="row" style={{borderBottomColor: 'black',borderBottomWidth: 1.5}} />
                        <br/>

                        <dl>
                          <dd><strong>Select your current roles(You can have multiple,<br/>
                            but please state one role as your default as well):
                          </strong></dd>
                        </dl>

                            {/* ------------SERVICE PROVIDER---------- */}
                        <div className=" checkbox">
                            <div class="row">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <div class="col">
                                <label className="form-check-label">
                                  <input type="checkbox"
                                  checked={this.state.isServiceProvider || this.state.isSPOtherRole==true}
                                  onChange={this.toggleChangeServiceProvider}
                                  className="form-check-input"
                                  onBlur= {this.clearMessage}
                                  disabled={this.state.defaultRole === "ServiceProvider"}
                                  />
                                <i>Service Provider</i>
                                </label>
                            </div>
                            <div class="col offset-2">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <label>
                                  <input type="radio" value="ServiceProvider"
                                    checked={this.state.defaultRole === "ServiceProvider"}
                                    onChange={this.onRadioChange}
                                    onBlur= {this.clearMessage}
                                  />
                                  Default
                              </label>
                            </div>
                          </div>
                        </div>
                        <br/>

                            {/* ------------SERVICE CONSUMER---------- */}
                        <div className=" checkbox">
                            <div class="row">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <div class="col">
                                <label className="form-check-label">
                                  <input type="checkbox"
                                    checked={this.state.isServiceConsumer || this.state.isSCOtherRole}
                                    onChange={this.toggleChangeServiceConsumer}
                                    className="form-check-input"
                                    onBlur= {this.clearMessage}
                                    disabled={this.state.defaultRole === "ServiceConsumer"}
                                  />
                                  <i>Service Consumer</i>
                              </label>
                            </div>
                            <div class="col offset-2">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <label>
                                  <input type="radio" value="ServiceConsumer"  checked={this.state.defaultRole === "ServiceConsumer"}
                                    onChange={this.onRadioChange}
                                    checked={this.state.defaultRole === "ServiceConsumer"}
                                    onBlur= {this.clearMessage}
                                  />
                                  Default
                              </label>
                            </div>
                          </div>
                        </div>
                        <br/>

                             {/* ------------RECRUITER---------- */}
                        { (userLoginDetails.primary_role =="ADMIN")?
                        <div className=" checkbox">
                            <div class="row">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <div class="col">
                                <label className="form-check-label">
                                  <input type="checkbox"
                                    checked={this.state.isRecruiter || this.state.isRecruiterOtherRole}
                                    onChange={this.toggleChangeRecruiter}
                                    className="form-check-input"
                                    onBlur= {this.clearMessage}
                                    disabled={this.state.defaultRole === "Recruiter"}
                                  />
                                  <i>Recruiter</i>
                                </label>
                            </div>
                            <div class="col offset-2">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <label>
                                  <input type="radio" value="Recruiter"  checked={this.state.defaultRole === "Recruiter"}
                                    onChange={this.onRadioChange}
                                    checked={this.state.defaultRole === "Recruiter"}
                                    onBlur= {this.clearMessage}
                                />
                                Default
                                </label>
                            </div>
                          </div>
                        </div>
                        :' '}

                           {/* ------------TRAINER---------- */}
                        <div className=" checkbox">
                          <div class="row">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <div class="col">
                                <label className="form-check-label">
                                  <input type="checkbox"
                                    checked={this.state.isTrainer || this.state.isTrainerOtherRole == true}
                                    onChange={this.toggleChangeTrainer}
                                    className="form-check-input"
                                    onBlur= {this.clearMessage}
                                    disabled={this.state.defaultRole === "Trainer"}
                                  />
                                  <i>Trainer</i>
                                </label>
                            </div>
                            <div class="col offset-2">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <label>
                                <input type="radio" value="Trainer"  checked={this.state.defaultRole === "Trainer"}
                                  onChange={this.onRadioChange}
                                  checked={this.state.defaultRole === "Trainer"}
                                  onBlur= {this.clearMessage}
                                />
                                Default
                              </label>
                            </div>
                          </div>
                        </div>
                        <br/>

                           {/* ------------TRAINEE---------- */}
                        <div className=" checkbox">
                            <div class="row">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <div class="col">
                                <label className="form-check-label">
                                <input type="checkbox"
                                    checked={this.state.isTrainee || this.state.isTraineeOtherRole == true}
                                    onChange={this.toggleChangeTrainee}
                                    className="form-check-input"
                                    onBlur= {this.clearMessage}
                                    disabled={this.state.defaultRole === "Trainee"}
                                />
                                  <i>Trainee</i>
                              </label>
                            </div>
                            <div class="col offset-2">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <label>
                                  <input type="radio" value="Trainee"  checked={this.state.defaultRole === "Trainee"}
                                    onChange={this.onRadioChange}
                                    checked={this.state.defaultRole === "Trainee"}
                                    onBlur= {this.clearMessage}
                                />
                                Default
                              </label>
                            </div>
                          </div>
                        </div>

                        <FormGroup className="mb-3" align="center">
                          <div style={{color:"red"}}>{this.state.roleError}</div>
                        </FormGroup>

                        <FormGroup className="mb-3"  align="center">
                          <div style={{color:"green"}}>{this.state.roleSuccess}</div>
                        </FormGroup>
                      </form>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </main>
    </>
    );
  }
}

export default UserRolesComponent;



