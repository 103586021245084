import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import ls from 'local-storage';
import axios from 'axios';
import {Link } from 'react-router-dom';
import { Popover,  OverlayTrigger, FormControl, InputGroup,Dropdown, NavDropdown} from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SwitchRole from '../common/SwitchRole';

var userLoginDetails=ls.get('userLoginDetails');
var token = ls.get('token');
var userDetail = '';
var userPersonalDetails=ls.get('userPersonalDetails');


class ServiceProviderMenu extends Component {

  constructor(props) {
		if(token==null || token=="" || token=="undefined"){
		  window.location.href = '/gts/global-home-page';
		}

    super(props)

    this.state = {
      role_name:'',
      errors: {},
      isUserDetailExisting:false,
      first_name:'',
      last_name:'',
      userDetail:{}
    }
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  componentDidMount() {
    if(userPersonalDetails!== null){
    if( userPersonalDetails.gts_user_id == userLoginDetails.user_id){
      this.setState({first_name : userPersonalDetails.gts_user_first_name})
      this.setState({last_name : userPersonalDetails.gts_user_last_name})
      }
    }
  }

  setUserId = (userID)=>{
    ls.set('gts_user_id', userID);
  }

render()
{
  const {
   user_name,
   user_Detail
  }=this.state;
    var roles=[];
    roles=userLoginDetails.other_roles.split(',');

    return(
      <div>
       <div className="container-fluid">
         <InputGroup>
           <NavDropdown title={<span style={{color:'black',fontSize:"13px", paddingRight:"5px"}}><b>Profile</b></span>} >
              <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/service-provider-profile"><span style={{fontSize:"13px"}}><b>Personal Details</b></span></NavDropdown.Item>
              <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/user-projects"><span style={{fontSize:"13px"}}><b>Projects</b></span></NavDropdown.Item>
              <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/user-work-experience"><span style={{fontSize:"13px"}}><b>Work Experience</b></span></NavDropdown.Item>
              <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/user-skills"><span style={{fontSize:"13px"}}><b>Skills</b></span></NavDropdown.Item>
              <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/user-educations"><span style={{fontSize:"13px"}}><b>Education</b></span></NavDropdown.Item>
              <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/user-languages"><span style={{fontSize:"13px"}}><b>Language</b></span></NavDropdown.Item>
              <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/user-salary"><span style={{fontSize:"13px"}}><b>Salary</b></span></NavDropdown.Item>
              <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/user-addresses"><span style={{fontSize:"13px"}}><b>Address</b></span></NavDropdown.Item>
              <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/notice-period"><span style={{fontSize:"13px"}}><b>Notice Period</b></span></NavDropdown.Item>
              <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/your-profile-viewer"><span style={{fontSize:"13px"}}><b>Your Profile Viewer</b></span></NavDropdown.Item>
              <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/manage-roles"><span style={{fontSize:"13px"}}><b>Roles</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/change-password"><span style={{fontSize:"11px"}}><b>CHANGE PASSWORD</b></span></NavDropdown.Item>
           </NavDropdown>

           <NavDropdown title={<span style={{color:'black',fontSize:"13px", paddingRight:"5px"}}><b>Skill Asessment</b></span>} >
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/self-assessment"><span style={{fontSize:"13px"}}><b>Self Assessment</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/chakuri-assessment"><span style={{fontSize:"13px"}}><b>GTS Assessment</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/employer-assessment"><span style={{fontSize:"13px"}}><b>Employer Assessment</b></span></NavDropdown.Item>
           </NavDropdown>

           <NavDropdown title={<span style={{color:'black',fontSize:"13px", paddingRight:"5px"}}><b>Services/Jobs</b></span>} >
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/recommended-services"><span style={{fontSize:"13px"}}><b>Recommended Services/Jobs</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/saved-services"><span style={{fontSize:"13px"}}><b>Saved Services/Jobs</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/consult-with-recruiter"><span style={{fontSize:"13px"}}><b>Consult with Recruiter</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/sp-search-services"><span style={{fontSize:"13px"}}><b>Search Services/Jobs</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/wish-for-service"><span style={{fontSize:"13px"}}><b>Wish for Services/Jobs</b></span></NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title={<span style={{color:'black',fontSize:"13px"}}><b>Training</b></span>} >
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/search-training"><span style={{fontSize:"13px"}}><b>Search Trainings</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/recommended-training"><span style={{fontSize:"13px"}}><b>Recommended Trainings</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/training-in-progess"><span style={{fontSize:"13px"}}><b>Trainings in Progress</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/saved-training"><span style={{fontSize:"13px"}}><b>Saved Trainings</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/training-completed"><span style={{fontSize:"13px"}}><b>Trainings Completed</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/wish-for-training"><span style={{fontSize:"13px"}}><b>Wish for Training</b></span></NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title={<span style={{color:'black',fontSize:"13px", paddingRight:"5px"}}><b>Service/Job Applications</b></span>} >
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/active-job-applications"><span style={{fontSize:"13px"}}><b>Active Service/Job Applications</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/closed-job-applications"><span style={{fontSize:"13px"}}><b>Closed Service/Jobs Applications</b></span></NavDropdown.Item>
          </NavDropdown>

           <NavDropdown title={<span style={{color:'black',fontSize:"13px"}}><b>Payment</b></span>} >
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/make-payment"><span style={{fontSize:"13px"}}><b>Make Payment</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/payment-history"><span style={{fontSize:"13px"}}><b>Payment History</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/employment-agreement"><span style={{fontSize:"13px"}}><b>Employment Agreements</b></span></NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title={<span style={{color:'black',fontSize:"13px"}}><b>Wish List</b></span>} >
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/training-wishes"><span style={{fontSize:"13px"}}><b>Training Wishes</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/job-wishes"><span style={{fontSize:"13px"}}><b>Service/Job Wishes</b></span></NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title={<span style={{color:'black',fontSize:"13px"}}><b>Notifications</b></span>} >
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/notifications"><span style={{fontSize:"13px"}}><b>Notifications</b></span></NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title={<span style={{color:'black',fontSize:"13px"}}><b>Messages</b></span>} >
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/messages"><span style={{fontSize:"13px"}}><b>Messages</b></span></NavDropdown.Item>
          </NavDropdown>
          <span style={{fontWeight: 'bold'}}>Current Role:</span>&nbsp;&nbsp; <span style={{fontWeight: 'bold', color: 'red'}}>{userLoginDetails.primary_role}</span>&nbsp;&nbsp;
          <SwitchRole/>
       </InputGroup>
       <div style={{borderBottomColor: 'black',borderBottomWidth: 3}} />
     </div>
   </div>
  )
}}
export default ServiceProviderMenu;