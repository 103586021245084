// import React from 'react';
// import {Link } from 'react-router-dom';
// import { Button } from 'react-bootstrap';
// import ls from 'local-storage';
// import { BrowserRouter, Switch, Route } from 'react-router-dom';
// import  { Redirect } from 'react-router-dom'

// var userLoginDetails=ls.get('userPersonalDetails');
// var other_roles=ls.get('other_roles');

// class SwitchRole extends React.Component {

//     render(){
//         var roles=[];
//         roles=other_roles.split(',');
//      return (
//         <div>
//             <button type="button" class="btn btn-primary btn-sm btn-center" data-toggle="modal" data-target="#switchmode" ><span style={{fontWeight: 'bold'}}>Switch Role</span></button>
//             <div id="switchmode" class="modal fade" role="dialog"  maxWidth={'md'}>
//                 <div class="modal-dialog"  maxWidth={'md'}>
//                 <div class="modal-content">
//                         <div class="modal-header">
//                             <h5 class="modal-title"> Switch to Role</h5>
//                         </div>
//                         <div>
//                             <h6 class="modal-title">&nbsp;&nbsp;Primary Role&nbsp;:</h6>
//                             <a href={"/gts/"+userLoginDetails.primary_role.toLowerCase().replace(/_/g,'-')+"-profile"} style={{ color: "blue", textDecoration: "underline" }} >
//                                <center>{userLoginDetails.primary_role}</center><br/>
//                             </a>
//                             <h6 class="modal-title">&nbsp;&nbsp;Secondary Role&nbsp;:</h6>
//                             {roles.map(name => (
//                             <a href={"/gts/"+name.toLowerCase().replace(/_/g,'-')+"-profile"} style={{ color: "blue", textDecoration: "underline" }} >
//                                 <center>{name}<br/></center>
//                             </a>
//                             ))}
//                         </div>
//                         <div class="modal-footer">
//                             <div class="text-inline">
//                             <Button type="button" class="close" data-dismiss="modal" onClick={this.cancelHandler}>Cancel</Button>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//      )
//     }
// }
// export default SwitchRole;

import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import ls from 'local-storage';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

class SwitchRole extends React.Component {
  constructor(props) {
    super(props);
    this.cancelHandler = this.cancelHandler.bind(this);
  }

  cancelHandler() {
    // Handle cancel action
  }

  render() {
    const userLoginDetails = ls.get('jsonPayLoad');
    const other_roles = ls.get('other_roles');

    if (!userLoginDetails || !userLoginDetails.primary_role) {
      return <div>Error: Primary role is missing.</div>;
    }

    let roles = [];
    if (other_roles) {
      roles = other_roles.split(',');
    }

    return (
      <div>
        <button type="button" className="btn btn-primary btn-sm btn-center" data-toggle="modal" data-target="#switchmode">
          <span style={{ fontWeight: 'bold' }}>Switch Role</span>
        </button>
        <div id="switchmode" className="modal fade" role="dialog" maxWidth={'md'}>
          <div className="modal-dialog" maxWidth={'md'}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Switch to Role</h5>
              </div>
              <div>
                <h6 className="modal-title">&nbsp;&nbsp;Primary Role&nbsp;:</h6>
                <a href={`/gts/${userLoginDetails.primary_role.toLowerCase().replace(/_/g, '-')}-profile`} style={{ color: 'blue', textDecoration: 'underline' }}>
                  <center>{userLoginDetails.primary_role}</center><br />
                </a>
                <h6 className="modal-title">&nbsp;&nbsp;Secondary Role&nbsp;:</h6>
                {roles.map((name, index) => (
                  <a key={index} href={`/gts/${name.toLowerCase().replace(/_/g, '-')}-profile`} style={{ color: 'blue', textDecoration: 'underline' }}>
                    <center>{name}<br /></center>
                  </a>
                ))}
              </div>
              <div className="modal-footer">
                <div className="text-inline">
                  <Button type="button" className="close" data-dismiss="modal" onClick={this.cancelHandler}>Cancel</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SwitchRole;
