import React from 'react';
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';
import IndustryComponent from '../../components/common/IndustryComponent';

class IndustryPage extends React.Component{
    render(){
        return(
            <>
                <Header />
                <IndustryComponent />
                <Footer />
            </>
        )
    }
}
export default IndustryPage;