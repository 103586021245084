import React, { Component } from 'react'
import '../../App.css';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import 'bootstrap/dist/css/bootstrap.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faTrash, faEdit, faUpload } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { MDBCol, MDBInput, MDBTable } from "mdbreact";
import ls from 'local-storage';
import { endpoints_properties } from '../../properties/EndPointsProperties.js';
import { api_properties } from '../../properties/APIProperties.js';
import {Button, Dropdown, Card, CardBody,CardText ,FormControl,InputGroup, FormGroup, FormLabel, FormRow, FormCheck} from 'react-bootstrap';


var saveOrEditJobURL="";
var userPersonalDetailsURL="";
var industry_url="";
var company_url ="";
var jobTitle_url ="";
var activeCountriesURL="";
var activeDegreesURL="";
var activeLanguagesURL="";
var activeCurrencies="";
var skill_url="";
var getStates="";
var getCities="";

var gtsActiveCountriesCache="";
var userAddressesCache="";
var gtsActiveStatesByCountryIDCache="";
var gtsActiveCitiesByStateIDCache="";

var env=ls.get('ENV');
var token = ls.get("token");
var	userLoginDetails = ls.get("userLoginDetails");
var userPersonalDetails=ls.get('userPersonalDetails');


var	activeCountriesURL="";
var	getUserAddressesURL="";
var	getStatesbyCountry="";
var	getCitiesByStateURL="";
var	getCitiesByCountry="";
var	saveOrEditUserAddress="";

if(env=="LOCAL"){
	getUserAddressesURL=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_USER_ADDRESSES;
	getStatesbyCountry=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_STATES_BY_COUNTRY;
	getCitiesByStateURL=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_CITIES_BY_STATE;
	getCitiesByCountry=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_CITIES_BY_COUNTRY;
	saveOrEditUserAddress=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_USER_ADDRESSES;
	activeCountriesURL=endpoints_properties.ENDPOINT_COUNTRIES_LOCAL+api_properties.API_GET_ACTIVE_COUNTRIES;
	getStates =endpoints_properties.ENDPOINT_STATES_LOCAL+api_properties.API_GET_STATES_BY_COUNTRY;
	getCities =endpoints_properties.ENDPOINT_CITIES_LOCAL+api_properties.API_GET_CITIES_BY_STATE;
}
else{
	getUserAddressesURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_USER_ADDRESSES;
	getStatesbyCountry=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_STATES_BY_COUNTRY;
	getCitiesByStateURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_CITIES_BY_STATE;
	getCitiesByCountry=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_CITIES_BY_COUNTRY;
	saveOrEditUserAddress=endpoints_properties.ENDPOINT_PROD+api_properties.API_USER_ADDRESSES;
	activeCountriesURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_COUNTRIES;
	getStates =endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_STATES_BY_COUNTRY;
	getCities =endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_CITIES_BY_STATE;
}




class UserAddressesComponent extends Component {


  constructor(props) {
    super(props)

    this.state = {
      currentAddressValidated: true,
      permanentAddressValidated: true,
      billingAddressValidated: true,
      successAlert:'',
      failureAlert:'',
      isCurrentAddressExisting:false,
      isPermanentAddressExisting:false,
      isBillingAddressExisting :false,
      disabledCurrentAddr: true,
      disabledPermanentAddr: true,
      disabledBillingAddr: true,
      current_address_id:'',
      current_country_name:'',
      current_state_name:'',
      current_city_name:'',
      permanent_country_name:'',
      permanent_state_name:'',
      permanent_city_name:'',
      billing_state_name:'',
      billing_country_name:'',
      permanent_address_line_1:'',
      billing_address_line_1:'',
      billing_city_name:'',
      current_country:'',
      current_state:'',
      current_city:'',
      permanent_country:'',
      permanent_state:'',
      permanent_city:'',
      billing_state:'',
      billing_country:'',
      billing_city:'',
      current_address_line_1:'',
      user_address_line_2:'',
      user_address_line_3:'',
      current_state_id: '',
      current_country_id:'',
      current_city_id:'',
      permanent_state_id: '',
      permanent_country_id: '',
      permanent_city_id:'',
      billing_state_id: '',
      billing_country_id: '',
      billing_city_id:'',
      user_pin_code:'',
      is_permanent_address:'',
      is_current_address:'',
      is_billing_address:'',
      user_id:'',
      user_address_id:'',
      country: [],
      countries : [],
      permanentCountry : [],
      permanentCountries : [],
      state: [],
      states:[],
      permanentState:[],
      permanentStates:[],
      billingState:[],
      billingStates:[],
      cities: [],
      city: [],
      permanentCities: [],
      permanentCity: [],
      billingCities: [],
      billingCity: [],
      errors:[],
      message:[],
      currentAddrUpdated:false,
      permanentAddrUpdated:false,
      hasError: false
    };

    this.changeHandler = this.changeHandler.bind(this);
  }


 componentDidMount() {
	 this.populateAddresses();
     this.populateCountries();
 }


populateCountries(){
	gtsActiveCountriesCache=ls.get('gtsActiveCountriesCache');
    console.log("gtsActiveCountriesCache: "+gtsActiveCountriesCache);

   if(gtsActiveCountriesCache===null || gtsActiveCountriesCache==="" || gtsActiveCountriesCache==="undefined"){
   axios.get(activeCountriesURL,{ headers: {"Auth_Token" : `Bearer ${token}`} })
        .then((res) =>{
			    console.log("Retrieve countries from Database and save into Cache");
			    ls.set('gtsActiveCountriesCache', res.data);

				this.setState({ countries : res.data});
				this.state.countries.forEach((country) => {
				this.state.country.push(country.gts_country_name);
				console.log("Populating addresses after retrieving countries from Database");
          });
        })
   }
	else{
		console.log("Populating countries from Cache...");
		this.state.countries=gtsActiveCountriesCache;
		console.log("gtsActiveCountriesCache: "+gtsActiveCountriesCache);
		console.log("this.state.countries: "+this.state.countries);
		this.setState({ countries : gtsActiveCountriesCache});
		this.state.country=[];
		this.state.countries.forEach((country) => {
			this.state.country.push(country.gts_country_name);
		})
	}
}

populateAddresses(){
	userAddressesCache=ls.get('userAddressesCache');
	console.log("userAddressesCache: "+userAddressesCache);

	if(userAddressesCache===null || userAddressesCache==="" || userAddressesCache==="undefined"){
		var url = getUserAddressesURL+'/'+userLoginDetails.user_id;
		axios.get(url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
		  .then(response => {
			   console.log("Retrieving addresses from database and saving to cache for User ID: "+userLoginDetails.user_id);
			   ls.set('userAddressesCache', response.data);
			   this.renderAddresses(response.data);
		  })
	  }
	else{
		console.log("Populating addresses from Cache...");
		this.renderAddresses(userAddressesCache);
	}
 }

 renderAddresses(addresses){
	  console.log("addresses.lenght: "+addresses.length);

	   for(var i=0;i<addresses.length;i++){

		 console.log("addresses[i].gts_user_address_id: "+addresses[i].gts_user_address_id);
		 console.log("addresses[i].gts_address_type: "+addresses[i].gts_address_type);

		 if(addresses[i].gts_address_type === 1) {
		   this.setState({isCurrentAddressExisting : true ,disabledCurrentAddr : true})
		   this.setState({
			 current_address_id:addresses[i].gts_user_address_id,
			 current_address_line_1:addresses[i].gts_user_address_line_1,
			 current_address_line_2:addresses[i].gts_user_address_line_2,
			 current_address_line_3:addresses[i].gts_user_address_line_3,
			 current_country_id:addresses[i].gts_country_id,
			 current_state_id:addresses[i].gts_state_id,
			 current_city_id:addresses[i].gts_city_id,
			 current_country_name:addresses[i].gts_country_name,
			 current_state_name:addresses[i].gts_state_name,
			 current_city_name:addresses[i].gts_city_name,
			 current_pincode:addresses[i].gts_user_pin_code,
		   });
		 }
		 else  if(addresses[i].gts_address_type === 2 ) {
		   this.setState({isPermanentAddressExisting : true ,disabledPermanentAddr : true})
		   this.setState({
			 permanent_address_id:addresses[i].gts_user_address_id,
			 permanent_address_line_1:addresses[i].gts_user_address_line_1,
			 permanent_address_line_2:addresses[i].gts_user_address_line_2,
			 permanent_address_line_3:addresses[i].gts_user_address_line_3,
			 permanent_country_id:addresses[i].gts_country_id,
			 permanent_state_id:addresses[i].gts_state_id,
			 permanent_city_id:addresses[i].gts_city_id,
			 permanent_country_name:addresses[i].gts_country_name,
			 permanent_state_name:addresses[i].gts_state_name,
			 permanent_city_name:addresses[i].gts_city_name,
			 permanent_pincode:addresses[i].gts_user_pin_code,
		   });
		 }
		 else if(addresses[i].gts_address_type === 3 ) {
		   this.setState({isBillingAddressExisting : true ,disabledBillingAddr : true})
		   this.setState({
			billing_address_id:addresses[i].gts_user_address_id,
			billing_address_line_1:addresses[i].gts_user_address_line_1,
			billing_address_line_2:addresses[i].gts_user_address_line_2,
			billing_address_line_3:addresses[i].gts_user_address_line_3,
			billing_country_name:addresses[i].gts_country_name,
			billing_state_name:addresses[i].gts_state_name,
			billing_city_name:addresses[i].gts_city_name,
			billing_country_id:addresses[i].gts_country_id,
			billing_state_id:addresses[i].gts_state_id,
			billing_city_id:addresses[i].gts_city_id,
			billing_pincode:addresses[i].gts_user_pin_code,
		   });
		 }

		}
 }


  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  setSuccessAlertFalse= e =>{
    this.setState({ isCurrentAddressExisting:false});
  }

  setSuccessAlertFalse= e =>{
    this.setState({ isPermanentAddressExisting:false});
  }

  setSuccessAlertFalse= e =>{
    this.setState({ isBillingAddressExisting:false});
  }


  enableCurrentAddr = () => {
    this.setState( {isCurrentAddressExisting: false,disabledCurrentAddr: true} )
  }

  enablePermanentAddr = () => {
    this.setState( {isPermanentAddressExisting: false,disabledPermanentAddr: true} )
  }

  enableBillingAddr = () => {
    this.setState( {isBillingAddressExisting: false,disabledBillingAddr: true} )
  }

  clearCurrentSuccessMessage = () => {
    let message1= this.state.message1;
    message1=" ";
    this.setState({message1 : message1});
  }

  clearPermanentSuccessMessage = () => {
    let message2= this.state.message2;
    message2 =" ";
    this.setState({message2 : message2});
  }

  clearBillingSuccessMessage = () => {
    let message3= this.state.message3;
    message3=" ";
    this.setState({message3 : message3});
  }

 currentAddrToPermanentAddr = (checked)=> {
    this.setState({
      [checked.target.name]: checked.target.checked
    });

    this.setState({disabledPermanentAddr: false})
    this.setState({currentAddrToPermanentAddr : true})


   console.log("this.state.currentAddrUpdated: "+this.state.currentAddrUpdated);
   if(checked.target.checked==true){
       //this.setState({permanantValidated: false})
       this.setState({permanentAddrUpdated : true})
       this.setState({permanent_address_line_1 : this.state.current_address_line_1});
       this.setState({permanent_address_line_2 : this.state.current_address_line_2});
       this.setState({permanent_address_line_3 : this.state.current_address_line_3});
       this.setState({permanent_country_name : this.state.current_country_name});
       this.setState({permanent_state_name : this.state.current_state_name});
       this.setState({permanent_city_name : this.state.current_city_name});
       this.setState({permanent_pincode : this.state.current_pincode});
   }
   else {
    this.setState({disabledPermanentAddr: true})
    this.setState({permanantValidated: false})
    this.setState({permanent_address_line_1 : ''});
    this.setState({permanent_address_line_2 : ''});
    this.setState({permanent_address_line_3 : ''});
    this.setState({permanent_country_name : ''});
    this.setState({permanent_state_name : ''});
    this.setState({permanent_city_name : ''});
    this.setState({permanent_pincode : ''});
   }
 }

 permanentAddrToBillingAddr = (checked)=> {
   this.setState({
     [checked.target.name]: checked.target.checked
   });



this.currentAddrToBillingAddr.checked=false;


   //this.setState({disabledBillingAddr: false});

   if(checked.target.checked==true){
        //this.setState({billingAddressValidated:false})
        this.setState({billing_address_line_1 : this.state.permanent_address_line_1});
        this.setState({billing_address_line_2 : this.state.permanent_address_line_2});
        this.setState({billing_address_line_3 : this.state.permanent_address_line_3});
        this.setState({billing_country_name : this.state.permanent_country_name});
        this.setState({billing_state_name : this.state.permanent_state_name});
        this.setState({billing_city_name : this.state.permanent_city_name});
        this.setState({billing_pincode : this.state.permanent_pincode});
    }
   else {
     //this.setState({billingAddressValidated:false})
     //this.setState({disabledBillingAddr: true});
     this.setState({billing_address_line_1 : ''});
     this.setState({billing_address_line_2 : ''});
     this.setState({billing_address_line_3 : ''});
     this.setState({billing_country_name : ''});
     this.setState({billing_state_name : ''});
     this.setState({billing_city_name : ''});
     this.setState({billing_pincode : ''});
   }
 }

 currentAddrToBillingAddr = (checked)=> {
  this.setState({
    [checked.target.name]: checked.target.checked
  });

  //this.setState({disabledBillingAddr: false})

  if(checked.target.checked==true){
    //this.setState({billingAddressValidated:false})
    this.setState({billing_address_line_1 : this.state.current_address_line_1});
    this.setState({billing_address_line_2 : this.state.current_address_line_2});
    this.setState({billing_address_line_3 : this.state.current_address_line_3});
    this.setState({billing_country_name : this.state.current_country_name});
    this.setState({billing_state_name : this.state.current_state_name});
    this.setState({billing_city_name : this.state.current_city_name});
    this.setState({billing_pincode : this.state.current_pincode});

  }
  else{
     this.setState({billingAddressValidated:false})
     //this.setState({disabledBillingAddr: true})
     this.setState({billing_address_line_1 : ''});
     this.setState({billing_address_line_2 : ''});
     this.setState({billing_address_line_3 : ''});
     this.setState({billing_country_name : ''});
     this.setState({billing_state_name : ''});
     this.setState({billing_city_name : ''});
     this.setState({billing_pincode : ''});
   }
 }

 componentDidCatch(errorMsg, errorInfo) {
  let error= this.state.error;
  error="Try after sometime or Please contact the administrator.";
  this.setState({error: error})
 }

 clearMessage = e =>{
  let error= this.state.error;
  error=" ";
  this.setState({error: ''})
  this.setState({error1: ''})
  this.setState({error2: ''})
  this.setState({error3: ''})
  this.setState({message1: ''})
  this.setState({message2: ''})
  this.setState({message3: ''})
 }



//  ----------------------------------------Save Current Address-----------------------------------------
 saveCurrentCountry = (event, value) => {
    this.state.current_country_name = value;
    console.log("Selected current country: "+value);

    if(value === ''  || value=== null || value==="undefined"){
      this.setState({current_country_name : ''})
      this.setState({current_country_id : ''})
    }
     else{
      this.state.countries.forEach((country) => {
        if (this.state.current_country_name === country.gts_country_name) {
          this.state.current_country_id = country.gts_country_id;
          console.log("this.state.current_country_id: "+this.state.current_country_id);
        }
      })

   }

   this.populateStates();
  }


   saveCurrentState = (event, value) => {
      this.state.current_state_name = value;
      console.log("this.state.current_state_name: "+this.state.current_state_name);
      if(value === ''  || value=== null || value==="undefined"){
        this.setState({current_state_id : ''})
      }
       else{
        this.state.states.forEach((state) => {
          if (this.state.current_state_name === state.gts_state_name) {
            this.state.current_state_id = state.gts_state_id;
            console.log("this.state.current_state_id: "+this.state.current_state_id);
          }
         })
       }
       this.populateCities();
  }

 saveCurrentCity = (event, value) => {
    this.state.current_city_name = value;
    if(value === ''  || value=== null || value==="undefined"){
      this.setState({current_city_id : ''})
    }
     else{
      this.state.cities.forEach((city) => {
        if (this.state.current_city_name === city.gts_city_name) {
          this.state.current_city_id = city.gts_city_id;
        }
       })
     }
  }


  populateStates(){
	//Loading active states
	gtsActiveStatesByCountryIDCache=ls.get('gtsActiveStatesByCountryIDCache-'+this.state.current_country_id);
    console.log("gtsActiveStatesByCountryIDCache of Country ID: "+this.state.current_country_id+": "+gtsActiveStatesByCountryIDCache);


	if(gtsActiveStatesByCountryIDCache===null || gtsActiveStatesByCountryIDCache==="" || gtsActiveStatesByCountryIDCache==="undefined"){
		console.log("Loading states from Database");

		var getStatesURLLocal=getStates+'/'+this.state.current_country_id;

		axios.get(getStatesURLLocal,{ headers: {"Auth_Token" : `Bearer ${token}`} })
		.then((res) =>{
			console.log("Saving States to Cache for country ID: "+this.state.current_country_id);
			ls.set('gtsActiveStatesByCountryIDCache-'+this.state.current_country_id, res.data);

			this.setState({states: []})

			this.setState({ states: res.data });
			this.state.states.forEach((state) => {
				this.state.state.push(state.gts_state_name);
			})
		})
		.catch(err => {
			this.setState({state: []});
			this.setState({states: []});
			ls.set('gtsActiveStatesByCountryIDCache-'+this.state.current_country_id, "");
		}
		);
	}
	else{
		console.log("Populating states from Cache...");
		this.state.states=gtsActiveStatesByCountryIDCache;
		this.state.state=[];
		this.state.states.forEach((state) => {
			this.state.state.push(state.gts_state_name);
		})

	}

  }

  populateCities(){
	 //Loading active cities
	console.log("this.state.current_state_id: "+this.state.current_state_id);
	gtsActiveCitiesByStateIDCache=ls.get('gtsActiveCitiesByStateIDCache-'+this.state.current_state_id);
	console.log("gtsActiveCitiesByStateIDCache: "+gtsActiveCitiesByStateIDCache);

	if(gtsActiveCitiesByStateIDCache===null || gtsActiveCitiesByStateIDCache==="" || gtsActiveCitiesByStateIDCache==="undefined"){
		 var getCitiesURLLocal =getCities+'/'+this.state.current_state_id;
		 axios.get(getCitiesURLLocal,{ headers: {"Auth_Token" : `Bearer ${token}`} })
		 .then((res) =>{
			  console.log("Populating cities from Database and saving to Cache...");
			  ls.set('gtsActiveCitiesByStateIDCache-'+this.state.current_state_id, res.data);
			  this.setState({cities: []})
			  this.setState({ cities: res.data });
			  this.state.cities.forEach((city) => {
			  this.state.city.push(city.gts_city_name);
		  })
		})
		.catch(err => {
			console.log("err: "+err);
			this.setState({city: []});
			this.setState({cities: []});
			ls.set('gtsActiveCitiesByStateIDCache-'+this.state.current_state_id, "");
		}
		);

	}
	else{
		console.log("Populating cities from Cache...");
		this.state.cities=gtsActiveCitiesByStateIDCache;
		this.state.city=[];
		this.state.cities.forEach((city) => {
			this.state.city.push(city.gts_city_name);
		})
		}
  }




 saveCurrentAddress= (event) => {
   console.log("Calling saveCurrentAddress()");


   event.preventDefault();

   console.log("Validating current address line-1");
   this.validateCurrentAddrLine1();

   console.log("Validating current country");
   this.validateCurrentCountry();

   console.log("Validating current state");
   this.validateCurrentState();

   console.log("Validating current city");
   this.validateCurrentCity();

   console.log("Validating current pincode");
   this.validateCurrentPincode();


   let responses = this.state.responses;
   let message1 = this.state.message;
   let error1 = this.state.error1;

   var url = saveOrEditUserAddress;
   console.log("url: "+url);

   console.log("this.state.current_address_id-1: "+this.state.current_address_id);


   // 1 for Current Address , 2 for Permanent Address , 3 for Billing Address
   var postPayload = {
     "gts_user_address_line_1":this.state.current_address_line_1,
     "gts_user_address_line_2":this.state.current_address_line_2,
     "gts_user_address_line_3":this.state.current_address_line_3,
     "gts_state_id":this.state.current_state_id,
     "gts_country_id":this.state.current_country_id,
     "gts_city_id":this.state.current_city_id,
     "gts_user_pin_code":this.state.current_pincode,
     "gts_user_id": userLoginDetails.user_id,
     "gts_address_type" : 1
    };


    console.log("this.state.currentAddressValidated : "+this.state.currentAddressValidated );

    if(this.state.currentAddressValidated == true){
	this.setState({errors : []});
	this.setState({error1 : []});

	console.log("this.state.current_address_id-2: "+this.state.current_address_id);

    if (this.state.current_address_id === null || this.state.current_address_id === "" || this.state.current_address_id === 0 || this.state.current_address_id === undefined ) {
		console.log("Creating current Address");

     axios.post(url,postPayload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
      .then(response => {

        console.log("response.data.status_code: "+response.data.status_code);
        console.log("response.data.address_id: "+response.data.address_id);

        this.setState({currentAddrUpdated : true});
        this.setState({gts_current_user_address_id: response.data.address_id})

        if(response.data.status_code == 403){
          error1 = "Not able to save current address"
        }
        else{
			message1 = response.data.message;
			this.setState({message1 : response.data.message});
        }
      })
      .catch(err => {
		console.log("Inside catch block:"+err);

        if(!err.response){
          error1 = "Not able to save current address"
        }
        else if(err.response.status > 500){
          error1 = "Not able to save current address"
        }
        else{
          error1 = err.response.data.message;
        }
        this.setState({error1 : error1});
        })
    }
    else{
		console.log("Editing address...");

		// 1 for Current Address , 2 for Permanent Address , 3 for Billing Address
		var putPayload = {
		  "gts_user_address_line_1":this.state.current_address_line_1,
		  "gts_user_address_line_2":this.state.current_address_line_2,
		  "gts_user_address_line_3":this.state.current_address_line_3,
		  "gts_state_id":this.state.current_state_id,
		  "gts_country_id":this.state.current_country_id,
		  "gts_city_id":this.state.current_city_id,
		  "gts_user_pin_code":this.state.current_pincode,
		  "gts_user_address_id":this.state.current_address_id,
		  "gts_address_type" : 1
		};

        axios.put(url,putPayload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
        .then(response => {
			console.log("response: "+response);
			console.log("response.data.status_code: "+response.data.status_code);

			this.setState({currentAddrUpdated : true});
			if(response.data.status_code == 403){
			  error1 = "Not able to update current address"
        	}
       		 else{
 			       message1 = response.data.message;
 			       this.setState({message1 : response.data.message});
 		       }
      })
      .catch(err => {
		console.log("Inside catch block, while editing:"+err);
        if(!err.response){
          error1 = "Not able to update current address"
        }
        else if(err.response.status > 500){
          error1 = "Not able to save current address"
        }
        else{
          error1 = err.response.data.message;
        }
        this.setState({error1 :  error1});
      })
   }
  }
 }


//  ----------------------------------------Save Permanent Address-----------------------------------------
 savePermanentCountry = (event, value) => {
    this.state.permanent_country_name = value;
    console.log("Selected permanent country: "+value);

    if(value === ''  || value=== null || value==="undefined"){
      this.setState({permanent_country_name : ''})
      this.setState({permanent_country_id : ''})
    }
     else{
      this.state.countries.forEach((country) => {
		//console.log("this.state.permanent_country_name"+this.state.permanent_country_name);
		//console.log("country.gts_country_name"+country.gts_country_name);
        if (this.state.permanent_country_name === country.gts_country_name) {
          this.state.permanent_country_id = country.gts_country_id;
          //console.log("this.state.permanent_country_id: "+this.state.permanent_country_id);

        }
      })
   }

   this.populatePermanentStates();
  }


   savePermanentState = (event, value) => {
      this.state.permanent_state_name = value;
      console.log("this.state.permanent_state_name: "+this.state.permanent_state_name);

      if(value === ''  || value=== null || value==="undefined"){
        this.setState({permanent_state_id : ''})
      }
       else{
        this.state.states.forEach((state) => {
          if (this.state.permanent_state_name === state.gts_state_name) {
            this.state.permanent_state_id = state.gts_state_id;
            console.log("this.state.permanent_state_id: "+this.state.permanent_state_id);
          }
         })

       }
       this.populatePermanentCities();
  }

 savePermanentCity = (event, value) => {
    this.state.permanent_city_name = value;
    if(value === ''  || value=== null || value==="undefined"){
      this.setState({permanent_city_id : ''})
    }
     else{
      this.state.cities.forEach((city) => {
        if (this.state.permanent_city_name === city.gts_city_name) {
          this.state.permanent_city_id = city.gts_city_id;
        }
       })
     }
  }


  populatePermanentStates(){
	//Loading active states
	gtsActiveStatesByCountryIDCache=ls.get('gtsActiveStatesByCountryIDCache-'+this.state.permanent_country_id);
    console.log("gtsActiveStatesByCountryIDCache of Country ID: "+this.state.permanent_country_id+": "+gtsActiveStatesByCountryIDCache);


	if(gtsActiveStatesByCountryIDCache===null || gtsActiveStatesByCountryIDCache==="" || gtsActiveStatesByCountryIDCache==="undefined"){
		console.log("Loading states from Database");

		var getStatesURLLocal=getStates+'/'+this.state.permanent_country_id;

		axios.get(getStatesURLLocal,{ headers: {"Auth_Token" : `Bearer ${token}`} })
		.then((res) =>{
			console.log("Saving States to Cache for country ID: "+this.state.permanent_country_id);
			ls.set('gtsActiveStatesByCountryIDCache-'+this.state.permanent_country_id, res.data);

			this.setState({states: []})

			this.setState({ states: res.data });
			this.state.states.forEach((state) => {
				this.state.permanentState.push(state.gts_state_name);
			})
		})
		.catch(err => {
			this.setState({permanentState: []});
			this.setState({states: []});
			ls.set('gtsActiveStatesByCountryIDCache-'+this.state.permanent_country_id, "");
		}
		);
	}
	else{
		console.log("Populating states from Cache...");
		this.state.states=gtsActiveStatesByCountryIDCache;
		this.state.permanentState=[];
		this.state.states.forEach((state) => {
			this.state.permanentState.push(state.gts_state_name);
		})

	}

  }

populateBillingStates(){
	//Loading active states
	gtsActiveStatesByCountryIDCache=ls.get('gtsActiveStatesByCountryIDCache-'+this.state.billing_country_id);
    console.log("gtsActiveStatesByCountryIDCache of Country ID: "+this.state.billing_country_id+": "+gtsActiveStatesByCountryIDCache);


	if(gtsActiveStatesByCountryIDCache===null || gtsActiveStatesByCountryIDCache==="" || gtsActiveStatesByCountryIDCache==="undefined"){
		console.log("Loading states from Database");

		var getStatesURLLocal=getStates+'/'+this.state.billing_country_id;

		axios.get(getStatesURLLocal,{ headers: {"Auth_Token" : `Bearer ${token}`} })
		.then((res) =>{
			console.log("Saving States to Cache for country ID: "+this.state.billing_country_id);
			ls.set('gtsActiveStatesByCountryIDCache-'+this.state.billing_country_id, res.data);

			this.setState({states: []})

			this.setState({ states: res.data });
			this.state.states.forEach((state) => {
				this.state.billingState.push(state.gts_state_name);
			})
		})
		.catch(err => {
			this.setState({billingState: []});
			this.setState({states: []});
			ls.set('gtsActiveStatesByCountryIDCache-'+this.state.billing_country_id, "");
		}
		);
	}
	else{
		console.log("Populating states from Cache...");
		this.state.states=gtsActiveStatesByCountryIDCache;
		this.state.billingState=[];
		this.state.states.forEach((state) => {
			this.state.billingState.push(state.gts_state_name);
		})

	}

  }

  populatePermanentCities(){
	 //Loading active cities
	console.log("this.state.permanent_state_id: "+this.state.permanent_state_id);
	gtsActiveCitiesByStateIDCache=ls.get('gtsActiveCitiesByStateIDCache-'+this.state.permanent_state_id);
	console.log("gtsActiveCitiesByStateIDCache: "+gtsActiveCitiesByStateIDCache);

	if(gtsActiveCitiesByStateIDCache===null || gtsActiveCitiesByStateIDCache==="" || gtsActiveCitiesByStateIDCache==="undefined"){
		 var getCitiesURLLocal =getCities+'/'+this.state.permanent_state_id;
		 axios.get(getCitiesURLLocal,{ headers: {"Auth_Token" : `Bearer ${token}`} })
		 .then((res) =>{
			  console.log("Populating cities from Database and saving to Cache...");
			  ls.set('gtsActiveCitiesByStateIDCache-'+this.state.permanent_state_id, res.data);
			  this.setState({cities: []})
			  this.setState({ cities: res.data });
			  this.state.cities.forEach((city) => {
			  this.state.permanentCity.push(city.gts_city_name);
		  })
		})
		.catch(err => {
			console.log("err: "+err);
			this.setState({permanentCity: []});
			this.setState({cities: []});
			ls.set('gtsActiveCitiesByStateIDCache-'+this.state.permanent_state_id, "");
		}
		);

	}
	else{
		console.log("Populating permanent cities from Cache...");
		this.state.cities=gtsActiveCitiesByStateIDCache;
		this.state.city=[];
		this.state.cities.forEach((city) => {
			this.state.permanentCity.push(city.gts_city_name);
		})
		}
  }


  populateBillingCities(){
	 //Loading active cities
	console.log("this.state.billing_state_id: "+this.state.billing_state_id);
	gtsActiveCitiesByStateIDCache=ls.get('gtsActiveCitiesByStateIDCache-'+this.state.billing_state_id);
	console.log("gtsActiveCitiesByStateIDCache: "+gtsActiveCitiesByStateIDCache);

	if(gtsActiveCitiesByStateIDCache===null || gtsActiveCitiesByStateIDCache==="" || gtsActiveCitiesByStateIDCache==="undefined"){
		 var getCitiesURLLocal =getCities+'/'+this.state.billing_state_id;
		 axios.get(getCitiesURLLocal,{ headers: {"Auth_Token" : `Bearer ${token}`} })
		 .then((res) =>{
			  console.log("Populating cities from Database and saving to Cache...");
			  ls.set('gtsActiveCitiesByStateIDCache-'+this.state.billing_state_id, res.data);
			  this.setState({cities: []})
			  this.setState({ cities: res.data });
			  this.state.cities.forEach((city) => {
			  this.state.billingCity.push(city.gts_city_name);
		  })
		})
		.catch(err => {
			console.log("err: "+err);
			this.setState({billingCity: []});
			this.setState({cities: []});
			ls.set('gtsActiveCitiesByStateIDCache-'+this.state.billing_state_id, "");
		}
		);

	}
	else{
		console.log("Populating billing cities from Cache...");
		this.state.cities=gtsActiveCitiesByStateIDCache;
		this.state.city=[];
		this.state.cities.forEach((city) => {
			this.state.billingCity.push(city.gts_city_name);
		})
		}
  }

 savePermanentAddress= (event) => {
   console.log("Calling savePermanentAddress()");


   event.preventDefault();

   console.log("Validating permanent address line-1");
   this.validatePermanentAddrLine1();

   console.log("Validating permanent country");
   this.validatePermanentCountry();

   console.log("Validating permanent state");
   this.validatePermanentState();

   console.log("Validating permanent city");
   this.validatePermanentCity();

   console.log("Validating permanent pincode");
   this.validatePermanentPincode();


   let responses = this.state.responses;
   let message2 = this.state.message2;
   let error2 = this.state.error2;

   var url = saveOrEditUserAddress;
   console.log("url: "+url);

   console.log("this.state.permanent_address_id-1: "+this.state.permanent_address_id);
   //console.log("this.state.permanent_pincode: "+this.state.permanent_pincode);

   // 1 for Current Address , 2 for Permanent Address , 3 for Billing Address
   var postPayload = {
     "gts_user_address_line_1":this.state.permanent_address_line_1,
     "gts_user_address_line_2":this.state.permanent_address_line_2,
     "gts_user_address_line_3":this.state.permanent_address_line_3,
     "gts_state_id":this.state.permanent_state_id,
     "gts_country_id":this.state.permanent_country_id,
     "gts_city_id":this.state.permanent_city_id,
     "gts_user_pin_code":this.state.permanent_pincode,
     "gts_user_id": userLoginDetails.user_id,
     "gts_address_type" : 2
    };

    var permanent_country_id = this.state.permanent_country_id;
    var permanent_state_id = this.state.permanent_state_id;
    var permanent_city_id = this.state.permanent_city_id;

    console.log("permanent_country_id: "+permanent_country_id);
    console.log("permanent_state_id: "+permanent_state_id);
    console.log("permanent_city_id: "+permanent_city_id);


    this.setState({gts_present_permanent_country_id :permanent_country_id})
    this.setState({gts_present_permanent_state_id : permanent_state_id})
    this.setState({gts_present_permanent_city_id : permanent_city_id})

    //console.log("Validating before save...");
    //this.validateBeforePermanentAddressSave();
    console.log("this.state.permanentAddressValidated: "+this.state.permanentAddressValidated);

    if(this.state.permanentAddressValidated == true){
	console.log("this.state.permanent_address_id-2: "+this.state.permanent_address_id);

    if (this.state.permanent_address_id === null || this.state.permanent_address_id === "" || this.state.permanent_address_id === 0 || this.state.permanent_address_id === undefined ) {
		console.log("Creating permanent Address");

     axios.post(url,postPayload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
      .then(response => {

        console.log("response.data.status_code: "+response.data.status_code);
        console.log("response.data.address_id: "+response.data.address_id);

        this.setState({permanentAddrUpdated : true});
        this.setState({gts_permanent_user_address_id: response.data.address_id})

        if(response.data.status_code == 403){
          error2 = "Not able to save permanent address"
        }
        else{
			message2 = response.data.message;
			this.setState({message2 : response.data.message});
        }
      })
      .catch(err => {
		console.log("Inside catch block:"+err);

        if(!err.response){
          error2 = "Not able to save permanent address"
        }
        else if(err.response.status > 500){
          error2 = "Not able to save permanent address"
        }
        else{
          error2 = err.response.data.message;
        }
        this.setState({error2 : error2});
        })
    }
    else{

		console.log("Editing address...");


		// 1 for Current Address , 2 for Permanent Address , 3 for Billing Address
		var putPayload = {
		  "gts_user_address_line_1":this.state.permanent_address_line_1,
		  "gts_user_address_line_2":this.state.permanent_address_line_2,
		  "gts_user_address_line_3":this.state.permanent_address_line_3,
		  "gts_state_id":this.state.permanent_state_id,
		  "gts_country_id":this.state.permanent_country_id,
		  "gts_city_id":this.state.permanent_city_id,
		  "gts_user_pin_code":this.state.permanent_pincode,
		  "gts_user_address_id":this.state.permanent_address_id,
		  "gts_address_type" : 2
		};


        axios.put(url,putPayload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
        .then(response => {
			console.log("response: "+response);
			console.log("response.data.status_code: "+response.data.status_code);

			this.setState({permanentAddrUpdated : true});
			if(response.data.status_code == 403){
			  error2 = "Not able to update permanent address"
        	}
       		 else{
 			       message2 = response.data.message;
 			       this.setState({message2 : response.data.message});
 		       }
      })
      .catch(err => {
		console.log("Inside catch block, while editing:"+err);
        if(!err.response){
          error2 = "Not able to update permanent address"
        }
        else if(err.response.status > 500){
          error2 = "Not able to save permanent address"
        }
        else{
          error2 = err.response.data.message;
        }
        this.setState({error2 :  error2});
      })

  }
 }
}



//  ----------------------------------------------Save Billing Address------------------------------------------------------
 saveBillingCountry = (event, value) => {
    this.state.billing_country_name = value;
    console.log("Selected billing country: "+value);

    if(value === ''  || value=== null || value==="undefined"){
      this.setState({billing_country_name : ''})
      this.setState({billing_country_id : ''})
    }
     else{
      this.state.countries.forEach((country) => {
		//console.log("this.state.billing_country_name"+this.state.billing_country_name);
		//console.log("country.gts_country_name"+country.gts_country_name);
        if (this.state.billing_country_name === country.gts_country_name) {
          this.state.billing_country_id = country.gts_country_id;
          //console.log("this.state.billing_country_id: "+this.state.permanent_country_id);

        }
      })
   }

   this.populateBillingStates();
  }


  saveBillingState = (event, value) => {
      this.state.billing_state_name = value;
      console.log("this.state.billing_state_name: "+this.state.billing_state_name);

      if(value === ''  || value=== null || value==="undefined"){
        this.setState({billing_state_id : ''})
      }
       else{
        this.state.states.forEach((state) => {
          if (this.state.billing_state_name === state.gts_state_name) {
            this.state.billing_state_id = state.gts_state_id;
            console.log("this.state.billing_state_id: "+this.state.billing_state_id);
          }
         })

       }
       this.populateBillingCities();
  }



 saveBillingCity = (event, value) => {
    this.state.billing_city_name = value;
    if(value === ''  || value=== null || value==="undefined"){
      this.setState({billing_city_id : ''})
    }
     else{
      this.state.cities.forEach((city) => {
        if (this.state.billing_city_name === city.gts_city_name) {
          this.state.billing_city_id = city.gts_city_id;
        }
       })
     }
  }



 saveBillingAddress= (event) => {
   console.log("Calling saveBillingAddress()");


   event.preventDefault();

   this.validateBillingAddrLine1();
   this.validateBillingCountry();
   this.validateBillingState();
   this.validateBillingCity();
   this.validateBillingPincode();


   let responses = this.state.responses;
   let message3 = this.state.message3;
   let error3 = this.state.error3;

   var url = saveOrEditUserAddress;

   console.log("this.state.billing_address_id: "+this.state.billing_address_id);
   //console.log("this.state.billing_pincode: "+this.state.billing_pincode);

   // 1 for Current Address , 2 for Permanent Address , 3 for Billing Address
   var postPayload = {
     "gts_user_address_line_1":this.state.billing_address_line_1,
     "gts_user_address_line_2":this.state.billing_address_line_2,
     "gts_user_address_line_3":this.state.billing_address_line_3,
     "gts_state_id":this.state.billing_state_id,
     "gts_country_id":this.state.billing_country_id,
     "gts_city_id":this.state.billing_city_id,
     "gts_user_pin_code":this.state.billing_pincode,
     "gts_user_id": userLoginDetails.user_id,
     "gts_address_type" : 3
    };

    var billing_country_id = this.state.billing_country_id;
    var billing_state_id = this.state.billing_state_id;
    var billing_city_id = this.state.billing_city_id;

    console.log("billing_country_id: "+billing_country_id);
    console.log("billing_state_id: "+billing_state_id);
    console.log("billing_city_id: "+billing_city_id);


    this.setState({gts_present_billing_country_id :billing_country_id})
    this.setState({gts_present_billing_state_id : billing_state_id})
    this.setState({gts_present_billing_city_id : billing_city_id})

    //console.log("Validating before save...");
    //this.validateBeforebillingAddressSave();
    console.log("this.state.billingAddressValidated: "+this.state.billingAddressValidated);

    if(this.state.billingAddressValidated == true){
	console.log("this.state.billing_address_id-2: "+this.state.billing_address_id);

    if (this.state.billing_address_id === null || this.state.billing_address_id === "" || this.state.billing_address_id === 0 || this.state.billing_address_id === undefined ) {
		console.log("Creating billing Address");

     axios.post(url,postPayload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
      .then(response => {

        console.log("response.data.status_code: "+response.data.status_code);
        console.log("response.data.address_id: "+response.data.address_id);

        this.setState({billingAddrUpdated : true});
        this.setState({gts_billing_user_address_id: response.data.address_id})

        if(response.data.status_code == 403){
          error3 = "Not able to save billing address"
        }
        else{
			message3 = response.data.message;
			this.setState({message3 : response.data.message});
        }
      })
      .catch(err => {
		console.log("Inside catch block:"+err);

        if(!err.response){
          error3 = "Not able to save billing address"
        }
        else if(err.response.status > 500){
          error3 = "Not able to save billing address"
        }
        else{
          error3 = err.response.data.message;
        }
        this.setState({error3 : error3});
        })
    }
    else{

		console.log("Editing address...");


		// 1 for Current Address , 2 for Permanentbilling Address , 3 for Billing Address
		var putPayload = {
		  "gts_user_address_line_1":this.state.billing_address_line_1,
		  "gts_user_address_line_2":this.state.billing_address_line_2,
		  "gts_user_address_line_3":this.state.billing_address_line_3,
		  "gts_state_id":this.state.billing_state_id,
		  "gts_country_id":this.state.billing_country_id,
		  "gts_city_id":this.state.billing_city_id,
		  "gts_user_pin_code":this.state.billing_pincode,
		  "gts_user_address_id":this.state.billing_address_id,
		  "gts_address_type" : 3
		};


        axios.put(url,putPayload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
        .then(response => {
			console.log("response: "+response);
			console.log("response.data.status_code: "+response.data.status_code);

			this.setState({billingAddrUpdated : true});
			if(response.data.status_code == 403){
			  error3 = "Not able to update billing address"
        	}
       		 else{
 			       message3 = response.data.message;
 			       this.setState({message3 : response.data.message});
 		       }
      })
      .catch(err => {
		console.log("Inside catch block, while editing:"+err);
        if(!err.response){
          error3 = "Not able to update billing address"
        }
        else if(err.response.status > 500){
          error3 = "Not able to save billing address"
        }
        else{
          error3 = err.response.data.message;
        }
        this.setState({error3 :  error3});
      })

  }
 }
}




  validateCurrentAddrLine1() {
	console.log("this.state.errors: "+this.state.errors);

    var errors = [];
    errors["current_address_line_1"]='';
    if(this.state.errors!==null & this.state.errors!=="" && this.state.errors==="undefined"){
		errors=this.state.errors;
	}

    if(this.state.current_address_line_1===null || this.state.current_address_line_1==="" || this.state.current_address_line_1==="undefined") {
		console.log("Current address line-1 validaion failed");
		this.setState({
			   currentAddressValidated: false,
			   errors: errors
		 });
    }
    else if(this.state.current_address_line_1.trim().length==0) {
		errors["current_address_line_1"]="Please enter address line 1";
		console.log("Current address line-1 validaion failed");
		this.setState({
		   currentAddressValidated: false,
		   errors: errors
		});
   	 }
   	 else if (this.state.current_address_line_1.trim().length > 100) {
       errors["current_address_line_1"]="Only 100 characters are allowed ";
		console.log("Current address line-1 validaion failed");
		this.setState({
			   currentAddressValidated: false,
			   errors: errors
		 });
     }
	else{
		  console.log("Current address line-1 validaion successful");
	      this.setState({
	  	       currentAddressValidated: true,
	  	       errors: errors
    	 });

  	 }
}


validatePermanentAddrLine1() {
	console.log("this.state.errors: "+this.state.errors);

    var errors = [];
    errors["permanent_address_line_1"]='';
    if(this.state.errors!==null & this.state.errors!=="" && this.state.errors==="undefined"){
		errors=this.state.errors;
	}

    if(this.state.permanent_address_line_1===null || this.state.permanent_address_line_1==="" || this.state.permanent_address_line_1==="undefined") {
      if(this.state.permanent_address_line_1.trim().length==0) {
      	errors["permanent_address_line_1"]="Please enter address line 1";
   	 }
   	 else if (this.state.permanent_address_line_1.trim().length > 100) {
       errors["permanent_address_line_1"]="Only 100 characters are allowed ";
     }
    console.log("Permanent address line-1 validaion failed");
    this.setState({
	       permanentAddressValidated: false,
	       errors: errors
     });
  }
  else{
		  console.log("Current address line-1 validaion successfull");
	      this.setState({
	  	       permanentAddressValidated: true,
	  	       errors: errors
    	 });

  }

}

  validateBillingAddrLine1() {
	console.log("this.state.errors: "+this.state.errors);

    var errors = [];
    errors["billing_address_line_1"]='';
    if(this.state.errors!==null & this.state.errors!=="" && this.state.errors==="undefined"){
		errors=this.state.errors;
	}

    if(this.state.billing_address_line_1===null || this.state.billing_address_line_1==="" || this.state.billing_address_line_1==="undefined") {
      if(this.state.billing_address_line_1.trim().length==0) {
      	errors["billing_address_line_1"]="Please enter address line 1";
   	 }
   	 else if (this.state.billing_address_line_1.trim().length > 100) {
       errors["billing_address_line_1"]="Only 100 characters are allowed ";
     }
	console.log("Billing address line-1 validaion failed");
    this.setState({
	       billingAddressValidated: false,
	       errors: errors
     });
  }
  else{
		 console.log("Billing address line-1 validaion successfull");
	      this.setState({
	  	       billingAddressValidated: true,
	  	       errors: errors
    	 });

  }

}

  validateCurrentCountry() {
    var errors = [];
    errors["current_country_name"]='';
    if(this.state.errors!==null & this.state.errors!=="" && this.state.errors==="undefined"){
		errors=this.state.errors;
	}


    console.log("this.state.current_country_name: "+this.state.current_country_name);

    if(this.state.current_country_name===null || this.state.current_country_name==="" || this.state.current_country_name==="undefined") {
      	errors["current_country_name"]="Please select current country";
      	console.log("Current country validaion failed");
		this.setState({
			   currentAddressValidated: false,
			   errors: errors
		 });
 	 }
	  else{
		     console.log("Current country validaion successfull");
			  this.setState({
				   currentAddressValidated: true,
				   errors: errors
			 });

	  }
	}



   validatePermanentCountry() {
     var errors = [];
     errors["permanent_country_name"]='';
     if(this.state.errors!==null & this.state.errors!=="" && this.state.errors==="undefined"){
 		errors=this.state.errors;
 	}


     console.log("this.state.permanent_country_name: "+this.state.permanent_country_name);

     if(this.state.permanent_country_name===null || this.state.permanent_country_name==="" || this.state.permanent_country_name==="undefined") {
       	errors["permanent_country_name"]="Please select permanent country";
       	console.log("Permanent country validaion failed");
 		this.setState({
 			   permanentAddressValidated: false,
 			   errors: errors
 		 });
  	 }
 	  else{
 		     console.log("Permanent country validaion successfull");
 			  this.setState({
 				   permanentAddressValidated: true,
 				   errors: errors
 			 });

 	  }
	}



  validateBillingCountry() {
    var errors = [];
    errors["billing_country_name"]='';
    if(this.state.errors!==null & this.state.errors!=="" && this.state.errors==="undefined"){
		errors=this.state.errors;
	}


    console.log("this.state.billing_country_name: "+this.state.billing_country_name);

    if(this.state.billing_country_name===null || this.state.billing_country_name==="" || this.state.billing_country_name==="undefined") {
		console.log("Billing country validaion failed");
      	errors["billing_country_name"]="Please select billing country";
		this.setState({
			   billingAddressValidated: false,
			   errors: errors
		 });
 	 }
	  else{
		      console.log("Billing country validaion successfull");
			  this.setState({
				   billingAddressValidated: true,
				   errors: errors
			 });

	  }
	}

  validateCurrentState() {
    var errors = [];

    if(this.state.errors!==null & this.state.errors!=="" && this.state.errors==="undefined"){
		errors=this.state.errors;
	}

	errors["current_state_name"]='';
    console.log("this.state.current_state_name: "+this.state.current_state_name);

    if(this.state.current_state_name===null || this.state.current_state_name==="" || this.state.current_state_name==="undefined") {
		console.log("Current state validaion failed");
      	errors["current_state_name"]="Please select current state";
		this.setState({
			   currentAddressValidated: false,
			   errors: errors
		 });
 	 }
	  else{
              console.log("Current state validaion successfull");
			  this.setState({
				   currentAddressValidated: true,
				   errors: errors,
				   error1: errors
			 });

	  }
}

  validatePermanentState() {
    var errors = [];

    if(this.state.errors!==null & this.state.errors!=="" && this.state.errors==="undefined"){
		errors=this.state.errors;
	}

	errors["permanent_state_name"]='';
    console.log("this.state.permanent_state_name: "+this.state.permanent_state_name);

    if(this.state.permanent_state_name===null || this.state.permanent_state_name==="" || this.state.permanent_state_name==="undefined") {
		console.log("Permanent state validaion failed");
      	errors["permanent_state_name"]="Please select permanent state";
		this.setState({
			   permanentAddressValidated: false,
			   errors: errors
		 });
 	 }
	  else{
              console.log("Permanent state validaion successfull");
			  this.setState({
				   permanentAddressValidated: true,
				   errors: errors,
				   error1: errors
			 });

	  }
}


 validateBillingState() {
    var errors = [];

    if(this.state.errors!==null & this.state.errors!=="" && this.state.errors==="undefined"){
		errors=this.state.errors;
	}

	errors["billing_state_name"]='';
    console.log("this.state.billing_state_name: "+this.state.billing_state_name);

    if(this.state.billing_state_name===null || this.state.billing_state_name==="" || this.state.billing_state_name==="undefined") {
		console.log("Billing state validaion failed");
      	errors["billing_state_name"]="Please select billing state";
		this.setState({
			   billingAddressValidated: false,
			   errors: errors
		 });
 	 }
	  else{
			  console.log("Billing state validaion successfull");
			  this.setState({
				   billingAddressValidated: true,
				   errors: errors
			 });

	  }
}

 validateCurrentCity() {
    var errors = [];

    if(this.state.errors!==null & this.state.errors!=="" && this.state.errors==="undefined"){
		errors=this.state.errors;
	}

	errors["current_city_name"]='';
    console.log("this.state.current_city_name: "+this.state.current_city_name);

    if(this.state.current_city_name===null || this.state.current_city_name==="" || this.state.current_city_name==="undefined") {
		console.log("Current city validaion failed");
      	errors["current_city_name"]="Please select current city";
		this.setState({
			   currentAddressValidated: false,
			   errors: errors
		 });
 	 }
	  else{
			  console.log("Current city validaion successfull");
			  this.setState({
				   currentAddressValidated: true,
				   errors: errors
			 });

	  }
}

 validatePermanentCity() {
    var errors = [];

    if(this.state.errors!==null & this.state.errors!=="" && this.state.errors==="undefined"){
		errors=this.state.errors;
	}

	errors["permanent_city_name"]='';
    console.log("this.state.permanent_city_name: "+this.state.permanent_city_name);

    if(this.state.permanent_city_name===null || this.state.permanent_city_name==="" || this.state.permanent_city_name==="undefined") {
		console.log("Permanent city validaion failed");
      	errors["permanent_city_name"]="Please select permanent city";
		this.setState({
			   permanentAddressValidated: false,
			   errors: errors
		 });
 	 }
	  else{
			  console.log("Permanent city validaion successfull");
			  this.setState({
				   permanentAddressValidated: true,
				   errors: errors
			 });

	  }
}

 validateBillingCity() {
    var errors = [];

    if(this.state.errors!==null & this.state.errors!=="" && this.state.errors==="undefined"){
		errors=this.state.errors;
	}

	errors["billing_city_name"]='';
    console.log("this.state.billing_city_name: "+this.state.billing_city_name);

    if(this.state.billing_city_name===null || this.state.billing_city_name==="" || this.state.billing_city_name==="undefined") {
		console.log("Billing city validaion failed");
      	errors["billing_city_name"]="Please select billing city";
		this.setState({
			   billingAddressValidated: false,
			   errors: errors
		 });
 	 }
	  else{
			  console.log("Billing city validaion successfull");
			  this.setState({
				   billingAddressValidated: true,
				   errors: errors
			 });

	  }
}

  validateCurrentPincode(){
    var errors = [];
    errors["current_pincode"]="";

    let regex = new RegExp(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/);

    if(this.state.errors!==null & this.state.errors!=="" && this.state.errors==="undefined"){
		 errors=this.state.errors;
	}

	if(!this.state.current_pincode){
      errors["current_pincode"]="Please enter current pincode";
      console.log("Current pincode validaion failed");
        this.setState({
          currentAddressValidated: false,
          errors: errors
        });
    }
    else if (!regex.test(this.state.current_pincode)) {
	    errors["current_pincode"]="Pincode is invalid.";
        console.log("Current pincode validaion failed");
		 this.setState({
				   currentAddressValidated: false,
				   errors: errors
		 });
	 }
	 else{
		 console.log("Current pincode validaion successfull");
		 this.setState({
				   currentAddressValidated: true,
				   errors: errors
		 });
    }
  }

   validatePermanentPincode(){
     var errors = [];
     errors["permanent_pincode"]="";

     let regex = new RegExp(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/);

     if(this.state.errors!==null & this.state.errors!=="" && this.state.errors==="undefined"){
 		 errors=this.state.errors;
 	}

 	if(!this.state.permanent_pincode){
       errors["permanent_pincode"]="Please enter permanent pincode";
       console.log("permanent pincode validaion failed");
         this.setState({
           permanentAddressValidated: false,
           errors: errors
         });
     }
     else if (!regex.test(this.state.permanent_pincode)) {
 	    errors["permanent_pincode"]="Pincode is invalid.";
         console.log("permanent pincode validaion failed");
 		 this.setState({
 				   permanentAddressValidated: false,
 				   errors: errors
 		 });
 	 }
 	 else{
 		 console.log("permanent pincode validaion successfull");
 		 this.setState({
 				   permanentAddressValidated: true,
 				   errors: errors
 		 });
     }
  }

   validateBillingPincode(){
     var errors = [];
     errors["billing_pincode"]="";

     let regex = new RegExp(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/);

     if(this.state.errors!==null & this.state.errors!=="" && this.state.errors==="undefined"){
 		 errors=this.state.errors;
 	}

 	if(!this.state.billing_pincode){
       errors["billing_pincode"]="Please enter billing pincode";
       console.log("billing pincode validaion failed");
         this.setState({
           billingAddressValidated: false,
           errors: errors
         });
     }
     else if (!regex.test(this.state.billing_pincode)) {
 	    errors["billing_pincode"]="Pincode is invalid.";
         console.log("billing pincode validaion failed");
 		 this.setState({
 				   billingAddressValidated: false,
 				   errors: errors
 		 });
 	 }
 	 else{
 		 console.log("billing pincode validaion successfull");
 		 this.setState({
 				   billingAddressValidated: true,
 				   errors: errors
 		 });
     }
  }





  render() {

    const successMessageStyles = {
      //backgroundColor: "#f0f",
      fontWeight: 'bold',
      fontSize: 18,
      color: "#008000",
      //padding: paddings
    }

    const {
      saveCurrentAddress,
      savePermanentAddress,
      saveBillingAddress,
      permanent_state,
      permanent_country,
      permanent_city,
      permanent_pincode,
      permanent_address_line_1,
      permanent_address_line_2,
      permanent_address_line_3,
      current_state,
      current_city,
      current_pincode,
      current_address_line_1,
      current_address_line_2,
      current_address_line_3,
      billing_address_line_1,
      billing_address_line_2,
      billing_address_line_3,
      billing_state,
      billing_country,
      billing_city,
      billing_pincode,
      address,
      current_state_id,
      current_country_id,
      current_city_id,
      permanent_state_id,
      permanent_country_id,
      permanent_city_id,
      billing_state_id,
      billing_country_id,
      billing_city_id,
      successAlert,
      countryId,
      stateId,
      cityId

    } = this.state


    const FormBox =this.props;
     return (
      <div className="container align-items-center">
        <div className="container align-items-center">
          <div className='mt-3'>
            <div className="border border-dark rounded-lg" >
              <div className="row">
                <div className = "col" align="left">
                  <h5  className="mb6-3 p-2"><b>&nbsp;User Addresses</b></h5>
                </div>
             </div>
             <div style={{borderBottomColor: 'black',borderBottomWidth: 1.5}} />
             <br/>
             <div className="row"align="center">
               <span style={{color: "red"}}>{this.state.error}</span>
             </div>
             <div className="container">
                {/* ------------------------------------------------CURRENT ADDRESS----------------------------------------------*/}
               {/* <div> */}
                  <InputGroup className="mb-3" size="sm">
                    <h6 style={{color:"black", fontSize:"18px"}}>Current Address:<span style={{fontSize:"15px", color: "red"}}><b>*</b></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>
                    <FormControl
                     type="text"
                     name="current_address_line_1"
                     id="current_address_line_1"
                     style={{color : (!this.state.isCurrentAddressExisting && this.state.disabledCurrentAddr)? "black" : "gray",border:"1px solid #D3D3D3",height:"30px", width:200}}
                     placeholder="Address Line 1"
                     name="current_address_line_1"
                     value={current_address_line_1}
                     onChange={this.changeHandler}
                     disabled = {(!this.state.isCurrentAddressExisting && this.state.disabledCurrentAddr)? "" : "disabledCurrentAddr"}
                     onBlur={this.validateCurrentAddrLine1.bind(this)}
                     //onFocus={this.clearMessage}
                     aria-describedby="inputGroup-sizing-default"
                    />
                    <h6 style={{ color : "black",fontSize:"18px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Current Country<span style={{fontSize:"15px", color: "red"}}><b>*</b></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>


				  <Autocomplete
					options={this.state.country}
					style={{ width: "150px", outlineColor: "black", display: "inline-block"}}
					renderInput={(params) => <TextField {...params} variant="outlined" style={{ color: "black" }} size="small"/>}
					onChange={this.saveCurrentCountry}
					noOptionsText='No options'
					onBlur={this.validateCurrentCountry.bind(this)}
					value={this.state.current_country_name}
					size="small"
				  />

                 </InputGroup>
                 {(this.state.errors.current_address_line_1 || this.state.errors.current_country_name)?
                 <div class='row-0' align="left">
                   <span style={{color: "red"  , align: "left"}} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {this.state.errors.current_address_line_1}</span>

                    <span  style={{color: "red", float:"right"}}>
                    {this.state.errors.current_country_name}</span><br/>&nbsp;
                    </div>:''}



                  <InputGroup className="mb-3" size="sm">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <FormControl
                     type="text"
                     name="current_address_line_2"
                     id="current_address_line_2"
                     //onFocus={this.clearMessage}
                     style={{color :(!this.state.isCurrentAddressExisting && this.state.disabledCurrentAddr)? "black" : "gray", border:"1px solid #D3D3D3", height:"30px", width:200}}
                     placeholder="Address Line 2"
                     name="current_address_line_2"
                     defaultValue={current_address_line_2}
                     onChange={this.changeHandler}
                     disabled = {(!this.state.isCurrentAddressExisting && this.state.disabledCurrentAddr)? "" : "disabledCurrentAddr"}
                     aria-describedby="inputGroup-sizing-default"
                    />


                    <h6 style={{ color : "black",fontSize:"18px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Current State<span style={{fontSize:"15px", color: "red"}}><b>*</b></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>


					<Autocomplete
					  options={this.state.state}
					  style={{ width: "150px", outlineColor: "black", display: "inline-block"}}
					  renderInput={

						  (
							  params) =>

							  <TextField {...params} id="current_state_id" variant="outlined" style={{ color: "black" }} name="current_state_name" size="small"/>
						  }
					  onChange={this.saveCurrentState}
					  noOptionsText='No options'
					  onBlur={this.validateCurrentState.bind(this)}
					  value={this.state.current_state_name}
					  size="small"
					/>


                 </InputGroup>

                 {
					 (this.state.errors.current_state_name)?
				      <div class="row-0" align="right">
				      	<span  style={{color: "red", align:"left"}}>
				      		{this.state.errors.current_state_name}
				        </span>
				        <br/>&nbsp;
                  	  </div>
                  	  :''

                 }

                 <InputGroup className="mb-3" size="sm">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <FormControl
                     type="text"
                     name="current_address_line_3"
                     id="current_address_line_3"
                     onClick={this.clearMessage}
                     style={{color :(!this.state.isCurrentAddressExisting && this.state.disabledCurrentAddr)? "black" : "gray", border:"1px solid #D3D3D3", height:"30px", width:200}}
                     placeholder="Address Line 3"
                     name="current_address_line_3"
                     defaultValue={current_address_line_3}
                     onChange={this.changeHandler}
                     disabled = {(!this.state.isCurrentAddressExisting && this.state.disabledCurrentAddr)? "" : "disabledCurrentAddr"}
                     aria-describedby="inputGroup-sizing-default"
                    />


                    <h6 style={{ color : "black",fontSize:"18px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                    City

                    <span style={{fontSize:"15px", color: "red"}}><b>*</b></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>

				  <Autocomplete
					options={this.state.city}
					style={{ width: "150px", outlineColor: "black", display: "inline-block"}}
					renderInput={(params) => <TextField {...params} id="current_city_id" variant="outlined" style={{ color: "black" }} name="current_city_name" size="small"/>}
					onChange={this.saveCurrentCity}
					noOptionsText='No options'
					onBlur={this.validateCurrentCity.bind(this)}
					value={this.state.current_city_name}
					size="small"
				  />


                 </InputGroup>
                 {
				(this.state.errors.current_city_name)?
                 <div class="row-0" align="right">
                 <span  style={{color: "red", align:"left"}}>
                 	{this.state.errors.current_city_name}
                 </span>
                 <br/>&nbsp;
                  </div> :''
                  }

                  <div class="col-14 pr-0 pl-0" align="right">
                    <h6 style={{ color : "black",fontSize:"18px"}}>Pincode<span style={{fontSize:"15px", color: "red"}}><b>*</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <input
                        type="type"
                        onBlur={this.validateCurrentPincode.bind(this)}
                        //onFocus={this.clearMessage}
                        style={{focus: "blue",border:"1px solid #D3D3D3",height:"29px",width:158, color : (!this.state.isCurrentAddressExisting && this.state.disabledCurrentAddr)? "black" : "gray"}}
                        name="current_pincode"
                        value={current_pincode}
                        onChange={this.changeHandler}
                        disabled = {(!this.state.isCurrentAddressExisting && this.state.disabledCurrentAddr)? "" : "disabledCurrentAddr"}
                      /></h6>
                 </div>
                  {(this.state.errors.current_pincode)?
				                  <div class="row-0" align="right"><span  style={{color: "red", align:"left"}}>{this.state.errors.current_pincode}</span>
				                     <span >{(this.state.errors.current_pincode)?<br/> : ''}</span>
                  				   </div> :''
                  }
				 <div class="row" align="right">&nbsp;</div>
                 <div class="row" align="right">
                   <div class="col-md-12 text-right">
                      <Button type="button" variant="light"  class="btn " style={{display: "inline"}}  href="#" onClick={this.enableCurrentAddr.bind(this)} ><FontAwesomeIcon icon={faEdit} /></Button>
                      <Button type="button" variant="primary"  class="btn btn-primary" href="#" style={{display: "inline"}} onFocus={this.validateBeforeCurrentAddressSave} onBlur={this.clearCurrentSuccessMessage} onClick ={this.saveCurrentAddress} >Save</Button>
                   </div>
                  </div>
                  <br/>
                 <div>
                   <span style={{color:  "#228B22"}}><center><b>{this.state.message1}</b></center></span>
                   <span style={{color:  "red"}}><center><b>{this.state.error1}</b></center></span>

                   <span >{this.state.message1 || this.state.error1?<br/> : ''}</span>
                   <br/>
                 </div>
               {/* </div>  */}

                {/*********************************************************PERMANENT ADDRESS*****************************************************************************/}

                <div>
                  <InputGroup className="mb-3" size="sm">
                    <h6 style={{color:"black", fontSize:"18px"}}>Permanent Address:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>
                    <FormControl
                     type="text"
                     onBlur={this.validatePermanentAddrLine1.bind(this)}
                     name="permanent_address_line_1"
                     id="permanent_address_line_1"
                     style={{color :(!this.state.isPermanentAddressExisting && this.state.disabledPermanentAddr)? "black" : "gray",height:"30px",width:200}}
                     placeholder="Address Line 1"
                     name="permanent_address_line_1"
                     value={permanent_address_line_1}
                     onChange={this.changeHandler}
                     //onFocus={this.clearMessage}
                     disabled = {(!this.state.isPermanentAddressExisting && this.state.disabledPermanentAddr)? "" : "disabledPermanentAddr" }
                     aria-describedby="inputGroup-sizing-default"
                    />
                    <h6 style={{ color : "black",fontSize:"18px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Permanent Country<span style={{fontSize:"15px", color: "red"}}><b>*</b></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>
                    <Autocomplete
                     options={this.state.country}
                     style={{ width: 158, outlineColor: "black", display: "inline-block", color: !this.state.isPermanentAddressExisting && this.state.disabledPermanentAddr ? 'black' : 'gray'}}
                     disabled = {(!this.state.isPermanentAddressExisting && this.state.disabledPermanentAddr)? "" : "disabledPermanentAddr"}
                     renderInput={(params) => <TextField {...params} variant="outlined" style={{ color: "black" }} name="permanent_country_name"  size="small"/>}
                     onChange={this.savePermanentCountry}
                     //onSelect={this.onSelectPermanentCountryHandler}
                     onBlur={this.validatePermanentCountry.bind(this)}
                     //onFocus={this.clearMessage}
                     value={this.state.permanent_country_name}
                     noOptionsText='No options'
                   />
                 </InputGroup>
                  {(this.state.errors.permanent_address_line_1 || this.state.errors.permanent_country_name)?
                 <div class='row-0' align="left">
                    <span style={{color: "red"  , align: "left"}} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {this.state.errors.permanent_address_line_1}</span>

                    <span  style={{color: "red", float :"right"}}>
                    {this.state.errors.permanent_country_name}</span>
                    </div>:''}
                    <span >{( this.state.errors.permanent_country_name)?<br/> : ''}</span>

                  <InputGroup className="mb-3" size="sm">
                    <h6><label for="permanant" style={{ color : "black",fontSize:"13px"}}>Same as current Address&nbsp;</label>
                    <input
                      type="checkbox"
                      class="onoffswitch-checkbox"
                      id="inline"
                      name="currentAddrToPermanentAddr"
                      onChange={ this.currentAddrToPermanentAddr}
                      disabled = {(!this.state.isPermanentAddressExisting && this.state.disabledPermanentAddr)? "" : "disabledPermanentAddr"}/>
                    </h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <FormControl
                     type="text"
                     name="permanent_address_line_2"
                     id="permanent_address_line_2"
                     style={{color :(!this.state.isPermanentAddressExisting && this.state.disabledPermanentAddr)? "black" : "gray",height:"30px", width:200}}
                     placeholder="Address Line 2"
                     name="permanent_address_line_2"
                     value={permanent_address_line_2}
                     onChange={this.changeHandler}
                     onFocus={this.state.errors}
                     disabled = {(!this.state.isPermanentAddressExisting && this.state.disabledPermanentAddr)? "" : "disabledPermanentAddr" }
                     aria-describedby="inputGroup-sizing-default"
                    />
                    <h6 style={{ color : "black",fontSize:"18px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;State<span style={{fontSize:"15px", color: "red"}}><b>*</b></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>
                    <Autocomplete
                     options={this.state.permanentState}
                     style={{ width: 158, outlineColor: "black", display: "inline-block", color: !this.state.isPermanentAddressExisting && this.state.disabledPermanentAddr ? 'black' : 'gray'}}
                     disabled = {(!this.state.isPermanentAddressExisting && this.state.disabledPermanentAddr)? "" : "disabledPermanentAddr"}
                     renderInput={(params) => <TextField {...params} variant="outlined" style={{ color: "black" }} name="permanent_state_name" size="small" />}
                     onChange={this.savePermanentState}
                     //onSelect={this.onSelectPermanentStateHandler}
                     value={this.state.permanent_state_name}
                     noOptionsText='No options'
                     onBlur={this.validatePermanentState.bind(this)}
                   />
                 </InputGroup>
                 {
					 (this.state.errors.permanent_state_name)?
				      <div class="row-0" align="right">
				      	<span  style={{color: "red", align:"left"}}>
				      		{this.state.errors.permanent_state_name}
				        </span>
				        <br/>&nbsp;
                  	  </div>
                  	  :''

                 }
                 <InputGroup className="mb-3" size="sm">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <FormControl
                     type="text"
                     name="permanent_address_line_3"
                     id="permanent_address_line_3"
                     style={{color :(!this.state.isPermanentAddressExisting && this.state.disabledPermanentAddr)? "black" : "gray",height:"30px", width:200}}
                     placeholder="Address Line 3"
                     name="permanent_address_line_3"
                     value={permanent_address_line_3}
                     onChange={this.changeHandler}
                     onFocus={this.state.errors}
                     disabled = {(!this.state.isPermanentAddressExisting && this.state.disabledPermanentAddr)? "" : "disabledPermanentAddr" }
                     aria-describedby="inputGroup-sizing-default"
                    />
                    <h6 style={{ color : "black",fontSize:"18px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;City<span style={{fontSize:"15px", color: "red"}}><b>*</b></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>
                    <Autocomplete
                     options={this.state.permanentCity}
                     style={{ width: 158, outlineColor: "black", display: "inline-block", color: !this.state.isPermanentAddressExisting && this.state.disabledPermanentAddr ? 'black' : 'gray'}}
                     disabled = {(!this.state.isPermanentAddressExisting && this.state.disabledPermanentAddr)? "" : "disabledPermanentAddr"}
                     renderInput={(params) => <TextField {...params}  variant="outlined" style={{ color: "black" }} name="permanent_city_name" size="small"/>}
                     onChange={this.savePermanentCity}
                     onBlur={this.validatePermanentCity.bind(this)}
                     //onFocus={this.clearMessage}
                     value={this.state.permanent_city_name}
                     noOptionsText='No options'
                   />
                 </InputGroup>
                 {(this.state.errors.permanent_city_name)?
                 <div class="row-0" align="right"><span  style={{color: "red", align:"left"}}>{this.state.errors.permanent_city_name}</span>
                    <span >{(this.state.errors.permanent_city_name)?<br/> : ''}</span>
                  </div> :''}

                  <div class="col-14 pr-0 pl-0" align="right">
                    <h6 style={{ color : "black",fontSize:"18px"}}>Pincode<span style={{fontSize:"15px", color: "red"}}><b>*</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <input
                        type="type"
                        onBlur={this.validatePermanentPincode.bind(this)}
                        //onFocus={this.clearMessage}
                        style={{focus: "blue",border:"1px solid #D3D3D3",height:"29px",width:158, color : (!this.state.isPermanentAddressExisting && this.state.disabledPermanentAddr)? "black" : "gray"}}
                        name="permanent_pincode"
                        value={permanent_pincode}
                        onChange={this.changeHandler}
                        disabled = {(!this.state.isPermanentAddressExisting && this.state.disabledPermanentAddr)? "" : "disabledPermanentAddr"}
                      /></h6>
                 </div>
                  {(this.state.errors.permanent_pincode)?
				                  <div class="row-0" align="right"><span  style={{color: "red", align:"left"}}>{this.state.errors.permanent_pincode}</span><br/>
				                     <span >{(this.state.errors.permanent_pincode)?<br/>: ''}</span>
                  				   </div> :''
                  }
                 <div class="row" align="right">&nbsp;</div>
                 <div class="row" align="right">
                   <div class="col-md-12 text-right">
                      <Button variant="light" class="btn float" href="#" style={{display: "inline"}}
                      	onClick={this.enablePermanentAddr.bind(this)}><FontAwesomeIcon icon={faEdit} />
                      </Button>
                      <Button variant="primary" class="btn btn-primary" style={{display: "inline"}} href="#"
                      onFocus={this.validateBeforePermanentAddressSave}
                      onBlur={this.clearPermanentSuccessMessage}
                      onClick ={this.savePermanentAddress}>
                      	Save
                      </Button>
                   </div>
                 </div>
                 <br/>
                   <div>
                   <span style={{color:  "#228B22"}}><center><b>{this.state.message2}</b></center></span>
                   <span style={{color:  "red"}}><center><b>{this.state.error2}</b></center></span>
                   <span >{this.state.message2|| this.state.error2?<br/> : ''}</span>
                   </div>
               </div>


                {/*****************************************************************BILLING ADDRESS****************************************************************************/}

                <div>
                  <InputGroup className="mb-3" size="sm">
                    <h6 style={{color:"black", fontSize:"18px"}}>Billing Address:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>
                    <FormControl
                     type="text"
                     onBlur={this.validateBillingAddrLine1.bind(this)}
                     name="billing_address_line_1"
                     id="billing_address_line_1"
                     style={{color :(!this.state.isBillingAddressExisting && this.state.disabledBillingAddr)? "black" : "gray",height:"30px",width:200}}
                     placeholder="Address Line 1"
                     name="billing_address_line_1"
                     value={billing_address_line_1}
                     onChange={this.changeHandler}
                     //onFocus={this.clearMessage}
                     disabled = {(!this.state.isBillingAddressExisting && this.state.disabledBillingAddr)? "" : "disabledBillingAddr" }
                     aria-describedby="inputGroup-sizing-default"
                    />
                    <h6 style={{ color : "black",fontSize:"18px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Country<span style={{fontSize:"15px", color: "red"}}><b>*</b></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>
                    <Autocomplete
                     options={this.state.country}
                     style={{ width: 158, outlineColor: "black", display: "inline-block", color: !this.state.isBillingAddressExisting && this.state.disabledBillingAddr ? 'black' : 'gray'}}
                     disabled = {(!this.state.isBillingAddressExisting && this.state.disabledBillingAddr)? "" : "disabledBillingAddr"}
                      onBlur={this.validateBillingCountry.bind(this)}
                      //onFocus={this.clearMessage}
                     renderInput={(params) => <TextField {...params} variant="outlined" style={{ color: "black" }} name="billing_country_name" size="small"/>}
                     onSelect={this.onSelectBillingCountryHandler}
                     onChange={this.saveBillingCountry}
                     value={this.state.billing_country_name}
                     noOptionsText='No options'
                   />
                 </InputGroup>

                  {(this.state.errors.billing_address_line_1 || this.state.errors.billing_country_name)?
                 <div class='row-0' align="left">
                    <span style={{color: "red"  , align: "left"}} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {this.state.errors.billing_address_line_1}</span>

                    <span  style={{color: "red", float :"right"}}>
                    {this.state.errors.billing_country_name}</span>
                    </div>:''}
                    <span >{( this.state.errors.billing_country_name)?<br/> : ''}</span>

                  <InputGroup className="mb-3" size="sm">
                    <h6><label style={{ color : "black",fontSize:"13px"}} for="permanant">Same as current Address&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                      <input
                      type="radio"
                      class="onoffswitch-checkbox"
                      name="populateBillingAddress"
                      id="inline"
                      onChange={ this.currentAddrToBillingAddr}
                      disabled = {(!this.state.isBillingAddressExisting && this.state.disabledBillingAddr)? "" : "disabledBillingAddr"}/>
                    </h6>&nbsp;&nbsp;&nbsp;&nbsp;
                    <FormControl
                     type="text"
                     name="billing_address_line_2"
                     id="billing_address_line_2"
                     style={{color :(!this.state.isBillingAddressExisting && this.state.disabledBillingAddr)? "black" : "gray",height:"30px", width:200}}
                     placeholder="Address Line 2"
                     name="billing_address_line_2"
                     value={billing_address_line_2}
                     onChange={this.changeHandler}
                     onFocus={this.state.errors}
                     disabled = {(!this.state.isBillingAddressExisting && this.state.disabledBillingAddr)? "" : "disabledBillingAddr" }
                     aria-describedby="inputGroup-sizing-default"
                    />
                    <h6 style={{ color : "black",fontSize:"18px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;State<span style={{fontSize:"15px", color: "red"}}><b>*</b></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>
                    <Autocomplete
                     options={this.state.billingState}
                     style={{ width: 158, outlineColor: "black", display: "inline-block", color: !this.state.isBillingAddressExisting && this.state.disabledBillingAddr ? 'black' : 'gray'}}
                     disabled = {(!this.state.isBillingAddressExisting && this.state.disabledBillingAddr)? "" : "disabledBillingAddr"}
                     renderInput={(params) => <TextField {...params}  variant="outlined"  style={{ color: "black" }} name="billing_state_name" size="small" />}
                     onChange={this.saveBillingState}
                     onBlur={this.validateBillingState.bind(this)}
                     //onSelect={this.SelectCityOnBillingStateHandler}
                     value={this.state.billing_state_name}
                     noOptionsText='No options'
                   />
                 </InputGroup>
                 {
					 (this.state.errors.billing_state_name)?
				      <div class="row-0" align="right">
				      	<span  style={{color: "red", align:"left"}}>
				      		{this.state.errors.billing_state_name}
				        </span>
				        <br/>&nbsp;
                  	  </div>
                  	  :''

                 }
                 <InputGroup className="mb-3" size="sm">
                   <h6><label style={{ color : "black",fontSize:"13px"}} for="current">Same as permanent Address&nbsp;</label>
                   <input
                     type="radio"
                     class="onoffswitch-checkbox"
                     name="populateBillingAddress"
                     id="inline"
                     onChange={ this.permanentAddrToBillingAddr}
                     disabled = {(!this.state.isBillingAddressExisting && this.state.disabledBillingAddr)? "" : "disabledBillingAddr"}/>
                   </h6>&nbsp;&nbsp;&nbsp;
                    <FormControl
                     type="text"
                     name="billing_address_line_3"
                     id="billing_address_line_3"
                     style={{color :(!this.state.isBillingAddressExisting && this.state.disabledBillingAddr)? "black" : "gray",height:"30px", width:200}}
                     placeholder="Address Line 3"
                     name="billing_address_line_3"
                     value={billing_address_line_3}
                     onChange={this.changeHandler}
                     onFocus={this.state.errors}
                     disabled = {(!this.state.isBillingAddressExisting && this.state.disabledBillingAddr)? "" : "disabledBillingAddr" }
                     aria-describedby="inputGroup-sizing-default"
                    />
                    <h6 style={{ color : "black",fontSize:"18px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;City<span style={{fontSize:"15px", color: "red"}}><b>*</b></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>
                    <Autocomplete
                     options={this.state.billingCity}
                     style={{ width: 158, outlineColor: "1px solid gray",display: "1px solid gray", color: !this.state.isBillingAddressExisting && this.state.disabledBillingAddr ? 'black' : 'gray'}}
                     disabled = {(!this.state.isBillingAddressExisting && this.state.disabledBillingAddr)? "" : "disabledBillingAddr"}
                     renderInput={(params) => <TextField {...params}  variant="outlined" style={{ color: "black" }} name="billing_city_name" size="small"/>}
                     onChange={this.saveBillingCity}
                     onBlur={this.validateBillingCity.bind(this)}
                     //onFocus={this.clearMessage}
                     value={this.state.billing_city_name}
                     noOptionsText='No options'
                   />
                 </InputGroup>
                 {(this.state.errors.billing_city_name)?
                 <div class="row-0" align="right"><span  style={{color: "red", align:"left"}}>{this.state.errors.billing_city_name}</span>
                    <span >{(this.state.errors.billing_city_name)?<br/> : ''}</span>
                  </div> :''}

                  <div class="col-14 pr-0 pl-0" align="right">
                    <h6 style={{ color : "black",fontSize:"18px"}}>Pincode<span style={{fontSize:"15px", color: "red"}}><b>*</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <input
                        type="type"
                        onBlur={this.validateBillingPincode.bind(this)}
                        //onFocus={this.clearMessage}
                        style={{focus: "blue",border:"1px solid #D3D3D3",height:"29px",width:158, color : (!this.state.isBillingAddressExisting && this.state.disabledBillingAddr)? "black" : "gray"}}
                        name="billing_pincode"
                        value={billing_pincode}
                        onChange={this.changeHandler}
                        disabled = {(!this.state.isBillingAddressExisting && this.state.disabledBillingAddr)? "" : "disabledBillingAddr"}
                      /></h6>
                 </div>
                  {(this.state.errors.billing_pincode)?
				                  <div class="row-0" align="right"><span  style={{color: "red", align:"left"}}>{this.state.errors.billing_pincode}</span><br/>
				                     <span >{(this.state.errors.billing_pincode)?<br/>: ''}</span>
                  				   </div> :''
                  }
                 <div class="row" align="right">&nbsp;</div>
                 <div class="row" align="right">
                   <div class="col-md-12 text-right">
                      <Button variant="light" class="btn float" style={{display: "inline"}} href="#" onClick={this.enableBillingAddr.bind(this)} ><FontAwesomeIcon icon={faEdit} /></Button>
                      <Button variant="primary" class="btn btn-primary" style={{display: "inline"}} href="#"
                      onFocus={this.validateBeforeBillingAddressSave}
                      onBlur={this.clearBillingSuccessMessage}
                      onClick ={this.saveBillingAddress}
                      >Save</Button>
                   </div>
                 </div>
                 <br/>
                    <div>
                    <span style={{color:  "#228B22"}}><center><b>{this.state.message3}</b></center></span>
                   <span style={{color:  "red"}}><center><b>{this.state.error3}</b></center></span>
                   <span >{this.state.message3 || this.state.error3?<br/> : ''}</span>
                   </div>
               </div>
                {/* ----------------------------------------------------------------------------------------------------------------- */}
              </div>
           </div>
         </div>
       </div>
     </div>
    );
  }
}

    export default UserAddressesComponent;
