import React from 'react';
import { Form,FormControl, InputGroup} from 'react-bootstrap';
import axios from 'axios';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import ls from 'local-storage';
import Moment from 'moment';import { endpoints_properties } from '../../properties/EndPointsProperties.js';
import { api_properties } from '../../properties/APIProperties.js';


var saveOrEditJobURL="";
var userPersonalDetailsURL="";
var industry_url="";
var company_url ="";
var jobTitle_url ="";
var activeCountriesURL="";
var activeDegreesURL="";
var activeLanguagesURL="";
var activeCurrencies="";
var skill_url="";
var getStates="";
var getCities="";

var gtsActiveCountriesCache="";
var gtsActiveStatesByCountryIDCache="";
var gtsActiveCitiesByStateIDCache="";

var env=ls.get('ENV');
var token = ls.get("token");
var	userLoginDetails = ls.get("userLoginDetails");
var userPersonalDetails=ls.get('userPersonalDetails');

if(env=="LOCAL"){
	saveOrEditJobURL=endpoints_properties.ENDPOINT_SC_SERVICES_LOCAL+api_properties.API_SC_SERVICES;
	userPersonalDetailsURL=endpoints_properties.ENDPOINT_PERSONAL_DETAILS_LOCAL+api_properties.API_USER_PERSONAL_DETAILS
	industry_url = endpoints_properties.ENDPOINT_INDUSTRIES_LOCAL+api_properties.API_GET_ACTIVE_INDUSTRIES;
	company_url = endpoints_properties.ENDPOINT_COMPANIES_LOCAL+api_properties.API_GET_ACTIVE_COMPANIES;
	jobTitle_url = endpoints_properties.ENDPOINT_SERVICE_TITLES_LOCAL+api_properties.API_GET_ACTIVE_SERVICE_TITLES;
	activeDegreesURL=endpoints_properties.ENDPOINT_DEGREES_LOCAL+api_properties.API_GET_ACTIVE_DEGREES;
	activeLanguagesURL=endpoints_properties.ENDPOINT_LANGUAGES_LOCAL+api_properties.API_GET_LANGUAGES_ACTIVE;
	activeCurrencies=endpoints_properties.ENDPOINT_CURRENCIES_LOCAL+api_properties.API_GET_ACTIVE_CURRENCIES;
	skill_url = endpoints_properties.ENDPOINT_SKILLS_LOCAL+api_properties.API_GET_ACTIVE_SKILLS;
	activeCountriesURL=endpoints_properties.ENDPOINT_COUNTRIES_LOCAL+api_properties.API_GET_ACTIVE_COUNTRIES;
	getStates =endpoints_properties.ENDPOINT_STATES_LOCAL+api_properties.API_GET_STATES_BY_COUNTRY;
	getCities =endpoints_properties.ENDPOINT_CITIES_LOCAL+api_properties.API_GET_CITIES_BY_STATE;
}
else{
	saveOrEditJobURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_SC_SERVICES;
	userPersonalDetailsURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_USER_PERSONAL_DETAILS
	industry_url = endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_INDUSTRIES;
	company_url = endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_COMPANIES;
	jobTitle_url = endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_SERVICE_TITLES;
	activeDegreesURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_DEGREES;
	activeLanguagesURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_LANGUAGES_ACTIVE;
	activeCurrencies=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_CURRENCIES;
	skill_url = endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_SKILLS;
	activeCountriesURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_COUNTRIES;
	getStates =endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_STATES_BY_COUNTRY;
	getCities =endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_CITIES_BY_STATE;
}

class JobPostComponent extends React.Component{

 constructor(props){
   super(props)
   this.state={
     is_company_requirement: false,
     userPersonalDetails:'',
      gts_job_id : 0,
      successAlert:false,
      jobPosted:[],
      gts_job_skills:[],
      industry:[],
      company:[],
      jobTitle:[],
      city:[],
      state:[],
      country:[],
      degree:[],
      language: [],
      currency:[],
      status:0,
      gts_job_title_id:0,
      gts_company_id:0,
      gts_job_expiry_date:'',
      year_of_exp:0,
      gts_city_id:0,
      gts_industry_id:0,
      gts_country_id :0,
      gts_state_id :0,
      work_type:'',
      gts_job_salary:0,
      gts_currency_id:0,
      gts_salary_duration:0,
      gts_job_type:'',
      gts_job_description:'',
      gts_degree_id:0,
      gts_language_id:0,
      gts_notice_period: 0,
      gts_no_of_vacancy:0,
      gts_user_email:'',
      gts_country_code:0,
      mobile_number:0,
      whatsapp_number:0,
      gts_job_skill_ids:[],
      gts_job_skills_name:[]
    }
    this.changeHandler = this.changeHandler.bind(this)
  }

 changeHandler = (e) => {
   this.setState({ [e.target.name] : e.target.value})
  }


 componentDidMount() {

	/*
	if(userPersonalDetails==null || userPersonalDetails=="" || userPersonalDetails=="undefined"){
    var getUserPersonalDetailsURL=userPersonalDetailsURL+"/gts-user-id/"+userLoginDetails.user_id;

      axios.get(getUserPersonalDetailsURL,{ headers: {"Auth_Token" : `Bearer ${token}`} })
        .then(response => {

			ls.set('userPersonalDetails', response.data);
			//this.setState({userPersonalDetails : response.data})
      })
      .catch(error => {
        console.log("Problem to get Personal Details: "+error);
      });

	}
*/

    axios.get(industry_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
    .then((res) => {
      console.log("industries"+res.data)
      this.setState({ industries: res.data });
      this.state.industries.forEach((industries) => {
      this.state.industry.push(industries.gts_industry_name);
      });
    });


    axios.get(company_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
    .then((res) => {
      console.log("companies"+res.data)
      this.setState({ companies: res.data });
      this.state.companies.forEach((companies) => {
          this.state.company.push(companies.gts_company_name);
      });
    });


    axios.get(jobTitle_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
    .then((res) => {
      console.log("jobTitles"+res.data)
      this.setState({ jobTitles: res.data });
      this.state.jobTitles.forEach((jobTitles) => {
          this.state.jobTitle.push(jobTitles.gts_job_title_name);
      })
    });


	gtsActiveCountriesCache=ls.get('gtsActiveCountriesCache');
    console.log("gtsActiveCountriesCache: "+gtsActiveCountriesCache);

   if(gtsActiveCountriesCache===null || gtsActiveCountriesCache==="" || gtsActiveCountriesCache==="undefined"){
	   axios.get(activeCountriesURL,{ headers: {"Auth_Token" : `Bearer ${token}`} })
		.then((res) =>{
			console.log("Retrieve countries from Database and save into Cache");
			ls.set('gtsActiveCountriesCache', res.data);
			this.setState({ countries : res.data});
			this.state.countries.forEach((countries) => {
			this.state.country.push(countries.gts_country_name);
		 });
	   });
  }
  else{
		console.log("Populating countries from Cache...");
		this.state.countries=gtsActiveCountriesCache;
		console.log("gtsActiveCountriesCache: "+gtsActiveCountriesCache);
		console.log("this.state.countries: "+this.state.countries);
		//this.setState({ countries : gtsActiveCountriesCache});
		this.state.country=[];
		this.state.countries.forEach((country) => {
			this.state.country.push(country.gts_country_name);
		})

	}


   axios.get(activeDegreesURL,{ headers: {"Auth_Token" : `Bearer ${token}`} })
   .then((res) =>{
    console.log("degrees"+res.data)
      this.setState({ degrees : res.data});
        this.state.degrees.forEach((degrees) => {
        this.state.degree.push(degrees.gts_degree_name);
      });
    });

    axios.get(activeLanguagesURL,{ headers: {"Auth_Token" : `Bearer ${token}`} })
    .then((res) =>{
      console.log("languages"+res.data)
      this.setState({ languages : res.data});
        this.state.languages.forEach((languages) => {
        this.state.language.push(languages.gts_language_name);
      });
   });

      axios.get(activeCurrencies,{ headers: {"Auth_Token" : `Bearer ${token}`} })
    .then((res) =>{
      console.log("currencies"+res.data)
      this.setState({ currencies : res.data});
        this.state.currencies.forEach((currencies) => {
        this.state.currency.push(currencies.gts_currency_name+"("+currencies.gts_currency_code+")");
      });
    });


    axios.get(skill_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
    .then((res) => {
      this.setState({ skills : res.data});
      this.state.skills.forEach((skills) => {
       this.state.gts_job_skills.push(skills.gts_skill_name);
      })
    })
  }



 saveJob (gts_job_status) {
   console.log("***Calling saveJob()()****");
   console.log("gts_job_status: "+gts_job_status);
   var gts_job_skill_ids=[];


   console.log("this.state.gts_job_skills_name: "+this.state.gts_job_skills_name);
   if(this.state.gts_job_skills_name!==[] ||this.state.gts_job_skills_name!==null || this.state.gts_job_skills_name!== ''){
      this.state.gts_job_skills_name.forEach((gts_job_skill_name) => {
        this.state.skills.forEach((skill) => {

          if (gts_job_skill_name === skill.gts_skill_name) {
            gts_job_skill_ids.push(skill.gts_skill_id);
          }
        });
      });
    }

console.log("this.state.gts_user_email: "+this.state.gts_user_email);
console.log("this.state.gts_job_title_id: "+this.state.gts_job_title_id);
console.log("this.state.gts_job_skill_ids: "+this.state.gts_job_skill_ids);

   var jobPostPayLoad ={
      gts_job_title_id: this.state.gts_job_title_id,
      gts_job_skill_ids: gts_job_skill_ids.toString(),
      gts_job_is_company_requirement: this.state.is_company_requirement,
      gts_job_company_id: this.state.gts_company_id,
      gts_job_employer_id: userLoginDetails.user_id,
      gts_job_industry_id: this.state.gts_industry_id,
      gts_job_posted_date: Moment(Date.now()).format('YYYY-MM-DD'),
      gts_job_expiry_date: this.state.gts_job_expiry_date,
      gts_job_min_exp_in_months: this.state.year_of_exp,
      gts_job_city_id: this.state.gts_city_id,
      gts_job_country_id: this.state.gts_country_id,
      gts_work_type: this.state.gts_work_type,
      gts_job_salary: this.state.gts_job_salary,
      gts_job_salary_currency_id: this.state.gts_currency_id,
      gts_job_salary_duration_unit: this.state.gts_salary_duration,
      gts_job_type: this.state.gts_job_type,
      gts_job_description: this.state.gts_job_description,
      gts_job_applicant_highest_qualification: this.state.gts_degree_id,
      gts_job_applicant_first_language: this.state.gts_language_id,
      gts_job_expected_hiring_weeks: this.state.gts_notice_period,
      gts_job_vacancy_numbers: this.state.gts_no_of_vacancy,
      gts_job_contact_email: this.state.gts_user_email,
      gts_job_mobile_country_code: this.state.gts_job_mobile_country_code,
      gts_job_contact_mobile_number: this.state.gts_job_contact_mobile_number,
      gts_job_whatsapp_country_code: this.state.gts_job_whatsapp_country_code,
      gts_job_contact_whatsapp_number: this.state.gts_job_contact_whatsapp_number,
      gts_job_status: gts_job_status
    }

   var jobPutPayLoad ={
      gts_job_id: this.state.gts_job_id,
      gts_job_title_id: this.state.gts_job_title_id,
      gts_job_skill_ids: gts_job_skill_ids.toString(),
      gts_job_is_company_requirement: this.state.is_company_requirement,
      gts_job_company_id: this.state.gts_company_id,
      gts_job_employer_id: userLoginDetails.user_id,
      gts_job_industry_id: this.state.gts_industry_id,
      gts_job_posted_date: Moment(Date.now()).format('YYYY-MM-DD'),
      gts_job_expiry_date: this.state.gts_job_expiry_date,
      gts_job_min_exp_in_months: this.state.year_of_exp,
      gts_job_city_id: this.state.gts_city_id,
      gts_job_country_id: this.state.gts_country_id,
      gts_work_type: this.state.gts_work_type,
      gts_job_salary: this.state.gts_job_salary,
      gts_job_salary_currency_id: this.state.gts_currency_id,
      gts_job_salary_duration_unit: this.state.gts_salary_duration,
      gts_job_type: this.state.gts_job_type,
      gts_job_description: this.state.gts_job_description,
      gts_job_applicant_highest_qualification: this.state.gts_degree_id,
      gts_job_applicant_first_language: this.state.gts_language_id,
      gts_job_expected_hiring_weeks: this.state.gts_notice_period,
      gts_job_vacancy_numbers: this.state.gts_no_of_vacancy,
      gts_job_contact_email: this.state.gts_user_email,
      gts_job_mobile_country_code: this.state.gts_job_mobile_country_code,
      gts_job_contact_mobile_number: this.state.gts_job_contact_mobile_number,
      gts_job_whatsapp_country_code: this.state.gts_job_whatsapp_country_code,
      gts_job_contact_whatsapp_number: this.state.gts_job_contact_whatsapp_number,
      gts_job_status: gts_job_status
    }

   console.log("this.state.validated: "+this.state.validated);
   if(this.state.validated=== true){
   console.log("this.state.gts_job_id: "+this.state.gts_job_id);
   console.log("saveOrEditJobURL: "+saveOrEditJobURL);

   if(this.state.gts_job_id === 0){
      axios.post(saveOrEditJobURL,jobPostPayLoad,{ headers: {"Auth_Token" : `Bearer ${token}`} })
      .then(response =>{
		  console.log("response: "+response);
		  console.log("****response.data****: "+response.data);
		  console.log("responsedata.status_code: "+response.data.status_code);
		  console.log("responsedata.message: "+response.data.message);

          if(response.data.status_code === "201"){
            var message = "Job Posted Successfully";
            this.setState({message : message});
            this.setState({successAlert:true});
            this.setState({gts_job_id : response.data.job_post_id });
            console.log("Created Job ID: "+response.data.job_post_id);
          }
          else{
			  console.log("Unable to create Job MMMMMM");
			  this.setState({message : "Not able to create the job, error code: "});
		  }
        })
      .catch(error => {
		  console.log("Error to create Job: "+error);
		  console.log("error.message: "+error.message);

		  this.setState({status:400});

		  if(error.response){
			  console.log("error.response: "+error.response);
			  console.log("error.response.data: "+error.response.data);
			  console.log("error.response.data.message: "+error.response.data.message);
		 	  this.setState({message : error.response.data.message});
			}
		  else{
      	    this.setState({message : error.message});
	      }
        })
      }
   else {
      axios.put(saveOrEditJobURL,jobPutPayLoad,{ headers: {"Auth_Token" : `Bearer ${token}` , "Access-Control-Allow-Origin" : "*"
    , "Access-Control-Allow-Headers" : "*", "Access-Control-Allow-Methods" : "*"} })
      .then(response =>{
            var message = "Job Posted Successfully"
            this.setState({message : message})
            this.setState({successAlert:true});
            this.setState({gts_job_id : response.data.job_post_id })
            console.log("Updated Job ID: "+response.data.job_post_id);
        })
      .catch(error => {
		  console.log("Error to update Job: "+error);
          this.setState({status:400});
          this.setState({message : error.response.data.message});
        })
      }
    }
    }



  populateStates(){
	//Loading active states
	gtsActiveStatesByCountryIDCache=ls.get('gtsActiveStatesByCountryIDCache-'+this.state.gts_country_id);

	if(gtsActiveStatesByCountryIDCache===null || gtsActiveStatesByCountryIDCache==="" || gtsActiveStatesByCountryIDCache==="undefined"){
		console.log("Loading states from Database");

		var getStatesURLLocal=getStates+'/'+this.state.gts_country_id;

		axios.get(getStatesURLLocal,{ headers: {"Auth_Token" : `Bearer ${token}`} })
		.then((res) =>{

			ls.set('gtsActiveStatesByCountryIDCache-'+this.state.gts_country_id, res.data);

			this.setState({states: []})

			this.setState({ states: res.data });
			this.state.states.forEach((state) => {
				this.state.state.push(state.gts_state_name);
			})
		})
		.catch(err => {
			this.setState({state: []});
			this.setState({states: []});
			ls.set('gtsActiveStatesByCountryIDCache-'+this.state.gts_country_id, "");
		}
		);
	}
	else{

		console.log("Populating states from Cache...");
		this.state.states=gtsActiveStatesByCountryIDCache;
		this.state.state=[];
		this.state.states.forEach((state) => {
			this.state.state.push(state.gts_state_name);
		})

	}

  }

  populateCities(){
	 //Loading active cities
	console.log("In populateCities()- this.state.gts_state_id: "+this.state.gts_state_id);
	gtsActiveCitiesByStateIDCache=ls.get('gtsActiveCitiesByStateIDCache-'+this.state.gts_state_id);

	console.log("gtsActiveCitiesByStateIDCache: "+gtsActiveCitiesByStateIDCache);

	if(gtsActiveCitiesByStateIDCache===null || gtsActiveCitiesByStateIDCache==="" || gtsActiveCitiesByStateIDCache==="undefined"){
		 var getCitiesURLLocal =getCities+'/'+this.state.gts_state_id;
		 axios.get(getCitiesURLLocal,{ headers: {"Auth_Token" : `Bearer ${token}`} })
		 .then((res) =>{
			 ls.set('gtsActiveCitiesByStateIDCache-'+this.state.gts_state_id, res.data);
			  this.setState({cities: []})
			  this.setState({ cities: res.data });
			  this.state.cities.forEach((city) => {
			  this.state.city.push(city.gts_city_name);
		  })
		})
		.catch(err => {
			console.log("err: "+err);
			this.setState({city: []});
			this.setState({cities: []});
			ls.set('gtsActiveCitiesByStateIDCache-'+this.state.gts_state_id, "");
		}
		);

	}
	else{
		  console.log("Loading cities from Cache");
		  this.state.cities=gtsActiveCitiesByStateIDCache;
		  this.setState({ cities: gtsActiveCitiesByStateIDCache });
		  this.state.cities.forEach((city) => {
		  this.state.city.push(city.gts_city_name);
		}
		)
		}
  }


 saveAutoCompleteCompany=(event, value) => {
    this.state.selectedCompany = value;
    this.state.gts_company_name = value;
    if(value === ''  || value=== null || value==="undefined"){
      this.setState ({gts_company_id : ''})
    }
    else{
	//if(this.state.companies){
      this.state.companies.forEach((company) => {
        if (this.state.selectedCompany === company.gts_company_name) {
          this.state.gts_company_id = company.gts_company_id;
          this.state.gts_job_company_description = company.gts_company_description;
        }
       })
   		//}
    }
  }

  saveAutoCompleteIndustry=(event, value) => {
    this.state.selectedIndustry = value;
    this.state.gts_industry_name = value;
    if(value === ''  || value=== null || value==="undefined"){
      this.setState ({gts_industry_id : ''})
    }
    else{
		//if(this.state.industries){
		  this.state.industries.forEach((industries) => {
			if (this.state.selectedIndustry === industries.gts_industry_name) {
			  this.state.gts_industry_id = industries.gts_industry_id;
			}
		   })
   		//}
    }
  }

 saveAutoCompleteJobTitle=(event, value) => {
    this.state.selectedJobTitle = value;
    this.state.gts_job_title = value;

    console.log("this.state.jobTitles: "+this.state.jobTitles);

    if(value === ''  || value=== null || value==="undefined"){
      this.setState({gts_job_title_id : ''})
    }
     else{
	 //if(this.state.jobTitles){
      this.state.jobTitles.forEach((jobTitle) => {
        if (this.state.selectedJobTitle === jobTitle.gts_job_title_name) {
          this.state.gts_job_title_id = jobTitle.gts_job_title_id;
        }
       })
     //}
    }
  }





 saveAutoCompleteCity = (event, value) => {
    this.state.selectedCity = value;
    this.state.gts_city_name = value;
    if(value === ''  || value=== null || value==="undefined"){
      this.setState({gts_city_id : ''})
    }
     else{
	//if(this.state.cities){
      this.state.cities.forEach((cities) => {
        if (this.state.selectedCity === cities.gts_city_name) {
          this.state.gts_city_id = cities.gts_city_id;
        }
       })
     // }

     }
  }

 saveAutoCompleteCountry = (event, value) => {
    this.state.selectedCountry = value;
    this.state.gts_country_name = value;

    if(value === ''  || value=== null || value==="undefined"){
      this.setState({gts_country_name : ''})
      this.setState({gts_country_id : ''})
    }
     else{
	//if(this.state.countries){
      this.state.countries.forEach((countries) => {
        if (this.state.selectedCountry === countries.gts_country_name) {
          this.state.gts_country_id = countries.gts_country_id;
        }
      })
  	//}
   }

   this.populateStates();
  }

   saveAutoCompleteState = (event, value) => {
      this.state.selectedState = value;
      this.state.gts_state_name = value;

      if(value === ''  || value=== null || value==="undefined"){
        this.setState({gts_state_id : ''})
      }
       else{
	//if(this.state.states){
        this.state.states.forEach((states) => {
          if (this.state.selectedState === states.gts_state_name) {
            this.state.gts_state_id = states.gts_state_id;
          }
         })
		//}

       }
       this.populateCities();
  }

  saveAutoCompleteCurrency = (event, value) => {
    this.state.selectedCurrency = value;
    this.state.gts_currency_name = value;
    if(value === ''  || value=== null || value==="undefined"){
      this.setState({gts_currency_name : ''})
      this.setState({gts_currency_id : ''})
    }
     else{
	//if(this.state.currencies){
      this.state.currencies.forEach((currencies) => {
        if (this.state.selectedCurrency.split('(')[0] === currencies.gts_currency_name) {
          this.state.gts_currency_id = currencies.gts_currency_id;
        }
      })
  	//}
   }
  }

  saveAutoCompleteDegree = (event, value) => {
    this.state.selectedDegree = value;
    this.state.gts_degree_name = value;
    if(value === ''  || value=== null || value==="undefined"){
      this.setState({gts_degree_name : ''})
      this.setState({gts_degree_id : ''})
    }
     else{
	//if(this.state.degrees){
      this.state.degrees.forEach((degrees) => {
        if (this.state.selectedDegree === degrees.gts_degree_name) {
          this.state.gts_degree_id = degrees.gts_degree_id;
        }
      })
  //	}
   }
  }

  saveAutoCompleteLanguage = (event, value) => {
    this.state.selectedLanguage = value;
    this.state.gts_language_name = value;
    if(value === ''  || value=== null || value==="undefined"){
      this.setState({gts_language_name : ''})
      this.setState({gts_language_id : ''})
    }
     else{
	//if(this.state.languages){
      this.state.languages.forEach((languages) => {
        if (this.state.selectedLanguage === languages.gts_language_name) {
          this.state.gts_language_id = languages.gts_language_id;
        }
      })
 	// }
   }
  }

 saveAutoCompleteInput = (event, value) => {
   if(value!== '' || value!== null){
    this.state.gts_job_skills_name = value;
   }
   else{
    this.state.gts_job_skills_name = '';
   }
  }

  onSelectWorkType =(e)=> {
     this.state.gts_work_type= e.target.value
  }

  onSelectJobType =(e)=> {
    this.state.gts_job_type= e.target.value
 }

 onSelectSalaryDuration =(e)=> {
   if(e.target.value === "Year"){
     this.state.gts_salary_duration = 1
   }

   if(e.target.value === "Month"){
     this.state.gts_salary_duration = 2
   }

   if(e.target.value === "Week"){
     this.state.gts_salary_duration = 3
   }

   if(e.target.value === "Day"){
     this.state.gts_salary_duration = 4
   }

   if(e.target.value === "Hour"){
     this.state.gts_salary_duration = 5
    }
  }

 onSelectEmail = (e) =>{
    this.state.gts_user_email= e.target.value
  }

 onSelectMobileNumber = (e) =>{
    this.state.gts_job_contact_mobile_number= e.target.value
  }

 onSelectWhatsAppNumber = (e) =>{
    this.state.gts_job_contact_whatsapp_number= e.target.value
  }

 onSelectMobilecode = (e) =>{
    this.state.gts_job_mobile_country_code= e.target.value
  }

 onSelectWhatsAppcode = (e) =>{
    this.state.gts_job_whatsapp_country_code= e.target.value
  }

 selectExpiryDate = (e) =>{
    this.state.gts_job_expiry_date = e.target.value
    this.state.gts_job_expiry_date = Moment(this.state.gts_job_expiry_date).format('YYYY-MM-DD')
  }

  companyRequirement =(e) =>{
	let companyError = this.state.companyError;

    this.state.is_company_requirement = e.target.checked;
    if(e.target.checked === true){
     this.setState({is_company_requirement : true})
    }
    else{
      this.setState({is_company_requirement : false})
    companyError = '';
    this.setState({
      validated:true,
      companyError: companyError
    });

    }
  }

 validateJobTitle = e =>{
  let formIsValid = true;
  let jobTitleError = this.state.jobTitleError;
  if (this.state.gts_job_title_id === 0 || this.state.gts_job_title_id=='' || this.state.gts_job_title_id=== null || this.state.gts_job_title_id===undefined) {
    formIsValid = false;
    jobTitleError = "Please select service name.";
    this.setState({
      validated: false,
      jobTitleError: jobTitleError
    });
  }
  else{
    jobTitleError = '';
    this.setState({
      validated: true,
      jobTitleError: jobTitleError
    });
  }
 }

 validateSkill = e =>{
  let formIsValid = true;
  let skillError = this.state.skillError;
  if (this.state.gts_job_skills_name === '') {
    formIsValid = false;
    skillError = "Please select skills.";
    this.setState({
      validated: false,
      skillError: skillError
    });
  }
  else{
    skillError = '';
    this.setState({
      validated: true,
      skillError: skillError
    });
  }
 }

 validateIndustry = e =>{
  let formIsValid = true;
  let industryError = this.state.industryError;
  if (this.state.gts_industry_id ===0 || this.state.gts_industry_id==='' || this.state.gts_industry_id=== null || this.state.gts_industry_id===undefined) {
    formIsValid = false;
    industryError = "Please select industry.";
    this.setState({
      validated: false,
      industryError: industryError
    });
  }
  else{
    industryError = '';
    this.setState({
      validated: true,
      industryError: industryError
    });
  }
 }


 validateCompany = e =>{
  let formIsValid = true;
  let companyError = this.state.companyError;


  if ((this.state.is_company_requirement === true) && (this.state.gts_company_id === 0 || this.state.gts_company_id==='' || this.state.gts_company_id=== null || this.state.gts_company_id===undefined)) {
    formIsValid = false;
    companyError = "Please select company.";
    this.setState({
      validated: false,
      companyError: companyError
    });
  }
  else{
    companyError = '';
    this.setState({
      validated:true,
      companyError: companyError
    });
  }
 }


  validateState = e =>{
   let formIsValid = true;
   let stateError = this.state.stateError;
   if (this.state.gts_state_id === 0 || this.state.gts_state_id==='' || this.state.gts_state_id=== null || this.state.gts_state_id===undefined) {
     formIsValid = false;
     stateError = "Please select state.";
     this.setState({
       validated: false,
       stateError: stateError
     });
   }
   else{
     stateError = '';
     this.setState({
       validated: true,
       stateError: stateError
     });
   }
 }



 validateCity = e =>{
  let formIsValid = true;
  let cityError = this.state.cityError;
  if (this.state.gts_city_id === 0 || this.state.gts_city_id==='' || this.state.gts_city_id=== null || this.state.gts_city_id===undefined) {
    formIsValid = false;
    cityError = "Please select city.";
    this.setState({
      validated: false,
      cityError: cityError
    });
  }
  else{
    cityError = '';
    this.setState({
      validated: true,
      cityError: cityError
    });
  }
 }

 validateCountry = e =>{
  let formIsValid = true;
  let countryError = this.state.countryError;
  if (this.state.gts_country_id === 0 || this.state.gts_country_id==='' || this.state.gts_country_id=== null || this.state.gts_country_id===undefined) {
    formIsValid = false;
    countryError = "Please select country.";
    this.setState({
      validated: false,
      countryError: countryError
    });
  }
  else{
    countryError = '';
    this.setState({
      validated: true,
      countryError: countryError
    });
  }
 }

 validateCurrency =e =>{
  let formIsValid = true;
  let salaryCurrencyError = this.state.salaryCurrencyError;
  if (this.state.gts_currency_id ===0 || this.state.gts_currency_id==='' || this.state.gts_currency_id=== null || this.state.gts_currency_id===undefined) {
    formIsValid = false;
    salaryCurrencyError = "Please select salary currency.";
    this.setState({
      validated: false,
      salaryCurrencyError: salaryCurrencyError
    });
  }
  else{
    salaryCurrencyError = '';
    this.setState({
      validated: true,
      salaryCurrencyError: salaryCurrencyError
    });
  }
 }

 validateSalary = e =>{
  let formIsValid = true;
  let salaryError = this.state.salaryError;
  if (this.state.gts_job_salary ===0 || this.state.gts_job_salary==='' || this.state.gts_job_salary=== null || this.state.gts_job_salary===undefined) {
    formIsValid = false;
    salaryError = "Please enter salary.";
    this.setState({
      validated: false,
      salaryError: salaryError
    });
  }
  else{
    salaryError = '';
    this.setState({
      validated: true,
      salaryError: salaryError
    });
  }
 }

 validateDuration = e =>{
  let formIsValid = true;
  let salaryDurationError = this.state.salaryDurationError;
  if (this.state.gts_salary_duration ===0 || this.state.gts_salary_duration==='' || this.state.gts_salary_duration=== null || this.state.gts_salary_duration===undefined) {
    formIsValid = false;
    salaryDurationError = "Please enter salary duration.";
    this.setState({
      validated: false,
      salaryDurationError: salaryDurationError
    });
  }
  else{
    salaryDurationError = '';
    this.setState({
      validated: true,
      salaryDurationError: salaryDurationError
    });
  }
 }

 validateDegree = e =>{
  let formIsValid = true;
  let degreeError = this.state.degreeError;
  if (this.state.gts_degree_id === 0|| this.state.gts_degree_id==='' || this.state.gts_degree_id=== null || this.state.gts_degree_id===undefined) {
    formIsValid = false;
    degreeError = "Please select degree.";
    this.setState({
      validated: false,
      degreeError: degreeError
    });
  }
  else{
    degreeError = '';
    this.setState({
      validated: true,
      degreeError: degreeError
    });
  }
 }

 validateLanguage = e =>{
  let formIsValid = true;
  let langError = this.state.langError;
  if (this.state.gts_language_id ===0 || this.state.gts_language_id==='' || this.state.gts_language_id=== null || this.state.gts_language_id===undefined) {
    formIsValid = false;
    langError = "Please select language.";
    this.setState({
      validated: false,
      langError: langError
    });
  }
  else{
    langError = '';
    this.setState({
      validated: true,
      langError: langError
    });
  }
 }

 validateNotice = e =>{
  let formIsValid = true;
  let noticeError = this.state.noticeError;
  if (this.state.gts_notice_period==='' || this.state.gts_notice_period=== null || this.state.gts_notice_period===undefined) {
    formIsValid = false;
    noticeError = "Please enter excepted hiring weeks.";
    this.setState({
      noticeError: noticeError
    });
  }
  else{
    noticeError = '';
    this.setState({
      noticeError: noticeError
    });
  }
 }

 validateFields = e => {
    let formIsValid = true;
    let jobTitleError = this.state.jobTitleError;
    if (this.state.gts_job_title_id === 0 || this.state.gts_job_title_id==='' || this.state.gts_job_title_id=== null || this.state.gts_job_title_id===undefined) {
      formIsValid = false;
      jobTitleError = "Please select service name.";
      this.setState({
        validated: false,
        jobTitleError: jobTitleError
      });
    }
    else{
      jobTitleError = '';
      this.setState({
        validated: true,
        jobTitleError: jobTitleError
      });
    }

    let skillError = this.state.skillError;
    if (this.state.gts_job_skills_name === '') {
      formIsValid = false;
      skillError = "Please select skills.";
      this.setState({
        validated: false,
        skillError: skillError
      });
    }
    else{
      skillError = '';
      this.setState({
        validated: true,
        skillError: skillError
      });
    }

    let industryError = this.state.industryError;
    if (this.state.gts_industry_id ===0 || this.state.gts_industry_id==='' || this.state.gts_industry_id=== null || this.state.gts_industry_id===undefined) {
      formIsValid = false;
      industryError = "Please select industry.";
      this.setState({
        validated: false,
        industryError: industryError
      });
    }
    else{
      industryError = '';
      this.setState({
        validated: true,
        industryError: industryError
      });
    }

    let companyError = this.state.companyError;
    if ((this.state.is_company_requirement === true) && (this.state.gts_company_id === 0 || this.state.gts_company_id==='' || this.state.gts_company_id=== null || this.state.gts_company_id===undefined)) {
      formIsValid = false;
      companyError = "Please select company.";
      this.setState({
        validated: false,
        companyError: companyError
      });
    }
    else{
      companyError = '';
      this.setState({
        validated:true,
        companyError: companyError
      });
    }

    let cityError = this.state.cityError;
    if (this.state.gts_city_id === 0 || this.state.gts_city_id==='' || this.state.gts_city_id=== null || this.state.gts_city_id===undefined) {
      formIsValid = false;
      cityError = "Please select city.";
      this.setState({
        validated: false,
        cityError: cityError
      });
    }
    else{
      cityError = '';
      this.setState({
        validated: true,
        cityError: cityError
      });
    }

    let countryError = this.state.countryError;
    if (this.state.gts_country_id === 0 || this.state.gts_country_id==='' || this.state.gts_country_id=== null || this.state.gts_country_id===undefined) {
      formIsValid = false;
      countryError = "Please select country.";
      this.setState({
        validated: false,
        countryError: countryError
      });
    }
    else{
      countryError = '';
      this.setState({
        validated: true,
        countryError: countryError
      });
    }

    let salaryCurrencyError = this.state.salaryCurrencyError;
    if (this.state.gts_currency_id ===0 || this.state.gts_currency_id==='' || this.state.gts_currency_id=== null || this.state.gts_currency_id===undefined) {
      formIsValid = false;
      salaryCurrencyError = "Please select salary currency.";
      this.setState({
        validated: false,
        salaryCurrencyError: salaryCurrencyError
      });
    }
    else{
      salaryCurrencyError = '';
      this.setState({
        validated: true,
        salaryCurrencyError: salaryCurrencyError
      });
    }

    let salaryError = this.state.salaryError;
    if (this.state.gts_job_salary ===0 || this.state.gts_job_salary==='' || this.state.gts_job_salary=== null || this.state.gts_job_salary===undefined) {
      formIsValid = false;
      salaryError = "Please enter salary.";
      this.setState({
        validated: false,
        salaryError: salaryError
      });
    }
    else{
      salaryError = '';
      this.setState({
        validated: true,
        salaryError: salaryError
      });
    }

    let salaryDurationError = this.state.salaryDurationError;
    if (this.state.gts_salary_duration ===0 || this.state.gts_salary_duration==='' || this.state.gts_salary_duration=== null || this.state.gts_salary_duration===undefined) {
      formIsValid = false;
      salaryDurationError = "Please enter salary duration.";
      this.setState({
        validated: false,
        salaryDurationError: salaryDurationError
      });
    }
    else{
      salaryDurationError = '';
      this.setState({
        validated: true,
        salaryDurationError: salaryDurationError
      });
    }

    let degreeError = this.state.degreeError;
    if (this.state.gts_degree_id === 0|| this.state.gts_degree_id==='' || this.state.gts_degree_id=== null || this.state.gts_degree_id===undefined) {
      formIsValid = false;
      degreeError = "Please select degree.";
      this.setState({
        validated: false,
        degreeError: degreeError
      });
    }
    else{
      degreeError = '';
      this.setState({
        validated: true,
        degreeError: degreeError
      });
    }

    let langError = this.state.langError;
    if (this.state.gts_language_id ===0 || this.state.gts_language_id==='' || this.state.gts_language_id=== null || this.state.gts_language_id===undefined) {
      formIsValid = false;
      langError = "Please select language.";
      this.setState({
        validated: false,
        langError: langError
      });
    }
    else{
      langError = '';
      this.setState({
        validated: true,
        langError: langError
      });
    }

    let noticeError = this.state.noticeError;
    if (this.state.gts_notice_period==='' || this.state.gts_notice_period=== null || this.state.gts_notice_period===undefined) {
      formIsValid = false;
      noticeError = "Please enter excepted hiring weeks.";
      this.setState({
        noticeError: noticeError
      });
    }
    else{
      noticeError = '';
      this.setState({
        noticeError: noticeError
      });
    }
  }


render()
  {
    const{
      gts_job_title_name,
      gts_job_min_exp,
      gts_job_posted_date,
      gts_city_name,
      gts_skill_name,
      gts_skill_is_primary,
      MinNoticePeriod,
      gts_job_description,
      gts_job_salary,
      gts_job_applicant_highest_qualification
    }=this.state

    return(
    <>
      <div className="container align-items-center">
        <div className='mt-3'>
          <div className="border border-dark rounded-lg" >
             <div className="row-0">
               <div class="col-0 p-1">
                 <h5><b> &nbsp;&nbsp;&nbsp;POST A SERVICE</b></h5>
               </div>
              </div>
              <div style={{borderBottomColor: 'black',borderBottomWidth: "0.5px"}}/><br/>
              <div class="container">
                  <div class = "row-0">
                    <h6 style={{color : "black"}}><label style={{fontSize:"15px"}}><b><input type ="checkbox" id="companyRequirement" onChange={this.companyRequirement}></input>&nbsp;&nbsp;Company Requirement</b></label></h6>
                  </div>
                  <InputGroup>
                      <div class="row-0" align="left">
                        <div class="col-0" align="left">
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Service Name<span style={{color:'red'}}>*</span> :</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <Autocomplete
                            options={this.state.jobTitle}
                            style={{ width: "170px",height:"30px", outlineColor: "black", display: "inline-block"}}
                            renderInput={(params) => <TextField {...params} id="gts_job_title" variant="outlined" style={{ color: "black" }} name="gts_job_title" size="small"/>}
                            onChange={this.saveAutoCompleteJobTitle}
                            onBlur={this.validateJobTitle.bind(this)}
                          /></label></h6>
                          <div class="row" >
                            <span align="center" style={{color:'red'}}>{this.state.jobTitleError}</span>
                            <span>{this.state.jobTitleError? <br/> : '' }</span>
                          </div>
                          <h6 style={{color : "black", display:this.state.gts_job_title}}><label style={{fontSize:"15px"}}><b>Industry Name<span style={{color:'red'}}>*</span> :&nbsp;&nbsp;</b>&nbsp;&nbsp;
                          <Autocomplete
                            options={this.state.industry}
                            style={{ width: "170px",maxHeight: '15rem', outlineColor: "black", display: "inline-block"}}
                            renderInput={(params) => <TextField {...params} id="gts_industry_name" variant="outlined" style={{ color: "black",maxHeight: '15rem' }} name="gts_industry_name" size="small"/>}
                            onChange={this.saveAutoCompleteIndustry}
                            noOptionsText='No options'
                            onBlur={this.validateIndustry.bind(this)}
                          /></label></h6>
                          <div class="row">
                            <span align="center" style={{color:'red'}}>{this.state.industryError}</span>
                            <span>{this.state.industryError? <br/> : '' }</span>
                          </div>
                          <h6  style={{color : "black", display : this.state.is_company_requirement === true ? 'block' : 'none'}}><label style={{fontSize:"15px"}}><b>Company Name<span style={{color:'red'}}>*</span> :</b>&nbsp;&nbsp;
                          <Autocomplete
                            options={this.state.company}
                            style={{ width: "170px",maxHeight: '15rem', outlineColor: "black", display: "inline-block"}}
                            renderInput={(params) => <TextField {...params} id="gts_company_name" variant="outlined" style={{ color: "black",maxHeight: '15rem' }} name="gts_company_name" size="small"/>}
                            onChange={this.saveAutoCompleteCompany}
                            noOptionsText='No options'
                            onBlur={this.validateCompany.bind(this)}
                          /></label></h6>
                          <div class="row">
                            <span align="center" style={{color:'red'}}>{this.state.companyError}</span>
                            <span>{this.state.companyError? <br/> : '' }</span>
                          </div>
                        </div>
                     </div>
                      <h6  style={{color : "black"}}><label style={{fontSize:"15px"}} ><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Expiry Date<span style={{color:'red'}}>*</span> :</b>&nbsp;&nbsp;
                      <input
                        type = "date"
                        style={{ border: "1px solid #D3D3D3", height:"30px", width:"144px"}}
                        id='gts_job_expiry_date'
                        name="gts_job_expiry_date"
                        onChange={this.selectExpiryDate}
                       /></label></h6>
                      <div class="row-0" align="right">
                        <div class="col-0" align="right">
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}} ><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Experience(Months)<span style={{color:'red'}}>*</span> :</b>&nbsp;&nbsp;
                         <input
                           type = "text"
                           style={{ border: "1px solid #D3D3D3", height:"30px", width:"50px"}}
                           onChange={this.changeHandler}
                           id='year_of_exp'
                           name='year_of_exp'
                          /></label></h6>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}} ><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Work Type<span style={{color:'red'}}>*</span> :</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <select style={{ border: "1px solid #D3D3D3", height:"30px", width:"100px"}} id="workType" onChange={this.onSelectWorkType}>
                             <option hidden selected value="select">Select</option>
                            <option>REMOTE</option>
                            <option>ONSITE</option>
                          </select></label></h6>
                         <h6  style={{color : "black"}}><label style={{fontSize:"15px"}} ><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Job Type<span style={{color:'red'}}>*</span> :</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                         <select style={{ border: "1px solid #D3D3D3", height:"30px", width:"100px"}} id="jobType" onChange={this.onSelectJobType}>
                           <option hidden selected value="select">Select</option>
                            <option >FULL-TIME</option>
                            <option>PART-TIME</option>
                          </select></label></h6>
                       </div>
                     </div>
                     <div class="row-0" align="right">
                        <div class="col-0" align="right">
                          <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Country<span style={{color:'red'}}>*</span> :</b>
                            &nbsp;
                          <Autocomplete
                            options={this.state.country}
                            style={{ width: "150px", outlineColor: "black", display: "inline-block"}}
                            renderInput={(params) => <TextField {...params} id="gts_user_company_country" variant="outlined" style={{ color: "black" }} name="gts_user_company_country" size="small"/>}
                            onChange={this.saveAutoCompleteCountry}
                            //onSelect={this.onSelectCountryHandler}
                            noOptionsText='No options'
                            onBlur={this.validateCountry.bind(this)}
                            size="small"
                          /></label></h6>
                          <div class="row">
                            <span align="center" style={{color:'red'}}>{this.state.countryError}</span>
                            <span>{this.state.countryError? <br/> : '' }</span>
                          </div>



                          <h6 class="form-group" style={{color : "black"}}>
							  <label style={{fontSize:"15px"}}>
								  <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								  State
								  <span style={{color:'red'}}>*</span>

								  :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
								&nbsp;

									<Autocomplete
									  options={this.state.state}
									  style={{ width: "150px", outlineColor: "black", display: "inline-block"}}
									  renderInput={

										  (
											  params) =>

											  <TextField {...params} id="gts_state_id" variant="outlined" style={{ color: "black" }} name="gts_user_company_state" size="small"/>
									      }
									  onChange={this.saveAutoCompleteState}
									  //onSelect={this.onSelectStateHandler}
									  noOptionsText='No options'
									  onBlur={this.validateState.bind(this)}
									  size="small"
									/>
									</label></h6>
									<div class="row">
									  <span align="center" style={{color:'red'}}>{this.state.cityError}</span>
									  <span>{this.state.cityError? <br/> : '' }</span>
									</div>



                          <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;City<span style={{color:'red'}}>*</span> :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                          &nbsp;
                          <Autocomplete
                            options={this.state.city}
                            style={{ width: "150px", outlineColor: "black", display: "inline-block"}}
                            renderInput={(params) => <TextField {...params} id="gts_user_company_city" variant="outlined" style={{ color: "black" }} name="gts_user_company_city" size="small"/>}
                            onChange={this.saveAutoCompleteCity}
                            noOptionsText='No options'
                            onBlur={this.validateCity.bind(this)}
                            size="small"
                          />

                          </label></h6>
                          <div class="row">
                            <span align="center" style={{color:'red'}}>{this.state.cityError}</span>
                            <span>{this.state.cityError? <br/> : '' }</span>
                          </div>
                       </div>
                     </div>
                   </InputGroup>
                    <div className="form-row">
                      <h6 class="text" style={{color : "black", display : this.state.is_company_requirement === true ? 'block' : 'none'}}><label style={{fontSize:"15px"}}><b>&nbsp;Company/SC Description:&nbsp;&nbsp;&nbsp;</b></label></h6>
                     <FormControl
                       as="textarea"
                       rows={'auto'}
                       onChange={this.changeHandler}
                       name="gts_company_description"
                       style={{ display : this.state.is_company_requirement === true ? 'block' : 'none'}}
                       defaultValue={this.state.gts_job_company_description}
                       id="gts_company_description"
                       placeholder="Company Description"
                       onFocus={this.clearMessage}
                      />
                   </div>
                   <br/>
                   <div className="form-row">
				              <h6 class="text" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;Service Description:</b></label></h6>
                      <Form.Control
                         as="textarea"
                         rows={4}
                         onChange={this.changeHandler}
                         name="gts_job_description"
                         id="gts_job_description"
                         placeholder="Job Description"
                         onFocus={this.clearMessage}
                      />
                   </div><br/>
                   <div className="form-row">
								      <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;Skills<span style={{color:'red'}}>*</span> :</b></label></h6>
                      <Autocomplete multiple
                          options={this.state.gts_job_skills}
                          style={{width: "100%", outlineColor: "black"}}
                          filterSelectedOptions
                          onChange={this.saveAutoCompleteInput}
                          //  {this.autoCompleteChangeHandler(params);
                          renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Skills"/>}
                          noOptionsText='No options'
                          onBlur={this.validateSkill}
                          size="small"
                     />
                     <div class="row">
                        <span align="center" style={{color:'red'}}>{this.state.skillError}</span>
                        <span>{this.state.skillError? <br/> : '' }</span>
                      </div>
                   </div>
                   <br/>
                     <InputGroup>
                        <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Minimum Qualification<span style={{color:'red'}}>*</span> :</b>
                        &nbsp;
                        <Autocomplete
                         options={this.state.degree}
                         style={{ width: "140px", outlineColor: "black", display: "inline-block"}}
                         renderInput={(params) => <TextField {...params} id="gts_job_degree" variant="outlined" style={{ color: "black" }} name="gts_job_degree" size="small"/>}
                         onChange={this.saveAutoCompleteDegree}
                         noOptionsText='No options'
                         onBlur={this.validateDegree.bind(this)}
                         size="small"
                       /></label></h6>
                       <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;Language Proficiency<span style={{color:'red'}}>*</span> :</b>
                       &nbsp;
                       <Autocomplete
                         options={this.state.language}
                         style={{ width: "140px", outlineColor: "black", display: "inline-block"}}
                         renderInput={(params) => <TextField {...params} id="gts_job_language" variant="outlined" style={{ color: "black" }} name="gts_job_language" size="small"/>}
                         onChange={this.saveAutoCompleteLanguage}
                         noOptionsText='No options'
                         onBlur={this.validateLanguage.bind(this)}
                         size="small"
                       /></label></h6>
                       <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;Notice Period(Days)<span style={{color:'red'}}>*</span> :</b>&nbsp;</label></h6>
                       <input
                         type="text"
                         style={{ border: "1px solid #D3D3D3", height:"30px", width:"60px"}}
                         onChange={this.changeHandler}
                         onBlur={this.validateNotice.bind(this)}
                         id='gts_notice_period'
                         name='gts_notice_period'
                       />
                       <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;No. of Vacancies :</b>&nbsp;</label></h6>
                       <input
                          type="text"
                          style={{ border: "1px solid #D3D3D3", height:"30px", width:"60px"}}
                          onChange={this.changeHandler}
                          id='gts_no_of_vacancy'
                          name='gts_no_of_vacancy'
                        />
                     </InputGroup>
                     <div class="row">
                     <div class="col-4" align="left">
                        <span align="center" style={{color:'red'}}>{this.state.degreeError}</span>
                        </div>
                        <div class="col-3">
                        <span align="center" style={{color:'red'}}>{this.state.langError}</span>
                      </div>
                      <div class="col">
                        <span align="center" style={{color:'red'}}>{this.state.noticeError}</span>
                      </div>
                      </div>
                      { this.state.degreeError || this.state.langError || this.state.noticeError ?
                      <div class="row">
                        <span><br/></span>
                      </div>
                      : ''}
                     <InputGroup>
                       <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Salary Currency<span style={{color:'red'}}>*</span> :</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                       <Autocomplete
                         options={this.state.currency}
                         style={{ width: "140px", outlineColor: "black", display: "inline-block"}}
                         renderInput={(params) => <TextField {...params} id="gts_salary_currency" variant="outlined" style={{ color: "black" }} name="gts_salary_currency" size="small"/>}
                         onChange={this.saveAutoCompleteCurrency}
                         noOptionsText='No options'
                         onBlur={this.validateCurrency.bind(this)}
                         size="small"
                       /></label></h6>
                        <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;Salary<span style={{color:'red'}}>*</span> :</b>&nbsp;</label></h6>
                        <input
                         type="text"
                         style={{ border: "1px solid #D3D3D3", height:"30px", width:"120px"}}
                         onChange={this.changeHandler}
                         onBlur={this.validateSalary.bind(this)}
                         disabled={this.state.gts_currency_id=== 0}
                         id='gts_job_salary'
                         name='gts_job_salary'
                       />
                       <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;Per<span style={{color:'red'}}>*</span> :</b>&nbsp;
                        <select style={{ border: "1px solid #D3D3D3", height:"30px", width:"60px"}} id="duration" disabled={this.state.gts_currency_id=== 0} onBlur={this.validateDuration.bind(this)} onChange={this.onSelectSalaryDuration}>
                        <option hidden selected value="select">Select</option>
                         <option >Year</option>
                         <option >Month</option>
                         <option >Week</option>
                         <option >Day</option>
                         <option >Hour</option>
                        </select></label></h6>
                     </InputGroup>
                     <div class="row">
                     <div class="col-4" align="left">
                        <span align="center" style={{color:'red'}}>{this.state.salaryCurrencyError}</span>
                        </div>
                        <div class="col-2">
                        <span align="center" style={{color:'red'}}>{this.state.salaryError}</span>
                      </div>
                      <div class="col">
                        <span align="center" style={{color:'red'}}>{this.state.salaryDurationError}</span>
                      </div>
                      </div>
                      { this.state.salaryCurrencyError || this.state.salaryError || this.state.salaryDurationError ?
                      <div class="row">
                        <span><br/></span>
                      </div>
                      : ''}
                     <div style={{borderBottomColor: 'black',borderBottomWidth: "0.5px"}}/><br/>
                     <InputGroup>
                        <h6 class="form-group" style={{color : "black", align:"left"}}><label style={{fontSize:"15px"}}><b>Email<span style={{color:'red'}}>*</span> :</b>&nbsp;
                         <select style={{ border: "1px solid #D3D3D3", height:"30px", width:"200px"}} id="gts_user_email" onChange={this.onSelectEmail}>
                            <option hidden selected value="select">Select</option>
                            <option>{userLoginDetails.gts_user_primary_email}</option>
                          </select></label></h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                          <h6 class="form-group" style={{color : "black", align:"center"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;Mobile Number<span style={{color:'red'}}>*</span> :</b>&nbsp;
                          <input
                              type="text"
                              style={{ border: "1px solid #D3D3D3", height:"30px", width:"15px"}}
                              value="+"
                           />
                          <select style={{ border: "1px solid #D3D3D3", height:"30px", width:"50px"}} id="gts_job_mobile_country_code" onChange={this.onSelectMobilecode}>
                            <option hidden selected value="select">Select</option>
                            <option>{userLoginDetails.gts_user_country_code}</option>
                          </select>&nbsp;&nbsp;
                          <select style={{ border: "1px solid #D3D3D3", height:"30px", width:"130px"}} id="gts_job_contact_mobile_number" onChange={this.onSelectMobileNumber}>
                            <option hidden selected value="select">Select</option>
                            <option>{userLoginDetails.gts_primary_contact_number}</option>
                          </select></label></h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                          <h6 class="form-group" style={{color : "black", align:"right"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;WhatsApp Number :</b>&nbsp;
                          <input
                              type="text"
                              style={{ border: "1px solid #D3D3D3", height:"30px", width:"15px"}}
                              value="+"
                           />
                          <select style={{ border: "1px solid #D3D3D3", height:"30px", width:"50px"}} id="gts_job_whatsapp_country_code" onChange={this.onSelectWhatsAppcode}>
                            <option hidden selected value="select">Select</option>
                            <option>{userLoginDetails.gts_user_country_code}</option>
                          </select>&nbsp;&nbsp;
                          <select style={{ border: "1px solid #D3D3D3", height:"30px", width:"130px"}} id="gts_job_contact_whatsapp_number"  onChange={this.onSelectWhatsAppNumber}>
                            <option hidden selected value="select">Select</option>
                            <option>{userLoginDetails.gts_primary_contact_number}</option>
                          </select></label></h6>
                       </InputGroup>
                       <div class="row-0" align="right">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={()=>this.saveJob(this.state.gts_job_status = "SAVED")}
                              onFocus={this.validateFields.bind(this)}
                              style={{backgroundColor:"white",color:"white", align:"right", borderRadius:"25px", fontSize:"12px"}}>
                              <b>Save Details</b>
                           </button>
                            <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={()=>this.saveJob(this.state.gts_job_status = "SUBMITTED")}
                              onFocus={this.validateFields.bind(this)}
                              style={{backgroundColor:"white",color:"white", align:"right", borderRadius:"25px", fontSize:"12px"}}>
                              <b>Submit</b>
                           </button>
                        </div>
                        <br/>
                        {this.state.successAlert === true&&<div class="alert alert-success alert-dismissible fade show">
                          <button
                            type="button"
                            class="close"
                            data-dismiss="alert"
                            onClick={this.setSuccessAlertFalse}>&times;
                        </button>
                        <strong>{this.state.message}</strong>
                      </div>}
                      {this.state.status === 400 ? this.state.successAlert === false&& <div class="alert alert-danger alert-dismissible fade show">
                      <button
                        type="button"
                        class="close"
                        data-dismiss="alert"
                        onClick={this.setSuccessAlertFalse}>&times;
                    </button>
                    <strong>{this.state.message}</strong>
                  </div>: ''}
                 </div>
          </div>
        </div>
      </div>
      <br/>
    </>);
  }
}
export default JobPostComponent;