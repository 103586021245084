import React, { useState, useEffect, Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faUser,
  faTrash,
  faEdit,
  faUpload,
  faUserEdit,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import ls from "local-storage";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { endpoints_properties } from '../../properties/EndPointsProperties.js';
import { api_properties } from '../../properties/APIProperties.js';

import { Row, NavLink, NavItem, Col, ButtonGroup } from "reactstrap";
import {
  Form,
  FormControl,
  Modal,
  FormLabel,
  FormRow,
  Button,
  FormCheck,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";

var env=ls.get('ENV');
var token=ls.get('token');
var userLoginDetails = ls.get("userLoginDetails");

var getOrDeleteFielsOfRecruitmentsURL="";
var activeServiceTitlesURL="";
var addOrEditFieldOfRecruitmentsURL="";


if(env=="LOCAL"){
	getOrDeleteFielsOfRecruitmentsURL=endpoints_properties.ENDPOINT_LOCAL+ api_properties.API_GET_FIELD_OF_RECRUITMENT;
	activeServiceTitlesURL=endpoints_properties.ENDPOINT_LOCAL+ api_properties.API_GET_ACTIVE_SERVICE_TITLES;
	addOrEditFieldOfRecruitmentsURL=endpoints_properties.ENDPOINT_LOCAL+ api_properties.API_FIELD_OF_RECRUITMENT;
}
else{
	getOrDeleteFielsOfRecruitmentsURL=endpoints_properties.ENDPOINT_PROD+ api_properties.API_GET_FIELD_OF_RECRUITMENT;
	activeServiceTitlesURL=endpoints_properties.ENDPOINT_PROD+ api_properties.API_GET_ACTIVE_SERVICE_TITLES;
	addOrEditFieldOfRecruitmentsURL=endpoints_properties.ENDPOINT_PROD+ api_properties.API_FIELD_OF_RECRUITMENT;
}



class FieldOfRecruitmentComponent extends Component {
  constructor(props) {
   super(props);

    this.state = {
      jobTitle: [
        {
          gts_job_title_id: 0,
          gts_job_title_name: "",
          gts_job_title_description: "",
          gts_job_title_status: false,
        },
      ],
      jobTitleName: [],
      selectedJobTitles: [],
      selectedJobTitleIds: [],
      storedJobTitleName: [],
      fieldOfRecruitmentExists: false,
      disableInput: true,
      savedMsg: "",
      updatedMsg: "",
      errorMsg: ""
    };
  }

  componentDidMount() {
    var url=getOrDeleteFielsOfRecruitmentsURL+'/'+userLoginDetails.user_id;
    axios.get(url,{ headers: { Auth_Token: `Bearer ${token}` } }
      )
      .then((response) => {
        if (response.data.length > 0) {
          this.setState({
            fieldOfRecruitmentExists: true,
          });
        const tempStoredJobTitles = [];
        for (let i = 0; i < response.data.length; i++) {
          tempStoredJobTitles.push(response.data[i].gtsJobTitle);
        }
        this.setState({storedJobTitleName : tempStoredJobTitles});
        this.state.storedJobTitleName = tempStoredJobTitles;
      }
      })
      .catch((error) => {
        console.log(error);
      });

    this.JobTitleAutoCompleteChangeHandler();
  }

  JobTitleAutoCompleteChangeHandler = () => {
    this.state.errorMsg="";
    axios
      .get(activeServiceTitlesURL, {
        headers: { Auth_Token: `Bearer ${token}` },
      })
      .then((response) => {
        this.state.jobTitle = response.data;
        if (this.state.jobTitleName.length != 0)
          this.state.jobTitleName.length = [];

        this.state.jobTitle.forEach((jobTitles) => {
          this.state.jobTitleName.push(jobTitles.gts_job_title_name);
        });
        // console.log(this.state.jobTitleName);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  saveSelectedJobTitles = (e, value) => {
    this.state.selectedJobTitles = value;
    this.state.storedJobTitleName = value;

    let selectedJobTitleIds = [];
    this.state.selectedJobTitles.forEach((selectedJobTitle) => {
      this.state.jobTitle.forEach((jobTitle) => {
        if (selectedJobTitle === jobTitle.gts_job_title_name) {
          selectedJobTitleIds.push(jobTitle.gts_job_title_id);
        }
      });
    });
    this.setState({
      selectedJobTitleIds: selectedJobTitleIds,
    });
    console.log("Selected job title id: " + selectedJobTitleIds);
  };

  saveFieldOfRecruitment = () => {

    let successMsg = "Field of recruitment saved successfully";
    let updateMsg = "Field of recruitment updated successfully";
    //let errorUpdateMsg = "Field of recruitment cannot be updated";

    let postUrl = addOrEditFieldOfRecruitmentsURL;

    var selectedJobPositions=this.state.selectedJobTitleIds.join(",").toString();

	if(selectedJobPositions==="" || selectedJobPositions==="undefined" || selectedJobPositions===null){
		this.setState({errorMsg: "Please add/edit Job Titles."});
		return;
	}



    let postPayload = {
      gts_recruiter_id: userLoginDetails.user_id,
      gts_job_title_ids: selectedJobPositions,
    };

    if (this.state.fieldOfRecruitmentExists) {
     axios
        .delete(
          getOrDeleteFielsOfRecruitmentsURL +'/'+
            userLoginDetails.user_id,
          { headers: { Auth_Token: `Bearer ${token}` } }
        )
        .then((response) => {
          if (response.data.status_code === "200") {
            axios
              .post(postUrl, postPayload, {
                headers: { Auth_Token: `Bearer ${token}` },
              })
              .then((response) => {
                this.setState({
                  updatedMsg: updateMsg
                })
              })
              .catch((error) => {
                console.log(error.response.data.message);
                if(error.response.data.status_code == 504){
                  this.setState({
                    errorMsg: "Not able to add fields of recruitment."
                  })
                }
                else{
                this.setState({
                  errorMsg: error.response.data.message
                })
              }
              });
          }
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    } else {
      axios.post(postUrl, postPayload, { headers: { Auth_Token: `Bearer ${token}` },
        })
        .then((response) => {
          console.log(response.data);
          this.setState({
            savedMsg: successMsg
          })
        })
        .catch((error) => {
          console.log("***Problem to save FieldsOfRecruitment***: "+error.response.data.message);
          if(error.response.data.status_code == 504){
            this.setState({
              errorMsg: "Not able to add fields of recruitment."
            })
          }
          else{
          this.setState({
            errorMsg: error.response.data.message
          })
        }
        });
    }
  };

  enableInput = (e) => {
    e.preventDefault();
    //console.log("inside enble input method");
    this.setState({
      disableInput: false,
      savedMsg: "",
      updatedMsg: "",
    });
  };

  clearMessage(){
    this.setState({
      disableInput: false,
      savedMsg: "",
      updatedMsg: "",
    });
  };

  render() {

    const successMessageStyles = {
      fontWeight: 'bold',
      fontSize: 18,
      color: "#008000"
    };
    const errorMessageStyles = {
      fontWeight: 'bold',
      fontSize: 18,
      color: "#ff4d4d"
    };
    return (
      <div className="container align-items-center">
        <div className="container align-items-center">
          <div className="mt-3">
            <div className="border border-dark rounded-lg">
            <div className="row">
                <div className = "col" align="left">
                  <h5  className="mb6-3 p-2">
                    <b>Fields Of Recruitment</b>
                  </h5>
                  <div style={{borderBottomColor: 'black',borderBottomWidth: 1.5}} />
                  <br/>
                </div>
                <div class="row-sm">
                  <label style={{ fontWeight: "bold", paddingLeft: "10px" }}>
                    Recruitment Field (Any 5) :
                    <span
                      style={{
                        color: "red",
                        fontStyle: "italic",
                        margin: "0 0 0 10px",
                      }}
                    >
                      (If you can't find appropriate job title, please contact
                      Administrator at hr.gts@ggtech.co.in)
                    </span>
                  </label>
                  <br />
                  <Autocomplete
                    multiple
                    options={this.state.jobTitleName}
                    onChange={this.saveSelectedJobTitles}
                    value={this.state.storedJobTitleName}
                    //onFocus={this.clearMessage}
                    style={{
                      width: "50%",
                      paddingLeft: "10px",
                      borderColor: "black",
                    }}
                    filterSelectedOptions
                    disabled={
                      this.state.fieldOfRecruitmentExists &&
                      this.state.disableInput
                        ? "disableInput"
                        : ""
                    }
                    //getOptionDisabled={(options, tags) => (tags.length > 3 ? true : false)}
                    renderInput={(params) => {
                      this.JobTitleAutoCompleteChangeHandler(params);
                      return (
                        <TextField
                          {...params}
                          placeholder="Job Position"
                          variant="outlined"
                          style={{ width: "200%" }}
                          value={this.state.storedJobTitleName}
                        />
                      );
                    }}
                  />
                </div>
                <div>
                  <div class="text-center" style={successMessageStyles}>{this.state.savedMsg}</div>
                  <div class="text-center" style={successMessageStyles}>{this.state.updatedMsg}</div>
                  <div class="text-center" style={errorMessageStyles}>{this.state.errorMsg}</div>
                  <Button
                    variant="primary"
                    style={{ fontWeight: 'bold', float: "right" }}
                    onClick={this.saveFieldOfRecruitment}
                  >
                    Save
                  </Button>
                  <Button
                    variant="primary"
                    style={{ float: "right" }}
                    onClick={this.enableInput}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FieldOfRecruitmentComponent;
