import React, { Component } from 'react'
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import 'bootstrap/dist/css/bootstrap.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser,faTrash,faEdit,faUpload} from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import ls from 'local-storage';
import UserPersonalDetailsComponent from '../../components/common/UserPersonalDetailsComponent';
import TraineeMenu from '../../components/trainee/TraineeMenu';
import {
  Button,Alert,FormGroup,Form,Input,InputGroupAddon,InputGroupText,InputGroup,Row,Col,Container
} from 'reactstrap';
import { FormControl, FormLabel, Card, CardBody,CardText } from 'react-bootstrap';

var token = ls.get("token");

class TraineeProfilePage extends Component {
   constructor(props) {
		if(token==null || token=="" || token=="undefined"){
		  window.location.href = '/gts/global-home-page';
		}
		super(props);
	}
    render()
     {

        return (


           <div>
                <Header/>
                <TraineeMenu/>
<br></br>
<UserPersonalDetailsComponent/>
<br></br>
  <Footer />


           </div>  //main end




        )
    }
}
export default TraineeProfilePage;



