import React, { Component } from 'react';
import { Button, FormGroup } from 'reactstrap';
import ls from 'local-storage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { faEdit} from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.css'
import axios from 'axios';
import { endpoints_properties } from '../../properties/EndPointsProperties.js';
import { api_properties } from '../../properties/APIProperties.js';

var token=ls.get('token');
var env=ls.get('ENV');
var	userLoginDetails=ls.get('userLoginDetails');
var	userPersonalDetails=ls.get('userPersonalDetails');


var createOrUpdatePersonalDetailsURL="";
var getUserPersonalDetailsURL="";
var nationalitiesURL="";
var updateUserPrimaryMobileURL="";
var updatePrimaryEmailURL="";
var updateUserAltMobileURL="";
var updateAlternateEmailURL="";
var generateOTPPrimaryEmailURL="";
var generateOTPAltEmailURL="";
var generateOTPPrimaryMobileURL="";
var generateOTPAltMobileURL="";
var validatePrimaryEMailURL="";
var validateAltEMailURL="";
var validatePrimaryEMobileURL="";

var profileOwner="";

var validateAltEMobileURL="";

if(env=="LOCAL"){
	createOrUpdatePersonalDetailsURL = endpoints_properties.ENDPOINT_PERSONAL_DETAILS_LOCAL+api_properties.API_USER_PERSONAL_DETAILS;
	getUserPersonalDetailsURL = endpoints_properties.ENDPOINT_PERSONAL_DETAILS_LOCAL+api_properties.API_USER_PERSONAL_DETAILS;
	nationalitiesURL = endpoints_properties.ENDPOINT_NATIONALITIES_LOCAL+api_properties.API_GET_ACTIVE_NATIONALITIES;
	updateUserPrimaryMobileURL=endpoints_properties.ENDPOINT_IDENTITY_LOCAL+api_properties.API_UPDATE_PRIMARY_CONTACT;
	updatePrimaryEmailURL = endpoints_properties.ENDPOINT_IDENTITY_LOCAL+api_properties.API_UPDATE_PRIMARY_EMAIL;
	updateUserAltMobileURL=endpoints_properties.ENDPOINT_IDENTITY_LOCAL+api_properties.API_UPDATE_ALTERNATE_CONTACT;
	updateAlternateEmailURL = endpoints_properties.ENDPOINT_PERSONAL_DETAILS_LOCAL+api_properties.API_UPDATE_ALTERNATE_EMAIL;
	generateOTPPrimaryEmailURL = endpoints_properties.ENDPOINT_IDENTITY_LOCAL+api_properties.API_GENERATE_OTP_PRIMARY_EMAIL;
	generateOTPAltEmailURL = endpoints_properties.ENDPOINT_PERSONAL_DETAILS_LOCAL+api_properties.API_GENERATE_OTP_ALTERNATE_EMAIL;
	generateOTPPrimaryMobileURL = endpoints_properties.ENDPOINT_IDENTITY_LOCAL+api_properties.API_GENERATE_OTP_ALTERNATE_CONTACT;
	generateOTPAltMobileURL = endpoints_properties.ENDPOINT_PERSONAL_DETAILS_LOCAL+api_properties.API_GENERATE_OTP_ALTERNATE_CONTACT;
	validatePrimaryEMailURL = endpoints_properties.ENDPOINT_IDENTITY_LOCAL+api_properties.API_VALIDATE_PRIMARY_EMAIL;
	validateAltEMailURL = endpoints_properties.ENDPOINT_IDENTITY_LOCAL+api_properties.API_VALIDATE_ALTERNATE_EMAIL;
	validatePrimaryEMobileURL = endpoints_properties.ENDPOINT_IDENTITY_LOCAL+api_properties.API_VALIDATE_PRIMARY_CONATCT;
	validateAltEMobileURL = endpoints_properties.ENDPOINT_IDENTITY_LOCAL+api_properties.API_VALIDATE_ALTERNATE_CONATCT;
}
else{
	createOrUpdatePersonalDetailsURL = endpoints_properties.ENDPOINT_PROD+api_properties.API_USER_PERSONAL_DETAILS;
	getUserPersonalDetailsURL = endpoints_properties.ENDPOINT_PROD+api_properties.API_USER_PERSONAL_DETAILS;
	nationalitiesURL = endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_NATIONALITIES;
	updateUserPrimaryMobileURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_UPDATE_PRIMARY_CONTACT;
	updatePrimaryEmailURL = endpoints_properties.ENDPOINT_PROD+api_properties.API_UPDATE_PRIMARY_EMAIL;
	updateUserAltMobileURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_UPDATE_ALTERNATE_CONTACT;
	updateAlternateEmailURL = endpoints_properties.ENDPOINT_PROD+api_properties.API_UPDATE_ALTERNATE_EMAIL;
	generateOTPPrimaryEmailURL = endpoints_properties.ENDPOINT_PROD+api_properties.API_GENERATE_OTP_PRIMARY_EMAIL;
	generateOTPAltEmailURL = endpoints_properties.ENDPOINT_PROD+api_properties.API_GENERATE_OTP_ALTERNATE_EMAIL;
	generateOTPPrimaryMobileURL = endpoints_properties.ENDPOINT_PROD+api_properties.API_GENERATE_OTP_ALTERNATE_CONTACT;
	generateOTPAltMobileURL = endpoints_properties.ENDPOINT_PROD+api_properties.API_GENERATE_OTP_ALTERNATE_CONTACT;
	validatePrimaryEMailURL = endpoints_properties.ENDPOINT_PROD+api_properties.API_VALIDATE_PRIMARY_EMAIL;
	validateAltEMailURL = endpoints_properties.ENDPOINT_PROD+api_properties.API_VALIDATE_ALTERNATE_EMAIL;
	validatePrimaryEMobileURL = endpoints_properties.ENDPOINT_PROD+api_properties.API_VALIDATE_PRIMARY_CONATCT;
	validateAltEMobileURL = endpoints_properties.ENDPOINT_PROD+api_properties.API_VALIDATE_ALTERNATE_CONATCT;
}


class UserPersonalDetailsComponent extends Component {

  constructor(props) {
   super(props)
    this.state = {
      gts_user_personal_detail_id: '',
      gts_user_first_name: '',
      gts_user_middle_name: '',
      gts_user_last_name: '',
      gts_user_father_first_name: '',
      gts_user_father_middle_name: '',
      gts_user_father_last_name: '',
      gts_user_mother_first_name: '',
      gts_user_mother_middle_name: '',
      gts_user_mother_last_name: '',
      gts_user_gender: '',
      gts_user_dob: '',
      gts_user_nationality_id: '',
      gts_user_nationality_name: '',
      gts_user_primary_country_code: '',
      gts_primary_contact_number: '',
      gts_user_primary_email:'',
      gts_user_country_code: '',
      gts_user_alternate_mobile_number: '',
      gts_user_alternate_email_id:'',
      gts_user_objectives: '',
      gts_primary_contact_is_validated: '',
      gts_user_email_is_validated: '',
      gts_user_is_alternate_email_validated: '',
      gts_user_is_alternate_number_validated: '',

      successAlert: false,
      isPersonalDetailExisting: false,
      isUserEmailContactExisting: false,
      message:'',
      errorMessage:'',
      update:'',
      isError: '',
      isExisting: false,
      errors: {},
      responses: {},
      userPersonalDetailError: '',
      userPersonalDetailSuccess: '',
      gts_user_otp: '',
      gts_user_primary_mob_otp: '',
      gts_user_alt_email_otp: '',
      gts_user_alt_mob_otp: '',
      email_otp_sent_message:'',
      mob_otp_sent_message:'',
      alt_email_otp_sent_message:'',
      alt_mob_otp_sent_message:'',
      isVerified:'',
      disablePersonalDetailInput:true,
      nationalities: [] ,
      nationality : []

    };

    this.changeHandler = this.changeHandler.bind(this);
    this.clearMessage = this.clearMessage.bind(this)
  }


  componentDidMount() {

	const search = window.location.search;
	const params = new URLSearchParams(search);
	profileOwner = params.get('profile-owner');

	console.log("profileOwner: "+profileOwner);


	console.log("userLoginDetails.user_id: "+userLoginDetails.user_id);
 	if(userPersonalDetails==null || userPersonalDetails=="" || userPersonalDetails=="undefined"){

     getUserPersonalDetailsURL=getUserPersonalDetailsURL+"/gts-user-id/"+userLoginDetails.user_id;

       axios.get(getUserPersonalDetailsURL,{ headers: {"Auth_Token" : `Bearer ${token}`} })
         .then(response => {

         if (response.data.gts_user_id == '') {
           this.setState({
             isPersonalDetailExisting: false,

           });
         }
         else {
           this.setState({
             isPersonalDetailExisting: true,
             isUserEmailContactExisting: true
           });
         }
 		ls.set('userPersonalDetails', response.data);

         this.setState({
           gts_user_personal_detail_id: response.data.gts_user_personal_detail_id,
           gts_user_first_name: response.data.gts_user_first_name,
           gts_user_middle_name: response.data.gts_user_middle_name,
           gts_user_last_name: response.data.gts_user_last_name,
           gts_user_father_first_name: response.data.gts_user_father_first_name,
           gts_user_father_middle_name: response.data.gts_user_father_middle_name,
           gts_user_father_last_name: response.data.gts_user_father_last_name,
           gts_user_mother_first_name: response.data.gts_user_mother_first_name,
           gts_user_mother_middle_name: response.data.gts_user_mother_middle_name,
           gts_user_mother_last_name: response.data.gts_user_mother_last_name,
           gts_user_gender: response.data.gts_user_gender,
           gts_user_dob : response.data.gts_user_dob,
           gts_user_country_code: response.data.gts_user_country_code,
           gts_user_alternate_mobile_number: response.data.gts_user_alternate_mobile_number,
           gts_user_alternate_email_id: response.data.gts_user_alternate_email_id,
           gts_user_nationality_id: response.data.gts_user_nationality_id,
           gts_user_nationality_name: response.data.gts_user_nationality_name,
           gts_user_objectives: response.data.gts_user_objectives,
           gts_user_primary_country_code: response.data.gts_user_primary_country_code,
           gts_primary_contact_number: response.data.gts_primary_contact_number,
           gts_user_primary_email: response.data.gts_user_primary_email,
           gts_primary_contact_is_validated: response.data.gts_primary_contact_is_validated,
           gts_user_email_is_validated: response.data.gts_user_email_is_validated,
           gts_user_is_alternate_email_validated: response.data.gts_user_is_alternate_email_validated,
           gts_user_is_alternate_number_validated: response.data.gts_user_is_alternate_number_validated

         });
       })
       .catch(error => {
         console.log("Problem to get Personal Details: "+error);
 		this.setState({
 			gts_primary_contact_number: userLoginDetails.gts_primary_contact_number,  //Getting from Login Details
 			gts_user_primary_email: userLoginDetails.gts_user_primary_email,  //Getting from Login Details
 		});
       });

 	}
 	else
 	{
 	    this.setState({
           gts_user_personal_detail_id: userPersonalDetails.gts_user_personal_detail_id,
           gts_user_first_name: userPersonalDetails.gts_user_first_name,
           gts_user_middle_name: userPersonalDetails.gts_user_middle_name,
           gts_user_last_name: userPersonalDetails.gts_user_last_name,
           gts_user_father_first_name: userPersonalDetails.gts_user_father_first_name,
           gts_user_father_middle_name: userPersonalDetails.gts_user_father_middle_name,
           gts_user_father_last_name: userPersonalDetails.gts_user_father_last_name,
           gts_user_mother_first_name: userPersonalDetails.gts_user_mother_first_name,
           gts_user_mother_middle_name: userPersonalDetails.gts_user_mother_middle_name,
           gts_user_mother_last_name: userPersonalDetails.gts_user_mother_last_name,
           gts_user_gender: userPersonalDetails.gts_user_gender,
           gts_user_dob : userPersonalDetails.gts_user_dob,
           gts_user_country_code: userPersonalDetails.gts_user_country_code,
           gts_user_alternate_mobile_number: userPersonalDetails.gts_user_alternate_mobile_number,
           gts_user_alternate_email_id: userPersonalDetails.gts_user_alternate_email_id,
           gts_user_nationality_id: userPersonalDetails.gts_user_nationality_id,
           gts_user_nationality_name: userPersonalDetails.gts_user_nationality_name,
           gts_user_objectives: userPersonalDetails.gts_user_objectives,
           gts_user_primary_country_code: userPersonalDetails.gts_user_primary_country_code,
           gts_primary_contact_number: userPersonalDetails.gts_primary_contact_number,
           gts_user_primary_email: userPersonalDetails.gts_user_primary_email,
           gts_primary_contact_is_validated: userPersonalDetails.gts_primary_contact_is_validated,
           gts_user_email_is_validated: userPersonalDetails.gts_user_email_is_validated,
           gts_user_is_alternate_email_validated: userPersonalDetails.gts_user_is_alternate_email_validated,
           gts_user_is_alternate_number_validated: userPersonalDetails.gts_user_is_alternate_number_validated

         });


      }

       axios.get(nationalitiesURL,{ headers: {"Auth_Token" : `Bearer ${token}`} })
         .then((res) =>{
            console.log(res.data);
           this.setState({ nationalities : res.data});
           this.state.nationalities.forEach((nationalities) => {
             this.state.nationality.push(nationalities.gts_nationality_name);
           });
         })

  }


  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  enableInput = () => {
    this.setState( {disablePersonalDetailInput: false} )
  }

  clearMessage = e =>{
    let message= this.state.message;
    message=" ";

    if(this.state.message){
    this.setState({message: message})
   }

   else if(this.state.error){
    let error= this.state.error;
    error=" ";
    this.setState({error: error})
    }

   else if(this.state.contactMessage){
      let contactMessage= this.state.contactMessage;
      contactMessage=" ";
      this.setState({contactMessage: contactMessage})
     }

     else if(this.state.contactError){
      let contactError= this.state.contactError;
      contactError=" ";
      this.setState({contactError: contactError})
      }

	if(this.state.primaryEmailUpdatedMessage){
      this.setState({primaryEmailUpdatedMessage: ""});
     }
	if(this.state.primaryContactNumberUpdatedMessage){
      this.setState({primaryContactNumberUpdatedMessage: ""});
     }
	if(this.state.altContactNumberUpdatedMessage){
      this.setState({altContactNumberUpdatedMessage: ""});
     }
	if(this.state.altEmailUpdatedMessage){
      this.setState({altEmailUpdatedMessage: ""});
     }


   }

  clearFirstNameError = e => {
    let errors = this.state.errors;
    let formIsValid = true;

    if (e.target.name == "gts_user_first_name") {
      errors["gts_user_first_name"] = "";

      this.setState({
        errors: errors
      });

      return formIsValid;
    }

  }

  clearLastNameError = e => {
    let errors = this.state.errors;
    let formIsValid = true;

    if (e.target.name == "gts_user_last_name") {
      errors["gts_user_last_name"] = "";

      this.setState({
        errors: errors
      });

      return formIsValid;
    }

  }
  clearGenderError = e => {
    let errors = this.state.errors;
    let formIsValid = true;

    if (e.target.name == "gts_user_gender") {
      errors["gts_user_gender"] = "";

      this.setState({
        errors: errors
      });

      return formIsValid;
    }

  }
  clearNationalityError = e => {
    let errors = this.state.errors;
    let formIsValid = true;

    if (e.target.name == "gts_user_nationality") {
      errors["gts_user_nationality"] = "";

      this.setState({
        errors: errors
      });

      return formIsValid;
    }
  }

    clearDOBError = e => {
      let errors = this.state.errors;
      let formIsValid = true;

      if (e.target.name == "gts_user_dob") {
        errors["gts_user_dob"] = "";

        this.setState({
          errors: errors
        });

        return formIsValid;
      }
    }

  clearPrimaryCountryCodeError = e => {
    let errors = this.state.errors;
    let formIsValid = true;

    if (e.target.name == "gts_user_primary_country_code") {
      errors["gts_user_primary_country_code"] = "";

      this.setState({
        errors: errors
      });

      return formIsValid;
    }

  }
  clearPrimaryMobError = e => {
    let errors = this.state.errors;
    let formIsValid = true;

    if (e.target.name == "gts_primary_contact_number") {
      errors["gts_primary_contact_number"] = "";

      this.setState({
        errors: errors
      });

      return formIsValid;
    }

  }
  clearAltCountryCodeError = e => {
    let errors = this.state.errors;
    let formIsValid = true;

    if (e.target.name == "gts_user_country_code") {
      errors["gts_user_country_code"] = "";

      this.setState({
        errors: errors
      });

      return formIsValid;
    }

  }
  clearAltMobError = e => {
    let errors = this.state.errors;
    let formIsValid = true;

    if (e.target.name == "gts_user_alternate_mobile_number") {
      errors["gts_user_alternate_mobile_number"] = "";

      this.setState({
        errors: errors
      });

      return formIsValid;
    }

  }
  clearEmailError = e => {
    let errors = this.state.errors;
    let formIsValid = true;

    if (e.target.name == "gts_user_primary_email") {
      errors["gts_user_primary_email"] = "";

      this.setState({
        errors: errors
      });

      return formIsValid;
    }

  }
  clearAltEmailError = e => {
    let errors = this.state.errors;
    let formIsValid = true;

    if (e.target.name == "gts_user_alternate_email_id") {
      errors["gts_user_alternate_email_id"] = "";

      this.setState({
        errors: errors
      });

      return formIsValid;
    }

  }

  validateFirstName = e => {
    let errors = this.state.errors;
    let formIsValid = true;

    var pattern = new RegExp( /^[a-zA-Z]+(?:-[a-zA-Z]+)*$/);
    if(!pattern.test(this.state.gts_user_first_name)){
    // if (!this.state.gts_user_first_name && this.state.gts_user_first_name.length<=0) {

    formIsValid = false;
      errors["gts_user_first_name"] = "Enter the valid name.";

      this.setState({
        errors: errors
      });
      return formIsValid;
    }

  //   if (this.state.gts_user_first_name.match(/^[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)){
  //     formIsValid = false;
  //   errors["gts_user_first_name"] = "Enter the valid name.";

  //   this.setState({
  //     errors: errors
  //   });

  // }

   }
  validateLastName = e => {
    let errors = this.state.errors;
    let formIsValid = true;

    // if (!this.state.gts_user_last_name && this.state.gts_user_last_name.length<=0) {
    //   console.log("validate last name2");
      var pattern = new RegExp( /^[a-zA-Z]+(?:-[a-zA-Z]+)*$/);
      if(!pattern.test(this.state.gts_user_first_name)){
      // if (!this.state.gts_user_first_name && this.state.gts_user_first_name.length<=0) {

      formIsValid = false;
        errors["gts_user_first_name"] = "Enter the valid name.";

      this.setState({
        errors: errors
      });

      return formIsValid;
    }

  }
  validateGender = e => {
    let errors = this.state.errors;
    let formIsValid = true;

    if (!this.state.gts_user_gender && this.state.gts_user_gender.length<=0) {
      formIsValid = false;
      errors["gts_user_gender"] = "Please enter your gender.";

      this.setState({
        errors: errors
      });

      return formIsValid;
    }
  }

  validateNationality = e => {
    let errors = this.state.errors;
    let formIsValid = true;

    if (this.state.gts_user_nationality_id=== null) {
      formIsValid = false;
      errors["gts_user_nationality"] = "Please select your nationality.";

      this.setState({
        errors: errors
      });

      return formIsValid;
    }
  }

  validateDOB = e => {
    let errors = this.state.errors;
    let formIsValid = true;

    if (this.state.gts_user_dob=== '') {
      formIsValid = false;
      errors["gts_user_dob"] = "Please enter your dob.";

      this.setState({
        errors: errors
      });

      return formIsValid;
    }
  }

  validatePrimaryCountryCode = e => {
    let errors = this.state.errors;
    let formIsValid = true;
    var pattern = new RegExp(/^[0-9]|1[1-4]$/);
    if(!pattern.test(this.state.gts_user_primary_country_code)){
      formIsValid = false;
      errors["gts_user_primary_country_code"]="Enter the valid country code";
        this.setState({
          errors: errors
        });
      return formIsValid;
    }

  }
  validateAltCountryCode = e => {
    let errors = this.state.errors;
    let formIsValid = true;
    var pattern = new RegExp(/^[0-9]|1[1-4]$/);
    if(!pattern.test(this.state.gts_user_country_code)){
      formIsValid = false;
      errors["gts_user_country_code"]="Only numeric values are allowed";
        this.setState({
          errors: errors
        });
      return formIsValid;
    }

  }
  validatePrimaryMob = e => {
    let errors = this.state.errors;
    let formIsValid = true;
    var pattern = new RegExp(/^[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-/\s.]?[0-9]{4}$/);
    if(!pattern.test(this.state.gts_primary_contact_number)){
      formIsValid = false;
      errors["gts_primary_contact_number"]="Enter the valid contact number";
        this.setState({
          errors: errors
        });
      return formIsValid;
    }
  }
  validateAltMob = e => {
    let errors = this.state.errors;
    let formIsValid = true;
    var pattern = new RegExp(/^[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-/\s.]?[0-9]{4}$/);
    if(!pattern.test(this.state.gts_user_alternate_mobile_number)){
      formIsValid = false;
      errors["gts_user_alternate_mobile_number"]="Enter the valid contact number";
        this.setState({
          errors: errors
        });
      return formIsValid;
    }
    if (this.state.gts_primary_contact_number === this.state.gts_user_alternate_mobile_number) {
        errors["gts_user_alternate_mobile_number"] = "Contact must not be same.";
        this.setState({
          errors: errors
        });
        return formIsValid;
      }

  }
  validateEmail = e => {
    let errors = this.state.errors;
    let formIsValid = true;

      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

      if (!pattern.test(this.state.gts_user_primary_email)) {
        formIsValid = false;
        errors["gts_user_primary_email"] = "Please enter valid email-ID.";

        this.setState({
          errors: errors
        });

        return formIsValid;

      }

  }
  validateAltEmail = e => {
    let errors = this.state.errors;
    let formIsValid = true;

      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

      if (!pattern.test(this.state.gts_user_alternate_email_id)) {
        formIsValid = false;
        errors["gts_user_alternate_email_id"] = "Please enter valid alternate email-ID.";

        this.setState({
          errors: errors
        });

        return formIsValid;

      }
      if (this.state.gts_user_primary_email === this.state.gts_user_alternate_email_id) {
        errors["gts_user_alternate_email_id"] = "Email ID must not be same.";
        this.setState({
          errors: errors
        });
        return formIsValid;
      }
  }

  validateIcon = e => {
    let formIsValid = true;
    if (this.state.isVerified) {
      formIsValid = true;
      return formIsValid;
    }
  }




  saveAutoCompleteNationality=(event, value) => {
    this.state.selectedNationality = value;
    this.state.gts_user_nationality_name = value

    this.state.nationalities.forEach((nationality) => {
      if (value=== nationality.gts_nationality_name) {
        this.state.gts_user_nationality_id = nationality.gts_nationality_id;
        this.state.gts_user_nationality_name = nationality.gts_nationality_name
      }
    });
}

  savePersonalDetailHandler = (e) => {

    e.preventDefault();
    let message = this.state.message;
    let error = this.state.error;

    if(this.state.gts_user_nationality_id ==null){
      this.state.gts_user_nationality_id = 0;
    }

    userPersonalDetails=ls.get('userPersonalDetails');
    console.log("this.state.userPersonalDetails: "+this.state.userPersonalDetails);


  if (userPersonalDetails === "" || userPersonalDetails === "undefined" || userPersonalDetails === null) {
    var add_personal_detail_payload = {
      "gts_user_id": userLoginDetails.user_id,
      "gts_user_first_name": this.state.gts_user_first_name,
      "gts_user_middle_name": this.state.gts_user_middle_name,
      "gts_user_last_name": this.state.gts_user_last_name,
      "gts_user_father_first_name": this.state.gts_user_father_first_name,
      "gts_user_father_middle_name": this.state.gts_user_father_middle_name,
      "gts_user_father_last_name": this.state.gts_user_father_last_name,
      "gts_user_mother_first_name": this.state.gts_user_mother_first_name,
      "gts_user_mother_middle_name": this.state.gts_user_mother_middle_name,
      "gts_user_mother_last_name": this.state.gts_user_mother_last_name,
      "gts_user_gender": this.state.gts_user_gender,
      "gts_user_dob" : this.state.gts_user_dob,
      "gts_user_nationality_id": this.state.gts_user_nationality_id,
      "gts_user_objectives": this.state.gts_user_objectives
    };

       console.log("Creating personal details");
       this.setState({ successAlert: true });
  		axios.post(createOrUpdatePersonalDetailsURL, add_personal_detail_payload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
       .then(response => {

         ls.set('userPersonalDetails', add_personal_detail_payload);

         message = "Personal detail is added successfully";
         this.setState({
           message : message,
           gts_user_personal_detail_id:response.data.personal_detail_id,
           isPersonalDetailExisting:true
        })
        })

      .catch(error => {
		console.log("Failed to create personal details: "+error);
        error = "Not able to add personal details"
        this.setState({
          error : error
       })
      });
  }
    else  {
	console.log("Updating Personal details with ID: "+this.state.gts_user_personal_detail_id);

    var update_personal_detail_payload = {
      "gts_user_personal_detail_id": this.state.gts_user_personal_detail_id,
      "gts_user_id": userLoginDetails.user_id,
      "gts_user_first_name": this.state.gts_user_first_name,
      "gts_user_middle_name": this.state.gts_user_middle_name,
      "gts_user_last_name": this.state.gts_user_last_name,
      "gts_user_father_first_name": this.state.gts_user_father_first_name,
      "gts_user_father_middle_name": this.state.gts_user_father_middle_name,
      "gts_user_father_last_name": this.state.gts_user_father_last_name,
      "gts_user_mother_first_name": this.state.gts_user_mother_first_name,
      "gts_user_mother_middle_name": this.state.gts_user_mother_middle_name,
      "gts_user_mother_last_name": this.state.gts_user_mother_last_name,
      "gts_user_gender": this.state.gts_user_gender,
      "gts_user_dob" : this.state.gts_user_dob,
      "gts_user_nationality_id": this.state.gts_user_nationality_id,
      "gts_user_objectives": this.state.gts_user_objectives
    };

	axios.put(createOrUpdatePersonalDetailsURL, update_personal_detail_payload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
        .then(response => {
          console.log("Personal details updated.");
          message= "Personal detail is updated successfully";
          this.setState({
            message : message
          })

        })

      .catch(error => {
		console.log("Failed to update personal details: "+error);
        error = "Not able to update personal details"
        this.setState({
          error : error
       })
        console.log(error);
      });
    }
  }

  // -----------------------------------------------------------

  updateUserPrimaryEmailHandler = (e) => {
    let contactMessage = this.state.contactMessage;
    let contactError = this.state.contactError;
    e.preventDefault();

    var updateEmailPayload={
      "gts_user_id": userLoginDetails.user_id,
      "gts_user_primary_email": this.state.gts_user_primary_email,
    };

    axios
      .put(updatePrimaryEmailURL,updateEmailPayload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
      .then(response => {
		  userPersonalDetails.gts_user_primary_email=this.state.gts_user_primary_email;
		  ls.set('userPersonalDetails', userPersonalDetails);

        contactMessage="Email updated successfully"
          this.setState({
            primaryEmailUpdatedMessage:response.data.message
          })
          //window.location.reload(false,10000);
      })
      .catch(contactError => {
        contactError="Not able to update email."
        this.setState({
          contactError : contactError
        })
      });
  };

  generatePrimaryEmailOTPHandler = (e) => {

    e.preventDefault();
    let contactMessage = this.state.contactMessage;
    let contactError = this.state.contactError;

    var email_otp_payload = {
     "gts_user_id": userLoginDetails.user_id,
      "gts_user_primary_email": this.state.gts_user_primary_email
    };

     if (this.state.isUserEmailContactExisting === true) {

      axios.post(generateOTPPrimaryEmailURL, email_otp_payload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
      .then(response => {
          this.setState({contactMessage:response.data.message});

        })

        .catch(contactError => {
          console.log(contactError.response)
          contactError="Not able to generate OTP for email validation."
          this.setState({
            contactError : contactError
          })
        });
    }

  };


  validatePrimaryEmailHandler = (e) => {

    this.setState({ successAlert: true });
    e.preventDefault();

    let contactMessage = this.state.contactMessage;
    let contactError = this.state.contactError;

    var email_payload = {
      "gts_user_id": userLoginDetails.user_id,
      "gts_user_primary_email_id": this.state.gts_user_primary_email,
      "primary_email_otp":this.state.gts_user_otp
    };

     if (this.state.isUserEmailContactExisting === true) {
      axios.put(validatePrimaryEMailURL,email_payload,{headers: {"Auth_Token" : `Bearer ${token}`}})
      .then(response => {

          console.log(response.data.contactMessage);
          this.setState({
            contactMessage:response.data.message,
            isVerified:response.data.status});
            //window.location.reload(false,10000);
        })

        .catch(contactError => {
          contactError="Not able to validate primary email."
          this.setState({
            contactError : contactError
          })
        });
    }
  };

  updateUserPrimaryMobileHandler = (e) => {
    console.log("primary contact update service is called");
    let contactMessage = this.state.contactMessage;
    let contactError = this.state.contactError;

    console.log("userLoginDetails.user_id: "+userLoginDetails.user_id);
    console.log("this.state.gts_user_primary_country_code: "+this.state.gts_user_primary_country_code);
    console.log("this.state.gts_primary_contact_number: "+this.state.gts_primary_contact_number);

    var updateMobPayload={
      "gts_user_id": userLoginDetails.user_id,
      "gts_user_country_code": this.state.gts_user_primary_country_code,
      "gts_primary_contact_number": this.state.gts_primary_contact_number
    };

    axios
      .put(updateUserPrimaryMobileURL, updateMobPayload, { headers: {"Auth_Token" : `Bearer ${token}`} })
      .then(response => {

		  userPersonalDetails.gts_primary_contact_number=this.state.gts_primary_contact_number;
		  ls.set('userPersonalDetails', userPersonalDetails);

          this.setState({
            primaryContactNumberUpdatedMessage:response.data.message,
          })
          //window.location.reload(false,10000);
      })
      .catch(contactError => {
		console.log("contactError: "+contactError);
        contactError="Not able to update primary contact number."
        this.setState({
          contactError : contactError
        })
      });
  };


  generatePrimaryMobNoOTPHandler = (e) => {

    e.preventDefault();

    let contactMessage = this.state.contactMessage;
    let contactError = this.state.contactError;

    var mob_otp_payload = {

      "gts_user_id": userLoginDetails.user_id,
      "gts_user_country_code": this.state.gts_user_primary_country_code,
      "gts_user_primary_mobile_number": this.state.gts_primary_contact_number

    };
     if (this.state.isUserEmailContactExisting === true) {

      axios.post(generateOTPPrimaryMobileURL,mob_otp_payload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
      .then(response => {
          this.setState({contactMessage:response.data.message});
       })

        .catch(contactError => {
          console.log(contactError)
          contactError="Not able to generate OTP for contact number."
          this.setState({
            contactError : contactError
          })
        });
    }

  };

  validatePrimaryMobNoHandler = (e) => {
    e.preventDefault();
	let contactMessage = this.state.contactMessage;
    let contactError = this.state.contactError;

    console.log("userLoginDetails.user_id: "+userLoginDetails.user_id);
    console.log("this.state.gts_user_primary_country_code: "+this.state.gts_user_primary_country_code);
    console.log("this.state.gts_user_primary_country_code: "+this.state.gts_user_primary_country_code);
    console.log("this.state.gts_user_primary_mob_otp: "+this.state.gts_user_primary_mob_otp);

    var contact_payload = {
      "gts_user_id": userLoginDetails.user_id,
      "gts_user_country_code": this.state.gts_user_primary_country_code,
      "gts_user_primary_mobile_number": this.state.gts_primary_contact_number,
      "primary_mobile_number_otp":this.state.gts_user_primary_mob_otp
    };
      axios.put(validatePrimaryEMobileURL,contact_payload,{headers: {"Auth_Token" : `Bearer ${token}`}})
      .then(response => {
            this.setState({
              contactMessage:response.data.message
            })
            //window.location.reload(false,10000);

        })
        .catch(contactError => {
          contactError="Not able to validate primary contact number."
          this.setState({
            contactError : contactError
          })
        });
  };

  updateUserAltEmailHandler = (e) => {
    let contactMessage = this.state.contactMessage;
    let contactError = this.state.contactError;
    e.preventDefault();

    console.log("userLoginDetails.user_id: "+userLoginDetails.user_id);
    console.log("this.state.gts_user_alternate_email_id: "+this.state.gts_user_alternate_email_id);

    var updateEmailPayload={
      "gts_user_id": userLoginDetails.user_id,
      "gts_user_alternate_email_id": this.state.gts_user_alternate_email_id,
    };



    axios
      .put(updateAlternateEmailURL, updateEmailPayload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
      .then(response => {
		  userPersonalDetails.gts_user_alternate_email_id=this.state.gts_user_alternate_email_id;
		  ls.set('userPersonalDetails', userPersonalDetails);

          this.setState({
            altEmailUpdatedMessage:response.data.message
          })
          //window.location.reload(false,10000);
      })
      .catch(contactError => {
        contactError="Not able to update alternate email."
        this.setState({
          contactError : contactError
        })
      });
  };

  generateAltEmailOTPHandler = (e) => {
let contactMessage = this.state.contactMessage;
    let contactError = this.state.contactError;
    e.preventDefault();

   console.log("userLoginDetails.user_id: "+userLoginDetails.user_id);
    console.log("this.state.gts_user_alternate_email_id: "+this.state.gts_user_alternate_email_id);


    var email_otp_payload = {

      "gts_user_id": userLoginDetails.user_id,
      "gts_user_alternate_email_id": this.state.gts_user_alternate_email_id

    };

     if (this.state.isPersonalDetailExisting === true) {

      axios.post(generateOTPAltEmailURL,email_otp_payload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
      .then(response => {
          this.setState({contactMessage:response.data.message});

        })

        .catch(contactError => {
          console.log(contactError.response.data)
          contactError="Not able to generate OTP for alternate email."
          this.setState({
            contactError : contactError
          })
        });
    }

  };

  validateAltEmailHandler = (e) => {
    let contactMessage = this.state.contactMessage;
    let contactError = this.state.contactError;

    this.setState({ successAlert: true });
    e.preventDefault();

    console.log("userLoginDetails.user_id: "+userLoginDetails.user_id);
    console.log("this.state.gts_user_alternate_email_id: "+this.state.gts_user_alternate_email_id);
    console.log("this.state.gts_user_alt_email_otp: "+this.state.gts_user_alt_email_otp);


    var email_payload = {
      "gts_user_id": userLoginDetails.user_id,
      "gts_user_alternate_email_id": this.state.gts_user_alternate_email_id,
      "alternate_email_otp":this.state.gts_user_alt_email_otp
    };

     if (this.state.isPersonalDetailExisting === true) {
      axios.put(validateAltEMailURL,email_payload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
      .then(response => {
          this.setState({
            contactMessage:response.data.message,
            isVerified:response.data.status
          });
          //window.location.reload(false,10000);

        })
     .catch(contactError => {
      contactError="Not able to validate alternate email."
          this.setState({
            contactError : contactError
          })
        });
    }
  };

  updateUserAltMobileHandler = (e) => {
    let contactMessage = this.state.contactMessage;
    let contactError = this.state.contactError;
    e.preventDefault();

    console.log("userLoginDetails.user_id: "+userLoginDetails.user_id);
    console.log("this.state.gts_user_country_code: "+this.state.gts_user_country_code);
    console.log("this.state.gts_user_alternate_mobile_number: "+this.state.gts_user_alternate_mobile_number);

    var updateMobPayload={
      "gts_user_id": userLoginDetails.user_id,
      "gts_user_country_code": this.state.gts_user_country_code,
      "gts_user_alternate_mobile_number": this.state.gts_user_alternate_mobile_number
    };

    axios
      .put(updateUserAltMobileURL, updateMobPayload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
      .then(response => {
          userPersonalDetails.gts_user_alternate_mobile_number=this.state.gts_user_alternate_mobile_number;
		  ls.set('userPersonalDetails', userPersonalDetails);

          this.setState({
            altContactNumberUpdatedMessage:response.data.message
          })
          //window.location.reload(false,10000);
      })
      .catch(contactError => {
        contactError="Not able to update alternate contact number."
        this.setState({
          contactError : contactError
        })
      });
  };

  generateAltMobNoOTPHandler = (e) => {
    let contactMessage = this.state.contactMessage;
    let contactError = this.state.contactError;

    e.preventDefault();

    console.log("userLoginDetails.user_id: "+userLoginDetails.user_id);
    console.log("this.state.gts_user_country_code: "+this.state.gts_user_country_code);
    console.log("this.state.gts_user_alternate_mobile_number: "+this.state.gts_user_alternate_mobile_number);

    var mob_otp_payload = {

      "gts_user_id":userLoginDetails.user_id,
      "gts_user_country_code": this.state.gts_user_country_code,
      "gts_user_alternate_mobile_number": this.state.gts_user_alternate_mobile_number
    };
     if (this.state.isPersonalDetailExisting === true) {
       console.log("post")
      //  this.setState({ successAlert: true });

      axios.post(generateOTPAltMobileURL,mob_otp_payload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
      .then(response => {

          this.setState({contactMessage:response.data.message});

        })

        .catch(contactError => {
          contactError="Not able to generate OTP for alternate contact number."
          this.setState({
            contactError : contactError
          })
        });
    }

  };

  validateAltMobNoHandler = (e) => {
    let contactMessage = this.state.contactMessage;
    let contactError = this.state.contactError;

    e.preventDefault();

   console.log("userLoginDetails.user_id: "+userLoginDetails.user_id);
    console.log("this.state.gts_user_country_code: "+this.state.gts_user_country_code);
    console.log("this.state.gts_user_alternate_mobile_number: "+this.state.gts_user_alternate_mobile_number);


    var contact_payload = {

      "gts_user_id": userLoginDetails.user_id,
      "gts_user_country_code": this.state.gts_user_country_code,
      "gts_user_alternate_mobile_number": this.state.gts_user_alternate_mobile_number,
      "alternate_mobile_number_otp":this.state.gts_user_alt_mob_otp
    };

     if (this.state.isPersonalDetailExisting === true) {
       console.log("put")

      axios.put(validateAltEMobileURL, contact_payload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
      .then(response => {
          this.setState({contactMessage:response.data.message});
          //window.location.reload(false,10000);
        })
        .catch(contactError => {
          contactError="Not able to validate alternate contact number."
          this.setState({
            contactError : contactError
          })
        });
    }
  };

  selectGender = (e) => {
    this.state.gts_user_gender = e.target.value;
    console.log(e.target.value)
  }


  render() {
    const iconStyles = {
      float: "left",
      clear: "left",
      height: "50%",
      width: "25px"
    }

    const successMessageStyles = {
      fontWeight: 'bold',
      fontSize: 20,
      color: "#008000"
    }

    const errorMessageStyles = {
      fontWeight: 'bold',
      fontSize: 20,
      color: "#ff4d4d"

    }

    const {
      isPersonalDetailExisting,
      gts_user_first_name,
      gts_user_middle_name,
      gts_user_last_name,
      gts_user_father_first_name,
      gts_user_father_middle_name,
      gts_user_father_last_name,
      gts_user_mother_first_name,
      gts_user_mother_middle_name,
      gts_user_mother_last_name,
      gts_user_gender,
      gts_user_dob,
      gts_user_primary_country_code,
      gts_primary_contact_number,
      gts_user_primary_email,
      gts_user_country_code,
      gts_user_alternate_mobile_number,
      gts_user_alternate_email_id,
      gts_user_objectives,
      gts_user_otp,
      gts_user_primary_mob_otp,
      gts_user_alt_email_otp,
      gts_user_alt_mob_otp
    } = this.state

    return (
    <div className="container align-items-center">

      <div className="container align-items-center">

        <div className='mt-3'>
          <form  >
            <div>
              <div className="form-group">
               <h5 className="mb6-3 p-2"><b>Objective </b></h5>
                <input type="text" class="text-primary" class="form-control" style={{ border: "1px solid black",color:this.state.isPersonalDetailExisting && this.state.disablePersonalDetailInput ? 'gray' : 'black' }} name="gts_user_objectives" value={gts_user_objectives} placeholder="Enter your objective" onChange={this.changeHandler} disabled={this.state.isPersonalDetailExisting && this.state.disablePersonalDetailInput ? 'disablePersonalDetailInput' : ''}></input>
              </div>
            </div>

            <div className="border border-dark rounded-lg" >

              <div className="row">
                <div className="col">
                  <h5 className="mb6-3 p-2"><b>Personal Detail &nbsp;&nbsp;</b> </h5>
                </div>
                <div className="col-1">
                </div>
              </div>

            {(this.state.message || this.state.error) ?
              <div>
                <span style={{color:  "#228B22"}} onBlur={this.clearMessage}><center><b>{this.state.message}</b></center></span>
                <span style={{color:  "red"}} onBlur={this.clearMessage}><center><b>{this.state.error}</b></center></span>
                <span onClick={this.clearMessage}>&nbsp;&nbsp;</span>
                <span >{this.state.message || this.state.error ?<br/> : ''}</span>
                </div>
                : ''
            }
              <div style={{borderBottomColor: 'black',borderBottomWidth: 1.5}} />
              <br/>
              <div className="container">

                <div className="row">
                  <div className="col-4">
                    <label><h6>First Name&nbsp;<span style={{fontSize:"15px", color: "red"}}><b>*</b></span>&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6></label>
                    <input type="text"
                            style={{ border: "1px solid #D3D3D3",color:this.state.isPersonalDetailExisting && this.state.disablePersonalDetailInput ? 'gray' : 'black', height:"35px"}}
                            placeholder="First Name"
                            name="gts_user_first_name"
                            value={gts_user_first_name}
                            onChange={this.changeHandler}
                            onBlur={this.validateFirstName}
                            onFocus={this.clearFirstNameError}
                            disabled={this.state.isPersonalDetailExisting && this.state.disablePersonalDetailInput ? 'disablePersonalDetailInput' : ''}
                            size="19">
                    </input>
                    <span style={{color: "red"}}>{this.state.errors["gts_user_first_name"]}</span><br/>
                  </div>
                  <div className="col-4">
                    <label><h6>Middle Name&nbsp;:&nbsp; </h6></label>
                    <input type="text"
                    onBlur={this.clearMessage}
                    style={{ border: "1px solid #D3D3D3", height:"35px",color:this.state.isPersonalDetailExisting && this.state.disablePersonalDetailInput ? 'gray' : 'black'}}
                    placeholder="Middle Name"
                    name="gts_user_middle_name"
                    value={gts_user_middle_name}
                    onChange={this.changeHandler}
                    disabled={this.state.isPersonalDetailExisting && this.state.disablePersonalDetailInput ? 'disablePersonalDetailInput' : ''}
                    size="19"></input>
                  </div>
                  <div className="col-4">
                    <label><h6>Last Name&nbsp;<span style={{fontSize:"15px", color: "red"}}><b>*</b></span>&nbsp;: &nbsp;</h6> </label>
                    <input type="text"
                            style={{ border: "1px solid #D3D3D3", height:"35px",color:this.state.isPersonalDetailExisting && this.state.disablePersonalDetailInput ? 'gray' : 'black'}}
                            placeholder="Last Name"
                            name="gts_user_last_name"
                            value={gts_user_last_name}
                            onChange={this.changeHandler}
                            onBlur={this.validateLastName}
                            onFocus={this.clearLastNameError}
                            disabled={this.state.isPersonalDetailExisting && this.state.disablePersonalDetailInput ? 'disablePersonalDetailInput' : ''}
                            size="19">
                    </input>
                    <span style={{color: "red"}}>{this.state.errors["gts_user_last_name"]}</span><br/>
                  </div>
                </div>


                <div className="row">
                <div className="col-4">
                    <label><h6>Father's First Name&nbsp;:&nbsp;&nbsp;</h6></label>
                    <input type="text" onBlur={this.clearMessage} style={{ border: "1px solid #D3D3D3", height:"35px",color:this.state.isPersonalDetailExisting && this.state.disablePersonalDetailInput ? 'gray' : 'black'}} placeholder="First Name" name="gts_user_father_first_name" value={gts_user_father_first_name} onChange={this.changeHandler} disabled={this.state.isPersonalDetailExisting && this.state.disablePersonalDetailInput ? 'disablePersonalDetailInput' : ''} size="19"></input>
                  </div>
                  <div className="col-4">
                    <label><h6>Middle Name&nbsp;:&nbsp;</h6></label>
                    <input type="text" onBlur={this.clearMessage} style={{ border: "1px solid #D3D3D3", height:"35px",color:this.state.isPersonalDetailExisting && this.state.disablePersonalDetailInput ? 'gray' : 'black'}} placeholder="Middle Name" name="gts_user_father_middle_name" value={gts_user_father_middle_name} onChange={this.changeHandler} disabled={this.state.isPersonalDetailExisting && this.state.disablePersonalDetailInput ? 'disablePersonalDetailInput' : ''} size="19"></input>
                  </div>
                  <div className="col-4">
                    <label><h6>Last Name&nbsp;: &nbsp;&nbsp;&nbsp;</h6></label>
                    <input type="text" onBlur={this.clearMessage} style={{ border: "1px solid #D3D3D3", height:"35px",color:this.state.isPersonalDetailExisting && this.state.disablePersonalDetailInput ? 'gray' : 'black'}} placeholder="Last Name" name="gts_user_father_last_name" value={gts_user_father_last_name} onChange={this.changeHandler} disabled={this.state.isPersonalDetailExisting && this.state.disablePersonalDetailInput ? 'disablePersonalDetailInput' : ''} size="19"></input>
                  </div>
                 </div>

                <div className="row">
                  <div className="col-4">
                    <label><h6>Mother's First Name:&nbsp;</h6></label>
                    <input type="text" onBlur={this.clearMessage} style={{ border: "1px solid #D3D3D3", height:"35px",color:this.state.isPersonalDetailExisting && this.state.disablePersonalDetailInput ? 'gray' : 'black'}} placeholder="First Name" name="gts_user_mother_first_name" value={gts_user_mother_first_name} onChange={this.changeHandler} disabled={this.state.isPersonalDetailExisting && this.state.disablePersonalDetailInput ? 'disablePersonalDetailInput' : ''} size="19" />
                  </div>
                  <div className="col-4">
                    <label><h6>Middle Name&nbsp;:&nbsp;</h6></label>
                    <input type="text" onBlur={this.clearMessage} style={{ border: "1px solid #D3D3D3", height:"35px",color:this.state.isPersonalDetailExisting && this.state.disablePersonalDetailInput ? 'gray' : 'black'}} placeholder="Middle Name" name="gts_user_mother_middle_name" value={gts_user_mother_middle_name} onChange={this.changeHandler} disabled={this.state.isPersonalDetailExisting && this.state.disablePersonalDetailInput ? 'disablePersonalDetailInput' : ''} size="19" />
                  </div>
                  <div className="col-4">
                    <label><h6>Last Name&nbsp;: &nbsp;&nbsp;&nbsp;</h6></label>
                    <input type="text" onBlur={this.clearMessage} style={{ border: "1px solid #D3D3D3", height:"35px",color:this.state.isPersonalDetailExisting && this.state.disablePersonalDetailInput ? 'gray' : 'black'}} placeholder="Last Name" name="gts_user_mother_last_name" value={gts_user_mother_last_name} onChange={this.changeHandler} disabled={this.state.isPersonalDetailExisting && this.state.disablePersonalDetailInput ? 'disablePersonalDetailInput' : ''} size="19" />
                  </div>

                </div>
                <br></br>

                <div id="verifyMobNoID" className="row">
                <div className="col-4">
                    <label><h6>Date of Birth&nbsp;<span style={{fontSize:"15px", color: "red"}} ><b>&nbsp;</b></span>&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6></label>
                    <input type="date" style={{ border: "1px solid #D3D3D3", width:"176px", height:"35px", color:this.state.isPersonalDetailExisting && this.state.disablePersonalDetailInput ? 'gray' :'black'}}
                    disabled={this.state.isPersonalDetailExisting && this.state.disablePersonalDetailInput ? 'disablePersonalDetailInput' : ''}
                            placeholder="dob"
                            name="gts_user_dob"
                            value={gts_user_dob}
                            onChange={this.changeHandler}
                            onBlur={this.validateDOB}
                            onFocus={this.clearDOBError} >
                    </input>
                    {/* <span style={{color: "red"}}>{this.state.errors["gts_user_gender"]}</span><br/> */}
                  </div>
                  <div className="col-4">
                    <label><h6>Gender&nbsp;<span style={{fontSize:"15px", color: "red"}} ><b>&nbsp;</b></span>&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6></label>
                    <select style={{ border: "1px solid #D3D3D3", width:"175px", height:"35px",color:this.state.isPersonalDetailExisting && this.state.disablePersonalDetailInput ? 'gray' :'black'}}
                    disabled={this.state.isPersonalDetailExisting && this.state.disablePersonalDetailInput ? 'disablePersonalDetailInput' : ''}
                            id='Gender'
                            onChange={this.selectGender}
                            onBlur={this.validateGender}
                            value={this.state.gts_user_gender}
                            onFocus={this.clearGenderError} >
                              {/* <option name="select">{this.state.isPersonalDetailExisting==true ? this.state.gts_user_gender : 'Select'}</option>  */}
                      <option disabled hidden>Select</option>
                      <option name="male">Male</option>
                      <option name="female">Female</option>
                      <option name="other">Other</option>
                    </select>
                    {/* <span style={{color: "red"}}>{this.state.errors["gts_user_gender"]}</span><br/> */}
                  </div>
                  <div className="col-4">
                    <label><h6>Nationality&nbsp;<span style={{fontSize:"15px", color: "red"}}></span>&nbsp;:&nbsp;&nbsp;</h6></label>
                    <Autocomplete
                      options={this.state.nationality}
                      style={{ width:"175px",height:"35px" ,outlineColor: "black", display: "inline-block"}}
                      renderInput={(params) => <TextField {...params} variant="outlined" style={{ color: "black" }} size="small"/>}
                      disabled={this.state.isPersonalDetailExisting && this.state.disablePersonalDetailInput ? 'disablePersonalDetailInput' : ''}
                      onChange={this.saveAutoCompleteNationality.bind(this)}
                      onBlur={this.validateNationality}
                      onFocus={this.clearNationalityError}
                      value={this.state.gts_user_nationality_name}
                      noOptionsText='No options'
                   />
                   </div>
                   <div align="right">
                    <span style={{color: "red"}}>{this.state.errors["gts_user_nationality"]}</span><br/>
                  </div>
                </div>

                <div>
                  {(() => {
                    if (isPersonalDetailExisting===false) {
                      return (
                        <div class="row" align="right">
                          <div class="col-13">
                          <button href="#" type="button" class="btn" style={{display: "none"}}  href="#" onClick={this.enableInput} onBlur={this.clearMessage}><FontAwesomeIcon icon={faEdit}  /></button>
                            <button type="button" class="btn btn-primary" style={{fontWeight: 'bold', display: profileOwner==="true"?"none":"inline"}}  href="#" onBlur={this.clearMessage} onClick
                            ={this.savePersonalDetailHandler}> Save</button>
                          </div>
                        </div>
                      )
                    }else {
                      return (
                        <div class="row" align="right">
                          <div class="col-13">
                          <button href="#" type="button" class="btn" href="#" style={{display: profileOwner==="true"?"none":"inline"}}  onClick={this.enableInput}><FontAwesomeIcon icon={faEdit}  /></button>
                            <button type="button" class="btn btn-primary" style={{fontWeight: 'bold', display: profileOwner==="true"?"none":"inline"}}  href="#" onBlur={this.clearMessage} onClick
                            ={this.savePersonalDetailHandler}> Save</button>
                          </div>
                        </div>
                      )
                    }
                  })()}
                </div>
  </div>
            </div>
          </form>
        </div>

      </div>

      <div className="container align-items-center">

    <div className='mt-3'>
      <form  >
        <div className="border border-dark rounded-lg" >

            <div className="row">
                <div className="col">
                  <h5 className="mb6-3 p-2"><b>Contact Detail &nbsp;&nbsp; </b></h5>
                </div>
                <div className="col-1">
                </div>
              </div>

              <div style={{borderBottomColor: 'black',borderBottomWidth: 1.5}} />
              <br/>
            <div className="container">

            <div id="verifyMobNoID" className="row">

              <label style={{fontSize:"10px",paddingLeft: "155px",marginBottom: "-55px"}}>Country Code&nbsp;&nbsp;&nbsp;</label>
              <div className="col-6" style={{paddingTop: "15px"}}>
                <label><h6>Primary Mob.No &nbsp;:&nbsp;&nbsp;&nbsp;</h6></label>
                <input type="text" placeholder="+" value="+" disabled style={{border: "1px solid #D3D3D3",width:"10px",height:"35px",color:"black"}}></input>
                <input type="text"
                        style={{ border: "1px solid #D3D3D3",height:"35px", width:"40px"}}
                        placeholder="91"
                        name="gts_user_primary_country_code"
                        value={gts_user_primary_country_code==0 ?'' : gts_user_primary_country_code}
                        onChange={this.changeHandler}
                        onBlur={this.validatePrimaryCountryCode}
                        onFocus={this.clearPrimaryCountryCodeError}>
                </input>&nbsp;&nbsp;

                <input type="text"
                        style={{ border: "1px solid #D3D3D3",height:"35px"}}
                        placeholder="primary mobile number"
                        name="gts_primary_contact_number"
                        value={gts_primary_contact_number}
                        onChange={this.changeHandler}
                        onBlur={this.validatePrimaryMob}
                        onFocus={this.clearPrimaryMobError}
                        size="12">
                </input>
                {/*
                  this.state.gts_primary_contact_is_validated === false ?

                  <label labelID="icon" styles={iconStyles} align="left">
                    <img src={process.env.PUBLIC_URL + "/images/valid.png"}
                        alt="pic" height= "50%" width= "20px" class="iconStyles" align="left" onChange={this.changeHandler} onBlur={this.validateIcon}/>
                  </label>

                 :<label labelID="icon" styles={iconStyles} align="left" onChange={this.changeHandler} onBlur={this.validateIcon}>
                    <img src={process.env.PUBLIC_URL + "/images/invalid.png"} alt="pic" height= "50%" width= "20px" class="iconStyles" align="left" onChange={this.changeHandler}/>
                    <button type="button" style={{display: "inline",fontSize:"10px"}} class="btn btn-primary" data-toggle="modal" align="left" data-target="#verifyMobNo"
                        onClick={this.generatePrimaryMobNoOTPHandler}>GET OTP</button>
                </label>
                */}

                {(() => {
                  return (
                      <button type="button" style={{fontSize:"10px", fontWeight: 'bold', display: profileOwner==="true"?"none":"inline"}} class="btn btn-primary"  href="#" align="left"
                      onBlur={this.clearMessage} onClick={this.updateUserPrimaryMobileHandler}>Update</button>
                  )
                })()}<br/>
                <span style={{color: "red"}}>{this.state.errors["gts_user_primary_country_code"]}</span><br/>
                <span style={{color: "red"}}>{this.state.errors["gts_primary_contact_number"]}</span><br/>
                <span style={{color:  "#228B22", fontWeight: 'bold'}}>{this.state.primaryContactNumberUpdatedMessage}</span><br/>

              </div>
              <div className="col-6" style={{paddingTop: "10px"}}>
                <label><h6>Primary Email&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;</h6></label>
                <input type="text"
                        style={{ border: "1px solid #D3D3D3",height:"35px"}}
                        placeholder="primaryemail@gmail.com"
                        name="gts_user_primary_email" value={gts_user_primary_email}
                        onChange={this.changeHandler}
                        onBlur={this.validateEmail}
                        onFocus={this.clearEmailError}
                        size="24">
                </input>

                {/*
                  ((this.state.gts_user_email_is_validated === false)|| (this.state.isVerified === false))?


                  <label labelID="icon" styles={iconStyles} align="left">
                    <img src={process.env.PUBLIC_URL + "/images/valid.png"}
                        alt="pic" height= "50%" width= "20px" class="iconStyles"  align="left" onChange={this.changeHandler} onBlur={this.validateIcon}/>
                  </label>

                 :<label labelID="icon" styles={iconStyles} align="left" onChange={this.changeHandler} onBlur={this.validateIcon}>
                    <img src={process.env.PUBLIC_URL + "/images/invalid.png"} alt="pic" height= "50%" width= "20px" class="iconStyles" align="left" onChange={this.changeHandler}/>
                    <button type="button" style={{fontSize:"10px",display: "inline"}}  class="btn btn-primary" data-toggle="modal" align="left" data-target="#verifyPrimaryEmailID"
                        onClick={this.generatePrimaryEmailOTPHandler}>GET OTP</button>
                </label>
                */}

                {(() => {
                  return (
                      <button type="button" style={{fontSize:"10px", fontWeight: 'bold', display: profileOwner==="true"?"none":"inline"}}  class="btn btn-primary" href="#" align="left" onBlur={this.clearMessage}
                        onClick={this.updateUserPrimaryEmailHandler}>Update</button>
                  )
                })()}<br/>
                <span style={{color: "red"}}>{this.state.errors["gts_user_primary_email"]}</span><br/>
                <span style={{color:  "#228B22", fontWeight: 'bold'}}>{this.state.primaryEmailUpdatedMessage}</span><br/>

              </div>

            </div>

            <div id="verifyAltMobNoID" className="row">

              <div className="col-6" style={{paddingTop: "10px"}}>
                <label><h6>Alternate Mob.No &nbsp;:&nbsp;</h6></label>
                <input type="text" placeholder="+" value="+" disabled style={{border: "1px solid #D3D3D3",width:"10px",height:"35px",color:"black"}}></input>
                <input type="text"
                        style={{ border: "1px solid #D3D3D3",height:"35px", width:"40px"}}
                        placeholder="91"
                        name="gts_user_country_code"
                        value={gts_user_country_code==0 ? '' : gts_user_country_code}
                        pattern="[0-9] {4}"
                        onChange={this.changeHandler}
                        onBlur={this.validateAltCountryCode}
                        onFocus={this.clearAltCountryCodeError}>
                </input>&nbsp;&nbsp;
                <input type="text"
                        style={{ border: "1px solid #D3D3D3",height:"35px"}}
                        placeholder="mobilenumber"
                        name="gts_user_alternate_mobile_number"
                        value={gts_user_alternate_mobile_number}
                        onChange={this.changeHandler}
                        onBlur={this.validateAltMob}
                        onFocus={this.clearAltMobError}
                        size="12"></input>

                {/*
                  this.state.gts_user_is_alternate_number_validated === false ?

                  <label labelID="icon" styles={iconStyles} align="left">
                    <img src={process.env.PUBLIC_URL + "/images/valid.png"}
                        alt="pic" height= "50%" width= "20px" class="iconStyles" align="left" onChange={this.changeHandler}/>
                  </label>

                 :<label labelID="icon" styles={iconStyles} align="left" onBlur={this.validateIcon}>
                    <img src={process.env.PUBLIC_URL + "/images/invalid.png"} alt="pic" height= "50%" width= "20px" class="iconStyles" align="left" onChange={this.changeHandler}/>
                    <button class="btn btn-primary btn-sm" type="button"  onBlur={this.clearMessage} style={{ fontSize:"10px", display: "inline"}}  class="btn btn-primary" data-toggle="modal" align="left" data-target="#verifyAltMobNo"
                        onClick={this.generateAltMobNoOTPHandler}>GET OTP</button>
                </label>
                */}

                {(() => {
                  return (
                      <button class="btn btn-primary btn-sm" type="button" style={{fontSize:"10px", fontWeight: 'bold', display: profileOwner==="true"?"none":"inline"}}  class="btn btn-primary" href="#" align="left" onBlur={this.clearMessage}
                        onClick={this.updateUserAltMobileHandler}>Update</button>
                  )
                })()}<br/>
                <span style={{color: "red"}}>{this.state.errors["gts_user_country_code"]}</span><br/>
                <span style={{color: "red"}}>{this.state.errors["gts_user_alternate_mobile_number"]}</span><br/>
                <span style={{color:  "#228B22", fontWeight: 'bold'}}>{this.state.altContactNumberUpdatedMessage}</span><br/>

              </div>
              <div className="col-6" style={{paddingTop: "10px"}}>
                <label><h6>Alternative Email:&nbsp; </h6></label>
                <input type="text"
                        style={{ border: "1px solid #D3D3D3",height:"35px"}}
                        placeholder="alternateemail@gmail.com"
                        name="gts_user_alternate_email_id"
                        value={gts_user_alternate_email_id}
                        onChange={this.changeHandler}
                        onBlur={this.validateAltEmail}
                        onFocus={this.clearAltEmailError}
                        size="24">
                </input>

                {/*
                  this.state.gts_user_is_alternate_email_validated === false ?

                  <label labelID="icon" styles={iconStyles} align="left">
                    <img src={process.env.PUBLIC_URL + "/images/valid.png"}
                        alt="pic" height= "50%" width= "20px" class="iconStyles" align="left" onChange={this.changeHandler}/>
                  </label>

                 :<label labelID="icon" styles={iconStyles} align="left" onBlur={this.validateIcon}>
                    <img src={process.env.PUBLIC_URL + "/images/invalid.png"} alt="pic" height= "50%" width= "20px" class="iconStyles" align="left" onChange={this.changeHandler}/>
                    <button type="button"style={{fontSize:"10px", display: "inline"}}  class="btn btn-primary" onBlur={this.clearMessage} data-toggle="modal" align="left" data-target="#verifyAltEmailID"
                        onClick={this.generateAltEmailOTPHandler}>GET OTP</button>
                </label>
                */}

                {(() => {
                  return (
                      <button type="button" style={{fontSize:"10px", fontWeight: 'bold', display: profileOwner==="true"?"none":"inline"}}   class="btn btn-primary" href="#" align="left" onBlur={this.clearMessage}
                        onClick={this.updateUserAltEmailHandler}>Update</button>
                  )
                })()}<br/>
                <span style={{color: "red"}}>{this.state.errors["gts_user_alternate_email_id"]}</span><br/>
                <span style={{color:  "#228B22", fontWeight: 'bold'}}>{this.state.altEmailUpdatedMessage}</span><br/>

              </div>
            </div>

        </div>
      </div>
    </form>
  </div>

    <div id="verifyPrimaryEmailID" class="modal fade" role="dialog">
        <div class="modal-dialog">
             <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title"> Verify your primary email</h5>
                 </div>
                  <div class="modal-body">
                    <div className="container" >
                      <div class="row-sm m-0  text-left  ">
                        <label style={{fontSize:"15px", color: "green", backgroundColor: "lightgreen", alignSelf: "center"}}><b>{this.state.email_otp_sent_message}</b></label><br></br>
                        <label>Enter OTP&nbsp;<span style={{fontSize:"15px", color: "red"}}><b>*</b></span>:</label>
                        <input type="text" class="text-primary" class="form-control" style={{ border: "1px solid #D3D3D3" }} name="gts_user_otp" value={gts_user_otp} placeholder="Enter otp" onChange={this.changeHandler}></input>

                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <div class="text-inline">
                      {this.state.email_otp_sent_message = ''}

                      {
                        ((this.state.gts_user_otp.length!=6)&&(!this.state.successAlert))
                        ? <Button id ="test" type="button" onBlur={this.clearMessage}>Verify OTP</Button>
                        : <Button id ="test" type="button" data-dismiss="modal" onClick={this.validatePrimaryEmailHandler} onBlur={this.clearMessage}>Verify OTP</Button>
                      }
                      <Button type="button" class="close" data-dismiss="modal" onBlur={this.clearMessage}>Cancel</Button>

                      <div>
                        <span style={{color:  "#228B22"}} onBlur={this.clearMessage}><center><b>{this.state.contactMessage}</b></center></span>
                        <span style={{color:  "red"}} onBlur={this.clearMessage}><center><b>{this.state.contactError}</b></center></span>
                        <span >{this.state.contactMessage || this.state.error ?<br/> : ''}</span>
                        <span onClick={this.clearMessage}>&nbsp;&nbsp;</span>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

    <div id="verifyMobNo" class="modal fade" role="dialog">
        <div class="modal-dialog">
             <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title"> Verify your Mobile Number</h5>
                 </div>
                  <div class="modal-body">
                    <div className="container" >
                      <div class="row-sm m-0  text-left  ">
                        <label style={{fontSize:"15px", color: "green", backgroundColor: "lightgreen", alignSelf: "center"}}><b>{this.state.mob_otp_sent_message}</b></label><br></br>
                        <label>Enter OTP&nbsp;<span style={{fontSize:"15px", color: "red"}}><b>*</b></span>:</label>
                        <input type="text" class="text-primary" class="form-control" style={{ border: "1px solid #D3D3D3" }} name="gts_user_primary_mob_otp" value={gts_user_primary_mob_otp} placeholder="Enter otp" onChange={this.changeHandler}></input>

                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <div class="text-inline">
                      {this.state.mob_otp_sent_message = ''}
                      {
                        ((this.state.gts_user_primary_mob_otp.length!=6)&&(!this.state.successAlert))
                        ? <Button id ="test" type="button" onBlur={this.clearMessage}>Verify OTP</Button>
                        : <Button id ="test" type="button" onBlur={this.clearMessage} data-dismiss="modal" onClick={this.validatePrimaryMobNoHandler}>Verify OTP</Button>
                      }
                      <Button type="button" class="close" onBlur={this.clearMessage} data-dismiss="modal">Cancel</Button>
                      {(this.state.contactMessage || this.state.contactError) ?
                      <div>
                        <span style={{color:  "#228B22"}} onBlur={this.clearMessage}><center><b>{this.state.contactMessage}</b></center></span>
                        <span style={{color:  "red"}} onBlur={this.clearMessage}><center><b>{this.state.contactError}</b></center></span>
                        <span >{this.state.contactMessage || this.state.error ?<br/> : ''}</span>
                        <span onClick={this.clearMessage}>&nbsp;&nbsp;</span>
                        </div> : ""
                    }
                    </div>
                  </div>
                </div>
              </div>
            </div>

    <div id="verifyAltEmailID" class="modal fade" role="dialog">
        <div class="modal-dialog">
             <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title"> Verify your alternate email</h5>
                 </div>
                  <div class="modal-body">
                    <div className="container" >
                      <div class="row-sm m-0  text-left  ">
                        <label style={{fontSize:"15px", color: "green", backgroundColor: "lightgreen", alignSelf: "center"}}><b>{this.state.alt_email_otp_sent_message}</b></label><br></br>
                        <label>Enter OTP&nbsp;<span style={{fontSize:"15px", color: "red"}}><b>*</b></span>:</label>
                        <input type="text" class="text-primary" class="form-control" style={{ border: "1px solid #D3D3D3" }} name="gts_user_alt_email_otp" value={gts_user_alt_email_otp} placeholder="Enter otp" onChange={this.changeHandler}></input>

                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <div class="text-inline">
                      {this.state.alt_email_otp_sent_message = ''}
                      {
                        ((this.state.gts_user_alt_email_otp.length!=6)&&(!this.state.successAlert))
                        ? <Button id ="test" type="button" onBlur={this.clearMessage}>Verify OTP</Button>
                        : <Button id ="test" type="button" data-dismiss="modal" onBlur={this.clearMessage} onClick={this.validateAltEmailHandler}>Verify OTP</Button>
                      }
                      <Button type="button" class="close" data-dismiss="modal" onBlur={this.clearMessage}>Cancel</Button>
                      {(this.state.contactMessage || this.state.contactError) ?
                        <div>
                          <span style={{color:  "#228B22"}} onBlur={this.clearMessage}><center><b>{this.state.contactMessage}</b></center></span>
                          <span style={{color:  "red"}} onBlur={this.clearMessage}><center><b>{this.state.contactError}</b></center></span>
                          <span >{this.state.contactMessage || this.state.error ?<br/> : ''}</span>
                          <span onClick={this.clearMessage}>&nbsp;&nbsp;</span>
                          </div> : ""
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>

    <div id="verifyAltMobNo" class="modal fade" role="dialog">
        <div class="modal-dialog">
             <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title"> Verify your alternate mobile number</h5>
                 </div>
                  <div class="modal-body">
                    <div className="container" >
                      <div class="row-sm m-0  text-left  ">
                        <label style={{fontSize:"15px", color: "green", backgroundColor: "lightgreen", alignSelf: "center"}}><b>{this.state.alt_mob_otp_sent_message}</b></label><br></br>
                        <label>Enter OTP&nbsp;<span style={{fontSize:"15px", color: "red"}}><b>*</b></span>:</label>
                        <input type="text" class="text-primary" class="form-control" style={{ border: "1px solid #D3D3D3" }} name="gts_user_alt_mob_otp" value={gts_user_alt_mob_otp} placeholder="Enter otp" onChange={this.changeHandler}></input>

                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <div class="text-inline">
                      {this.state.alt_mob_otp_sent_message = ''}

                      {
                        ((this.state.gts_user_alt_mob_otp.length!=6)&&(!this.state.successAlert))
                        ? <Button id ="test" type="button" onBlur={this.clearMessage}>Verify OTP</Button>
                        : <Button id ="test" type="button" onBlur={this.clearMessage} data-dismiss="modal" onClick={this.validateAltMobNoHandler}>Verify OTP</Button>
                      }
                      <Button type="button" class="close" onBlur={this.clearMessage} data-dismiss="modal">Cancel</Button>
                      {(this.state.contactMessage || this.state.contactError) ?
                        <div>
                          <span style={{color:  "#228B22"}} onBlur={this.clearMessage}><center><b>{this.state.contactMessage}</b></center></span>
                          <span style={{color:  "red"}} onBlur={this.clearMessage}><center><b>{this.state.contactError}</b></center></span>
                          <span >{this.state.contactMessage || this.state.error ?<br/> : ''}</span>
                          <span onClick={this.clearMessage}>&nbsp;&nbsp;</span>
                          </div> : ""
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>

    </div>
    </div>

    );
  }

}

export default UserPersonalDetailsComponent;