import React from 'react';

class ProductsComponent extends React.Component{
    render(){
        return(
            <div className="container align-items-center">
                <h3 className="pt-4 text-center font-bold">GTS PRODUCTS</h3>
                <div className="pt-4" style={{textAlign:"justify"}}>
                    <h6 className="font-bold pb-2">GTS Service Providers</h6>
                    <p>This product will help the individuals to explain their skills. 
                        Based on their skills they can search related services which they can provide.
                    </p>
                    <p>
                        Our AI & ML platform will help the Service Providers to recommend the most important Training Courses for them and also
                        the most suitable services which they can provide with optimized profits.
                    </p>
                    <p>
                        Service Providers can avail all the features through our world class Mobile App, 
                        Desktop Client & Browser Client.
                    </p>
                    <p>
                        Each and every person can register themselves as a Service Provider.
                    </p>
                    <p>
                        <a href="https://play.google.com/store/apps/details?id=com.gts.chakuri.JobSeeker">Mobile App</a>
                    </p>
                </div>
                <hr />
                <div className="pt-4" style={{textAlign:"justify"}}>
                    <h6 className="font-bold pb-2">GTS Service Consumers</h6>
                    <p>
                        If you are an individual or a company looking for any service from any skilled professionals like Engineers, Architects, Teachers, Drivers, Plumbers etc, then register with us.
                    </p> 
                    <p>
                        We ensure best quality resources screened by the Artificial Intelligence and Machine Learning of our Platform.
                    </p>
                    <p>
                        Employers can avail all the features through our world class Mobile App, Desktop Client & Browser Client.
                    </p>
                    <p>
                        <a href="https://play.google.com/store/apps/details?id=com.gts.chakuri.Employer">Mobile App</a>
                    </p>
                </div>
                <hr/>
                <div className="pt-4" style={{textAlign:"justify"}}>
                    <h6 className="font-bold pb-2">GTS Service Trainers</h6>
                    <p>
                        If you are skilled on any area and ready to train others, you can register with Chakuri Platform to earn money.  
                        You can search people who are looking for training on your areas of expertise. 
                    </p>
                    <p>
                        <a href="https://play.google.com/store/apps/details?id=com.gts.chakuri.Employer">Mobile App</a>
                    </p>
                </div>
                <hr />
                <div className="pt-4" style={{textAlign:"justify"}}>
                    <h6 className="font-bold pb-2">GTS Service Mediator/Expert</h6>
                    <p>
                        We at GTS, believe that Service Mediator/Expert play the biggest role to connect the Service Providers to the Service Consumers. 
                        Service Mediator/Expert are our Internal Employees of GTS.
                    </p>
                    <p>
                        For any help feel free to reach out to our Service Mediator/Expert.<br/>
                        Email_ID: hr.gts@ggtech.co.in<br/>
                        WhatsApp No.: +91-9739403914
                    </p>
                </div>
                <br/>
                <br/>
            </div>
        )
    }

}
export default ProductsComponent;