import React, { Component } from "react";
import "./SearchServiceProviderComponent.css";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import axios from "axios";
import ls from "local-storage";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton  from 'react-bootstrap/DropdownButton';
import { Redirect } from "react-router-dom";
import { endpoints_properties } from '../../properties/EndPointsProperties.js';
import { api_properties } from '../../properties/APIProperties.js';
import {Container, Grid, Slider, Paper, FormLabel, Select, Input, Checkbox, ListItemText, MenuItem, FormControl, Chip} from "@material-ui/core";
import { Link } from "react-router-dom";
import { Button, DropdownItem, Pagination, PaginationItem, PaginationLink } from "reactstrap";


var env=ls.get('ENV');
var token = ls.get("token");
var userLoginDetails = ls.get("userLoginDetails");

var skillsURL = "";
var industryURL = "";
var locationURL = "";
var companyURL = "";
var currencyURL = "";
var degreeURL = "";
var instituteURL = "";
var languageURL = "";
var searchServiceProvidersURL="";
var profileViewersURL = "";
var activateUserURL = "";
var inactivateUserURL = "";

if(env=="LOCAL"){
	skillsURL = endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_ACTIVE_SKILLS;
	industryURL = endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_ACTIVE_INDUSTRIES;
	locationURL = endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_ACTIVE_CITIES;
	companyURL = endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_ACTIVE_COMPANIES;
	currencyURL = endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_ACTIVE_CURRENCIES;
	degreeURL = endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_ACTIVE_DEGREES;
	instituteURL = endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_ACTIVE_INSTITUTES;
	languageURL = endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_LANGUAGES_ACTIVE;
	searchServiceProvidersURL = endpoints_properties.ENDPOINT_LOCAL+api_properties.API_SEARCH_SERVICE_PROVIDER;
	profileViewersURL = endpoints_properties.ENDPOINT_LOCAL+api_properties.API_PROFILE_VIEWER;
	activateUserURL = endpoints_properties.ENDPOINT_LOCAL+api_properties.API_ACTIVATE_USER;
	inactivateUserURL = endpoints_properties.ENDPOINT_LOCAL+api_properties.API_INACTIVATE_USER;
}
else{
	skillsURL = endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_SKILLS;
	industryURL = endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_INDUSTRIES;
	locationURL = endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_CITIES;
	companyURL = endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_COMPANIES;
	currencyURL = endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_CURRENCIES;
	degreeURL = endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_DEGREES;
	instituteURL = endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_INSTITUTES;
	languageURL = endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_LANGUAGES_ACTIVE;
	searchServiceProvidersURL = endpoints_properties.ENDPOINT_PROD+api_properties.API_SEARCH_SERVICE_PROVIDER;
	profileViewersURL = endpoints_properties.ENDPOINT_PROD+api_properties.API_PROFILE_VIEWER;
	activateUserURL = endpoints_properties.ENDPOINT_PROD+api_properties.API_ACTIVATE_USER;
	inactivateUserURL = endpoints_properties.ENDPOINT_PROD+api_properties.API_INACTIVATE_USER;
}


class SearchServiceProviderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceProvider: [],
      primarySkillName: [],
      secondarySkillName: [],
      modalOpen: false,
      dimmer: undefined,
      value: 20,
      rangeVal: [0, 10],
      yearVal: [0, 10],
      skills: [
        {
          gts_skill_id: 0,
          gts_skill_name: null,
          gts_skill_description: null,
          gts_skill_status: false,
        },
      ],
      skillName: [],
      selectedSkills: [],
      selectedAdSkills: [],
      chipData: [],
      industries: [
        {
          gts_industry_id: 0,
          gts_industry_name: "",
          gts_industry_description: null,
          gts_industry_status: false
        },
      ],
      industryName: [],
      selectedIndustries: [],
      locations: [
        {
          gts_city_id: 0,
          gts_city_name: "",
          gts_city_description: null,
          gts_city_status: false,
          gts_country_id: 0,
          gts_state_id: 0,
          gts_country_name: "",
          gts_state_name: ""
        }
      ],
      cityName: [],
      selectedCities: [],
      companies: [
        {
          gts_company_id: 0,
          gts_company_name: "",
          gts_company_description: "",
          gts_company_status: false,
          gts_company_contact_email: "",
          gts_country_code: 0,
          gts_company_contact_mobile_number: 0,
          gts_company_contact_alternate_mobile_number: 0
        }
      ],
      companyName: [],
      selectedCompanies: [],
      currencies: [
        {
          gts_currency_id: 0,
          gts_currency_code: "",
          gts_currency_name: "",
          gts_currency_status: false,
          gts_currency_description: null
        },
      ],
      currencyName: [],
      selectedCurrencies: [],
      degrees: [
        {
        gts_degree_id: 0,
        gts_degree_name: "",
        gts_degree_description: "",
        gts_degree_status: false
        }
      ],
      degreeName: [],
      selectedEducationLevel: [],
      institutes: [
        {
          gts_institute_id: 0,
          gts_institute_name: "",
          gts_institute_city_id: 0,
          gts_country_code: 0,
          gts_institute_contact_number: 0,
          gts_institute_description: "",
          gts_institute_email_id: "",
          gts_institute_country_id: 0,
          gts_institute_status: false,
          gts_country_name: "",
          gts_city_name: ""
        }
      ],
      instituteName: [],
      selectedInstitute: [],
      languages: [
        {
          gts_language_id: 0,
          gts_language_name: "",
          gts_language_description: null,
          gts_language_status: false
        }
      ],
      languageName: [],
      selectedLanguages: [],
      ratings: 0,
      gender: "",
      noticeperiods: [],
      accountstatus: [],
      minCurrentSalaryValue: 0,
      maxCurrentSalaryValue: 0,
      minExpectedSalaryValue: 0,
      maxExpectedSalaryValue: 0,
      minYearValue: 0,
      maxYearValue: 0,
      minNoticePeriod: 0,
      maxNoticePeriod: 0,
      minAge: 0,
      maxAge: 0,
      // account: "Activated",
      search: "",
      successMessage: "",
      errorMessage: "",
      loading: false,
      currentPage: 0,
    };

    this.cancel = "";
    // this.updateSkillsOption = this.updateSkillsOption.bind(this);
  }

  componentDidMount() {
    if (this.cancel) {
      this.cancel.cancel();
    }
    this.cancel = axios.CancelToken.source();

    this.skillsAutoCompleteChangeHandler();
  }

  //search
  handleOnInputChange = (event) => {
    let search = event.target.value.substr(0, 20);
    this.setState({ search: search, loading: true, message: "" });
  };

  //pagination
  handleClick(e, index) {
    e.preventDefault();
    this.setState({
      currentPage: index,
    });
  }

  onInputChangeHandler = (e)=>{
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  skillsAutoCompleteChangeHandler = (input) => {


    axios
      .get(skillsURL, {
        headers: { Auth_Token: `Bearer ${token}` },
      })
      .then((response) => {
        // console.log(response.data);
        this.state.skills = response.data;

        if (this.state.skillName.length != 0) this.state.skillName.length = [];
        this.state.skills.forEach((skills) => {
          this.state.skillName.push(skills.gts_skill_name);
          this.state.skillName.filter((value) => value.includes(input));
        });
      })
      .catch((error) => {
        if (axios.isCancel(error) || error) {
          this.setState({
            loading: false,
            errorMessage: "Failed to fetch the data",
          });
          console.log(error);
        }
      });
  };

  saveSkillAutoCompleteInput = (event, value) => {
    this.setState({
      errorMessage: "",
      successMessage: ""
    })
    this.state.selectedSkills = value;
    console.log(this.state.selectedSkills);
  };

  saveAdSkillAutoCompleteInput = (e, value)=>{
    this.state.selectedAdSkills = value;
  }

  industryAutoCompleteChangeHandler = (input) =>{


    axios
      .get(industryURL, { headers: { Auth_Token: `Bearer ${token}` },
      })
      .then((response)=>{
        this.state.industries = response.data;

        if (this.state.industryName.length != 0) this.state.industryName.length = [];
        this.state.industries.forEach((industry) => {
          this.state.industryName.push(industry.gts_industry_name);
          this.state.industryName.filter((value) => value.includes(input));
        })
      })
      .catch((error)=>{
        //console.log(error.response.data.message);
        console.log(error);
      })
  }

  saveIndustryAutoCompleteInput =(event, value) =>{
    this.state.selectedIndustries = value;
    console.log(this.state.selectedIndustries);
  }

  locationAutoCompleteChangeHandler = (input) =>{
    axios
      .get(locationURL,{ headers: { Auth_Token: `Bearer ${token}` },
      })
      .then((response)=>{
        this.state.locations = response.data;

        if (this.state.cityName.length != 0) this.state.cityName.length = [];
          this.state.locations.forEach((location) => {
            this.state.cityName.push(location.gts_city_name);
            this.state.cityName.filter((value) => value.includes(input));
          })
      })
      .catch((error)=>{
        // console.log(error.response.data.message);
        console.log(error);

      })
  }

  saveLocationAutoCompleteInput = (event, value) =>{
    this.state.selectedCities = value;
    console.log(this.state.selectedCities);
  }

  companyAutoCompleteChangeHandler = (input) =>{


    axios
      .get(companyURL, { headers: { Auth_Token: `Bearer ${token}` },
      })
      .then((response)=>{
        this.state.companies = response.data;

        if (this.state.companyName.length != 0) this.state.companyName.length = [];
          this.state.companies.forEach((company) => {
            this.state.companyName.push(company.gts_company_name);
            this.state.companyName.filter((value) => value.includes(input));
          })
      })
      .catch((error)=>{
        //console.log(error.response.data.message);
        console.log(error);

      })
  }

  saveCompanyAutoCompleteInput =(event, value)=>{
    this.state.selectedCompanies = value;
    console.log(this.state.selectedCompanies);
  }

  currencyAutoCompleteChangeHandler = (input) =>{


    axios
      .get(currencyURL, { headers: { Auth_Token: `Bearer ${token}` },
      })
      .then((response)=>{
        this.state.currencies = response.data;

        if (this.state.currencyName.length != 0) this.state.currencyName.length = [];
          this.state.currencies.forEach((currency) => {
             this.state.currencyName.push
          (currency.gts_currency_name+"("+currency.gts_currency_code+")");
          })
      })
      .catch((error)=>{
        //console.log(error.response.data.message);
        console.log(error);

      })
  }
  saveCurrencyAutoCompleteInput = (event, value)=>{
    this.state.selectedCurrencies = value;
    console.log(this.state.selectedCurrencies);
  }

  degreeAutoCompleteChangeHandler = (input)=>{

//alert(1);
    axios
    .get(degreeURL, { headers: { Auth_Token: `Bearer ${token}` },
    })
    .then((response)=>{
      this.state.degrees = response.data;
      if (this.state.degreeName.length != 0) this.state.degreeName.length = [];
          this.state.degrees.forEach((degree) => {
            this.state.degreeName.push(degree.gts_degree_name);
            this.state.degreeName.filter((value) => value.includes(input));
          })
    })
    .catch((error)=>{
      //console.log(error.response.data.message);
      console.log(error);
    })
  }

  saveDegreeAutoCompleteInput = (event, value) =>{
    this.state.selectedEducationLevel = value;
    console.log(this.state.selectedEducationLevel);
  }

  instituteAutoCompleteChangeHandler = (input)=>{


    axios
      .get(instituteURL, { headers: { Auth_Token: `Bearer ${token}` },
      })
      .then((response)=>{
        this.state.institutes = response.data;
        if (this.state.instituteName.length != 0) this.state.instituteName.length = [];
          this.state.institutes.forEach((institute) => {
            this.state.instituteName.push(institute.gts_institute_name);
            this.state.instituteName.filter((value) => value.includes(input));
          })
      })
      .catch((error)=>{
        //console.log(error.response.data.message);
        console.log(error);
      })
  }

  saveInstituteAutoCompleteInput = (event, value) =>{
    this.state.selectedInstitute = value;
    console.log(this.state.selectedInstitute);
  }

  languageAutoCompleteChangeHandler = (input)=>{


    axios
      .get(languageURL, { headers: { Auth_Token: `Bearer ${token}` },
      })
      .then((response)=>{
        this.state.languages = response.data;
        if (this.state.languageName.length != 0) this.state.languageName.length = [];
          this.state.languages.forEach((language) => {
            this.state.languageName.push(language.gts_language_name);
            this.state.languageName.filter((value) => value.includes(input));
          })
      })
      .catch((error)=>{
        //console.log(error.response.data.message);
        console.log(error);
      })
  }

  saveLanguageAutoCompleteInput = (event, value) =>{
    this.state.selectedLanguages = value;
    console.log(this.state.selectedLanguages);
  }

  ratings = (e)=>{
    this.state.ratings = e.target.value;
  }

  gender = (e)=>{
    this.state.gender = e.target.value;
  }

  fetchServiceProvider = () => {
    const selectedSkillIds = [];
    this.state.selectedSkills.forEach((selectedSkill) => {
      this.state.skills.forEach((skill) => {
        if (selectedSkill === skill.gts_skill_name) {
          selectedSkillIds.push(skill.gts_skill_id);
        }
      });
    });
    console.log("Selected skill id: " + selectedSkillIds.join(",").toString());
    console.log("userLoginDetails.user_id: "+userLoginDetails.user_id);

    var payload = {
      gts_employee_searched_by_id: userLoginDetails.user_id,
      gts_employee_search_skill_ids: selectedSkillIds.join(",").toString(),
    };

    axios
      .post(searchServiceProvidersURL, payload, {
        headers: { Auth_Token: `Bearer ${token}` },
      })
      .then((response) => {
        this.setState({
          serviceProvider: response.data,
          successMessage: "Search Results",
          loading: false,
        });

      })
      .catch((error) => {
        if (axios.isCancel(error) || error) {
          this.setState({
            loading: false,
            errorMessage: error.response.data.message
          });
         console.log(error.response.data.message);
         console.log("Failed to search: "+error);

        }
      });
  }

  serviceProviderFromAdvanceSearch = ()=>{
    const selectedAdSkillIds = [];
    this.state.selectedAdSkills.forEach((selectedSkill) => {
      this.state.skills.forEach((skill) => {
        if (selectedSkill === skill.gts_skill_name) {
          selectedAdSkillIds.push(skill.gts_skill_id);
        }
      })
    })
    // console.log("Selected skill id: " + selectedAdSkillIds.join(",").toString());


    const selectedIndustryIds = [];
    this.state.selectedIndustries.forEach((selectedIndustry)=>{
      this.state.industries.forEach((industry)=>{
        if (selectedIndustry === industry.gts_industry_name){
          selectedIndustryIds.push(industry.gts_industry_id);
        }
      })
    })
    // console.log("Selected industry id: " + selectedIndustryIds.join(",").toString());

    const selectedLocationIds = [];
    this.state.selectedCities.forEach((selectedLocation)=>{
      this.state.locations.forEach((location)=>{
        if (selectedLocation === location.gts_city_name){
          selectedLocationIds.push(location.gts_city_id);
        }
      })
    })
    // console.log("Selected location id: " + selectedLocationIds.join(",").toString());

    const selectedCompanyIds = [];
    this.state.selectedCompanies.forEach((selectedCompany)=>{
      this.state.companies.forEach((company)=>{
        if (selectedCompany === company.gts_company_name){
          selectedCompanyIds.push(company.gts_company_id);
        }
      })
    })
    // console.log("Selected company id: " + selectedCompanyIds.join(",").toString());

    const selectedCurrencyIds = [];
    this.state.selectedCurrencies.forEach((selectedCurrency)=>{
      this.state.currencies.forEach((currency)=>{
        if (selectedCurrency.split('(')[0] === currency.gts_currency_name){
          selectedCurrencyIds.push(currency.gts_currency_id);
        }
      })
    })
    console.log("Selected currency id: " + selectedCurrencyIds.join(",").toString());

    const selectedDegreeIds = [];
    this.state.selectedEducationLevel.forEach((selectedDegree)=>{
      this.state.degrees.forEach((degree)=>{
        if (selectedDegree === degree.gts_degree_name){
          selectedDegreeIds.push(degree.gts_degree_id);
        }
      })
    })
    // console.log("Selected Degree id: " + selectedDegreeIds.join(",").toString());

    const selectedInstituteIds = [];
    this.state.selectedInstitute.forEach((selectedInstitute)=>{
      this.state.institutes.forEach((institute)=>{
        if (selectedInstitute === institute.gts_institute_name){
          selectedInstituteIds.push(institute.gts_institute_id);
        }
      })
    })
    // console.log("Selected institute id: " + selectedInstituteIds.join(",").toString());

    const selectedLanguageIds = [];
    this.state.selectedLanguages.forEach((selectedLanguage)=>{
      this.state.languages.forEach((language)=>{
        if (selectedLanguage === language.gts_language_name){
          selectedLanguageIds.push(language.gts_language_id);
        }
      })
    })
    // console.log("Selected language id: " + selectedLanguageIds.join(",").toString());
    this.state.errorMessage = "";

    var advPayload = {
      gts_employee_searched_by_id: userLoginDetails.user_id,
      gts_employee_search_skill_ids: selectedAdSkillIds.join(",").toString(),
      gts_employee_search_industry_ids: selectedIndustryIds.join(",").toString(),
      gts_employee_search_city_ids: selectedLocationIds.join(",").toString(),
      gts_employee_search_company_ids: selectedCompanyIds.join(",").toString(),
      gts_employee_search_salary_currency_id: selectedCurrencyIds.join(",").toString(),
      gts_employee_search_education_ids: selectedDegreeIds.join(",").toString(),
      gts_employee_search_institutes_ids: selectedInstituteIds.join(",").toString(),
      gts_employee_search_language_ids: selectedLanguageIds.join(",").toString(),
      gts_employee_search_ratings: this.state.ratings.toString(),
      gts_employee_search_gender: this.state.gender.toString(),
      gts_employee_search_current_salary_min: this.state.minCurrentSalaryValue.toString(),
      gts_employee_search_current_salary_max: this.state.maxCurrentSalaryValue.toString(),
      gts_employee_search_expected_salary_min: this.state.minExpectedSalaryValue.toString(),
      gts_employee_search_expected_salary_max: this.state.maxExpectedSalaryValue.toString(),
      gts_employee_search_year_of_experience_min: this.state.minYearValue.toString(),
      gts_employee_search_year_of_experience_max: this.state.maxYearValue.toString(),
      gts_employee_search_notice_period_min: this.state.minNoticePeriod.toString(),
      gts_employee_search_notice_period_max: this.state.maxNoticePeriod.toString(),
      gts_employee_search_age_min: this.state.minAge.toString(),
      gts_employee_search_age_max: this.state.maxAge.toString(),

    };
    console.log(advPayload);

    axios
    .post(searchServiceProvidersURL, advPayload, {
      headers: { Auth_Token: `Bearer ${token}` },
    })
    .then((response)=>{

      alert(2);
      this.setState({
        serviceProvider: response.data,
        successMessage: "Search Results",

      })
      console.log(response.data);
    })
    .catch((error)=>{
      this.setState({
        errorMessage: error.response.data.message
      })
      console.log(error.response.data.message);
    })
  }

   StarRating = (count, value, inactiveColor, size, activeColor)=> {
      const stars = Array.from({length: count}, ()=> '★')
      return(
        <>
          {stars.map((s,index) =>{
            let style = inactiveColor;
            if(index< value){
              style = activeColor;
            }
            return(
              <span
              className= {"star"}
              key={index}
              style={{color: style, width: size, height: size, fontSize: size}}
              >{s}
              </span>)
          })}
        </>
      )
    }

    clearAllFields = () =>{
      this.setState({
        selectedAdSkills: [],
        selectedIndustries: [],
        selectedCities: [],
        selectedCompanies: [],
        selectedCurrencies: [],
        selectedEducationLevel: [],
        selectedInstitute: [],
        selectedLanguages: [],
      })
    }

    saveProfileViewerInfo = (userID)=>{
	  console.log("gts_user_profile_owner_id: "+userID);
	  console.log("gts_user_profile_viewer_id: "+userLoginDetails.user_id);

      ls.set('gts_user_profile_owner_id', userID);

      var payload = {
        "gts_user_profile_owner_id": userID,
        "gts_user_profile_viewer_id": userLoginDetails.user_id
        }
      console.log("Saving profile viewer info...");

      axios.post(profileViewersURL, payload, {
        headers: { Auth_Token: `Bearer ${token}` },
      })
      .then((response)=>{
        console.log("Profile viewer ID is captured")
        console.log("response.data.message: "+response.data.message)
        window.location.href="/gts/service-provider-profile?profile-owner=true"
      })
	  .catch(error =>{
		console.log("Failed to capture Profile Viewer ID");
	 })

    }

    displayButton = (userID)=>{
	  console.log("userID: "+userID);
      if(userLoginDetails.primary_role === "ADMIN"){
        return(
          <DropdownButton title="Activate/Deactivate" variant="none">
            <Dropdown.Item onBlur={this.clearSuccessMessage} onClick={()=>this.ActivateOrDeactivateUser(userID,'true')}>Activate</Dropdown.Item>
            <Dropdown.Item onBlur={this.clearSuccessMessage} onClick={()=>this.ActivateOrDeactivateUser(userID,'false')}>Deactivate</Dropdown.Item>
          </DropdownButton>
        )
      }
      else{
        return(<div></div>)
      }
    }

    clearSuccessMessage =()=>{
      this.setState({
        successMessage:'',
        errorMessage : ''
      })
    }

    ActivateOrDeactivateUser =(userID,value)=>{
      var payload = {
          "gts_user_id": userID
      }

      if(value == 'true'){

        axios
        .put(activateUserURL, payload, {
          headers: { Auth_Token: `Bearer ${token}` },
        })
        .then((response)=>{
          this.setState({
            successMessage: "User account activated successfully",
          })
        })
        .catch((err) =>{
          this.setState({
            errorMessage: "Not able to activate user account"
          })
        })
      }
      else{

        axios
        .put(inactivateUserURL, payload, {
          headers: { Auth_Token: `Bearer ${token}` },
        })
        .then((response)=>{
          this.setState({
            successMessage: "User account inactivated successfully",
          })
        })
        .catch((err) =>{
          this.setState({
            errorMessage: "Not able to inactivate user account"
          })
        })
      }
    }

    clearSuccessMessage =()=>{
      this.setState({
        successMessage:'',
        errorMessage : ''
      })
    }

  render() {

    return (
      <Grid container spacing={2}>
        <Grid item xs={9} className="grid-two">
          <h4 className="h4-searchtext">Enter Skills in the Search Bar</h4>
          <div>
            <Autocomplete
              multiple
              options={this.state.skillName}
              style={{ width: "100%", outlineColor: "black" }}
              filterSelectedOptions
              onChange={this.saveSkillAutoCompleteInput}
              onBlur={this.clearSuccessMessage}
              renderInput={(params) => {
                this.skillsAutoCompleteChangeHandler(params);
                return (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Skills"
                  />
                );
              }}
            />
          </div>
          <div class="errorMsg">
            {this.state.errorMessage}
          </div>
          <div className="text-center">
            <Button color="primary" onBlur={this.clearSuccessMessage} onClick={this.fetchServiceProvider}>
              Search
            </Button>
            <Button
              color="link"
              data-toggle="modal"
              onBlur={this.clearSuccessMessage}
              data-target="#advancedSearch"
              style={{paddingLeft: "10px"}}
            >
              Advanced Search
            </Button>
          </div>
          <div class="successMsg">
            {this.state.successMessage}
          </div>
              {/* ----------------------- Mapping ----------------------- */}
              {
				 console.log("this.state.serviceProvider: "+this.state.serviceProvider)
              }
              {
				 console.log("this.state.serviceProvider.map.length: "+this.state.serviceProvider.map.length)
              }

            {
				//(this.state.serviceProvider==null || this.state.serviceProvider !="" || this.state.serviceProvider!="undefined")?:



				this.state.serviceProvider.map((userEntity)=>(
                      <div class="div1">
                        <div class="row">
                          <div class="col-4 pr-0 ml-2">

                            <h4>
                            <AccountCircleIcon fontSize="large"/>
                            {console.log("userEntity: "+userEntity)}
                              {console.log("Name: "+userEntity.gts_user_name)}

                              {userEntity.gts_user_name}

                              {console.log("***Skills***")}
                            </h4>


							{
                              userEntity.gts_employee_skills.map((skill)=>{

                                if(skill.gts_skill_is_primary == true){
                                this.state.primarySkillName.push(skill.gts_skill_name)
                                { console.log(this.state.primarySkillName);  }
                              }

                              else{
								  { console.log(this.state.secondarySkillName);  }
                                this.state.secondarySkillName.push(skill.gts_skill_name)
                              }
                            })}
                            <h6>Primary Skills: <strong>{this.state.primarySkillName.toString()}</strong></h6>
                            <h6>Secondary Skills: <strong>{this.state.secondarySkillName.toString()}</strong></h6>

                            {userEntity.gts_employee_work_expereince.map((workExp)=>{
                              this.state.primarySkillName= [];
                              this.state.secondarySkillName= [];

							  { console.log("workExp.is_currently_working: "+workExp.is_currently_working);  }

                              if(workExp.is_currently_working === true){

                                return <h6>Current Company: <strong>{workExp.gts_user_company_name}</strong></h6>
                              }
                              else{
                                return <h6>Current Company: <strong>Unemployed</strong></h6>
                              }
                            })}

                          </div>
                          <div class="col-4 p-0">
                            <h6>Primary Contact: <span class="span"> {userEntity.gts_primary_contact_number}</span></h6>
                            <h6>Email: <span class="span">{userEntity.gts_user_primary_email}</span></h6>
                            <h6>Ratings:{userEntity.sp_ratings > 0 ? this.StarRating(5, userEntity.sp_ratings[0].gts_sp_avg_ratings, 'grey', 20, 'yellow'):this.StarRating(5, 0, 'grey', 20, 'yellow')}</h6>
                          </div>
                          <div class="col-3 p-0">

                            {userEntity.gts_employee_salary_details === null ? <h6>Years of Exp:  <span class="span">0</span></h6> :
                                 <h6>Years of Exp: <span class="span">{userEntity.gts_employee_salary_details==null?"":userEntity.gts_employee_salary_details.gts_years_of_experience}</span></h6>
                              }
                              {userEntity.gts_employee_city.length === 0 ? <h6>Location: <span class="span">Unknown</span></h6> :
                                <h6>Location: <span class="span">{userEntity.gts_employee_city[0].gts_city_name}</span></h6>
                              }
                            <DropdownButton title="Actions" variant="none">
                              <Dropdown.Item onBlur={this.clearSuccessMessage} onClick={()=>this.saveProfileViewerInfo(userEntity.gts_user_id)}>
                                  View Profile
                              </Dropdown.Item>
                              <Dropdown.Item href="/gts/schedule-interviews" onBlur={this.clearSuccessMessage}>Schedule Interview</Dropdown.Item>
                              <Dropdown.Item disabled href="/gts/recommend-tests" onBlur={this.clearSuccessMessage}>Recommend Test</Dropdown.Item>
                              <Dropdown.Item  href="/gts/self-assessment" onBlur={this.clearSuccessMessage} onClick={()=>this.saveProfileViewerInfo(userEntity.gts_user_id)}>
                              	View Skill Assessment
                              </Dropdown.Item>
                            </DropdownButton>
                            <div>{this.displayButton(userEntity.gts_user_id)}</div>
                          </div>
                        </div>
                      </div>
            ))

            }

                  {/*-------------------- Modal dialog --------------------*/}

            <div id="advancedSearch" class="modal fade" role="dialog">
             <div class="modal-dialog modal-lg">
              <div class="modal-content" style={{ border: "solid 2px black" }}>
                <div class="modal-header">
                  <h4 class="modal-title">Advanced Search</h4>
                  <button type="button" class="close" onBlur={this.clearSuccessMessage} data-dismiss="modal">&times;</button>
                </div>
                <div class="modal-body" style={{ border: "solid 2px black" }}>
                  <div class="row">
                    <div class="col">
                      <label for="skills">Skills</label>
                      <Autocomplete
                        class="autocomplete"
                        multiple
                        id="skills"
                        options={this.state.skillName}
                        style={{outlineColor: "black" }}
                        filterSelectedOptions
                        onBlur={this.clearSuccessMessage}
                        onChange={this.saveAdSkillAutoCompleteInput}
                        renderInput={(params) => {
                          this.skillsAutoCompleteChangeHandler(params);
                          return (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Skills"
                            />
                          );
                        }}
                      />
                    </div>
                    <div class="col">
                      <label for="industry">Industry</label>
                      <Autocomplete
                        class="autocomplete"
                        multiple
                        id="industry"
                        options={this.state.industryName}
                        style={{outlineColor: "black" }}
                        onBlur={this.clearSuccessMessage}
                        filterSelectedOptions
                        onChange={this.saveIndustryAutoCompleteInput}
                        renderInput={(params) => {
                          this.industryAutoCompleteChangeHandler(params);
                          return (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Industry"
                            />
                          );
                        }}
                      />
                    </div>
                    <div class="col">
                      <label for="location">Location</label>
                      <Autocomplete
                        class="autocomplete"
                        multiple
                        id="location"
                        options={this.state.cityName}
                        style={{outlineColor: "black" }}
                        onBlur={this.clearSuccessMessage}
                        filterSelectedOptions
                        onChange={this.saveLocationAutoCompleteInput}
                        renderInput={(params) => {
                          this.locationAutoCompleteChangeHandler(params);
                          return (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Location"
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col">
                      <label for="company">Company</label>
                      <Autocomplete
                        class="autocomplete"
                        multiple
                        id="company"
                        options={this.state.companyName}
                        style={{outlineColor: "black" }}
                        onBlur={this.clearSuccessMessage}
                        filterSelectedOptions
                        onChange={this.saveCompanyAutoCompleteInput}
                        renderInput={(params) => {
                          this.companyAutoCompleteChangeHandler(params);
                          return (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Company"
                            />
                          );
                        }}
                      />
                    </div>
                    <div class="col">
                      <label for="currency">Currency</label>
                      <Autocomplete
                        class="autocomplete"
                        multiple
                        id="currency"
                        options={this.state.currencyName}
                        onBlur={this.clearSuccessMessage}
                        style={{outlineColor: "black" }}
                        filterSelectedOptions
                        onChange={this.saveCurrencyAutoCompleteInput}
                        renderInput={(params) => {
                          this.currencyAutoCompleteChangeHandler(params);
                          return (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Currency"
                            />
                          );
                        }}
                      />
                    </div>
                    <div class="col">
                        <label for="degree">Education Level</label>
                        <Autocomplete
                          multiple
                          id="degree"
                          options={this.state.degreeName}
                          onBlur={this.clearSuccessMessage}
                          style={{outlineColor: "black" }}
                          filterSelectedOptions
                          onChange={this.saveDegreeAutoCompleteInput}
                          renderInput={(params) => {
                            this.degreeAutoCompleteChangeHandler(params);
                            return (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Education Level"
                              />
                            );
                          }}
                        />
                    </div>
                  </div>
                  <hr />

                  <div class="row">
                    <div class="col">
                        <label for="institute">Institute</label>
                        <Autocomplete
                          multiple
                          id="institute"
                          options={this.state.instituteName}
                          onBlur={this.clearSuccessMessage}
                          style={{outlineColor: "black" }}
                          filterSelectedOptions
                          onChange={this.saveInstituteAutoCompleteInput}
                          renderInput={(params) => {
                            this.instituteAutoCompleteChangeHandler(params);
                            return (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Institute"
                              />
                            );
                          }}
                        />
                    </div>
                    <div class="col">
                        <label for="language">Language</label>
                        <Autocomplete
                          multiple
                          id="language"
                          options={this.state.languageName}
                          onBlur={this.clearSuccessMessage}
                          style={{outlineColor: "black" }}
                          filterSelectedOptions
                          onChange={this.saveLanguageAutoCompleteInput}
                          renderInput={(params) => {
                            this.languageAutoCompleteChangeHandler(params);
                            return (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Languages"
                              />
                            );
                          }}
                        />
                    </div>
                  </div>
                  <hr />

                  <div class="row">
                    <div class="col">
                      <div class="row pl-4">Current Salary</div>
                        <label for="minCurrentSalary">Min &nbsp;</label><input  id="minCurrentSalary" onBlur={this.clearSuccessMessage} type="text" placeholder="Enter Min Current Salary" class="inputText" name="minCurrentSalaryValue" onChange={this.onInputChangeHandler} />
                        <label for="maxCurrentSalary">Max &nbsp;</label><input id="maxCurrentSalary" onBlur={this.clearSuccessMessage} type="text" placeholder="Enter Max Current Salary" class="inputText" name="maxCurrentSalaryValue" onChange={this.onInputChangeHandler} />
                    </div>
                    <div class="col">
                    <div class="row pl-4">Expected Salary</div>
                        <label for="minExpectedSalary">Min &nbsp;</label><input id="minExpectedSalary" onBlur={this.clearSuccessMessage} type="text" placeholder="Enter Min Expected Salary" class="inputText" name="minExpectedSalaryValue" onChange={this.onInputChangeHandler} />
                        <label for="maxExpectedSalary">Max &nbsp;</label><input id="maxExpectedSalary" onBlur={this.clearSuccessMessage} type="text" placeholder="Enter Max Expected Salary" class="inputText" name="maxExpectedSalaryValue" onChange={this.onInputChangeHandler} />
                    </div>
                    <div class="col">
                    <div class="row pl-4">Year of Experience</div>
                      <label for="minYear">Min &nbsp;</label><input id="minYear" type="text" onBlur={this.clearSuccessMessage} placeholder="Enter Min year of exp" class="inputText" name="minYearValue" onChange={this.onInputChangeHandler} />
                      <label for="maxYear">Max &nbsp;</label><input id="maxYear" type="text" onBlur={this.clearSuccessMessage} placeholder="Enter Max year of exp" class="inputText" name="maxYearValue" onChange={this.onInputChangeHandler} />
                    </div>
                  </div>
                  <hr />

                  <div class="row">
                    <div class="col">
                      <div class="row pl-4">Notice Period</div>
                        <label for="minNotice">Min &nbsp;</label><input id="minNotice" type="text" onBlur={this.clearSuccessMessage} placeholder="Enter Min Notice period" class="inputText" name="minNoticePeriod" onChange={this.onInputChangeHandler} />
                        <label for="maxNotice">Max &nbsp;</label><input id="maxNotice" type="text" onBlur={this.clearSuccessMessage} placeholder="Enter Max Notice period" class="inputText" name="maxNoticePeriod" onChange={this.onInputChangeHandler}  />
                    </div>
                    <div class="col">
                      <div class="row pl-4">Age</div>
                        <label for="minAge">Min &nbsp;</label><input id="minAge" type="text" onBlur={this.clearSuccessMessage} placeholder="Enter Min Age" class="inputText" name="minAge" onChange={this.onInputChangeHandler}  />
                        <label for="maxAge">Max &nbsp;</label><input id="maxAge" type="text" onBlur={this.clearSuccessMessage} placeholder="Enter Max Age" class="inputText" name="maxAge" onChange={this.onInputChangeHandler}  />
                    </div>
                    <div class="col">
                      <div class="row pl-4">Ratings</div>
                      <select class="select" onChange={this.ratings} onBlur={this.clearSuccessMessage}>
                        <option selected disabled hidden>Ratings</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                      <div class="row pl-4">Gender</div>
                      <select class="select" onChange={this.gender} onBlur={this.clearSuccessMessage}>
                        <option selected disabled hidden>Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                    </div>
                  <div class="text-center">
                      <Button color="primary" class="close" data-dismiss="modal"  onBlur={this.clearSuccessMessage} onClick={this.serviceProviderFromAdvanceSearch}>
                        Search
                      </Button>

                  </div>
                </div>
                <div style={{display: this.state.primarySkillName!==null ? 'none' : 'block'}}>
                   { this.state.primarySkillName =  []}
             </div>
             <div style={{display: this.state.primarySkillName!==null ? 'none' : 'block'}}>
                   { this.state.secondarySkillName =  []}
             </div>
              </div>
             </div>
            </div>
        </Grid>
      </Grid>
    );
  }
}

export default SearchServiceProviderComponent;
