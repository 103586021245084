import React, { Component } from 'react';

import { Button } from 'react-bootstrap';
import { faUser, faTrash, faEdit, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';
import styles from './RequiredSkillsComponent.css';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { black, white } from 'material-ui/styles/colors';
import { blue } from '@material-ui/core/colors';
import { colors } from '@material-ui/core';
import { darkBlack } from 'material-ui/styles/colors';
import SecondaryRequiredSkillsComponent from '../../components/service_consumer/SecondaryRequiredSkillsComponent';
import ls from 'local-storage';
import { endpoints_properties } from '../../properties/EndPointsProperties.js';
import { api_properties } from '../../properties/APIProperties.js';

var userLoginDetails=ls.get('userLoginDetails');
var token=ls.get('token');
var gts_user_id = '';
if(userLoginDetails!= null){
    if (ls.get('gts_user_id')!= userLoginDetails.user_id){
      gts_user_id = ls.get('gts_user_id');
    }else{
      gts_user_id = userLoginDetails.user_id;
    }
}

class PrimaryRequiredSkillsComponent extends Component{
    constructor(props) {
     super(props);
     this.state={
         primaryCounter:0,
         requiredProficiency:'',
         requiredSkill:'',
         yearsOfExperience:'',
         requiredProficiencyValidationFlag:false,
         requiredSkillValidationFlag:false,
         yearsOfExperienceValidationFlag:false,
         saveFlag:false,
         editSaveFlag:false,
         addValidationFalg:false,
         cancelFlag:false,
         requiredSkills:[],
         skills:[{
             gts_skill_id : 0,
             gts_skill_name : null,
             gts_skill_description: null,
             gts_skill_status: false
           }],
          requiredSkill:{
            gts_employer_required_skill_id:0,
            gts_employer_id: 0,
            gts_required_skill_id :0 ,
            gts_skill_required_rating : 0 ,
            gts_required_experience_in_months  :0,
            gts_skill_is_primary : true,
            gts_required_skill_status : 1 ,
            gts_required_skill_name : null
            },
            userPrimaryRequiredSkills:[
            ]
        }
    }

  componentDidMount(){
     var get_url = endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_SC_REQUIRED_SKILLS+'/'+gts_user_id;
     axios.get(get_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
     .then(res => {
        var userPrimaryRequiredSkills = [];
         for(var i=0;i<res.data.length;i++){
             if(res.data[i].gts_skill_is_primary == true){
             userPrimaryRequiredSkills=userPrimaryRequiredSkills.concat(res.data[i])
             this.setState({userPrimaryRequiredSkills : userPrimaryRequiredSkills});
             this.setState({
                gts_employer_required_skill_id :res.data[i].gts_employer_required_skill_id,
                gts_required_skill_id :res.data[i].gts_required_skill_id,
                gts_required_skill_name :res.data[i].gts_required_skill_name,
                gts_skill_required_rating:res.data[i].gts_skill_required_rating,
                gts_required_experience_in_months:res.data[i].gts_required_experience_in_months,
                gts_skill_is_primary:true,
                requiredSkill : res.data[i].gts_required_skill_name ,
                requiredSkillId : res.data[i].gts_required_skill_id ,
                requiredProficiency : res.data[i].gts_skill_required_rating,
                yearsOfExperience : res.data[i].gts_required_experience_in_months
             })
             }
         }
         console.log(res.data)
       })
       var skill_url = endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_SKILLS;
    axios.get(skill_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
    .then((response) => {
      this.state.skills = response.data;
       if (this.state.skills.length > 0){
        this.state.skills.forEach((skills) => {
         this.state.requiredSkills.push(skills.gts_skill_name);
        });
       }
    })
 }

  addSkill = e =>{
      console.log(this.state.requiredSkill)
     var message = this.state.message;
     var error = this.state.error;
         var post_payload={
            gts_employer_id:userLoginDetails.user_id,
             gts_required_skill_id :this.state.requiredSkillId,
             gts_skill_required_rating:this.state.requiredProficiency,
             gts_required_experience_in_months:this.state.yearsOfExperience,
             gts_skill_is_primary:true
           }

           console.log(post_payload)

       if( this.state.requiredSkill !=='' && this.state.requiredSkill !== null){
         axios.post(endpoints_properties.ENDPOINT_PROD+api_properties.API_POST_SC_REQUIRED_SKILLS,post_payload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
         .then(response=>{
            console.log(this.state.userPrimaryRequiredSkills)
            var get_url = endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_SC_REQUIRED_SKILLS+userLoginDetails.user_id;
            axios.get(get_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
            .then(res => {
               var userPrimaryRequiredSkills = [];
                for(var i=0;i<res.data.length;i++){
                    if(res.data[i].gts_skill_is_primary == true){
                    userPrimaryRequiredSkills=userPrimaryRequiredSkills.concat(res.data[i])
                    this.setState({userPrimaryRequiredSkills : userPrimaryRequiredSkills});
                    }
                }
              })
              console.log(this.state.userPrimaryRequiredSkills)
             console.log(response.data)
             message = response.data.message;
             this.setState({message : message})
            })
            .catch(error=>{
                if(error.response.data.status_code == 400 || error.response.data.status_code == 404){
                    error= error.response.data.message
                    this.setState({error : error})
                }
                else{
             error = "Not able to add primary skills";
             this.setState({error : error})
                }
            })
        }
        else{
            this.validateReqPrimarySkill();
        }
    }

  updateSkill(requiredPrimarySkillId,requiredSkillId){
        var message = this.state.message;
        var error = this.state.error;
            var put_payload={
             gts_employer_required_skill_id :requiredPrimarySkillId,
             gts_required_skill_id :requiredSkillId,
             gts_skill_required_rating:this.state.requiredProficiency,
             gts_required_experience_in_months:this.state.yearsOfExperience,
             gts_skill_is_primary:true
        }

        console.log(put_payload)

        axios.put(endpoints_properties.ENDPOINT_PROD+api_properties.API_POST_SC_REQUIRED_SKILLS,put_payload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
        .then(response=>{
            var get_url = endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_SC_REQUIRED_SKILLS+userLoginDetails.user_id;
            axios.get(get_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
            .then(res => {
               var userPrimaryRequiredSkills = [];
                for(var i=0;i<res.data.length;i++){
                    if(res.data[i].gts_skill_is_primary == true){
                    userPrimaryRequiredSkills=userPrimaryRequiredSkills.concat(res.data[i])
                    this.setState({userPrimaryRequiredSkills : userPrimaryRequiredSkills});
                    }
                }
              })
             message = response.data.message;
             console.log(response.data.message)
             this.setState({message : message})
            })
            .catch(error=>{
             error ="Not able to update primary skills";
             this.setState({error : error})
            })
    }

  deleteSkill(requiredPrimarySkillId){
      let message = this.state.message;
      let error = this.state.error;
      if(window.confirm('Are you sure?')){
         axios.delete(endpoints_properties.ENDPOINT_PROD+api_properties.API_DELETE_SC_REQUIRED_SKILLS+requiredPrimarySkillId,{ headers: {"Auth_Token" : `Bearer ${token}`} })
          .then(response=>{
			  var userPrimaryRequiredSkills = [];
            var get_url = endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_SC_REQUIRED_SKILLS+userLoginDetails.user_id;
            axios.get(get_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
            .then(res => {

                for(var i=0;i<res.data.length;i++){
                    if(res.data[i].gts_skill_is_primary == true){
                    userPrimaryRequiredSkills=userPrimaryRequiredSkills.concat(res.data[i])
                    this.setState({userPrimaryRequiredSkills : userPrimaryRequiredSkills});
                    }
                }
              })
              console .log(response.data)
             message = response.data.message;
             this.setState({message : message})
             userPrimaryRequiredSkills = [];
              this.setState({userPrimaryRequiredSkills : userPrimaryRequiredSkills});
            })
            .catch(error=>{
                console.log(error.response.data)
                error = "Not able to delete primary skills";
                this.setState({error : error})
           })
        }
    }

  cancelEditHandler=e=>{
     this.setState({editSaveFlag:false});
    }

  saveFlagHandler=e=>{
     this.setState({saveFlag:true});
     console.log('saveFlag'+this.state.saveFlag);
   }

  editsaveFlagHandler=e=>{
     this.setState({editSaveFlag:true})
    }

  autoCompleteChangeHandler=(event,value)=>{
     this.setState({editSaveFlag:true});
     this.setState({requiredSkillValidationFlag:false});
     if(value==null){
         this.setState({requiredSkillValidationFlag:true});
        }
        this.state.selectedSkill = value;
        this.state.requiredSkill = value;
        if(value == ''  || value== null){
        this.setState ({requiredSkillId : ''})
        }
        else{
        this.state.skills.forEach((skill) => {
            if (this.state.selectedSkill === skill.gts_skill_name ) {
            this.state.requiredSkillId = skill.gts_skill_id
            }
        })
        }
    }

  changeHandler= e =>{
      this.setState({editSaveFlag:true})
      this.setState({saveFlag:false});
      this.setState({[e.target.name]: e.target.value})
      this.setState({requiredSkillValidationFlag:false});
      this.setState({requiredProficiencyValidationFlag:false});
      this.setState({yearsOfExperienceValidationFlag:false});

      console.log('targetValue is'+e.target.value);

      if(e.target.value==''||e.target.value==undefined){
         if(e.target.name=='requiredSkill'){
             this.setState({requiredSkillValidationFlag:true});
            }

         if(e.target.name=='requiredProficiency'){
             this.setState({requiredProficiencyValidationFlag:true});
             this.setState({requiredProficiency : e.target.value})
            }

         if(e.target.name=='yearsOfExperience'){
             this.setState({yearsOfExperienceValidationFlag:true});
             this.setState({gtsSkillExperienceMonths : e.target.value})
            }
        }
   }

  addButtonListener=e=>{
     this.setState({requiredProficiency:''});
     this.setState({requiredSkill:''});
     this.setState({yearsOfExperience:''});
     this.setState({editSaveFlag:false});
     this.setState({primaryCounter:this.state.primaryCounter+1});
    }

  cancelHandler=e=>{
      this.setState({cancelFlag:true});
   }

  editButtonListener=e=>{
     this.setState({editSaveFlag:false});
   }

  clearMessageOrError= () => {
     let message= this.state.message;
     message =" ";

     let error= this.state.error;
     error =" ";

     if(this.state.message){
         this.setState({message : message});
       }

     if(this.state.error){
         this.setState({error : error});
       }
    }

    validateReqPrimarySkill = e =>{
        var skillError = "Please select skill."
        if(this.state.requiredSkill=='' || this.state.requiredSkill== null){
            this.setState({skillError : skillError})
        }
        else{
            this.setState({skillError : ""})
        }
    }


  render(){

    const{requiredProficiency,requiredSkill,yearsOfExperience}=this.state;

    return(
   <div>
    <div className="container align-items-center">
      <div className="container align-items-center">
        <div className='mt-3'>
           <div className="border border-dark rounded-lg" >
             <div className="row p-2">
                 <div className = "col-2" align="left">
                     <h5><b>&nbsp;Primary Skills </b></h5>
                 </div>
                  <div className = "col p-1 pr-7">
                     <h6 fontSize="10px" style={{color:'gray'}}>I look for the following <strong>REQUIRED/MANDATORY</strong> skills from the candidates</h6>
                  </div>
                  <div className = "col-3" align="right">
                     < button type="button" style={{width:"120px", height:"30px",display: ls.get('gts_user_id')!= userLoginDetails.user_id ? "none" : "inline"}} class="btn btn-primary float-right" data-toggle="modal" data-target="#addPrimaryRequiredSkills"  onClick={this.addButtonListener} onBlur={this.clearMessageOrError} size="small"><center><h6 style={{ color: 'white' }}>Add Skill</h6></center></button>
                  </div>
             </div>
             <div style={{borderBottomColor: 'black',borderBottomWidth: 1.5}} />
             <span style={{color: "red" , width : "400px"}} ><center>{this.state.error}</center></span>
             <span style={{color: "#228B22" , width : "400px"}} ><center>{this.state.message}</center></span>
                 <br/>
                 <div class="container">
                     <table  id ="containerOfTablePrimaryRequiredSkills" className="table table-responsive" style={{borderWidth:"1px", 'borderColor':"border-dark", 'borderStyle':'border-dark', width:"1090px"}}>
                         <thead>
                             <tr>
                             <td><h6 style={{color : black, fontSize:"18px"}}><center>Sr.No.</center></h6></td>
                                 <td><br/></td><td><br/></td><td><br/></td><td><br/></td><td><br/></td>
                                 <td ><h6 style={{color : black, fontSize:"18px"}}><center>Skills</center></h6></td>
                                 <td><br/></td><td><br/></td><td><br/></td><td><br/></td><td><br/></td>
                                 <td><h6 style={{color : black, fontSize:"18px"}}><center>Claim Of Proficency(Out of 10)</center></h6></td>
                                 <td><br/></td><td><br/></td><td><br/></td><td><br/></td><td><br/></td>
                                 <td><h6 style={{color : black, fontSize:"18px"}}><center>Experience(Months)</center></h6></td>
                                 <td><br/></td><td><br/></td><td><br/></td><td><br/></td><td><br/></td>
                                 <td><h6 style={{color : black, fontSize:"18px" ,display: ls.get('gts_user_id')!= userLoginDetails.user_id ? "none" : "inline"}}><center>Edit/Delete Skill</center></h6></td>
                                 <td><br/></td>
                             </tr>
                         </thead>
                         <tbody >
                               {
                                  this.state.userPrimaryRequiredSkills.slice(0,this.state.userPrimaryRequiredSkills.length).map((requiredSkill,index)=> {
                                  return(<>
                                        { this.state.userPrimaryRequiredSkills.length > 0 && requiredSkill.gts_skill_is_primary &&
                                          <tr class={"strippedColors"+(index)%2}key={index}>
                                              <td><h6 style={{color : black}}><center>{index+1}</center></h6></td>
                                              <td><br/></td><td><br/></td><td><br/></td><td><br/></td><td><br/></td>
                                              <td><h6 style={{color : black}}><center>{requiredSkill.gts_required_skill_name}</center></h6></td>
                                              <td><br/></td><td><br/></td><td><br/></td><td><br/></td><td><br/></td>
                                              <td><h6 style={{color : black}}><center>{requiredSkill.gts_skill_required_rating}</center></h6></td>
                                              <td><br/></td><td><br/></td><td><br/></td><td><br/></td><td><br/></td>
                                              <td><h6 style={{color : black}}><center>{requiredSkill.gts_required_experience_in_months}</center></h6></td>
                                              <td><br/></td><td><br/></td><td><br/></td><td><br/></td><td><br/></td>
                                              <td><button href="#" class="btn float-right" style={{display: ls.get('gts_user_id')!= userLoginDetails.user_id ? "none" : "inline"}} onBlur={this.clearMessageOrError} onClick={()=>this.deleteSkill(requiredSkill.gts_employer_required_skill_id)}><center><FontAwesomeIcon icon={faTrash}  /></center></button>
                                              <button class="btn float-right " style={{display: ls.get('gts_user_id')!= userLoginDetails.user_id ? "none" : "inline"}} data-toggle="modal" data-target={"#editPrimaryRequiredSkills"+index} onClick={this.editButtonListener}><center><FontAwesomeIcon icon={faEdit} /></center></button></td>
                                              <td></td>
                                          </tr>
                                        }
                                        <div id= {"editPrimaryRequiredSkills"+index} class="modal fade" role="dialog">
                                             <div class="modal-dialog">
                                                 <div class="modal-content">
                                                     <div class="modal-header">
                                                          <h5 class="modal-title"> Edit Primary Skills</h5>
                                                     </div>
                                                     <div class="modal-body">
                                                         <div className="container" >
                                                              <div class="row-sm m-0  text-left  ">
                                                                   <label id="requiredSkill" name="requiredSkill" class="text-dark">Skill:&nbsp;&nbsp;
                                                                      <Autocomplete
                                                                         class="complete"
                                                                         options={this.state.requiredSkills}
                                                                         style={{ width: 200,outlineColor:"black",display:"inline-block" }}
                                                                         defaultValue={requiredSkill.gts_required_skill_name }
                                                                         disabled
                                                                         onChange={this.autoCompleteChangeHandler}
                                                                         renderInput={(params) => <TextField {...params}    />}
                                                                       s/>
                                                                 </label>
                                                              </div>
                                                              <div class="row-sm">
                                                                  <label class="text-dark">Proficency(out of 10): &nbsp;&nbsp;
                                                                  <input type="text" name="requiredProficiency" defaultValue={requiredSkill.gts_skill_required_rating} placeholder ={requiredSkill.gts_skill_required_rating}  onChange={this.changeHandler}  ></input>
                                                                  </label>
                                                              </div>
                                                              <div class="row-sm">
                                                                  <label class="text-dark">Experience In Months: &nbsp;&nbsp;
                                                                      <input type="text" name="yearsOfExperience" defaultValue={requiredSkill.gts_required_experience_in_months} placeholder={requiredSkill.gts_required_experience_in_months}  input={requiredSkill.gtsSkillExperienceMonths} onChange={this.changeHandler}></input>
                                                                 </label>
                                                              </div>
                                                         </div>
                                                      </div>
                                                      <div class="modal-footer">
                                                          <div class="text-inline">
                                                              <Button id ="test" type="button" data-dismiss="modal" onFocus={this.clearMessageOrError} onClick={()=>this.updateSkill(requiredSkill.gts_employer_required_skill_id, requiredSkill.gts_required_skill_id)}>Save</Button>
                                                              <Button type="button" class="close" data-dismiss="modal" onBlur={this.clearMessageOrError} onClick={this.cancelEditHandler}>Cancel</Button>
                                                          </div>
                                                     </div>
                                                 </div>
                                             </div>
                                          </div>
                                     </>)
                                   })}
                             </tbody>
                         </table>
                     </div>
                     <div id="addPrimaryRequiredSkills" class="modal fade" role="dialog">
                         <div class="modal-dialog">
                             <div class="modal-content">
                                 <div class="modal-header">
                                     <h5 class="modal-title"> Add Primary Skills</h5>
                                 </div>
                                 <div class="modal-body">
                                     <div className="container" >
                                         <div class="row-sm m-0  text-left  ">
                                             <label class="text-dark">Skill:&nbsp;&nbsp;
                                                 <Autocomplete class="complete"
                                                     options={this.state.requiredSkills}
                                                     style={{ width: 150,outlineColor:"black",display:"inline-block" }}
                                                     renderInput={(params) => <TextField {...params}  />}
                                                     onChange={this.autoCompleteChangeHandler}
                                                     onBlur = {this.validateReqPrimarySkill}
                                                  s />
                                                  <div>
                                                     <span class="validationStyle" style={{color: 'red'}}>{this.state.skillError}</span>
                                                  </div>
                                             </label>
                                         </div>
                                         <div class="row-sm">
                                             <label class="text-dark">Proficency(out of 10): &nbsp;&nbsp;
                                                 <input type="text" name="requiredProficiency" value={requiredProficiency} onChange={this.changeHandler}  ></input>
                                             </label>
                                          </div>
                                          <div class="row-sm">
                                              <label class="text-dark">Experience In Months: &nbsp;&nbsp;
                                                 <input type="text" name="yearsOfExperience" value={yearsOfExperience} onChange={this.changeHandler} ></input>
                                             </label>
                                         </div>
                                       </div>
                                  </div>
                                  <div class="modal-footer">
                                     <div class="text-inline">
                                          <Button id ="test" type="button" data-dismiss="modal" onClick={this.addSkill}>Save</Button>
                                         <Button type="button" class="close" data-dismiss="modal" onClick={this.cancelHandler}>Cancel</Button>
                                     </div>
                                  </div>
                             </div>
                         </div>
                     </div>
                  </div>
             </div>
         </div>
         </div>
         <div>
         <SecondaryRequiredSkillsComponent/>
         </div>
         </div>
         )
    }

}
export default PrimaryRequiredSkillsComponent;