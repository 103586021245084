import React from 'react';
import { FormGroup, Row, NavLink, NavItem, Col, ButtonGroup } from 'reactstrap';
import { Form, FormControl, FormLabel, FormRow, Button, FormCheck } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faTrash, faEdit, faUpload, faCalendar, faAngleRight} from '@fortawesome/free-solid-svg-icons'

class AboutUsComponent extends React.Component{

    render(){

	const iconStyles = {
      // marginLeft:"100%",
      float: "left",
      clear: "left",
      height: "60px",
      width: "90px"
    }
        return(
            <>
                <div className="container align-items-center">
                    <div className="border m-4 p-4 border-dark" style={{ borderRadius: "20px" }}>
                        <h3 class="pb-4 text-center font-bold">ABOUT US</h3>
                        <div style={{textAlign:"justify", lineHeight:"1.6"}}>
                            <p>
                                <span className="font-bold">Gorai Technology Solutions</span> is a Software Product Development Company.
                                We are building a Global Service platform where every person of the world can register to post
                                the services they provide and also they can post the services they consume.
                                The Service Producers can post service requirements like employees, contractors,
                                electricians, plumbers, builders, cook, shopkeeper etc.. Our platform ensures the
                                Reliability and Quality of the services provided by the Service Providers.
                            </p>
                            <p>
                                Trainers of all types of skills also can register in this platform to provide training to others.
                            </p>
                            <p>
                                We are having offices in Bengaluru & Paris.
                            </p>
                            <p>
                                To avail best service in low cost, please contact <span className="font-bold">hr.gts@ggtech.co.in</span>
                            </p>
                        </div>
                    </div>
           <div className="container">
              <div className='mt-5'>
                <div className="border m-4 p-4 border-dark" style={{ borderRadius: "20px" }}>
                  <h5 class="mb-3 text-center font-bold">WHO ARE WE?</h5>
                  <div class="column" divID="icon" styles={iconStyles} align="right">
                    <img src={process.env.PUBLIC_URL + "/images/who.jpg"}
                      alt="pic" width="100px" class="iconStyles" align="right" />
                  </div>
                  <dl class="row ">
                    <dd class="text-left">
                      "Gorai Technology Solutions" is primarily a Software Product Development Company. We have built a Global Service Platform over the past three years. Every Human Being of the world can register to post the services that they provide and also they can post the services they consume. The Service Providers can post service requirements like Scientists, Doctors, Engineers, Contractors, Electricians, Plumbers, Builders, Cook, Shopkeeper etc. Our platform ensures the Reliability and Quality of the services provided by the Service Providers. Service Consumers can post any kind of services they seek.
                    </dd>
                  </dl>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="mt-5 mb-4">
                <div className="border m-4 p-4 border-dark" style={{ borderRadius: "20px" }}>
                  <h5 class="mb-3 text-center font-bold">WHAT WE DO?</h5>
                  <div class="column" divID="icon" styles={iconStyles} align="right">
                    <img src={process.env.PUBLIC_URL + "/images/what.jpg"}
                      alt="pic" width="100px" class="iconStyles" align="right" />
                  </div>
                  <dl class="row ">
                    <dd class="text-left">
                      We are extending our technical expertise to other companies. We provide Software Development & Consulting Services to other organizations. We are expert at developing Enterprise Architecture, Product RoadMap & Estimation, Web Applications Development, Mobile Applications(Android, IOS, React Native, Flutter), Microservice API Development, Adobe AEM/CMS/AEP Integration, Cloud Computing(AWS, AZURE, GCP), Cloud Migration, Data Analytics, AI & ML Platform Support, Application Software Maintenance/Support, Build IOT Platform. We can become a Strategic Partner in your Digital Business  Transformation Journey and make you success!!!

					  We provide all types of IT Hardware & Software  resources. Do you need Laptop/Desktop or Networking Solutions or Maintenance of your Physical Servers? We are there to help you. We can help to setup your Data Center securely as well.

					  We provide Skilled IT Resources to other companies that includes, UI/UX Developers, Solution/Technical/Cloud Architects, Microservice/Backend Developers, DevOps Engineers, Scrum Masters, Business Analysts.

					  We provide Software Development Training to next generation Software Developers and helping them to build their career. People who are Graduate/Post-Graduate in Computer Science/Engineering or in the final year or want to shift their career to IT profiles of different skills, we help them to transform their mindset and skills and make them capable to perform exceptionally in their new role and excel professionally.
                    </dd>
                  </dl>
                </div>
              </div>
            </div>


            <div className="container">
              <div className='mt-5'>
                <div className="border m-4 p-4 border-dark" style={{ borderRadius: "20px" }}>
                  <h5 class="mb-3 text-center font-bold">HOW DO WE DO IT?</h5>
                  <div class="column" divID="icon" styles={iconStyles} align="right">
                    <img src={process.env.PUBLIC_URL + "/images/how.jpg"}
                      alt="pic" width="90px" class="iconStyles" align="right" />
                  </div>
                  <dl class="row ">
                    <dd class="text-left">
                      Talent Search/Recognition:
					  Our capability of Talent Search, integrating them within the organization as our close family members, guiding and mentoring them such that they can thieve to achieve their Goals and succeed in their aspirations. Our progress had been remarkable over the past three years. Our engineers are fluent in Generative AI to develop software components within very short period of time.


					  Our Process & Culture:
					  We believe well defined Process & Culture of an Organization is the key of their success. Vision, Mission, Strategy and Milestones all these will have a smooth balance when the Process & Culture bonds the people of an Organization very strongly.


					  Use next Generation Technologies:
					  We are on top of the Latest Technologies, let that be Generative Artificial Intelligence(AI), Machine Learning(ML), Cloud Technologies like AWS, AZURE, GCP, Programming Languages, or Creative Experience.


					  Problem Solving & Customer Satisfaction:
					  We believe customer satisfaction comes when we solve their problems and our service exceeds the their Expectation. Our experts constantly looks for hurdles and grey areas of the customers and provide solution to those so that customer can get best Quality Services in lowest cost
					</dd>
                  </dl>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="mt-5">
                <div className="border m-4 pt-4 border-dark" style={{ borderRadius: "20px" }}>
                  <h5 class="mb-3 text-center font-bold">LOOK AT OUR STATISTICS!</h5>
                  <dl class="row text-left" className="pl-4">
                    <dd class="col-sm-7">No. of Service Providers on our platform: <span style={{ color: "blue", float: "right" }}>33,000</span></dd><br />
                    <dd class="col-sm-7">No. of Service Consumers on our platform: <span style={{ color: "blue", float: "right" }}>12,899</span></dd><br />
                    <dd class="col-sm-7">No. of Trainers on our platform: <span style={{ color: "blue", float: "right" }}>500</span></dd><br />
                    <dd class="col-sm-7">No. of Trainees on our platform: <span style={{ color: "blue", float: "right" }}>11,899</span></dd><br />
                    <dd class="col-sm-7">Services consumed by Service providers: <span style={{ color: "blue", float: "right" }}>24,750</span></dd><br />
                  </dl>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="mt-5">
                <div className="border m-4 pt-4 border-dark" style={{ borderRadius: "20px" }}>
                  <h5 class="mb-3 text-center font-bold">Roles/Actions</h5>
                  <div class="row" className="pl-4">
                    <h6 class="font-bold">Service Consumer</h6>
                    <ul style={{ listStyleType: "circle" }}>
                      <li>
                        Service Consumer is any person who needs any kind of services from others,
                        for ex. Highly skilled professionals like: Software Engineer, Architect, Scientists etc.
                        and even other professionals like: Plumber, Electrician, Store boy, Daily Labor etc.
                        </li>
                      <li>
                        Consume services from people at best price.
                        </li>
                      <li>
                        Find out best skilled service providers for any day-to-day needs.
                        </li>
                      <li>
                        Consult Service Mediator/Expert for any help.
                        </li>
                    </ul>
                  </div>

                  <div class="row" className="pl-4">
                    <h6 class="font-bold">Service Provider</h6>
                    <ul style={{ listStyleType: "circle" }}>
                      <li>Service Provider is any person who provides any kind of services as a
                      Highly skilled professionals like: Software Engineer, Architect, Scientists etc.
                      and even other professionals like: Plumber, Electrician, Store boy, Daily Labor etc.
                      </li>
                      <li>
                        Provide services at best price.
                      </li>
                      <li>
                        Showcase your skills to genuine service consumers.
                      </li>
                      <li>
                        Consult with Service Mediator/Expert for any help.
                      </li>
                      <li>
                        Wish to provide any service or participate in any training programme.
                      </li>
                      <li>
                        Receive Service & Traning Recommendations.
                      </li>
                    </ul>
                  </div>

                  <div class="row" className="pl-4">
                    <h6 class="font-bold">Trainer</h6>
                    <ul style={{ listStyleType: "circle" }}>
                      <li>
                        Trainers have the opportunity to empower service providers through world class training.
                      </li>
                      <li>
                        Consult with Service Mediator/Expert for any help.
                      </li>
                      <li>
                        Search Trainees and receive the Trainee recommendations.
                      </li>
                      <li>
                        Wish to conduct Trainings(online/offline).
                      </li>
                    </ul>
                  </div>

                  <div class="row" className="pl-4">
                    <h6 class="font-bold">Trainee</h6>
                    <ul style={{ listStyleType: "circle" }}>
                      <li>
                        Trainee can search and apply for Training in order to learn new skills or to improve his current skills.
                      </li>
                      <li>
                        Consult with Service Mediator/Expert for any help.
                      </li>
                      <li>
                        Wish to participate in Trainings.
                      </li>
                      <li>
                        Wish to provide services.
                      </li>
                      <li>
                        Receive Training recommendations.
                      </li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>

            <div className="container">
              <div className='mt-5'>
                <div className="border m-4 pt-4 border-dark" style={{ borderRadius: "20px" }}>
                  <h5 class="mb-3 text-center font-bold">HEAR WHAT PEOPLE SAID ABOUT OUR PLATFORM</h5>

                  <div class="column" divID="icon" styles={iconStyles} align="left">
                    <img src={process.env.PUBLIC_URL + "/images/rev1.jpg"}
                      alt="pic" width="80px" class="iconStyles" align="left" />
                  </div>
                  <dl class="row" style={{ margin: "0" }}>
                    <dd class="text-left">
                      <i>
                        "I recently completed my Bachelors and had no idea how to approach and apply for jobs.
                        was the best decision,not only did they assist me in getting placed but also how to connect well with the employers."
                      </i>
                    </dd>
                  </dl>
                  <div align="center">
                    <span class="fa fa-star" style={{ color: "orange" }}></span>
                    <span class="fa fa-star" style={{ color: "orange" }}></span>
                    <span class="fa fa-star" style={{ color: "orange" }}></span>
                    <span class="fa fa-star" style={{ color: "orange" }}></span>
                    <span class="fa fa-star"></span>
                  </div>

                  <br />

                  <div class="column" divID="icon" styles={iconStyles} align="right">
                    <img src={process.env.PUBLIC_URL + "/images/rev2.jpg"}
                      alt="pic" width="80px" class="iconStyles" align="right" />
                  </div>
                  <dl class="row" style={{ margin: "0" }}>
                    <dd class="text-left">
                      <i>
                        "I was looking to hire fresh minds for our company and this platform greatly assisted in selecting the brightest minds for our company. The freshers now have branched out and running our companies in 3 continents!"
                      </i>
                    </dd>
                  </dl>
                  <div align="center">
                    <span class="fa fa-star" style={{ color: "orange" }}></span>
                    <span class="fa fa-star" style={{ color: "orange" }}></span>
                    <span class="fa fa-star" style={{ color: "orange" }}></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                  </div>

                  <br />

                  <div class="column" divID="icon" styles={iconStyles} align="left">
                    <img src={process.env.PUBLIC_URL + "/images/rev3.jpg"}
                      alt="pic" width="80px" height="50px" class="iconStyles" align="left" />
                  </div>
                  <dl class="row" style={{ margin: "0" }}>
                    <dd class="text-left">
                      <i>
                        "I loved cooking, and I always wanted to be a chef. But I found it difficult to find work as this was my only primary skill. Thanks to Chakuri, I am now working at my restaurant!"
                      </i>
                    </dd>
                  </dl>
                  <div align="center">
                    <span class="fa fa-star" style={{ color: "orange" }}></span>
                    <span class="fa fa-star" style={{ color: "orange" }}></span>
                    <span class="fa fa-star" style={{ color: "orange" }}></span>
                    <span class="fa fa-star" style={{ color: "orange" }}></span>
                    <span class="fa fa-star" style={{ color: "orange" }}></span>
                  </div>

                  <br />

                  <div class="column" divID="icon" styles={iconStyles} align="right">
                    <img src={process.env.PUBLIC_URL + "/images/rev4.jpg"}
                      alt="pic" width="80px" height="50px" class="iconStyles" align="right" />
                  </div>
                  <dl class="row" style={{ margin: "0" }}>
                    <dd class="text-left">
                      <i>
                        "I only know how to drive cars and trucks.
                        Now I drive for large company and making very good salary. Chakuri help me find good company. This is very good website"
                      </i>
                    </dd>
                  </dl>
                  <div align="center">
                    <span class="fa fa-star" style={{ color: "orange" }}></span>
                    <span class="fa fa-star" style={{ color: "orange" }}></span>
                    <span class="fa fa-star" style={{ color: "orange" }}></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                  </div>

                  <br />

                  <div class="column" divID="icon" styles={iconStyles} align="left">
                    <img src={process.env.PUBLIC_URL + "/images/rev5.jpg"}
                      alt="pic" width="80px" height="45px" class="iconStyles" align="left" />
                  </div>
                  <dl class="row" style={{ margin: "0" }}>
                    <dd class="text-left">
                      <i>
                        "The very best. Chakuri is worth much more than I paid for an other platform. Very easy to use."
                      </i>
                    </dd>
                  </dl>
                  <div align="center">
                    <span class="fa fa-star" style={{ color: "orange" }}></span>
                    <span class="fa fa-star" style={{ color: "orange" }}></span>
                    <span class="fa fa-star" style={{ color: "orange" }}></span>
                    <span class="fa fa-star" style={{ color: "orange" }}></span>
                    <span class="fa fa-star" style={{ color: "orange" }}></span>
                  </div>

                  <br />
                  <br />
                </div>

                <div className="text-center">
                  <h5>
                    Let us help you to Provide and Consume services with the people all over the World!
                    Register now to give us the opportunity to help you to give and take services at best price!
                    </h5>
                </div>
              </div>
              <div class="wrapper">
                <div className="text-center">
                  <ButtonGroup>
                    <NavLink className="nav-link-icon" to="/gts/register" tag={Link}>
                      <button class="btn btn-light">Register</button>
                    </NavLink>
                    <NavLink className="nav-link-icon" to="/gts/login" tag={Link}>
                      <button class="btn btn-light">Login</button>
                    </NavLink>
                  </ButtonGroup>
                </div>
              </div>
              <div className="text-center">
                <dd>-------------------------------------------------------OR-------------------------------------------------------</dd>
              </div>
              <div className="text-center font-bold">
                <h3>Check out our search engines</h3>
              </div>

              <div className="text-center">
                <ButtonGroup vertical>
                  <Button variant="outline-dark" style={{ borderRadius: "20px" }}>Search Service Consumers<FontAwesomeIcon icon={faAngleRight} style={{float:"right"}}/></Button>
                  <Button variant="outline-dark" style={{ borderRadius: "20px" }}>Search Service Providers<FontAwesomeIcon icon={faAngleRight} style={{float:"right"}}/></Button>
                  <Button variant="outline-dark" style={{ borderRadius: "20px" }}>Search Service Mediators/Experts<FontAwesomeIcon icon={faAngleRight} style={{float:"right"}}/></Button>
                  <Button variant="outline-dark" style={{ borderRadius: "20px" }}>Search Trainings<FontAwesomeIcon icon={faAngleRight} style={{float:"right"}}/></Button>
                  <Button variant="outline-dark" style={{ borderRadius: "20px" }}>Search Services<FontAwesomeIcon icon={faAngleRight} style={{float:"right"}}/></Button>
                </ButtonGroup>
              </div>
            </div>
                    <div className="text-left ml-8">
                        <h3 className="font-bold">OUR OFFICES</h3>
                        <p>
                            <span className="font-bold">Paris, France</span><br />
                            6 Rue d'Australie, Massy 91300, France
                        </p>
                        <p>
                            <span className="font-bold">Bengaluru, India</span><br />
                            #21, 6th cross, 4th main, Roopena Agrahara, Bommanahalli, Bengaluru-560068
                        </p>
                    </div>
                    <br />
                    <br />
                </div>
            </>
        )
    }
}

export default AboutUsComponent;