
import React, { Component } from 'react'
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import 'bootstrap/dist/css/bootstrap.css'
import axios from 'axios';
import EducationComponent from '../../components/common/EducationComponent';
import UserPersonalDetailsComponent from '../../components/common/UserPersonalDetailsComponent';
import CompanyDetailComponent from '../../components/service_consumer/CompanyDetailComponent';
import UserAddressesComponent from '../../components/common/UserAddressesComponent';
import ServiceConsumerMenu from '../../components/service_consumer/ServiceConsumerMenu';
import FieldOfRecruitmentComponent from '../../components/common/FieldOfRecruitmentComponent';
import LanguagesKnown from '../../components/common/LanguagesKnown';
import SCRequiredLanguagesKnown from '../../components/service_consumer/SCRequiredLanguagesKnown';
import RecruiterMenu from '../../components/recruiter/RecruiterMenu';
import AdminMenu from '../../components/admin/AdminMenu';
import ServiceProviderMenu from '../../components/service_provider/ServiceProviderMenu';
import ls from 'local-storage';

var token = ls.get("token");

class ServiceConsumerProfilePage extends Component {
   constructor(props) {
		if(token==null || token=="" || token=="undefined"){
		  window.location.href = '/gts/global-home-page';
		}
		super(props);
	}

    render()
     {
        return (
           <div>
				<Header/>
                <ServiceConsumerMenu/>
                <FieldOfRecruitmentComponent/>A
            	<UserPersonalDetailsComponent />
            	<br></br>
		  		<Footer />
           </div>
        )
    }
}
export default ServiceConsumerProfilePage;
