import React, { Component } from 'react'
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import 'bootstrap/dist/css/bootstrap.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser,faTrash,faEdit,faUpload} from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import EducationComponent from '../../components/common/EducationComponent';
import UserPersonalDetailsComponent from '../../components/common/UserPersonalDetailsComponent';
import UserAddressesComponent from '../../components/common/UserAddressesComponent';
import FieldOfRecruitmentComponent from '../../components/common/FieldOfRecruitmentComponent';
import LanguagesKnown from '../../components/common/LanguagesKnown';
import PrimarySkillsComponent from '../../components/common/PrimarySkillsComponent';
import SecondarySkillsComponent from '../../components/common/SecondarySkillsComponent';
import ServiceProviderMenu from '../../components/service_provider/ServiceProviderMenu';
import UserSalaryComponent from '../../components/common/UserSalaryComponent';
import ResumeUploadComponent from '../../components/common/ResumeUploadComponent';
import NoticePeriodComponent from '../../components/common/NoticePeriodComponent';
import ls from 'local-storage';
import { FormControl, FormLabel, Card, CardBody,CardText } from 'react-bootstrap';
import ServiceConsumerMenu from '../../components/service_consumer/ServiceConsumerMenu';
import RecruiterMenu from '../../components/recruiter/RecruiterMenu';
import AdminMenu from '../../components/admin/AdminMenu';

var token = ls.get("token");
var userLoginDetails=ls.get('userLoginDetails');

class ServiceProviderProfilePage extends Component {
   constructor(props) {
	    super(props);
		if(token==null || token=="" || token=="undefined"){
		  window.location.href = '/gts/global-home-page';
		}

	}
    render()
     {
        return (

          <div>
            <Header/>
            {userLoginDetails.primary_role == "RECRUITER"
                        ? <RecruiterMenu/> : ''}
                        {userLoginDetails.primary_role == "ADMIN"
                        ? <AdminMenu/> : ''}
                        {userLoginDetails.primary_role == "SERVICE_CONSUMER"
                        ? <ServiceConsumerMenu/> : ''}
                        {userLoginDetails.primary_role == "SERVICE_PROVIDER"
                        ? <ServiceProviderMenu/> : ''}
            <ResumeUploadComponent/>
            <UserPersonalDetailsComponent />
			<br/><br/>

           <Footer />
         </div>  //main end
       )
    }
}


export default ServiceProviderProfilePage;



