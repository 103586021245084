import React from 'react';
import { Link } from 'react-router-dom';
//import "node_modules/bootstrap/dist/css/bootstrap.min.css";
// reactstrap components
import {
  UncontrolledCollapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Form,
  Button
} from 'reactstrap';
import ls from 'local-storage';
import { NavDropdown } from 'react-bootstrap';
// import { Button } from '@material-ui/core';


var homePageLink;
var login;
var logout;
let register;
//let token = ls.get('token');
var userLoginDetails = "";

class Header extends React.Component {
	  constructor(props) {
		    super(props);
		    userLoginDetails = ls.get('userLoginDetails');

		    console.log("userLoginDetails******************:"+userLoginDetails);
			if (userLoginDetails == "undefined" || userLoginDetails == "" || userLoginDetails == null) {
			  login=true;
			  logout = false;
			  register=true;
			  homePageLink="/gts/global-home-page";
			}
			else {
				login = false;
				logout = true;
				register=false;
			}

		}


  render() {

    if (userLoginDetails == "undefined" || userLoginDetails == "" || userLoginDetails == null) {
	    homePageLink="/gts/global-home-page";
	}
	else{
			if(userLoginDetails.primary_role==='SERVICE_PROVIDER'){
				homePageLink="/gts/service-provider-homepage";
			}
			else if(userLoginDetails.primary_role==='ADMIN'){
				homePageLink="/gts/admin-homepage";
			}
			else if(userLoginDetails.primary_role==='SERVICE_CONSUMER'){
				homePageLink="/gts/service-consumer-homepage";
			}
			else if(userLoginDetails.primary_role==='TRAINER'){
				homePageLink="/gts/trainer-homepage";
			}
			else if(userLoginDetails.primary_role==='RECRUITER'){
				homePageLink="/gts/recruiter-homepage";
			}
			else if(userLoginDetails.primary_role==='TRAINEE'){
				homePageLink="/gts/trainee-homepage";
			}

	}






    return (
      <Navbar
        style={navbarStyle}
        className="navbar-horizontal navbar-dark navbar-fixed-top"
        expand="md"
      >
        <div class="w-100" style={menuStyle}>

        <NavbarBrand tag={Link} to={homePageLink} style={spanStyle}>
          HOME
        </NavbarBrand>

        <NavbarBrand tag={Link} to="/gts/about-us" style={spanStyle}>
          ABOUT US
        </NavbarBrand>

        <NavbarBrand tag={Link} to="/gts/contact-us" style={spanStyle}>
          CONTACT US
        </NavbarBrand>

        <NavbarBrand tag={Link} to="/gts/career" style={spanStyle}>
          CAREER
        </NavbarBrand>

        <NavbarBrand tag={Link} to="/gts/products" style={spanStyle}>
          OUR PRODUCTS
        </NavbarBrand>

        <NavbarBrand tag={Link} to="/gts/industry" style={spanStyle}>
          INDUSTRY
        </NavbarBrand>
{/*
        <NavbarBrand tag={Link} to="/gts/global-trainings" style={spanStyle}>
		          TRAININGS
        </NavbarBrand>
*/}

        {/* <NavDropdown title={<span className="text-white">SEARCH</span>} id="nav-dropdown">
          <NavDropdown.Item tag={Link} href="/#">SEARCH SERVICES</NavDropdown.Item>
          <NavDropdown.Item tag={Link} href="/#">SEARCH SERVICE PROVIDERS</NavDropdown.Item>
          <NavDropdown.Item tag={Link} href="/#">SEARCH TRAININGS</NavDropdown.Item>
        </NavDropdown> */}

{
		(login)?
		<NavbarBrand tag={Link} to="/gts/login" style={loginStyle}>
		Login

        </NavbarBrand>
        :  ""
}



{
		(logout)?
	    <NavbarBrand tag={Link} to="/gts/global-home-page" style={loginStyle}>
	          Logout
        </NavbarBrand>
        :  ""
}

{
		(register)?
	    <NavbarBrand tag={Link} to="/gts/register" style={loginStyle}>
	          Register
        </NavbarBrand>
        :  ""
}

      </div>

      </Navbar>
    );
  }
}
const navbarStyle = {
  backgroundColor: '#007bff',
  padding: '0px 20px',
  position: 'overflow',
  with: '100vw',
  top: '0px',
  left: '0px'
};
const menuStyle ={
  display:'flex',
  justifyContent:"space-around",
  alignItems: "center",
  flexWrap:"wrap"
}
const spanStyle = {
  fontSize: '16px',
  //color: '#424242'
  fontWeight: 'bold'
};

const loginStyle = {
  fontSize: '20px',
  color: '#424242',
  fontWeight: 'bold'
};

export default Header;