import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import ls from 'local-storage';
import axios from 'axios';
import {Link } from 'react-router-dom';
import { Popover,  OverlayTrigger, Dropdown, NavDropdown} from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SwitchRole from '../common/SwitchRole';
// reactstrap components
import {
  Button,
  Alert,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container
} from 'reactstrap';
import { FormControl, FormLabel, Card, CardBody,CardText } from 'react-bootstrap';

var userLoginDetails=ls.get('userLoginDetails');
var token = ls.get('token');
var userDetail = '';
var userPersonalDetails=ls.get('userPersonalDetails');


class TraineeMenu extends Component {

  constructor(props) {
		if(token==null || token=="" || token=="undefined"){
		  window.location.href = '/gts/global-home-page';
		}
    super(props)

    this.state = {
      role_name:'',
      errors: {},
      isUserDetailExisting:false,
      first_name:'',
      last_name:'',
      userDetail:{}
    }
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  componentDidMount() {


    if(userPersonalDetails!== null){
    if( userPersonalDetails.gts_user_id == userLoginDetails.user_id ){
      this.setState({first_name : userPersonalDetails.gts_user_first_name})
      this.setState({last_name : userPersonalDetails.gts_user_last_name})
      }
    }
  }

  setUserId = (userID)=>{
    ls.set('gts_user_id', userID);
  }

render()
{
  const {
   user_name,
   user_Detail
  }=this.state;
    var roles=[];
    roles=userLoginDetails.other_roles.split(',');

    return(
      <div>
       <div className="container-fluid">
         <InputGroup>
           <NavDropdown title={<span style={{color:'black',fontSize:"15px"}}><b>Profile</b></span>} >
              <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/trainee-profile"><span style={{fontSize:"15px"}}><b>Update Profile</b></span></NavDropdown.Item>
              <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/your-profile-viewer"><span style={{fontSize:"15px"}}><b>Your Profile Viewer</b></span></NavDropdown.Item>
              <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/manage-roles"><span style={{fontSize:"15px"}}><b>Role</b></span></NavDropdown.Item>
              <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/change-password"><span style={{fontSize:"15px"}}><b>Change Password</b></span></NavDropdown.Item>
           </NavDropdown>

           <NavDropdown title={<span style={{color:'black',fontSize:"15px", paddingRight:"5px"}}><b>Skills</b></span>} >
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/self-assessment"><span style={{fontSize:"15px"}}><b>Self Assessment</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/chakuri-assessment"><span style={{fontSize:"15px"}}><b>GTS Assessment</b></span></NavDropdown.Item>
           </NavDropdown>

          <NavDropdown title={<span style={{color:'black',fontSize:"15px"}}><b>Trainings</b></span>} >
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/search-training"><span style={{fontSize:"15px"}}><b>Search Trainings</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/recommended-training"><span style={{fontSize:"15px"}}><b>Recommended Trainings</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/training-in-progess"><span style={{fontSize:"15px"}}><b>Trainings In Progress</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/saved-training"><span style={{fontSize:"15px"}}><b>Saved Trainings</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/subscribed-training"><span style={{fontSize:"15px"}}><b>Subscribe for Training</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/training-completed"><span style={{fontSize:"15px"}}><b>Trainings Completed</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/training-history"><span style={{fontSize:"15px"}}><b>Training History</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/wish-for-training"><span style={{fontSize:"15px"}}><b>Wish for Training</b></span></NavDropdown.Item>
          </NavDropdown>

           <NavDropdown title={<span style={{color:'black',fontSize:"15px"}}><b>Payment</b></span>} >
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/make-payment"><span style={{fontSize:"15px"}}><b>Pay Here</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/payment-history"><span style={{fontSize:"15px"}}><b>Payment History</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/employement-agreement"><span style={{fontSize:"15px"}}><b>Employment Agreement</b></span></NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title={<span style={{color:'black',fontSize:"15px"}}><b>Wish List</b></span>} >
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/training-wishes"><span style={{fontSize:"15px"}}><b>Training Wishes</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/job-wishes"><span style={{fontSize:"15px"}}><b>Job Wishes</b></span></NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title={<span style={{color:'black',fontSize:"15px"}}><b>Notifications</b></span>} >
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/notifications"><span style={{fontSize:"15px"}}><b>Notifications</b></span></NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title={<span style={{color:'black',fontSize:"15px"}}><b>Messages</b></span>} >
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/messages"><span style={{fontSize:"15px"}}><b>Messages</b></span></NavDropdown.Item>
          </NavDropdown>
          <span style={{fontWeight: 'bold'}}>Current Role:</span>&nbsp;&nbsp; <span style={{fontWeight: 'bold', color: 'red'}}>{userLoginDetails.primary_role}</span>&nbsp;&nbsp;
          <SwitchRole/>
       </InputGroup>
		<div style={{borderBottomColor: 'black',borderBottomWidth: 1.5}} />
     </div>

   </div>
  )
}}

    const roleStyle = {
      // marginLeft:"100%",
      //float: "left",
      //clear: "left",
      //height: "60px",
      width: "5px",
      align:"right"
    }

const pageBackground = {
  backgroundColor: "white"
};
export default TraineeMenu;