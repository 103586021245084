import React from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import JobSearchAdminComponent from '../../components/common/JobSearchAdminComponent';
import ls from 'local-storage';
import RecruiterMenu from '../../components/recruiter/RecruiterMenu';
import ServiceConsumerMenu from '../../components/service_consumer/ServiceConsumerMenu';
import ServiceProviderMenu from '../../components/service_provider/ServiceProviderMenu';
import AdminMenu from '../../components/admin/AdminMenu';

var token = ls.get("token");
var userLoginDetails=ls.get('userLoginDetails');

class JobSearchAdminPage extends React.Component {
   constructor(props) {
	   super(props);
		if(token==null || token=="" || token=="undefined"){
		  window.location.href = '/gts/global-home-page';
		}

	}


              render() {
                return (
                <div>
                        <Header />
   						<AdminMenu/>
                        <JobSearchAdminComponent/>
                        <Footer/>
                </div>
               )
        }
}


export default JobSearchAdminPage;



