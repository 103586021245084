export const api_properties =
  {
    "API_LOGIN" : "/api/v1/user/login/email",
    "API_REGISTER" : "/api/v1/gts/user/email",
    "API_ADD_ROLES" : "/api/v1/gts/user/role",
    "API_GET_USER_ROLES_USER_ID":"/api/v1/gts/user/role/user_id",
    "API_GET_USER_ROLES" : "/api/v1/gts/user/role",
    "API_CHANGE_PASSWORD" : "/api/v1/gts/user/change/password",
    "API_ACTIVATE_USER" :"/api/v1/gts/user/activate",
    "API_INACTIVATE_USER" :"/api/v1/gts/user/inactivate",
    "API_GET_RECOMMENDED_SERVICES" : "/api/v1/gts/gts_user_recommended_job/gts_user_id" ,
    "API_GET_SC_COMPANY_DETAILS" : "/api/v1/gts/employer_company_detail/employer_id" ,
    "API_SC_COMPANY_DETAILS" :"/api/v1/gts/employer_company_detail",
    "API_GET_USER_LANGUAGES": "/api/v1/gts/user/languages/gts_user_id",
    "API_USER_LANGUAGES" : "/api/v1/gts/user/languages" ,
    "API_DELETE_USER_LANGUAGES": "/api/v1/gts/user/languages/gts_user_language_id",
    "API_GET_ACTIVE_SKILLS" : "/api/v1/gts/skills/active",
    "API_UPDATE_PRIMARY_CONTACT":"/api/v1/gts/user/primary_contact/update",
    "API_VALIDATE_PRIMARY_CONATCT":"/api/v1/gts/user/validate_primary_contact",
    "API_GET_USER": "/api/v1/gts/user/user_id",
    "API_POST_SERVICE_APPLICATIONS": "/api/v1/gts/job/applications",
    "API_GET_ACTIVE_COMPANIES": "/api/v1/gts/company/active",
    "API_GET_ACTIVE_SERVICE_TITLES" : "/api/v1/gts/user/job/title/active",
    "API_GET_ACTIVE_COUNTRIES" : "/api/v1/gts/countries/active",
    "API_GET_ACTIVE_DEGREES" : "/api/v1/gts/degree/active",
    "API_GET_ACTIVE_CITIES" : "/api/v1/gts/city/active",
    "API_GET_ACTIVE_INSTITUTES" :"/api/v1/gts/institute/active",
    "API_GET_INSTITUTES_BY_CITY": "/api/v1/gts/institute/city-id",
    "API_GET_LANGUAGES_ACTIVE" : "/api/v1/gts/languages/active",
    "API_GET_ACTIVE_CURRENCIES" : "/api/v1/gts/currencies/active",
    "API_GET_CITIES_BY_COUNTRY" : "/api/v1/gts/city/country_id",
    "API_GET_STATES_BY_COUNTRY" : "/api/v1/gts/states/country_id",
    "API_GET_CITIES_BY_STATE" : "/api/v1/gts/city/state_id",
    "API_GET_SERVICE_APPLICATIONS_BY_APPLIED_ID" : "/api/v1/gts/job/applications/applied_job_id",
    "API_GET_SERVICE_APPLICATIONS_BY_APPLICANT_ID" : "/api/v1/gts/job/applications/applicant_id",
    "API_GET_SC_SERVICES_ACTIVE" : "/api/v1/gts/employer/job_post/active/employer_id",
    "API_SC_SERVICES" :"/api/v1/gts/employer/job_post",
    "API_GET_ACTIVE_INDUSTRIES" : "/api/v1/gts/industry/active",
    "API_SEARCH_SC" : "/api/v1/gts/search/service_consumer",
    "API_SEARCH_SERVICES" : "/api/v1/gts/user/gts_job_search",
    "API_SEARCH_SERVICE_PROVIDER" :"/api/v1/gts/search_service_provider",
    "API_GET_USER_EDUCATIONS" : "/api/v1/gts/user/educations/user_id",
    "API_USER_EDUCATIONS" : "/api/v1/gts/user/educations",
    "API_DELETE_USER_EDUCATIONS": "/api/v1/gts/user/educations/user_education_id",
    "API_GET_SC_REQUIRED_LANGUAGES" : "/api/v1/gts/sc/required/languages/user_id",
    "API_POST_SC_REQUIRED_LANGUAGES" : "/api/v1/gts/sc/required/languages",
    "API_DELETE_SC_REQUIRED_LANGUAGES": "/api/v1/gts/sc/required/languages/sc_required_language_id",
    "API_GET_USER_PROJECTS" : "/api/v1/gts/user/project/user_id",
    "API_USER_PROJECTS" : "/api/v1/gts/user/project",
    "API_DELETE_USER_PROJECTS": "/api/v1/gts/user/project/user_project_id",
    "API_PROFILE_VIEWER":"/api/v1/gts/profile-viewer",
    "API_GET_PROFILE_VIEWER":"/api/v1/gts/profile_viewer/user-profile-viewer_id",
    "API_SEND_RESUME": "/api/v1/gts/user/resume/download/send/current/resume",
    "API_GET_SC_REQUIRED_SKILLS" :"/api/v1/gts/employer/required_skill/employer_id",
    "API_POST_SC_REQUIRED_SKILLS": "/api/v1/gts/employer/required_skill",
    "API_DELETE_SC_REQUIRED_SKILLS": "/api/v1/gts/employer/required_skill/employer_required_skill_id",
    "API_UPDATE_PRIMARY_EMAIL": "/api/v1/gts/user/primary-email/update",
    "API_GENERATE_OTP_PRIMARY_CONTACT":"/api/v1/gts/user/primary-contact/generate-otp",
    "API_USER_PERSONAL_DETAILS": "/api/v1/gts/user/personal-details",
    "API_GENERATE_OTP_ALTERNATE_CONTACT": "/api/v1/gts/user/personal-details/alternate-number/generate-otp",
    "API_GENERATE_OTP_PRIMARY_EMAIL": "/api/v1/gts/user/primary-email/generate-otp",
    "API_GENERATE_OTP_ALTERNATE_EMAIL":"/api/v1/gts/user/personal-details/alternate-email/generate_otp",
    "API_VALIDATE_PRIMARY_EMAIL": "/api/v1/gts/user/validate_primary_email",
    "API_UPDATE_ALTERNATE_EMAIL":  "/api/v1/gts/user/personal-details/alternate-email/update",
    "API_VALIDATE_ALTERNATE_EMAIL": "/api/v1/gts/user/personal-details/alternate-email/validate",
    "API_UPDATE_ALTERNATE_CONTACT": "/api/v1/gts/user/personal-details/alternate-mobile-number/update",
    "API_VALIDATE_ALTERNATE_CONATCT": "/api/v1/gts/user/personal-details/alternate-number/validate",
    "API_GET_ACTIVE_NATIONALITIES":"/api/v1/gts/nationalities/active",
    "API_GET_FIELD_OF_RECRUITMENT":"/api/v1/gts/user/gts-field-of-recruitment/recruiter-id",
    "API_FIELD_OF_RECRUITMENT":"/api/v1/gts/user/gts-field-of-recruitment",
    "API_CONTACT_US":"/api/v1/gts/contact_us",
    "API_GET_USER_ADDRESSES" : "/api/v1/gts/user/addresses/user_id",
    "API_USER_ADDRESSES" : "/api/v1/gts/user/addresses",
    "API_GET_USER_WORK_EXP" : "/api/v1/gts/user/work_experience/user_id",
    "API_USER_WORK_EXP": "/api/v1/gts/user/work_experience",
    "API_DELETE_USER_WORK_EXP" :"/api/v1/gts/user/work_experience/user_work_experience_id",
    "API_GET_SC_SERVICES_BY_SERVICE_ID" : "/api/v1/gts/employer/job_post/job_id",
    "API_DELETE_SERVICE_APPLICATION_BY_JOB_APPL_ID": "/api/v1/gts/job/applications/job_application_id",
    "API_GET_SERVICE_APPLICATIONS_BY_SERVICE" : "/api/v1/gts/job/applications/applicant_id/job_title_id",
    "API_GET_SERVICE_APPLICATIONS_BY_SKILL" : "/api/v1/gts/job/applications/applicant_id/job_skill_ids" ,
    "API_USER_RESUME_UPLOAD" : "/api/v1/gts/user/resume/upload/user_id",
    "API_USER_RESUME_DOWNLOAD" : "/api/v1/gts/resume/download/user-id",
    "API_USER_CURRENT_RESUME_DOWNLOAD" : "/api/v1/gts/user/resume/download/current/user-id",
    "API_USER_PREVIOUS_RESUME_DOWNLOAD" : "/api/v1/gts/user/resume/download/previous/user-id",
    "API_USER_CURRENT_RESUME_DELETE" :"/api/v1/gts/user/resume/upload/current/user-resume-id",
    "API_USER_PREVIOUS_RESUME_DELETE": "/api/v1/gts/user/resume/upload/previous/user-resume-id",
    "API_GET_USER_RESUMES" : "/api/v1/gts/user/resume/download/user-id" ,
    "API_GET_USER_SALARY" : "/api/v1/gts/user/salary/user_id",
    "API_USER_SALARY" : "/api/v1/gts/user/salary",
    "API_GET_NOTICE_PERIOD"  :"/api/v1/gts/user/notice_period/user_id",
    "API_NOTICE_PERIOD" : "/api/v1/gts/user/notice_period",
    "API_GET_USER_SKILLS":"/api/v1/gts/user/skill/user_id",
    "API_USER_SKILLS" : "/api/v1/gts/user/skill",
    "API_DELETE_USER_SKILLS" : "/api/v1/gts/user/skill/user_skill_id",
    "API_GET_SERVICE_POST_ACTIVITY" : "/api/v1/gts/job_post_activity/job_employer_id",
    "API_GET_SC_SERVICES_INACTIVE" : "/api/v1/gts/employer/job_post/inactive/employer_id",
    "API_SC_SERVICES_CHANGE_STATUS" : "/api/v1/gts/employer/job_post/change_status",
    "API_GET_TOP_QUALIFIED_SP": "/api/v1/gts/top_qualified_sp/gts_job_id",
    "API_NOTIFY_TOP_QUALIFIED_SP": "/api/v1/gts/top_qualified_sp/notify",
  }