import React from 'react';
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';
import CareerComponent from '../../components/common/CareerComponent';


class CareerPage extends React.Component{

    render(){
        return(
            <>
                <Header />
                <CareerComponent />
                <Footer />
            </>
        )
    }
}
export default CareerPage;