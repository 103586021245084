
import { getCoordinates, getCity} from "../../utilities/GTSGeoLocations.js";


import React, { Component } from 'react';

import ls from 'local-storage';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';

import { Link, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faTrash, faEdit, faUpload, faCalendar, faAngleRight} from '@fortawesome/free-solid-svg-icons'
import { MDBIcon } from 'mdbreact';
import { FormGroup, Row, NavLink, NavItem, Col, ButtonGroup } from 'reactstrap';
import { Form, FormControl, FormLabel, FormRow, Button, FormCheck } from 'react-bootstrap';
import { endpoints_properties } from '../../properties/EndPointsProperties.js';
import { api_properties } from '../../properties/APIProperties.js';

class GlobalHomePage extends Component {
  constructor(props) {
    super(props);
    ls.set('token', "");
    ls.set('ENV', "LOCAL");
	ls.set('userLoginDetails', "");
	ls.set('userPersonalDetails', "");
	ls.set('userAddressesCache', "");
  };

  componentDidMount() {


  }
  render() {

    //   var coordinates = getCoordinates();
    //   console.log("Coordinates: " + coordinates);

    //  var cityName = getCity(coordinates);
    //  console.log("cityName: "+cityName);


const iconStyles = {
      // marginLeft:"100%",
      float: "left",
      clear: "left",
      height: "60px",
      width: "90px"
    }

const pageBackground = {
  backgroundColor: "white"
};
    return (
      <>
        <Header/>
<div style={pageBackground}>
        <div className="container align-items-center" >      {/*MAIN class BORDER */}
          <div divID="icon" styles={iconStyles} align="right">
            {/* <i class="fas fa-map-marker-alt"></i> Location: <a href=" http://maps.google.com/?q=Bengaluru,IN" class="text-primary">Bengaluru</a> */}

            <img src={process.env.PUBLIC_URL + "/images/goraitech.jpg"}
              alt="pic" width="150px" class="iconStyles" align="left" />
          </div>
			<br/><br/>
          <h1 className="text-left font-weight-bold" style={{ fontSize: "2rem" }}>Gorai Technology Solutions</h1>
          <h5 class="text-left"><i>A Platform for Service Providers, Service Consumers, Trainers & Trainees</i></h5>


          {/*MAIN START BORDER */}

          {/*frist column start */}
          <div className="mt-5 m-5 ">
            {/*Second-Border-Main */}




            <div class="container">
              <div className='mt-5 mb-3'>
                <h3 className="text-left font-bold">TRENDING TRAINING PROGRAMS IN BANGALORE</h3>
                <div className="border m-2  border-dark" >
                  <div class="w-100 h-50">
                    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                      <div class="carousel-inner center">
                        <div class="carousel-item active ">
                          <img class="d-block w-100 h-50" src={"/images/training.jpg"} alt="First slide" />
                        </div>
                        <div class="carousel-item ">
                          <img class="d-block w-100 h-50" src={"/images/training2.jpg"} alt="Second slide" />
                        </div>

                      </div>
                      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                      </button>
                      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <br />

            <div class="container">
              <div className='mt-5 mb-3'>
               <h3 className="text-left font-bold">TRENDING SKILL SET</h3>
                <div className="border m-2  border-dark" >
                  <div class="w-100 h-50">
                    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                      <div class="carousel-inner center">
                        <div class="carousel-item active ">
                          <img class="d-block w-100 h-50" src={"/images/skill.jpg"} alt="First slide" />
                        </div>
                      </div>
                      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                      </button>
                      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="container">
              <div className='mt-5 mb-3'>
                <h3 className="text-left font-bold">TRENDING SERVICE PROVIDERS IN BANGALORE</h3>
                <div className="border m-2  border-dark" >
                  <div class="w-100 h-50">
                    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                      <div class="carousel-inner center">
                        <div class="carousel-item active ">
                          <img class="d-block w-100 h-50" src={"/images/serviceProviders.jpg"} alt="First slide" />
                        </div>

                      </div>
                      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                      </button>
                      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>


        <Footer />
</div>
      </>
    );
  }
}

export default GlobalHomePage;