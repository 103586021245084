import React, { Component } from 'react'
import axios from 'axios'
import ls from 'local-storage'
import { Alert, Button, ButtonGroup, Col, Container, Form, Modal, Row, InputGroup} from 'react-bootstrap';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faTrash, faEdit, faUpload } from '@fortawesome/free-solid-svg-icons';
import { endpoints_properties } from '../../properties/EndPointsProperties.js';
import { api_properties } from '../../properties/APIProperties.js';


var env=ls.get('ENV');
var token = ls.get('token');
var userLoginDetails=ls.get('userLoginDetails');

var activeCurrenciesURL="";
var getUserSalaryURL="";
var addOrEditUserSalary="";

if(env=="LOCAL"){
	activeCurrenciesURL=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_ACTIVE_CURRENCIES;
	getUserSalaryURL=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_USER_SALARY;
	addOrEditUserSalary=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_USER_SALARY;
}
else{
	activeCurrenciesURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_CURRENCIES;
	getUserSalaryURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_USER_SALARY;
	addOrEditUserSalary=endpoints_properties.ENDPOINT_PROD+api_properties.API_USER_SALARY;
}

class UserSalaryComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            gts_user_salary_id:'',
            gts_current_salary_amount: '',
            gts_expected_salary_amount: '',
            gts_current_salary_unit: '',
            gts_expected_salary_unit: '',
            gts_salary_currency_id: '',
            gts_salary_currency_name: '',
            gts_salary_currency_code: '',
            gts_years_of_experience:'',
            disableInput: true,
            userSalaryExisting : false,
            currencies : [],
            currency : [],
            validated: false
        }
        this.changeHandler = this.changeHandler.bind(this);
        this.enableInput = this.enableInput.bind(this);

    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    enableInput = () => {
        this.setState( {disableInput: false} )
    }

    validateCurrency = e => {
        let fields = this.state.fields;
        let currencyError = this.state.currencyError;
        let formIsValid = true;
        if(this.state.gts_salary_currency_id == 0) {
          formIsValid = false;
          currencyError="Please select salary currency";
            this.setState({
                currencyError: currencyError,
                validated: false
            });
          return formIsValid
        }
        else{
            currencyError= "";
          this.setState({
            currencyError: currencyError,
            validated : true
          });
       }
      }

      validate = e =>{
        let fields = this.state.fields;
        let currencyError = this.state.currencyError;
        let formIsValid = true;
        if(this.state.gts_salary_currency_id == 0) {
          formIsValid = false;
          currencyError="Please select salary currency";
            this.setState({
                currencyError: currencyError,
                validated: false
            });
          return formIsValid
        }
        else{
            currencyError= "";
          this.setState({
            currencyError: currencyError,
            validated : true
          });
       }

       if(this.state.gts_current_salary_amount == "" && this.state.gts_expected_salary_amount ==""
          && this.state.gts_current_salary_unit == "" && this.state.gts_expected_salary_unit == ""){
            formIsValid = false;
              this.setState({
                  error: "Please enter either current salary or expected salary with unit",
                  validated: false
              });
            return formIsValid
          }

         if((this.state.gts_current_salary_amount !== "" && this.state.gts_current_salary_unit !== "")
          || (this.state.gts_expected_salary_amount !=="" && this.state.gts_expected_salary_unit !== "")){
            this.setState({
                error: "",
              validated : true
            });
         }
      }

    componentDidMount() {

        axios.get(activeCurrenciesURL,{ headers: {"Auth_Token" : `Bearer ${token}`} })
        .then(res => {
            console.log(res.data)
            this.setState({currencies : res.data})
            res.data.forEach((currency) => {
            this.state.currency.push((currency.gts_currency_name).concat("("+currency.gts_currency_code+")"));
            });
        })
        console.log("getUserSalaryURL: "+getUserSalaryURL);
        console.log("userLoginDetails.user_id: "+userLoginDetails.user_id);
        axios.get(getUserSalaryURL+'/'+userLoginDetails.user_id,{ headers: {"Auth_Token" : `Bearer ${token}`} })
        .then(res => {
            console.log("res.data: "+res.data);
            if(res.data.gts_user_salary_id !==0){

				console.log("res.data.gts_user_salary_id: "+res.data.gts_user_salary_id);
				console.log("res.data.gts_current_salary_amount: "+res.data.gts_current_salary_amount);
				console.log("res.data.gts_expected_salary_amount: "+res.data.gts_expected_salary_amount);
				console.log("res.data.gts_user_salary_id: "+res.data.gts_user_salary_id);
				console.log("res.data.gts_salary_currency_id: "+res.data.gts_salary_currency_id);
				console.log("res.data.currency.gts_salary_currency_name: "+res.data.gts_salary_currency_name);
				console.log("res.data.gts_salary_currency_code: "+res.data.gts_salary_currency_code);
				console.log("res.data.gts_current_salary_unit: "+res.data.gts_current_salary_unit);
				console.log("res.data.gts_years_of_experience: "+res.data.gts_years_of_experience);


                this.setState({userSalaryExisting : true,validated : true})
                this.setState({gts_user_salary_id: res.data.gts_user_salary_id})
                this.setState({gts_current_salary_amount: res.data.gts_current_salary_amount})
                this.setState({gts_expected_salary_amount: res.data.gts_expected_salary_amount})
                this.setState({gts_salary_currency_id: res.data.gts_salary_currency_id})
                this.setState({gts_salary_currency_name: res.data.gts_salary_currency_name})
                this.setState({gts_salary_currency_code: res.data.gts_salary_currency_code})
                this.setState({current_salary_unit: res.data.gts_current_salary_unit})
                this.setState({expected_salary_unit: res.data.gts_expected_salary_unit})
                this.setState({gts_years_of_experience: res.data.gts_years_of_experience})

                if(res.data.gts_current_salary_unit == null){
                    this.setState({gts_current_salary_unit : '' })
                }
               else{
                    if(res.data.gts_current_salary_unit !== ' ' && res.data.gts_current_salary_unit !== null ){
                        if( res.data.gts_current_salary_unit === "Year"){
                            this.setState({gts_current_salary_unit : 1 })
                        }
                        else if( res.data.gts_current_salary_unit === "Month"){
                            this.setState({gts_current_salary_unit : 2 })
                        }
                        else if( res.data.gts_current_salary_unit === "Week"){
						   this.setState({gts_current_salary_unit : 3 })
                        }
                        else if( res.data.gts_current_salary_unit === "Day"){
                            this.setState({gts_current_salary_unit : 4 })
                        }
                        else if( res.data.gts_current_salary_unit === "Hour"){
                            this.setState({gts_current_salary_unit : 5 })
                        }
                        else if(res.data.gts_current_salary_unit === "null"){
                            this.setState({gts_current_salary_unit : 0 })
                        }
                    }
                }
                if(res.data.gts_expected_salary_unit == null){
                    this.setState({gts_expected_salary_unit : '' })
                }
                else{
                    if(res.data.gts_expected_salary_unit !== ' ' && res.data.gts_expected_salary_unit !== null ){
                        if( res.data.gts_expected_salary_unit === "Year"){
                            this.setState({gts_expected_salary_unit : 1 })
                        }
                        else if( res.data.gts_expected_salary_unit === "Month"){
                            this.setState({gts_expected_salary_unit : 2 })
                        }
                        else if( res.data.gts_expected_salary_unit === "Week"){
                            this.setState({gts_expected_salary_unit : 3 })
                        }
                        else if( res.data.gts_expected_salary_unit === "Day"){
                            this.setState({gts_expected_salary_unit : 4 })
                        }
                        else if( res.data.gts_expected_salary_unit === "Hour"){
                            this.setState({gts_expected_salary_unit : 5 })
                        }
                        else if( res.data.gts_expected_salary_unit === "null"){
                            this.setState({gts_expected_salary_unit : 0 })
                        }
                    }
                }
            }
        })
        .catch(error => {
            console.log(error)
        })
    }

    saveAutoCompleteCurrency=(event, value) => {
		console.log("***Calling saveAutoCompleteCurrency()***");
		console.log("value: "+value);



        if(value!==null && value!== ''){
            this.state.gts_salary_currency_name = value
            this.state.selectedCurrency = value.split('(')[0];
            this.setState({gts_salary_currency_name : value.split('(')[0]})
            this.setState({gts_salary_currency_code : value.split('(')[1].split(')')[0]})
            this.state.currencies.forEach((currency) => {
            if (this.state.selectedCurrency === currency.gts_currency_name) {
				console.log("currency.gts_currency_id: "+currency.gts_currency_id);

                this.state.gts_salary_currency_id = currency.gts_currency_id;
            }
            });

       }
    }

    selectPerCurrentSalary = (e) => {
        if(e.target.value !== null && e.target.value !== ''){
            var e = document.getElementById("perCurrentSalary");
            if( e.value === "Year"){
                this.state.gts_current_salary_unit = 1
            }
            else if( e.value === "Month"){
                this.state.gts_current_salary_unit = 2
            }
            else if( e.value === "Week"){
			    this.state.gts_current_salary_unit = 3
            }
            else if( e.value === "Day"){
                this.state.gts_current_salary_unit = 4
            }
            else if( e.value === "Hour"){
                this.state.gts_current_salary_unit = 5
            }
            else{
                this.state.gts_current_salary_unit = 0
            }
        }
        else{
          if( this.state.gts_current_salary_unit === "Year"){
            this.state.gts_current_salary_unit = 1
            }
            else if( this.state.gts_current_salary_unit === "Month"){
                this.state.gts_current_salary_unit = 2
            }
            else if( this.state.gts_current_salary_unit === "Week"){
                this.state.gts_current_salary_unit = 3
            }
            else if( this.state.gts_current_salary_unit === "Day"){
                this.state.gts_current_salary_unit = 4
            }
            else if( this.state.gts_current_salary_unit === "Hour"){
                this.state.gts_current_salary_unit = 5
            }
            else{
                this.state.gts_current_salary_unit = 0
            }
        }
    }

    selectPerExpectedSalary = (e) => {
        if(e.target.value !== null && e.target.value !== ''){
            var e = document.getElementById("perExpectedSalary");
            if( e.value === "Year"){
                this.state.gts_expected_salary_unit = 1
            }
            else if( e.value === "Month"){
                this.state.gts_expected_salary_unit = 2
            }
            else if( e.value === "Week"){
                this.state.gts_expected_salary_unit = 3
            }
            else if( e.value === "Day"){
                this.state.gts_expected_salary_unit = 4
            }
            else if( e.value === "Hour"){
                this.state.gts_expected_salary_unit = 5
            }
            else{
                this.state.gts_expected_salary_unit = 0
            }
        }
        else{
            if(this.state.gts_expected_salary_unit === "Year"){
                this.state.gts_expected_salary_unit = 1
            }
            else if( this.state.gts_expected_salary_unit === "Month"){
                this.state.gts_expected_salary_unit = 2
            }
            else if(this.state.gts_expected_salary_unit === "Week"){
                this.state.gts_expected_salary_unit = 3
            }
            else if(this.state.gts_expected_salary_unit === "Day"){
                this.state.gts_expected_salary_unit = 4
            }
            else if( this.state.gts_expected_salary_unit === "Hour"){
                this.state.gts_expected_salary_unit = 5
            }
            else{
                this.state.gts_expected_salary_unit = 0
            }
        }
    }

    saveUserSalary = e => {
		console.log("***Calling saveUserSalary()***");
        var message=this.state.message
        var error=this.state.error

		console.log("this.state.gts_current_salary_unit: "+this.state.gts_current_salary_unit);
		console.log("this.state.gts_expected_salary_unit: "+this.state.gts_expected_salary_unit);
		console.log("this.state.gts_salary_currency_id: "+this.state.gts_salary_currency_id);
		console.log("this.state.gts_years_of_experience: "+this.state.gts_years_of_experience);

        if(this.state.gts_current_salary_unit=='' || this.state.gts_current_salary_unit==null) {
            var postPayload = {
                gts_user_id:userLoginDetails.user_id,
                gts_salary_currency_id:this.state.gts_salary_currency_id,
                gts_current_salary_amount:this.state.gts_current_salary_amount,
                gts_expected_salary_amount:this.state.gts_expected_salary_amount,
                gts_current_salary_unit:0,
                gts_expected_salary_unit:this.state.gts_expected_salary_unit,
                gts_years_of_experience: this.state.gts_years_of_experience
             };
        }

        else if(this.state.gts_expected_salary_unit == '' || this.state.gts_expected_salary_unit==null){
            var postPayload = {
                gts_user_id:userLoginDetails.user_id,
                gts_salary_currency_id:this.state.gts_salary_currency_id,
                gts_current_salary_amount:this.state.gts_current_salary_amount,
                gts_expected_salary_amount:this.state.gts_expected_salary_amount,
                gts_current_salary_unit:this.state.gts_current_salary_unit,
                gts_expected_salary_unit:0,
                gts_years_of_experience: this.state.gts_years_of_experience
             };
        }

        else{
            var postPayload = {
                gts_user_id:userLoginDetails.user_id,
                gts_salary_currency_id:this.state.gts_salary_currency_id,
                gts_current_salary_amount:this.state.gts_current_salary_amount,
                gts_expected_salary_amount:this.state.gts_expected_salary_amount,
                gts_current_salary_unit:this.state.gts_current_salary_unit,
                gts_expected_salary_unit:this.state.gts_expected_salary_unit,
                gts_years_of_experience: this.state.gts_years_of_experience
             };
        }

        if(this.state.gts_current_salary_unit=='' || this.state.gts_current_salary_unit==null) {
            var putPayload = {
                gts_user_salary_id:this.state.gts_user_salary_id,
                gts_salary_currency_id:this.state.gts_salary_currency_id,
                gts_current_salary_amount:null,
                gts_expected_salary_amount:this.state.gts_expected_salary_amount,
                gts_current_salary_unit:0,
                gts_expected_salary_unit:this.state.gts_expected_salary_unit,
                gts_years_of_experience: this.state.gts_years_of_experience
              };
        }

        else if(this.state.gts_expected_salary_unit == '' || this.state.gts_expected_salary_unit==null){
            var putPayload = {
                gts_user_salary_id:this.state.gts_user_salary_id,
                gts_salary_currency_id:this.state.gts_salary_currency_id,
                gts_current_salary_amount:this.state.gts_current_salary_amount,
                gts_expected_salary_amount:this.state.gts_expected_salary_amount,
                gts_current_salary_unit:this.state.gts_current_salary_unit,
                gts_expected_salary_unit:0,
                gts_years_of_experience: this.state.gts_years_of_experience
              };
        }

        else{
            var putPayload = {
                gts_user_salary_id:this.state.gts_user_salary_id,
                gts_salary_currency_id:this.state.gts_salary_currency_id,
                gts_current_salary_amount:this.state.gts_current_salary_amount,
                gts_expected_salary_amount:this.state.gts_expected_salary_amount,
                gts_current_salary_unit:this.state.gts_current_salary_unit,
                gts_expected_salary_unit:this.state.gts_expected_salary_unit,
                gts_years_of_experience: this.state.gts_years_of_experience
              };
        }

        console.log("this.state.gts_user_salary_id: "+this.state.gts_user_salary_id);
        console.log("*****this.state.gts_salary_currency_id*****: "+this.state.gts_salary_currency_id);
        console.log("this.state.validated: "+this.state.validated);



        if(this.state.gts_user_salary_id==0 && this.state.validated == true){
            axios.post(addOrEditUserSalary,postPayload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
            .then(response => {
				 console.log("Added salary: "+response.data.message);
				 console.log("response.data.gts_user_salary_id: "+response.data.gts_user_salary_id);
				 //message = response.data.message;
				 this.setState({message : response.data.message});
				 this.setState({gts_user_salary_id: response.data.gts_user_salary_id})
            })
            .catch(err => {
                console.log("****err****: "+err);

                if(err.response){
					error = err.response.data.message
					this.setState({error: error});
					console.log("err.response: "+err.response);

                }
                else{
                    error = "Not able add salary details"
                    console.log("error: "+error);
                    this.setState({error: error});

                }
            })

        }

        else  {
            if(this.state.validated == true){
            axios.put(addOrEditUserSalary,putPayload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
            .then(response => {
				console.log("Updated salary: "+response.data.message);
				console.log("response.data.gts_user_salary_id: "+response.data.gts_user_salary_id);

				//message = response.data.message;
				this.setState({message : response.data.message});
            })
            .catch(err => {

                if(err.response){
                error = err.response.data.message
                this.setState({error: error});
                console.log("err.response: "+err.response);

                }
                else{
                    error = "Not able update salary details";
                    console.log("error: "+error);
                    this.setState({error: error});
                }
            })
          }
        }
    }

    componentDidCatch(errorMsg,errorInfo){
        let errors = this.state.error;
        errors = "Try after sometime or Please contact the administrator";
        this.setState({errors : errors})

   }

   clearMessage = e => {
        var message=this.state.message
        var error=this.state.error
        var errors=this.state.errors
        if (this.state.message) {
            message= '';
            this.setState({message : message});
       }

        if (this.state.error) {
            error = '';
            this.setState({error : error});
       }

        if (this.state.errors) {
            errors = '';
          this.setState({errors : errors});
       }
   }

    render() {
        return(

            <Container className="container align-items-center">
                <div className="container align-items-center">
                <div className="mt-3">
                    <div className="row">
                        <div className="col-4"></div>
                    </div>
                    <Row>
                        <Col>
                            <Form>
                                <div className="border border-dark  rounded-sm" >

                                    <h5 style={{color : "black"}} class="mb-2 p-2"><b>Experience and Salary</b></h5>
                                    <div style={{borderBottomColor: 'black',borderBottomWidth: 1.5}} /><br/>
                                    <div className="row">
                                          <h6 style={{padding: "10px 20px", border: " #D3D3D3", color:"black"}}>&nbsp;&nbsp;&nbsp;Experience (in Months) :
                                         &nbsp;&nbsp;&nbsp;  <input type="text" id="gts_years_of_experience" name="gts_years_of_experience" disabled={this.state.userSalaryExisting && this.state.disableInput ? 'disableInput' : ''} defaultValue={this.state.gts_user_salary_id !==0 ? this.state.gts_years_of_experience: ''} style={{color:this.state.userSalaryExisting && this.state.disableInput ? 'gray' : 'black',border: "1px solid #D3D3D3", height:"30px", width:"80px",padding: "10px 20px",}}  onChange={this.changeHandler}/>
                                  </h6></div>
                                    <div className="row">
                                        <div className="col-2 pl-0 pr-0" align="left">
                                          <h6 style={{padding: "10px 20px", border: " #D3D3D3", color:"black"}}>&nbsp;&nbsp;&nbsp;Select Currency&nbsp;:</h6>
                                       </div>
                                    {/* </div>
                                    <div className="row p-1"> */}
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <Autocomplete
                                            options={this.state.currency}
                                            style={{ width: 450, outlineColor: "black", display: "inline-block"}}
                                            renderInput={(params) => <TextField {...params} variant="outlined" style={{ color: "black" }} id="gts_salary_currency_name(gts_salary_currency_code)" name="gts_salary_currency_name(gts_salary_currency_code)" size="small"/>}
                                            onChange={this.saveAutoCompleteCurrency}
                                            onBlur={this.validateCurrency.bind(this)}
                                           // defaultValue={this.state.gts_salary_currency_name+"("+this.state.gts_salary_currency_code+")"}
                                            value={this.state.userSalaryExisting == true ? this.state.gts_salary_currency_name+"("+this.state.gts_salary_currency_code+")" : this.state.gts_salary_currency_name}
                                            noOptionsText='No options'
                                            disabled={this.state.userSalaryExisting==true && this.state.disableInput ? 'disableInput' : ''}
                                        />
                                    </div>
                                    {
                                        (this.state.currencyError) ?
                                      <div className="row">
                                           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <span style={{color : "red" , width : "500px"}}><left>{this.state.currencyError}</left></span>
                                      </div>
                                      : ''
                                    }
                                    <br/>
                                    <InputGroup>
                                    <div className="row">
                                        <div className="col pl-2">
                                            <h6 style={{padding: "0px 20px", border: " #D3D3D3", color:"black"}}>Current&nbsp;Salary:</h6>
                                        </div>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <div className="col">
                                            <input type="text" id="gts_current_salary_amount" name="gts_current_salary_amount" defaultValue={this.state.gts_user_salary_id !==0 ? this.state.gts_current_salary_amount : ''} style={{color:this.state.userSalaryExisting && this.state.disableInput ? 'gray' : 'black',border: "1px solid #D3D3D3", height:"35px", width:"60px"}} disabled={this.state.userSalaryExisting && this.state.disableInput ? 'disableInput' : ''}  onChange={this.changeHandler}/>
                                        </div>
                                        <div className="col">
                                            <h6 style={{padding: "0px 0px", border: " #D3D3D3", color:"black"}}>Per</h6>
                                        </div>
                                        <div className="col">
                                            <select style={{color:this.state.userSalaryExisting && this.state.disableInput ? 'gray' : 'black',border:"1px solid #D3D3D3", height:"35px", width:"75px"}} id="perCurrentSalary" disabled={this.state.userSalaryExisting && this.state.disableInput ? 'disableInput' : ''} onChange={this.selectPerCurrentSalary}>
                                                <option value="present">{this.state.gts_user_salary_id ? this.state.current_salary_unit :""}</option>
                                                <option value="Hour">Hour</option>
                                                <option value="Day">Day</option>
                                                <option value="Week">Week</option>
                                                <option value="Month">Month</option>
                                                <option value="Year">Year</option>
                                                <option value="Select">Select</option>
                                            </select>
                                       </div>
                                    </div>
                                    </InputGroup>
                                    <br/>
                                    <InputGroup>
                                    <div className="row">
                                        <div className="col pl-2">
                                            <h6 style={{padding: "0px 20px", border: " #D3D3D3", color:"black"}}>Excepted&nbsp;Salary:</h6>
                                        </div>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <div className="col">
                                            <input type="text" id="gts_expected_salary_amount" name="gts_expected_salary_amount" disabled={this.state.userSalaryExisting && this.state.disableInput ? 'disableInput' : ''} defaultValue={this.state.gts_user_salary_id !==0 ? this.state.gts_expected_salary_amount : ""} style={{color:this.state.userSalaryExisting && this.state.disableInput ? 'gray' : 'black',border: "1px solid #D3D3D3", height:"35px", width:"60px"}}  onChange={this.changeHandler}/>
                                        </div>
                                        <div className="col">
                                            <h6 style={{padding: "0px 0px", border: " #D3D3D3", color:"black"}}>Per</h6>
                                        </div>
                                        <div className="col">
                                          <select style={{color:this.state.userSalaryExisting && this.state.disableInput ? 'gray' : 'black',border:"1px solid #D3D3D3", height:"35px", width:"70px"}} id="perExpectedSalary" disabled={this.state.userSalaryExisting && this.state.disableInput ? 'disableInput' : ''} onChange={this.selectPerExpectedSalary}>
                                                <option value="present">{this.state.gts_user_salary_id !==0 ? this.state.expected_salary_unit :""}</option>
                                                <option value="Hour">Hour</option>
                                                <option value="Day">Day</option>
                                                <option value="Week">Week</option>
                                                <option value="Month">Month</option>
                                                <option value="Year">Year</option>
                                                <option value="Select">Select</option>
                                          </select>
                                        </div>
                                    </div>
                                    </InputGroup>
                                    <InputGroup>
                                    <div className="row offset-10">
                                        <div className="col">
                                            <button type="button" style={{display: (!this.state.userSalaryExisting)? "none" : "inline"}} class="btn" href="#" onClick={this.enableInput}><FontAwesomeIcon icon={faEdit}  /></button>
                                        </div>
                                        <div className="col">
                                            <button type="button" style={{display: "inline"}}  class="btn btn-primary" href="#" onClick  ={this.saveUserSalary} onFocus= {this.validate}  >Save</button>
                                        </div>
                                        <br/>
                                    </div>
                                    </InputGroup>
                                        <span style={{color : "#228B22" , width : "500px"}}><center>{this.state.message}</center></span>
                                        <span style={{color : "red" , width : "500px"}}><center>{this.state.error}</center></span>
                                        <span style={{color : "red" , width : "500px"}}><center>{this.state.errors}</center></span>
                                    </div>
                            </Form>
                        </Col>
                    </Row>
                </div>
                </div>
            </Container>
        );
    };

}

export default UserSalaryComponent;
