import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import ls from 'local-storage';
import Moment from 'moment';
import { faUser, faTrash, faEdit, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { endpoints_properties } from '../../properties/EndPointsProperties.js';
import { api_properties } from '../../properties/APIProperties.js';

import {
  Accordion ,Card,
  ButtonGroup,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Button,
  Form,
  InputGroup
} from "react-bootstrap";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

var env=ls.get('ENV');
var token=ls.get('token');
var	userLoginDetails=ls.get('userLoginDetails');
var	userPersonalDetails=ls.get('userPersonalDetails');

var	retrieveWorkExpURL="";
var	activeCompaniesURL="";
var	activeJobTitlesURL="";
var	citiesURL="";
var	skillsURL="";
var	createUpdateWorkExpURL="";
var	deleteWorkExpURL="";

if(env=="LOCAL"){
	retrieveWorkExpURL=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_USER_WORK_EXP;
	activeCompaniesURL=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_ACTIVE_COMPANIES;
	activeJobTitlesURL=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_ACTIVE_SERVICE_TITLES;
	citiesURL=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_ACTIVE_CITIES;
	skillsURL=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_ACTIVE_SKILLS;
	createUpdateWorkExpURL=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_USER_WORK_EXP;
	deleteWorkExpURL=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_DELETE_USER_WORK_EXP;
}
else{
	retrieveWorkExpURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_USER_WORK_EXP;
	activeCompaniesURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_COMPANIES;
	activeJobTitlesURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_SERVICE_TITLES;
	citiesURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_CITIES;
	skillsURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_SKILLS;
	createUpdateWorkExpURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_USER_WORK_EXP;
	deleteWorkExpURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_DELETE_USER_WORK_EXP;
}

class UserWorkExperienceComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deselected:true,
      disableWork: true,
      gts_user_id: userLoginDetails.user_id,
      gts_user_project_skill_ids: null,
      gts_user_project_skill_id: null,
      gts_user_project_skill_name: null,
      gts_user_work_start_date: "",
      gts_user_work_end_date: "",
      gts_work_experience: [],
      hasError:false,
      gts_user_job_description: "",
      gts_user_job_title_id: null,
      gts_user_job_title_name: "",
      gts_user_company_id: null,
      gts_user_company_name: "",
      skill: [],
      company: [],
      jobTitle: [],
      city: [],
      gts_project_skills: [],
      gts_user_project_skills: [],
      gts_project_skills_name: [],
      message: '',
      IsCollapsed: false,
      skills:[{
        gts_skill_id: 0,
        gts_skill_name: null,
        gts_skill_description: null,
        gts_skill_status: false
      }],
      workExperienceSkills:{
        gts_user_work_experience_id: null,
        gts_user_id: userLoginDetails.user_id,
        gts_user_project_skill_ids: null,
        gts_user_work_start_date: null,
        gts_user_work_end_date: null,
        gts_user_job_description: null,
        gts_user_job_title_id: null,
        gts_user_job_title_name: null,
        gts_user_company_id: null,
        gts_user_company_name: null,
       },
      startDate: new Date(),
      monthSelected:'',
      month:[],
      year:[]
    };
    this.cancel = '';
    this.handleChange = this.handleChange.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChange(date) {
    this.setState({
    startDate:date
    })
  }

  enableProject = () =>{
    this.setState({disableWork:false})
  }

  cancelHandler=e=>{
      this.setState({cancelFlag:true});
  }

  reset= (e) =>{
    let workExperience=null
    this.setState({workExperience:workExperience})
  }

  cancelEditHandler=e=>{
    this.setState({editSaveFlag:false});
  }

  onChangeValue = event => {
    this.setState({gts_user_project_skill_name: event.target.value,
      gts_user_project_skill_id: event.target.value
    })
  }

  clearMessage = () => {
    let message = this.state.message;
    let error = this.state.error;
    let updateMessage = this.state.updateMessage;
    let updateError = this.state.updateError;
    let deleteMessage = this.state.deleteMessage;
    let deleteError = this.state.deleteError;
    let errors = this.state.errors;
    let companyError = this.state.companyError;

    if(this.state.companyError){
      companyError = " ";
      this.setState({ companyError: companyError });
    }
    if(this.state.message){
      message = " ";
      this.setState({ message: message });
    }
    if(this.state.error){
      error = " ";
      this.setState({ error: error });
    }
    if(this.state.updateMessage){
      updateMessage = " ";
      this.setState({ updateMessage: updateMessage });
    }
    if(this.state.updateError){
      updateError = " ";
      this.setState({ updateError: updateError });
    }
    if(this.state.deleteMessage){
      deleteMessage = " ";
      this.setState({ deleteMessage: deleteMessage });
    }
    if(this.state.deleteError){
      deleteError = " ";
      this.setState({ deleteError: deleteError });
    }
    if(this.state.errors){
      errors = " ";
      this.setState({ errors: errors });
    }
  };

  saveAutoCompleteInput = (event, value) => {
    this.state.gts_project_skills_name = value;
  }

  resetValues = () =>{
    this.state.gts_user_company_name = ""
  }

  validateCompany = e => {
    let companyError = this.state.companyError;
    let formIsValid = true;
    if (!this.state.gts_user_company_name || this.state.gts_user_company_name=='' || this.state.gts_user_company_name== null || this.state.gts_user_company_name==undefined) {
      formIsValid = false;
      companyError = "Please select company.";
      this.setState({
        companyError: companyError
      });
    }
    else{
      companyError = '';
      this.setState({
        companyError: companyError
      });
    }
  }

  componentDidMount() {

    if (this.cancel) {
      this.cancel.cancel();
    }
    this.cancel = axios.CancelToken.source();

    var project_url = retrieveWorkExpURL+'/'+userLoginDetails.user_id;
    axios.get(project_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
    .then(res => {
      console.log(res.data)
      var gts_user_project_skills=[]
      this.setState({gts_work_experience : res.data});
    })
        var month=[];
        for(var i=1;i<=12;i++){
          let zero = i < 10 ? '0' : '';
          this.state.month.push(zero + i);
        }

        var years=[];
        var minOffset = 0, maxOffset = 70; // Change to whatever you want
        var thisYear = (new Date()).getFullYear();

        for (var i = minOffset; i <= maxOffset; i++) {
          var year = thisYear - i;
          years.push(year)
        }
        this.setState({year:years})

        var company_url = activeCompaniesURL;
        axios.get(company_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
        .then((res) => {
          console.log(res.data)
          this.setState({ companies: res.data });
          this.state.companies.forEach((companies) => {
              this.state.company.push(companies.gts_company_name);
          });
        });

        var jobTitle_url = activeJobTitlesURL;
        axios.get(jobTitle_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
        .then((res) => {
          console.log(res.data)
          this.setState({ jobTitles: res.data });
          this.state.jobTitles.forEach((jobTitles) => {
              this.state.jobTitle.push(jobTitles.gts_job_title_name);
          })
        });

        var getCities =citiesURL;
        axios.get(getCities,{ headers: {"Auth_Token" : `Bearer ${token}`} })
        .then((res) =>{
        console.log(res.data)
        this.setState({ cities: res.data });
          this.state.cities.forEach((city) => {
              this.state.city.push(city.gts_city_name);
          })
        });

     this.autoCompleteChangeHandler();
 }

  componentDidCatch(errorMsg,errorInfo){
        let errors = this.state.error;
        errors = "Try after sometime or Please contact the administrator";
        this.setState({errors : errors})
 }

 autoCompleteChangeHandler = (input) =>{
    var skill_url = skillsURL;
    axios.get(skill_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
    .then((response) => {
      this.state.skills = response.data;
      if (this.state.gts_project_skills.length != 0)
        this.state.gts_project_skills.length = [];
        this.state.skills.forEach((skills) => {
        this.state.gts_project_skills.push(skills.gts_skill_name);
        this.state.gts_project_skills.filter((value) => value.includes(input));
      });
    })
 }

 saveWorkExperience = (event) => {
    event.preventDefault();

    let error = this.state.error;
    var gts_user_project_skills=[];
    this.state.gts_project_skills_name.forEach((gts_project_skill_id) => {
      this.state.skills.forEach((skill) => {
        if (gts_project_skill_id === skill.gts_skill_name) {
          gts_user_project_skills.push(skill.gts_skill_id);
        }
      });
    });

    if(this.state.currentStartYear || this.state.currentStartMonth){
      var  start_date=(this.state.currentStartYear+"-"+this.state.currentStartMonth+"-01").toLocaleString();
    }
    else{
      var start_date = ''
    }

    if(this.state.currentEndYear || this.state.currentEndMonth){
      var  end_date=(this.state.currentEndYear+"-"+this.state.currentEndMonth+"-01").toLocaleString()
    }
    else{
      var  end_date= ''
    }
    var url = createUpdateWorkExpURL;
         var post_payload={
          gts_user_id: this.state.gts_user_id,
          gts_user_project_skill_ids: gts_user_project_skills.join(",").toString(),
          gts_user_work_start_date: start_date,
          gts_user_work_end_date: end_date,
          gts_user_job_description: this.state.gts_user_job_description,
          gts_user_job_title_id: this.state.gts_user_job_title_id,
          gts_user_company_id: this.state.gts_user_company_id,
          is_currently_working: this.state.is_currently_working,
          gts_city_id : this.state.gts_city_id
        }


         axios.post(url,post_payload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
         .then(response=>{
          var project_url = retrieveWorkExpURL+'/'+userLoginDetails.user_id;
          axios.get(project_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
          .then(res => {
          if(res.data.length!==0){
          this.setState({gts_work_experience : res.data});
          }
          else{
            this.setState({gts_work_experience : [] });
          }
          })
          this.setState({ message: response.data.message });
          this.setState({gts_user_work_experience_id : response.data.gts_user_work_experience_id})
        })
        .catch((err) => {
          if(err.response.data.status_code == 400 || err.response.data.status_code == 404){
           this.setState({ error: err.response.data.message });
          }
          else{
            error = "Not able to add work experience"
            this.setState({ error: error });
          }
        });
 }

 updateWorkExpereince = (workExp) =>{
    let updateError = this.state.updateError;
    let updateMessage = this.state.updateMessage;

    if(this.state.gts_project_skills_name){
      var gts_user_project_skills=[];
      this.state.gts_project_skills_name.forEach((gts_project_skill_id) => {
        this.state.skills.forEach((skill) => {
          if (gts_project_skill_id === skill.gts_skill_name) {
            gts_user_project_skills.push(skill.gts_skill_id);
          }
        });
      });
  }
    else{
      this.state.gts_user_project_skills = this.state.gts_user_project_skills
    }

    if(this.state.startYear || this.state.startMonth){
      var start_date=(this.state.startYear+"-"+this.state.startMonth+"-01").toLocaleString();
    }
    else{
      var start_date = this.state.gts_user_work_start_date;
    }

    if(this.state.endYear || this.state.endMonth){
      var end_date=(this.state.endYear+"-"+this.state.endMonth+"-01").toLocaleString()
      }
      else{
         var end_date = this.state.gts_user_work_end_date;
      }

    var url =  createUpdateWorkExpURL;
    var put_payload={
      gts_user_work_experience_id: workExp.gts_user_work_experience_id,
      gts_user_id: this.state.gts_user_id,
      gts_user_project_skill_ids: gts_user_project_skills.join(",").toString(),
      gts_user_work_start_date: start_date,
      gts_user_work_end_date: end_date,
      gts_user_job_description: this.state.gts_user_job_description,
      gts_user_job_title_id: this.state.gts_user_job_title_id,
      gts_user_company_id: workExp.gts_user_company_id,
      is_currently_working: this.state.is_currently_working,
      gts_city_id : this.state.gts_city_id
    }

    axios.put(url,put_payload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
    .then((response) => {
      console.log(response.data.message)
      var project_url = retrieveWorkExpURL+'/'+userLoginDetails.user_id
    axios.get(project_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
    .then(res => {
    if(res.data.length!==0){
    this.setState({gts_work_experience : res.data});
      }
      else{
        this.setState({gts_work_experience : [] });
      }
    })
      updateMessage ="User project details updated successfully."
      this.setState({ updateMessage: updateMessage });
    })
    .catch((err) => {
      if(err.response.data.status_code == 400 || err.response.data.status_code == 404){
       this.setState({ error: err.response.data.message });
      }
      else{
        this.setState({ error: "Not able to update work experience" });
      }
    });
 }

 deleteWorkExpereince(gts_user_work_experience_id){
    let deleteMessage = this.state.deleteMessage;
    let deleteError = this.state.deleteError;
    if(window.confirm('Are you sure?')){
      axios.delete(deleteWorkExpURL+'/'+gts_user_work_experience_id, { headers: {"Auth_Token" : `Bearer ${token}`} })
        .then(response=>{
      var gts_work_experience = [];
          var get_url = retrieveWorkExpURL+'/'+userLoginDetails.user_id
          axios.get(get_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
          .then(res => {
            if(res.data.length!==0){
                  this.setState({gts_work_experience : res.data});
            }
            else{
            this.setState({gts_work_experience : [] });
             }
           })

          this.setState({gts_work_experience : []});
          deleteMessage = "User project deleted successfully"
          this.setState({ deleteMessage : deleteMessage });
          window.location.reload(false,10000);
          })
          .catch((err) => {
            if(err.response.data.status_code == 400 || err.response.data.status_code == 404){
             this.setState({ error: err.response.data.message });
            }
            else{
              this.setState({ error: "Not able to delete work experience" });
            }
          });
      }
  }

  saveAutoCompleteCurrentCompany=(event, value) => {
    this.state.selectedCurrentCompany = value;
    this.state.gts_user_company_name = value;
    if(value == ''  || value== null){
      this.setState ({gts_user_company_id : ''})
    }
    else{
      this.state.companies.forEach((company) => {
        if (this.state.selectedCurrentCompany === company.gts_company_name) {
          this.state.gts_user_company_id = company.gts_company_id;
        }
       })
    }
 }

 saveAutoCompleteCurrentJobTitle=(event, value) => {
    this.state.selectedCurrentJobTitle = value;
    this.state.gts_user_job_title_name = value;
    if(value == ''  || value== null){
      this.setState({gts_user_job_title_id : ''})
    }
     else{
      this.state.jobTitles.forEach((jobTitle) => {
        if (this.state.selectedCurrentJobTitle === jobTitle.gts_job_title_name) {
          this.state.gts_user_job_title_id = jobTitle.gts_job_title_id;
        }
       })
     }
 }

 saveAutoCompleteCurrentLocation = (event, value) => {
    this.state.selectedCurrentLocation = value;
    this.state.gts_city_name = value;
    if(value == ''  || value== null){
      this.setState({gts_city_id : ''})
    }
     else{
      this.state.cities.forEach((cities) => {
        if (this.state.selectedCurrentLocation === cities.gts_city_name) {
          this.state.gts_city_id = cities.gts_city_id;
        }
       })
     }
 }

 selectStartMonth = (startMonth) => {
    if(startMonth.target.value){
    var e = document.getElementById("startMonth");
    this.state.startMonth = startMonth.target.value;
    }
    else{
      this.state.startMonth = Moment(this.state.gts_user_work_start_date).format("MM")
    }
 }

 selectStartYear = (startYear) => {
    if(startYear.target.value){
    var e = document.getElementById("startYear");
    this.state.startYear = startYear.target.value;
    }
    else{
      this.state.startYear = Moment(this.state.gts_user_work_start_date).format("YYYY")
    }
 }

 selectEndMonth = (endMonth) => {
    if(endMonth.target.value){
    var e = document.getElementById("endMonth");
    this.state.endMonth = endMonth.target.value;
    }
    else{
      this.state.endMonth = Moment(this.state.gts_user_work_end_date).format("MM")
    }
 }

 selectEndYear = (endYear) => {
    if(endYear.target.value){
    var e = document.getElementById("endYear");
    this.state.endYear = endYear.target.value;
    }
    else{
      this.state.endYear = Moment(this.state.gts_user_work_end_date).format("YYYY")
    }
 }

 selectCurrentStartMonth = (startMonth) => {
    var e = document.getElementById("currentStartMonth");
    this.state.currentStartMonth = startMonth.target.value;
 }

 selectCurrentStartYear = (startYear) => {
    var e = document.getElementById("currentStartYear");
    this.state.currentStartYear = startYear.target.value;
 }

 selectCurrentEndMonth = (endMonth) => {
    var e = document.getElementById("currentEndMonth");
    this.state.currentEndMonth = endMonth.target.value;
 }

 selectCurrentEndYear = (endYear) => {
    var e = document.getElementById("currentEndYear");
    this.state.currentEndYear = endYear.target.value;
 }

 checkCurrentWorking = (checked) => {
    this.setState({
      [checked.target.name]: checked.target.checked
    });
    if(checked.target.checked === true){
    this.state.is_currently_working = 1;
    }
    else{
      this.state.is_currently_working = 0;
    }
 }

 render() {

    const successMessageStyles = {
      //backgroundColor: "#f0f",
      fontWeight: "bold",
      fontSize: 18,
      color: "#008000",
      //padding: paddings
    };

    const useStyles = {
      '@global': {
          '.MuiAutocomplete-option[data-focus="true"]': {
              background: 'blue'
          }
      }
  };
  const sizes = ["X-Small", "Small", "Medium", "Large", "X-Large", "2X-Large"];

    const {
      gts_user_work_start_date,
      gts_user_work_end_date,
      gts_user_team_size,
      gts_user_job_description
    } = this.state;


    return (
      <Container className="align-items-center">
      <div className="container align-items-center">
        <div className="mt-3">
          <div className="border border-dark rounded">
              <Row>
                <Col>
                  <h5 style={{color : "black"}} class="mb-2 p-2"><b>Work Experience:</b></h5>
                </Col>
                <Col>
                   <div className = "col-12" align="right">
                     <button type="button" style={{width:"160px", height:"35px", align:"center"}} class="btn btn-primary float-right" data-toggle="modal" data-target="#addWorkExperienceDetails"  onClick={this.addButtonListener} onBlur={this.clearMessage} size="small"><center><h6 style={{ color: 'white' }}>Add Experience</h6></center></button>
                  </div>
                </Col>
              </Row>
              <div class="form.row" align="center">
                <span style={{color:"red"}} ><center>{this.state.errors}</center></span>
                <span style={{color:"green"}} ><center>{this.state.deleteMessage}</center></span>
                <span style={{color:"red"}}><center>{this.state.deleteError}</center></span>
              </div>
			        <div style={{borderBottomColor: 'black',borderBottomWidth: 1.5}} />

              <Row align="center">
                  <h6 style={{color : "black", fontSize:"17px"}} class="mb-2 p-2"><b>&nbsp;&nbsp;Total Work Experience: </b></h6>
                </Row>

              {/*------------------------------------------------------------MODEL DIALOG TO ADD EXPERIENCE-------------------------------------  */}

              <div id={"addWorkExperienceDetails"} class="modal fade" role="dialog">
                <div class="modal-dialog modal-lg">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title"> Add Work Experience</h5>
                    </div>
                    <div class="modal-body">
                      <div className="container" >
                      <div class="row-sm m-0  text-left">
                        <InputGroup className="mb-3" size="sm">
                          <div className="row">
                            <div className="col">
										          <h6 style={{color : "black"}}><label>Name&nbsp;of&nbsp;Company&nbsp;:</label></h6>
									          </div>
                            <div className="col">
                              <Autocomplete
                                 options={this.state.company}
                                 style={{ width: "290px", outlineColor: "black", display: "inline-block"}}
                                  renderInput={(params) => <TextField {...params} id="gts_user_company_name" variant="outlined" style={{ color: "black" }} name="gts_user_company_name" size="small"/>}
                                  onChange={this.saveAutoCompleteCurrentCompany}
                                  noOptionsText='No options'
                                  onFocus={this.clearMessage}
                                  onBlur={this.validateCompany.bind(this)}
                               />
                            </div>
                            <div className="col">
										          <h6 style={{color : "black"}}><label>Location:</label></h6>
									          </div>
                            <div className="col">
                              <Autocomplete
                                 options={this.state.city}
                                 style={{ width: "158px", outlineColor: "black", display: "inline-block"}}
                                  renderInput={(params) => <TextField {...params} id="gts_user_company_location" variant="outlined" style={{ color: "black" }} name="gts_user_company_location" size="small"/>}
                                  onChange={this.saveAutoCompleteCurrentLocation}
                                  noOptionsText='No options'
                                  onBlur={this.clearMessage}
                               />
                            </div>
                          </div>
                          </InputGroup>

                          <div class="row">
                            <span align="center" style={{color:'red'}}>{this.state.companyError}</span>
                            <span>{this.state.companyError? <br/> : '' }</span>
                          </div>
                          <InputGroup className="mb-3" size="sm">
                          <div className="row">
									          <div className="col">
										          <h6 style={{color : "black"}}><label> Job&nbsp;Title&nbsp;:</label></h6>
									          </div>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <div className="col">
                              <Autocomplete
                                 options={this.state.jobTitle}
                                 style={{ width: "290px", outlineColor: "black", display: "inline-block"}}
                                  renderInput={(params) => <TextField {...params} id="gts_user_job_title" variant="outlined" style={{ color: "black" }} name="gts_user_job_title" size="small"/>}
                                  onChange={this.saveAutoCompleteCurrentJobTitle}
                                  noOptionsText='No options'
                                  onBlur={this.clearMessage}
                               />
                            </div>
									          <div className="col">
                              <h6>Currently&nbsp;working</h6>
                              </div>
                              <div className="col">
                              <input  type="checkbox" defaultChecked={this.state.is_currently_working==false} class="onoffswitch-checkbox" id = "isCurrentWorking" onChange={this.checkCurrentWorking}></input>
                            </div>
                           </div>
                           </InputGroup>
                           <InputGroup className="mb-3" size="sm">
                            <div className="row">
                              <div className="col">
										            <h6 style={{color : "black"}}><label>Start&nbsp;Date(MM/YY):</label></h6>
									            </div>
                              <div className="col">
                                <select style={{border:"1px solid #D3D3D3", height:"35px", width:"75px"}} id="currentStartMonth" onChange={this.selectCurrentStartMonth}>
                                <option selected value="Month">Month</option>
                                {this.state.month.map(monthSelected => {
                                return (
                                  <option value={monthSelected}> {monthSelected} </option>
                                  )
                                  })}
                                </select>
                              </div>
                              <div className="col">
                                <select style={{border:"1px solid #D3D3D3", height:"35px", width:"75px"}} id="currentStartYear" onChange={this.selectCurrentStartYear}>
                                <option selected value="Year">Year</option>
                                {this.state.year.map(yearSelected => {
                                return (
                                  <option value={yearSelected}> {yearSelected} </option>
                                  )
                                  })}
                                </select>
                              </div>
								              </div>
                              </InputGroup>
                              <InputGroup className="mb-3" size="sm">
                                <div className="row">
                                  <div className="col">
										                <h6 style={{color : "black"}}><label>End&nbsp;Date(MM/YY):</label></h6>
									                </div>
                                  &nbsp;&nbsp;
                                  <div className="col">
                                    <select style={{border:"1px solid #D3D3D3", height:"35px", width:"75px"}} id="currentEndMonth" onChange={this.selectCurrentEndMonth}>
                                    <option selected value="Month">Month</option>
                                    {this.state.month.map(monthSelected => {
                                      return (
                                        <option value={monthSelected}> {monthSelected} </option>
                                        )
                                      })}
                                    </select>
                                  </div>
                                  <div className="col">
                                    <select style={{border:"1px solid #D3D3D3", height:"35px", width:"75px"}} id="currentEndYear" onChange={this.selectCurrentEndYear}>
                                    <option selected value="Year">Year</option>
                                    {this.state.year.map(yearSelected => {
                                      return (
                                       <option value={yearSelected}> {yearSelected} </option>
                                      )
                                      })}
                                    </select>
                                    </div>
								                  </div>
                                   </InputGroup>
                                  <div className="row">
										                  <h6 style={{color : "black"}}><label>Job Description:</label></h6>
                                      <Form.Control required as="textarea" rows={4} onChange={this.changeHandler} name="gts_user_job_description" value={gts_user_job_description} placeholder="Job Description" onFocus={this.clearMessage}/>
                                    </div><br/>
                                    <div className="row">
										                  <h6 style={{color : "black"}}><label>Skills:</label></h6>
                                      <Autocomplete multiple
                                        options={this.state.gts_project_skills}
                                        style={{width: "100%", outlineColor: "black"}}
                                        filterSelectedOptions
                                        onChange={this.saveAutoCompleteInput}
                                        renderInput={(params) => {this.autoCompleteChangeHandler(params);
                                        return (<TextField {...params} variant="outlined" placeholder="Skills"/> );
                                        }}/>
                                    </div>
                                    </div>
                                    </div>
                                  </div>
                                  <div class="modal-footer">
                                     <div class="text-inline">
                                        <Button type="button" onClick={this.saveWorkExperience} onBlur={this.clearMessage}>Save</Button>
                                        <Button type="button" class="close" data-dismiss="modal" onClick={this.resetValues.bind(this)}>Cancel</Button>
                                     </div>
                                  </div>
                                  <div class="row" align="center">
                                    <span style = {{color:"green"}}>{this.state.message}</span>
                                    <span style = {{color:"red"}}>{this.state.error}</span>
                                  </div>
                             </div>
                         </div>
                    </div>

           {/* ------------------------------------------------------ComponentDidMount------------------------------------------------- */}

            <Row>
              <Col>
                <Container>
                  <Col>
                    <Container className="align-items-center">
                      <div className="mt-3">
                      <Form onSubmit={this.submitHandler}>
                              <Row>
                                <br/>

                        {
                          this.state.gts_work_experience.map((workExperience,index) => {
                          return(<>
                          { this.state.gts_work_experience.length > 0 &&

<Accordion defaultActiveKey="1">
                        <Card>
                        <Container className="border border-dark rounded">
                          <Row>
                            <Col>

                              <div className="row">
                              <div className="col p-1">
                                <label><strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Name of Company:&nbsp;&nbsp;</strong><i><span style={{color: "red"}} >{workExperience.gts_user_company_name}</span></i></label>
                              </div>
                                <div className="col" align="right">
                                    <button type="button" class="btn float" data-toggle="modal" data-target={"#editWorkExperience"+workExperience.gts_user_work_experience_id} onBlur={this.clearmessage}><FaEdit/></button>
                                    <button type="button" class="btn float" href="#" onClick={() => this.deleteWorkExpereince(workExperience.gts_user_work_experience_id)} onFocus={this.clearmessage}><FaTrash/></button>&nbsp;
                                    {/* <button type="button" class="btn float" href="#" data-toggle="collapse" data-target={"#demo"+workExperience.gts_user_work_experience_id} area-expanded="false" area-controls="#a" onClick={() => this.collapse}>+</button> */}
                                    <Accordion.Toggle eventKey= {workExperience.gts_user_work_experience_id} aria-controls="additional-actions3-content" aria-label="Expand" >
                                      <i style={{align:"right"}}  aria-hidden="true"> <ExpandMoreIcon /></i>
                                     </Accordion.Toggle>
                                </div>
                              </div>
                            </Col>
                           </Row>
                           <Accordion.Collapse eventKey={workExperience.gts_user_work_experience_id} >
                      <Card.Body>
                           <Row>
                              <Col>
                              <div >
                                    <div className="row">
                                      <div className="col">
                                        <label ><strong>Location:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong><i><span style={{color: "red"}} >{workExperience.gts_city_name}</span></i></label>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col">
                                        <label ><strong>Job Title:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong><i><span style={{color: "red"}} >{workExperience.gts_user_job_title_name}</span></i></label>
                                      </div>
                                      <div className="col">
                                        <h6><strong>Currently working </strong>&nbsp;<input disabled={workExperience} type="checkbox" defaultChecked={workExperience.is_currently_working} class="onoffswitch-checkbox" id = "isCurrentWorking" ></input></h6>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col">
                                        <label><strong>Start Date:&nbsp;</strong><i><span style={{color: "red"}} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{workExperience.gts_user_work_start_date!==null ? Moment(workExperience.gts_user_work_start_date).format('MMM YYYY') : ''}</span></i></label>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col">
                                        <label><strong>End Date:&nbsp;</strong> <i><span style={{color: "red"}} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{workExperience.gts_user_work_end_date!==null ? Moment(workExperience.gts_user_work_end_date).format('MMM YYYY') : ''}</span></i></label>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col">
                                        <label style={{"text-align" : "justified"}}><strong>Job Description:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong><i  style={{color: "red"}}>{workExperience.gts_user_job_description}</i></label></div>
                                    </div>
                                    <div className="row">
                                      <div className="col">
                                        <label style={{"text-align":"justify"}}><strong>Skills:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong></label>
                                        <i><span style={{color: "red"}} >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        {workExperience.gts_user_project_skills.map((user_skill, i) => ( <label key={i}>{user_skill.gts_skill_name.concat(','," ")}</label>))}</span></i>
                                      </div>
                                    </div>
                                  </div>
                                  </Col>
                              </Row>
                              </Card.Body>
                              </Accordion.Collapse>
                              </Container>
                              </Card>
                              </Accordion>
                          }
                          <div class="row">
                            <h6 style={{color : "black"}}><label></label></h6>
                          </div>

                        {/*--------------------------------------------------MODEL DIALOG TO EDIT PROJECT-------------------------------------------------------------------------  */}

          <div id={"editWorkExperience"+workExperience.gts_user_work_experience_id} class="modal fade" role="dialog">
                <div class="modal-dialog modal-lg">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title"> Update Project</h5>
                    </div>
                    <div class="modal-body">
                      <div className="container" >
                      <div class="row-sm m-0  text-left">
                        <InputGroup className="mb-3" size="sm">
                          <div className="row">
                            <div className="col">
										          <h6 style={{color : "black"}}><label>Name&nbsp;of&nbsp;Company&nbsp;:</label></h6>
									          </div>
                            <div className="col">
                              <Autocomplete
                                 options={this.state.company}
                                 style={{ width: "290px", outlineColor: "black", display: "inline-block"}}
                                  renderInput={(params) => <TextField {...params} id="gts_project_company" variant="outlined" style={{ color: "black" }} name="gts_project_company" size="small"/>}
                                  onChange={this.saveAutoCompleteCurrentCompany}
                                  noOptionsText='No options'
                                  onFocus={this.clearMessage}
                                  disabled={workExperience}
                                  onBlur={this.validateCompany.bind(this)}
                                  defaultValue={workExperience.gts_user_company_name}/>
                            </div>
                            <div className="col">
										          <h6 style={{color : "black"}}><label>Location:</label></h6>
									          </div>
                            <div className="col">
                              <Autocomplete
                                 options={this.state.city}
                                 style={{ width: "158px", outlineColor: "black", display: "inline-block"}}
                                  renderInput={(params) => <TextField {...params} id="gts_user_company_location" variant="outlined" style={{ color: "black" }} name="gts_user_company_location" size="small"/>}
                                  onChange={this.saveAutoCompleteCurrentLocation}
                                  noOptionsText='No options'
                                  onBlur={this.clearMessage}
                                  defaultValue={workExperience.gts_city_name}
                               />
                            </div>
								            </div>
                            </InputGroup>
                            <InputGroup className="mb-3" size="sm">
                            <div className="row">
									          <div className="col">
										          <h6 style={{color : "black"}}><label> Job&nbsp;Title&nbsp;:</label></h6>
									          </div>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <div className="col">
                              <Autocomplete
                                 options={this.state.jobTitle}
                                 style={{ width: "290px", outlineColor: "black", display: "inline-block"}}
                                  renderInput={(params) => <TextField {...params} id="gts_user_job_title" variant="outlined" style={{ color: "black" }} name="gts_user_job_title" size="small"/>}
                                  onChange={this.saveAutoCompleteCurrentJobTitle}
                                  noOptionsText='No options'
                                  onBlur={this.clearMessage}
                                  defaultValue={workExperience.gts_user_job_title_name}
                               />
                            </div>
									          <div className="col">
                              <h6>Currently&nbsp;working</h6>
                              </div>
                              <div class="col">
                              <input  type="checkbox" class="onoffswitch-checkbox" defaultChecked={workExperience.is_currently_working} id = "isCurrentWorking" onChange={this.checkCurrentWorking}></input>
                            </div>
                           </div>
                           </InputGroup>
                           <InputGroup className="mb-3" size="sm">
                            <div className="row">
                              <div className="col">
										            <h6 style={{color : "black"}}><label>Start&nbsp;Date:</label></h6>
									            </div>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <div className="col">
                                <select style={{border:"1px solid #D3D3D3", height:"35px", width:"75px"}} id="startMonth" onChange={this.selectStartMonth}>
                                <option selected value="Month">{workExperience.gts_user_work_start_date ? Moment(workExperience.gts_user_work_start_date).format("MM") : 'Month'}</option>
                                {this.state.month.map(monthSelected => {
                                return (
                                  <option value={monthSelected}> {monthSelected} </option>
                                  )
                                  })}
                                </select>
                              </div>
                              <div className="col">
                                <select style={{border:"1px solid #D3D3D3", height:"35px", width:"75px"}} id="startYear" onChange={this.selectStartYear}>
                                <option selected value="Month">{workExperience.gts_user_work_start_date ? Moment(workExperience.gts_user_work_start_date).format("yyyy") : 'Year'}</option>
                                {this.state.year.map(yearSelected => {
                                return (
                                  <option value={yearSelected}> {yearSelected} </option>
                                  )
                                  })}
                                </select>
                              </div>
								              </div>
                              </InputGroup>
                              <InputGroup className="mb-3" size="sm">
                                <div className="row">
                                  <div className="col">
										                <h6 style={{color : "black"}}><label>End&nbsp;Date:</label></h6>
									                </div>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <div className="col">
                                    <select style={{border:"1px solid #D3D3D3", height:"35px", width:"75px"}} id="endMonth" onChange={this.selectEndMonth}>
                                    <option selected value="Month">{workExperience.gts_user_work_end_date ? Moment(workExperience.gts_user_work_end_date ).format("MM") : 'Month'}</option>
                                    {this.state.month.map(monthSelected => {
                                      return (
                                        <option value={monthSelected}> {monthSelected} </option>
                                        )
                                      })}
                                    </select>
                                  </div>
                                  <div className="col">
                                    <select style={{border:"1px solid #D3D3D3", height:"35px", width:"75px"}} id="endYear" onChange={this.selectEndYear}>
                                    <option selected value="Month">{workExperience.gts_user_work_end_date ? Moment(workExperience.gts_user_work_end_date ).format("yyyy") : 'Year'}</option>
                                    {this.state.year.map(yearSelected => {
                                      return (
                                       <option value={yearSelected}> {yearSelected} </option>
                                      )
                                      })}
                                    </select>
                                    </div>
								                  </div>
                                  </InputGroup>
                                  <div className="row">
										                  <h6 style={{color : "black"}}><label>Job Description:</label></h6>
                                      <Form.Control required as="textarea" rows={3} onChange={this.changeHandler} name="gts_user_job_description" value={workExperience.gts_user_job_description} placeholder="Project Description" onFocus={this.clearMessage}/>
                                    </div><br/>
                                    <div className="row">
										                  <h6 style={{color : "black"}}><label>Skills:</label></h6>
                                      <Autocomplete multiple
                                        options={this.state.gts_project_skills}
                                        style={{width: "100%", outlineColor: "black"}}
                                        filterSelectedOptions
                                        onChange={this.saveAutoCompleteInput}
                                        defaultValue={workExperience.gts_user_project_skills.map(user_skill =>user_skill.gts_skill_name)}
                                        renderInput={(params) => {this.autoCompleteChangeHandler(params);
                                        return (<TextField {...params} variant="outlined" placeholder="Skills"/> );
                                        }}/>
                                    </div>
                                    </div>
                                    </div>
                                  </div>
                                  <div class="modal-footer">
                                     <div class="text-inline">
                                        <Button type="button" onClick={()=>this.updateWorkExpereince(workExperience)} onBlur={this.clearMessage}>Save</Button>
                                        <Button type="button" class="close" data-dismiss="modal" >Cancel</Button>
                                     </div>
                                  </div>
                                  <div class="row" align="center">
                                    <span style = {{color:"green"}}>{this.state.updateMessage}</span>
                                    <span style = {{color:"red"}}>{this.state.updateError}</span>
                                  </div>
                             </div>
                         </div>
                    </div>
                        </>)
                      })}
                      </Row>
                   </Form>
                    </div>
                  </Container>
                </Col>
              </Container>
            </Col>
          </Row>
          </div>
        </div>
        </div>
      </Container>
    );
  }
}
export default UserWorkExperienceComponent;
