import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import ls from 'local-storage';
import axios from 'axios';
import {Link } from 'react-router-dom';
import { Popover,  OverlayTrigger, FormControl, InputGroup,Dropdown, NavDropdown} from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SwitchRole from '../common/SwitchRole';


var token = ls.get("token");
var userLoginDetails=ls.get('userLoginDetails');
var userPersonalDetails=ls.get('userPersonalDetails');

class ServiceConsumerMenu extends Component {

  constructor(props) {
		if(token==null || token=="" || token=="undefined"){
		  window.location.href = '/gts/global-home-page';
		}

    super(props)

    this.state = {
      role_name:'',
      errors: {},
      isUserDetailExisting:false,
      first_name:'',
      last_name:'',
      userDetail:{}
    }
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  setUserId = (userID)=>{
    ls.set('gts_user_id', userID);
  }

  componentDidMount() {
    if(userPersonalDetails!== null){
    if( userPersonalDetails.gts_user_id == userLoginDetails.user_id ){
		  this.setState({first_name : userPersonalDetails.gts_user_first_name})
		  this.setState({last_name : userPersonalDetails.gts_user_last_name})
      }
    }
  }

render()
{
  const {
   user_name,
   user_Detail
  }=this.state;
    var roles=[];
    roles=userLoginDetails.other_roles.split(',');

    return(
      <div>
       <div className="container-fluid">
         <InputGroup>
           <NavDropdown title={<span style={{color:'black',fontSize:"13px", paddingRight:"5px"}}><b>Profile</b></span>} >
              <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/service-consumer-profile"><span style={{fontSize:"13px"}}><b>Personal Details</b></span></NavDropdown.Item>
              <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/user-projects"><span style={{fontSize:"13px"}}><b>Projects</b></span></NavDropdown.Item>
              <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/user-work-experience"><span style={{fontSize:"13px"}}><b>Work Experience</b></span></NavDropdown.Item>
              <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/user-skills"><span style={{fontSize:"13px"}}><b>Skills</b></span></NavDropdown.Item>
              <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/user-educations"><span style={{fontSize:"13px"}}><b>Education</b></span></NavDropdown.Item>
              <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/user-languages"><span style={{fontSize:"13px"}}><b>Language</b></span></NavDropdown.Item>
              <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/user-addresses"><span style={{fontSize:"13px"}}><b>Address</b></span></NavDropdown.Item>
              <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/your-profile-viewer"><span style={{fontSize:"13px"}}><b>Your Profile Viewer</b></span></NavDropdown.Item>
              <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/manage-roles"><span style={{fontSize:"13px"}}><b>Roles</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/change-password"><span style={{fontSize:"11px"}}><b>CHANGE PASSWORD</b></span></NavDropdown.Item>
           </NavDropdown>

          <NavDropdown title={<span style={{color:'black',fontSize:"13px", paddingRight:"5px"}}><b>Services</b></span>} >
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/post-a-service"><span style={{fontSize:"13px"}}><b>Post Service/Job</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/sc-search-services"><span style={{fontSize:"13px"}}><b>List Services/Jobs</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/service-post-activty"><span style={{fontSize:"13px"}}><b>Post Activities</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/closed-services"><span style={{fontSize:"13px"}}><b>Closed Services/Jobs</b></span></NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title={<span style={{color:'black',fontSize:"13px", paddingRight:"5px"}}><b>Service/Job Applications</b></span>} >
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/active-applications"><span style={{fontSize:"13px"}}><b>Active Applications</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/schedule-interviews"><span style={{fontSize:"13px"}}><b>Schedule Interviews</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/closed-applications"><span style={{fontSize:"13px"}}><b>Closed Applications</b></span></NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title={<span style={{color:'black',fontSize:"13px"}}><b>Service Providers</b></span>} >
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/top-qualified-service-provider"><span style={{fontSize:"13px"}}><b>Top Qualifies SPs</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/search-service-provider"><span style={{fontSize:"13px"}}><b>Search Service Providers</b></span></NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title={<span style={{color:'black',fontSize:"13px"}}><b>Tests</b></span>} >
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/add-test"><span style={{fontSize:"13px"}}><b>Add Tests</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/search-tests"><span style={{fontSize:"13px"}}><b>Search Tests</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/assessment-evalution"><span style={{fontSize:"13px"}}><b>Assessment Evaluation</b></span></NavDropdown.Item>
          </NavDropdown>

           <NavDropdown title={<span style={{color:'black',fontSize:"13px"}}><b>Agreements</b></span>} >
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/employment-agreement"><span style={{fontSize:"13px"}}><b>Employment Agreements</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/employment-agreement-history"><span style={{fontSize:"13px"}}><b>Employment Agreement History</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/recruitment-agreement"><span style={{fontSize:"13px"}}><b>Recruitment Agreement</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/recruitment-agreement-history"><span style={{fontSize:"13px"}}><b>Recruitment Agreement History</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/candidate-agreement"><span style={{fontSize:"13px"}}><b>Candidate Agreement</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/candidate-agreement-history"><span style={{fontSize:"13px"}}><b>Candidate Agreement History</b></span></NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title={<span style={{color:'black',fontSize:"13px"}}><b>Wish List</b></span>} >
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/training-wishes"><span style={{fontSize:"13px"}}><b>Training Wishes</b></span></NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/job-wishes"><span style={{fontSize:"13px"}}><b>Job Wishes</b></span></NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title={<span style={{color:'black',fontSize:"13px"}}><b>Notifications</b></span>} >
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/notifications"><span style={{fontSize:"13px"}}><b>Notifications</b></span></NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title={<span style={{color:'black',fontSize:"13px"}}><b>Messages</b></span>} >
            <NavDropdown.Item onClick={()=>this.setUserId(userLoginDetails.user_id)} href="/gts/messages"><span style={{fontSize:"13px"}}><b>Messages</b></span></NavDropdown.Item>
          </NavDropdown>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{fontWeight: 'bold'}}>Current Role:</span>&nbsp;&nbsp; <span style={{fontWeight: 'bold', color: 'red'}}>{userLoginDetails.primary_role}</span>&nbsp;&nbsp;
          <SwitchRole/>
       </InputGroup>
       <div style={{borderBottomColor: 'black',borderBottomWidth: 3}} />
     </div>
   </div>
  )
}}
export default ServiceConsumerMenu;