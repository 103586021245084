import React from 'react';


// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
  ButtonGroup
} from 'reactstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ls from 'local-storage';
import { Card, CardBody, CardText, FormControl, FormLabel, FormCheck } from 'react-bootstrap';
import { endpoints_properties } from '../../properties/EndPointsProperties.js';
import { api_properties } from '../../properties/APIProperties.js';

var token=ls.get('token');
var env=ls.get('ENV');
var	userLoginDetails=ls.get('userLoginDetails');

var changePasswordURL="";



if(env=="LOCAL"){
	changePasswordURL=endpoints_properties.ENDPOINT_IDENTITY_LOCAL+api_properties.API_CHANGE_PASSWORD;
}
else{
	changePasswordURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_CHANGE_PASSWORD;
}


class ChangePasswordComponent extends React.Component {

  constructor(props) {
    super(props);
    console.log("****You are in Environment: *****:  "+env);

    this.state = {
      user: 0,
      gts_user_id: '',
      oldPassword: '',
      currentPassword: '',
      formIsValid: false,
      confirmPassword: '',
      changepassword:'',
      changepasswordError: '',
      changepasswordSuccess: '',
      buttonDisabled: false,
      message: '',
      isError: '',
      errors: {},
      responses: {},
      visible: false,
      validationResult: false
    }

  };

  inputOnChangeHandler = e => {
      this.setState({ [e.target.name]: e.target.value });
  };

  clearOldPasswordError = e => {
    let errors = this.state.errors;
    this.state.formIsValid = true;


   if (e.target.name == "oldPassword") {
      errors["oldPassword"] = "";
      this.setState({
        errors: errors,
        validationResult: true,
      });
      return this.state.formIsValid;
    }
  }

  clearcurrentPasswordError = e => {
    //let fields = this.state.fields;
    let errors = this.state.errors;
    this.state.formIsValid = true;


    if (e.target.name == "currentPassword") {
      errors["currentPassword"] = "";
      this.setState({
        errors: errors,
        validationResult: true
      });
      return this.state.formIsValid;
    }
  }

  clearConfirmPasswordError = e => {
    //let fields = this.state.fields;
    let errors = this.state.errors;
    this.state.formIsValid = true;

    if (e.target.name == "confirmPassword") {
      errors["confirmPassword"] = "";
      this.setState({
        errors: errors,
        validationResult : true
      });
      return this.state.formIsValid;
    }
  }

  validateOldPassword = e => {
    let errors = this.state.errors;
    this.state.formIsValid = true;
     errors["oldPassword"] = "";
    if (!this.state.oldPassword) {
      this.state.formIsValid = false;
      errors["oldPassword"] = "Please enter your current password.";
      this.setState({
        errors: errors,
        validationResult : false
      });
      return this.state.formIsValid;
    }
  }

  validateCurrentPassword = e => {
    let errors = this.state.errors;
    this.state.formIsValid = true;
    errors["currentPassword"] = "";

    if (!this.state.currentPassword) {
      this.state.formIsValid = false;
      errors["currentPassword"] = "Please enter your new password.";
      this.setState({
        errors: errors,
        validationResult : false
      });
      return this.state.formIsValid;
    }

    if (typeof this.state.currentPassword !== "undefined") {
      if (!this.state.currentPassword.match(/^.*(?=.{8,20})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&+=*><?_~]).*$/)) {
        this.state.formIsValid = false;
        errors["currentPassword"] = "Please enter secure and strong password .";
        this.setState({
          errors: errors,
          validationResult : false
        });
        return this.state.formIsValid;
      }

      if (this.state.oldPassword == this.state.currentPassword ) {
        errors["currentPassword"] = "Current-Password & New-Password cannot be same.";
        this.setState({
          errors: errors,
          validationResult : false
        });
        return this.state.formIsValid;
      }
    }

  }

  validateConfirmPassword = e => {
    let errors = this.state.errors;
    this.state.formIsValid = true;

    if (!this.state.confirmPassword) {
      this.state.formIsValid = false;
      errors["confirmPassword"] = "Please confirm your password.";
      this.setState({
        errors: errors,
        validationResult : false
      });
      return this.state.formIsValid;
    }

    if (typeof this.state.confirmPassword !== "undefined") {
      if (!this.state.confirmPassword.match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&+=*><?_~]).*$/)) {
        this.state.formIsValid = false;
        errors["confirmPassword"] = "Please enter secure and strong password .";
        this.setState({
          errors: errors,
          validationResult : false
        });
        return this.state.formIsValid;
      }
      if (this.state.currentPassword != this.state.confirmPassword) {
        errors["confirmPassword"] = "Current-Password & Confirm-Password must be same.";
        this.setState({
          errors: errors,
          validationResult : false
        });
        return this.state.formIsValid;
      }
    }
  }

  ChangePassword=(e)=> {
    e.preventDefault();

    console.log("this.state.responses: "+this.state.responses);
    console.log("this.state.validationResult: "+this.state.validationResult);

    let responses = this.state.responses;


    if (this.state.validationResult == true) {
      let fields = this.state.fields;
      var dataResponse='';
      var url=changePasswordURL;


       var payload = {
        "gts_user_id": userLoginDetails.user_id,
        "gts_user_current_password": this.state.oldPassword,
        "gts_user_new_password": this.state.currentPassword
       };

      console.log("userLoginDetails.user_id: "+userLoginDetails.user_id);
      console.log("this.state.oldPassword: "+this.state.oldPassword);
      console.log("this.state.currentPassword: "+this.state.currentPassword);
      console.log("url: "+url);

       axios.put(url, payload, {headers: { Auth_Token: `Bearer ${token}`}})
        .then((response) => {
          if(response.data.status_code == 200){
            this.setState({success:"Password Changed Successfully, you will be logged out in few seconds!"})
            window.setTimeout(function() {
              window.location.href = '/gts/login';
          },10000);
          }
          else if(response.data.status_code == 403){
              this.setState({ requestFailed: true })
              this.setState({error:"SOMETHING WENT WRONG! PLEASE CONTACT THE ADMINSTRATION."})
          }
        })
        .catch((error) =>{
          if(!error.response){
            this.setState({error:"SOMETHING WENT WRONG! PLEASE CONTACT THE ADMINSTRATION."})
          }
         else if(error.response.data.status_code ==400 ||error.response.data.status_code ==404){
          this.setState({error:error.response.data.message})
          }
          else{
            this.setState({error:"SOMETHING WENT WRONG! PLEASE CONTACT THE ADMINSTRATION."})
          }
        })
     }
 }

 clearMessage = (e)=>{
   this.setState({error:''})
   this.setState({success:''})
 }

    render() {

    const errorMessageStyles = {
      //backgroundColor: "#f0f",
      fontWeight: 'bold',
      fontSize: 15,
      color: "#ff4d4d",
      //padding: paddings
    }

    const successMessageStyles = {
      //backgroundColor: "#f0f",
      fontWeight: 'bold',
      fontSize: 15,
      color: "#008000",
      //padding: paddings
    }

    return (
      <>
        {/* <DemoNavbar /> */}
        <main ref="main">
          <section className="section section-shaped section-lg">
              <div>
             <Container>

             <br/>
             <Row className="justify-content-center mt-6 mb-5">

                    <Card border="primary" style={{ width: '50rem' }}>
                      <Card.Body>
                        <Form name="handler" onSubmit={this.onSubmitform}>

                          <div class="row">
                            <div class="col">
                          <FormGroup className="mt-3" controlId="formBasicPassword">
                            <FormLabel>Enter Current Password</FormLabel>
                            <InputGroup>

                              <Input
                                type="password"
                                name="oldPassword"
                                id="oldPassword"
                                className="form-control"
                                placeholder="**********"
                                value={this.state.password}
                                onChange={this.inputOnChangeHandler}
                                onBlur={this.validateOldPassword}
                                onFocus={this.clearOldPasswordError}
                              />
                            </InputGroup>
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <div divID="oldPasswordError" style={errorMessageStyles}>{this.state.errors.oldPassword}</div>
                          </FormGroup>

                          <FormGroup className="mb-3" controlId="formBasicPassword">
                            <FormLabel>Enter New Password</FormLabel>
                            <InputGroup>
                              <Input
                                type="password"
                                name="currentPassword"
                                id="currentPassword"
                                className="form-control"
                                placeholder="New Password"
                                value={this.state.currentPassword}
                                onChange={this.inputOnChangeHandler}
                                onBlur={this.validateCurrentPassword}
                                onFocus={this.clearcurrentPasswordError}
                              />
                            </InputGroup>
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <div divID="currentPasswordError" style={errorMessageStyles}>{this.state.errors.currentPassword}</div>
                          </FormGroup>


                          <FormGroup className="mb-3" controlId="formBasicPassword">
                            <FormLabel>Re-enter New Password</FormLabel>
                            <InputGroup>
                              <Input
                                type="password"
                                name="confirmPassword"
                                id="confirmPassword"
                                className="form-control"
                                placeholder="Confirm Password"
                                value={this.state.confirm_password}
                                onChange={this.inputOnChangeHandler}
                                onBlur={this.validateConfirmPassword}
                                onFocus={this.clearConfirmPasswordError}
                              />
                            </InputGroup>
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <div divID="confirmPasswordError" style={errorMessageStyles}>{this.state.errors.confirmPassword}</div>
                          </FormGroup>

                          <Button className="mt-4" color="primary" type="submit" style={{ borderRadius: "8px" }} onClick={this.ChangePassword} onBlur={this.clearMessage}>
                             Change Password
                          </Button>
                          </div>

                        <div class="col offset-2" style={{paddingTop:"15px"}}>
                          <Card border="primary" style={{ width: '15rem' }}>
                        <Card.Body style={{color:'gray'}}>
                          After changing the password successfully, you will be logged out and have to login again with the new passowrd.
                          <br/><br/>
                          A confirmation email will be sent to inform that you have changed your password.
                        </Card.Body>
                        </Card>
                    </div>
                    </div>
                    <FormGroup className="mb-3">
                            <div style={errorMessageStyles}>{this.state.error}</div>
                          </FormGroup>

                          <FormGroup className="mb-3">
                            <div style={successMessageStyles}>{this.state.success}</div>
                          </FormGroup>

                         </Form>
                        </Card.Body>
                      </Card>

                  </Row>
                  </Container>
</div>

          </section>
        </main>
      </>
    );
  }
}
export default ChangePasswordComponent;