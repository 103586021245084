import React from 'react';
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';
import ProductsComponent from '../../components/common/ProductsComponent';


class ProductsPage extends React.Component{
    render(){
        return(
            <>
                <Header />
                <ProductsComponent />
                <Footer />
            </>
        )
    }

}
export default ProductsPage;