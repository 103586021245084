import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faTrash,
  faEdit,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import ls from "local-storage";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { Button } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { endpoints_properties } from "../../properties/EndPointsProperties.js";
import { api_properties } from "../../properties/APIProperties.js";
//import Space from 'react-spaces';

var env=ls.get('ENV');
var token=ls.get('token');
var userLoginDetails = ls.get("userLoginDetails");
var getUsertLanguagesURL="";
var activeLanguagesURL="";
var deleteUserLanguagesURL="";
var addEditUsertLanguagesURL="";


if(env=="LOCAL"){
	getUsertLanguagesURL=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_USER_LANGUAGES;
	activeLanguagesURL=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_LANGUAGES_ACTIVE;
	addEditUsertLanguagesURL=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_USER_LANGUAGES;
	deleteUserLanguagesURL=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_DELETE_USER_LANGUAGES;
}
else{
	getUsertLanguagesURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_USER_LANGUAGES;
	activeLanguagesURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_LANGUAGES_ACTIVE;
	addEditUsertLanguagesURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_USER_LANGUAGES;
	deleteUserLanguagesURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_DELETE_USER_LANGUAGES;

}

class LanguagesKnown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      items: [],
      userLanguages: [],
      reponse: {},
      userLanguageExisting: false,
      disableInput: true,
      language: "",
      jobsString: "",
      Proficiency: "",
      read: "",
      write: "",
      speak: "",
      inputError: "",
      inputSuccess: "",
      buttonDisabled: false,
      errorMessage: "",
      successMessage: "",
      updatedMessage: "",
      isError: "",
      errors: {},
      responses: {},
      visible: false,
      languageName: [],
      selectedLang: [],
      selectedLanguageName: "",
      langRows: [],
      selectedValue: {
        gts_language_id: "",
        gts_user_language_can_read: "",
        gts_user_language_can_write: "",
        gts_user_language_can_speak: "",
        gts_user_proficiency: "",
      },
      //selectedValues: [],
      languages: [
        {
          gts_language_id: 0,
          gts_language_name: null,
          gts_language_description: null,
          gts_language_status: false,
        },
      ],
      selectedLanguages: [],
      isChanged: false,
    };
  }


  inputOnChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };


  clearError = (e) => {
    this.setState({
      successMessage: "",
      errorMessage: ""
    })
  };

  componentDidMount() {
    // this.createLangRow();


    axios
      .get(
        getUsertLanguagesURL+'/'+userLoginDetails.user_id,
        { headers: { Auth_Token: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log(response.data);
        this.setState({
          userLanguages: response.data,
        });
        if (response.data.length !== 0) {
          this.setState({
            userLanguageExisting: true,
          });
        } else {
          this.setState({
            userLanguages: [],
          });
        }

      });
  }

  langName = (e, value) => {
    let langId;
    let selectedLanguageName = this.state.selectedLanguageName;
    this.setState({
      gts_language_name: value,
	  errorMessage: "",
	  successMessage: ""
    });
    this.setState({
      lName: value,
    });
    this.state.languages.forEach((langObj) => {
      if (langObj.gts_language_name === value) {
        langId = langObj.gts_language_id;
        this.state.selectedLanguageName = langObj.gts_language_name;
        this.setState({
          selectedLanguageName: selectedLanguageName,
        });
      }
    });
    this.state.selectedValue.gts_language_id = langId;
    // console.log(temObj);
    console.log(
      `Language id = ${langId} of ${this.state.selectedLanguageName}`
    );
  };

  proficiency = (e) => {
    //console.log(e.target.value);
    this.state.selectedValue.gts_user_proficiency = e.target.value;
  };

  read = (e) => {
    //console.log(e.target.value);
    if (e.target.value === "Yes") {
      this.state.selectedValue.gts_user_language_can_read = 1;
    } else {
      this.state.selectedValue.gts_user_language_can_read = 0;
    }
  };

  write = (e) => {
    //console.log(e.target.value);
    if (e.target.value === "Yes") {
      this.state.selectedValue.gts_user_language_can_write = 1;
    } else {
      this.state.selectedValue.gts_user_language_can_write = 0;
    }
  };

  speak = (e) => {
    if (e.target.value === "Yes") {
      this.state.selectedValue.gts_user_language_can_speak = 1;
    } else {
      this.state.selectedValue.gts_user_language_can_speak = 0;
    }
  };

  selectedValue = (value) => {
    if (value == "Yes") return 1;
    if (value == "No") return 0;
  };

  addLanguageRow = () => {
    // let addRow = () =>{
    //   this.addLanguageRowValues(this.state.selectedValue);
    // }
    this.setState({
      errorMessage: "",
      successMessage: "",
      updatedMessage: "",
    });
    return (
      <div className="container align-items-center">
        <div className="row">
          <div className="col" style={{ width: "30%" }}>
            <Autocomplete
              options={this.state.languageName}
              onChange={this.langName}
              // defaultValue={this.state.gts_language_name}
              filterSelectedOptions
              renderInput={(params) => {
                this.autoCompleteChangeHandler(params);
                return (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Languages"
                  />
                );
              }}
            />
          </div>
          <div className="col">
            <select className="selectionButton" onChange={this.proficiency} onFocus={this.clearError}>
              <option value="Beginner">Beginner</option>
              <option value="Intermediate">Intermediate</option>
              <option value="Expert">Expert</option>
              <option selected disabled hidden>
                Select
              </option>
            </select>
          </div>
          <div className="col">
            <select className="selectionButton" onChange={this.read} onFocus={this.clearError}>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
              <option selected disabled hidden>
                Select
              </option>
            </select>
          </div>
          <div className="col">
            <select className="selectionButton" onChange={this.write} onFocus={this.clearError}>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
              <option selected disabled hidden>
                Select
              </option>
            </select>
          </div>
          <div className="col">
            <select className="selectionButton" onChange={this.speak} onFocus={this.clearError}>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
              <option selected disabled hidden>
                Select
              </option>
            </select>
          </div>
          <div class="col">
            <button>
              <i class="fas fa-trash" onClick={this.deleteLangRow}></i>
            </button>
            {/* <button style={{paddingLeft:"10px", display: this.state.selectedLanguageName!=="" ? 'block': 'none'}}><i class="fas fa-edit" onClick={this.enableInput}></i></button> */}
            <button
              className="btn btn-primary btn-sm"
              style={{ paddingLeft: "10px" }}
              onClick={this.saveUserLanguage}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  };

  autoCompleteChangeHandler = (input) => {
    axios
      .get(activeLanguagesURL,
        {
          headers: { Auth_Token: `Bearer ${token}` },
        }
      )
      .then((response) => {
        //console.log(response.data);
        this.state.languages = response.data;

        if (this.state.languageName.length != 0) this.state.languageName = [];
        this.state.languages.forEach((languages) => {
          this.state.languageName.push(languages.gts_language_name);
        });
        //console.log(this.state.languageName);
      })
      .catch((error) => {
        if (axios.isCancel(error) || error) {
          this.setState({
            loading: false,
            // #\errorMessage: "Failed to fetch the data",
          });
          console.log(error);
        }
      });
  };

  createLangRow = () => {
    console.log();
    let tempLangRows = this.state.langRows;
    tempLangRows.push(this.addLanguageRow());

    this.setState({
      langRows: tempLangRows,
      successMessage: "",
      errorMessage: "",
      updatedMessage: "",
    });
  };

  deleteLangRow = () => {
    const langRows = this.state.langRows;
    //console.log(`rows poped `);
    langRows.pop();
    this.setState({
      langRows: langRows,
	  errorMessage: "",
	  successMessage: ""
    });
  };

  createPostPayload = (selectedLanguages) => {
    var postPayLoad = [];
    let userid = { gts_user_id: userLoginDetails.user_id };

    selectedLanguages.forEach((element) => {
      postPayLoad.push({ ...userid, ...element });
    });

    return postPayLoad;
  };

  // createPutPayload = (updateLanguageId, langArray) => {
  //   var putPayLoad = [];
  //   let userid = { gts_user_id: userLoginDetails.user_id };
  //   let userLanguageId = { gts_user_language_id: updateLanguageId };

  //   langArray.forEach((element) => {
  //     putPayLoad.push({...userid, ...userLanguageId, ...element });
  //     console.log(element);
  //   });

  //   console.log(putPayLoad);

  //   return putPayLoad;
  // };

  saveUserLanguage = () => {
    let responses = this.state.responses;

    this.addLanguageRowValues(this.state.selectedValue);

    var url = addEditUsertLanguagesURL;
    //console.log(this.state.selectedLanguages);

    let postPayload = this.createPostPayload(this.state.selectedLanguages);
    console.log(postPayload);

    axios
      .post(url, postPayload, { headers: { Auth_Token: `Bearer ${token}` } })
      .then((response) => {
        this.setState({
          gts_user_language_id: response.data.gts_user_language_id,
        });
        if (response.data.success === "True") {
          this.setState({
            successMessage: "Language Added Successfully",
          });
          this.componentDidMount();
          this.deleteLangRow();
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);
        this.setState({
          errorMessage: error.response.data.message
        })
      });
  };

  updateUserLanguage = (userLanguages) => {

    if (this.state.selectedValue.gts_user_language_can_read === "") {
      this.state.selectedValue.gts_user_language_can_read =
        userLanguages.gts_user_language_can_read;
    }
    if (this.state.selectedValue.gts_user_language_can_speak === "") {
      this.state.selectedValue.gts_user_language_can_speak =
        userLanguages.gts_user_language_can_speak;
    }
    if (this.state.selectedValue.gts_user_language_can_write === "") {
      this.state.selectedValue.gts_user_language_can_write =
        userLanguages.gts_user_language_can_write;
    }
    if (this.state.selectedValue.gts_user_proficiency === "") {
      this.state.selectedValue.gts_user_proficiency =
        userLanguages.gts_user_proficiency;
    }
    let putPayload = [
      {
        gts_user_language_id: userLanguages.gts_user_language_id,
        gts_user_id: userLoginDetails.user_id,
        gts_language_id: userLanguages.gts_language_id,
        gts_user_language_can_read:
          this.state.selectedValue.gts_user_language_can_read,
        gts_user_language_can_write:
          this.state.selectedValue.gts_user_language_can_write,
        gts_user_language_can_speak:
          this.state.selectedValue.gts_user_language_can_speak,
        gts_user_proficiency: this.state.selectedValue.gts_user_proficiency,
      },
    ];

    var url = addEditUsertLanguagesURL;

    axios
      .put(url, putPayload, { headers: { Auth_Token: `Bearer ${token}` } })
      .then((response) => {
        console.log(response.data);
        this.setState({
          isChanged: true,
        });
        if (response.data.status_code === "200") {
          this.setState({
            updatedMessage: "Language Updated Successfully",
          });

          this.state.selectedValue.gts_user_proficiency = "";
          this.state.selectedValue.gts_user_language_can_read = "";
          this.state.selectedValue.gts_user_language_can_write = "";
          this.state.selectedValue.gts_user_language_can_speak = "";

          axios
            .get(
              getUsertLanguagesURL +'/'+
                userLoginDetails.user_id,
              { headers: { Auth_Token: `Bearer ${token}` } }
            )
            .then((response) => {
              //console.log(response.data);

              if (response.data.length == 0) {
                this.setState({
                  userLanguages: [],
                });
              } else {
                this.setState({
                  userLanguages: response.data,
                  userLanguageExisting: true,
                });
              }
            });
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  addLanguageRowValues = (selectedLangObj) => {
    this.state.selectedLanguages.push(selectedLangObj);
    console.log(this.state.selectedLanguages);
  };

  deleteSavedLanguageRow(userLanguageId) {
    axios
      .delete(deleteUserLanguagesURL +
          userLanguageId,
        { headers: { Auth_Token: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log(response.data);
        this.setState({
          userLanguages: [],
          successMessage: "Language deleted successfully",
        });
        axios
            .get(
              getUsertLanguagesURL +'/'+
                userLoginDetails.user_id,
              { headers: { Auth_Token: `Bearer ${token}` } }
            )
            .then((response) => {
              //console.log(response.data);

              if (response.data.length == 0) {
                this.setState({
                  userLanguages: [],
                });
              } else {
                this.setState({
                  userLanguages: response.data,
                  userLanguageExisting: true,
                });
              }
            });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  cancelHandler = () => {
        this.setState({
          successMessage: "",
          errorMessage: "",
          updatedMessage: "",
        });
  };

  render() {
    const errorMessageStyles = {
      //backgroundColor: "#f0f",
      //fontSize: someSize,
      color: "#ff4d4d",
      //padding: paddings
    };
    const { error, items } = this.state;
    //console.log(items);

    return (
      <div className="container align-items-center">
        <div className="container align-items-center">
          <div className="mt-6  mb-6">
            <div className="border border-dark rounded-lg">
              <div className="container">
                <div class="row">
                  <div class="col" style={{ width: "40%" }}>
                    <label>
                      <h6 style={{ fontSize: "18px" }}>
                        <b>User Languages</b>
                      </h6>
                    </label>
                  </div>
                  <div class="col">
                    <label>
                      <h6 style={{ fontSize: "18px" }}>Proficiency </h6>
                    </label>
                  </div>
                  <div class="col">
                    <label>
                      <h6 style={{ fontSize: "18px" }}>Read </h6>
                    </label>
                  </div>
                  <div class="col">
                    <label>
                      <h6 style={{ fontSize: "18px" }}>Write </h6>
                    </label>
                  </div>
                  <div class="col">
                    <label>
                      <h6 style={{ fontSize: "18px" }}>Speak</h6>
                    </label>
                  </div>
                  <div class="col">
                    <button
                      type="button"
                      style={{
                        width: "160px",
                        height: "35px",
                        align: "center",
                        display: "block",

                      }}
                      className="btn btn-primary float-right"
                      onClick={this.createLangRow}
                    >
                      <h6 style={{ color: "white" }}>Add Language</h6>
                    </button>
                  </div>
                </div>
              </div>
              <div
                style={{ borderBottomColor: "black", borderBottomWidth: 1.5 }}
              />
              {/* <div className="text-center font-bold" style={{color:"green"}}>{this.state.message}</div> */}
              <br />
              <div
                divID="message"
                className="text-center font-bold"
                style={successMessageStyles}
              >
                {this.state.successMessage}
              </div>
              <div
                divID="message"
                className="text-center font-bold"
                style={errorMessageStyles}
              >
                {this.state.errorMessage}
              </div>
              <div className="container">{this.state.langRows}</div>

              {this.state.userLanguages.map((item) => (
                <div>
                  <div className="row">
                    <div className="col" style={{ paddingLeft: "25px" }}>
                      <Autocomplete
                        options={this.state.languageName}
                        onChange={this.langName}
                        value={item.gts_language_name}
                        defaultValue={
                          this.state.isChanged === false
                            ? item.gts_language_name
                            : this.state.lName
                        }
                        disabled={
                          this.state.userLanguageExisting &&
                          this.state.disableInput
                            ? "disableInput"
                            : ""
                        }
                        filterSelectedOptions
                        renderInput={(params) => {
                          this.autoCompleteChangeHandler(params);
                          return (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Languages"
                            />
                          );
                        }}
                      />
                    </div>
                    <div className="col">
                      <select
                        className="selectionButton"
                        onChange={this.proficiency}
                        disabled={
                          this.state.userLanguageExisting &&
                          this.state.disableInput
                            ? "disableInput"
                            : ""
                        }
                        style={{
                          color:
                            this.state.userLanguageExisting &&
                            this.state.disableInput
                              ? "grey"
                              : "black",
                        }}
                      >
                        <option value="present">
                          {item.gts_user_proficiency}
                        </option>
                        <option value="Beginner">Beginner</option>
                        <option value="Intermediate">Intermediate</option>
                        <option value="Expert">Expert</option>
                        {/* <option selected value="select">Select</option> */}
                      </select>
                    </div>
                    <div className="col">
                      <select
                        className="selectionButton"
                        onChange={this.read}
                        disabled={
                          this.state.userLanguageExisting &&
                          this.state.disableInput
                            ? "disableInput"
                            : ""
                        }
                        style={{
                          color:
                            this.state.userLanguageExisting &&
                            this.state.disableInput
                              ? "grey"
                              : "black",
                        }}
                      >
                        <option value="present">
                          {item.gts_user_language_can_read == true
                            ? "Yes"
                            : "No"}
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        {/* <option selected value="select">Select</option> */}
                      </select>
                    </div>
                    <div className="col">
                      <select
                        className="selectionButton"
                        onChange={this.write}
                        disabled={
                          this.state.userLanguageExisting &&
                          this.state.disableInput
                            ? "disableInput"
                            : ""
                        }
                        style={{
                          color:
                            this.state.userLanguageExisting &&
                            this.state.disableInput
                              ? "grey"
                              : "black",
                        }}
                      >
                        <option value="present">
                          {item.gts_user_language_can_write == true
                            ? "Yes"
                            : "No"}
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        {/* <option selected value="select">Select</option> */}
                      </select>
                    </div>
                    <div className="col">
                      <select
                        className="selectionButton"
                        onChange={this.speak}
                        disabled={
                          this.state.userLanguageExisting &&
                          this.state.disableInput
                            ? "disableInput"
                            : ""
                        }
                        style={{
                          color:
                            this.state.userLanguageExisting &&
                            this.state.disableInput
                              ? "grey"
                              : "black",
                        }}
                      >
                        <option value="present">
                          {item.gts_user_language_can_speak == true
                            ? "Yes"
                            : "No"}
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        {/* <option selected value="select">Select</option> */}
                      </select>
                    </div>
                    <div class="col">
                      <button
                        style={{
                          display:
                            ls.get("gts_user_id") != userLoginDetails.user_id
                              ? "none"
                              : "inline",
                        }}
                      >
                        <i
                          class="fas fa-trash"
                          onClick={() =>
                            this.deleteSavedLanguageRow(
                              item.gts_user_language_id
                            )
                          }
                        ></i>
                      </button>
                      <button
                        style={{
                          paddingLeft: "10px",
                          display:
                            ls.get("gts_user_id") != userLoginDetails.user_id
                              ? "none"
                              : "inline",
                        }}
                        type="button"
                        class="btn float"
                        href="#"
                        data-toggle="modal"
                        data-target={
                          "#editLanguageDetails" + item.gts_user_language_id
                        }
                      >
                        <i class="fas fa-edit"></i>
                      </button>
                      {/* <button
                        className="btn btn-primary btn-sm"
                        style={{ paddingLeft: "10px" }}
                        onClick={() =>
                          this.updateUserLanguage(item.gts_user_language_id)
                        }
                      >
                        Save
                      </button> */}
                    </div>
                  </div>
                  {/* ----------------------------Modal dialog---------------------------- */}
                  <div
                    id={"editLanguageDetails" + item.gts_user_language_id}
                    class="modal fade"
                    role="dialog"
                  >
                    <div class="modal-dialog modal-lg">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h6 class="modal-title">Update User Language</h6>
                        </div>
                        <div class="modal-body">
                          <div class="row">
                            <div class="col-4">Language Name :</div>
                            <div class="col-8">
                              {item.gts_language_name}
                              {/* <Autocomplete
                                options={this.state.languageName}
                                onChange={this.langName}
                                defaultValue={item.gts_language_name}
                                filterSelectedOptions
                                renderInput={(params) => {
                                  this.autoCompleteChangeHandler(params);
                                  return (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      placeholder="Languages"
                                    />
                                  );
                                }}
							                  /> */}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-4">Proficiency :</div>
                            <div class="col-8">
                              <select
                                className="selectionButton"
                                onChange={this.proficiency}
                              >
                                <option value="present">
                                  {item.gts_user_proficiency}
                                </option>
                                <option value="Beginner">Beginner</option>
                                <option value="Intermediate">
                                  Intermediate
                                </option>
                                <option value="Expert">Expert</option>
                                {/* <option selected value="select">Select</option> */}
                              </select>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-4">Read :</div>
                            <div class="col-8">
                              <select
                                className="selectionButton"
                                onChange={this.read}
                              >
                                <option value="present">
                                  {item.gts_user_language_can_read == true
                                    ? "Yes"
                                    : "No"}
                                </option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                                {/* <option selected value="select">Select</option> */}
                              </select>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-4">Write :</div>
                            <div class="col-8">
                              <select
                                className="selectionButton"
                                onChange={this.write}
                              >
                                <option value="present">
                                  {item.gts_user_language_can_write == true
                                    ? "Yes"
                                    : "No"}
                                </option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                                {/* <option selected value="select">Select</option> */}
                              </select>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-4">Speak :</div>
                            <div class="col-8">
                              <select
                                className="selectionButton"
                                onChange={this.speak}
                              >
                                <option value="present">
                                  {item.gts_user_language_can_speak == true
                                    ? "Yes"
                                    : "No"}
                                </option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                                {/* <option selected value="select">Select</option> */}
                              </select>
                            </div>
                          </div>
                          <div></div>
                          <div
                            divID="message"
                            className="text-center font-bold"
                            style={successMessageStyles}
                          >
                            {this.state.updatedMessage}
                          </div>
                          <div className="text-right">
                            <button
                              className="btn btn-primary btn-sm"
                              style={{ paddingLeft: "10px" }}
                              onClick={() => this.updateUserLanguage(item)}
                            >
                              Update
                            </button>

                            <button
                              className="btn btn-primary btn-sm"
                              data-dismiss="modal"
                              onClick={this.cancelHandler}
                              onBlur={this.cancelHandler}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              {/* <div className="container text-right">
                      <button className="btn btn-primary btn-sm" onClick={this.saveUserLanguage}>Save</button>
                    </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const bgImage = {
  backgroundSize: "contain",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
};
const successMessageStyles = {
  //backgroundColor: "#f0f",
  //fontSize: someSize,
  color: "#008000",
  //padding: paddings
  marginLeft: "-25%",
};
export default LanguagesKnown;
