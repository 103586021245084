import React from 'react';
import ReactLoading from "react-loading";
import axiosRetry from 'axios-retry';
import ls from 'local-storage';
import { endpoints_properties } from '../../properties/EndPointsProperties.js';
import { api_properties } from '../../properties/APIProperties.js';
// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert,
  ButtonGroup
} from 'reactstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Card, CardBody, CardText, FormControl, FormLabel, FormCheck } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignRight, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import "./../../css/tooltip.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

class RegistrationComponent extends React.Component {
  constructor(props) {
	ls.set('ENV', "PROD");
    super(props);
    this.state = {
      user: 0,
      isServiceProvider: false,
      isServiceConsumer: false,
      isTrainer: false,
      isTrainee: false,
      defaultRole: 'none',
      gts_user_id: '',
      gts_user_primary_email: '',
      password: '',
      role: '',
      confirmPassword: '',
      registrationError: '',
      registrationSuccess: '',
      buttonDisabled: false,
      message: '',
      isError: '',
      errors: {},
      responses: {},
      visible: false,
      roleError: '',
      defaultRoleError: '',
      roleSuccess: '',
      callInProgress:false
    };
    this.onRadioChange = this.onRadioChange.bind(this);
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  onRadioChange = (e) => {
    if (e.target.value == "ServiceProvider") {
      this.setState({
        defaultRole: e.target.value,
        isServiceProvider: true
      });
    }
    if (e.target.value == "ServiceConsumer") {
      this.setState({
        defaultRole: e.target.value,
        isServiceConsumer: true
      });
    }
    if (e.target.value == "Trainee") {
      this.setState({
        defaultRole: e.target.value,
        isTrainee: true
      });
    }
    if (e.target.value == "Trainer") {
      this.setState({
        defaultRole: e.target.value,
        isTrainer: true
      });
    }
  }
  //analyse why we need this?
  toggleChangeServiceProvider = () => {
    this.setState(prevState => ({
      isServiceProvider: !prevState.isServiceProvider
    }));
  }

  toggleChangeServiceConsumer = () => {
    this.setState(prevState => ({
      isServiceConsumer: !prevState.isServiceConsumer
    }));
  }

  toggleChangeTrainee = () => {
    this.setState(prevState => ({
      isTrainee: !prevState.isTrainee
    }));
  }

  toggleChangeTrainer = () => {
    this.setState(prevState => ({
      isTrainer: !prevState.isTrainer
    }));
  }

  onSubmit = (e) => {
    e.preventDefault();
    console.log(this.state);
  }

  inputOnChangeHandler = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  clearEmailError = e => {
    let responses = this.state.responses;
    let errors = this.state.errors;
    let formIsValid = true;

    //errors["gts_user_primary_email"] = "";
    this.setState({errors: errors});

    if (e.target.name == "registrationError") {
      responses["registrationError"] = "";
      this.setState({
        responses: responses
      });
      return formIsValid;
    }
  }

  clearPasswordError = e => {
    let responses = this.state.responses;
    let errors = this.state.errors;
    let formIsValid = true;


    if (e.target.name == "password") {
      errors["password"] = "";
      this.setState({
        errors: errors
      });
      return formIsValid;
    }

    if (e.target.name == "registrationError") {
      responses["registrationError"] = "";
      this.setState({
        responses: responses
      });
      return formIsValid;
    }
  }

  clearConfirmPasswordError = e => {
    let responses = this.state.responses;
    let errors = this.state.errors;
    let formIsValid = true;


    if (e.target.name == "confirmPassword") {
      errors["confirmPassword"] = "";
      this.setState({
        errors: errors
      });
      return formIsValid;
    }

    if (e.target.name == "registrationError") {
      responses["registrationError"] = "";
      this.setState({
        responses: responses
      });
      return formIsValid;
    }
  }

  validateEmail = e => {

	console.log("Validating Email.");
    let responses = this.state.responses;
    let errors = this.state.errors;
    let formIsValid = true;

    if (!this.state.gts_user_primary_email) {
      formIsValid = false;
      errors["gts_user_primary_email"] = "Please enter your Email.";
      this.setState({
        errors: errors
      });
      return formIsValid;
    }
	else if (typeof this.state.gts_user_primary_email !== "undefined") {
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

      if (!pattern.test(this.state.gts_user_primary_email)) {
        formIsValid = false;
        errors["gts_user_primary_email"] = "Please enter valid Email.";
      }
      this.setState({
        errors: errors
      });
      return formIsValid;
    }
  }

  validatePassword = e => {
    let errors = this.state.errors;
    let formIsValid = true;

    if (!this.state.password) {
      formIsValid = false;
      errors["password"] = "Please enter your password.";
      this.setState({
        errors: errors
      });
      return formIsValid;
    }

    if (typeof this.state.password !== "undefined") {
      if (!this.state.password.match(/^.*(?=.{8,20})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&+=*><?_~]).*$/)) {
        formIsValid = false;
        errors["password"] = "Please enter secure and strong password .";
        this.setState({
          errors: errors
        });
        return formIsValid;
      }
    }
  }

  validateConfirmPassword = e => {
    let errors = this.state.errors;
    let formIsValid = true;
    let responses = this.state.responses;

    if (!this.state.confirmPassword) {
      formIsValid = false;
      errors["confirmPassword"] = "Please confirm your  password.";
      this.setState({
        errors: errors
      });
      return formIsValid;
    }

    if (typeof this.state.confirmPassword !== "undefined") {
      if (!this.state.confirmPassword.match(/^.*(?=.{8,20})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&+=*><?_~]).*$/)) {
        formIsValid = false;
        errors["confirmPassword"] = "Please enter secure and strong password .";
        this.setState({
          errors: errors
        });
        return formIsValid;
      }

      if (this.state.password != this.state.confirmPassword) {
        errors["confirmPassword"] = "Password & Confirm-Password must be same.";
        this.setState({
          errors: errors
        });
        return formIsValid;
      }
    }
    if (e.target.name == "registrationError") {
      responses["registrationError"] = "";
      this.setState({
        responses: responses
      });
      return formIsValid;
    }
  }

  onSubmitHandler = e => {
	let errors = this.state.errors;
    e.preventDefault();
    var validationResultOfForm = this.formIsValid;

    let responses = this.state.responses;
    var url=""



	var env=ls.get('ENV');

	if(env=="LOCAL"){
		url = endpoints_properties.ENDPOINT_IDENTITY_LOCAL+api_properties.API_REGISTER;
	}
	else{
		url = endpoints_properties.ENDPOINT_PROD+api_properties.API_REGISTER;
	}

    var payload = {

      "gts_user_primary_email": this.state.gts_user_primary_email,
      "gts_user_password": this.state.password,
      "gts_user_status": true,
      "gts_user_login_tries": 0
    };



	 //axios.defaults.timeout = 29000;
	 axiosRetry(axios, {
		  retries: 3, // Number of retries (Defaults to 3)
		  retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 10000),


		  retryCondition(error) {
			// Conditional check the error status code
		  if(error.message==="Network Error"){
			  return true;
		  }

		  },

	 });

    if(this.state.defaultRole !== 'none'){
      this.setState({roleErrors : ""});

      this.setState({callInProgress: true});

       axios.post(url, payload)
      .then(response => {
        this.setState({
          gts_user_id: response.data.gts_user_id
        })

        if (validationResultOfForm == false) {
          responses["registrationError"] = response.data.message;
          responses["registrationSuccess"] = "";
          this.setState({
            responses: responses
          });
        }
        else {
          let user_id = this.state.gts_user_id;
          console.log(response.data)
          if(response.data.status_code == 400 || response.data.status_code == 404 ){
            responses["registrationError"] = response.data.message;
		}
          else{
            var ro = this.onSubmitHandlerRole(user_id, e);
            if (ro === true) {
			  this.setState({callInProgress: false});
              responses["registrationSuccess"] = "Registration is successful";
              responses["registrationError"] = "";
              this.setState({
                responses: responses
              });
              this.setState({
			          errors: ""
      		  });
            }
          }
        }
      })
      .catch(error => {
		this.setState({callInProgress: false});
        if(error.response){
          if(error.response.data.status_code == 400 || error.response.data.status_code == 404 ){
            responses["registrationError"] = error.response.data.message;
            this.setState({
              responses: responses
            });
          }
          else{
            responses["registrationError"] = "Error while Registering! Please Try again...";
              this.setState({
                responses: responses
              });
            }
          }
          else{
            responses["registrationError"] = "Error while Registering! Please Try again...";
            this.setState({
              responses: responses
            });
          }
      });
    }
    else{
      this.setState({roleErrors : "Please select atleast one role as default"})
    }
  };


  onSubmitHandlerRole(user_id, e) {
    let responses = this.state.responses;
    var arr = [];
    console.log("userId inside handler method: " + user_id);
    if (this.state.isServiceProvider === true) {
      arr.push(2);
    }
    if (this.state.isServiceConsumer === true) {
      arr.push(3);
    }
    if (this.state.isTrainee === true) {
      arr.push(6);
    }
    if (this.state.isTrainer === true) {
      arr.push(4);
    }

    if (arr.length === 0) {
      this.state.errors.roleError = "Please select atleast one role";
    }

    let listOfRoles = arr.toString();
    console.log("list of roles: " + listOfRoles);

    var defaultRole = 0
    if (this.state.defaultRole === "ServiceProvider") {
      defaultRole = 2;
    }
    else if (this.state.defaultRole === "ServiceConsumer") {
      defaultRole = 3;
    }
    else if (this.state.defaultRole === "Trainee") {
      defaultRole = 6;
    }
    else if (this.state.defaultRole === "Trainer") {
      defaultRole = 4;
    }

    if (defaultRole == 0) {
      this.state.errors.defaultRoleError = "Please select atleast one role as default";
    }

    e.preventDefault();
    var RoleValidationResult = arr.length>0 && defaultRole>0;




	var env=ls.get('ENV');
	var url2 = "";

	if(env=="LOCAL"){
		url2 = endpoints_properties.ENDPOINT_IDENTITY_LOCAL+ api_properties.API_ADD_ROLES;
	}
	else{
		url2 = endpoints_properties.ENDPOINT_PROD+ api_properties.API_ADD_ROLES;
	}

    var rolesPayload = [];

    for (var i = 0; i < arr.length; i++) {
      let role_id = arr[i];
      let isPrimary = 0;

      if (role_id === defaultRole) {
        isPrimary = 1;
      }

      var userRole = {
        "gts_role_id": role_id,
        "gts_user_role_is_primary": isPrimary
      };

      rolesPayload[i] = userRole;
      var userRoles = {
        "gts_user_id": user_id,
        "userRole":rolesPayload
      };
    }
    console.log("rolesPayload: " + JSON.stringify(rolesPayload));

    if(RoleValidationResult == true){
    axios
      .post(url2, userRoles)
      .then(response => {
        console.log(response.data);
        let message = response.message;
          return true;
      })
      .catch(error => {
		this.setState({callInProgress: false});
        console.log(error.response.data);
        responses["registrationError"] = "Not able to add roles.";
        this.setState({
          responses: responses
        });
        return false;
      });
      return true;
    }
    else{
      return false;
      responses["registrationError"] = "Not able to add roles.";
        this.setState({
          responses: responses
        });
    }
  };



  render() {

    const errorMessageStyles = {
      //backgroundColor: "#f0f",
      fontWeight: 'bold',
      fontSize: 18,
      color: "#ff4d4d",
      //padding: paddings
    }

    const successMessageStyles = {
      //backgroundColor: "#f0f",
      fontWeight: 'bold',
      fontSize: 18,
      color: "#008000",
      //padding: paddings
    }

    return (
      <>
        {/* <DemoNavbar /> */}
        <main ref="main">
          <section className="section section-shaped section-lg">


              <Container className="pt-lg-md">
					<Row className="justify-content-center mt-4">
						<Col className="col-6">
							<div className="pt-4">
								<h1 className="font-bold">Register Below</h1>
							</div>
						</Col>
					</Row>

					<Row className="justify-content-center mt-4">
						<Col className="col-10">

                   <Card border="primary" style={{ width: '35rem' }}>
                      <Card.Body>
                      	    <FormGroup className="mb-3" controlId="formBasicEmail">
                            <FormLabel><span style={{fontWeight: 'bold'}}>Select one/more roles and mark anyone as primary :</span></FormLabel>
				                      <form>
				                        <div class="row">
				                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				                          <div class="col">
				                            <label className="form-check-label">
				                              <input type="checkbox"
				                                checked={this.state.isServiceProvider}
				                                onChange={this.toggleChangeServiceProvider}
				                                className="form-check-input"
				                              />
				                                  &nbsp;Service Provider or Jobseeker
				                              </label>
				                          </div>
				                          <div class="col">
				                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				                            <label>
				                              <input type="radio" value="ServiceProvider"
				                                checked={this.state.defaultRole === "ServiceProvider"}
				                                onChange={this.onRadioChange}
				                              />
				                                  &nbsp;Primary
				                              </label>
				                          </div></div><br />


				                        <div class="row">
				                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				                          <div class="col">

				                            <label className="form-check-label">
				                              <input type="checkbox"
				                                checked={this.state.isServiceConsumer}
				                                onChange={this.toggleChangeServiceConsumer}
				                                className="form-check-input"
				                              />
				                                  &nbsp;Service Consumer or Employer
				                              </label>
				                          </div>
				                          <div class="col">
				                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				                            <label>
				                              <input type="radio" value="ServiceConsumer" checked={this.state.defaultRole === "ServiceConsumer"}
				                                onChange={this.onRadioChange}
				                              />
				                                  &nbsp;Primary
				                              </label>
				                          </div>
				                        </div><br />

				                        <div class="row">
				                        	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				                          <div class="col">

				                            <label className="form-check-label">
				                              <input type="checkbox"
				                                checked={this.state.isTrainee}
				                                onChange={this.toggleChangeTrainee}
				                                className="form-check-input"
				                              />
				                                  &nbsp;Trainee
				                              </label>
				                          </div>
				                          <div class="col">
				                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				                            <label>
				                              <input type="radio" value="Trainee" checked={this.state.defaultRole === "Trainee"}
				                                onChange={this.onRadioChange}
				                              />
				                                  &nbsp;Primary
				                              </label>
				                          </div>
				                        </div><br />


				                        <div class="row">
				                        	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				                          <div class="col">
				                            <label className="form-check-label">
				                              <input type="checkbox"
				                                checked={this.state.isTrainer}
				                                onChange={this.toggleChangeTrainer}
				                                className="form-check-input"

				                              />
				                                  &nbsp;Trainer
				                              </label>
				                          </div>
				                          <div class="col">
				                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				                            <label>
				                              <input type="radio" value="Trainer" checked={this.state.defaultRole === "Trainer"}
				                                onChange={this.onRadioChange}
				                              />
				                                  &nbsp;Primary
				                              </label>
				                          </div></div><br />
				                      </form>
				                      <FormGroup className="mb-3">
				                        <div divID="roleError" style={errorMessageStyles}>{this.state.errors.roleError}</div>
				                        <div divID="roleError" style={errorMessageStyles}>{this.state.roleErrors}</div>
				                        <div divID="defaultRoleError" style={errorMessageStyles}>{this.state.errors.defaultRoleError}</div>
				                      </FormGroup>
				                      <FormGroup className="mb-3">
				                        <div divID="roleSuccess" style={errorMessageStyles}>{this.state.errors.roleSuccess}</div>
				                      </FormGroup>
						</FormGroup>
                      </Card.Body>
                    </Card>

				                    </Col>
                   </Row>

                <Row className="justify-content-center mt-4">
                  <Col className="col-8">
                    <Card border="primary" style={{ width: '25rem' }}>
                      <Card.Body>
                        <Form method="post" name="handler" onSubmit={this.onSubmitHandler}>
                          <FormGroup className="mb-3" controlId="formBasicEmail">
                                                      <FormLabel>
							                          <span style={{fontWeight: 'bold'}}>Enter Email:</span>
                          </FormLabel>
                            <InputGroup>
                              <Input
                                type="text"
                                name="gts_user_primary_email"
                                id="gts_user_primary_email"
                                className="form-control"
                                placeholder="abc@gmail.com"
                                value={this.state.gts_user_primary_email}
                                onChange={this.inputOnChangeHandler}
                                onBlur={this.validateEmail}
                                onFocus={this.clearEmailError}
                              />
                            </InputGroup>
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <div divID="emailError" style={errorMessageStyles}>{this.state.errors.gts_user_primary_email}</div>
                          </FormGroup>

                          <FormGroup className="mb-3" controlId="formBasicPassword" style={{clear:"both"}} >
                            <FormLabel>Enter Password:</FormLabel>
                            <InputGroup>

                              <Input
                                type="password"
                                name="password"
                                id="password"
                                className="form-control"
                                placeholder="**********"
                                value={this.state.password}
                                onChange={this.inputOnChangeHandler}
                                onBlur={this.validatePassword}
                                onFocus={this.clearPasswordError}
                              />
                            </InputGroup>
                            <OverlayTrigger placement="bottom" overlay={
                              <Tooltip
                              className="mytooltip"
                              style={{backgroundColor:"white",border:"none"}}
                              >
                                <h6>Password must have:</h6>
                                <div style={{textAlign:"left",width:"95%",margin:"auto"}}>
                                    &#9913; At least <span style={{color:"red"}}>8</span> characters.<br/>
                                    &#9913; At least <span style={{color:"red"}}>one lower case</span> letter.<br/>
                                    &#9913; At least <span style={{color:"red"}}>one upper case</span> letter.<br/>
                                    &#9913; At least <span style={{color:"red"}}>one number</span>.<br/>
                                    &#9913; At least <span style={{color:"red"}}>one special character</span> &#160;&#160;&#160;&#160;like !@#$%^&#38;+=*&#62;&#60;?_~<br/>
                                </div>
                              </Tooltip>
                                  }
                            >
                                <FontAwesomeIcon icon={faInfoCircle} style={info} />
                            </OverlayTrigger>
                          </FormGroup>

                          <FormGroup className="mb-3">
                            <div divID="passwordError" style={errorMessageStyles}>{this.state.errors.password}</div>
                          </FormGroup>

                          <FormGroup className="mb-3" controlId="formBasicPassword">
                                                      <FormLabel>
							                          <span style={{fontWeight: 'bold'}}>Re-Enter Password:</span>
                          </FormLabel>
                            <InputGroup>
                              <Input
                                type="password"
                                name="confirmPassword"
                                id="confirmPassword"
                                className="form-control"
                                placeholder="Confirm Password"
                                value={this.state.confirm_password}
                                onChange={this.inputOnChangeHandler}
                                onBlur={this.validateConfirmPassword}
                                onFocus={this.clearConfirmPasswordError}
                              />
                            </InputGroup>
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <div divID="confirmPasswordError" style={errorMessageStyles}>{this.state.errors.confirmPassword}</div>
                          </FormGroup>

                          <FormGroup className="mb-3">
                            <div divID="registrationError" style={errorMessageStyles}>{this.state.responses.registrationError}</div>
                          </FormGroup>

                          <FormGroup className="mb-3">
                            <div divID="registrationSuccess" style={successMessageStyles}>{this.state.responses.registrationSuccess}</div>
                          </FormGroup>

						  {this.state.callInProgress?
						   	<div className="text-center">
						      <span style={{color:  "#228B22", fontWeight: 'bold'}}>Please wait, we are registering.</span>

								<ReactLoading type="bars" color="#0000FF" height={100} width={50}/>

                          	</div>
							: <div></div>
					     }

                          <div className="text-center">
                            <Button className="mt-4" color="primary" type="submit" style={{ borderRadius: "8px" }}
                            onBlur={this.clearEmailError}
                            onClick={this.validateEmail}
							onClick={this.validatePassword}
							onClick={this.validateConfirmPassword}
                            >

                              Register
                          </Button>
                          </div>
                        </Form>
                        <p style={{textAlign:"center"}}>OR</p>
                        <div className="text-center">
                          <ButtonGroup vertical>
                            <Button outline color="primary" style={{ borderRadius: "12px" }}><i class="fab fa-google fa-fw"></i>Join with Google</Button>
                            <Button outline color="primary" style={{ borderRadius: "12px" }}><i class="fab fa-facebook fa-fw"></i>Join with Facebook</Button>
                            <Button outline color="primary" style={{ borderRadius: "12px" }}><i class="fab fa-linkedin fa-fw"></i>Join with Linkedin</Button>
                          </ButtonGroup>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                   </Row>

					<Row className="justify-content-center mt-4">
						<Col className="col-6">
							<div className="pt-4">
								&nbsp;
							</div>
						</Col>
					</Row>

                </Container>

          </section>
        </main>

        {/* <SimpleFooter /> */}
      </>
    );
  }
}

const bgImage = {
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  //backgroundImage: `url(${backgroundImage})`
};

const info = {
  color: "lightblue",
  width: "10px",
  cursor: "pointer",
  float: "center"
};
export default RegistrationComponent;


