
import React, { Component } from 'react'
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import 'bootstrap/dist/css/bootstrap.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser,faTrash,faEdit,faUpload} from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import JobPostComponent from '../../components/common/JobPostComponent';
import ServiceConsumerMenu from '../../components/service_consumer/ServiceConsumerMenu';
import {
  Button,Alert,FormGroup,Form,Input,InputGroupAddon,InputGroupText,InputGroup,Row,Col,Container
} from 'reactstrap';
import { FormControl, FormLabel, Card, CardBody,CardText } from 'react-bootstrap';
import ls from 'local-storage';

var token = ls.get("token");

class ServiceConsumerServicePostPage extends Component {
   constructor(props) {
		if(token==null || token=="" || token=="undefined"){
		  window.location.href = '/gts/global-home-page';
		}
		super(props);
	}
  render()
  {
    return (
     <div>
        <Header/>
        <ServiceConsumerMenu/>
        <JobPostComponent/>
        <br></br>
        <br></br>
        <Footer />
     </div>
    )
  }
}
export default ServiceConsumerServicePostPage;
