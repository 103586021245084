import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import {
  NavbarBrand
} from 'reactstrap';

class TrainingForStudentsTraineesComponent extends React.Component {
  componentDidMount() {
   
  }
  

  render() {
    const content = {
      backgroundColor: "#3F3A3A",
      padding: "10px",
    };

    const titleStyle = {
      color: "#6EFF00",
      fontFamily: "Adobe Clean",
      fontSize: "75px",
      fontWeight: "bold"
    }

    const searchStyle = {
      color: "#DDFF00",
      fontFamily: "Marcellus SC",
      fontSize: "35px"
    }

    const searchBoxStyle = {
      backgroundColor: "#DEE272",
      height: "30px",
      width: "300px"
    }

    const bottonStyle = {
      borderRadius: "50px",
      borderStyle: "solid",
      borderColor: "#0CFF2B",
      borderWidth: "3px",
      backgroundColor: "#498F41",
      marginRight: "20px",
      marginBottom: "20px",
      height: "100px",
      width: "550px",
      padding: "50px"
    }

    const bottonTextStyle = {
      color: "#FF6F00",
      fontFamily: "Adobe Clean",
      fontSize: "30px",
      fontWeight: "bold"
    }

    return (
      <>
      <div style = {content}>

        <h1 style = {titleStyle}>
        Training Industries for Students
        </h1>


        <form>
        <label style = {searchStyle}>Search by service name ➼</label>
        <input style = {searchBoxStyle} placeholder="Service Name..."></input>
        </form>

        <br/>
        <br/>

        <NavbarBrand tag={Link} to="/gts/global-trainings">
        <Button variant="contained" style = {bottonStyle}><h2 style = {bottonTextStyle}>Information Technology(IT)</h2></Button>
        </NavbarBrand>

        <NavbarBrand tag={Link} to="/gts/global-trainings">
        <Button variant="contained" style = {bottonStyle}><h2 style = {bottonTextStyle}>Medical</h2></Button>
        </NavbarBrand>

        <NavbarBrand tag={Link} to="/gts/global-trainings">
        <Button variant="contained" style = {bottonStyle}><h2 style = {bottonTextStyle}>Cultivation</h2></Button>
        </NavbarBrand>
        
        <NavbarBrand tag={Link} to="/gts/global-trainings">
        <Button variant="contained" style = {bottonStyle}><h2 style = {bottonTextStyle}>Manufacturing</h2></Button>
        </NavbarBrand>

        <NavbarBrand tag={Link} to="/gts/global-trainings">
        <Button variant="contained" style = {bottonStyle}> <h2 style = {bottonTextStyle}>Home Maintenance</h2></Button>
        </NavbarBrand>

        <NavbarBrand tag={Link} to="/gts/global-trainings">
        <Button variant="contained" style = {bottonStyle}><h2 style = {bottonTextStyle}>Retail</h2></Button>
        </NavbarBrand>

        <NavbarBrand tag={Link} to="/gts/global-trainings">
        <Button variant="contained" style = {bottonStyle}><h2 style = {bottonTextStyle}>Fishing</h2></Button>
        </NavbarBrand>

        <NavbarBrand tag={Link} to="/gts/global-trainings">
        <Button variant="contained" style = {bottonStyle}><h2 style = {bottonTextStyle}>Education</h2></Button>
        </NavbarBrand>

        <NavbarBrand tag={Link} to="/gts/global-trainings">
        <Button variant="contained" style = {bottonStyle}><h2 style = {bottonTextStyle}>Sports</h2></Button>
        </NavbarBrand>

        <NavbarBrand tag={Link} to="/gts/global-trainings">
        <Button variant="contained" style = {bottonStyle}><h2 style = {bottonTextStyle}>Transport</h2></Button>
        </NavbarBrand>

        <NavbarBrand tag={Link} to="/gts/global-trainings">
        <Button variant="contained" style = {bottonStyle}><h2 style = {bottonTextStyle}>Human Care</h2></Button>
        </NavbarBrand>

        <NavbarBrand tag={Link} to="/gts/global-trainings">
        <Button variant="contained" style = {bottonStyle}><h2 style = {bottonTextStyle}>Call Center</h2></Button>
        </NavbarBrand>

        <NavbarBrand tag={Link} to="/gts/global-trainings">
        <Button variant="contained" style = {bottonStyle}><h2 style = {bottonTextStyle}>Hotel & Hospitality</h2></Button>
        </NavbarBrand>

        <NavbarBrand tag={Link} to="/gts/global-trainings">
        <Button variant="contained" style = {bottonStyle}><h2 style = {bottonTextStyle}>Daily Wages</h2></Button>
        </NavbarBrand>

        <NavbarBrand tag={Link} to="/gts/global-trainings">
        <Button variant="contained" style = {bottonStyle}><h2 style = {bottonTextStyle}>Politics</h2></Button>
        </NavbarBrand>

        <NavbarBrand tag={Link} to="/gts/global-trainings">
        <Button variant="contained" style = {bottonStyle}><h2 style = {bottonTextStyle}>Real Estate</h2></Button>
        </NavbarBrand>

        <br/>
        <br/>
        <br/>
        <br/>

      </div>
       
  
      </>
    );
  }
}


export default TrainingForStudentsTraineesComponent;

