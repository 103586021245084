import React from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import SCClosedServiceComponent from '../../components/common/SCClosedServiceComponent';
import ls from 'local-storage';
import ServiceConsumerMenu from '../../components/service_consumer/ServiceConsumerMenu';
import AdminMenu from '../../components/admin/AdminMenu';

var token = ls.get("token");
var userLoginDetails=ls.get('userLoginDetails');
class ServiceConsumerClosedServicePage extends React.Component {
   constructor(props) {
	   super(props);
		if(token==null || token=="" || token=="undefined"){
		  window.location.href = '/gts/global-home-page';
		}
	}
              render() {
                return (
                <div>

                        <Header />
                        {userLoginDetails.primary_role == "ADMIN"
                        ? <AdminMenu/> : ''}
                        {userLoginDetails.primary_role == "SERVICE_CONSUMER"
                        ? <ServiceConsumerMenu/> : ''}
                        <SCClosedServiceComponent/>
                        <Footer/>
                </div>
               )
        }
}


export default ServiceConsumerClosedServicePage;



