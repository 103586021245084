import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.css'
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import axios from "axios";
import ls from "local-storage";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import DropdownButton from 'react-bootstrap/DropdownButton'
import Moment from 'moment';
import ShowMoreText from 'react-show-more-text';
import { endpoints_properties } from '../../properties/EndPointsProperties.js';
import { api_properties } from '../../properties/APIProperties.js';

import {
  Container,
  Grid,
  Slider,
  Paper,
  FormLabel,
  Select,
  Input,
  Checkbox,
  ListItemText,
  MenuItem,
  FormControl,
  Chip,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { Button, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { Dropdown,InputGroup,Form } from "react-bootstrap";


var userPersonalDetails=ls.get('userPersonalDetails');
var userLoginDetails=ls.get('userLoginDetails');
var token=ls.get('token');
var gts_user_id = '';
if(userLoginDetails!= null){
    if (ls.get('gts_user_id')!= userLoginDetails.user_id){
      gts_user_id = ls.get('gts_user_id');
    }else{
      gts_user_id = userLoginDetails.user_id;
    }
}
class SCClosedServiceComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      myClosedJobs:[],
      serviceTitles:[],
      serviceName:[],
      selectedServiceIds:[],
      selectedServices:[]
    };
    this.cancel = "";
    this.changeHandler = this.changeHandler.bind(this)
  }

  changeHandler = (e) => {
   this.setState({ [e.target.name] : e.target.value})
  }

  componentDidMount(){
    if(userLoginDetails.primary_role=='SERVICE_CONSUMER'){
    var url=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_SC_SERVICES_INACTIVE+"/"+gts_user_id;
    axios.get(url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
    .then(response =>{
      console.log(response.data)
      this.setState({myClosedJobs: response.data})
    })
    .catch(error => {
      var closedJobs = []
       this.setState({myClosedJobs:closedJobs})
      })
    }
  }

  validateJobTitle = e =>{
    let formIsValid = true;
    let jobTitleError = this.state.jobTitleError;
    if (this.state.gts_job_title_id == 0 || this.state.gts_job_title_id=='' || this.state.gts_job_title_id== null || this.state.gts_job_title_id==undefined) {
      formIsValid = false;
      jobTitleError = "Please select service name.";
      this.setState({
        validated: false,
        jobTitleError: jobTitleError
      });
    }
    else{
      jobTitleError = '';
      this.setState({
        validated: true,
        jobTitleError: jobTitleError
      });
    }
  }

  serviceTitleAutoCompleteChangeHandler = (input) => {
    axios
     .get(endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_SERVICE_TITLES, {
        headers: { Auth_Token: `Bearer ${token}` },
      })
      .then((response) => {
        this.state.serviceTitles = response.data;

        if (this.state.serviceName.length != 0) this.state.serviceName.length = [];
        this.state.serviceTitles.forEach((serviceTitles) => {
          this.state.serviceName.push(serviceTitles.gts_job_title_name);
          this.state.serviceName.filter((value) => value.includes(input));
        });
      })
  };

  saveJobTitleAutoCompleteInput = (event, value) => {
    this.setState({
      errorMessage: "",
      successMessage: ""
    })
    this.state.selectedServiceIds = [];
    this.state.selectedServices = value;
    console.log(this.state.selectedServices);
    this.state.selectedServices.forEach((selectedService) => {
      this.state.serviceTitles.forEach((service) => {
        if (selectedService === service.gts_job_title_name) {
          console.log(service.gts_job_title_id)
          this.state.selectedServiceIds.push(service.gts_job_title_id);
        }
      });
    });
  };

  searchClosedJobs = e =>{
    if(this.state.selectedServiceIds== ''){
      var error = 'Please select the service name(s).'
      this.setState({error: error})
    }
    else{
      this.setState({error: ''})
    }
    if(userLoginDetails.primary_role=='SERVICE_CONSUMER'){
      var closedJobs = [];
      var url=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_SC_SERVICES_INACTIVE+"/"+gts_user_id;
      axios.get(url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
      .then(response =>{
        for(var i=0; i<response.data.length;i++){
          this.state.selectedServiceIds.forEach(jobTitleIds=>{
            if(response.data[i].gts_job_title_id == jobTitleIds){
              closedJobs.push(response.data[i])
              this.setState({myClosedJobs: closedJobs})
            }
          })
        }
      })
      .catch(error => {
         this.setState({status:400})
        })
      }
  }

  changeJobStatus(gts_job_status,gts_job_id){
    var url=endpoints_properties.ENDPOINT_PROD+api_properties.API_SC_SERVICES_CHANGE_STATUS;
    var stausPayload={
      gts_job_id : gts_job_id,
	  	gts_job_status : gts_job_status,
    }
    axios.put(url,stausPayload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
    .then(response =>{
      this.setState({message:'Service re-opened successfully'})
      this.componentDidMount();
    })
    .catch(error => {
     this.setState({status:400})
    })
  }

  clearError= e=>{
    this.setState({error:''})
    this.setState({message:''})
  }

   render() {
    return (
     <div>
       <br/>
       {/* -------------------------------------------------- SEARCH--------------------------------------------------------- */}
       <div>
           <div className="row offset-1">
             <div className="col">
              <h5 style={{fontSize:"18px",paddingTop: "1px"}} className="h4-searchtext">Enter Service Name in the Search Bar</h5>
              <Autocomplete
                multiple
                options={this.state.serviceName}
                style={{ outlineColor: "black" }}
                filterSelectedOptions
                onChange={this.saveJobTitleAutoCompleteInput}
                onBlur={this.clearError}
                renderInput={(params) => {
                  this.serviceTitleAutoCompleteChangeHandler(params);
                  return (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Service Name"
                    />
                  );
                }}
              />
              </div>
              <div className="col p-4 pr-9">
              <Button color="primary" onClick={this.searchClosedJobs}>Search</Button>
              </div>
            </div>
         <span style={{fontSize:"15px", color:'red'}} ><strong><center>{this.state.error}</center></strong></span>
         <span style={{fontSize:"15px", color:'green'}} ><strong><center>{this.state.message}</center></strong></span>
       </div>
     {/* ------------------------------------------DISPLAY------------------------------------------------------------------ */}
        {this.state.myClosedJobs.map((item)=>(
         <InputGroup>
           <Grid container spacing={2}>
            <Grid item xs={userLoginDetails.primary_role=='SERVICE_PROVIDER'? 11 : 10}>
              <div class="mt-4">
               <div class="border border-dark rounded-lg offset-1">
                 <div class="row" >
                   <div class="col-4 p-3 pl-3" >
                   <h5 style={{fontSize:"15px"}}><b>Service ID&nbsp;:&nbsp;{item.gts_job_id}</b></h5>
                     <h5 style={{fontSize:"15px"}}><strong>Service Name: </strong>{item.job_title_name.toUpperCase() }</h5>
                     <h5 style={{fontSize:"15px"}}><strong>Company: </strong><span style={{color:'black'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.company_name.toUpperCase() }</span></h5>
                     <h6 style={{fontSize:"15px"}}>Expiry Date: <span style={{color:'red'}}>{(item.gts_job_expiry_date == '') ? '' : Moment(item.gts_job_expiry_date).format('DD-MMM-YYYY') }</span></h6>
                     <h6 style={{fontSize:"15px"}}>Job Type: <span style={{color:'red'}}>{item.gts_job_type.toUpperCase() }</span></h6>
                   </div>

                    <div class= "col-3 p-3">
                      <br/>
                      <h6 style={{fontSize:"15px"}}>Position Requistion: <span style={{color:'red'}}>{item.gts_job_vacancy_numbers}</span></h6>
                      <br/>
                      <h6 style={{fontSize:"15px"}}>Position Filled: <span style={{color:'red'}}>{item.numberOfCandidatesOffered}</span></h6>
                    </div>

                   <div class= "col-3 p-3">
                     <br/>
                      <h6 style={{fontSize:"15px"}}>Posted Date: <span style={{color:'red'}}>{Moment(item.gts_job_posted_date).format('DD-MMM-YYYY') }</span></h6>
                      {item.gts_job_min_exp_in_months == 0 || item.gts_job_min_exp_in_months == '' ? <h6>Experience: '' </h6> :
                      <h6 style={{fontSize:"15px"}}>Experience: <span style={{color:'red'}}>{item.gts_job_min_exp_in_months} months</span></h6>
                      }
                      <h6 style={{fontSize:"15px"}}>Location:<span style={{color:'red'}}>{item.city_name}</span></h6>
                    </div>

                    <div class= "col p-2" text-align="right">
                      <br/>
                      <button
                       className="btn btn-primary"
                       data-toggle="modal"
                       data-target={"#viewJob"+item.gts_job_id}
                       onClick={this.state.is_company_requirement = item.gts_job_is_company_requirement}
                       onBlur={this.clearError}
                       style={{backgroundColor:"white",color:"white", align:"right", borderRadius:"10px", fontSize:"12px"}}
                      >
                      <b>View</b>
                      </button>
                      <br/>
                      <br/>
                     <button
                       className="btn btn-primary"
                       onClick={()=>this.changeJobStatus("SUBMITTED",item.gts_job_id)}
                       onBlur={this.clearError}
                       style={{backgroundColor:"white",color:"white", align:"right", borderRadius:"10px", fontSize:"12px"}}>
                       <b>Reopen</b>
                      </button>
                    </div>
                  </div>
             </div>
             </div>
      {/* ------------------------------------------------ VIEW ON IF CONDITION-------------------------------------------------- */}
        <div id={"viewJob"+item.gts_job_id} class="modal fade" role="dialog">
         <div class="modal-dialog modal-xl" >
           <div class="modal-content">
              <div class="modal-body">
                <div className="container" >
                  <div class="row-sm m-0  text-left">
                  <div class="row-0" align="right">
                     <div class="col-0" align="right">
                         <button  type="button" class="close" data-dismiss="modal" ><i  class="fas fa-window-close fa-lg"></i></button>
                       </div>
                     </div>
                  <InputGroup>
                      <div class="row-0" align="left">
                        <div class="col-0" align="left">
                        <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Service ID&nbsp;:&nbsp;{item.gts_job_id}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Service Name&nbsp;:&nbsp;{item.job_title_name.toUpperCase()}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                          <h6 style={{color : "black", display:this.state.gts_job_title}}><label style={{fontSize:"15px"}}><b>Industry Name&nbsp; :&nbsp;</b><span style={{color:'red'}}>{item.industry_name}</span>&nbsp;&nbsp;</label></h6>
                          <h6  style={{color : "black", display : this.state.is_company_requirement == true ? 'block' : 'none'}}><label style={{fontSize:"15px"}}><b>Company Name&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.company_name}</span>&nbsp;&nbsp;</label></h6>
                        </div>
                     </div>
                     <div class="row-0" align="right">
                        <div class="col-0" align="right">
                          <br/>
                        <h6  style={{color : "black"}}><label style={{fontSize:"15px", display:item.gts_job_id >0 ? 'none' : 'block'}}><b>Service ID&nbsp;:&nbsp;{item.gts_job_id}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                         <h6  style={{color : "black"}}><label style={{fontSize:"15px"}} ><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Expiry Date&nbsp; :&nbsp;</b><span style={{color:'red'}}>{Moment(item.gts_job_expiry_date).format('DD-MMM-YYYY')}</span>&nbsp;&nbsp;</label></h6>
                         </div>
                     </div>

                     <div class="row-0" align="right">
                       <div class="col-0" align="right">
                       <br/>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px", display:item.gts_job_id >0 ? 'none' : 'block'}}><b>Service ID&nbsp;:&nbsp;{item.gts_job_id}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}} ><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Experience(Months) &nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_min_exp_in_months}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}} ><b>Work Type &nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_work_type}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}} ><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Job Type&nbsp;:&nbsp;</b> <span style={{color:'red'}}>{item.gts_job_type}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                        </div>
                      </div>

                     <div class="row-0" align="right">
                        <div class="col-0" align="right">
                        <br/>
                        <h6  style={{color : "black"}}><label style={{fontSize:"15px", display:item.gts_job_id >0 ? 'none' : 'block'}}><b>Service ID&nbsp;:&nbsp;{item.gts_job_id}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                          <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Country&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.country_name}</span>&nbsp;</label></h6>
                          <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;City&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.city_name}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                       </div>
                     </div>
                   </InputGroup>

                    <div className="form-row">
                      <h6 class="text" style={{color : "black", display : this.state.is_company_requirement == true ? 'block' : 'none'}}><label style={{fontSize:"15px"}}><b>&nbsp;Company/SC Description &nbsp;:&nbsp; </b>{item.company_description}&nbsp;&nbsp;</label></h6>
                   </div>

                   <div className="row">
                      <div class="col">
                       <h6 class="text" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Service Description&nbsp;:&nbsp;</b>
                       <h6 >{item.gts_job_description}</h6></label></h6>
                      </div>
                   </div>

                   <div className="row">
								      <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;serviceTitles &nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_post_skill_ids.map(user_skill =>user_skill.gts_skill_name.concat(', '))}</span></label></h6>
                   </div>

                   <InputGroup>
                     <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Minimum Qualification &nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.degree_name}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                       <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Language Proficiency&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.language_name}</span> &nbsp;&nbsp;&nbsp;</label></h6>
                       <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Notice Period(Days)&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_expected_hiring_weeks}</span>&nbsp;&nbsp;&nbsp;</label></h6>
                       <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No. of Vacancies&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_vacancy_numbers}</span>&nbsp;</label></h6>
                     </InputGroup>

                     <InputGroup>
                       <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Salary Currency&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.currency_name}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                        <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;Salary&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_salary}</span>&nbsp;</label></h6>
                       <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;Per&nbsp;&nbsp;</b><span style={{color:'red'}}>{item.gts_job_salary_duration_unit}</span>&nbsp;</label></h6>
                     </InputGroup>

                     <div style={{borderBottomColor: 'black',borderBottomWidth: "0.5px"}}/><br/>

                     <InputGroup>
                        <h6 class="form-group" style={{color : "black", align:"left"}}><label style={{fontSize:"15px"}}><b>Email&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_contact_email}</span>&nbsp;</label></h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <h6 class="form-group" style={{color : "black", align:"center"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mobile Number&nbsp;:&nbsp;</b><span style={{color:'red'}}>+{item.gts_job_mobile_country_code}&nbsp;{item.gts_job_contact_mobile_number}</span>&nbsp;&nbsp;&nbsp;</label></h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <h6 class="form-group" style={{color : "black", align:"right"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;WhatsApp Number&nbsp;:&nbsp;</b><span style={{color:'red'}}>+{item.gts_job_whatsapp_country_code}&nbsp;{item.gts_job_contact_whatsapp_number}</span>&nbsp;</label></h6>
                      </InputGroup>

                  </div>
                </div>
              </div>
           </div>
         </div>
       </div>
      </Grid>
    </Grid>
  </InputGroup> ))}
 <br/>
  </div>
  );
  }
}

export default SCClosedServiceComponent;
