import React from 'react';
import ls from 'local-storage';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import JobSearchSCComponent from '../../components/common/JobSearchSCComponent';
import ServiceConsumerMenu from '../../components/service_consumer/ServiceConsumerMenu';

var token = ls.get("token");

class ServiceConsumerServiceDetailsPage extends React.Component {
   constructor(props) {
	   super(props)
		if(token==null || token=="" || token=="undefined"){
		  window.location.href = '/gts/global-home-page';
		}
	}

      render() {
                return (
                <div>
					<Header />
					<ServiceConsumerMenu/>
					<JobSearchSCComponent/>
					<Footer/>
                </div>
               )
        }
}
export default ServiceConsumerServiceDetailsPage;
