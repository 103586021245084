import React from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import JobSearchSPComponent from '../../components/common/JobSearchSPComponent';
import ls from 'local-storage';
import ServiceProviderMenu from '../../components/service_provider/ServiceProviderMenu';

var token = ls.get("token");
var userLoginDetails="";

class SPJobSearchPage extends React.Component {
	  constructor(props) {
    		super(props);

   			if(token==null || token=="" || token=="undefined"){
			  window.location.href = '/gts/global-home-page';
			}

			userLoginDetails=ls.get('userLoginDetails');
		}


              render() {
                return (
                <div>

                        <Header />
                        <ServiceProviderMenu/>
                        <JobSearchSPComponent/>
                        <Footer/>
                </div>
               )
        }
}


export default SPJobSearchPage;



