import React, { Component } from 'react';

import { Button } from 'react-bootstrap';
import {faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';
import styles from './PrimarySkillsComponent.css';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { black, white } from 'material-ui/styles/colors';
import ls from 'local-storage';
import { endpoints_properties } from '../../properties/EndPointsProperties.js';
import { api_properties } from '../../properties/APIProperties.js';

var env=ls.get('ENV');
var token = ls.get('token');
var userLoginDetails=ls.get('userLoginDetails');

var	activeSkillsCache=ls.get('activeSkillsCache');

var getUserSkillsURL="";
var getActiveSkillsURL="";
var addOrEditUserSkillsURL="";
var deleteUserSkillsURL="";

if(env=="LOCAL"){
	getUserSkillsURL=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_USER_SKILLS;
	getActiveSkillsURL=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_ACTIVE_SKILLS;
	addOrEditUserSkillsURL=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_USER_SKILLS;
	deleteUserSkillsURL=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_DELETE_USER_SKILLS;
}
else{
	getUserSkillsURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_USER_SKILLS;
	getActiveSkillsURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_SKILLS;
	addOrEditUserSkillsURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_USER_SKILLS;
	deleteUserSkillsURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_DELETE_USER_SKILLS;

}


class PrimarySkillsComponent extends Component{
    constructor(props) {
     super(props);
     this.state={
         primaryCounter:0,
         requiredPrimaryProficiency:'',
         requiredPrimarySkill:'',
         yearsOfExperiencePrimary:'',
       requiredPrimaryProficiencyValidationFlag  :true,
         requiredPrimarySkillValidationFlag:false,
         yearsOfPrimaryExperienceValidationFlag:true,
         saveFlag:false,
         editSaveFlag:false,
         addValidationFalg:false,
         cancelFlag:false,
         userSkills:[],
         skills:[{
             gts_skill_id: 0,
             gts_skill_name: null,
             gts_skill_description: null,
             gts_skill_status: false
           }],
          userSkill:{
             gtsUserSkillId:0,
             gtsUser:0,
             gtsSkill:null,
             gtsSkillSelfRatings:null,
             gtsSkillChakuriRatings:null,
             gtsSkillIsPrimary:false,
             gtsSkillExperienceMonths:null,
             gtsSkillExternalRatings:null
            },
            userPrimarySkills:[
            ]
        }
    }

  componentDidMount(){
     var get_url = getUserSkillsURL+'/'+userLoginDetails.user_id;
     axios.get(get_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
     .then(res => {
        var userPrimarySkills = [];
         for(var i=0;i<res.data.length;i++){
             if(res.data[i].gts_skill_is_primary == true){
             userPrimarySkills=userPrimarySkills.concat(res.data[i])
             this.setState({userPrimarySkills : userPrimarySkills});
             this.setState({
                gts_user_skill_id:res.data[i].gts_user_skill_id,
                gts_skill_id:res.data[i].gts_skill_id,
                gts_skill_name:res.data[i].gts_skill_name,
                gts_skill_self_ratings:res.data[i].gts_skill_self_ratings,
                gts_skill_experience_months:res.data[i].gts_skill_experience_months,
                gts_skill_is_primary:true,
                requiredPrimarySkill : res.data[i].gts_skill_name,
                requiredSkillId : res.data[i].gts_skill_id,
                requiredPrimaryProficiency : res.data[i].gts_skill_self_ratings,
                yearsOfExperiencePrimary : res.data[i].gts_skill_experience_months
             })
             }
         }
       })


		var skill_url = getActiveSkillsURL;


		if(activeSkillsCache==null || activeSkillsCache=="" || activeSkillsCache=="undefined"){
			axios.get(skill_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
			.then((response) => {
			  console.log("Loading skills from Database and storing into Cache");
			  ls.set('activeSkillsCache',response.data);

			  this.state.skills = response.data;
			   if (this.state.skills.length > 0){
				this.state.skills.forEach((skills) => {
				 this.state.userSkills.push(skills.gts_skill_name);
				});
			   }
			})//End of then
		}
		else{
			  console.log("Loading skills from cache");
			  this.state.skills = activeSkillsCache;
			   if (this.state.skills.length > 0){
				this.state.skills.forEach((skills) => {
				 this.state.userSkills.push(skills.gts_skill_name);
				});
			   }

		}
 }

  addSkill = e =>{

    this.setState({proficencyPrimaryError:""})
   this.setState({experiencePrimaryError : ""})
     var primarySkillMessage = this.state.primarySkillMessage;
     var primarySkillError = this.state.primarySkillError;
         var post_payload=[{
             gts_user_id:userLoginDetails.user_id,
             gts_skill_id:this.state.requiredSkillId,
             gts_skill_self_ratings:this.state.requiredPrimaryProficiency,
             gts_skill_experience_months:this.state.yearsOfExperiencePrimary,
             gts_skill_is_primary:true
           }]

       if(this.state.requiredPrimarySkill !=='' && this.state.requiredPrimaryProficiencyValidationFlag == true && this.state.yearsOfPrimaryExperienceValidationFlag == true){
         axios.post(addOrEditUserSkillsURL,post_payload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
         .then(response=>{
            var get_url = getUserSkillsURL+'/'+userLoginDetails.user_id;
            axios.get(get_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
            .then(res => {
               var userPrimarySkills = [];
                for(var i=0;i<res.data.length;i++){
                    if(res.data[i].gts_skill_is_primary == true){
                    userPrimarySkills=userPrimarySkills.concat(res.data[i])
                    this.setState({userPrimarySkills : userPrimarySkills});
                    }
                }
              })
             primarySkillMessage = response.data.message;
             this.setState({primarySkillMessage : primarySkillMessage})
            })
            .catch(error=>{
             primarySkillError = "Not able to add primary skills";
             this.setState({primarySkillError : primarySkillError})
            })
        }
        else{
            this.validateUserPrimarySkill();
        }
    }

  updateSkill(primarySkillId){

    this.setState({proficencyPrimaryError:""})
    this.setState({experiencePrimaryError : ""})
        var primarySkillMessage = this.state.primarySkillMessage;
        var primarySkillError = this.state.primarySkillError;
            var put_payload={
             gts_user_skill_id:primarySkillId,
             gts_skill_id:this.state.requiredSkillId,
             gts_skill_self_ratings:this.state.requiredPrimaryProficiency,
             gts_skill_experience_months:this.state.yearsOfExperiencePrimary,
             gts_skill_is_primary:true
        }

        if(this.state.requiredPrimaryProficiencyValidationFlag == true && this.state.yearsOfPrimaryExperienceValidationFlag == true){
          axios.put(addOrEditUserSkillsURL,put_payload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
          .then(response=>{
                var get_url = getUserSkillsURL+'/'+userLoginDetails.user_id;
                axios.get(get_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
                .then(res => {
                var userPrimarySkills = [];
                    for(var i=0;i<res.data.length;i++){
                        if(res.data[i].gts_skill_is_primary == true){
                        userPrimarySkills=userPrimarySkills.concat(res.data[i])
                        this.setState({userPrimarySkills : userPrimarySkills});
                        }
                    }
              })
             primarySkillMessage = response.data.message;
             this.setState({primarySkillMessage : primarySkillMessage})
            })
            .catch(error=>{
             primarySkillError ="Not able to update primary skills";
             this.setState({primarySkillError : primarySkillError})
            })
        }
    }

  deleteSkill(primarySkillId){
      let primarySkillMessage = this.state.primarySkillMessage;
      let primarySkillError = this.state.primarySkillError;
      if(window.confirm('Are you sure?')){
         axios.delete(deleteUserSkillsURL+'/'+primarySkillId,{ headers: {"Auth_Token" : `Bearer ${token}`} })
          .then(response=>{
			  var userPrimarySkills = [];
            var get_url = getUserSkillsURL+'/'+userLoginDetails.user_id;
            axios.get(get_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
            .then(res => {

                for(var i=0;i<res.data.length;i++){
                    if(res.data[i].gts_skill_is_primary == true){
                    userPrimarySkills=userPrimarySkills.concat(res.data[i])
                    this.setState({userPrimarySkills : userPrimarySkills});
                    }
                }
              })
              console .log(response.data)
             primarySkillMessage = response.data.message;
             this.setState({deleteMessage : primarySkillMessage})
             userPrimarySkills = [];
              this.setState({userPrimarySkills : userPrimarySkills});
            })
            .catch(error=>{
                primarySkillError = "Not able to delete primary skills";
                this.setState({deletePrimarySkillError : primarySkillError})
           })
        }
    }

  cancelEditHandler=e=>{
     this.setState({editSaveFlag:false});
    }

  autoCompleteChangeHandler=(event,value)=>{
     this.setState({editSaveFlag:true});
     this.setState({requiredPrimarySkillValidationFlag:false});
     if(value==null){
         this.setState({requiredPrimarySkillValidationFlag:true});
        }
        this.state.selectedSkill = value;
        this.state.requiredPrimarySkill = value;
        if(value == ''  || value== null){
        this.setState ({requiredSkillId : ''})
        }
        else{
        this.state.skills.forEach((skill) => {
            if (this.state.selectedSkill === skill.gts_skill_name) {
            this.state.requiredSkillId = skill.gts_skill_id
            }
        })
        }
    }

  changeHandler= e =>{
      this.setState({editSaveFlag:true})
      this.setState({saveFlag:false});
      this.setState({[e.target.name]: e.target.value})

      var pattern = new RegExp(/^[0-9]+$/);
      if(e.target.value !==''|| e.target.value !==undefined){
         if(e.target.name=='requiredPrimarySkill'){
             this.setState({requiredPrimarySkillValidationFlag:true});
             this.setState({requiredPrimarySkill : e.target.value})
            }

          if(e.target.name=="requiredPrimaryProficiency"){
              if(e.target.value ==""){
                this.setState({requiredPrimaryProficiencyValidationFlag:true});
                this.setState({proficencyPrimaryError : ""})
              }
              else{
                    if(pattern.test(e.target.value)){
                        if(e.target.value > 10){
                        this.setState({requiredPrimaryProficiencyValidationFlag:false});
                            this.setState({proficencyPrimaryError : "Please enter proficiency out of 10."})
                        }
                        else{
                            this.setState({requiredPrimaryProficiencyValidationFlag:true});
                            this.setState({requiredPrimaryProficiency : e.target.value})
                            this.setState({proficencyPrimaryError : ""})
                        }
                    }
                    else {
                        this.setState({requiredPrimaryProficiencyValidationFlag:false});
                        this.setState({proficencyPrimaryError : "Please enter proficiency out of 10."})
                    }
                }
           }
           if(e.target.name=='yearsOfExperiencePrimary'){
            this.setState({proficencyPrimaryError:""})
               if(e.target.value == ""){
                this.setState({yearsOfPrimaryExperienceValidationFlag:true});
                this.setState({experiencePrimaryError : ""})
               }
               else{
                    if(!pattern.test(e.target.value)){
                        this.setState({yearsOfPrimaryExperienceValidationFlag:false});
                        this.setState({experiencePrimaryError : "Please enter valid year of experience"})
                    }
                    else{
                        this.setState({yearsOfPrimaryExperienceValidationFlag:true});
                        this.setState({experiencePrimaryError : ""})
                        this.setState({gtsSkillExperienceMonths : e.target.value})
                    }
               }
            }
        }
   }

  addButtonListener=e=>{
     this.setState({requiredPrimaryProficiency:''});
     this.setState({requiredPrimarySkill:''});
     this.setState({yearsOfExperiencePrimary:''});
     this.setState({editSaveFlag:false});
     this.setState({primaryCounter:this.state.primaryCounter+1});
    }

  cancelHandler=e=>{
      this.setState({cancelFlag:true});
   }

  editButtonListener=e=>{
     this.setState({editSaveFlag:false});
   }

  clearMessageOrError= () => {
         this.setState({primarySkillMessage : ""});
         this.setState({primarySkillError : ""});
    }

    validateUserPrimarySkill = e =>{
        var skillError = "Please select skill."
        if(this.state.requiredPrimarySkill=='' || this.state.requiredPrimarySkill== null){
            this.setState({skillError : skillError})
        }
        else{
            this.setState({skillError : ""})
        }
    }

    clearError = e=>{
        this.setState({proficencyPrimaryError:""})
    }


  render(){

    const{requiredPrimaryProficiency,requiredPrimarySkill,yearsOfExperiencePrimary}=this.state;

    return(
    <div className="container align-items-center">
      <div className="container align-items-center">
        <div className='mt-3'>
           <div className="border border-dark rounded-lg" >
             <div className="row">
                 <div className = "col" align="left">
                     <h5  className="mb6-3 p-2"><b>&nbsp;Primary Skills</b></h5>
                  </div>
                  <div className = "col-3" align="right">
                     < button type="button" style={{width:"120px", height:"30px",display: "inline"}} class="btn btn-primary float-right" data-toggle="modal" data-target="#addPrimaryDetails"  onClick={this.addButtonListener} onBlur={this.clearMessageOrError} size="small"><center><h6 style={{ color: 'white' }}>Add Skill</h6></center></button>
                  </div>
             </div>
             <div style={{borderBottomColor: 'black',borderBottomWidth: 1.5}} />
             <span style={{color: "red" , width : "400px"}} ><center>{this.state.deletePrimarySkillError}</center></span>
             <span style={{color: "#228B22" , width : "400px"}} ><center>{this.state.deleteMessage}</center></span>
                 <br/>
                 <div class="container">
                     <table  id ="containerOfTablePrimarySkills" className="table table-responsive" style={{borderWidth:"1px", 'borderColor':"border-dark", 'borderStyle':'border-dark', width:"1090px"}}>
                         <thead>
                             <tr>
                             <td><h6 style={{color : black, fontSize:"18px"}}><center>Sr.No.</center></h6></td>
                                 <td><br/></td><td><br/></td><td><br/></td><td><br/></td><td><br/></td>
                                 <td ><h6 style={{color : black, fontSize:"18px"}}><center>Skills</center></h6></td>
                                 <td><br/></td><td><br/></td><td><br/></td><td><br/></td><td><br/></td>
                                 <td><h6 style={{color : black, fontSize:"18px"}}><center>Claim Of Proficency(Out of 10)</center></h6></td>
                                 <td><br/></td><td><br/></td><td><br/></td><td><br/></td><td><br/></td>
                                 <td><h6 style={{color : black, fontSize:"18px"}}><center>Experience(Months)</center></h6></td>
                                 <td><br/></td><td><br/></td><td><br/></td><td><br/></td><td><br/></td>
                                 <td><h6 style={{color : black, fontSize:"18px" ,display: "inline"}}><center>Edit/Delete Skill</center></h6></td>
                                 <td><br/></td>
                             </tr>
                         </thead>
                         <tbody >
                               {
                                  this.state.userPrimarySkills.slice(0,this.state.userPrimarySkills.length).map((userSkill,index)=> {
                                  return(<>
                                        { this.state.userPrimarySkills.length > 0 && userSkill.gts_skill_is_primary &&
                                          <tr class={"strippedColors"+(index)%2}key={index}>
                                              <td><h6 style={{color : black}}><center>{index+1}</center></h6></td>
                                              <td><br/></td><td><br/></td><td><br/></td><td><br/></td><td><br/></td>
                                              <td><h6 style={{color : black}}><center>{userSkill.gts_skill_name}</center></h6></td>
                                              <td><br/></td><td><br/></td><td><br/></td><td><br/></td><td><br/></td>
                                              <td><h6 style={{color : black}}><center>{userSkill.gts_skill_self_ratings}</center></h6></td>
                                              <td><br/></td><td><br/></td><td><br/></td><td><br/></td><td><br/></td>
                                              <td><h6 style={{color : black}}><center>{userSkill.gts_skill_experience_months}</center></h6></td>
                                              <td><br/></td><td><br/></td><td><br/></td><td><br/></td><td><br/></td>
                                              <td><button href="#" class="btn float-right" style={{display: "inline"}} onClick={()=>this.deleteSkill(userSkill.gts_user_skill_id)}><center><FontAwesomeIcon icon={faTrash}  /></center></button>
                                              <button class="btn float-right " style={{display: "inline"}} data-toggle="modal" data-target={"#editPrimarySkills"+index} onClick={this.editButtonListener}><center><FontAwesomeIcon icon={faEdit} /></center></button></td>
                                              <td></td>
                                          </tr>
                                        }
                                        <div id= {"editPrimarySkills"+index} class="modal fade" role="dialog">
                                             <div class="modal-dialog">
                                                 <div class="modal-content">
                                                     <div class="modal-header">
                                                          <h5 class="modal-title"> Edit Primary Skills</h5>
                                                     </div>
                                                     <div class="modal-body">
                                                         <div className="container" >
                                                              <div class="row-sm m-0  text-left  ">
                                                                   <label id="requiredPrimarySkill" name="requiredPrimarySkill" class="text-dark">Skill:&nbsp;&nbsp;
                                                                      <Autocomplete
                                                                         class="complete"
                                                                         options={this.state.userSkills}
                                                                         style={{ width: 200,outlineColor:"black",display:"inline-block" }}
                                                                         defaultValue={userSkill.gts_skill_name}
                                                                         disabled
                                                                         onChange={this.autoCompleteChangeHandler}
                                                                         renderInput={(params) => <TextField {...params}    />}
                                                                       s/>
                                                                 </label>
                                                              </div>
                                                              <div class="row-sm">
                                                                  <label class="text-dark">Proficency(out of 10): &nbsp;&nbsp;
                                                                  <input type="text" style={{ border: "2px solid #D3D3D3" }} name="requiredPrimaryProficiency" defaultValue={userSkill.gts_skill_self_ratings} placeholder ={userSkill.gts_skill_self_ratings}  onChange={this.changeHandler}  ></input>
                                                                  </label>
                                                                  <div class="row-sm">
                                                                    <span style={{color: "red" , width : "400px"}} >{this.state.proficencyPrimaryError}</span>
                                                                </div>
                                                              </div>
                                                              <div class="row-sm">
                                                                  <label class="text-dark">Experience In Months: &nbsp;&nbsp;
                                                                      <input type="text" style={{ border: "2px solid #D3D3D3" }} name="yearsOfExperiencePrimary" defaultValue={userSkill.gts_skill_experience_months} placeholder={userSkill.gts_skill_experience_months}  input={userSkill.gtsSkillExperienceMonths} onChange={this.changeHandler}></input>
                                                                 </label>
                                                                 <div class="row-sm">
                                                                    <span style={{color: "red" , width : "400px"}} >{this.state.experiencePrimaryError}</span>
                                                                </div>
                                                              </div>
                                                         </div>
                                                      </div>
                                                      <div class="modal-footer">
                                                          <div class="text-inline">
                                                              <Button id ="test" type="button" onBlur={this.clearMessageOrError} onClick={()=>this.updateSkill(userSkill.gts_user_skill_id)}>Save</Button>
                                                              <Button type="button" class="close" data-dismiss="modal" onBlur={this.clearMessageOrError} onClick={this.cancelEditHandler}>Cancel</Button>
                                                          </div>
                                                          <span style={{color: "red" , width : "400px"}} ><center>{this.state.primarySkillError}</center></span>
                                                          <span style={{color: "#228B22" , width : "400px"}} ><center>{this.state.primarySkillMessage}</center></span>
                                                     </div>
                                                 </div>
                                             </div>
                                          </div>
                                     </>)
                                   })}
                             </tbody>
                         </table>
                     </div>
                     <div id="addPrimaryDetails" class="modal fade" role="dialog">
                         <div class="modal-dialog">
                             <div class="modal-content">
                                 <div class="modal-header">
                                     <h5 class="modal-title"> Add Primary Skills</h5>
                                 </div>
                                 <div class="modal-body">
                                     <div className="container" >
                                         <div class="row-sm m-0  text-left  ">
                                             <label class="text-dark">Skill:&nbsp;&nbsp;
                                                 <Autocomplete class="complete"
                                                     options={this.state.userSkills}
                                                     style={{ width: 150,outlineColor:"black",display:"inline-block" }}
                                                     renderInput={(params) => <TextField {...params}  />}
                                                     onChange={this.autoCompleteChangeHandler}
                                                     onBlur = {this.validateUserPrimarySkill}
                                                  s />
                                                  <div>
                                                     <span class="validationStyle" style={{color: 'red'}}>{this.state.skillError}</span>
                                                  </div>
                                             </label>
                                         </div>
                                         <div class="row-sm">
                                             <label class="text-dark">Proficency(out of 10): &nbsp;&nbsp;
                                                 <input type="text" style={{ border: "2px solid #D3D3D3" }} name="requiredPrimaryProficiency" defaultValue={requiredPrimaryProficiency} onChange={this.changeHandler}></input>
                                             </label>
                                          </div>
                                          <div class="row-sm">
                                             <span style={{color: "red" , width : "400px"}} >{this.state.proficencyPrimaryError}</span>
                                         </div>
                                          <div class="row-sm">
                                              <label class="text-dark">Experience In Months: &nbsp;&nbsp;
                                                 <input type="text" style={{ border: "2px solid #D3D3D3" }} name="yearsOfExperiencePrimary" defaultValue={yearsOfExperiencePrimary} onChange={this.changeHandler}></input>
                                             </label>
                                             <div class="row-sm">
                                                 <span style={{color: "red" , width : "400px"}} >{this.state.experiencePrimaryError}</span>
                                            </div>
                                         </div>
                                       </div>
                                  </div>
                                  <div class="modal-footer">
                                     <div class="text-inline">
                                          <Button id ="test" onClick={this.addSkill}>Save</Button>
                                         <Button type="button" class="close" data-dismiss="modal" onClick={this.cancelHandler}>Cancel</Button>
                                     </div>
                                     <span style={{color: "red" , width : "400px"}} ><center>{this.state.primarySkillError}</center></span>
                                    <span style={{color: "#228B22" , width : "400px"}} ><center>{this.state.primarySkillMessage}</center></span>
                                  </div>
                             </div>
                         </div>
                     </div>
                  </div>
             </div>
         </div>
         </div>)
    }

}
export default PrimarySkillsComponent;