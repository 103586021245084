import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faTrash,
  faEdit,
  faUpload,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import {Button} from 'react-bootstrap';

import Autocomplete from "@material-ui/lab/Autocomplete";
import ls from "local-storage";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { endpoints_properties } from '../../properties/EndPointsProperties.js';
import { api_properties } from '../../properties/APIProperties.js';

var token = ls.get("token");

var userLoginDetails = ls.get("userLoginDetails");

class CompanyDetailComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      company: [],
      jobTitle: [
        {
          gts_job_title_id: 0,
          gts_job_title_name: "",
          gts_job_title_description: "",
          gts_job_title_status: false,
        },
      ],
      jobTitleName: [],
      company: [
        {
          gts_company_id: 0,
          gts_company_name: "",
          gts_company_description: "",
          gts_company_status: false,
          gts_company_contact_email: "",
          gts_country_code: 0,
          gts_company_contact_mobile_number: 0,
          gts_company_contact_alternate_mobile_number: 0,
        },
      ],
      companyName: [],
      selectedCompany: {
        companyName: "",
        companyId: 0
      },
      gts_employer_company_name: "",
      gts_job_title_name: "",
      gts_employer_job_description: "",
      gts_employer_company_detail_id: 0,
      selectedJobTitle:{
        jobTitleName: "",
        jobTitleId: 0
      },
      jobDescription: "",
      employerCompanyDetails: [],
      employerCompanyDetailsExists: false,
      isChanged: false,
      disableInput: true,
      savedMsg: "",
      updatedMsg: ""
    };
  }

  componentDidMount() {

    axios
      .get(endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_SC_COMPANY_DETAILS+'/'+userLoginDetails.user_id,
      { headers: { Auth_Token: `Bearer ${token}` } }
      )
      .then((response)=>{

        this.setState({
          employerCompanyDetails: response.data
        })
        console.log(this.state.employerCompanyDetails);
        if(response.data.length !== 0) {
          this.setState({
            employerCompanyDetailsExists: true
          })
        } else{
          this.setState({
            employerCompanyDetails: []
          })
        }

        this.setState({
          gts_employer_company_name: response.data[0].gts_employer_company_name,
          gts_employer_company_id: response.data[0].gts_employer_company_id,
          gts_job_title_name: response.data[0].gts_job_title_name,
          gts_job_title_id: response.data[0].gts_job_title_id,
          gts_employer_job_description: response.data[0].gts_employer_job_description,
          gts_employer_company_detail_id: response.data[0].gts_employer_company_detail_id
        });
        this.state.selectedCompany.companyName = response.data[0].gts_employer_company_name;
        this.state.selectedCompany.companyId = response.data[0].gts_employer_company_id;
        this.state.selectedJobTitle.jobTitleName = response.data[0].gts_job_title_name;
        this.state.selectedJobTitle.jobTitleId = response.data[0].gts_job_title_id;
        this.state.jobDescription = response.data[0].gts_employer_job_description;
      })
      .catch((error)=>{
        console.log(error);
      })


    this.JobTitleAutoCompleteChangeHandler();
    this.CompanyNameAutoCompleteChangeHandler();
  }

  JobTitleAutoCompleteChangeHandler = (input) => {
    axios
      .get(endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_SERVICE_TITLES, {
        headers: { Auth_Token: `Bearer ${token}` },
      })
      .then((response) => {
        this.state.jobTitle = response.data;

        if (this.state.jobTitleName.length != 0)
          this.state.jobTitleName.length = [];

        this.state.jobTitle.forEach((jobTitles) => {
          this.state.jobTitleName.push(jobTitles.gts_job_title_name);
        });
        //console.log(this.state.jobTitleName);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  CompanyNameAutoCompleteChangeHandler = () => {
    axios
      .get(endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_COMPANIES, {
        headers: { Auth_Token: `Bearer ${token}` },
      })
      .then((response) => {
        this.state.company = response.data;

        if (this.state.companyName.length != 0)
          this.state.companyName.length = [];

        this.state.company.forEach((company) => {
          if(company.gts_company_status == true){
            this.state.companyName.push(company.gts_company_name);
          }
        });
        //console.log(this.state.companyName);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  companyName = (e, value) =>{

    this.setState({
      gts_employer_company_name: value
    });

    this.state.selectedCompany.companyName = value;
    this.state.company.forEach((company)=>{
      if(this.state.selectedCompany.companyName === company.gts_company_name){
        this.state.selectedCompany.companyId = company.gts_company_id;
      }

      console.log(`${this.state.selectedCompany.companyName} id is ${this.state.selectedCompany.companyId}`);

    })
  }

  jobTitle = (e, value) =>{

    this.setState({
      gts_job_title_name: value
    })

    this.state.selectedJobTitle.jobTitleName = value;
    this.state.jobTitle.forEach((jobTitle)=>{
      if(this.state.selectedJobTitle.jobTitleName === jobTitle.gts_job_title_name){
        this.state.selectedJobTitle.jobTitleId = jobTitle.gts_job_title_id;
      }

      console.log(`${this.state.selectedJobTitle.jobTitleName} id id ${this.state.selectedJobTitle.jobTitleId}`);
    })
  }

  OnChangeJobDescriptionHandler = (e, value)=>{
    this.state.jobDescription = e.target.value;

    this.setState({
      jobDescription: e.target.value,
      gts_employer_job_description: value
    })
  }

  OnSubmitHandler = (e) => {
    e.preventDefault();
    let postUrl = endpoints_properties.ENDPOINT_PROD+api_properties.API_SC_COMPANY_DETAILS;
    let postPayload = {
      gts_employer_id: userLoginDetails.user_id,
      gts_employer_company_id: this.state.selectedCompany.companyId,
      gts_job_title_id: this.state.selectedJobTitle.jobTitleId,
      gts_employer_job_description: this.state.jobDescription
    };
    console.log(postPayload);

    let savedSuccessMsg = "Company details saved successfully";
    let savedFailMsg = "Failed to update Company details";


    axios
      .post(postUrl, postPayload, { headers: { Auth_Token: `Bearer ${token}` } })
      .then((response) => {
        console.log(response.data);
          this.state.employerCompanyDetailsExists = true;
          this.setState({
            savedMsg: savedSuccessMsg
          })
      })
      .catch((error) => {
        console.log(error.response);
        if(!error.response){
          this.setState({
            savedMsg: savedFailMsg
          })
        }
        else if(error.response.data.status_code >500){
          this.setState({
            savedMsg: savedFailMsg
          })
        }
        else{
          this.setState({
            savedMsg: error.response.data.message
          })
        }

      });
  };

  enableInput = (e) =>{
    e.preventDefault();
    //console.log("inside enble input method");
    this.setState({
      disableInput: false,
      savedMsg: "",
      updatedMsg: ""
    })
  }

  OnUpdateHandler = (e) => {
    e.preventDefault();

    let putUrl = endpoints_properties.ENDPOINT_PROD+api_properties.API_SC_COMPANY_DETAILS;
    let putPayload = {
      gts_employer_company_detail_id: this.state.gts_employer_company_detail_id,
      gts_employer_company_id: this.state.selectedCompany.companyId,
      gts_job_title_id: this.state.selectedJobTitle.jobTitleId,
      gts_employer_job_description: this.state.jobDescription
    };
    let updateSuccessMsg = "Company details Updated successfully";
    let updateFailMsg = "Failed to update Company details";

    console.log(putPayload);
    axios
    .put(putUrl, putPayload, { headers: { Auth_Token: `Bearer ${token}` } })
    .then((response)=>{
      console.log(response.data);

        this.setState({
          updatedMsg: updateSuccessMsg
        })
        //console.log(this.state.updatedMsg);
    })
    .catch((error)=>{
      console.log(error);
      this.setState({
        updatedMsg: updateFailMsg
      })
    })
  };

  render() {

    return (
      <div className="container align-items-center">
        <div className="container align-items-center">
          <div className='mt-3'>
            <div className="border border-dark rounded-lg" >
            <div className="row">
                <div className = "col" align="left">
                  <h5  className="mb6-3 p-2"><b>&nbsp;Employer Company Details</b></h5>
                </div>
             </div>
             <div style={{borderBottomColor: 'black',borderBottomWidth: 1.5}} />
             <br/>

              <form>
                <div className="form-group">
                  <div class="row-sm">
                    <label style={{ fontWeight: "bold", paddingLeft: "10px" }}>
                      Company Name :
                    </label>
                    <br />
                    <Autocomplete
                      options={this.state.companyName}
                      onChange={this.companyName}
                      style={{
                        width: "50%",
                        paddingLeft: "10px",
                        borderColor: "black",
                      }}
                      //defaultValue={cname}
                      value={this.state.gts_employer_company_name}
                      disabled={
                                this.state.employerCompanyDetailsExists &&
                                this.state.disableInput
                                ? "disableInput"
                                : ""
                              }
                      renderInput={(params) => {
                        this.CompanyNameAutoCompleteChangeHandler(params);
                        return (
                          <TextField
                            {...params}
                            placeholder="Company Name"
                            id="gts_employer_company_name"
                            variant="outlined"
                          />
                        );
                      }}
                    />
                    <p style={{color:"red", fontStyle:"italic", margin:"0 0 0 10px"}}>
                      (If you can't find company in the list, please contact Adminstrator at hr.gts@ggtech.co.in)
                    </p>
                  </div>
                      <br/>
                  <div class="row-sm">
                    <label style={{ fontWeight: "bold", paddingLeft: "10px" }}>
                      Job Position :
                    </label>
                    <br />
                    <Autocomplete
                      options={this.state.jobTitleName}
                      onChange={this.jobTitle}
                      style={{
                        width: "50%",
                        paddingLeft: "10px",
                        borderColor: "black",
                      }}
                      value={this.state.gts_job_title_name}
                      disabled={
                                this.state.employerCompanyDetailsExists &&
                                this.state.disableInput
                                ? "disableInput"
                                : ""
                              }
                      renderInput={(params) => {
                        this.JobTitleAutoCompleteChangeHandler(params);
                        return (
                          <TextField
                            {...params}
                            placeholder="Job Position"
                            variant="outlined"
                          />
                        );
                      }}
                    />
                  </div>
                  <br/>
                  <div class="row-sm">
                    <label style={{ paddingLeft: "10px", fontWeight: "bold" }}>
                      Job Description :
                    </label>
                    <br />
                    <label id="JobDescription" style={{ paddingLeft: "10px" }}>
                      <textarea
                        name="JobDescription"
                        rows="5"
                        cols="70"
                        maxLength="500"
                        style={{
                          border: "solid 1px",
                          width: "100%",
                          borderRadius: "5px",
                          borderColor:
                                this.state.employerCompanyDetailsExists &&
                                this.state.disableInput
                                ? "grey"
                                : "black"
                        }}
                        value={this.state.gts_employer_job_description}
                        disabled={
                                this.state.employerCompanyDetailsExists &&
                                this.state.disableInput
                                ? "disableInput"
                                : ""
                              }
                        onChange={this.OnChangeJobDescriptionHandler}
                      />
                    </label>
                  </div>
                  <div class="row-sm">
                    <div divID="message" className="text-center font-bold" style={{color:"green"}}>
                      {this.state.savedMsg}
                    </div>
                    <div divID="message" className="text-center font-bold" style={{color:"green"}}>
                      {this.state.updatedMsg}
                    </div>

                    <div class="col-md-12 text-right">
                    <Button
                      type="button"
                      variant="light"
                      class="btn "
                      style={{display: ls.get('gts_user_id')!= userLoginDetails.user_id ? "none" : "inline"}}
                      href="#"
                      onClick={this.enableInput}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                    <Button
                      type="button"
                      variant="primary"
                      class="btn btn-primary"
                      href="#"
                      style={{display: ls.get('gts_user_id')!= userLoginDetails.user_id ? "none" : "inline"}}
                      id="SaveDetails"
                      onClick={this.state.employerCompanyDetailsExists ? this.OnUpdateHandler : this.OnSubmitHandler}
                      // disabled={this.state.disableInput}
                    >
                      Save
                    </Button>
                   </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CompanyDetailComponent;
