import React, { useState, useEffect,Component } from 'react';
import { Alert, Button, ButtonGroup, Col, Container, Form, Modal, Row,Toast,Popover,  OverlayTrigger } from 'react-bootstrap';
import axios from 'axios';
import ls from 'local-storage';
import Moment from 'moment';
import { endpoints_properties } from '../../properties/EndPointsProperties.js';
import { api_properties } from '../../properties/APIProperties.js';

var userLoginDetails=ls.get('userLoginDetails');
var token=ls.get('token');
var env=ls.get('ENV');

var	resumeUploadURL="";
var	currentResumeDownloadURL="";
var	previousResumeDownloadURL="";
var	currentResumeDeleteURL="";
var	previousResumeDeleteURL="";
var	getUserResumeURL="";

if(env=="LOCAL"){
	resumeUploadURL=endpoints_properties.ENDPOINT_USER_RESUME_UPLOAD+api_properties.API_USER_RESUME_UPLOAD;
	currentResumeDownloadURL=endpoints_properties.ENDPOINT_USER_RESUME_DOWNLOAD+api_properties.API_USER_CURRENT_RESUME_DOWNLOAD;
	previousResumeDownloadURL=endpoints_properties.ENDPOINT_USER_RESUME_DOWNLOAD+api_properties.API_USER_PREVIOUS_RESUME_DOWNLOAD;
	currentResumeDeleteURL=endpoints_properties.ENDPOINT_USER_RESUME_UPLOAD+api_properties.API_USER_CURRENT_RESUME_DELETE;
	previousResumeDeleteURL=endpoints_properties.ENDPOINT_USER_RESUME_UPLOAD+api_properties.API_USER_PREVIOUS_RESUME_DELETE;
	getUserResumeURL=endpoints_properties.ENDPOINT_USER_RESUME_DOWNLOAD+api_properties.API_GET_USER_RESUMES;
}
else{
	resumeUploadURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_USER_RESUME_UPLOAD;
	currentResumeDownloadURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_USER_CURRENT_RESUME_DOWNLOAD;
	previousResumeDownloadURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_USER_PREVIOUS_RESUME_DOWNLOAD;
	currentResumeDeleteURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_USER_CURRENT_RESUME_DELETE;
	previousResumeDeleteURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_USER_PREVIOUS_RESUME_DELETE;
	getUserResumeURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_USER_RESUMES;
}

class ResumeUploadComponent extends Component{

    constructor(props) {
        super(props)

        this.state = {
            file : ''
        }
    }

    onChange(e){
        let file = e.target.files[0];
        this.setState({file : file})
    }

    uploadResume = e =>{
        e.preventDefault();

        let message = this.state.message;
        let error = this.state.error

        var upload_url = resumeUploadURL +'/'+userLoginDetails.user_id;

          var bodyFormData_post = new FormData();
          bodyFormData_post.append("file",this.state.file)

          axios.post(upload_url,bodyFormData_post,{ headers: {"Auth_Token" : `Bearer ${token}`} })
          .then(response => {
             this.setState({message : response.data.message});
             if(!this.state.gts_user_resume_id){
             this.setState({"gts_user_current_resume_name" : this.state.file.name})
             this.setState({"gts_user_resume_id" :  response.data.gts_user_resume_id})
             this.setState({"gts_current_resume_upload_date" : Moment(Date.now()).format('DD-MMMM-YYYY')})
             }
             else{
                  this.setState({
                        "gts_user_current_resume_name" : this.state.file.name,
                        "gts_user_previous_resume_name" : this.state.gts_user_current_resume_name,
                        "gts_current_resume_upload_date" : Moment(Date.now()).format('DD-MMMM-YYYY'),
                        "gts_previous_resume_upload_date" : this.state.gts_current_resume_upload_date,
                        "gts_user_resume_id" : response.data.gts_user_resume_id,
                   })
                }
           })
           .catch(err =>{
            if(!err.response){
                error = "Not able to add resume. Try Again!!!";
                this.setState({error : error})
            }
            else if(err.response.data.message == "User id already exists"){
              error = "Problem to show existing resumes, please contact administrator"
              this.setState({error : error})
            }
            else if(err.response.data.status>400 || err.response.data.status>500){
                this.setState({error : err.response.data.message})
            }
            else{
                error = "Not able to upload resume";
                this.setState({error : error})
            }
             console.log(err.response);
      });
    }

    downloadCurrentResume = e =>{
        e.preventDefault();
        let message = this.state.message;
        let error = this.state.error;

        console.log("in downloadCurrentResume")

        var currentResumeURL = currentResumeDownloadURL + '/'+userLoginDetails.user_id;

        axios({
            url: currentResumeURL,
            method: 'GET',
            responseType: 'blob',
            headers: {"Auth_Token" : `Bearer ${token}`}
          }).then((response) => {

             const url = window.URL.createObjectURL(new Blob([response.data]));
             const link = document.createElement('a');
             link.href = url;
             link.setAttribute('download', this.state.gts_user_current_resume_name);
             document.body.appendChild(link);
             link.click();
             console.log(response)
          })
        .catch(err => {
            console.log(err.response)
             error = "Not able to download resume "+this.state.gts_user_current_resume_name;
             this.setState({error : error})
        })
    }

    downloadPreviousResume = e =>{
        e.preventDefault();
        let message =this.state.message;
        let error = this.state.error;

        var previousResumeURL = previousResumeDownloadURL + '/'+userLoginDetails.user_id;

        axios({
            url: previousResumeURL,
            method: 'GET',
            responseType: 'blob',
            headers: {"Auth_Token" : `Bearer ${token}`}
          }).then((response) => {
             const url = window.URL.createObjectURL(new Blob([response.data]));
             const link = document.createElement('a');
             link.href = url;
             link.setAttribute('download', this.state.gts_user_previous_resume_name);
             document.body.appendChild(link);
             link.click();

             })
          .then((response) =>{
            message="Resume downloaded successfully"
               this.setState({
                message : message
          })
        })
        .catch(err => {
            error = "Not able to download resume "+this.state.gts_user_previous_resume_name;
            this.setState({error : error})
       })
    }

    deleteCurrentResume = e =>{
        e.preventDefault();
        let message =this.state.message;
        let error = this.state.error;

        var previousResumeURL = currentResumeDeleteURL + '/'+this.state.gts_user_resume_id;

        axios.delete(previousResumeURL,{ headers: {"Auth_Token" : `Bearer ${token}`} })
        .then(response => {

			if(this.state.gts_user_previous_resume_name==''){
				this.setState({
				      "gts_user_current_resume_name" : '',
                      "gts_user_previous_resume_name" : '',
				      "gts_current_resume_upload_date" : ''
                 })
		     }

		      if(this.state.gts_user_current_resume_name=='' && this.state.gts_user_previous_resume_name==''){
			                 window.location.reload();
		      }

             if(this.state.gts_user_previous_resume_name !== null){
                 this.setState({
                     "gts_user_current_resume_name" : this.state.gts_user_previous_resume_name,
                     "gts_current_resume_upload_date" : this.state.gts_previous_resume_upload_date,
                     "gts_user_previous_resume_name" : '',
                     "gts_previous_resume_upload_date" : ''
                 })
                 message = "Resume deleted successfully"
                  this.setState({message : message})
             }

        })
        .catch(err => {
            error = "Not able to delete resume "+this.state.gts_user_current_resume_name;
            this.setState({error : error})
       })
    }

    deletePreviousResume = e =>{
        e.preventDefault();
        let message =this.state.message;
        let error = this.state.error;

        var previousResumeURL = previousResumeDeleteURL + '/'+this.state.gts_user_resume_id;

        axios.delete(previousResumeURL,{ headers: {"Auth_Token" : `Bearer ${token}`} })
        .then(response => {
             this.setState({
                "gts_user_previous_resume_name" : '',
                "gts_previous_resume_upload_date" : ''
            })
            message = "Resume deleted successfully"
             this.setState({message : message})
        })
        .catch(err => {
            error = "Not able to delete resume "+this.state.gts_user_previous_resume_name;
            this.setState({error : error})
       })
    }

    componentDidMount() {
        var get_url = getUserResumeURL + '/'+userLoginDetails.user_id;
        axios.get(get_url,{ headers: {"Auth_Token":`Bearer ${token}`} })
        .then(response => {
            console.log(response.data)
            this.setState({
                "gts_user_current_resume_name" : response.data.gts_user_current_resume_name,
                "gts_user_previous_resume_name" : response.data.gts_user_previous_resume_name,
                "gts_user_resume_id" : response.data.gts_user_resume_id,
                "gts_current_resume_upload_date": Moment(response.data.gts_current_resume_upload_date).format('DD-MMMM-YYYY')
            })
            if(response.data.gts_user_previous_resume_name !== null){
				this.setState({"gts_previous_resume_upload_date":Moment(response.data.gts_previous_resume_upload_date).format('DD-MMMM-YYYY')})
			}
        })
        .catch(err =>{
            console.log(err.response)
        })

    }

    clearMessage = e =>{
        this.setState({message : ''});
        this.setState({error : ''});
        this.setState({errors : ''});
    }

    render() {

        const {
            gts_user_current_resume_name,
            gts_user_previous_resume_name,
        } = this.state;

    return(

        <Container className="align-items-center mt-4">
            <div className="container align-items-center">
             <Row>
                 <Col>
                     <Form>
                         <div className="border border-dark  rounded-lg" >
                                <div className="container align-items-center"></div>

                                <h5 className="mb6-3 p-2"><b>Upload CV/Resume &nbsp;&nbsp;</b> </h5>
                                  <div style={{borderBottomColor: 'black',borderBottomWidth: 1.5}} />
                                <br/>
                                <div className="row">
                                    <div className="col-2 pl-0 pr-0" align="left">
                                      <p><h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;File Name:</h6></p>
                                    </div>
                                    <div className="col-8 pr-0 pl-0" align="left">
                                       <input type="file" name="file" id="file" onBlur={this.clearMessage} onChange={(e)=>this.onChange(e)} size="40"></input>
                                    </div>
                                    <div className="col-2 pr-5" align="right">
                                     <button type="button" data-toggle="modal" data-target={(this.state.file.name || this.state.gts_user_current_resume_name == null || this.state.gts_user_previous_resume_name == null || this.state.gts_user_current_resume_name == '' || this.state.gts_user_previous_resume_name == '') ? "#confirm" : "#confirmForThirdFile"} onClick={this.addButtonListener} onBlur={this.clearMessage} name="file" style={{outline:"black",display: "inline"}}><i class="fas fa-upload"></i></button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4">
                                      <span type="text" name="gts_user_current_resume_name" id="gts_user_current_resume_name">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.gts_user_current_resume_name}</span>
                                    </div>
                                    <div className="col-3 ">
                                      <span type="text" name="gts_current_resume_upload_date" id="gts_current_resume_upload_date">{this.state.gts_current_resume_upload_date}</span>
                                    </div>
                                    <div className="col-2 " style={{color : "red"}}>
                                     <button onClick={this.downloadCurrentResume} onBlur={this.clearMessage} style={{outline:"black" , display: this.state.gts_user_current_resume_name ? 'block' : 'none' }}><p><h6>Download</h6></p></button>
                                    </div>

                                    <div className="col-1"  style={{color : "black"}}>
                                     <button  onClick ={this.deleteCurrentResume} onBlur={this.clearMessage} style={{outline:"black" , display: this.state.gts_user_current_resume_name ? 'block' : 'none' }}><p><h6>Delete</h6></p></button>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-4">
                                      <span type="text" name="gts_user_previous_resume_name" id="gts_user_previous_resume_name">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.gts_user_previous_resume_name}</span>
                                    </div>
                                    <div className="col-3">
                                      <span type="text" name="gts_previous_resume_upload_date" id="gts_previous_resume_upload_date">{this.state.gts_previous_resume_upload_date}</span>
                                    </div>
                                    <div className="col-2 " style={{color : "red"}}>
                                     <button onClick={this.downloadPreviousResume} onBlur={this.clearMessage} style={{outline:"black", display: this.state.gts_user_previous_resume_name ? 'block' : 'none' }}><p><h6>Download</h6></p></button>
                                    </div>

                                    <div className="col-1"  style={{color : "black"}}>
                                     <button onClick ={this.deletePreviousResume} onBlur={this.clearMessage} style={{outline:"black", display: this.state.gts_user_previous_resume_name ? 'block' : 'none'}}><p><h6>Delete</h6></p></button>
                                    </div>

                                </div>
                                <span style={{color : "#228B22" , width : "500px"}}><center>{this.state.message}</center></span>
                                <span style={{color : "red" , width : "500px"}}><center>{this.state.error}</center></span>
                                <span style={{color : "red" , width : "500px"}}><center>{this.state.errors}</center></span>
                            </div>
                            <div id="confirmForThirdFile" class="modal fade" role="dialog">
                         <div class="modal-dialog">
                             <div class="modal-content">
                                 <div class="modal-header">
                                     <h5 class="modal-title"></h5>
                                 </div>
                                 <div class="modal-body">
                                     <div className="container" >
                                         <div class="row-sm m-0  text-left  ">
                                             <div>
                                                 <label class="text-dark">Maximum two resumes can be uploaded.</label>
                                             </div>
                                             <div  class="text-inline" align = "right">
                                                 <Button type="button" class="close" data-dismiss="modal" onClick={this.cancelHandler}>OK</Button>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                             </div>
                         </div>
                      </div>
                      <div id="confirm" class="modal fade" role="dialog">
                      <div class="modal-dialog">
                             <div class="modal-content">
                                 <div class="modal-header">
                                     <h5 class="modal-title"></h5>
                                 </div>
                                 <div class="modal-body">
                                     <div className="container" >
                                         <div class="row-sm m-0  text-left  ">
                                             <div>
                                                 <label class="text-dark">Uploading file {this.state.file.name}.</label>
                                             </div>
                                             <div  class="text-inline" align = "right">
                                                    <Button id ="test" type="button" data-dismiss="modal" onClick={this.uploadResume}>Confirm</Button>
                                                    <Button type="button" class="close" data-dismiss="modal" onClick={this.cancelHandler}>Cancel</Button>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                             </div>
                         </div>
                      </div>
                     </Form>
                 </Col>
             </Row>
        </div>
      </Container>
    );
};

}

export default ResumeUploadComponent;