import React, { Component } from "react";
import  "./SPServiceApplicationsComponent.css";
import 'bootstrap/dist/css/bootstrap.css'
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import axios from "axios";
import ls from "local-storage";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import DropdownButton from 'react-bootstrap/DropdownButton'
import Moment from 'moment';
import ShowMoreText from 'react-show-more-text';
import { endpoints_properties } from '../../properties/EndPointsProperties.js';
import { api_properties } from '../../properties/APIProperties.js';

import {
  Container,
  Grid,
  Slider,
  Paper,
  FormLabel,
  Select,
  Input,
  Checkbox,
  ListItemText,
  MenuItem,
  FormControl,
  Chip,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { Button, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { Dropdown,InputGroup,Form } from "react-bootstrap";

var token = ls.get('token');
var env=ls.get('ENV');

var	activeServiceTitlesURL="";
var	getServiceApplicationsByServiceURL="";
var	getApplicationsByApplicantID="";
var	activeSkillsURL="";
var	applicationsBySkillsURL="";


var userLoginDetails = ls.get("userLoginDetails");
var userPersonalDetails=ls.get('userPersonalDetails');
if(userLoginDetails!==null){
var gts_user_id = userLoginDetails.user_id;
}



if(env=="LOCAL"){
	activeServiceTitlesURL=endpoints_properties.ENDPOINT_SERVICE_TITLES_LOCAL+api_properties.API_GET_ACTIVE_SERVICE_TITLES;
	getServiceApplicationsByServiceURL=endpoints_properties.ENDPOINT_SERVICE_APPLICATIONS_LOCAL+api_properties.API_GET_SERVICE_APPLICATIONS_BY_SERVICE;
	getApplicationsByApplicantID=endpoints_properties.ENDPOINT_SERVICE_APPLICATIONS_LOCAL+api_properties.API_GET_SERVICE_APPLICATIONS_BY_APPLICANT_ID;
	activeSkillsURL=endpoints_properties.ENDPOINT_SKILLS_LOCAL+api_properties.API_GET_ACTIVE_SKILLS;
	applicationsBySkillsURL=endpoints_properties.ENDPOINT_SERVICE_APPLICATIONS_LOCAL+api_properties.API_GET_SERVICE_APPLICATIONS_BY_SKILL
}
else{
	activeServiceTitlesURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_SERVICE_TITLES;
	getServiceApplicationsByServiceURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_SERVICE_APPLICATIONS_BY_SERVICE;
	getApplicationsByApplicantID=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_SERVICE_APPLICATIONS_BY_APPLICANT_ID;
	activeSkillsURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_SKILLS;
	applicationsBySkillsURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_SERVICE_APPLICATIONS_BY_SKILL

}



class SPActiveServiceApplicationsComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      validated:false,
      myJobApplications:[],
      myJobApplicationsBySkill:[],
      myJobApplicationsByService:[],
      jobTitles:[],
      jobTitleName:[],
      gts_job_title_id:0,
      selectedServices:[],
      selectedServiceIds:[],
      skills: [],
      skillName: [],
      gts_job_skill_ids:0,
      selectedSkills: [],
      selectedSkillIds: [],
      jobSkillName : [],
      search: "",
    };
    this.cancel = "";
    this.changeHandler = this.changeHandler.bind(this)
  }

  changeHandler = (e) => {
   this.setState({ [e.target.name] : e.target.value})
  }

  componentDidMount(){
    var getURL = getApplicationsByApplicantID+"/"+gts_user_id;
    axios.get(getURL,{ headers: {"Auth_Token" : `Bearer ${token}`} })
    .then(response =>{
      console.log(response.data)
      var jobs=[];
        for(var i=0; i<response.data.length; i++){
          if(response.data[i].gts_job_application_status != 'REJECTED' && response.data[i].gts_job_application_status != 'OFFERED'){
            jobs.push(response.data[i])
          }
          this.setState({myJobApplications: jobs})
         }
    })
  }

  ServiceTitleAutoCompleteChangeHandler = (input)=>{
    axios
      .get(activeServiceTitlesURL, { headers: { Auth_Token: `Bearer ${token}` },
      })
      .then((response)=>{
        this.state.jobTitles = response.data;
        if (this.state.jobTitles.length != 0)
        this.state.jobTitleName.length = [];
          this.state.jobTitles.forEach((jobTitle) => {
            this.state.jobTitleName.push(jobTitle.gts_job_title_name);
            this.state.jobTitleName.filter((value) => value.includes(input));
          })
      })
      .catch((error)=>{
        console.log(error);
      })
  }

  saveJobTitleAutoCompleteInput = (event, value) => {
    this.setState({
      errorMessage: "",
      successMessage: ""
    })
    this.state.selectedServiceIds = [];
    this.state.selectedServices = value;
    console.log(this.state.selectedServices);
    this.state.selectedServices.forEach((selectedService) => {
      this.state.jobTitles.forEach((service) => {
        if (selectedService === service.gts_job_title_name) {
          this.state.selectedServiceIds.push(service.gts_job_title_id);
        }
      });
    });
 };

 SKillAutoCompleteChangeHandler = (input)=>{
  axios
    .get(activeSkillsURL, { headers: { Auth_Token: `Bearer ${token}` },
    })
    .then((response)=>{
      this.state.skills = response.data;
      if (this.state.skills.length != 0)
      this.state.skillName.length = [];
        this.state.skills.forEach((skill) => {
          this.state.skillName.push(skill.gts_skill_name);
          this.state.skillName.filter((value) => value.includes(input));
        })
    })
    .catch((error)=>{
      console.log(error);
    })
 }

 saveJobSkillAutoCompleteInput = (event, value) => {
    this.setState({
      errorMessage: "",
      successMessage: ""
    })
    this.state.selectedSkillIds = [];
    this.state.selectedSkills = value;
    console.log(this.state.selectedSkills);
    this.state.selectedSkills.forEach((selectedSkill) => {
      this.state.skills.forEach((skill) => {
        if (selectedSkill === skill.gts_skill_name) {
          this.state.selectedSkillIds.push(skill.gts_skill_id);
        }
      });
    });
  };

  validateFields = e => {
     let formIsValid = true;
     let jobTitleError = this.state.jobTitleError;
     if (this.state.selectedServiceIds == 0 || this.state.selectedServiceIds=='' || this.state.selectedServiceIds== null || this.state.selectedServiceIds==undefined) {
       formIsValid = false;
       jobTitleError = "Please select service name.";
       this.setState({
         validated: false,
         jobTitleError: jobTitleError
       });
     }
     else{
       jobTitleError = '';
       this.setState({
         validated: true,
         jobTitleError: jobTitleError
       });
     }

     let jobSkillError = this.state.jobSkillError;
     if (this.state.selectedSkillIds == 0 || this.state.selectedSkillIds=='' || this.state.selectedSkillIds== null || this.state.selectedSkillIds==undefined) {
       formIsValid = false;
       jobSkillError = "Please select skill name.";
       this.setState({
         validated: false,
         jobSkillError: jobSkillError
       });
     }
     else{
      jobSkillError = '';
       this.setState({
         validated: true,
         jobSkillError: jobSkillError
       });
     }
 }

 searchJobApplications = e =>{
   if((this.state.selectedServiceIds == '' && this.state.selectedSkillIds == '')
       || (this.state.selectedServiceIds == null && this.state.selectedSkillIds == null)){
    this.setState({error : " Please select the keywords"})
   }
   else{
    if(this.state.selectedServiceIds == '' || this.state.selectedServiceIds == null){
      this.setState({myJobApplicationsByService: []})
    }
    else{
    var url=getServiceApplicationsByServiceURL+'/'+userLoginDetails.user_id+"/"+this.state.selectedServiceIds;
    axios.get(url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
    .then(response =>{
      console.log( response.data)
      var jobs=[];
        for(var i=0; i<response.data.length; i++){
          if(response.data[i].gts_job_application_status != 'REJECTED' && response.data[i].gts_job_application_status != 'OFFERED'){
            jobs.push(response.data[i])
          }
          else{
            this.setState({error: "This job has been already closed."})
          }
          this.setState({myJobApplicationsByService: jobs})
         }
         this.setState({myJobApplications : []})
         this.state.myJobApplicationsBySkill.forEach((skill)=>{
          this.state.myJobApplicationsByService.forEach((job)=>{
            if(skill.gts_job_application_id == job.gts_job_application_id){
              this.setState({myJobApplications: this.state.myJobApplicationsBySkill})
              this.setState({myJobApplicationsByService : []})
              this.setState({myJobApplicationsBySkill : []})
            }
          })
        })
         if(response.data == ''){
          var error="Job applications are not avaliable for this service name."
          this.setState({myJobApplicationsByService:[]})
          this.setState({error: error})
        }
    })
    .catch(error => {
      this.setState({status:400})
      var error="Job applications are not avaliable for this service name."
      this.setState({myJobApplicationsByService:[]})
      this.setState({myJobApplications:[]})
      this.setState({error: error})
      })
    }
      if(this.state.selectedSkillIds == '' || this.state.selectedSkillIds == null){
        this.setState({myJobApplicationsBySKill: []})
      }
      else{
      var url=applicationsBySkillsURL+"/"+userLoginDetails.user_id+"/"+this.state.selectedSkillIds;
      axios.get(url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
      .then(response =>{
        console.log( response.data)
        var jobs=[];
        for(var i=0; i<response.data.length; i++){
          if(response.data[i].gts_job_application_status != 'REJECTED' && response.data[i].gts_job_application_status != 'OFFERED'){
            jobs.push(response.data[i])
          }
          else{
            this.setState({error: "This job has been already closed."})
          }
          this.setState({myJobApplicationsBySkill: jobs})
         }
           this.setState({myJobApplications : []})
           this.state.myJobApplicationsBySkill.forEach((skill)=>{
            this.state.myJobApplicationsByService.forEach((job)=>{
              if(skill.gts_job_application_id == job.gts_job_application_id){
                this.setState({myJobApplications: this.state.myJobApplicationsBySkill})
                this.setState({myJobApplicationsByService : []})
                this.setState({myJobApplicationsBySkill : []})
              }
            })
          })
           if(response.data == ''){
            var error="Job applications are not avaliable for this skill name."
            this.setState({myJobApplicationsBySkill:[]})
            this.setState({myJobApplications:[]})
            this.setState({error: error})
          }
      })
      .catch(error => {
        this.setState({status:400})
        var error="Job applications are not avaliable for this skill name."
        this.setState({myJobApplications:[]})
        this.setState({myJobApplicationsBySkill:[]})
        this.setState({myJobApplications:[]})
        this.setState({error: error})
        })
      }
   }
 }

 clearError= e=>{
  this.setState({error:''})
  this.setState({message:''})
  this.setState({updateError:''})
  this.setState({updateMessage: ''})
  this.setState({appliedMessage: ''})
 }

  clearAllFields = () =>{
    this.setState({
      selectedServices: [],
      selectedAdvServices: [],
    })
  }

   render() {
    return (
     <div>
       <br/>
       {/* -----------------------------------------SEARCH--------------------------------------------------------- */}
       <div>
      <div className="row pl-24">
        <div class="col-7">
       <h5 style={{fontSize:"18px",paddingTop: "10px"}} className="h4-searchtext">Service Name&nbsp;&nbsp;</h5>
            <Autocomplete
              multiple
              options={this.state.jobTitleName}
              style={{ width: "350px", outlineColor: "black" }}
              filterSelectedOptions
              onChange={this.saveJobTitleAutoCompleteInput}
              renderInput={(params) => { this.ServiceTitleAutoCompleteChangeHandler(params);
                return (
                <TextField {...params}
                variant="outlined"
                placeholder="Service Name" />
                )}}
            />
            </div>
            <div class="col">
         <h5 style={{fontSize:"18px",paddingTop: "12px"}} className="h4-searchtext">Skill Name&nbsp;&nbsp;</h5>
            <Autocomplete
              multiple
              options={this.state.skillName}
              style={{ width: "350px", outlineColor: "black" }}
              filterSelectedOptions
              onChange={this.saveJobSkillAutoCompleteInput}
              renderInput={(params) => { this.SKillAutoCompleteChangeHandler(params);
                return (
                <TextField {...params}
                variant="outlined"
                placeholder="Skill Name" />
                )}}
            />
            </div>
            </div>
            <div class="offset-5 pl-10">
            <Button color="primary" onClick={this.searchJobApplications} onBlur={this.clearError.bind(this)}>Search</Button>
            </div>
         </div>

            <span style={{color: "red"}}><center><b>{this.state.error}</b></center></span>
    {/* ------------------------------------------DISPLAY ACTIVE AND BASED ON SERVICE------------------------------------------------------------------ */}
        {this.state.myJobApplications.map((item)=>(
         <InputGroup>
           <Grid container spacing={2}>
            <Grid item xs={userLoginDetails.primary_role=='SERVICE_PROVIDER'? 11 : 10}>
              <div class="mt-4">
               <div class="border border-dark rounded-lg offset-1">
                 <div class="row p-1" >
                   <div class="col-4 p-3 pl-3" >
                   <h5 style={{fontSize:"15px"}}><b>Service ID&nbsp;:&nbsp;{item.gts_applied_job_id}</b></h5>
                     <h5 style={{fontSize:"15px"}}><strong>Service Name: </strong>{item.gtsJobDetail.job_title_name.toUpperCase() }</h5>
                     <h5 style={{fontSize:"15px"}}><strong>Company: </strong><span style={{color:'black'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.gtsJobDetail.company_name===null?"":item.gtsJobDetail.company_name.toUpperCase() }</span></h5>
                    <h5 style={{fontSize:"15px"}}><strong>Job Description: </strong><span style={{color:'black'}}>
                     <ShowMoreText
                        lines={3}
                        more='Show more'
                        less='Show less'
                        className='content-css'
                        anchorClass='my-anchor-css-class'
                        onClick={this.executeOnClick}
                        expanded={false}
                        width={280}
                      >
                       {item.gtsJobDetail.gts_job_description}
                      </ShowMoreText></span></h5>

                   </div>

                    <div class="col-3 p-3">
                      <br/>
                      <h6 style={{fontSize:"15px"}}>Status: <span style={{color:'red'}}>{item.gts_job_application_status.toUpperCase() }</span></h6>
                      <br/>
                      <h6 style={{fontSize:"15px"}}>Location:<span style={{color:'red'}}>{item.gtsJobDetail.city_name}</span></h6>
                    </div>

                   <div class="col-3 p-3">
                     <br/>
                     {item.gtsJobDetail.gts_job_post_skill_ids.map((skill)=>{
                        this.state.jobSkillName.push(skill.gts_skill_name)
                      })}
                      <h6 style={{fontSize:"15px"}}>Skills: <span style={{color:'red'}}>{this.state.jobSkillName.toString()}</span></h6>
                      <br/>
                      {item.gtsJobDetail.gts_job_min_exp_in_months == 0 || item.gtsJobDetail.gts_job_min_exp_in_months == '' ? <h6>Experience: '' </h6> :
                      <h6 style={{fontSize:"15px"}}>Experience: <span style={{color:'red'}}>{item.gtsJobDetail.gts_job_min_exp_in_months} months</span></h6>
                      }

                    </div>

                    <div class="col-0 p-2" text-align="right">
                      <br/>
                      <button
                       className="btn btn-primary"
                       data-toggle="modal"
                       data-target={"#viewJob"+item.gts_job_application_id}
                       onClick={this.state.is_company_requirement = item.gts_job_is_company_requirement}
                       style={{backgroundColor:"white",color:"white", align:"right", borderRadius:"15px", fontSize:"12px"}}>
                       <b>View</b>
                      </button>
                    </div>
                  </div>
             </div>
             </div>
       <div id={"viewJob"+item.gts_job_application_id} class="modal fade" role="dialog">
         <div class="modal-dialog modal-lg" >
           <div class="modal-content">
              <div class="modal-body">
                <div className="container" >
                  <div class="row-sm m-0  text-left">
                  <div class="row-0" align="right">
                     <div class="col-0" align="right">
                         <button  type="button" class="close" data-dismiss="modal" ><i  class="fas fa-window-close fa-lg"></i></button>
                       </div>
                     </div>
                      <div class="row" align="left">
                        <div class="col" align="left">
                          <br/>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Service&nbsp;Application&nbsp;ID&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_application_id}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Service&nbsp;ID&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_applied_job_id}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                        </div>
                        <div class="col" align="right">
                          <br/>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Application Status&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_application_status}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Applicant ID&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_applicant_id}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                        </div>
                     </div>

                      <div class="row p-3" align="left">
                          <div class="col" align="left">
                          <h6 style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Applicant&nbsp;Proposal&nbsp;:&nbsp;</b>
                          <br/>{item.gts_applicant_proposal}</label></h6>
                          </div>
                      </div>

                      <div class="row p-3" align="left">
                        <div class="col" align="left">
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Application&nbsp;Date&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_application_date!==null ? Moment(item.gts_job_application_date).format('DD-MMM-YYYY') : ''}</span></label></h6>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Reviwed&nbsp;Date&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_application_reviewed_date!==null ?Moment(item.gts_job_application_reviewed_date).format('DD-MMM-YYYY') : ''}</span></label></h6>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Interview&nbsp;Proposed&nbsp;Date&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_proposed_for_interview_date!==null ?Moment(item.gts_job_proposed_for_interview_date).format('DD-MMM-YYYY') : ''}</span></label></h6>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Offer&nbsp;Date&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_application_offered_date!==null ?Moment(item.gts_job_application_offered_date).format('DD-MMM-YYYY') : ''}</span></label></h6>
                        </div>
                        <div class="col" align="right">
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Application&nbsp;Updated&nbsp;Date&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_application_updated_date!==null ?Moment(item.gts_job_application_updated_date).format('DD-MMM-YYYY') : ''}</span></label></h6>

                          {item.gts_job_application_shortlisted_date!==null ?
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Shortlisted&nbsp;Date&nbsp;:&nbsp;</b><span style={{color:'red'}}>{Moment(item.gts_job_application_shortlisted_date).format('DD-MMM-YYYY')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></label></h6>
                          :<h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Shortlisted&nbsp;Date&nbsp;:&nbsp;</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>}
                           {item.gts_job_application_selected_date!==null ?
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Selected&nbsp;Date&nbsp;:&nbsp;</b><span style={{color:'red'}}>{Moment(item.gts_job_application_selected_date).format('DD-MMM-YYYY')}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                          :<h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Selected&nbsp;Date&nbsp;:&nbsp;</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>}
                        </div>
                     </div>

                     <div class="row p-3" align="left">
                          <div class="col" align="left">
                          <h6 style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Feedback from Employer/Recruiter&nbsp;:&nbsp;</b>
                          <br/>{item.gts_applicant_proposal}</label></h6>
                          </div>
                      </div>

                  </div>
                </div>
              </div>
           </div>
         </div>
       </div>
       <div style={{display: this.state.jobSkillName!==null ? 'none' : 'block'}}>
         { this.state.jobSkillName =  []}
       </div>

      </Grid>
    </Grid>
  </InputGroup> ))}
  {/* -----------------------------------------------BASED ON JOB--------------------------------------------------------- */}
  {this.state.myJobApplicationsByService.map((item)=>(
    // this.state.myJobApplicationsBySkill.map((jobAppl) => jobAppl.gts_job_application_id != item.gts_job_application_id ?
         <InputGroup>
           <Grid container spacing={2}>
            <Grid item xs={userLoginDetails.primary_role=='SERVICE_PROVIDER'? 11 : 10}>
              <div class="mt-4">
               <div class="border border-dark rounded-lg offset-1">
                 <div class="row p-1" >
                   <div class="col-4 p-3 pl-3" >
                   <h5 style={{fontSize:"15px"}}><b>Service ID&nbsp;:&nbsp;{item.gts_applied_job_id}</b></h5>
                     <h5 style={{fontSize:"15px"}}><strong>Service Name: </strong>{item.gtsJobDetail.job_title_name.toUpperCase() }</h5>
                     <h5 style={{fontSize:"15px"}}><strong>Company: </strong><span style={{color:'black'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.gtsJobDetail.company_name===null?"":item.gtsJobDetail.company_name.toUpperCase() }</span></h5>
                    <h5 style={{fontSize:"15px"}}><strong>Job Description: </strong><span style={{color:'black'}}>
                     <ShowMoreText
                        lines={3}
                        more='Show more'
                        less='Show less'
                        className='content-css'
                        anchorClass='my-anchor-css-class'
                        onClick={this.executeOnClick}
                        expanded={false}
                        width={280}
                      >
                       {item.gtsJobDetail.gts_job_description}
                      </ShowMoreText></span></h5>

                   </div>

                    <div class="col-3 p-3">
                      <br/>
                      <h6 style={{fontSize:"15px"}}>Status: <span style={{color:'red'}}>{item.gts_job_application_status.toUpperCase() }</span></h6>
                      <br/>
                      <h6 style={{fontSize:"15px"}}>Location:<span style={{color:'red'}}>{item.gtsJobDetail.city_name}</span></h6>
                    </div>

                   <div class="col-3 p-3">
                     <br/>
                     {item.gtsJobDetail.gts_job_post_skill_ids.map((skill)=>{
                        this.state.jobSkillName.push(skill.gts_skill_name)
                      })}
                      <h6 style={{fontSize:"15px"}}>Skills: <span style={{color:'red'}}>{this.state.jobSkillName.toString()}</span></h6>
                      <br/>
                      {item.gtsJobDetail.gts_job_min_exp_in_months == 0 || item.gtsJobDetail.gts_job_min_exp_in_months == '' ? <h6>Experience: '' </h6> :
                      <h6 style={{fontSize:"15px"}}>Experience: <span style={{color:'red'}}>{item.gtsJobDetail.gts_job_min_exp_in_months} months</span></h6>
                      }

                    </div>

                    <div class="col-0 p-2" text-align="right">
                      <br/>
                      <button
                       className="btn btn-primary"
                       data-toggle="modal"
                       data-target={"#viewJob"+item.gts_job_application_id}
                       onClick={this.state.is_company_requirement = item.gts_job_is_company_requirement}
                       style={{backgroundColor:"white",color:"white", align:"right", borderRadius:"15px", fontSize:"12px"}}>
                       <b>View</b>
                      </button>
                    </div>
                  </div>
             </div>
             </div>
       <div id={"viewJob"+item.gts_job_application_id} class="modal fade" role="dialog">
         <div class="modal-dialog modal-lg" >
           <div class="modal-content">
              <div class="modal-body">
                <div className="container" >
                  <div class="row-sm m-0  text-left">
                  <div class="row-0" align="right">
                     <div class="col-0" align="right">
                         <button  type="button" class="close" data-dismiss="modal" ><i  class="fas fa-window-close fa-lg"></i></button>
                       </div>
                     </div>
                      <div class="row" align="left">
                        <div class="col" align="left">
                          <br/>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Service&nbsp;Application&nbsp;ID&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_application_id}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Service&nbsp;ID&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_applied_job_id}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                        </div>
                        <div class="col" align="right">
                          <br/>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Application Status&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_application_status}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Applicant ID&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_applicant_id}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                        </div>
                     </div>

                      <div class="row p-3" align="left">
                          <div class="col" align="left">
                          <h6 style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Applicant&nbsp;Proposal&nbsp;:&nbsp;</b>
                          <br/>{item.gts_applicant_proposal}</label></h6>
                          </div>
                      </div>

                      <div class="row p-3" align="left">
                        <div class="col" align="left">
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Application&nbsp;Date&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_application_date!==null ? Moment(item.gts_job_application_date).format('DD-MMM-YYYY') : ''}</span></label></h6>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Reviwed&nbsp;Date&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_application_reviewed_date!==null ?Moment(item.gts_job_application_reviewed_date).format('DD-MMM-YYYY') : ''}</span></label></h6>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Interview&nbsp;Proposed&nbsp;Date&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_proposed_for_interview_date!==null ?Moment(item.gts_job_proposed_for_interview_date).format('DD-MMM-YYYY') : ''}</span></label></h6>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Offer&nbsp;Date&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_application_offered_date!==null ?Moment(item.gts_job_application_offered_date).format('DD-MMM-YYYY') : ''}</span></label></h6>
                        </div>
                        <div class="col" align="right">
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Application&nbsp;Updated&nbsp;Date&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_application_updated_date!==null ?Moment(item.gts_job_application_updated_date).format('DD-MMM-YYYY') : ''}</span></label></h6>

                          {item.gts_job_application_shortlisted_date!==null ?
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Shortlisted&nbsp;Date&nbsp;:&nbsp;</b><span style={{color:'red'}}>{Moment(item.gts_job_application_shortlisted_date).format('DD-MMM-YYYY')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></label></h6>
                          :<h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Shortlisted&nbsp;Date&nbsp;:&nbsp;</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>}
                           {item.gts_job_application_selected_date!==null ?
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Selected&nbsp;Date&nbsp;:&nbsp;</b><span style={{color:'red'}}>{Moment(item.gts_job_application_selected_date).format('DD-MMM-YYYY')}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                          :<h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Selected&nbsp;Date&nbsp;:&nbsp;</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>}
                        </div>
                     </div>

                     <div class="row p-3" align="left">
                          <div class="col" align="left">
                          <h6 style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Feedback from Employer/Recruiter&nbsp;:&nbsp;</b>
                          <br/>{item.gts_applicant_proposal}</label></h6>
                          </div>
                      </div>

                  </div>
                </div>
              </div>
           </div>
         </div>
       </div>
       <div style={{display: this.state.jobSkillName!==null ? 'none' : 'block'}}>
         { this.state.jobSkillName =  []}
       </div>

      </Grid>
    </Grid>
  </InputGroup>))}
  {/* ----------------------------------------------BASED ON SKILL-------------------------------------------------------------- */}
  {this.state.myJobApplicationsBySkill.map((item)=>(
    // this.state.myJobApplicationsByService.map((jobAppl) => jobAppl.gts_job_application_id != item.gts_job_application_id?
         <InputGroup>
           <Grid container spacing={2}>
            <Grid item xs={userLoginDetails.primary_role=='SERVICE_PROVIDER'? 11 : 10}>
              <div class="mt-4">
               <div class="border border-dark rounded-lg offset-1">
                 <div class="row p-1" >
                   <div class="col-4 p-3 pl-3" >
                   <h5 style={{fontSize:"15px"}}><b>Service ID&nbsp;:&nbsp;{item.gts_applied_job_id}</b></h5>
                     <h5 style={{fontSize:"15px"}}><strong>Service Name: </strong>{item.gtsJobDetail.job_title_name.toUpperCase() }</h5>
                     <h5 style={{fontSize:"15px"}}><strong>Company: </strong><span style={{color:'black'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.gtsJobDetail.company_name===null?"":item.gtsJobDetail.company_name.toUpperCase() }</span></h5>
                    <h5 style={{fontSize:"15px"}}><strong>Job Description: </strong><span style={{color:'black'}}>
                     <ShowMoreText
                        lines={3}
                        more='Show more'
                        less='Show less'
                        className='content-css'
                        anchorClass='my-anchor-css-class'
                        onClick={this.executeOnClick}
                        expanded={false}
                        width={280}
                      >
                       {item.gtsJobDetail.gts_job_description}
                      </ShowMoreText></span></h5>

                   </div>

                    <div class="col-3 p-3">
                      <br/>
                      <h6 style={{fontSize:"15px"}}>Status: <span style={{color:'red'}}>{item.gts_job_application_status.toUpperCase() }</span></h6>
                      <br/>
                      <h6 style={{fontSize:"15px"}}>Location:<span style={{color:'red'}}>{item.gtsJobDetail.city_name}</span></h6>
                    </div>

                   <div class="col-3 p-3">
                     <br/>
                     {item.gtsJobDetail.gts_job_post_skill_ids.map((skill)=>{
                        this.state.jobSkillName.push(skill.gts_skill_name)
                      })}
                      <h6 style={{fontSize:"15px"}}>Skills: <span style={{color:'red'}}>{this.state.jobSkillName.toString()}</span></h6>
                      <br/>
                      {item.gtsJobDetail.gts_job_min_exp_in_months == 0 || item.gtsJobDetail.gts_job_min_exp_in_months == '' ? <h6>Experience: '' </h6> :
                      <h6 style={{fontSize:"15px"}}>Experience: <span style={{color:'red'}}>{item.gtsJobDetail.gts_job_min_exp_in_months} months</span></h6>
                      }

                    </div>

                    <div class="col-0 p-2" text-align="right">
                      <br/>
                      <button
                       className="btn btn-primary"
                       data-toggle="modal"
                       data-target={"#viewJob"+item.gts_job_application_id}
                       onClick={this.state.is_company_requirement = item.gts_job_is_company_requirement}
                       style={{backgroundColor:"white",color:"white", align:"right", borderRadius:"15px", fontSize:"12px"}}>
                       <b>View</b>
                      </button>
                    </div>
                  </div>
             </div>
             </div>
       <div id={"viewJob"+item.gts_job_application_id} class="modal fade" role="dialog">
         <div class="modal-dialog modal-lg" >
           <div class="modal-content">
              <div class="modal-body">
                <div className="container" >
                  <div class="row-sm m-0  text-left">
                  <div class="row-0" align="right">
                     <div class="col-0" align="right">
                         <button  type="button" class="close" data-dismiss="modal" ><i  class="fas fa-window-close fa-lg"></i></button>
                       </div>
                     </div>
                      <div class="row" align="left">
                        <div class="col" align="left">
                          <br/>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Service&nbsp;Application&nbsp;ID&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_application_id}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Service&nbsp;ID&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_applied_job_id}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                        </div>
                        <div class="col" align="right">
                          <br/>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Application Status&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_application_status}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Applicant ID&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_applicant_id}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                        </div>
                     </div>

                      <div class="row p-3" align="left">
                          <div class="col" align="left">
                          <h6 style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Applicant&nbsp;Proposal&nbsp;:&nbsp;</b>
                          <br/>{item.gts_applicant_proposal}</label></h6>
                          </div>
                      </div>

                      <div class="row p-3" align="left">
                        <div class="col" align="left">
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Application&nbsp;Date&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_application_date!==null ? Moment(item.gts_job_application_date).format('DD-MMM-YYYY') : ''}</span></label></h6>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Reviwed&nbsp;Date&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_application_reviewed_date!==null ?Moment(item.gts_job_application_reviewed_date).format('DD-MMM-YYYY') : ''}</span></label></h6>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Interview&nbsp;Proposed&nbsp;Date&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_proposed_for_interview_date!==null ?Moment(item.gts_job_proposed_for_interview_date).format('DD-MMM-YYYY') : ''}</span></label></h6>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Offer&nbsp;Date&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_application_offered_date!==null ?Moment(item.gts_job_application_offered_date).format('DD-MMM-YYYY') : ''}</span></label></h6>
                        </div>
                        <div class="col" align="right">
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Application&nbsp;Updated&nbsp;Date&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_application_updated_date!==null ?Moment(item.gts_job_application_updated_date).format('DD-MMM-YYYY') : ''}</span></label></h6>

                          {item.gts_job_application_shortlisted_date!==null ?
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Shortlisted&nbsp;Date&nbsp;:&nbsp;</b><span style={{color:'red'}}>{Moment(item.gts_job_application_shortlisted_date).format('DD-MMM-YYYY')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></label></h6>
                          :<h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Shortlisted&nbsp;Date&nbsp;:&nbsp;</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>}
                           {item.gts_job_application_selected_date!==null ?
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Selected&nbsp;Date&nbsp;:&nbsp;</b><span style={{color:'red'}}>{Moment(item.gts_job_application_selected_date).format('DD-MMM-YYYY')}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                          :<h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Selected&nbsp;Date&nbsp;:&nbsp;</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>}
                        </div>
                     </div>

                     <div class="row p-3" align="left">
                          <div class="col" align="left">
                          <h6 style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Feedback from Employer/Recruiter&nbsp;:&nbsp;</b>
                          <br/>{item.gts_applicant_proposal}</label></h6>
                          </div>
                      </div>

                  </div>
                </div>
              </div>
           </div>
         </div>
       </div>
       <div style={{display: this.state.jobSkillName!==null ? 'none' : 'block'}}>
         { this.state.jobSkillName =  []}
       </div>

      </Grid>
    </Grid>
  </InputGroup> ))}

  {/* --------------------------------------------------------------------------------------------------------------------------- */}
 <br/>
  </div>
  );
  }
}

export default SPActiveServiceApplicationsComponent;
