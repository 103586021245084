import React from 'react';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';


import ContactUsPage from './pages/common/ContactUsPage';
import AboutUsPage from './pages/common/AboutUsPage';
import CareerPage from './pages/common/CareerPage';
import ProductsPage from './pages/common/ProductsPage';
import IndustryPage from './pages/common/IndustryPage';

import GlobalTrainingsPage from './pages/trainings/GlobalTrainingsPage';
import TrainingForStudentsTraineesPage from './pages/trainings/TrainingForStudentsTraineesPage';
import TrainingForServiceProviderPage from './pages/trainings/TrainingForServiceProviderPage';
import TrainingForServiceConsumersPage from './pages/trainings/TrainingForServiceConsumersPage';
import TrainingForTrainersPage from './pages/trainings/TrainingForTrainersPage';


import LoginPage from './pages/common/LoginPage';
import RegistrationPage from './pages/common/RegistrationPage';
import GlobalHomePage from './pages/common/GlobalHomePage';

import ServiceConsumerProfilePage from './pages/service_consumer/ServiceConsumerProfilePage';
import ServiceConsumerHomePage from './pages/service_consumer/ServiceConsumerHomePage';
import TrainerHomePage from './pages/trainer/TrainerHomePage';
import TraineeHomePage from './pages/trainee/TraineeHomePage';
import AdminHomePage from './pages/admin/AdminHomePage';
import ServiceProviderProfilePage from './pages/service_provider/ServiceProviderProfilePage';
import ServiceProviderHomePage from './pages/service_provider/ServiceProviderHomePage';
import ServiceConsumerSkillsPage from './pages/service_consumer/ServiceConsumerSkillsPage';
import SCServiceActivityPage from './pages/service_consumer/SCServiceActivityPage';
import UserProjectPage from './pages/common/UserProjectPage';
import ServiceConsumerClosedServicePage from './pages/common/ServiceConsumerClosedServicePage';
import ServiceConsumerActiveJobApplicationsPage from './pages/service_consumer/ServiceConsumerActiveJobApplicationsPage';
import SCListOfClosedApplicationsPage from './pages/service_consumer/SCListOfClosedApplicationsPage';
import AdminProfilePage from './pages/admin/AdminProfilePage';
import RecruiterProfilePage from './pages/recruiter/RecruiterProfilePage';
import TraineeProfilePage from './pages/trainee/TraineeProfilePage';
import TrainerProfilePage from './pages/trainer/TrainerProfilePage';
import ChangePasswordPage from './pages/common/ChangePasswordPage';
import ManageRolePage from './pages/common/ManageRolePage';
import ProfileViewerPage from './pages/common/ProfileViewerPage'
import ServiceConsumerServicePostPage from './pages/service_consumer/ServiceConsumerServicePostPage';
import JobSearchSCPage from './pages/common/JobSearchSCPage';
import JobSearchAdminPage from './pages/common/JobSearchAdminPage';
import JobSearchSPPage from './pages/common/JobSearchSPPage';
import UserWorkExperiencePage from './pages/common/UserWorkExperiencePage';
import UserLanguagesPage from './pages/common/UserLanguagesPage';
import UserSalaryPage from './pages/common/UserSalaryPage';
import UserNoticePeriodPage from './pages/common/UserNoticePeriodPage';
import UserEducationsPage from './pages/common/UserEducationsPage';
import UserSkillsPage from './pages/common/UserSkillsPage';
import UserAddressesPage from './pages/common/UserAddressesPage';
import RecommendedServicePage from './pages/service_provider/RecommendedServicePage';
import SavedServicesPage from './pages/service_provider/SavedServicesPage';
import SPActiveServiceApplicationsPage from './pages/service_provider/SPActiveServiceApplicationsPage';
import SPClosedServiceApplicationsPage from './pages/service_provider/SPClosedServiceApplicationsPage';
import UnderConstructionPage from './pages/common/UnderConstructionPage';
import SearchServiceProviderPage from './pages/common/SearchServiceProviderPage';
import TopQualifiedServiceProviderPage from './pages/service_consumer/TopQualifiedServiceProviderPage';
import ManageSkillsPage from './pages/common/ManageSkillsPage';
import SearchServiceConsumerPage from './pages/common/SearchServiceConsumerPage';
import store from './redux/store';

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <Switch>
          <Route path="/" component={GlobalHomePage} exact></Route>
          <Route path="/home" component={GlobalHomePage}exact></Route>
          <Route path="/gts/about-us" component={AboutUsPage} exact></Route>
          <Route path="/gts/contact-us" component={ContactUsPage}></Route>
          <Route path="/gts/career" component={CareerPage} exact></Route>
          <Route path="/gts/products" component={ProductsPage} exact></Route>
          <Route path="/gts/industry" component={IndustryPage} exact></Route>
          <Route path="/gts/global-trainings" component={GlobalTrainingsPage}exact></Route>
          <Route path="/gts/global-trainings/training-for-students-trainees" component={TrainingForStudentsTraineesPage}exact></Route>
          <Route path="/gts/global-trainings/training-for-service-provider" component={TrainingForServiceProviderPage}exact></Route>
          <Route path="/gts/global-trainings/training-for-service-consumers" component={TrainingForServiceConsumersPage}exact></Route>
          <Route path="/gts/global-trainings/training-for-trainers" component={TrainingForTrainersPage}exact></Route>

          <Route path="/gts/login" component={LoginPage} exact></Route>
          <Route path="/gts/register" component={RegistrationPage}></Route>
          <Route path="/gts/service-provider-profile" component={ServiceProviderProfilePage}exact></Route>
          <Route path="/gts/service-provider-homepage" component={ServiceProviderHomePage}exact></Route>
          <Route path="/gts/admin-profile" component={AdminProfilePage}exact></Route>
          <Route path="/gts/trainee-profile" component={TraineeProfilePage}exact></Route>
          <Route path="/gts/trainer-profile" component={TrainerProfilePage}exact></Route>
          <Route path="/gts/service-consumer-profile" component={ServiceConsumerProfilePage}exact></Route>
          <Route path="/gts/service-consumer-homepage" component={ServiceConsumerHomePage}exact></Route>
          <Route path="/gts/trainer-homepage" component={TrainerHomePage}exact></Route>
          <Route path="/gts/trainee-homepage" component={TraineeHomePage}exact></Route>
          <Route path="/gts/admin-homepage" component={AdminHomePage}exact></Route>
          <Route path="/gts/recruiter-profile" component={RecruiterProfilePage}exact></Route>
          <Route path="/gts/global-home-page" component={GlobalHomePage} exact></Route>
          <Route path="/gts/change-password" component={ChangePasswordPage} exact></Route>
          <Route path="/gts/manage-skills" component={ServiceConsumerSkillsPage} exact></Route>
          <Route path="/gts/user-projects" component={UserProjectPage}exact></Route>
          <Route path="/gts/manage-roles" component={ManageRolePage}exact></Route>
          <Route path="/gts/your-profile-viewer" component={ProfileViewerPage}></Route>
          <Route path="/gts/post-a-service" component={ServiceConsumerServicePostPage}exact></Route>
          <Route path="/gts/sc-search-services" component={JobSearchSCPage}exact></Route>
          <Route path="/gts/admin-search-services" component={JobSearchAdminPage}exact></Route>
          <Route path="/gts/sp-search-services" component={JobSearchSPPage}exact></Route>
          <Route path="/gts/search-service-consumer" component={SearchServiceConsumerPage}exact></Route>
          <Route path="/gts/search-service-provider" component={SearchServiceProviderPage}exact></Route>
          <Route path="/gts/top-qualified-service-provider" component={TopQualifiedServiceProviderPage}exact></Route>
          <Route path="/gts/service-post-activty" component={SCServiceActivityPage}exact></Route>
          <Route path="/gts/active-applications" component={ServiceConsumerActiveJobApplicationsPage}exact></Route>
          <Route path="/gts/closed-applications" component={SCListOfClosedApplicationsPage}exact></Route>
          <Route path="/gts/closed-services" component={ServiceConsumerClosedServicePage}exact></Route>
          <Route path="/gts/user-work-experience" component={UserWorkExperiencePage}exact></Route>

          <Route path="/gts/user-addresses" component={UserAddressesPage}exact></Route>
          <Route path="/gts/user-skills" component={UserSkillsPage}exact></Route>
          <Route path="/gts/user-educations" component={UserEducationsPage}exact></Route>
          <Route path="/gts/user-languages" component={UserLanguagesPage}exact></Route>
          <Route path="/gts/user-salary" component={UserSalaryPage}exact></Route>
          <Route path="/gts/notice-period" component={UserNoticePeriodPage}exact></Route>

          <Route path="/gts/recommended-services" component={RecommendedServicePage}exact></Route>
          <Route path="/gts/recommend-tests" component={RecommendedServicePage}exact></Route>
          <Route path="/gts/saved-services" component={SavedServicesPage}exact></Route>
          <Route path="/gts/active-job-applications" component={SPActiveServiceApplicationsPage}exact></Route>
          <Route path="/gts/closed-job-applications" component={SPClosedServiceApplicationsPage}exact></Route>
          <Route path="/gts/total-revenue-generated" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/number-of-clients" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/number-of-sp-attending-trainings" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/job-statistics" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/chakuri-share-and-revenue" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/employment-agreement" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/current-employments" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/employment-agreement-history" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/recruitment-agreement" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/recruitment-agreement-history" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/candidate-agreement" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/candidate-agreement-history" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/training-wishes" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/job-wishes" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/notifications" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/messages" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/service-consumer-placement" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/service-provider-skills-and-trainings" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/service-provider-recruited" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/schedule-interviews" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/add-test" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/search-tests" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/assessment-evalution" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/self-assessment" component={ManageSkillsPage}exact></Route>
          <Route path="/gts/chakuri-assessment" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/employer-assessment" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/consult-with-recruiter" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/wish-for-service" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/search-training" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/recommended-training" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/training-in-progess" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/saved-training" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/training-completed" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/wish-for-training" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/make-payment" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/payment-history" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/subscribed-training" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/training-history" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/add-training" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/add-training-offer" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/list-of-training" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/training-applications" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/training-in-progress" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/trainee-recommendation" component={UnderConstructionPage}exact></Route>
          <Route path="/gts/trainee-search" component={UnderConstructionPage}exact></Route>
        </Switch>
      </Provider>

    </BrowserRouter>
  );
}

export default App;
