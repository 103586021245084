import React from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import ChangePasswordComponent from '../../components/common/ChangePasswordComponent';
import ls from 'local-storage';

import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert,
  ButtonGroup
} from 'reactstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AdminMenu from '../../components/admin/AdminMenu';
import TrainerMenu from '../../components/trainer/TrainerMenu';
import RecruiterMenu from '../../components/recruiter/RecruiterMenu';
import ServiceConsumerMenu from '../../components/service_consumer/ServiceConsumerMenu';
import ServiceProviderMenu from '../../components/service_provider/ServiceProviderMenu';
import TraineeMenu from '../../components/trainee/TraineeMenu';
import {Card, CardBody,CardText, FormControl, FormLabel,FormCheck } from 'react-bootstrap';

var token = ls.get("token");
var userLoginDetails = ls.get("userLoginDetails");

class ChangePasswordPage extends React.Component {
   constructor(props) {
	   super(props);
		if(token==null || token=="" || token=="undefined"){
		  window.location.href = '/gts/global-home-page';
		}

	}
  render() {
    return (
      <div>
       <Header/>
       {userLoginDetails.primary_role == 'ADMIN' ?
         <AdminMenu/> : ''
       }
       {userLoginDetails.primary_role == 'SERVICE_PROVIDER' ?
         <ServiceProviderMenu/> : ''
       }
       {userLoginDetails.primary_role == 'SERVICE_CONSUMER' ?
         <ServiceConsumerMenu/> : ''
       }
       {userLoginDetails.primary_role == 'RECRUITER' ?
         <RecruiterMenu/> : ''
       }
       {userLoginDetails.primary_role == 'TRAINER' ?
         <TrainerMenu/> : ''
       }
       {userLoginDetails.primary_role == 'TRAINEE' ?
         <TraineeMenu/> : ''
       }
       <ChangePasswordComponent/>
       <br/>
       <br/>
       <Footer/>
     </div>
    )
  }
}


export default ChangePasswordPage;



