import React, { Component } from 'react';

import { Button } from 'react-bootstrap';
import { faUser, faTrash, faEdit, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';
import styles from './PrimarySkillsComponent.css';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { black, white } from 'material-ui/styles/colors';
import { blue } from '@material-ui/core/colors';
import { colors } from '@material-ui/core';
import { darkBlack } from 'material-ui/styles/colors';
import ls from 'local-storage';
import { endpoints_properties } from '../../properties/EndPointsProperties.js';
import { api_properties } from '../../properties/APIProperties.js';

var env=ls.get('ENV');
var token = ls.get('token');
var userLoginDetails=ls.get('userLoginDetails');


var getUserSkillsURL="";
var getActiveSkillsURL="";
var addOrEditUserSkillsURL="";
var deleteUserSkillsURL="";

if(env=="LOCAL"){
	getUserSkillsURL=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_USER_SKILLS;
	getActiveSkillsURL=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_ACTIVE_SKILLS;
	addOrEditUserSkillsURL=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_USER_SKILLS;
	deleteUserSkillsURL=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_DELETE_USER_SKILLS;
}
else{
	getUserSkillsURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_USER_SKILLS;
	getActiveSkillsURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_SKILLS;
	addOrEditUserSkillsURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_USER_SKILLS;
	deleteUserSkillsURL=deleteUserSkillsURL;

}

class SecondarySkillsComponent extends Component{
    constructor(props) {
     super(props);
     this.state={
         counter:0,
         requiredSecondaryProficiency:'',
         requiredSecondarySkill:'',
         yearsOfExperienceSecondary:'',
         requiredSecondaryProficiencyValidationFlag:true,
         requiredSecondarySkillValidationFlag:false,
         yearsOfSecondaryExperienceValidationFlag:true,
         saveFlag:false,
         editSaveFlag:false,
         addValidationFalg:false,
         cancelFlag:false,
         userSkills:[],
         skills:[{
             gts_skill_id: 0,
             gts_skill_name: null,
             gts_skill_description: null,
             gts_skill_status: false
           }],
          userSkill:{
             gtsUserSkillId:0,
             gtsUser:0,
             gtsSkill:null,
             gtsSkillSelfRatings:null,
             gtsSkillChakuriRatings:null,
             gtsSkillIsPrimary:false,
             gtsSkillExperienceMonths:null,
             gtsSkillExternalRatings:null
            },
            userSecondarySkills:[
            ]
        }
    }

  componentDidMount(){
     var get_url = getUserSkillsURL+'/'+userLoginDetails.user_id;
     axios.get(get_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
     .then(res => {
        var userSecondarySkills = [];
         for(var i=0;i<res.data.length;i++){
             if(res.data[i].gts_skill_is_primary == false){
             userSecondarySkills=userSecondarySkills.concat(res.data[i])
             this.setState({userSecondarySkills : userSecondarySkills});
             this.setState({
                gts_user_skill_id:res.data[i].gts_user_skill_id,
                gts_skill_id:res.data[i].gts_skill_id,
                gts_skill_name:res.data[i].gts_skill_name,
                gts_skill_self_ratings:res.data[i].gts_skill_self_ratings,
                gts_skill_experience_months:res.data[i].gts_skill_experience_months,
                gts_skill_is_primary:false,
                requiredSkillId : res.data[i].gts_skill_id,
                requiredSecondarySkill : res.data[i].gts_skill_name,
                requiredSecondaryProficiency : res.data[i].gts_skill_self_ratings,
                yearsOfExperienceSecondary : res.data[i].gts_skill_experience_months
             })
             }
         }
       })
       var skill_url = getActiveSkillsURL;
       axios.get(skill_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
       .then((response) => {
         this.state.skills = response.data;
          if (this.state.skills.length > 0){
           this.state.skills.forEach((skills) => {
            this.state.userSkills.push(skills.gts_skill_name);
           });
          }
       })
   }

  addSecondaySkill = e =>{

    this.setState({proficencySecondaryError:""})
   this.setState({experienceSecondaryError : ""})

     var secondarySkillMessage = this.state.secondarySkillMessage;
     var secondarySkillError = this.state.secondarySkillError;
         var post_payload=[{
             gts_user_id:userLoginDetails.user_id,
             gts_skill_id:this.state.requiredSkillId,
             gts_skill_self_ratings:this.state.requiredSecondaryProficiency,
             gts_skill_experience_months:this.state.yearsOfExperienceSecondary,
             gts_skill_is_primary:false
           }]

           if(this.state.requiredSecondarySkill !=='' && this.state.requiredSecondaryProficiencyValidationFlag == true && this.state.yearsOfSecondaryExperienceValidationFlag == true){
         axios.post(addOrEditUserSkillsURL,post_payload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
         .then(response=>{
            var get_url =getUserSkillsURL+'/'+userLoginDetails.user_id;
            axios.get(get_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
            .then(res => {
               var userSecondarySkills = [];
                for(var i=0;i<res.data.length;i++){
                    if(res.data[i].gts_skill_is_primary == false){
                    userSecondarySkills=userSecondarySkills.concat(res.data[i])
                    this.setState({userSecondarySkills : userSecondarySkills});
                    }
                }
              })
             console .log(response.data)
             secondarySkillMessage = response.data.message;
             this.setState({secondarySkillMessage : secondarySkillMessage})
            })
            .catch(error=>{
                secondarySkillError = "Not able to add seconadry skills"
                console.log(secondarySkillError.response.data.message)
             this.setState({secondarySkillError : secondarySkillError})
            })

        }
        else{
            this.validateUserSecondarySkill();
        }
    }

    validateUserSecondarySkill = e =>{
        var skillError = "Please select skill."
        if(this.state.requiredSecondarySkill=='' || this.state.requiredSecondarySkill== null){
            this.setState({skillError : skillError})
        }
        else{
            this.setState({skillError : ""})
        }
    }

    updateSecondaySkill(primarySkillId){
        var secondarySkillMessage = this.state.secondarySkillMessage;
        var secondarySkillError = this.state.secondarySkillError;
            var put_payload={
             gts_user_skill_id:primarySkillId,
             gts_skill_id:this.state.requiredSkillId,
             gts_skill_self_ratings:this.state.requiredSecondaryProficiency,
             gts_skill_experience_months:this.state.yearsOfExperienceSecondary,
             gts_skill_is_primary:false
        }

        if(this.state.requiredSecondaryProficiencyValidationFlag == true && this.state.yearsOfSecondaryExperienceValidationFlag == true){
        axios.put(addOrEditUserSkillsURL,put_payload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
        .then(response=>{
            var get_url = getUserSkillsURL+'/'+userLoginDetails.user_id;
            axios.get(get_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
            .then(res => {
               var userSecondarySkills = [];
                for(var i=0;i<res.data.length;i++){
                    if(res.data[i].gts_skill_is_primary == false){
                    userSecondarySkills=userSecondarySkills.concat(res.data[i])
                    this.setState({userSecondarySkills : userSecondarySkills});
                    }
                }
              })
             secondarySkillMessage = response.data.message;
             this.setState({secondarySkillMessage : secondarySkillMessage})
            })
            .catch(error=>{
             secondarySkillError = "Not able to update seconadry skills"
             this.setState({secondarySkillError : secondarySkillError})
            })
        }
    }

  deleteSecondaySkill(primarySkillId){
      let secondarySkillMessage = this.state.secondarySkillMessage;
      let secondarySkillError = this.state.secondarySkillError;
      if(window.confirm('Are you sure?')){
         axios.delete(deleteUserSkillsURL+'/'+primarySkillId,{ headers: {"Auth_Token" : `Bearer ${token}`} })
          .then(response=>{
            var userSecondarySkills = [];
            var get_url = getUserSkillsURL+'/'+userLoginDetails.user_id;
            axios.get(get_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
            .then(res => {
                for(var i=0;i<res.data.length;i++){
                    if(res.data[i].gts_skill_is_primary == false){
                    userSecondarySkills=userSecondarySkills.concat(res.data[i])
                    this.setState({userSecondarySkills : userSecondarySkills});
                    }
                }
              })
               secondarySkillMessage = response.data.message;
               this.setState({deleteSeconadryMessage : secondarySkillMessage})
               userSecondarySkills = [];
               this.setState({userSecondarySkills : userSecondarySkills});
            })
            .catch(error=>{
                secondarySkillError = "Not able to delete seconadry skills"
                this.setState({deleteSeconadryError : secondarySkillError})
           })
        }
    }

  cancelEditHandler=e=>{
     this.setState({editSaveFlag:false});
    }

    autoCompleteChangeHandler=(event,value)=>{
        this.setState({editSaveFlag:true});
        this.setState({requiredSecondarySkillValidationFlag:false});
        if(value==null){
            this.setState({requiredSecondarySkillValidationFlag:true});
           }
           this.state.selectedSkill = value;
           this.state.requiredSecondarySkill = value;
           if(value == ''  || value== null){
           this.setState ({requiredSkillId : ''})
           }
           else{
           this.state.skills.forEach((skill) => {
               if (this.state.selectedSkill === skill.gts_skill_name) {
               this.state.requiredSkillId = skill.gts_skill_id
               }
           })
           }
       }

  changeHandler= e =>{
      this.setState({editSaveFlag:true})
      this.setState({saveFlag:false});
      this.setState({[e.target.name]: e.target.value})

      var pattern = new RegExp(/^[0-9]+$/);
      if(e.target.value !==''|| e.target.value !==undefined){
         if(e.target.name=='requiredSecondarySkill'){
             this.setState({requiredSecondarySkillValidationFlag:true});
             this.setState({requiredSecondarySkill : e.target.value})
            }

          if(e.target.name=="requiredSecondaryProficiency"){
              if(e.target.value ==""){
                this.setState({requiredSecondaryProficiencyValidationFlag:true});
                this.setState({proficencySecondaryError : ""})
              }
              else{
                    if(pattern.test(e.target.value)){
                        if(e.target.value > 10){
                        this.setState({requiredSecondaryProficiencyValidationFlag:false});
                            this.setState({proficencySecondaryError : "Please enter proficiency out of 10."})
                        }
                        else{
                            this.setState({requiredSecondaryProficiencyValidationFlag:true});
                            this.setState({requiredSecondaryProficiency : e.target.value})
                            this.setState({proficencySecondaryError : ""})
                        }
                    }
                    else {
                        this.setState({requiredSecondaryProficiencyValidationFlag:false});
                        this.setState({proficencySecondaryError : "Please enter proficiency out of 10."})
                    }
                }
           }
           if(e.target.name=='yearsOfExperienceSecondary'){
            this.setState({proficencySecondaryError:""})
               if(e.target.value == ""){
                this.setState({yearsOfSecondaryExperienceValidationFlag:true});
                this.setState({experienceSecondaryError : ""})
               }
               else{
                    if(!pattern.test(e.target.value)){
                        this.setState({yearsOfSecondaryExperienceValidationFlag:false});
                        this.setState({experienceSecondaryError : "Please enter valid year of experience"})
                    }
                    else{
                        this.setState({yearsOfSecondaryExperienceValidationFlag:true});
                        this.setState({experienceSecondaryError : ""})
                        this.setState({gtsSkillExperienceMonths : e.target.value})
                    }
               }
            }
        }
   }

  addButtonListener=e=>{
     this.setState({requiredSecondaryProficiency:''});
     this.setState({requiredSecondarySkill:''});
     this.setState({yearsOfExperienceSecondary:''});
     this.setState({editSaveFlag:false});
     this.setState({counter:this.state.counter+1});
    }

  cancelHandler=e=>{
      this.setState({cancelFlag:true});
   }

  editButtonListener=e=>{
     this.setState({editSaveFlag:false});
   }

  clearMessageOrError= () => {
     let secondarySkillMessage= this.state.secondarySkillMessage;
     secondarySkillMessage =" ";

     let secondarySkillError= this.state.secondarySkillError;
     secondarySkillError =" ";

     if(this.state.secondarySkillMessage){
         this.setState({secondarySkillMessage : secondarySkillMessage});
       }

     if(this.state.secondarySkillError){
         this.setState({secondarySkillError : secondarySkillError});
       }
    }


  render(){

    const{requiredSecondaryProficiency,requiredSecondarySkill,yearsOfExperienceSecondary}=this.state;

    return(
    <div className="container align-items-center">
     <div className="container align-items-center">
        <div className='mt-3'>
           <div className="border border-dark rounded-lg" >
             <div className="row">
                 <div className = "col" align="left">
                     <h5  className="mb6-3 p-2" style={{color : black}}><b>&nbsp;&nbsp;Secondary Skills</b></h5>
                  </div>
                  <div className = "col-3" align="right">
                     < button type="button" style={{width:"120px", height:"30px",display: "inline"}} class="btn btn-primary float-right" data-toggle="modal" data-target="#addSeconadryDetails"  onClick={this.addButtonListener} onBlur={this.clearMessageOrError} size="small"><center><h6 style={{ color: 'white' }}>Add Skill</h6></center></button>
                  </div>
             </div>
             <div style={{borderBottomColor: 'black',borderBottomWidth: 1.5}} />
             <span style={{color: "red" , width : "400px"}} ><center>{this.state.deleteSeconadryError}</center></span>
             <span style={{color: "#228B22" , width : "400px"}} ><center>{this.state.deleteSeconadryMessage}</center></span>
                 <br/>
                 <div class="container">
                     <table  id ="containerOfTableSecondarySkills" className="table table-responsive" style={{borderWidth:"1px", 'borderColor':"border-dark", 'borderStyle':'border-dark', width:"1090px"}}>
                         <thead>
                             <tr>
                             <td><h6 style={{color : black, fontSize:"18px"}}><center>Sr.No.</center></h6></td>
                                 <td><br/></td><td><br/></td><td><br/></td><td><br/></td><td><br/></td>
                                 <td ><h6 style={{color : black, fontSize:"18px"}}><center>Skills</center></h6></td>
                                 <td><br/></td><td><br/></td><td><br/></td><td><br/></td><td><br/></td>
                                 <td><h6 style={{color : black, fontSize:"18px"}}><center>Claim Of Proficency(Out of 10)</center></h6></td>
                                 <td><br/></td><td><br/></td><td><br/></td><td><br/></td><td><br/></td>
                                 <td><h6 style={{color : black, fontSize:"18px"}}><center>Experience(Months)</center></h6></td>
                                 <td><br/></td><td><br/></td><td><br/></td><td><br/></td><td><br/></td>
                                 <td><h6 style={{color : black, fontSize:"18px",display: "inline"}}><center>Edit/Delete Skill</center></h6></td>
                                 <td><br/></td>
                             </tr>
                         </thead>
                         <tbody >
                               {
                                  this.state.userSecondarySkills.map((userSkill,index) =>{
                                  return(<>

                                        { this.state.userSecondarySkills.length > 0 &&  !userSkill.gts_skill_is_primary &&
                                          <tr class={"strippedColors"+(index)%2}key={index}>
                                              <td><h6 style={{color : black}}><center>{index+1}</center></h6></td>
                                              <td><br/></td><td><br/></td><td><br/></td><td><br/></td><td><br/></td>
                                              <td><h6 style={{color : black}}><center>{userSkill.gts_skill_name}</center></h6></td>
                                              <td><br/></td><td><br/></td><td><br/></td><td><br/></td><td><br/></td>
                                              <td><h6 style={{color : black}}><center>{userSkill.gts_skill_self_ratings}</center></h6></td>
                                              <td><br/></td><td><br/></td><td><br/></td><td><br/></td><td><br/></td>
                                              <td><h6 style={{color : black}}><center>{userSkill.gts_skill_experience_months}</center></h6></td>
                                              <td><br/></td><td><br/></td><td><br/></td><td><br/></td><td><br/></td>
                                              <td><button href="#" class="btn float-right" style={{display: "inline"}} onBlur={this.clearMessageOrError}  onClick={()=>this.deleteSecondaySkill(userSkill.gts_user_skill_id)}><center><FontAwesomeIcon icon={faTrash}  /></center></button>
                                              <button class="btn float-right " style={{display: "inline"}} data-toggle="modal" data-target={"#editSecondarySkills"+index} onBlur={this.clearMessageOrError} onClick={this.editButtonListener}><center><FontAwesomeIcon icon={faEdit} /></center></button></td>
                                              <td></td>
                                          </tr>

                                        }
                                        <div id= {"editSecondarySkills"+index} class="modal fade" role="dialog">
                                             <div class="modal-dialog">
                                                 <div class="modal-content">
                                                     <div class="modal-header">
                                                          <h5 class="modal-title"> Edit Secondary Skills</h5>
                                                     </div>
                                                     <div class="modal-body">
                                                         <div className="container" >
                                                              <div class="row-sm m-0  text-left  ">
                                                                   <label id="requiredSecondarySkill" name="requiredSecondarySkill" class="text-dark">Skill:&nbsp;&nbsp;
                                                                      <Autocomplete
                                                                         class="complete"
                                                                         options={this.state.userSkills}
                                                                         style={{ width: 200,outlineColor:"black",display:"inline-block" }}
                                                                         defaultValue={{gts_skill_name:userSkill.gts_skill_name}}
                                                                         onChange={this.autoCompleteChangeHandler}
                                                                         disabled
                                                                         renderInput={(params) => <TextField {...params}    />}
                                                                       s/>
                                                                 </label>
                                                              </div>
                                                              <div class="row-sm">
                                                                  <label class="text-dark">Proficency(out of 10): &nbsp;&nbsp;
                                                                  <input type="text"  style={{ border: "2px solid #D3D3D3" }} name="requiredSecondaryProficiency" defaultValue={userSkill.gts_skill_self_ratings} placeholder ={userSkill.gts_skill_self_ratings}  onChange={this.changeHandler}  ></input>
                                                                  </label>
                                                                  <div class="row-sm">
                                                                        <span style={{color: "red" , width : "400px"}} >{this.state.proficencySecondaryError}</span>
                                                                    </div>
                                                              </div>
                                                              <div class="row-sm">
                                                                  <label class="text-dark">Experience In Months: &nbsp;&nbsp;
                                                                      <input type="text"   style={{ border: "2px solid #D3D3D3" }} name="yearsOfExperienceSecondary" defaultValue={userSkill.gts_skill_experience_months} placeholder={userSkill.gts_skill_experience_months}  input={userSkill.gtsSkillExperienceMonths} onChange={this.changeHandler} ></input>
                                                                 </label>
                                                                 <div class="row-sm">
                                                                    <span style={{color: "red" , width : "400px"}} >{this.state.experiencePrimaryError}</span>
                                                                </div>
                                                              </div>
                                                         </div>
                                                      </div>
                                                      <div class="modal-footer">
                                                          <div class="text-inline">
                                                              <Button id ="test" type="button" onBlur={this.clearMessageOrError} onClick={()=>this.updateSecondaySkill(userSkill.gts_user_skill_id)}>Save</Button>
                                                              <Button type="button" class="close" data-dismiss="modal" onBlur={this.clearMessageOrError}  onClick={this.cancelEditHandler}>Cancel</Button>
                                                          </div>
                                                          <span style={{color: "red" , width : "400px"}} ><center>{this.state.secondarySkillError}</center></span>
                                                         <span style={{color: "#228B22" , width : "400px"}} ><center>{this.state.secondarySkillMessage}</center></span>
                                                     </div>
                                                 </div>
                                             </div>
                                          </div>
                                     </>)
                                   })}
                             </tbody>
                         </table>
                     </div>
                     <div id="addSeconadryDetails" class="modal fade" role="dialog">
                         <div class="modal-dialog">
                             <div class="modal-content">
                                 <div class="modal-header">
                                     <h5 class="modal-title"> Add Secondary Skills</h5>
                                 </div>
                                 <div class="modal-body">
                                     <div className="container" >
                                         <div class="row-sm m-0  text-left  ">
                                             <label class="text-dark">Skill:&nbsp;&nbsp;
                                                 <Autocomplete class="complete"
                                                     options={this.state.userSkills}
                                                     style={{ width: 150,outlineColor:"black",display:"inline-block" }}
                                                     renderInput={(params) => <TextField {...params}  />}
                                                     onChange={this.autoCompleteChangeHandler}
                                                     onBlur={this.validateUserSecondarySkill}
                                                  s />
                                                  <div>
                                                     <span class="validationStyle" style={{color: 'red'}}>{this.state.skillError}</span>
                                                  </div>
                                             </label>
                                         </div>
                                         <div class="row-sm">
                                             <label class="text-dark">Proficency(out of 10): &nbsp;&nbsp;
                                                 <input type="text" style={{ border: "2px solid #D3D3D3" }} name="requiredSecondaryProficiency" value={requiredSecondaryProficiency} onChange={this.changeHandler}  ></input>
                                             </label>
                                             <div class="row-sm">
                                                <span style={{color: "red" , width : "400px"}} >{this.state.proficencySecondaryError}</span>
                                             </div>
                                          </div>
                                          <div class="row-sm">
                                              <label class="text-dark">Experience In Months: &nbsp;&nbsp;
                                                 <input type="text" style={{ border: "2px solid #D3D3D3" }} name="yearsOfExperienceSecondary" value={yearsOfExperienceSecondary} onChange={this.changeHandler} ></input>
                                             </label>
                                             <div class="row-sm">
                                                <span style={{color: "red" , width : "400px"}} >{this.state.experiencePrimaryError}</span>
                                             </div>
                                         </div>
                                       </div>
                                  </div>
                                  <div class="modal-footer">
                                     <div class="text-inline">
                                          <Button id ="test" type="button"  onClick={this.addSecondaySkill}>Save</Button>
                                         <Button type="button" class="close" data-dismiss="modal" onClick={this.cancelHandler}>Cancel</Button>
                                     </div>
                                     <span style={{color: "red" , width : "400px"}} ><center>{this.state.secondarySkillError}</center></span>
                                    <span style={{color: "#228B22" , width : "400px"}} ><center>{this.state.secondarySkillMessage}</center></span>
                                  </div>
                             </div>
                         </div>
                     </div>
                  </div>
             </div>
         </div>
         </div>)
    }

}
export default SecondarySkillsComponent;