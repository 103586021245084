import React from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import SPSavedServiesComponent from '../../components/service_provider/SPSavedServiesComponent';
import ls from 'local-storage';
import ServiceProviderMenu from '../../components/service_provider/ServiceProviderMenu';

var token = ls.get("token");
var userLoginDetails=ls.get('userLoginDetails');

class SavedServicesPage extends React.Component {
   constructor(props) {
		if(token==null || token=="" || token=="undefined"){
		  window.location.href = '/gts/global-home-page';
		}
		super(props);
	}
              render() {
                return (
                <div>

                        <Header />
                        <ServiceProviderMenu/>
                        <SPSavedServiesComponent/>
                        <Footer/>
                </div>
               )
        }
}


export default SavedServicesPage;



