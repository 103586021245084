import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import ls from 'local-storage';
import Moment from 'moment';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { endpoints_properties } from '../../properties/EndPointsProperties.js';
import { api_properties } from '../../properties/APIProperties.js';

import {
  Accordion,
  ButtonGroup,
  Col,
  Container,
  Row,
  Button,
  Form,
  InputGroup
} from "react-bootstrap";

var userLoginDetails = ls.get("userLoginDetails");
var token = ls.get('token');
var env=ls.get('ENV');

var	getUserProjectsURL="";
var	activeCompaniesURL="";
var	activeInstitutesURL="";
var	activeSkillsURL="";
var	userProjectsURL="";
var	deleteUserProjectsURL="";
var descriptionToUpdate="";

if(env=="LOCAL"){
	getUserProjectsURL=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_USER_PROJECTS;
	activeCompaniesURL=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_ACTIVE_COMPANIES;
	activeInstitutesURL=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_ACTIVE_INSTITUTES;
	activeSkillsURL=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_ACTIVE_SKILLS;
	userProjectsURL=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_USER_PROJECTS;
	deleteUserProjectsURL=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_DELETE_USER_PROJECTS;
}
else{
	getUserProjectsURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_USER_PROJECTS;
	activeCompaniesURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_COMPANIES;
	activeInstitutesURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_INSTITUTES;
	activeSkillsURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_SKILLS;
	userProjectsURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_USER_PROJECTS;
	deleteUserProjectsURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_DELETE_USER_PROJECTS;
}

class UserProjectComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalProjects : 0,
      selectedSkill: false,
      validatedUpdate: false,
      validated: false,
      disableProject: true,
      gts_user_project: "",
      gts_user_project_id: "",
      gts_user_project_id_to_update:"",
      gts_user_id: userLoginDetails.user_id,
      gts_user_project_name: "",
      gts_user_project_skill_ids: "",
      gts_user_project_skill_id: "",
      gts_user_project_skill_name: "",
      gts_user_project_start_date: "",
      gts_user_project_end_date: "",
      gts_user_team_size: "",
      gts_user_projects: [],
      hasError:false,
      gts_project_description: "",
      gts_project_description_to_update: "",
      gts_project_id_to_update: "",
      gts_project_institute_id: 0,
      gts_user_institute_name: "",
      gts_project_company_id: 0,
      gts_user_company_name: "",
      skill: [],
      company: [],
      companies: [],
      institute: [],
      institutes: [],
      gts_project_skills: [],
      gts_user_project_skills: [],
      gts_project_skills_name: [],
      message: '',
      IsCollapsed: false,
      skills:[{
        gts_skill_id: 0,
        gts_skill_name: null,
        gts_skill_description: null,
        gts_skill_status: false
      }],
      userProjectSkills:{
        gts_user_project_id: 0,
        gts_user_id: userLoginDetails.user_id,
        gts_user_project_name: null,
        gts_user_project_skill_ids: null,
        gts_user_project_start_date: null,
        gts_user_project_end_date: null,
        gts_user_team_size: 0,
        gts_project_description: null,
        gts_project_institute_id: 0,
        gts_user_institute_name: null,
        gts_project_company_id: 0,
        gts_user_company_name: null,
       },
      startDate: new Date(),
      monthSelected:'',
      month:[],
      year:[],
      numberOfSkills:0
    };
    this.cancel = '';
    this.handleChange = this.handleChange.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
  }



  changeHandler = (e) => {
   this.setState({ [e.target.name]: e.target.value });
   this.setState({ gts_user_project_id_to_update: e.target.id });

   	var gts_user_current_projects=[];
   	var i=0;

   	  this.state.gts_user_projects.map((userProjectExisting, index) => {

   		  if(userProjectExisting.gts_user_project_id==e.target.id){
   	      	userProjectExisting.gts_project_description=e.target.value;
   	      	gts_user_current_projects[i]=userProjectExisting;
   	     }
   	     else
   	     {
   			 gts_user_current_projects[i]=userProjectExisting;
   		 }


   		});

		this.setState({userProject:gts_user_current_projects})

  }




  handleChange(date) {
    this.setState({
    startDate:date
    })
  }

  enableProject = () =>{
    this.setState({disableProject:false})
  }

  cancelHandler=e=>{
      this.setState({cancelFlag:true});
  }

  reset= (e) =>{
    let userProject=null
    this.setState({userProject:userProject})
  }

  cancelEditHandler=e=>{
    this.setState({editSaveFlag:false});
  }

  onChangeValue = event => {
    this.setState({gts_user_project_skill_name: event.target.value,
      gts_user_project_skill_id: event.target.value
    })
  }

  clearMessage = () => {
    let message = this.state.message;
    let error = this.state.error;
    let updateMessage = this.state.updateMessage;
    let updateError = this.state.updateError;
    let deleteMessage = this.state.deleteMessage;
    let deleteError = this.state.deleteError;
    let errors = this.state.errors;

    if(this.state.message){
      message = " ";
      this.setState({ message: message ,
      validated: true});
    }
    if(this.state.error){
      error = " ";
      this.setState({ error: error ,
      validated: true});
    }
    if(this.state.updateMessage){
      updateMessage = " ";
      this.setState({ updateMessage: updateMessage ,
      validated:true});
    }
    if(this.state.updateError){
      updateError = " ";
      this.setState({ updateError: updateError ,
      validated: true});
    }
    if(this.state.deleteMessage){
      deleteMessage = " ";
      this.setState({ deleteMessage: deleteMessage,
      validated: true });
    }
    if(this.state.deleteError){
      deleteError = " ";
      this.setState({ deleteError: deleteError ,
      validated: true});
    }
    if(this.state.errors){
      errors = " ";
      this.setState({ errors: errors ,
      validated: true});
    }
  };

  validateProjectName = () =>{
    if(this.state.gts_user_project_name == '' || this.state.gts_user_project_name == null){
      this.setState({
        projectNameError : "Please enter the project name.",
        validated: false
      })
    }
    else{
      this.setState({validated: true,
        projectNameError: ''})
    }
  }

  validateProjectSkills = () =>{
    if(this.state.gts_project_skills_name == '' || this.state.gts_project_skills_name == null){
      this.setState({
        projectSkillsError : "Please select the project skills.",
        validated: false
      })
    }
    else if(this.state.numberOfSkills > 5){
      this.setState({
        projectSkillsError : "More than 5 skills are not allowed.",
        validated: false
      })
    }
    else{
      this.setState({validated: true,
        projectSkillsError: ''})
    }
  }

  validateProjectTeamSize = () =>{
    if(this.state.gts_user_team_size == ''){
      this.setState({
        projectTeamError : "Please select the project team size.",
        validated: false
      })
    }
    else{
      this.setState({validated: true,
        projectTeamError: ''})
    }
  }

  validateDate = () =>{
    if( this.state.currentEndYear !='' && this.state.currentStartYear !=='')
           if(this.state.currentEndYear < this.state.currentStartYear){
            this.state.validatedUpdate = false;
               this.setState({
                error: "Start date cannot be greater than end date."
               })
            }
            else{
              this.setState({validated: true,
              error: ''})
            }

  }

  validate = () =>{
    this.validateProjectName();
    this.validateProjectSkills();
    this.validateProjectTeamSize();
    this.validateDate();
  }

  validateUpdateProjectSkills = () =>{
    if(this.state.gts_user_project_skills == '' || this.state.gts_user_project_skills == null){
      this.setState({
        updateProjectSkillsError : "Please select the project skills.",
        validatedUpdate: false
      })
    }
    else{
      this.setState({validatedUpdate: true,
        updateProjectSkillsError: ''})
    }
  }

  validateUpdatedProjectTeamSize = () =>{
    if(this.state.gts_user_team_size == 0 ||this.state.gts_user_team_size == '' || this.state.gts_user_team_size == null){
      this.setState({
        updateProjectTeamError : "Please select the project team size.",
        validatedUpdate: false
      })
    }
    else{
      this.setState({validatedUpdate: true,
        updateProjectTeamError: ''})
    }
  }

  validateUpdatedDate = () =>{
    if( this.state.currentEndYear !='' && this.state.currentStartYear !=='')
           if(this.state.currentEndYear < this.state.currentStartYear){
            this.state.validatedUpdate = false;
               this.setState({
                updateError: "Start date cannot be greater than end date."
               })
            }
            else{
              this.setState({validated: true,
                updateError: ''})
            }

  }

  validateUpdate = () =>{
    this.validateUpdateProjectSkills();
    this.validateUpdatedProjectTeamSize();
    this.validateUpdatedDate();
  }

  saveAutoCompleteInput = (event, value) => {
    this.state.numberOfSkills = value.length

    if(value == '' || value == null){
      this.setState({validatedUpdate : false})
      this.setState({selectedSkill : true})
      this.state.numberOfSkills = 0
    }
    else{
    this.state.gts_project_skills_name = value;
    this.setState({selectedSkill : false})
    }
  }

  componentDidMount() {

    if (this.cancel) {
      this.cancel.cancel();
    }
    this.cancel = axios.CancelToken.source();

    var project_url = getUserProjectsURL+'/'+userLoginDetails.user_id;
    axios.get(project_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
    .then(res => {
      this.setState({gts_user_projects : res.data});
      this.setState({totalProjects : res.data.length})
    })
    .catch((error) => {
		console.log("error: "+error);
      });

        var month=[];
        for(var i=1;i<=12;i++){
          let zero = i < 10 ? '0' : '';
          this.state.month.push(zero + i);
        }

        var years=[];
        var minOffset = 0, maxOffset = 70; // Change to whatever you want
        var thisYear = (new Date()).getFullYear();

        for (var i = minOffset; i <= maxOffset; i++) {
          var year = thisYear - i;
          years.push(year)
        }
        this.setState({year:years})

        var company_url = activeCompaniesURL;
      axios.get(company_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
      .then((res) => {
        this.setState({ companies: res.data });

        console.log("companies: ");

        this.state.companies.forEach((companies) => {
            this.state.company.push(companies.gts_company_name);
        });

      }).catch((error) => {
      });

      var institute_url =activeInstitutesURL ;
      axios.get(institute_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
      .then((res) => {
        this.setState({ institutes: res.data });
        if(res.data != ""){
        this.state.institutes.forEach((institutes) => {
            this.state.institute.push(institutes.gts_institute_name);
        });
        }

      })

         this.autoCompleteChangeHandler();
    }

    componentDidCatch(errorMsg,errorInfo){
        let errors = this.state.error;
        errors = "Try after sometime or Please contact the administrator";
        this.setState({errors : errors})
    }

    autoCompleteChangeHandler = (input) =>{
      var skill_url = activeSkillsURL;
      axios.get(skill_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
      .then((response) => {
        this.state.skills = response.data;

        if (this.state.gts_project_skills.length != 0)
          this.state.gts_project_skills.length = [];
        this.state.skills.forEach((skills) => {
          this.state.gts_project_skills.push(skills.gts_skill_name);
          this.state.gts_project_skills.filter((value) => value.includes(input));
        });
      })
      .catch((error) => {
        if (axios.isCancel(error) || error) {
          this.setState({
            loading: false,
            message: "Failed to fetch the data",
          });
        }
      });
  }

  saveUserProject = (event) => {
    event.preventDefault();

    let error = this.state.error;

    const gts_user_project_skills = [];
    this.state.gts_project_skills_name.forEach((gts_project_skill_id) => {
      this.state.skills.forEach((skill) => {
        if (gts_project_skill_id === skill.gts_skill_name) {
          gts_user_project_skills.push(skill.gts_skill_id);
        }
      });
    });
    if(this.state.currentStartYear || this.state.currentStartMonth){
      var  start_date=(this.state.currentStartYear+"-"+this.state.currentStartMonth+"-01").toLocaleString();
    }
    else{
      var start_date = ''
    }

    if(this.state.currentEndYear || this.state.currentEndMonth){
      var  end_date=(this.state.currentEndYear+"-"+this.state.currentEndMonth+"-01").toLocaleString()
    }
    else{
      var  end_date= ''
    }
     var url = userProjectsURL;


         var post_payload={
          gts_user_id: this.state.gts_user_id,
          gts_user_project_name: this.state.gts_user_project_name,
          gts_user_project_skill_ids: gts_user_project_skills.join(",").toString(),
          gts_user_project_start_date: start_date,
          gts_user_project_end_date: end_date,
          gts_user_team_size: this.state.gts_user_team_size,
          gts_project_description: this.state.gts_project_description,
          gts_project_institute_id: this.state.gts_project_institute_id,
          gts_project_company_id: this.state.gts_project_company_id
        }

      if(this.state.validated == true){
         axios.post(url,post_payload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
         .then(response=>{
          var project_url = getUserProjectsURL+'/'+userLoginDetails.user_id;
          axios.get(project_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
          .then(res => {
              if(res.data.length!==0){
              this.setState({gts_user_projects : res.data});
              this.setState({totalProjects:res.data.length})
              }
              else{
              this.setState({gts_user_projects : [] });
            }
           })
           if(response.data.status_code == 403){
            error = "Not able to add project"
            this.setState({ error: error });
           }
           else{
          this.setState({ message: response.data.message });
          this.setState({gts_user_project_id : response.data.gts_user_project_id})
           }
        })
        .catch((err) => {
          error = "Not able to add project"
          this.setState({ error: error });
        });
      }
  }

  updateProject = (userProject) =>{
    let updateError = this.state.updateError;
    let updateMessage = this.state.updateMessage;

    const gts_user_project_skills = [];
    if(this.state.gts_project_skills_name !==""){
    this.state.gts_project_skills_name.forEach((gts_project_skill_id) => {
      this.state.skills.forEach((skill) => {
        if (gts_project_skill_id === skill.gts_skill_name) {
          gts_user_project_skills.push(skill.gts_skill_id);
          this.state.gts_user_project_skills.toString()
        }
      });
    });
   }

    if(this.state.gts_user_team_size == '') {
      this.state.gts_user_team_size = userProject.gts_user_team_size
    }

    if(this.state.currentStartMonth=='' || this.state.currentStartMonth == undefined){
      this.state.currentStartMonth = Moment(userProject.gts_user_project_start_date).format('MM')
    }

    if(this.state.currentEndMonth=='' || this.state.currentEndMonth == undefined){
      this.state.currentEndMonth =  Moment(userProject.gts_user_project_end_date).format('MM')
    }

    if(this.state.currentStartYear=='' || this.state.currentStartYear == undefined){
      this.state.currentStartYear = Moment(userProject.gts_user_project_start_date).format('YYYY')
    }

    if(this.state.currentEndYear=='' || this.state.currentEndYear == undefined){
      this.state.currentEndYear =  Moment(userProject.gts_user_project_end_date).format('YYYY')
    }

    if(this.state.currentStartYear || this.state.currentStartMonth){
    var  start_date=(this.state.currentStartYear+"-"+this.state.currentStartMonth+"-01").toLocaleString();
    }
    else{
      var start_date = userProject.gts_user_project_start_date;
    }

    if(this.state.currentEndYear || this.state.currentEndMonth){
      var end_date=(this.state.currentEndYear+"-"+this.state.currentEndMonth+"-01").toLocaleString()
      }
      else{
        var end_date = userProject.gts_user_project_end_date;
      }

      if(this.state.gts_user_team_size == ''){
        this.state.gts_user_team_size= userProject.gts_user_team_size
      }
      else{
        this.state.gts_user_team_size = this.state.gts_user_team_size
      }


      if(this.state.gts_project_description == ''){
        this.state.gts_project_description= userProject.gts_project_description
      }
      else{
        this.state.gts_project_description = this.state.gts_project_description
      }


   if(this.state.selectedSkill == false){
      if(this.state.gts_user_project_skills == ''){
        userProject.gts_user_project_skills.forEach((skill) => {
          this.state.gts_user_project_skills.push(skill.gts_skill_id)
        });
      }
      else{
        this.state.gts_user_project_skills = this.state.gts_user_project_skills.toString()
      }
    }

   this.validateUpdate();
    var url = userProjectsURL;

    var put_payload={
      gts_user_project_id: userProject.gts_user_project_id,
      gts_user_project_name: userProject.gts_user_project_name,
      gts_user_project_skill_ids: this.state.gts_user_project_skills.toString(),
      gts_user_project_start_date: start_date,
      gts_user_project_end_date: end_date,
      gts_user_team_size: this.state.gts_user_team_size,
      gts_project_description: this.state.gts_project_description,
      gts_project_institute_id: userProject.gts_project_institute_id,
      gts_project_company_id: userProject.gts_project_company_id,
    }

    if(this.state.validatedUpdate == true){
    axios.put(url,put_payload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
    .then((response) => {
      var project_url = getUserProjectsURL+'/'+userLoginDetails.user_id;
        axios.get(project_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
        .then(res => {

        this.setState({gts_user_projects : res.data});
        })
        if(response.data.status_code == 403){
         var error = "Not able to add project"
         this.setState({ error: error });
        }
        else{
      console.log(response.data.message)
      updateMessage ="User project details updated successfully."
      this.setState({ updateMessage: updateMessage });
        }
    })
    .catch((err) => {
      updateError = "Not able to update project"
      this.setState({ updateError: updateError });
      console.log(err.response.data.message)

    });
  }

  }

  deleteProject(gts_user_project_id){
    let deleteMessage = this.state.deleteMessage;
    let deleteError = this.state.deleteError;
    if(window.confirm('Are you sure?')){
      axios.delete(deleteUserProjectsURL+'/'+gts_user_project_id, { headers: {"Auth_Token" : `Bearer ${token}`} })
        .then(response=>{
      var gts_user_projects = [];
          var get_url = getUserProjectsURL+'/'+userLoginDetails.user_id;
          axios.get(get_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
          .then(res => {
            if(res.data.length!==0){
                  this.setState({gts_user_projects : res.data});
                  var project_url = getUserProjectsURL+'/'+userLoginDetails.user_id;
                  axios.get(project_url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
                  .then(res => {
                  if(res.data.length!==0){
                  this.setState({gts_user_projects : res.data});
                  this.setState({totalProjects:res.data.length})
                }
                else{
                  this.setState({gts_user_projects : [] });
                }
                  })
            }
            else{
            this.setState({gts_user_projects : [] });
             }
           })

          this.setState({gts_user_projects : []});
          if(response.data.status_code == 403){
          var error = "Not able to add project"
           this.setState({ error: error });
          }
          else{
          deleteMessage = "User project deleted successfully"
          this.setState({ deleteMessage : deleteMessage });
          window.location.reload(false,10000);
          }
          })
          .catch(error=>{
            console.log(error.response)
            deleteError = "Not able delete userProject";
            this.setState({ deleteError: deleteError })
        })
      }
  }

  saveAutoCompleteCurrentCompany=(event, value) => {

	console.log("1");

    this.state.selectedCurrentCompany = value;

    console.log("value: "+value);
    if(value == ''  || value== null){
      this.setState ({gts_project_company_id : ''})
    }
    else{
      this.state.companies.forEach((company) => {
        if (this.state.selectedCurrentCompany === company.gts_company_name) {
          this.state.gts_project_company_id = company.gts_company_id;
        }
       })
    }
  }

  saveAutoCompleteCurrentInstitute=(event, value) => {
    this.state.selectedCurrentInstitute = value;
    if(value == ''  || value== null){
      this.setState({gts_project_institute_id : ''})
    }
     else{
      this.state.institutes.forEach((institute) => {
        if (this.state.selectedCurrentInstitute === institute.gts_institute_name) {
          this.state.gts_project_institute_id = institute.gts_institute_id;
        }
       })
     }
  }

  selectStartMonth = (e) => {
    this.state.startMonth = e.target.value;
  }

  selectStartYear = (e) => {
    this.state.startYear = e.target.value;
  }

  selectEndMonth = (e) => {
    this.state.endMonth = e.target.value;
  }

  selectEndYear = (e) => {
    this.state.endYear =  e.target.value;
  }

  selectCurrentStartMonth = (e) => {
    this.state.currentStartMonth = e.target.value;
  }

  selectCurrentStartYear = (e) => {
    this.state.currentStartYear = e.target.value;
  }

  selectCurrentEndMonth = (e) => {
    this.state.currentEndMonth =  e.target.value;
  }

  selectCurrentEndYear = (e) => {
    this.state.currentEndYear = e.target.value;
  }

  render() {

    const successMessageStyles = {
      //backgroundColor: "#f0f",
      fontWeight: "bold",
      fontSize: 18,
      color: "#008000",
      //padding: paddings
    };

    const useStyles = {
      '@global': {
          '.MuiAutocomplete-option[data-focus="true"]': {
              background: 'blue'
          }
      }
  };
  const sizes = ["X-Small", "Small", "Medium", "Large", "X-Large", "2X-Large"];

    const {
      gts_user_project_name,
      gts_user_project_start_date,
      gts_user_project_end_date,
      gts_user_team_size,
      gts_project_description
    } = this.state;


    return (
      <Container className="align-items-center">
      <div className="container align-items-center">
        <div className="mt-3">
          <div className="border border-dark rounded">
              <Row>
                <Col>
                  <h5 style={{color : "black"}} class="mb-2 p-2"><b>Project Details:</b></h5>
                </Col>
                {this.state.totalProjects < 5 ?
                <Col>
                   <div className = "col-12" align="right">
                     <button
                        style={{width:"160px", height:"35px", align:"center"}}
                        className="btn btn-primary float-right"
                        data-toggle="modal"
                        data-target="#addProjectDetails"
                        onClick={this.addButtonListener}
                        onBlur={this.clearMessage} size="small">
                        <center><h6 style={{ color: 'white' }}>Add Project</h6></center>
                     </button>
                  </div>
                </Col> :''}
              </Row>
              <div class="form.row">
                <span style={{color:"red"}} ><center>{this.state.errors}</center></span>
                <span style={{color:"green"}} ><center>{this.state.deleteMessage}</center></span>
                <span style={{color:"red"}}><center>{this.state.deleteError}</center></span>
              </div>
			        <div style={{borderBottomColor: 'black',borderBottomWidth: 1.5}} />

              {/*------------------------------------------------------------MODEL DIALOG TO ADD PROJECT-------------------------------------  */}

              <div id={"addProjectDetails"} class="modal fade" role="dialog">
                <div class="modal-dialog modal-lg">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title"> Add Project</h5>
                    </div>
                    <div class="modal-body">
                      <div className="container" >
                      <InputGroup>
                      <div class="row-sm m-0  text-left">
                        <InputGroup className="mb-3" size="sm">
                          <div class="row">
                            <div class="col">
										      <h6  style={{color : "black"}}><label> Project Title :</label></h6>
                          </div>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <div class="col">
                          <input required type="text" name="gts_user_project_name" id="gts_user_project_name" style={{border:"1px solid #D3D3D3",paddingLeft:"10px",paddingRight:"10px",borderRadius:"4px",height:"35px",width: 410,paddingTop:"7px",paddingBottom:"7px"}} onChange={this.changeHandler} onBlur={this.validateProjectName} onFocus={this.clearMessage} size="14" ></input>
                       </div>
                       </div>
                       <span style={{color:'red'}}><center>{this.state.projectNameError}</center></span>
                        </InputGroup>
                        <InputGroup className="mb-3" size="sm">
                          <div className="row">
                            <div className="col">
										          <h6  style={{color : "black"}}><label>Name of Company :</label></h6>
									          </div>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <div className="col">
                              <Autocomplete
                                 options={this.state.company}
                                 style={{ width: "410px", outlineColor: "black", display: "inline-block"}}
                                  renderInput={(params) => <TextField {...params} id="gts_project_company" variant="outlined" style={{ color: "black" }} name="gts_project_company" size="small"/>}
                                  // disabled={this.state.gts_project_institute_id}
                                  // style={{width:"410px",color :(!this.state.gts_project_institute_id)? "black" : "gray",border:"1px solid #D3D3D3",paddingLeft:"10px",paddingRight:"10px",borderRadius:"4px",height:"35px",paddingTop:"7px",paddingBottom:"7px"}}
                                  onChange={this.saveAutoCompleteCurrentCompany}
                                  noOptionsText='No options'
                                  onBlur={this.clearMessage}/>
                            </div>
                          </div>
                          </InputGroup>
                          <InputGroup className="mb-3" size="sm">
                          <div className="row">
									          <div className="col">
										          <h6 style={{color : "black"}}><label>Name of University/Insititute:</label></h6>
									          </div>
									          <div className="col">
                              <Autocomplete
                                options={this.state.institute}
                                  style={{ width: "410px", outlineColor: "black", display: "inline-block"}}
                                  renderInput={(params) => <TextField {...params} variant="outlined" style={{ color: "black" }} id="gts_project_institute" name="gts_project_institute" size="small"/>}
                                  // disabled={!this.state.gts_project_company_id}
                                  onChange={this.saveAutoCompleteCurrentInstitute}
                                  onBlur={this.clearMessage}
                                  noOptionsText='No options'/>
                            </div>
								            </div>
                            </InputGroup>
                            <InputGroup className="mb-3" size="sm">
                            <div className="row">
                              <div className="col">
										            <h6 style={{color : "black"}}><label>Start&nbsp;Date(MM/YY):</label></h6>
									            </div>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <div className="col">
                                <select style={{border:"1px solid #D3D3D3", height:"35px", width:"75px"}} id="currentStartMonth" onChange={this.selectCurrentStartMonth}>
                                <option selected hidden value="Month">Month</option>
                                {this.state.month.map(monthSelected => {
                                return (
                                  <option value={monthSelected}> {monthSelected} </option>
                                  )
                                  })}
                                </select>
                              </div>
                              <div className="col">
                                <select style={{border:"1px solid #D3D3D3", height:"35px", width:"75px"}} id="currentStartYear" onChange={this.selectCurrentStartYear}>
                                <option selected hidden value="Year">Year</option>
                                {this.state.year.map(yearSelected => {
                                return (
                                  <option value={yearSelected}> {yearSelected} </option>
                                  )
                                  })}
                                </select>
                              </div>
								              </div>
                              </InputGroup>
                              <InputGroup className="mb-3" size="sm">
                                <div className="row">
                                  <div className="col">
										                <h6 style={{color : "black"}}><label>End&nbsp;Date(MM/YY):</label></h6>
									                </div>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <div className="col">
                                    <select style={{border:"1px solid #D3D3D3", height:"35px", width:"75px"}} id="currentEndMonth" onChange={this.selectCurrentEndMonth}>
                                    <option selected hidden value="Month">Month</option>
                                    {this.state.month.map(monthSelected => {
                                      return (
                                        <option value={monthSelected}> {monthSelected} </option>
                                        )
                                      })}
                                    </select>
                                  </div>
                                  <div className="col">
                                    <select style={{border:"1px solid #D3D3D3", height:"35px", width:"75px"}} id="currentEndYear" onChange={this.selectCurrentEndYear}>
                                    <option selected hidden value="Year">Year</option>
                                    {this.state.year.map(yearSelected => {
                                      return (
                                       <option value={yearSelected}> {yearSelected} </option>
                                      )
                                      })}
                                    </select>
                                    </div>
								                  </div>
                                  </InputGroup>
                                  <div className="row">
										                  <h6 style={{color : "black"}}><label>Project Description:</label></h6>
                                      <Form.Control required as="textarea" rows={5} onChange={this.changeHandler} name="gts_project_description" value={gts_project_description} placeholder="Project Description" onFocus={this.clearMessage}/>
                                    </div><br/>

                                    <div className="row">
										                  <h6 style={{color : "black"}}><label>Skills:</label></h6>
                                      <Autocomplete multiple
                                        options={this.state.gts_project_skills}
                                        style={{width: "100%", outlineColor: "black"}}
                                        onBlur={this.validateProjectSkills}
                                        onFocus={this.clearMessage}
                                        filterSelectedOptions
                                        onChange={this.saveAutoCompleteInput}
                                        renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Skills"/>
                                        }/>
                                    </div><br/>
                                    <span style={{color:'red'}}><center>{this.state.projectSkillsError}</center></span>
                                    <div className="row">
                                    <div className="col-3">
                                      <h6 style={{color : "black"}}><label> Team Size :</label></h6>
                                    </div>
                                    <div className="col-5 offset-1">
                                      <Form.Control required as="select" name="gts_user_team_size" id="gts_user_team_size" style={{border:"1px solid #D3D3D3",paddingLeft:"10px",paddingRight:"10px",borderRadius:"4px",height:"35px",width: 210,paddingTop:"7px",paddingBottom:"7px"}} onChange={this.changeHandler} onBlur={this.validateProjectTeamSize} onFocus={this.clearMessage} size="14" >
                                        <option hidden>--select--</option>
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                        <option>6</option>
                                        <option>7</option>
                                        <option>8</option>
                                        <option>9</option>
                                        <option>10</option>
                                        <option>11</option>
                                        <option>12</option>
                                        <option>13</option>
                                        <option>14</option>
                                        <option>15</option>
                                      </Form.Control>
                                    </div>
                                  </div>
                                  <span style={{color:'red'}}><center>{this.state.projectTeamError}</center></span>
                                    </div>
                                    </InputGroup>
                                    </div>
                                  </div>
                                  <div class="modal-footer">
                                     <div class="text-inline">
                                        <Button type="button" class="dismiss-model" onClick={this.saveUserProject} onFocus={this.validate}>Save</Button>
                                        <Button type="button" class="close" data-dismiss="modal"  onBlur={this.clearMessage}>Cancel</Button>
                                     </div>
                                  </div>
                                  <div class="row" align="center">
                                    <span style = {{color:"green"}}>{this.state.message}</span>
                                    <span style = {{color:"red"}}>{this.state.error}</span>
                                  </div>
                             </div>

                         </div>
                    </div>

           {/* ------------------------------------------------------ComponentDidMount------------------------------------------------- */}

            <Row>
              <Col>
                <Container>
                  <Col>
                    <Container className="align-items-center">
                      <div className="mt-3">
                      <Form onSubmit={this.submitHandler}>
                              <Row>
                                <br/>

                        {
                          this.state.gts_user_projects.map((userProject,index) => {

                          return(<>
                          { this.state.gts_user_projects.length > 0 &&
                            <Container className="border border-dark rounded">

                          <Row>

                            <Col>
                            <div className="row">
                                    <div className="col">
                                      <br/>
                                        <label><strong>Project Title:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong><i><span style={{color: "red"}} >{userProject.gts_user_project_name}</span></i></label>
                                      </div>
                                <div className="col pr-0 pl-7" align="right">
                                    <button type="button" class="btn float" data-toggle="modal" data-target={"#editUserProject"+userProject.gts_user_project_id} onBlur={this.clearmessage}><FaEdit/></button>
                                    <button type="button" class="btn float" href="#" onClick={() => this.deleteProject(userProject.gts_user_project_id)} onFocus={this.clearmessage}><FaTrash/></button>&nbsp;
                                    <button type="button" class="btn float" href="#" data-toggle="collapse" data-target={"#demo"+userProject.gts_user_project_id} area-expanded="false" area-controls="#a" onClick={() => this.collapse}>{this.state.IsCollapsed?'-':'+'}</button>
                                </div>
                              </div>
                            </Col>
                           </Row>
                           <Row>
                              <Col>
                                  {/* {this.state.IsCollapsed? */}
                                  <div class="collapse" id={"demo"+userProject.gts_user_project_id}>
                                    <div className="row">
                                      <div className="col">
                                        <label style={{display: userProject.gts_user_company_name ? 'block' : 'none' }}><strong>Name of Company:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong><i><span style={{color: "red"}} >{userProject.gts_user_company_name}</span></i></label>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col">
                                        <label style={{display: userProject.gts_user_institute_name ? 'block' : 'none' }}><strong>Name of University/Institution:&nbsp;</strong><i><span style={{color: "red"}} >{userProject.gts_user_institute_name}</span></i></label>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col">
                                        <label><strong>Start Date:&nbsp;</strong><i><span style={{color: "red"}} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{userProject.gts_user_project_start_date}</span></i></label>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col">
                                        <label><strong>End Date:&nbsp;</strong> <i><span style={{color: "red"}} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{userProject.gts_user_project_end_date}</span></i></label>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col">
                                        <label style={{"text-align" : "justified"}}><strong>Project Details:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong><i  style={{color: "red"}}>{userProject.gts_project_description}</i></label></div>
                                    </div>
                                    <div className="row">
                                      <div className="col">
                                        <label style={{"text-align":"justify"}}><strong>Skills:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong></label>
                                        <i><span style={{color: "red"}} >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          {userProject.gts_user_project_skills.map((user_skill, i) => ( <label key={i}>{user_skill.gts_skill_name}</label>))}</span></i>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col">
                                        <label style={{"text-align":"justify"}}><strong>Team Size:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong><i><span style={{color: "red"}} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{userProject.gts_user_team_size}</span></i></label>
                                      </div>
                                    </div>
                                  </div>
                                  </Col>
                              </Row>
                              </Container>
                          }
                          <div class="row">
                            <h6 style={{color : "black"}}><label></label></h6>
                          </div>

                        {/*--------------------------------------------------MODEL DIALOG TO EDIT PROJECT-------------------------------------------------------------------------  */}

          <div id={"editUserProject"+userProject.gts_user_project_id} class="modal fade" role="dialog">
                <div class="modal-dialog modal-lg">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title"> Update Project</h5>
                    </div>
                    <div class="modal-body">
                      <div className="container" >
                      <div class="row-sm m-0  text-left">
                        <InputGroup className="mb-3" size="sm">
                          <div className="row">
									          <div className="col">
										          <h6 style={{color : "black"}}><label> Project Title :</label></h6>
									          </div>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									          <div className="col">
                              <input required type="text" disabled defaultValue={userProject.gts_user_project_name} name="gts_user_project_name" id="gts_user_project_name" style={{color:"gray",border:"1px solid #D3D3D3",paddingLeft:"10px",paddingRight:"10px",borderRadius:"4px",height:"35px",width: 410,paddingTop:"7px",paddingBottom:"7px"}} onChange={this.changeHandler} onFocus={this.clearMessage} size="14" ></input>
									          </div>
								          </div>
                          </InputGroup>
                        <InputGroup className="mb-3" size="sm">
                          <div className="row">
                            <div className="col">
										          <h6 style={{color : "black"}}><label>Name of Company :</label></h6>
									          </div>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <div className="col">
                              <Autocomplete
                                 options={this.state.company}
                                 style={{ width: "410px", outlineColor: "black", display: "inline-block"}}
                                  renderInput={(params) => <TextField {...params} id="gts_project_company" variant="outlined" style={{ color: "black" }} name="gts_project_company" size="small"/>}
                                  onChange={this.saveAutoCompleteCurrentCompany}
                                  disabled
                                  noOptionsText='No options'
                                  onBlur={this.clearMessage}
                                  defaultValue={userProject.gts_user_company_name}/>
                            </div>
                          </div>
                          </InputGroup>
                        <InputGroup className="mb-3" size="sm">
                          <div className="row">
									          <div className="col">
										          <h6 style={{color : "black"}}><label>Name of University/Insititute:</label></h6>
									          </div>
                              <div className="col">
                              <Autocomplete
                                options={this.state.institute}
                                  style={{ width: "410px", outlineColor: "black", display: "inline-block"}}
                                  renderInput={(params) => <TextField {...params} variant="outlined" style={{ color: "black" }} id="gts_project_institute" name="gts_project_institute" size="small"/>}
                                  defaultValue={userProject.gts_user_institute_name}
                                  onChange={this.saveAutoCompleteCurrentInstitute}
                                  disabled
                                  onBlur={this.clearMessage}
                                  noOptionsText='No options'/>
                            </div>
								            </div>
                            </InputGroup>
                        <InputGroup className="mb-3" size="sm">
                          <div className="row">
                              <div className="col">
										            <h6 style={{color : "black"}}><label>Start&nbsp;Date(MM/YY):</label></h6>
									            </div>
									          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <div className="col">
                                <select style={{border:"1px solid #D3D3D3", height:"35px", width:"75px"}} id="currentStartMonth" onChange={this.selectCurrentStartMonth}>
                                <option selected hidden value="Month">{userProject.gts_user_project_start_date ? Moment(userProject.gts_user_project_start_date).format("MM") : 'Month'}</option>
                                {this.state.month.map(monthSelected => {
                                return (
                                  <option value={monthSelected}> {monthSelected} </option>
                                  )
                                  })}
                                </select>
                              </div>
                              <div className="col">
                                <select style={{border:"1px solid #D3D3D3", height:"35px", width:"75px"}} id="currentStartYear" onChange={this.selectCurrentStartYear}>
                                <option selected hidden value="Month">{userProject.gts_user_project_start_date ? Moment(userProject.gts_user_project_start_date).format("yyyy") : 'Year'}</option>
                                {this.state.year.map(yearSelected => {
                                return (
                                  <option value={yearSelected}> {yearSelected} </option>
                                  )
                                  })}
                                </select>
                              </div>
								              </div>
                              </InputGroup>
                        <InputGroup className="mb-3" size="sm">
                          <div className="row">
                                  <div className="col">
										                <h6 style={{color : "black"}}><label>End&nbsp;Date(MM/YY):</label></h6>
									                </div>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <div className="col">
                                    <select style={{border:"1px solid #D3D3D3", height:"35px", width:"75px"}} id="currentEndMonth" onChange={this.selectCurrentEndMonth}>
                                    <option selected hidden value="Month">{userProject.gts_user_project_end_date ? Moment(userProject.gts_user_project_end_date ).format("MM") : 'Month'}</option>
                                    {this.state.month.map(monthSelected => {
                                      return (
                                        <option value={monthSelected}> {monthSelected} </option>
                                        )
                                      })}
                                    </select>
                                  </div>
                                  <div className="col">
                                    <select style={{border:"1px solid #D3D3D3", height:"35px", width:"75px"}} id="currentEndYear" onChange={this.selectCurrentEndYear}>
                                    <option hidden selected value="Month">{userProject.gts_user_project_end_date ? Moment(userProject.gts_user_project_end_date ).format("yyyy") : 'Year'}</option>
                                    {this.state.year.map(yearSelected => {
                                      return (
                                       <option value={yearSelected}> {yearSelected} </option>
                                      )
                                      })}
                                    </select>
                                    </div>
								                  </div>
                                   </InputGroup>
                                    <div className="row">
										                  <h6 style={{color : "black"}}><label>Project Description:</label></h6>
                                      <Form.Control required as="textarea" rows={5}
                                      onChange={this.changeHandler}

                                      id={userProject.gts_user_project_id}
                                      name="gts_project_description"
                                      value={userProject.gts_project_description}
                                      placeholder="Project Description" onFocus={this.clearMessage}/>
                                    </div><br/>
                                    <div className="row">
										                  <h6 style={{color : "black"}}><label>Skills:</label></h6>
                                      <Autocomplete multiple
                                        options={this.state.gts_project_skills}
                                        style={{width: "100%", outlineColor: "black"}}
                                        onBlur={this.validateUpdateProjectSkills}
                                        onFocus={this.clearMessage}
                                        filterSelectedOptions
                                        onChange={this.saveAutoCompleteInput}
                                        defaultValue={userProject.gts_user_project_skills.map(user_skill =>user_skill.gts_skill_name)}
                                        renderInput={(params) =><TextField {...params} variant="outlined" placeholder="Skills"/>
                                        }/>
                                    </div><br/>
                                    <span style={{color:'red'}}><center>{this.state.updateProjectSkillsError}</center></span>
                                    <span>{this.state.updateProjectSkillsError ? <br/> : ''}</span>
                                    <div className="row">
                                    <div className="col-3">
                                      <h6 style={{color : "black"}}><label> Team Size :</label></h6>
                                    </div>
                                    <div className="col-5 offset-1">
                                      <Form.Control onBlur={this.validateUpdatedProjectTeamSize} required as="select" name="gts_user_team_size" id="gts_user_team_size" style={{border:"1px solid #D3D3D3",paddingLeft:"10px",paddingRight:"10px",borderRadius:"4px",height:"35px",width: 210,paddingTop:"7px",paddingBottom:"7px"}} onChange={this.changeHandler} onFocus={this.clearMessage} size="14" >
                                      <option  selected hidden value="select">{userProject.gts_user_team_size ? userProject.gts_user_team_size : '--select--'}</option>
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                        <option>6</option>
                                        <option>7</option>
                                        <option>8</option>
                                        <option>9</option>
                                        <option>10</option>
                                        <option>11</option>
                                        <option>12</option>
                                        <option>13</option>
                                        <option>14</option>
                                        <option>15</option>
                                      </Form.Control>
                                    </div>
                                  </div>
                                  <span style={{color:'red'}}><center>{this.state.updateProjectTeamError}</center></span>
                                    </div>
                                    </div>
                                  </div>
                                  <div class="modal-footer">
                                     <div class="text-inline">
                                        <Button type="button" onClick={()=>this.updateProject(userProject)}>Save</Button>
                                        <Button type="button" class="close" data-dismiss="modal" onBlur={this.clearMessage}>Cancel</Button>
                                     </div>
                                  </div>
                                  <div class="row" align="center">
                                    <span style = {{color:"green"}}>{this.state.updateMessage}</span>
                                    <span style = {{color:"red"}}>{this.state.updateError}</span>
                                  </div>
                             </div>
                         </div>
                    </div>
                        </>)
                      })}
                      </Row>
                   </Form>
                    </div>

                    {/* <div style={{borderBottomColor: 'black',borderBottomWidth: 1.5}} /> */}
                    {/* </div> */}
                  </Container>
                </Col>
              </Container>
            </Col>
          </Row>
          </div>
        </div>
        </div>
      </Container>
    );

  }
}
export default UserProjectComponent;
