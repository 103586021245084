import React from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import SPClosedServiceApplicationsComponent from '../../components/service_provider/SPClosedServiceApplicationsComponent';
import ls from 'local-storage';
import RecruiterMenu from '../../components/recruiter/RecruiterMenu';
import ServiceConsumerMenu from '../../components/service_consumer/ServiceConsumerMenu';
import ServiceProviderMenu from '../../components/service_provider/ServiceProviderMenu';
import AdminMenu from '../../components/admin/AdminMenu';

var token = ls.get("token");
var userLoginDetails=ls.get('userLoginDetails');

class SPClosedServiceApplicationsPage extends React.Component {
   constructor(props) {
		if(token==null || token=="" || token=="undefined"){
		  window.location.href = '/gts/global-home-page';
		}
		super(props);
	}
              render() {
                return (
                <div>

                        <Header />
                        <ServiceProviderMenu/>
                        <SPClosedServiceApplicationsComponent/>
                        <Footer/>
                </div>
               )
        }
}
export default SPClosedServiceApplicationsPage;



