import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import {
  NavbarBrand
} from 'reactstrap';

class GlobalTrainingsComponent extends React.Component {
  componentDidMount() {
   
  }
  

  render() {
    const content = {
      backgroundColor: "#3F3A3A",
      padding: "10px",
    };

    const titleStyle = {
      color: "#00DCFF",
      fontFamily: "Adobe Clean",
      fontSize: "75px",
      fontWeight: "bold"
    }

    const bottonStyle = {
      borderRadius: "50px",
      borderStyle: "solid",
      borderColor: "#0CCEFF",
      borderWidth: "3px",
      backgroundColor: "#3C5C5C",
      marginRight: "20px",
      marginBottom: "20px",
      height: "100px",
      width: "650px",
      padding: "50px"
    }

    const bottonTextStyle = {
      color: "#6EFF00",
      fontFamily: "Adobe Clean",
      fontSize: "30px",
      fontWeight: "bold"
    }

    return (
      <>
      <div style = {content}>

        <h1 style = {titleStyle}>
        Welcome to the Global Trainings
        </h1>

        <br/>
        <br/>

        <NavbarBrand tag={Link} to="/gts/global-trainings/training-for-students-trainees">
        <Button variant="contained" style = {bottonStyle}><h2 style = {bottonTextStyle}>Training for Students/Trainees</h2></Button>
        </NavbarBrand>
        <NavbarBrand tag={Link} to="/gts/global-trainings/training-for-service-provider">
        <Button variant="contained" style = {bottonStyle}> <h2 style = {bottonTextStyle}>Training for Service Provider (SP)</h2></Button>
        </NavbarBrand>
        <NavbarBrand tag={Link} to="/gts/global-trainings/training-for-service-consumers">
        <Button variant="contained" style = {bottonStyle}> <h2 style = {bottonTextStyle}>Training for Service Consumers (SC)</h2></Button>
        </NavbarBrand>
        <NavbarBrand tag={Link} to="/gts/global-trainings/training-for-trainers">
        <Button variant="contained" style = {bottonStyle}> <h2 style = {bottonTextStyle}>Training for Trainers</h2></Button>
        </NavbarBrand>
        
        <br/>
        <br/>
        <br/>
        
      </div>
       
  
      </>
    );
  }
}


export default GlobalTrainingsComponent;

