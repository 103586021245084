import React from 'react';

class CareerComponent extends React.Component{
    render(){
        return(
            <div className="container align-items-center">
                <h3 className="pt-4 text-center font-bold">OPEN POSITIONS AT GTS</h3>
                <div className="pl-8">
                    <div className="pt-4">
                        <h6 className="font-bold">Trainee - Mobile App Developers</h6>
                        <p>GTS has 5 job positions for Trainee - UI developer.<br/>
                            Qualification: BCA,BSc, MCA, BE, B-Tech<br/>
                            Job Type: Work from home <br/>
                            Skills: Android & ios <br/>
                            Experience: 0-2 years <br/>
                            If you are interested, then send your resume to hr.gts@ggtech.co.in
                        </p>
                        <hr style={{width:"100%", margin:"0"}} />
                    </div>

                    <div className="pt-4">
                        <h6 className="font-bold">Trainee - UI Developers</h6>
                        <p>GTS has 15 job positions for Trainee - UI developer.<br/>
                            Qualification: BCA,BSc, MCA, BE, B-Tech<br/>
                            Job Type: Work from home <br/>
                            Skills: NodeJS, ReactJS, JavaScript, HTML<br/>
                            Experience: 0-2 years <br/>
                            If you are interested, then send your resume to hr.gts@ggtech.co.in
                        </p>
                        <hr style={{width:"100%", margin:"0"}} />
                    </div>

                    <div className="pt-4">
                        <h6 className="font-bold">Trainee - Java Developer</h6>
                        <p>GTS has 5 job positions for Trainee - Java developer.<br/>
                            Qualification: BCA,BSc, MCA, BE, B-Tech<br/>
                            Job Type: Work from home <br/>
                            Skills: Java, Springboot, Microservices<br/>
                            Experience: 0-2 years <br/>
                            If you are interested, then send your resume to hr.gts@ggtech.co.in
                        </p>
                        <hr style={{width:"100%", margin:"0"}} />
                    </div>

                    <div className="pt-4">
                        <h6 className="font-bold">Trainee - DevOps</h6>
                        <p>GTS has 5 job positions for Trainee - DevOps.<br/>
                            Qualification: BCA,BSc, MCA, BE, B-Tech<br/>
                            Job Type: Work from home <br/>
                            Skills: Ansible, Terraform, Docker, Kubernetes/Swarm, AWS/Azure/GCP<br/>
                            Experience: 0-2 years <br/>
                            If you are interested, then send your resume to hr.gts@ggtech.co.in
                        </p>
                        <hr style={{width:"100%", margin:"0"}} />
                    </div>

                    <div className="pt-4">
                        <h6 className="font-bold">Trainee - Business Analyst/ Product Owner</h6>
                        <p>GTS has 3 job positions for Trainee - Business Analyst.<br/>
                            Qualification: BCA,BSc, MCA, BE, B-Tech<br/>
                            Job Type: Work from home <br/>
                            Skills: Story Writing<br/>
                            Experience: 0-2 years <br/>
                            If you are interested, then send your resume to hr.gts@ggtech.co.in
                        </p>
                        <hr style={{width:"100%", margin:"0"}} />
                    </div>

                    <div className="pt-4">
                        <h6 className="font-bold">Trainee - HR/ Recruiter</h6>
                        <p>GTS has 10 job positions for Trainee - HR/ Recruiter.<br/>
                            Qualification: BCA,BSc, MCA, BE, B-Tech<br/>
                            Job Type: Work from home <br/>
                            Skills: Story Writing<br/>
                            Experience: 0-2 years <br/>
                            If you are interested, then send your resume to hr.gts@ggtech.co.in
                        </p>
                        <hr style={{width:"100%", margin:"0"}} />
                    </div>

                    <div className="pt-4">
                        <h6 className="font-bold">Trainee - QA Engineer</h6>
                        <p>GTS has 5 job positions for Trainee - QA Engineers.<br/>
                            Qualification: BCA,BSc, MCA, BE, B-Tech<br/>
                            Job Type: Work from home <br/>
                            Skills: Manual Testing, Test Automation with Selenium, Karate, Postman<br/>
                            Experience: 0-2 years <br/>
                            If you are interested, then send your resume to hr.gts@ggtech.co.in
                        </p>
                        <hr style={{width:"100%", margin:"0"}} />
                    </div>

                    <div className="pt-4">
                        <h6 className="font-bold">Trainee - Scrum Masters</h6>
                        <p>GTS has 3 job positions for Trainee - Scrum Masters.<br/>
                            Qualification: BCA,BSc, MCA, BE, B-Tech<br/>
                            Job Type: Work from home <br/>
                            Skills: Manual Testing, Test Automation with Selenium, Karate, Postman<br/>
                            Experience: 0-2 years <br/>
                            If you are interested, then send your resume to hr.gts@ggtech.co.in
                        </p>
                    </div>
                    <br/><br/>

                </div>
            </div>
        )
    }

}
export default CareerComponent;