
import React, { Component } from 'react'
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import ContactUsComponent from '../../components/common/ContactUsComponent'
class ContactUsPage extends Component {
    render()
     {

        return (
           <div>
                <Header/>
                <ContactUsComponent/>
                <Footer/>
           </div>
        )
    }
}
export default ContactUsPage;
