import React, { Component } from "react";
import  "./SPSavedServiesComponent.css";
import 'bootstrap/dist/css/bootstrap.css'
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import axios from "axios";
import ls from "local-storage";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import DropdownButton from 'react-bootstrap/DropdownButton'
import Moment from 'moment';
import ShowMoreText from 'react-show-more-text';
import { endpoints_properties } from '../../properties/EndPointsProperties.js';
import { api_properties } from '../../properties/APIProperties.js';

import {
  Container,
  Grid,
  Slider,
  Paper,
  FormLabel,
  Select,
  Input,
  Checkbox,
  ListItemText,
  MenuItem,
  FormControl,
  Chip,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { Button, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { Dropdown,InputGroup,Form } from "react-bootstrap";

var token = ls.get("token");
var userLoginDetails = ls.get("userLoginDetails");
var userLoginDetails="";
var userPersonalDetails="";

class SPSavedServiesComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      jobs:[],
      mySavedJobs:[],
      savedJobApplications:[],
      jobTitles:[],
      jobTitle:[],
      gts_job_title_id:0,
      selectedServices:[],
      selectedServiceIds:[],
      skills: [],
      skillName: [],
      gts_job_skill_ids:0,
      selectedSkills: [],
      selectedSkillIds: [],
      jobSkillName : [],
      search: "",
      mySavedJobsBySkills:[],
      mySavedJobsByServiceTitles:[]
    };
    this.cancel = "";
    this.changeHandler = this.changeHandler.bind(this)

    userLoginDetails=ls.get('userLoginDetails');
	userPersonalDetails=ls.get('userPersonalDetails');
  }

  changeHandler = (e) => {
   this.setState({ [e.target.name] : e.target.value})
  }

  componentDidMount(){
    var jobs = [];
    var savedJobs = [];
    var url = endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_SERVICE_APPLICATIONS_BY_APPLICANT_ID+"/"+userLoginDetails.user_id;
    axios.get(url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
    .then(response =>{
      for(var i=0; i<response.data.length; i++){
        this.state.savedJobApplications.push(response.data[i])
        console.log(response.data)
       if(response.data[i].gts_job_application_status == 'SAVED'){
        console.log(response.data[i])
          var jobUrl = endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_SC_SERVICES_BY_SERVICE_ID+'/'+response.data[i].gts_applied_job_id;
          axios.get(jobUrl,{ headers: {"Auth_Token" : `Bearer ${token}`} })
          .then(res =>{
            jobs.push(res.data)
            this.setState({mySavedJobs: jobs})
            console.log(this.state.mySavedJobs)
          })
        }
      }
    })
    .catch(error => {
      // this.setState({errors : "No saved jobs"})
      this.state.savedJobApplications = []
      this.state.mySavedJobs = []
    })
  }

  ServiceTitleAutocomplete = (input)=>{
    axios
      .get(endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_SERVICE_TITLES, { headers: { Auth_Token: `Bearer ${token}` },
      })
      .then((response)=>{
        this.state.jobTitles = response.data;
        if (this.state.jobTitles.length != 0)
        this.state.jobTitle.length = [];
          this.state.jobTitles.forEach((jobTitle) => {
            this.state.jobTitle.push(jobTitle.gts_job_title_name);
            this.state.jobTitle.filter((value) => value.includes(input));
          })
      })
      .catch((error)=>{
        console.log(error);
      })
  }

  saveJobTitleAutoComplete = (event, value) => {
    this.setState({
      errorMessage: "",
      successMessage: ""
    })
    this.state.selectedServiceIds = [];
    this.state.selectedServices = value;
    console.log(this.state.selectedServices);
    this.state.selectedServices.forEach((selectedService) => {
      this.state.jobTitles.forEach((service) => {
        if (selectedService === service.gts_job_title_name) {
          this.state.selectedServiceIds.push(service.gts_job_title_id);
        }
      });
    });
 };

 SKillAutoCompleteChangeHandler = (input)=>{
  axios
    .get(endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_ACTIVE_SKILLS, { headers: { Auth_Token: `Bearer ${token}` },
    })
    .then((response)=>{
      this.state.skills = response.data;
      if (this.state.skills.length != 0)
      this.state.skillName.length = [];
        this.state.skills.forEach((skill) => {
          this.state.skillName.push(skill.gts_skill_name);
          this.state.skillName.filter((value) => value.includes(input));
        })
    })
    .catch((error)=>{
      console.log(error);
    })
 }

 saveJobSkillAutoCompleteInput = (event, value) => {
    this.setState({
      errorMessage: "",
      successMessage: ""
    })
    this.state.selectedSkillIds = [];
    this.state.selectedSkills = value;
    console.log(this.state.selectedSkills);
    this.state.selectedSkills.forEach((selectedSkill) => {
      this.state.skills.forEach((skill) => {
        if (selectedSkill === skill.gts_skill_name) {
          this.state.selectedSkillIds.push(skill.gts_skill_id);
        }
      });
    });
 };

 validateFields = e => {
  let formIsValid = true;
  let jobTitleError = this.state.jobTitleError;
  if (this.state.selectedServiceIds == 0 || this.state.selectedServiceIds=='' || this.state.selectedServiceIds== null || this.state.selectedServiceIds==undefined) {
    formIsValid = false;
    jobTitleError = "Please select service name.";
    this.setState({
      validated: false,
      jobTitleError: jobTitleError
    });
  }
  else{
    jobTitleError = '';
    this.setState({
      validated: true,
      jobTitleError: jobTitleError
    });
  }

  let jobSkillError = this.state.jobSkillError;
  if (this.state.selectedSkillIds == 0 || this.state.selectedSkillIds=='' || this.state.selectedSkillIds== null || this.state.selectedSkillIds==undefined) {
    formIsValid = false;
    jobSkillError = "Please select skill name.";
    this.setState({
      validated: false,
      jobSkillError: jobSkillError
    });
  }
  else{
   jobSkillError = '';
    this.setState({
      validated: true,
      jobSkillError: jobSkillError
    });
  }
}

  searchSavedJobs = () =>{
    if((this.state.selectedServiceIds == '' && this.state.selectedSkillIds == '')
      || (this.state.selectedServiceIds == null && this.state.selectedSkillIds == null)){
     this.setState({errors : " Please select the keywords"})
    }
    else{
     if(this.state.selectedServiceIds == '' || this.state.selectedServiceIds == null){
       this.setState({mySavedJobsByServiceTitles: []})
      }
     else{
       var url=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_SERVICE_APPLICATIONS_BY_SERVICE+'/'+userLoginDetails.user_id+"/"+this.state.selectedServiceIds;
       axios.get(url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
       .then(response =>{
          console.log( response.data)
          var jobs=[];
          for(var i=0; i<response.data.length; i++){
            this.state.savedJobApplications.push(response.data[i])
            if(response.data[i].gts_job_application_status == 'SAVED'){
              var jobUrl = endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_SC_SERVICES_BY_SERVICE_ID+'/'+response.data[i].gts_applied_job_id;
              axios.get(jobUrl,{ headers: {"Auth_Token" : `Bearer ${token}`} })
              .then(res =>{
                jobs.push(res.data)
                this.setState({mySavedJobsByServiceTitles: jobs})
              })
            }
            else{
              this.setState({error: "No jobs saved for this service title."})
            }
          }
          this.setState({mySavedJobs : []})
          this.state.mySavedJobsBySkills.forEach((skill)=>{
            this.state.mySavedJobsByServiceTitles.forEach((job)=>{
              if(skill.gts_job_application_id == job.gts_job_application_id){
                this.setState({mySavedJobs: this.state.mySavedJobsBySkills})
                this.setState({mySavedJobsByServiceTitles : []})
                this.setState({mySavedJobsBySkills : []})
              }
            })
          })
          if(response.data == ''){
            var error="Jobs are not avaliable for this service name."
            this.setState({mySavedJobsByServiceTitles:[]})
            this.setState({error: error})
          }
       })
       .catch(error => {
          this.setState({status:400})
          var error="Job are not avaliable for this service name."
          this.setState({mySavedJobsByServiceTitles:[]})
          this.setState({mySavedJobs:[]})
         this.setState({error: error})
       })
     }
     if(this.state.selectedSkillIds == '' || this.state.selectedSkillIds == null){
       this.setState({mySavedJobsBySKill: []})
     }
     else{
      var url=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_SERVICE_APPLICATIONS_BY_SKILL+"/"+userLoginDetails.user_id+"/"+this.state.selectedSkillIds;
      axios.get(url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
      .then(response =>{
         console.log( response.data)
         var jobs=[];
         for(var i=0; i<response.data.length; i++){
           this.state.savedJobApplications.push(response.data[i])
           if(response.data[i].gts_job_application_status == 'SAVED'){
             var jobUrl = endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_SC_SERVICES_BY_SERVICE_ID+'/'+response.data[i].gts_applied_job_id;
             axios.get(jobUrl,{ headers: {"Auth_Token" : `Bearer ${token}`} })
             .then(res =>{
               jobs.push(res.data)
               this.setState({mySavedJobsBySkills: jobs})
             })
           }
           else{
             this.setState({error: "No jobs saved for this skill name."})
           }
         }
         this.setState({mySavedJobs : []})
         this.state.mySavedJobsBySkills.forEach((skill)=>{
           this.state.mySavedJobsByServiceTitles.forEach((job)=>{
              if(skill.gts_job_application_id == job.gts_job_application_id){
               this.setState({mySavedJobs: this.state.mySavedJobsBySkills})
               this.setState({mySavedJobsByServiceTitles : []})
               this.setState({mySavedJobsBySkills : []})
              }
           })
         })
         if(response.data == ''){
           var error="Jobs are not avaliable for this skill name."
           this.setState({mySavedJobsBySkills:[]})
           this.setState({error: error})
         }
      })
      .catch(error => {
         this.setState({status:400})
         var error="Job are not avaliable for this skill name."
         this.setState({mySavedJobsBySkills:[]})
         this.setState({mySavedJobs:[]})
        this.setState({error: error})
      })
    }
    }
  }

  clearAllFields = () =>{
    this.setState({
      selectedServices: [],
      selectedAdvServices: [],
    })
  }

  applyForJob =(gts_job_id) =>{
    var gts_job_application_id = '';
    this.state.savedJobApplications.map(jobAppl =>{
      if(jobAppl.gts_applied_job_id == gts_job_id){
        gts_job_application_id = jobAppl.gts_job_application_id
      }
    })
    var url =endpoints_properties.ENDPOINT_PROD+api_properties.API_POST_SERVICE_APPLICATIONS;
    var jobApplicationPutPayLoad = {
      "gts_job_application_id":gts_job_application_id,
      "gts_applied_job_id": gts_job_id,
      "gts_applicant_id": userLoginDetails.user_id,
      "gts_applicant_proposal": this.state.jobProposal,
      "gts_job_application_status": "APPLIED",
      "gts_job_application_is_active":true,
      "is_fraud_job":0,
      "gts_job_reviewed_date" : "",
      "gts_job_shortlisted_date" : "",
      "gts_job_proposed_for_interview_date" : "",
      "gts_job_rejected_date"  :"",
      "gts_job_selected_date" : "",
      "gts_job_offered_date"  :""
    }
    axios.put(url,jobApplicationPutPayLoad,{ headers: {"Auth_Token" : `Bearer ${token}`} })
    .then(response =>{
      var message = "Job applied successfully";
      this.setState({message : message})
    })
    .catch(error =>{
      var error="Not able to apply for this job";
      console.log(error.response)
      this.setState({
        error: error
      })
    })
  }

  clearError= e=>{
    this.setState({error:''})
    this.setState({message:''})
    this.setState({messages:''})
    this.setState({errors:''})
  }

  deleteSavedJob = (gts_job_id) =>{
    var gts_job_application_id = '';
    this.state.savedJobApplications.map(jobAppl =>{
      if(jobAppl.gts_applied_job_id == gts_job_id){
        gts_job_application_id = jobAppl.gts_job_application_id
      }
    })
    var jobs=[];
    var url =endpoints_properties.ENDPOINT_PROD+api_properties.API_DELETE_SERVICE_APPLICATION_BY_JOB_APPL_ID+'/'+gts_job_application_id;
    axios.delete(url,{ headers: {"Auth_Token" : `Bearer ${token}`} })
    .then(response =>{
      this.componentDidMount();
      var message = "Job deleted successfully";
      this.setState({messages : message})
      if(response.data == ''){
        this.setState({mySavedJobs: jobs})
      }
    })
    .catch(error =>{
      var error="Not able to delete for job";
      console.log(error.response)
      this.setState({
        error: error
     })
    })
  }

  render() {
    return (
     <div>
       <br/>

         <div className="row pl-24">
           <div class="col-7">
              <h5 style={{fontSize:"18px",paddingTop: "10px"}} className="h4-searchtext">Service Name&nbsp;&nbsp;</h5>
              <Autocomplete
                multiple
                options={this.state.jobTitle}
                style={{ width: "350px", outlineColor: "black" }}
                filterSelectedOptions
                onChange={this.saveJobTitleAutoComplete}
                renderInput={(params) => { this.ServiceTitleAutocomplete(params);
                  return (
                  <TextField {...params}
                  variant="outlined"
                  placeholder="Service Name" />
                )}}
              />
           </div>
          <div class="col">
            <h5 style={{fontSize:"18px",paddingTop: "12px"}} className="h4-searchtext">Skill Name&nbsp;&nbsp;</h5>
            <Autocomplete
              multiple
              options={this.state.skillName}
              style={{ width: "350px", outlineColor: "black" }}
              filterSelectedOptions
              onChange={this.saveJobSkillAutoCompleteInput}
              renderInput={(params) => { this.SKillAutoCompleteChangeHandler(params);
                return (
                <TextField {...params}
                variant="outlined"
                placeholder="Skill Name" />
              )}}
            />
          </div>
          <div class="offset-5">
            <Button color="primary" onClick={this.searchSavedJobs} onBlur={this.clearError}>Search</Button>
          </div>
        </div>

       <span style={{fontSize:"15px", color:'green'}} ><strong><center>{this.state.messages}</center></strong></span>
       <span style={{fontSize:"15px", color:'red'}} ><strong><center>{this.state.errors}</center></strong></span>
       <span style={{fontSize:"15px", color:'red'}} ><strong><center>{this.state.error}</center></strong></span>

   {/* ------------------------------------------DISPLAY------------------------------------------------------------------ */}
        {this.state.savedJobApplications !== '' &&
        this.state.mySavedJobs.map((item)=>(
         <InputGroup>
           <Grid container spacing={2}>
            <Grid item xs={10}>
              <div class="mt-4">
               <div class="border border-dark rounded-lg offset-1">
                 <div class="row" >
                   <div class="col-3 p-3 pl-4" >
                   <h5 style={{fontSize:"15px"}}><b>Service ID&nbsp;:&nbsp;{item.gts_job_id}</b></h5>
                     <h5 style={{fontSize:"15px"}}><strong>Service Name: </strong>{item.job_title_name.toUpperCase() }</h5>
                     <h5 style={{fontSize:"15px"}}><strong>Company: </strong><span style={{color:'black'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.company_name.toUpperCase() }</span></h5>
                    <h5 style={{fontSize:"15px"}}><strong>Job Description: </strong><span style={{color:'black'}}>
                     <ShowMoreText
                        lines={2}
                        more='Show more'
                        less='Show less'
                        className='content-css'
                        anchorClass='my-anchor-css-class'
                        onClick={this.executeOnClick}
                        expanded={false}
                        width={230}
                      >
                       {item.gts_job_description}
                      </ShowMoreText></span></h5>

                   </div>

                    <div class="col-3 p-3">
                      <br/>
                      {item.gts_job_post_skill_ids.map((skill)=>{
                        this.state.jobSkillName.push(skill.gts_skill_name)
                      })}
                      <h6 style={{fontSize:"15px"}}>Skills: <span style={{color:'red'}}>{this.state.jobSkillName.toString()}</span></h6>
                      <br/>
                    </div>

                   <div class="col-3 p-3">
                     <br/>
                      <h6 style={{fontSize:"15px"}}>Posted Date: <span style={{color:'red'}}>{Moment(item.gts_job_posted_date).format('DD-MMM-YYYY') }</span></h6>
                      {item.gts_job_min_exp_in_months == 0 || item.gts_job_min_exp_in_months == '' ? <h6>Experience: '' </h6> :
                      <h6 style={{fontSize:"15px"}}>Experience: <span style={{color:'red'}}>{item.gts_job_min_exp_in_months} months</span></h6>
                      }
                      <h6 style={{fontSize:"15px"}}>Location:<span style={{color:'red'}}>{item.city_name}</span></h6>
                    </div>
                    <div class="col-0 p-2">
                      <br/>
                      <button
                       className="btn btn-primary"
                       data-toggle="modal"
                       data-target={"#viewSavedJob"+item.gts_job_id}
                       style={{backgroundColor:"white",color:"white", align:"right", borderRadius:"10px", fontSize:"12px"}}
                       >
                       <b>View</b>
                      </button>
                    </div>
                    <div class="col p-2">
                      <br/>
                      <button
                       className="btn btn-primary"
                       style={{backgroundColor:"white",color:"white", align:"right", borderRadius:"10px", fontSize:"12px"}}
                       onClick={()=>this.deleteSavedJob(item.gts_job_id)}
                       >
                       <b>Remove</b>
                      </button>
                    </div>
                  </div>
             </div>
             </div>

       <div id={"viewSavedJob"+item.gts_job_id} class="modal fade" role="dialog">
         <div class="modal-dialog modal-xl" >
           <div class="modal-content">
              <div class="modal-body">
                <div className="container" >
                  <div class="row-sm m-0  text-left">
                  <div class="row-0" align="right">
                     <div class="col-0" align="right">
                         <button  type="button" class="close" data-dismiss="modal" ><i  class="fas fa-window-close fa-lg"></i></button>
                       </div>
                     </div>
                  <InputGroup>
                      <div class="row-0" align="left">
                        <div class="col-0" align="left">
                        <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Service ID&nbsp;:&nbsp;{item.gts_job_id}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Service Name&nbsp;:&nbsp;{item.job_title_name.toUpperCase()}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                          <h6 style={{color : "black", display:this.state.gts_job_title}}><label style={{fontSize:"15px"}}><b>Industry Name&nbsp; :&nbsp;</b><span style={{color:'red'}}>{item.industry_name}</span>&nbsp;&nbsp;</label></h6>
                          <h6  style={{color : "black", display : this.state.is_company_requirement == true ? 'block' : 'none'}}><label style={{fontSize:"15px"}}><b>Company Name&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.company_name}</span>&nbsp;&nbsp;</label></h6>
                        </div>
                     </div>
                     <div class="row-0" align="right">
                        <div class="col-0" align="right">
                          <br/>
                        <h6  style={{color : "black"}}><label style={{fontSize:"15px", display:item.gts_job_id >0 ? 'none' : 'block'}}><b>Service ID&nbsp;:&nbsp;{item.gts_job_id}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                         <h6  style={{color : "black"}}><label style={{fontSize:"15px"}} ><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Expiry Date&nbsp; :&nbsp;</b><span style={{color:'red'}}>{Moment(item.gts_job_expiry_date).format('DD-MMM-YYYY')}</span>&nbsp;&nbsp;</label></h6>
                         </div>
                     </div>

                     <div class="row-0" align="right">
                       <div class="col-0" align="right">
                       <br/>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px", display:item.gts_job_id >0 ? 'none' : 'block'}}><b>Service ID&nbsp;:&nbsp;{item.gts_job_id}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}} ><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Experience(Months) &nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_min_exp_in_months}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}} ><b>Work Type &nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_work_type}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}} ><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Job Type&nbsp;:&nbsp;</b> <span style={{color:'red'}}>{item.gts_job_type}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                        </div>
                      </div>

                     <div class="row-0" align="right">
                        <div class="col-0" align="right">
                        <br/>
                        <h6  style={{color : "black"}}><label style={{fontSize:"15px", display:item.gts_job_id >0 ? 'none' : 'block'}}><b>Service ID&nbsp;:&nbsp;{item.gts_job_id}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                          <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Country&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.country_name}</span>&nbsp;</label></h6>
                          <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;City&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.city_name}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                       </div>
                     </div>
                   </InputGroup>

                    <div className="form-row">
                      <h6 class="text" style={{color : "black", display : this.state.is_company_requirement == true ? 'block' : 'none'}}><label style={{fontSize:"15px"}}><b>&nbsp;Company/SC Description &nbsp;:&nbsp; </b>{item.company_description}&nbsp;&nbsp;</label></h6>
                   </div>

                   <div className="row">
                      <div class="col">
                       <h6 class="text" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Service Description&nbsp;:&nbsp;</b>
                       <h6 >{item.gts_job_description}</h6></label></h6>
                      </div>
                   </div>

                   <div className="row">
								      <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;Skills &nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_post_skill_ids.map(user_skill =>user_skill.gts_skill_name.concat(', '))}</span></label></h6>
                   </div>

                   <InputGroup>
                     <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Minimum Qualification &nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.degree_name}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                       <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Language Proficiency&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.language_name}</span> &nbsp;&nbsp;&nbsp;</label></h6>
                       <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Notice Period(Days)&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_expected_hiring_weeks}</span>&nbsp;&nbsp;&nbsp;</label></h6>
                       <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No. of Vacancies&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_vacancy_numbers}</span>&nbsp;</label></h6>
                     </InputGroup>

                     <InputGroup>
                       <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Salary Currency&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.currency_name}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                        <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;Salary&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_salary}</span>&nbsp;</label></h6>
                       <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;Per&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_salary_duration_unit}</span>&nbsp;</label></h6>
                     </InputGroup>

                     <div style={{borderBottomColor: 'black',borderBottomWidth: "0.5px"}}/><br/>

                     <InputGroup>
                        <h6 class="form-group" style={{color : "black", align:"left"}}><label style={{fontSize:"15px"}}><b>Email&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_contact_email}</span>&nbsp;</label></h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <h6 class="form-group" style={{color : "black", align:"center"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mobile Number&nbsp;:&nbsp;</b><span style={{color:'red'}}>+{item.gts_job_mobile_country_code}&nbsp;{item.gts_job_contact_mobile_number}</span>&nbsp;&nbsp;&nbsp;</label></h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <h6 class="form-group" style={{color : "black", align:"right"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;WhatsApp Number&nbsp;:&nbsp;</b><span style={{color:'red'}}>+{item.gts_job_whatsapp_country_code}&nbsp;{item.gts_job_contact_whatsapp_number}</span>&nbsp;</label></h6>
                      </InputGroup>

                  </div>
                </div>
              </div>
               <div class="modal-footer">
                   <div class="text-inline">
                     <Button type="button" class="close" data-dismiss="modal"  data-toggle="modal" data-target={"#savedJobProposal"+item.gts_job_id} color="primary" onBlur={this.clearError.bind(this)}>APPLY FOR JOB</Button>
                   </div>
                 </div>
           </div>
         </div>
       </div> }

       <div id={"savedJobProposal"+item.gts_job_id} class="modal fade" role="dialog">
         <div class="modal-dialog modal-lg ">
           <div class="modal-content">
             <div style={{align:"center"}}>
           <button type="button"  class="close"  data-dismiss="modal" style={{align:"right"}}>&times;</button>
           </div>
              <div class="modal-body">
                <div className="container" >
                  <h6 style={{fontSize:"15px"}}>Job Proposal: </h6>
                  <Form.Control
                    as="textarea"
                    rows={'auto'}
                    style={{height:"200px"}}
                    onChange={this.changeHandler}
                    name="gts_job_proposal"
                    id="gts_job_proposal"
                   onFocus={this.clearError}
                      />
                </div>
                <div class="modal-footer">
                <Button
                  type="button"
                  color="primary"
                  onClick={()=>this.applyForJob(item.gts_job_id)}
                  onBlur={this.clearError.bind(this)}
                  onChange={this.state.jobProposal=''}>
                  Skip
                </Button>
                <Button
                  type="button"
                  color="primary"
                  onClick={()=>this.applyForJob(item.gts_job_id)}
                  onBlur={this.clearError.bind(this)}>
                  Save
                </Button>
                <br/>
                <span style={{fontSize:"15px", color:'red'}} ><strong><center>{this.state.error}</center></strong></span>
                     <span style={{fontSize:"15px", color:'green'}} ><strong><center>{this.state.message}</center></strong></span>
                </div>
             </div>
           </div>
          </div>
        </div>

       <div style={{display: this.state.jobSkillName!==null ? 'none' : 'block'}}>
         { this.state.jobSkillName =  []}
       </div>

      </Grid>
    </Grid>
  </InputGroup> ))}
 <br/>
 {/* ----------------------------------DISPLAY BY SERVICE NAME------------------------------------------------------------ */}
 {this.state.savedJobApplications !== '' &&
        this.state.mySavedJobsByServiceTitles.map((item)=>(
         <InputGroup>
           <Grid container spacing={2}>
            <Grid item xs={10}>
              <div class="mt-4">
               <div class="border border-dark rounded-lg offset-1">
                 <div class="row" >
                   <div class="col-3 p-3 pl-4" >
                   <h5 style={{fontSize:"15px"}}><b>Service ID&nbsp;:&nbsp;{item.gts_job_id}</b></h5>
                     <h5 style={{fontSize:"15px"}}><strong>Service Name: </strong>{item.job_title_name.toUpperCase() }</h5>
                     <h5 style={{fontSize:"15px"}}><strong>Company: </strong><span style={{color:'black'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.company_name.toUpperCase() }</span></h5>
                    <h5 style={{fontSize:"15px"}}><strong>Job Description: </strong><span style={{color:'black'}}>
                     <ShowMoreText
                        lines={2}
                        more='Show more'
                        less='Show less'
                        className='content-css'
                        anchorClass='my-anchor-css-class'
                        onClick={this.executeOnClick}
                        expanded={false}
                        width={230}
                      >
                       {item.gts_job_description}
                      </ShowMoreText></span></h5>

                   </div>

                    <div class="col-3 p-3">
                      <br/>
                      {item.gts_job_post_skill_ids.map((skill)=>{
                        this.state.jobSkillName.push(skill.gts_skill_name)
                      })}
                      <h6 style={{fontSize:"15px"}}>Skills: <span style={{color:'red'}}>{this.state.jobSkillName.toString()}</span></h6>
                      <br/>
                    </div>

                   <div class="col-3 p-3">
                     <br/>
                      <h6 style={{fontSize:"15px"}}>Posted Date: <span style={{color:'red'}}>{Moment(item.gts_job_posted_date).format('DD-MMM-YYYY') }</span></h6>
                      {item.gts_job_min_exp_in_months == 0 || item.gts_job_min_exp_in_months == '' ? <h6>Experience: '' </h6> :
                      <h6 style={{fontSize:"15px"}}>Experience: <span style={{color:'red'}}>{item.gts_job_min_exp_in_months} months</span></h6>
                      }
                      <h6 style={{fontSize:"15px"}}>Location:<span style={{color:'red'}}>{item.city_name}</span></h6>
                    </div>
                    <div class="col-0 p-2">
                      <br/>
                      <button
                       className="btn btn-primary"
                       data-toggle="modal"
                       data-target={"#viewSavedJob"+item.gts_job_id}
                       style={{backgroundColor:"white",color:"white", align:"right", borderRadius:"10px", fontSize:"12px"}}
                       >
                       <b>View</b>
                      </button>
                    </div>
                    <div class="col p-2">
                      <br/>
                      <button
                       className="btn btn-primary"
                       style={{backgroundColor:"white",color:"white", align:"right", borderRadius:"10px", fontSize:"12px"}}
                       onClick={()=>this.deleteSavedJob(item.gts_job_id)}
                       >
                       <b>Remove</b>
                      </button>
                    </div>
                  </div>
             </div>
             </div>

       <div id={"viewSavedJob"+item.gts_job_id} class="modal fade" role="dialog">
         <div class="modal-dialog modal-xl" >
           <div class="modal-content">
              <div class="modal-body">
                <div className="container" >
                  <div class="row-sm m-0  text-left">
                  <div class="row-0" align="right">
                     <div class="col-0" align="right">
                         <button  type="button" class="close" data-dismiss="modal" ><i  class="fas fa-window-close fa-lg"></i></button>
                       </div>
                     </div>
                  <InputGroup>
                      <div class="row-0" align="left">
                        <div class="col-0" align="left">
                        <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Service ID&nbsp;:&nbsp;{item.gts_job_id}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Service Name&nbsp;:&nbsp;{item.job_title_name.toUpperCase()}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                          <h6 style={{color : "black", display:this.state.gts_job_title}}><label style={{fontSize:"15px"}}><b>Industry Name&nbsp; :&nbsp;</b><span style={{color:'red'}}>{item.industry_name}</span>&nbsp;&nbsp;</label></h6>
                          <h6  style={{color : "black", display : this.state.is_company_requirement == true ? 'block' : 'none'}}><label style={{fontSize:"15px"}}><b>Company Name&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.company_name}</span>&nbsp;&nbsp;</label></h6>
                        </div>
                     </div>
                     <div class="row-0" align="right">
                        <div class="col-0" align="right">
                          <br/>
                        <h6  style={{color : "black"}}><label style={{fontSize:"15px", display:item.gts_job_id >0 ? 'none' : 'block'}}><b>Service ID&nbsp;:&nbsp;{item.gts_job_id}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                         <h6  style={{color : "black"}}><label style={{fontSize:"15px"}} ><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Expiry Date&nbsp; :&nbsp;</b><span style={{color:'red'}}>{Moment(item.gts_job_expiry_date).format('DD-MMM-YYYY')}</span>&nbsp;&nbsp;</label></h6>
                         </div>
                     </div>

                     <div class="row-0" align="right">
                       <div class="col-0" align="right">
                       <br/>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px", display:item.gts_job_id >0 ? 'none' : 'block'}}><b>Service ID&nbsp;:&nbsp;{item.gts_job_id}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}} ><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Experience(Months) &nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_min_exp_in_months}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}} ><b>Work Type &nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_work_type}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}} ><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Job Type&nbsp;:&nbsp;</b> <span style={{color:'red'}}>{item.gts_job_type}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                        </div>
                      </div>

                     <div class="row-0" align="right">
                        <div class="col-0" align="right">
                        <br/>
                        <h6  style={{color : "black"}}><label style={{fontSize:"15px", display:item.gts_job_id >0 ? 'none' : 'block'}}><b>Service ID&nbsp;:&nbsp;{item.gts_job_id}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                          <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Country&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.country_name}</span>&nbsp;</label></h6>
                          <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;City&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.city_name}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                       </div>
                     </div>
                   </InputGroup>

                    <div className="form-row">
                      <h6 class="text" style={{color : "black", display : this.state.is_company_requirement == true ? 'block' : 'none'}}><label style={{fontSize:"15px"}}><b>&nbsp;Company/SC Description &nbsp;:&nbsp; </b>{item.company_description}&nbsp;&nbsp;</label></h6>
                   </div>

                   <div className="row">
                      <div class="col">
                       <h6 class="text" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Service Description&nbsp;:&nbsp;</b>
                       <h6 >{item.gts_job_description}</h6></label></h6>
                      </div>
                   </div>

                   <div className="row">
								      <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;Skills &nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_post_skill_ids.map(user_skill =>user_skill.gts_skill_name.concat(', '))}</span></label></h6>
                   </div>

                   <InputGroup>
                     <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Minimum Qualification &nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.degree_name}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                       <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Language Proficiency&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.language_name}</span> &nbsp;&nbsp;&nbsp;</label></h6>
                       <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Notice Period(Days)&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_expected_hiring_weeks}</span>&nbsp;&nbsp;&nbsp;</label></h6>
                       <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No. of Vacancies&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_vacancy_numbers}</span>&nbsp;</label></h6>
                     </InputGroup>

                     <InputGroup>
                       <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Salary Currency&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.currency_name}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                        <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;Salary&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_salary}</span>&nbsp;</label></h6>
                       <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;Per&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_salary_duration_unit}</span>&nbsp;</label></h6>
                     </InputGroup>

                     <div style={{borderBottomColor: 'black',borderBottomWidth: "0.5px"}}/><br/>

                     <InputGroup>
                        <h6 class="form-group" style={{color : "black", align:"left"}}><label style={{fontSize:"15px"}}><b>Email&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_contact_email}</span>&nbsp;</label></h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <h6 class="form-group" style={{color : "black", align:"center"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mobile Number&nbsp;:&nbsp;</b><span style={{color:'red'}}>+{item.gts_job_mobile_country_code}&nbsp;{item.gts_job_contact_mobile_number}</span>&nbsp;&nbsp;&nbsp;</label></h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <h6 class="form-group" style={{color : "black", align:"right"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;WhatsApp Number&nbsp;:&nbsp;</b><span style={{color:'red'}}>+{item.gts_job_whatsapp_country_code}&nbsp;{item.gts_job_contact_whatsapp_number}</span>&nbsp;</label></h6>
                      </InputGroup>

                  </div>
                </div>
              </div>
               <div class="modal-footer">
                   <div class="text-inline">
                     <Button type="button" class="close" data-dismiss="modal"  data-toggle="modal" data-target={"#savedJobProposal"+item.gts_job_id} color="primary" onBlur={this.clearError.bind(this)}>APPLY FOR JOB</Button>
                   </div>
                 </div>
           </div>
         </div>
       </div> }

       <div id={"savedJobProposal"+item.gts_job_id} class="modal fade" role="dialog">
         <div class="modal-dialog modal-lg ">
           <div class="modal-content">
             <div style={{align:"center"}}>
           <button type="button"  class="close"  data-dismiss="modal" style={{align:"right"}}>&times;</button>
           </div>
              <div class="modal-body">
                <div className="container" >
                  <h6 style={{fontSize:"15px"}}>Job Proposal: </h6>
                  <Form.Control
                    as="textarea"
                    rows={'auto'}
                    style={{height:"200px"}}
                    onChange={this.changeHandler}
                    name="gts_job_proposal"
                    id="gts_job_proposal"
                   onFocus={this.clearError}
                      />
                </div>
                <div class="modal-footer">
                <Button
                  type="button"
                  color="primary"
                  onClick={()=>this.applyForJob(item.gts_job_id)}
                  onBlur={this.clearError.bind(this)}
                  onChange={this.state.jobProposal=''}>
                  Skip
                </Button>
                <Button
                  type="button"
                  color="primary"
                  onClick={()=>this.applyForJob(item.gts_job_id)}
                  onBlur={this.clearError.bind(this)}>
                  Save
                </Button>
                <br/>
                <span style={{fontSize:"15px", color:'red'}} ><strong><center>{this.state.error}</center></strong></span>
                     <span style={{fontSize:"15px", color:'green'}} ><strong><center>{this.state.message}</center></strong></span>
                </div>
             </div>
           </div>
          </div>
        </div>

       <div style={{display: this.state.jobSkillName!==null ? 'none' : 'block'}}>
         { this.state.jobSkillName =  []}
       </div>

      </Grid>
    </Grid>
  </InputGroup> ))}
  <br/>
 {/* ------------------------------------------------DISPLAY BY SKILL NAME------------------------------------------------ */}
 {this.state.savedJobApplications !== '' &&
        this.state.mySavedJobsBySkills.map((item)=>(
         <InputGroup>
           <Grid container spacing={2}>
            <Grid item xs={10}>
              <div class="mt-4">
               <div class="border border-dark rounded-lg offset-1">
                 <div class="row" >
                   <div class="col-3 p-3 pl-4" >
                   <h5 style={{fontSize:"15px"}}><b>Service ID&nbsp;:&nbsp;{item.gts_job_id}</b></h5>
                     <h5 style={{fontSize:"15px"}}><strong>Service Name: </strong>{item.job_title_name.toUpperCase() }</h5>
                     <h5 style={{fontSize:"15px"}}><strong>Company: </strong><span style={{color:'black'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.company_name.toUpperCase() }</span></h5>
                    <h5 style={{fontSize:"15px"}}><strong>Job Description: </strong><span style={{color:'black'}}>
                     <ShowMoreText
                        lines={2}
                        more='Show more'
                        less='Show less'
                        className='content-css'
                        anchorClass='my-anchor-css-class'
                        onClick={this.executeOnClick}
                        expanded={false}
                        width={230}
                      >
                       {item.gts_job_description}
                      </ShowMoreText></span></h5>

                   </div>

                    <div class="col-3 p-3">
                      <br/>
                      {item.gts_job_post_skill_ids.map((skill)=>{
                        this.state.jobSkillName.push(skill.gts_skill_name)
                      })}
                      <h6 style={{fontSize:"15px"}}>Skills: <span style={{color:'red'}}>{this.state.jobSkillName.toString()}</span></h6>
                      <br/>
                    </div>

                   <div class="col-3 p-3">
                     <br/>
                      <h6 style={{fontSize:"15px"}}>Posted Date: <span style={{color:'red'}}>{Moment(item.gts_job_posted_date).format('DD-MMM-YYYY') }</span></h6>
                      {item.gts_job_min_exp_in_months == 0 || item.gts_job_min_exp_in_months == '' ? <h6>Experience: '' </h6> :
                      <h6 style={{fontSize:"15px"}}>Experience: <span style={{color:'red'}}>{item.gts_job_min_exp_in_months} months</span></h6>
                      }
                      <h6 style={{fontSize:"15px"}}>Location:<span style={{color:'red'}}>{item.city_name}</span></h6>
                    </div>
                    <div class="col-0 p-2">
                      <br/>
                      <button
                       className="btn btn-primary"
                       data-toggle="modal"
                       data-target={"#viewSavedJob"+item.gts_job_id}
                       style={{backgroundColor:"white",color:"white", align:"right", borderRadius:"10px", fontSize:"12px"}}
                       >
                       <b>View</b>
                      </button>
                    </div>
                    <div class="col p-2">
                      <br/>
                      <button
                       className="btn btn-primary"
                       style={{backgroundColor:"white",color:"white", align:"right", borderRadius:"10px", fontSize:"12px"}}
                       onClick={()=>this.deleteSavedJob(item.gts_job_id)}
                       >
                       <b>Remove</b>
                      </button>
                    </div>
                  </div>
             </div>
             </div>

       <div id={"viewSavedJob"+item.gts_job_id} class="modal fade" role="dialog">
         <div class="modal-dialog modal-xl" >
           <div class="modal-content">
              <div class="modal-body">
                <div className="container" >
                  <div class="row-sm m-0  text-left">
                  <div class="row-0" align="right">
                     <div class="col-0" align="right">
                         <button  type="button" class="close" data-dismiss="modal" ><i  class="fas fa-window-close fa-lg"></i></button>
                       </div>
                     </div>
                  <InputGroup>
                      <div class="row-0" align="left">
                        <div class="col-0" align="left">
                        <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Service ID&nbsp;:&nbsp;{item.gts_job_id}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Service Name&nbsp;:&nbsp;{item.job_title_name.toUpperCase()}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                          <h6 style={{color : "black", display:this.state.gts_job_title}}><label style={{fontSize:"15px"}}><b>Industry Name&nbsp; :&nbsp;</b><span style={{color:'red'}}>{item.industry_name}</span>&nbsp;&nbsp;</label></h6>
                          <h6  style={{color : "black", display : this.state.is_company_requirement == true ? 'block' : 'none'}}><label style={{fontSize:"15px"}}><b>Company Name&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.company_name}</span>&nbsp;&nbsp;</label></h6>
                        </div>
                     </div>
                     <div class="row-0" align="right">
                        <div class="col-0" align="right">
                          <br/>
                        <h6  style={{color : "black"}}><label style={{fontSize:"15px", display:item.gts_job_id >0 ? 'none' : 'block'}}><b>Service ID&nbsp;:&nbsp;{item.gts_job_id}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                         <h6  style={{color : "black"}}><label style={{fontSize:"15px"}} ><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Expiry Date&nbsp; :&nbsp;</b><span style={{color:'red'}}>{Moment(item.gts_job_expiry_date).format('DD-MMM-YYYY')}</span>&nbsp;&nbsp;</label></h6>
                         </div>
                     </div>

                     <div class="row-0" align="right">
                       <div class="col-0" align="right">
                       <br/>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px", display:item.gts_job_id >0 ? 'none' : 'block'}}><b>Service ID&nbsp;:&nbsp;{item.gts_job_id}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}} ><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Experience(Months) &nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_min_exp_in_months}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}} ><b>Work Type &nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_work_type}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                          <h6  style={{color : "black"}}><label style={{fontSize:"15px"}} ><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Job Type&nbsp;:&nbsp;</b> <span style={{color:'red'}}>{item.gts_job_type}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                        </div>
                      </div>

                     <div class="row-0" align="right">
                        <div class="col-0" align="right">
                        <br/>
                        <h6  style={{color : "black"}}><label style={{fontSize:"15px", display:item.gts_job_id >0 ? 'none' : 'block'}}><b>Service ID&nbsp;:&nbsp;{item.gts_job_id}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                          <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Country&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.country_name}</span>&nbsp;</label></h6>
                          <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;City&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.city_name}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                       </div>
                     </div>
                   </InputGroup>

                    <div className="form-row">
                      <h6 class="text" style={{color : "black", display : this.state.is_company_requirement == true ? 'block' : 'none'}}><label style={{fontSize:"15px"}}><b>&nbsp;Company/SC Description &nbsp;:&nbsp; </b>{item.company_description}&nbsp;&nbsp;</label></h6>
                   </div>

                   <div className="row">
                      <div class="col">
                       <h6 class="text" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Service Description&nbsp;:&nbsp;</b>
                       <h6 >{item.gts_job_description}</h6></label></h6>
                      </div>
                   </div>

                   <div className="row">
								      <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;Skills &nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_post_skill_ids.map(user_skill =>user_skill.gts_skill_name.concat(', '))}</span></label></h6>
                   </div>

                   <InputGroup>
                     <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Minimum Qualification &nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.degree_name}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                       <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Language Proficiency&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.language_name}</span> &nbsp;&nbsp;&nbsp;</label></h6>
                       <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Notice Period(Days)&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_expected_hiring_weeks}</span>&nbsp;&nbsp;&nbsp;</label></h6>
                       <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No. of Vacancies&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_vacancy_numbers}</span>&nbsp;</label></h6>
                     </InputGroup>

                     <InputGroup>
                       <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>Salary Currency&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.currency_name}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></h6>
                        <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;Salary&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_salary}</span>&nbsp;</label></h6>
                       <h6 class="form-group" style={{color : "black"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;Per&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_salary_duration_unit}</span>&nbsp;</label></h6>
                     </InputGroup>

                     <div style={{borderBottomColor: 'black',borderBottomWidth: "0.5px"}}/><br/>

                     <InputGroup>
                        <h6 class="form-group" style={{color : "black", align:"left"}}><label style={{fontSize:"15px"}}><b>Email&nbsp;:&nbsp;</b><span style={{color:'red'}}>{item.gts_job_contact_email}</span>&nbsp;</label></h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <h6 class="form-group" style={{color : "black", align:"center"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mobile Number&nbsp;:&nbsp;</b><span style={{color:'red'}}>+{item.gts_job_mobile_country_code}&nbsp;{item.gts_job_contact_mobile_number}</span>&nbsp;&nbsp;&nbsp;</label></h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <h6 class="form-group" style={{color : "black", align:"right"}}><label style={{fontSize:"15px"}}><b>&nbsp;&nbsp;WhatsApp Number&nbsp;:&nbsp;</b><span style={{color:'red'}}>+{item.gts_job_whatsapp_country_code}&nbsp;{item.gts_job_contact_whatsapp_number}</span>&nbsp;</label></h6>
                      </InputGroup>

                  </div>
                </div>
              </div>
               <div class="modal-footer">
                   <div class="text-inline">
                     <Button type="button" class="close" data-dismiss="modal"  data-toggle="modal" data-target={"#savedJobProposal"+item.gts_job_id} color="primary" onBlur={this.clearError.bind(this)}>APPLY FOR JOB</Button>
                   </div>
                 </div>
           </div>
         </div>
       </div> }

       <div id={"savedJobProposal"+item.gts_job_id} class="modal fade" role="dialog">
         <div class="modal-dialog modal-lg ">
           <div class="modal-content">
             <div style={{align:"center"}}>
           <button type="button"  class="close"  data-dismiss="modal" style={{align:"right"}}>&times;</button>
           </div>
              <div class="modal-body">
                <div className="container" >
                  <h6 style={{fontSize:"15px"}}>Job Proposal: </h6>
                  <Form.Control
                    as="textarea"
                    rows={'auto'}
                    style={{height:"200px"}}
                    onChange={this.changeHandler}
                    name="gts_job_proposal"
                    id="gts_job_proposal"
                   onFocus={this.clearError}
                      />
                </div>
                <div class="modal-footer">
                <Button
                  type="button"
                  color="primary"
                  onClick={()=>this.applyForJob(item.gts_job_id)}
                  onBlur={this.clearError.bind(this)}
                  onChange={this.state.jobProposal=''}>
                  Skip
                </Button>
                <Button
                  type="button"
                  color="primary"
                  onClick={()=>this.applyForJob(item.gts_job_id)}
                  onBlur={this.clearError.bind(this)}>
                  Save
                </Button>
                <br/>
                <span style={{fontSize:"15px", color:'red'}} ><strong><center>{this.state.error}</center></strong></span>
                     <span style={{fontSize:"15px", color:'green'}} ><strong><center>{this.state.message}</center></strong></span>
                </div>
             </div>
           </div>
          </div>
        </div>

       <div style={{display: this.state.jobSkillName!==null ? 'none' : 'block'}}>
         { this.state.jobSkillName =  []}
       </div>

      </Grid>
    </Grid>
  </InputGroup> ))}
  <br/>
 {/* --------------------------------------------------------------------------------------------------------------------- */}
  </div>
  );
  }
}

export default SPSavedServiesComponent;
