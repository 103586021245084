import React from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import AboutUsComponent from '../../components/common/AboutUsComponent.js';


class AboutUsPage extends React.Component{
    render(){
        return(
            <div>
                <Header />
                <AboutUsComponent />
                <Footer />
            </div>
        )
    }
}
export default AboutUsPage;