import React, { Component } from "react";
import { AppBar, Container } from "@material-ui/core";
import Footer from "../../components/common/Footer";
import Header from "../../components/common/Header";
import SearchServiceProviderComponent from "../../components/common/SearchServiceProviderComponent";
import ls from 'local-storage';
import ServiceConsumerMenu from '../../components/service_consumer/ServiceConsumerMenu';
import AdminMenu from '../../components/admin/AdminMenu';
import RecruiterMenu from '../../components/recruiter/RecruiterMenu';

var token = ls.get("token");
var userLoginDetails=ls.get('userLoginDetails');

class SearchServiceProviderPage extends Component {

  constructor(props) {
	  super(props);
		if(token==null || token=="" || token=="undefined"){
		  window.location.href = '/gts/global-home-page';
		}
    this.state = {}
  }
  render() {
    return (
      <Container id="main-container">
		<AppBar className="header-part">
			<Header />
			<ServiceConsumerMenu/>
		</AppBar>
		<SearchServiceProviderComponent />
		<Footer />
      </Container>
    );
  }
}

export default SearchServiceProviderPage;