import React, { Component } from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import ls from 'local-storage';
import axios from 'axios';
import Moment from 'moment';
import {FormControl, InputGroup,Dropdown,Accordion ,Card,NavDropdown} from 'react-bootstrap';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { endpoints_properties } from '../../properties/EndPointsProperties.js';
import { api_properties } from '../../properties/APIProperties.js';

var userLoginDetails=ls.get('userLoginDetails');
if(userLoginDetails !=null){
var gts_user_id = userLoginDetails.user_id;
}
var token = ls.get('token');
var userDetail = '';
var userPersonalDetails=ls.get('userPersonalDetails');

class SCListOfClosedApplications extends Component {

  constructor(props) {
    super(props)

    this.state = {
      jobClosedApplications:[],
      closedApplications:[],
      isCollapsed: false,
      displayed:false,
      totalCandiadtes:0
    }
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  componentDidMount() {
    var appliedJobIDs=[];
    var jobURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_SC_SERVICES_ACTIVE+'/'+gts_user_id;
    axios.get(jobURL,{ headers: {"Auth_Token" : `Bearer ${token}`} })
    .then(response =>{
      this.setState({jobClosedApplications: response.data})
      for(var i=0; i<response.data.length;i++){
        var closedJobapplicationURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_SERVICE_APPLICATIONS_BY_APPLIED_ID+'/'+response.data[i].gts_job_id;
        axios.get(closedJobapplicationURL,{ headers: {"Auth_Token" : `Bearer ${token}`} })
        .then(res =>{
          for(var i=0;i<res.data.length;i++){
            if(res.data[i]!== ''){
              if(res.data[i].gts_job_application_status == 'REJECTED' || res.data[i].gts_job_application_status == 'OFFERED'){
                appliedJobIDs.push(res.data[i])
                console.log(appliedJobIDs)
              }
            }
          }
          this.setState({closedApplications: appliedJobIDs})
      })
      .catch(error => {
        this.setState({status:400})
        })
    }
    })
    .catch(error => {
       this.setState({status:400})
   })
  }

  render(){
    return(
      <div className="mt-2">
        <div className="container align-jobAppls-center">
         <h5 class="p-1">LIST OF CLOSED APPLICATIONS</h5>
         <div className="border border-dark  rounded-lg">
           {this.state.closedApplications.map((job, index)=>(
           <InputGroup>
              <div style={{ display: 'block', width: 1150, padding: 15 }}>
                <Accordion defaultActiveKey="1">
                 <Card>
                   <Card.Header>
                      <div class="row" >
                       <div class="col-2">
                          <h6 style={{fontSize:"14px", color:'black'}}><b>Service ID: </b><span style={{color:'red'}}> {job.gts_job_id}</span></h6>
                        </div>
                        <div class="col-3">
                            <h6 style={{fontSize:"14px", color:'black'}}><b>Service Name: </b><span style={{color:'red'}}>{job.job_title_name.toUpperCase()}</span></h6>
                        </div>
                        <div class="col-3">
                          <h6 style={{fontSize:"14px", color:'black'}}><b>Years Of Experience: </b><span style={{color:'red'}}>{job.gts_job_min_exp_in_months}&nbsp; months</span></h6>
                        </div>
                        <div class="col-3">
                          <h6 style={{fontSize:"14px", color:'black'}}><b>Skills: </b><span style={{color:'red'}}> {job.gts_job_post_skill_ids.map((skill, i) => ( <label key={i}>{skill.gts_skill_name.concat(", ")}</label>))}</span></h6>
                        </div>
                        <div class="col" id={job.gts_job_id}>
                          <Accordion.Toggle eventKey= "0" aria-controls="additional-actions3-content" aria-label="Expand" >
                            <i style={{align:"right"}} id="btnGetCount" value="Count Rows"  aria-hidden="true"> <ExpandMoreIcon /></i>
                          </Accordion.Toggle>
                        </div>
                     </div>
                   </Card.Header>
                   <Accordion.Collapse eventKey="0" >
                      <Card.Body>
                        <div class="row">
                            <div class="col-5">
                              <h6 style={{fontSize:"14px", color:'black'}}><b>Date Of Posting Job:&nbsp; </b><span style={{color:'red'}}> {Moment(job.gts_job_posted_date).format('DD-MMM-YYYY')}</span></h6>
                              <h6 style={{fontSize:"14px", color:'black'}}><b>Location:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b><span style={{color:'red'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{job.city_name}</span></h6>
                              <h6 style={{fontSize:"14px", color:'black'}}><b>No. Of Candidates: &nbsp;&nbsp;&nbsp;&nbsp;</b><span style={{color:'red'}}>{this.state.closedApplications.slice(0,1).map(jobAppl =>( job.gts_job_id ==  jobAppl.gtsJobDetail.gts_job_id ? jobAppl.numberOfCandiadtesClosedApplication : 0))}</span></h6>
                          </div>
                        </div>
                        <br/>
                        <div class="table-responsive">
                            <table class="table table-bordered sm">
                            <thead>
                                <tr>
                                  <td scope="col" ><h6 style={{color:'black', fontSize:'14px'}}><b><center>Name of Applicant</center></b></h6></td>
                                  <td scope="col" colSpan={job.gts_job_post_skill_ids.length}><h6 style={{color:'black', fontSize:'14px'}}><b><center>Years of Experience</center></b></h6></td>
                                  <td scope="col" colSpan={job.gts_job_post_skill_ids.length}><h6 style={{color:'black', fontSize:'14px'}}><b><center>Self Assessment</center></b></h6></td>
                                  <td scope="col"><h6 style={{color:'black', fontSize:'14px'}}><b><center>Current Salary</center></b></h6></td>
                                  <td scope="col"><h6 style={{color:'black', fontSize:'14px'}}><b><center>Status</center></b></h6></td>
                                  <td scope="col"><h6 style={{color:'black', fontSize:'14px'}}><b><center>Last Interview Date</center></b></h6></td>
                                  <td scope="col" colSpan="2"><h6 style={{color:'black', fontSize:'14px'}}><b>Schedule&nbsp;Interview&nbsp;&nbsp;&nbsp;&nbsp;Recommend&nbsp;Test</b></h6></td>
                                </tr>
                            </thead>
                            <thead>
                                <td></td>
                                {job.gts_job_post_skill_ids.map((skill, i) => ( skill.gts_skill_name!=='' ? <td scope="col" ><label key={i}><h6 style={{color:'black', fontSize:'14px'}}><b><center>{skill.gts_skill_name}</center></b></h6></label>&nbsp;</td>:<td></td>))}
                                {job.gts_job_post_skill_ids.map((skill, i) => ( <td scope="col"><label key={i}><h6 style={{color:'black', fontSize:'14px'}}><b>{skill.gts_skill_name}</b></h6></label>&nbsp;</td>))}
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </thead>
                            {
                                this.state.closedApplications.map((jobAppl, index)=>( job.gts_job_id ==  jobAppl.gtsJobDetail.gts_job_id ?
                                  <tbody>
                                    {jobAppl.gtsApplicant.gts_applicant_name ?
                                      <td><h6 style={{color:'black', fontSize:'14px',paddingTop:"7px",color:'black'}}><center>{jobAppl.gtsApplicant.gts_applicant_name}</center></h6></td>
                                    :<td></td>}

                                      {jobAppl.gts_service_provider_skills == null ?
                                      job.gts_job_post_skill_ids.map((userskill,i)=>(<td ><h6 style={{color:'black', fontSize:'14px',paddingTop:"7px"}}>-NIL-</h6></td>)):
                                      job.gts_job_post_skill_ids.map((userskill, i) => ( <td scope="col"><label key={i}><h6 style={{color:'black', fontSize:'14px',paddingTop:"7px"}}><center>{jobAppl.gts_service_provider_skills.map((skill, i) => userskill.gts_skill_id ==skill.gts_skill_id ? (skill.gts_skill_experience_months)/12 >= 1 ? (skill.gts_skill_experience_months/12).toString().split('.')[0]+" years "+(("0."+(skill.gts_skill_experience_months/12).toString().split('.')[1]))*12+" months" : skill.gts_skill_experience_months+" months" : '-NIL-') }</center></h6></label>&nbsp;</td>))
                                      }

                                      {jobAppl.gts_service_provider_skills == null ?
                                      job.gts_job_post_skill_ids.map((userskill,i)=>(<td ><h6 style={{color:'black', fontSize:'14px',paddingTop:"7px"}}>0/10</h6></td>)):
                                      job.gts_job_post_skill_ids.map((userskill, i) => ( <td scope="col"><label key={i}><h6 style={{color:'black', fontSize:'14px',paddingTop:"7px"}}>{jobAppl.gts_service_provider_skills.map((skill, i) => userskill.gts_skill_id ==skill.gts_skill_id ? skill.gts_skill_self_ratings+'/10': '0/10')}</h6></label>&nbsp;</td>))
                                      }

                                      { jobAppl.gtsApplicant.gts_current_salary !=="" ?
                                        <td><h6 style={{color:'black', fontSize:'14px',paddingTop:"7px"}}><center>{jobAppl.gtsApplicant.gts_current_salary}</center></h6></td>
                                      :<td><h6 style={{color:'black', fontSize:'14px',paddingTop:"7px"}}><center>-NIL-</center></h6></td>}

                                      <td><h6 style={{color:'black', fontSize:'14px',paddingTop:"7px"}}><center>{jobAppl.gts_job_application_status}</center></h6></td>
                                      <td><h6 style={{color:'black', fontSize:'14px',paddingTop:"7px"}}><center>{jobAppl.gts_job_proposed_for_interview_date!==null?Moment(jobAppl.gts_job_proposed_for_interview_date).format('DD-MMM-YYYY'):''}</center></h6></td>

                                      { jobAppl.gts_job_application_status == 'REJECTED' ?
                                      <td><h6 style={{color:'black', fontSize:'14px'}}><center>
                                      {((new Date() - new Date(jobAppl.gts_job_proposed_for_interview_date))/(1000 * 3600 * 24)) > 30 ?
                                      <button
                                          className="btn btn-primary"
                                          style={{backgroundColor:"white",color:"white", align:"right", borderRadius:"10px", fontSize:"10px"}}>
                                          <b>Schedule</b>
                                        </button>
                                        :
                                      <button
                                          disabled
                                          className="btn btn-primary"
                                          style={{backgroundColor:"white",color:"white", align:"right", borderRadius:"10px", fontSize:"10px"}}>
                                          <b>Schedule</b>
                                        </button>
                                      }

                                      {((new Date() - new Date(jobAppl.gts_job_proposed_for_interview_date))/(1000 * 3600 * 24)) > 30 ?
                                        <button
                                          className="btn btn-primary"
                                          style={{backgroundColor:"white",color:"white", align:"right", borderRadius:"10px", fontSize:"10px"}}>
                                          <b>Recommend</b>
                                       </button>
                                       :
                                        <button
                                          disabled
                                          className="btn btn-primary"
                                          style={{backgroundColor:"white",color:"white", align:"right", borderRadius:"10px", fontSize:"10px"}}>
                                          <b>Recommend</b>
                                       </button>
                                      }
                                    </center></h6></td>
                                    :<td style={{backgroundColor:'green'}}></td>}
                                  </tbody>
                                  : ''
                                ))
                              }
                          </table>
                          </div>
                      </Card.Body>
                    </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
            </InputGroup>))}
          </div>
       </div>
     </div>
    )
  }
}
export default SCListOfClosedApplications;