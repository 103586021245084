import React, { Component } from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import ls from 'local-storage';
import axios from 'axios';
import Moment from 'moment';
import {FormControl, InputGroup,Dropdown,Accordion ,Card,NavDropdown,Button} from 'react-bootstrap';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { endpoints_properties } from '../../properties/EndPointsProperties.js';
import { api_properties } from '../../properties/APIProperties.js';

var userLoginDetails=ls.get('userLoginDetails');
var gts_user_id = "";
if(userLoginDetails!=null){
  gts_user_id = userLoginDetails.user_id;
}
var token = ls.get('token');
var userDetail = '';
var userPersonalDetails=ls.get('userPersonalDetails');

class TopQualifiedServiceProvider extends Component {

  constructor(props) {
    super(props)

    this.state = {
      selectedIds:[],
      selected:'none',
      myServices:[],
      isCollapsed: false,
      displayed:false,
      serviceProviders:[],
      TopQualifiers:[],
      gts_job_id:0,
      spIds : [],
      emailsValidated: false,
      loopCompleted: false,
      selectedProvider:[],
      qualifiers:[],
      contactsValidated: false,
      count:[]
    }
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  componentDidMount() {

    var appliedJobIDs=[];
    var jobURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_SC_SERVICES_ACTIVE+'/'+gts_user_id;
    axios.get(jobURL,{ headers: {"Auth_Token" : `Bearer ${token}`} })
    .then(response =>{
      this.setState({myServices: response.data})
      for(var i=0; i<response.data.length;i++){
      var jobapplicationURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_TOP_QUALIFIED_SP+"/"+response.data[i].gts_job_id;
      console.log(jobapplicationURL)
      axios.get(jobapplicationURL,{ headers: {"Auth_Token" : `Bearer ${token}`} })
      .then(res =>{
        this.setState({error : " "})
         this.setState({TopQualifiers:[]})
        this.state.TopQualifiers.push(res.data)
       console.log(this.state.TopQualifiers)
        for(var i=0; i<this.state.TopQualifiers.length;i++){
          for(var j=0; j<this.state.TopQualifiers[i].gts_top_qualified_jobseekers.length;j++){
           appliedJobIDs.push(Object.assign(this.state.TopQualifiers[i].gts_top_qualified_jobseekers[j],this.state.TopQualifiers[i].gts_job));
           var spCount = {'job_id':this.state.TopQualifiers[i].gts_job.gts_job_id,'numberOfTopQualifiers':this.state.TopQualifiers[i].numberOfTopQualifiers}
           this.state.count.push(spCount)
           this.setState({serviceProviders:appliedJobIDs})
          }
        }
        console.log(this.state.serviceProviders)
     })
     .catch(error => {
       this.setState({status:400})
      })
      this.setState({error : "No Top Qualified Service Providers"})
    }
    })
    .catch(error => {
       this.setState({status:400})
   })
  }

  selectCandidates = (e , sp)=>{
    this.setState({selected: e.target.checked})
    this.state.selected = e.target.checked
    this.setState({appliedmessage:''})
    this.setState({error:''})
    this.setState({gts_job_id : sp.gts_job_id})

     if(this.state.selected == true){

       this.state.selectedIds.push(sp.gts_user_id)
       this.setState({selectedIds: this.state.selectedIds})
       this.state.spIds=this.state.selectedIds
     }
     else{
      const valuesToRemove = [sp.gts_user_id]
      const filteredItems = this.state.selectedIds.filter(item => !valuesToRemove.includes(item))
      this.state.selectedIds = filteredItems
      this.state.spIds=this.state.selectedIds
     }
  }

  notifyTopQualifiers =()=>{
    var url = endpoints_properties.ENDPOINT_PROD+api_properties.API_NOTIFY_TOP_QUALIFIED_SP;
    if(this.state.spIds !==undefined && this.state.job_id !==0){
      for(var i =0; i<this.state.spIds.length;i++){
        var payLoad ={
          gts_user_id:this.state.spIds[i],
          gts_job_id:this.state.gts_job_id
        }
        axios.post(url,payLoad,{ headers: {"Auth_Token" : `Bearer ${token}`} })
        .then(res =>{
          this.state.selectedIds = []
          this.state.spIds= []
          this.setState({notifiedMessage:res.data.message })
          this.state.notifiedMessage = res.data.message
        })
        .catch(err =>{
          if(err.response){
            this.setState({error : err.response.data.message})
            this.state.error = err.response.data.message
          }
          else{
            this.setState({error : "Top qualified service providers are not notified."})
            this.state.error = "Top qualified service providers are not notified."
          }
        })
      }
    }
  }

  clearMessage=()=>{
    this.state.notifiedMessage = ""
    this.state.error = ""
    this.setState({notifiedMessage : ''})
    this.setState({error : ''})

  }

  render(){
    return(
      <div className="align-items-center mt-4">
      <div className="align-items-center mt-4">
        <div className="container align-items-center" style={{width:1150}}>
          {this.state.myServices!= null ?
         <div className="border border-dark  rounded-lg">
           {this.state.myServices.map((job, jobIndex)=>(
           <InputGroup>
              <div style={{ display: 'block', width: 1150, padding: 15 }}>

<Accordion defaultActiveKey="1">
      <Card>
        <Card.Header>
        <div class="row" >
                   <div class="col-3">
                      <h6 style={{fontSize:"13px", color:'black', display:this.state.isCollapsed ?'none' : 'block'}}><b>Service Name: </b><span style={{color:'red'}}>{job.job_title_name.toUpperCase()}</span></h6>
                   </div>
                   <div class="col-3">
                     <h6 style={{fontSize:"13px", color:'black', display:this.state.isCollapsed ?'none' : 'block'}}><b>Years Of Experience: </b><span style={{color:'red'}}>{job.gts_job_min_exp_in_months}&nbsp; months</span></h6>
                   </div>
                   <div class="col-3">
                     <h6 style={{fontSize:"13px", color:'black', display:this.state.isCollapsed  ? 'none' :'block'}}><b>Date Of Posting Job: </b><span style={{color:'red'}}> {Moment(job.gts_job_posted_date).format('DD-MMM-YYYY')}</span></h6>
                   </div>
                   <div class="col-2">
                     <h6 style={{fontSize:"13px", color:'black', display:this.state.isCollapsed  ?'none' : 'block'}}><b>No. Of Candidates: &nbsp;</b><span style={{color:'red'}}>{this.state.count.slice(0,this.state.count.length).map((sp, index)=>( job.gts_job_id == sp.job_id ? sp.numberOfTopQualifiers : ''))}</span></h6>
                   </div>
                   <div class="col" id={job.gts_job_id}>
                      <Accordion.Toggle eventKey= "0" aria-controls="additional-actions3-content" aria-label="Expand" >
                        <i style={{align:"right"}}  aria-hidden="true"> <ExpandMoreIcon /></i>

                      </Accordion.Toggle>
                   </div>
                 </div>
        </Card.Header>
        <Accordion.Collapse eventKey="0" >
          <Card.Body>
          <div class="row">
                          <div class="col-5">
                            {this.state.displayed = true}
                            <h6 style={{fontSize:"14px", color:'black'}}><b>Service ID:&nbsp; </b><span style={{color:'red'}}>{job.gts_job_id}</span></h6>
                            <h6 style={{fontSize:"14px", color:'black'}}><b>Service Name:&nbsp; </b><span style={{color:'red'}}>{job.job_title_name}</span></h6>
                            <h6 style={{fontSize:"14px", color:'black'}}><b>Years Of Experience:&nbsp; </b><span style={{color:'red'}}> {job.gts_job_min_exp_in_months}&nbsp; months</span></h6>
                            <h6 style={{fontSize:"14px", color:'black'}}><b>Date Of Posting Job:&nbsp; </b><span style={{color:'red'}}> {Moment(job.gts_job_posted_date).format('DD-MMM-YYYY')}</span></h6>
                            <h6 style={{fontSize:"14px", color:'black'}}><b>Location:&nbsp; </b><span style={{color:'red'}}>{job.city_name}</span></h6>
                            <h6 style={{fontSize:"14px", color:'black'}}><b>Skills: </b><span style={{color:'red'}}> {job.gts_job_post_skill_ids.map((skill, i) => ( <label key={i}>{skill.gts_skill_name.concat(", ")}</label>))}</span></h6>
                         </div>
                         <div class="col">
                           <h6 style={{fontSize:"14px", color:'black'}}><b>No. Of Candidates: &nbsp;</b><span style={{color:'red'}}>{this.state.count.slice(0,this.state.count.length).map((sp, index)=>( job.gts_job_id == sp.job_id ? sp.numberOfTopQualifiers : ''))}</span></h6>
                         </div>
                       </div>

   {/*------------------------------------------------------------TABLE------------------------------------------------------------  */}
          <div class="table-responsive">
          <table class="table table-bordered sm" style={{height:"30px"}}>
          <thead>
    <tr>
    <td scope="col" ><h6 style={{color:'black', fontSize:'13px'}}><b><center>Name of Applicant</center></b></h6></td>
    <td scope="col" colSpan={job.gts_job_post_skill_ids.length}><h6 style={{color:'black', fontSize:'13px'}}><b><center>Years of Experience</center></b></h6>
    </td>
    <td scope="col" colSpan={job.gts_job_post_skill_ids.length}><h6 style={{color:'black', fontSize:'13px'}}><b><center>Self Assessment</center></b></h6></td>
    <td scope="col" colSpan={job.gts_job_post_skill_ids.length}><h6 style={{color:'black', fontSize:'13px'}}><b><center>GTS Assessment</center></b></h6></td>
    <td scope="col" colSpan={job.gts_job_post_skill_ids.length}><h6 style={{color:'black', fontSize:'13px'}}><b><center>Employer Assessment</center></b></h6></td>
    <td scope="col"><h6 style={{color:'black', fontSize:'13px'}}><b><center>Current Salary in LPA</center></b></h6></td>
    <td scope="col"><h6 style={{color:'black', fontSize:'13px'}}><b><center>Selected Candidates</center></b></h6></td>
    </tr>
  </thead>

  <thead>
    <td></td>
    {job.gts_job_post_skill_ids.map((skill, i) => ( <td scope="col"><label key={i}><h6 style={{color:'black', fontSize:'12px'}}><b><center>{skill.gts_skill_name}</center></b></h6></label>&nbsp;</td>))}
    {job.gts_job_post_skill_ids.map((skill, i) => ( <td scope="col"><label key={i}><h6 style={{color:'black', fontSize:'12px'}}><b>{skill.gts_skill_name}</b></h6></label>&nbsp;</td>))}
    {job.gts_job_post_skill_ids.map((skill, i) => ( <td scope="col"><label key={i}><h6 style={{color:'black', fontSize:'12px'}}><b>{skill.gts_skill_name}</b></h6></label>&nbsp;</td>))}
    {job.gts_job_post_skill_ids.map((skill, i) => ( <td scope="col"><label key={i}><h6 style={{color:'black', fontSize:'12px'}}><b>{skill.gts_skill_name}</b></h6></label>&nbsp;</td>))}
    <td></td>
    <td></td>
  </thead>

  {
  this.state.serviceProviders.map((sp, index)=>(
      job.gts_job_id ==  sp.gts_job_id ?

  <tbody>
      <td><h6 style={{color:'black', fontSize:'13px',paddingTop:"7px"}}><center>{sp.gts_user_name}</center></h6></td>

      {sp.gts_user_skills == null ?
        sp.gts_user_skills.map((userskill,i)=>(<td ><h6 style={{color:'black', fontSize:'14px',paddingTop:"7px"}}>-NIL-</h6></td>))
        :
        job.gts_job_post_skill_ids.map((userskill, i) => ( <td scope="col"><label key={i}><h6 style={{color:'black', fontSize:'14px',paddingTop:"7px"}}>{sp.gts_user_skills.map((skill, i) => userskill.gts_skill_id == skill.gts_skill_id ? (skill.gts_skill_experience_months)/12 >= 1 ? (skill.gts_skill_experience_months/12).toString().split('.')[0]+" years "+(("0."+(skill.gts_skill_experience_months/12).toString().split('.')[1]))*12+" months" : skill.gts_skill_experience_months+" months" : '')}</h6></label>&nbsp;</td>))
      }

      {sp.gts_user_skills == null ?
        sp.gts_user_skills.map((userskill,i)=>(<td ><h6 style={{color:'black', fontSize:'14px',paddingTop:"7px"}}>0/10</h6></td>))
        :
        job.gts_job_post_skill_ids.map((userskill, i) => ( <td scope="col"><label key={i}><h6 style={{color:'black', fontSize:'14px',paddingTop:"7px"}}>{sp.gts_user_skills.map((skill, i) => userskill.gts_skill_id == skill.gts_skill_id ? skill.gts_skill_self_ratings == null || skill.gts_skill_self_ratings == 0 ? '' : skill.gts_skill_self_ratings+'/10': '' )}</h6></label>&nbsp;</td>))
      }

      {sp.gts_user_skills == null ?
        sp.gts_user_skills.map((userskill,i)=>(<td ><h6 style={{color:'black', fontSize:'14px',paddingTop:"7px"}}>0/10</h6></td>))
        :
        job.gts_job_post_skill_ids.map((userskill, i) => ( <td scope="col"><label key={i}><h6 style={{color:'black', fontSize:'14px',paddingTop:"7px"}}>{sp.gts_user_skills.map((skill, i) => userskill.gts_skill_id == skill.gts_skill_id ? skill.gts_skill_chakuri_ratings == null || skill.gts_skill_chakuri_ratings == 0 ? '' :  skill.gts_skill_chakuri_ratings+'/10': '' )}</h6></label>&nbsp;</td>))
      }

     {sp.gts_user_skills == null ?
        sp.gts_user_skills.map((userskill,i)=>(<td ><h6 style={{color:'black', fontSize:'14px',paddingTop:"7px"}}>0/10</h6></td>))
        :
        job.gts_job_post_skill_ids.map((userskill, i) => ( <td scope="col"><label key={i}><h6 style={{color:'black', fontSize:'14px',paddingTop:"7px"}}>{sp.gts_user_skills.map((skill, i) => userskill.gts_skill_id == skill.gts_skill_id ? skill.gts_skill_external_ratings == null || skill.gts_skill_external_ratings == 0 ? '' :  skill.gts_skill_external_ratings+'/10': '' )}</h6></label>&nbsp;</td>))
      }

      <td><h6 style={{color:'black', fontSize:'13px',paddingTop:"7px"}}><center>{sp.gts_current_salary==0 ? '-NIL-' :(sp.gts_current_salary * 12)}</center></h6></td>
      <td><h6 style={{color:'black', fontSize:'13px'}}><center>
        {
          <input
             type="checkbox"
             id="selectedCandidates"
             class="custom-control custom-checkbox checkbox-lg"
             style={{paddingTop:"10px", width:"22px", height:"22px", borderRadius: ".25em"}}
             onClick={e => this.selectCandidates(e,sp)}
             onBlur={this.clearMessage}
          />
        }
      </center></h6></td>
    </tbody>

   : ''
  ))}
</table>
        </div>
        <Button
          type ="button"
          variant="primary"
          class="btn btn-primary float-right"
          style={{ width:"80px", height:"40px",float: "right" }}
          onClick={this.notifyTopQualifiers}
          onBlur={this.clearMessage}
          >
            Notify
        </Button>

        <span style={{color:'red'}}><strong><center>{this.state.error}</center></strong></span>
        <span style={{color:'green'}}><strong><center>{this.state.notifiedMessage}</center></strong></span>

          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>


             </div>
           </InputGroup>))}
         </div>
         :''}
       </div>
     </div>
     </div>
    )
  }
}
export default TopQualifiedServiceProvider;