import React, { Component } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
   } from 'reactstrap';
import {FormLabel} from 'react-bootstrap';
import { endpoints_properties } from '../../properties/EndPointsProperties.js';
import { api_properties } from '../../properties/APIProperties.js';
import axios from 'axios';

const pageBackground = {
  backgroundColor: "white"
};

class ContactUsComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      countryCode: '',
      contactNumber: '',
      message: '',
      messageSuccess: '',
      errorMessage: '',
      responses: {},
      ccvalue: '',
      validated: false
    }
    this.onChangeCCHandler = this.onChangeCCHandler.bind(this)
  }


  inputOnChangeHandler = e => {
    this.setState({ [e.target.name]: e.target.value});
  };

  onChangeCCHandler = event =>{
    const re = /^[\d +]+$/;
      if (event.target.value === '' || re.test(event.target.value)) {
         this.setState({ccvalue: event.target.value})
         this.setState({countryCode: event.target.value})
      }
  }

  validate = e =>{
    if(this.state.name ==''  || this.state.email =='' || this.state.countryCode=='' || this.state.contactNumber=='' || this.state.message==''){
      this.setState({validated : false})
    }
    else{
      let responses = this.state.responses;
      this.setState({validated : true})
      responses["errorMessage"] = "";
          this.setState({
            responses: responses
          });
    }
  }




  onSubmitHandler = e => {

    e.preventDefault();


    var url = endpoints_properties.ENDPOINT_PROD+api_properties.API_CONTACT_US;
    let responses = this.state.responses;
    var payload = {
      gts_client_name: this.state.name,
      gts_client_email_id: this.state.email,
      gts_country_code: this.state.countryCode,
      gts_client_contact_number: this.state.contactNumber,
      gts_client_message: this.state.message
    };
    if(this.state.validated == true){
    axios
      .post(url, payload)
      .then(response => {

        if(response.data.success == "True"){
           responses["messageSuccess"] = response.data.message;
           this.setState({
            responses: responses
          });
        }
      })
      .catch(error => {
        responses["errorMessage"] = "Error while sending message";
          this.setState({
            responses: responses
          });
      })
    }
    else{
      responses["errorMessage"] = "Please fill the details.";
      this.setState({
        responses: responses
      });
    }
  }

  clearMessage = e =>{
    let responses = this.state.responses;
    responses["messageSuccess"] = "";
    responses["errorMessage"] = "";
      this.setState({
        responses: responses
      });
  }

  render() {

    const iconStyles = {
      // marginLeft:"100%",
      float: "left",
      clear: "left",
      height: "60px",
      width: "90px"
    }

    const successMessageStyles = {
      fontWeight: 'bold',
      fontSize: 20,
      color: "#008000"
    }

    const errorMessageStyles = {
      color: "#ff4d4d",
      fontWeight: 'bold',
      fontSize: 20
    }

    return (
      <>
      <div style={pageBackground}>
        <div className="container align-items-center">
          <div divID="icon" styles={iconStyles} align="right">
            {/* <i class="fas fa-map-marker-alt"></i> Location: <a href=" http://maps.google.com/?q=Bengaluru,IN" class="text-primary">Bengaluru</a> */}

            <img src={process.env.PUBLIC_URL + "/images/goraitech.jpg"}
              alt="pic" width="150px" class="iconStyles" align="left" />
          </div>
			<br/><br/>
          <h1 className="text-left font-weight-bold" style={{ fontSize: "2rem" }}>Gorai Technology Solutions</h1>
          <h5 class="text-left"><i>A Platform for Service Providers, Service Consumers, Trainers & Trainees</i></h5>

          <h5 class="text-center pt-4 underline font-weight-bold">
            CONTACT US
          </h5>

          <br/>
          <div class="">
            <div class="row">

              <div class="col">
                <h6 class="underline pb-4">SOCIAL NETWORK</h6>
                <ul className="list-unstyled">
                <li><p><a href="https://www.facebook.com/GTS-102920368149918" class="fb-ic" target="_blank"><i class="fab fa-facebook-square fa-2x mr-3" style={{color:"black"}}> </i>Facebook</a></p></li>
                  <li><p><a href="https://web.whatsapp.com/"><i class="fab fa-whatsapp-square fa-2x mr-3" style={{color:"black"}}> </i>Whatsapp</a></p></li>
                  <li><p><a href="https://www.twitter.com"><i class="fab fa-twitter-square fa-2x mr-3" style={{color:"black"}}> </i>Twitter</a></p></li>
                  <li><p><a href="https://in.linkedin.com/company/goraitechnologysolutions"><i class="fab fa-linkedin fa-2x mr-3" style={{color:"black"}}> </i>Linkedin</a></p></li>
                </ul>
                <p class="underline">CALL US</p>
                <p>+91 9739403914</p>
              </div>

              <div class="col ">
                <h6 class="underline pb-4 text-center">EMAIL US</h6>

                <Form method="post" name="handler" onSubmit={this.onSubmitHandler}>
                  <FormGroup className="mb-3">
                    <div divID="messageSuccess" style={successMessageStyles}>{this.state.responses.messageSuccess}</div>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <div divID="errorMessage" style={errorMessageStyles}>{this.state.responses.errorMessage}</div>
                  </FormGroup>
                    <FormGroup>
                      <FormLabel className="font-bold">Enter Name</FormLabel>
                      <InputGroup>
                        <Input
                          type="text"
                          name="name"
                          placeholder="Name"
                          onChange={this.inputOnChangeHandler}
                          onBlur={this.clearMessage}
                        />
                      </InputGroup>
                    </FormGroup>

                    <FormGroup>
                      <FormLabel className="font-bold">Enter Email ID</FormLabel>
                      <InputGroup>
                        <Input
                          type="email"
                          name="email"
                          placeholder="abc@gmail.com"
                          onChange={this.inputOnChangeHandler}
                          onBlur={this.clearMessage}
                        />
                      </InputGroup>
                    </FormGroup>

                    <FormGroup>
                      <FormLabel className="font-bold">Enter Contact Number</FormLabel>
                        {/* <InputGroup>
                          <ContactNumber />
                        </InputGroup> */}
                      <InputGroup>
                        <Input
                          type="text"
                          name="countryCode"
                          value={this.state.ccvalue}
                          maxLength={4}
                          size="4"
                          placeholder="Country Code(+91)"
                          onChange={this.onChangeCCHandler}
                          onBlur={this.clearMessage}
                        />
                        <Input
                          type="text"
                          name="contactNumber"
                          placeholder="Phone Number"
                          onChange={this.inputOnChangeHandler}
                          onBlur={this.clearMessage}
                        />
                      </InputGroup>
                    </FormGroup>

                    <FormGroup>
                      <FormLabel className="font-bold">Enter Message</FormLabel>
                      <InputGroup>
                        <Input
                          type="textarea"
                          name="message"
                          placeholder="Enter your Message"
                          onChange={this.inputOnChangeHandler}
                          onBlur={this.clearMessage}
                        />
                      </InputGroup>
                    </FormGroup>

                    <Button color="primary" type="submit" onFocus={this.validate} onBlur={this.clearMessage}>
                      Send
                    </Button>


                </Form>
              </div>

            </div>
          </div>
        </div>
        </div>
      </>
    );
  }
}
export default ContactUsComponent;