
import React, { Component } from 'react'
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import 'bootstrap/dist/css/bootstrap.css'
import axios from 'axios';
import EducationComponent from '../../components/common/EducationComponent';
import UserPersonalDetailsComponent from '../../components/common/UserPersonalDetailsComponent';
import CompanyDetailComponent from '../../components/service_consumer/CompanyDetailComponent';
import UserAddressesComponent from '../../components/common/UserAddressesComponent';
import ServiceConsumerMenu from '../../components/service_consumer/ServiceConsumerMenu';
import FieldOfRecruitmentComponent from '../../components/common/FieldOfRecruitmentComponent';
import LanguagesKnown from '../../components/common/LanguagesKnown';
import SCRequiredLanguagesKnown from '../../components/service_consumer/SCRequiredLanguagesKnown';
import RecruiterMenu from '../../components/recruiter/RecruiterMenu';
import AdminMenu from '../../components/admin/AdminMenu';
import ServiceProviderMenu from '../../components/service_provider/ServiceProviderMenu';
import ls from 'local-storage';

// reactstrap components
import {
  Button,
  Alert,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container
} from 'reactstrap';

import { FormControl, FormLabel, Card, CardBody,CardText } from 'react-bootstrap';

var token = ls.get("token");
var userLoginDetails=ls.get('userLoginDetails');


class ServiceProviderHomePage extends Component {
   constructor(props) {
		if(token==null || token=="" || token=="undefined"){
		  window.location.href = '/gts/global-home-page';
		}
		super(props);
	}
    render()
     {

        return (


           <div>
				<Header/>
					{
						userLoginDetails.primary_role == "RECRUITER"? <RecruiterMenu/> : ''
					}
					{
						userLoginDetails.primary_role == "ADMIN"? <AdminMenu/> : ''
					}
					{
						userLoginDetails.primary_role == "SERVICE_CONSUMER"? <ServiceConsumerMenu/> : ''
					}
					{
						userLoginDetails.primary_role == "SERVICE_PROVIDER"? <ServiceProviderMenu/> : ''
					}




					<div class="d-flex justify-content-around">
						<Container className="pt-lg-md">
							<Row className="justify-content-left mt-4">
								<Col className="col-6">
									<div className="pt-4">
									<h3 className="font-bold underline">What you want to do now?</h3>
									</div>
								</Col>
							</Row>

							<Row className="justify-content-left mt-4">
								<Col className="col-6">
									<Card border="primary" style={{ width: '50rem' }}>
										<Card.Body>
											<FormGroup className="mb-3" controlId="formBasicEmail">
												<FormLabel>
												    <a href="/gts/recommended-services">Recommended Services/Jobs</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
												    <a href="/gts/saved-services">Saved Service/Job</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
												    <a href="/gts/sp-search-services">Search Services/Jobs</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;


												</FormLabel>
											</FormGroup>
											<FormGroup className="mb-3" controlId="formBasicPassword">
												<FormLabel>
													<a href="/gts/recommended-training">Recommended Trainings</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
													<a href="/gts/search-training">Search Trainings</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
													<a href="/gts/training-in-progess">Training in progress</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
												</FormLabel>
											</FormGroup>
										</Card.Body>
									</Card>
								</Col>
							</Row>

						</Container>
					</div>



					<br></br>
				<Footer />

           </div>  //main end
        )
    }
}
export default ServiceProviderHomePage;
