import React, { Component } from 'react'
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import 'bootstrap/dist/css/bootstrap.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser,faTrash,faEdit,faUpload} from '@fortawesome/free-solid-svg-icons'
import TrainerMenu from '../../components/trainer/TrainerMenu';
import TraineeMenu from '../../components/trainee/TraineeMenu';
import RecruiterMenu from '../../components/recruiter/RecruiterMenu';
import AdminMenu from '../../components/admin/AdminMenu';
import ServiceProviderMenu from '../../components/service_provider/ServiceProviderMenu';
import ServiceConsumerMenu from '../../components/service_consumer/ServiceConsumerMenu';
import UserProjectComponent from '../../components/common/UserProjectComponent';
import {
  Button,Alert,FormGroup,Form,Input,InputGroupAddon,InputGroupText,InputGroup,Row,Col,Container
} from 'reactstrap';
import { FormControl, FormLabel, Card, CardBody,CardText } from 'react-bootstrap';
import ls from 'local-storage';


var userLoginDetails=ls.get('userLoginDetails');

class  UnderConstructionPage extends Component {

  render()
  {
   return (
           <div>
             <Header/>
                {userLoginDetails.primary_role == "ADMIN" ?
                <AdminMenu/>
                : ''}
                {userLoginDetails.primary_role == "RECRUITER" ?
                <RecruiterMenu/>
                : ''}
                {userLoginDetails.primary_role == "TRAINEE" ?
                <TraineeMenu/>
                : ''}
                {userLoginDetails.primary_role == "SERVICE_CONSUMER" ?
                <ServiceConsumerMenu/>
                : ''}
                {userLoginDetails.primary_role == "SERVICE_PROVIDER" ?
                <ServiceProviderMenu/>
                : ''}
                {userLoginDetails.primary_role == "TRAINER" ?
                <TrainerMenu/>
                : ''}
            <b>THIS PAGE IS UNDER CONSTRUCTION</b>
             <br></br>
             <br></br>
             <Footer />
           </div>
         )
  }
}
export default  UnderConstructionPage;



