import React, { Component } from 'react'
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import 'bootstrap/dist/css/bootstrap.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser,faTrash,faEdit,faUpload} from '@fortawesome/free-solid-svg-icons'
import TrainerMenu from '../../components/trainer/TrainerMenu';
import ServiceProviderMenu from '../../components/service_provider/ServiceProviderMenu';
import ServiceConsumerMenu from '../../components/service_consumer/ServiceConsumerMenu';
import UserProjectComponent from '../../components/common/UserProjectComponent';
import {
  Button,Alert,FormGroup,Form,Input,InputGroupAddon,InputGroupText,InputGroup,Row,Col,Container
} from 'reactstrap';
import { FormControl, FormLabel, Card, CardBody,CardText } from 'react-bootstrap';
import ls from 'local-storage';

var token = ls.get("token");
var userLoginDetails=ls.get('userLoginDetails');

class  UserProjectPage extends Component {
   constructor(props) {
	   super(props);
		if(token==null || token=="" || token=="undefined"){
		  window.location.href = '/gts/global-home-page';
		}
	}
  render()
  {
   return (
           <div>
             <Header/>
             {userLoginDetails.primary_role == "SERVICE_CONSUMER" ?
                <ServiceConsumerMenu/>
                : ''}
                {userLoginDetails.primary_role == "SERVICE_PROVIDER" ?
                <ServiceProviderMenu/>
                : ''}
                {userLoginDetails.primary_role == "TRAINER" ?
                <TrainerMenu/>
                : ''}
             <UserProjectComponent/>
             <br></br>
             <br></br>
             <Footer />
           </div>
         )
  }
}
export default  UserProjectPage;



