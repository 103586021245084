export const endpoints_properties =
  {
    "ENDPOINT_IDENTITY_LOCAL" : "http://localhost:4728",
    "ENDPOINT_PERSONAL_DETAILS_LOCAL" : "http://localhost:4728",
    "ENDPOINT_RECOMMENDED_SERVICES_LOCAL" : "http://localhost:4728",
    "ENDPOINT_SKILLS_LOCAL" : "http://localhost:4728",
    "ENDPOINT_SERVICE_APPLICATIONS_LOCAL" : "http://localhost:4728",
    "ENDPOINT_SC_SERVICES_LOCAL": "http://localhost:4728",
    "ENDPOINT_INDUSTRIES_LOCAL" :"http://localhost:4728",
    "ENDPOINT_COMPANIES_LOCAL" : "http://localhost:4728",
    "ENDPOINT_SERVICE_TITLES_LOCAL" : "http://localhost:4728",
    "ENDPOINT_COUNTRIES_LOCAL":"http://localhost:4728",
    "ENDPOINT_STATES_LOCAL":"http://localhost:4728",
    "ENDPOINT_CITIES_LOCAL":"http://localhost:4728",
    "ENDPOINT_DEGREES_LOCAL" : "http://localhost:4728",
    "ENDPOINT_INSTITUTES_LOCAL": "http://localhost:4728",
    "ENDPOINT_LANGUAGES_LOCAL" :"http://localhost:4728",
    "ENDPOINT_CURRENCIES_LOCAL" : "http://localhost:4728",
    "ENDPOINT_SEARCH_SERVICES_LOCAL" : "http://localhost:4728",
    "ENDPOINT_USER_EDUCATIONS_LOCAL": "http://localhost:4728",
    "ENDPOINT_SC_REQUIRED_LANGUAGES_LOCAL": "http://localhost:4728",
    "ENDPOINT_USER_PROJECTS_LOCAL": "http://localhost:4728",
    "ENDPOINT_PROFILE_VIEWER_LOCAL": "http://localhost:4728",
    "ENDPOINT_USER_RESUME_DOWNLOAD" : "http://localhost:4728",
    "ENDPOINT_USER_RESUME_UPLOAD": "http://localhost:4728",
    "ENDPOINT_SC_REQUIRED_SKILLS_LOCAL": "http://localhost:4728",
    "ENDPOINT_NATIONALITIES_LOCAL" : "http://localhost:4728",
    "ENDPOINT_FIELD_OF_RECRUITMENT_LOCAL" : "http://localhost:4728",
    "ENDPOINT_DEV" :"https://6kmxdtde21.execute-api.ap-south-1.amazonaws.com/dev",
    "ENDPOINT_PROD_LAMBDA" : "https://n30s6a09y2.execute-api.ap-south-1.amazonaws.com/prod",
    "ENDPOINT_PROD" : "https://zl5zgx9zh7.execute-api.ap-south-1.amazonaws.com/prod",
    "ENDPOINT_PROD_EC2" : "https://ec2-13-232-221-159.ap-south-1.compute.amazonaws.com:4728",
    "ENDPOINT_LOCAL" : "http://localhost:4728",

  }