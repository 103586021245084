import React, { Component } from 'react'
import axios from 'axios'
import ls from 'local-storage'
import {  Button, ButtonGroup, Col, Container, Form, Modal, Row} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faTrash, faEdit, faUpload } from '@fortawesome/free-solid-svg-icons';
import { endpoints_properties } from '../../properties/EndPointsProperties.js';
import { api_properties } from '../../properties/APIProperties.js';

var token = ls.get('token');
var env=ls.get('ENV');
var userLoginDetails=ls.get('userLoginDetails');

var getNoticePeriodURL="";
var addOrEditNoticePeriodURL="";



if(env=="LOCAL"){
	getNoticePeriodURL=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_GET_NOTICE_PERIOD;
	addOrEditNoticePeriodURL=endpoints_properties.ENDPOINT_LOCAL+api_properties.API_NOTICE_PERIOD;

}
else{
	getNoticePeriodURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_GET_NOTICE_PERIOD;
	addOrEditNoticePeriodURL=endpoints_properties.ENDPOINT_PROD+api_properties.API_NOTICE_PERIOD;
}

class NoticePeriodComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            gts_notice_period_days:'',
            gts_notice_period_id:'',
            disableNoticePeriodInput: true,
            noticePeriodExisting : false
        }
        this.changeHandler = this.changeHandler.bind(this);
        this.enableInput = this.enableInput.bind(this);

    }
    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
      }

      enableInput = () => {
        this.setState( {disableNoticePeriodInput: false} )
      }


    componentDidMount() {
        axios.get(getNoticePeriodURL+'/'+userLoginDetails.user_id,{ headers: {"Auth_Token" : `Bearer ${token}`} })
        .then(res => {
            console.log(res.data)
            this.setState({gts_notice_period_days: res.data.gts_notice_period_days})
            this.setState({gts_notice_period_id: res.data.gts_notice_period_id})

            if(res.data.gts_notice_period_id !==0){
                this.setState({noticePeriodExisting : true})
            }
        })
        .catch(error => {
            console.log(error)
        })
    }

    saveNoticePeriod = e => {
        var message=this.state.message
        var error=this.state.error
        var url=addOrEditNoticePeriodURL;
             var postPayload = {
                gts_user_id:userLoginDetails.user_id,
                gts_notice_period_days: this.state.gts_notice_period_days
             };
             var putPayload = {
               gts_notice_period_id:this.state.gts_notice_period_id,
                gts_notice_period_days: this.state.gts_notice_period_days
             };
        if(this.state.gts_notice_period_id==0){
            axios.post(url,postPayload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
            .then(response => {
             message = response.data.message;
             this.setState({message : response.data.message});
             this.setState({gts_notice_period_id: response.data.gts_notice_period_id})
            })
            .catch(err => {
                if(err.request){
                    error = "Not able add notice period"
                    this.setState({error: error});
                }
                else{
                error = err.response.data.message
             this.setState({error: error});
              console.log(err.response);
                }
            })

        }

        else  {
            axios.put(url,putPayload,{ headers: {"Auth_Token" : `Bearer ${token}`} })
            .then(response => {
                console.log(response)
             message = response.data.message;
             this.setState({message : response.data.message});
             this.setState({gts_notice_period_days: response.data.gts_notice_period_days});
            })
            .catch(err => {
                if(err.request){
                    error = "Not able add notice period"
                    this.setState({error: error});
                }
                else{
                error = err.response.data.message
                this.setState({error: error});
              console.log(err.response);
                }
            })

        }
    }
        clearMessage = e => {
            var message=this.state.message
            var error=this.state.error
            if (this.state.message) {
                message= '';
                this.setState({message : message});
            }

            if (this.state.error) {
                error = '';
                this.setState({error : error});


            } }




    render() {
        const { gts_notice_period_days, isenableInput } = this.state
        return(
            <Container className="container align-items-center">
                <div className="container align-items-center">
                 <div className="mt-3">
                     <div className="row">
                         <div className="col-4"></div>
                     </div>
                     <Row>
                         <Col>
                             <Form>
                                 <div className="border border-dark  rounded-sm" >

                                     <h5 style={{color : "black"}} class="mb-2 p-2"><b>Notice period</b></h5>
                                     <div style={{borderBottomColor: 'black',borderBottomWidth: 1.5}} />
                                     <br/>
                                    <div>
                                     <div class="row">
                                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                         <div class="col">
                                             <h6 style={{color:"black", fontSize:"18px"}}>
                                                 <input type='text' style={{width: 80, border: "1px solid #D3D3D3", color: this.state.disableNoticePeriodInput == false ? 'gray' : 'black'}} name="gts_notice_period_days" value={gts_notice_period_days} onChange={this.changeHandler} disabled ={this.state.noticePeriodExisting && this.state.disableNoticePeriodInput ? 'disableNoticePeriodInput' : ''} />
                                                 &nbsp;Days
                                              </h6>
                                         </div>
                                      </div>
                             <div class="row" style={{align:"right"}}>
                                 <div class="col-md-12 text-right">
                                     <button type="button" class="btn" style={{display: "inline"}} onClick={this.enableInput}><FontAwesomeIcon icon={faEdit}/></button>
                                     <button type="button" class="btn btn-primary" style={{display: "inline"}} onClick  ={this.saveNoticePeriod} onBlur = {this.clearMessage}>Save</button>
                                  </div>
                            </div>
                             <span  style={{color: "green"}}><center>{this.state.message}</center></span>
                             <span  style={{color: "red"}}><center>{this.state.error}</center></span>
                          </div>
                           </div>
                        </Form>
                    </Col>
                </Row>
            </div>
            </div>
            <br/>
            </Container>

        )
    }

}

export default NoticePeriodComponent;
