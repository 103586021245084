import React, { Component } from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import TrainerMenu from '../../components/trainer/TrainerMenu';
import ServiceProviderMenu from '../../components/service_provider/ServiceProviderMenu';
import ServiceConsumerMenu from '../../components/service_consumer/ServiceConsumerMenu';
import UserWorkExperienceComponent from '../../components/common/UserWorkExperienceComponent';
import 'bootstrap/dist/css/bootstrap.css';
import ls from 'local-storage';

var token = ls.get("token");
var userLoginDetails=ls.get('userLoginDetails');

class UserWorkExperiencePage extends Component {
   constructor(props) {
	   super(props);
		if(token==null || token=="" || token=="undefined"){
		  window.location.href = '/gts/global-home-page';
		}
	}
  render(){
    return (
      <div>
        <Header />
        {userLoginDetails.primary_role == "SERVICE_CONSUMER" ?
                <ServiceConsumerMenu/>
                : ''}
                {userLoginDetails.primary_role == "SERVICE_PROVIDER" ?
                <ServiceProviderMenu/>
                : ''}
                {userLoginDetails.primary_role == "TRAINER" ?
                <TrainerMenu/>
                : ''}
        <UserWorkExperienceComponent/>
        <br/>
        <Footer />
      </div>
    )
  }
}

export default UserWorkExperiencePage;
