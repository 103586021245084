
import React, { Component } from 'react'
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import PrimarySkillsComponent from '../../components/common/PrimarySkillsComponent';
import SecondarySkillsComponent from '../../components/common/SecondarySkillsComponent';
import ServiceProviderMenu from '../../components/service_provider/ServiceProviderMenu';
import TraineeMenu from '../../components/trainee/TraineeMenu';
import ls from 'local-storage';

var token = ls.get("token");
var userLoginDetails=ls.get('userLoginDetails');

class ManageSkillsPage extends Component {
   constructor(props) {
	   super(props);
		if(token==null || token=="" || token=="undefined"){
		  window.location.href = '/gts/global-home-page';
		}
	}
  render(){
    return (
     <div>
        <Header/>
        {userLoginDetails.primary_role == "TRAINEE"
          ? <TraineeMenu/> : ''}
        {userLoginDetails.primary_role == "SERVICE_PROVIDER"
          ? <ServiceProviderMenu/> : ''}
        <PrimarySkillsComponent/>
        <SecondarySkillsComponent/>
        <br/>
        <Footer />
      </div>
    )
  }
}
export default ManageSkillsPage;
